import moment from "moment";
import { useNavigate } from "react-router-dom";
import classNames from "classnames/bind";
import { GoDotFill } from "react-icons/go"
import { IoArrowRedoCircleOutline, IoFileTrayFullOutline, IoFingerPrintOutline, IoStopwatchOutline } from "react-icons/io5";
import { TiWarningOutline } from "react-icons/ti";
import { GrFormPreviousLink } from "react-icons/gr";
import { FiUnlock } from "react-icons/fi";
import { FiSend } from "react-icons/fi"


import styles from "../Job.module.scss"
import SearchDataMonthChamcong from "../Component/Chamcong/SearchDataMothChamcong";
import { getDaysArray } from "../../../handle-reuses/reuses";
import { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import ModalReact from "../../../Components/Modal/ModalReact";
import AppContext from "../../../handle-reuses/Contexts/CreateContexts";
import FormChamcong from "../Component/Chamcong/FormChamcong";
import { BsDot } from "react-icons/bs";
import FormXinvangmat from "../Component/Chamcong/FormXinvangmat";
import FormTangca from "../Component/Chamcong/FormTangca";
import ListVangmat from "../Component/Chamcong/ListVangmat";
import ListTangca from "../Component/Chamcong/ListTangca";
import FormDonXinNghiviec from "../Component/Chamcong/FormDonXinNghiviec";
import ListDonNghiviec from "../Component/Chamcong/ListDonNghiviec";
import LoadingSpinner from "../../../Components/Loading/LoadingSpinner";
const cx = classNames.bind(styles);

function BangChamCongPC() {
  const {socketio, dataUser} = useContext(AppContext)
  const goBack = useNavigate()
  const handleGoBack = () => {
    goBack(-1)
  }
  useEffect(() => {
    if (dataUser[0].cham_cong !== 1) {
      goBack("/")
      alert("Bạn không có quyền truy cập vào trang này.")
    }
  }, []);

  // Quản lý danh sách nhân viên chấm công.
  const [listPersonnel, setListPersonnel] = useState([])
  const [listTimekeeping, setListTimeKeeping] = useState([]);
  const dataTimekeepingRef = useRef([])
  const listPersonnelRef = useRef([])
  const monthRef = useRef(moment().format("MM/YYYY"))
  const handleGetDataPersonnelTimekeeping = () => {
    // Điều kiện hiển thị danh sách chấm công.
    if (dataUser[0].list_chamcong === 1) {
      let params = {
        iden: process.env.REACT_APP_IDEN_DASH,
      }
      socketio.emit("client-get-list-all-personnel-timekeeping", params)
      socketio.on("server-get-list-all-personnel-timekeeping", (data) => {
        setListPersonnel([...data])
        listPersonnelRef.current = [...data]
      })
    } else {
      let params = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: { user_id: dataUser[0].id_user },
      }
      socketio.emit("client-get-list-id-personnel-timekeeing", params)
      socketio.on("server-get-list-id-personnel-timekeeing", (data) => {
        setListPersonnel([...data])
        listPersonnelRef.current = [...data]
      })
    }
  }
  useEffect(() => {
    // Get data ban đầu
    handleGetDataPersonnelTimekeeping()
    return () => {
      socketio.off("client-get-list-all-personnel-timekeeping")
      socketio.off("server-get-list-all-personnel-timekeeping")
      socketio.off("client-get-list-id-personnel-timekeeing")
      socketio.off("server-get-list-id-personnel-timekeeing")
    }
  }, [])

  // Quản lý get data timekeeping.
  const [monthSearch, setMonthSearch] = useState(moment().format("YYYY-MM"))
  const handleGetListTimekeeping = (monthSearch_)=>{
    let arrTimekeeping = []
    for (let i = 0; i < listPersonnel.length; i++) {
      let paramsTimekeeping = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: {
          user_id: listPersonnel[i].id_user,
          addtime: monthSearch_,
        },
      }
      socketio.emit("client-get-list-id-month-timekeeping", paramsTimekeeping)
    }
    socketio.on("server-get-list-id-month-timekeeping", (dataTimekeeping) => {
      if (dataTimekeeping.length > 0) {
        arrTimekeeping.push(...dataTimekeeping)
        setListTimeKeeping([...arrTimekeeping])
        dataTimekeepingRef.current = [...arrTimekeeping]
      }
    })
  }
  useEffect(() => {
    if (listPersonnel.length > 0) {
      handleGetListTimekeeping(monthSearch)
    }
    return () => {
      socketio.off("client-get-list-id-month-timekeeping")
      socketio.off("server-get-list-id-month-timekeeping")
    }
  }, [listPersonnel])

  // Quản lý modal chấm công.
  const [modalChamcong, setModalChamcong] = useState(false);
  const handleAddChamcong = () => {
    setModalChamcong(true);
  }
  const handleCloseModalChamcong = () => {
    setModalChamcong(false)
  }

  const handleChamcongSuccess = (object) => {
    if (object.status) {
      // setModalChamcong(false)
      let dataUpdate = dataTimekeepingRef.current;
      let checkIndex = dataUpdate.findIndex((item) => item.id === object.dataUpdateTimekeeping[0].id);
      if (checkIndex > 0) {
        dataUpdate[checkIndex] = object.dataUpdateTimekeeping[0];
        setListTimeKeeping([...dataUpdate])
        dataTimekeepingRef.current = [...dataUpdate];
      } else {
        dataUpdate.push(object.dataUpdateTimekeeping[0])
        setListTimeKeeping([...dataUpdate]);
        dataTimekeepingRef.current = [...dataUpdate]
      }
    }
  }

  // Quản lý xin vắng mặt.
  const [modalXinvangmat, setModalXinvangmat] = useState(false);
  const handleModalXinvangmat = () => {
    setModalXinvangmat(true)
  }
  const handleCloseModalXinvangmat = () => {
    setModalXinvangmat(false)
  }
  const handleAddXinvangmatSuccess = (status_) => {
    if (status_) {
      setModalXinvangmat(false)
    }
  }

  // Quản lý tăng ca thêm giờ.
  const [modalTangca, setModalTangca] = useState(false)
  const handleShowModalTangca = () => {
    setModalTangca(true)
  }
  const handleCloseModalTangca = () => {
    setModalTangca(false)
  }
  const handleAddTangcaSuccess = (status_) => {
    if (status_) {
      setModalTangca(false)
    }
  }

  // Quản lý tạo mới đơn xin nghỉ việc.
  const [modalDonNghiviec, setModalDonNghiviec] = useState(false);
  const handleModalDonNghiviec = () => {
    setModalDonNghiviec(true)
  }
  const handleCloseModalDonNghiviec = () => {
    setModalDonNghiviec(false)
  }

  // Quản lý search data timekeeping.
  const handleResultSearchTimekeeping = (object) => {
    
    if (object.textSearch && object.monthSearch) {
      const paramsSearch = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: {keywords: object.textSearch}
      }
      socketio.emit("client-search-personnel-timekeeping", paramsSearch)
      socketio.on("server-search-personnel-timekeeping", (dataSearch) => {
        setListPersonnel([...dataSearch])
      })
      // Set nếu month search.
      setMonthSearch(object.monthSearch)
      const paramsSearch_ = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: { addtime: object.monthSearch },
      }
      socketio.emit("client-search-list-month-timekeeping", paramsSearch_)
      socketio.on(
        "server-search-list-month-timekeeping",
        (updateTimekeeping) => {
          setListTimeKeeping([...updateTimekeeping])
        },
      )
    } else if (object.textSearch === "" && object.monthSearch === "") {
      setListPersonnel([...listPersonnelRef.current])
      setMonthSearch(moment().format("YYYY-MM"))
    } else if (object.textSearch === "" && object.monthSearch) {
      setMonthSearch(object.monthSearch)
      const paramsSearch_ = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: {addtime: object.monthSearch}
      }
      socketio.emit("client-search-list-month-timekeeping", paramsSearch_)
      socketio.on("server-search-list-month-timekeeping", (updateTimekeeping) => {
        setListTimeKeeping([...updateTimekeeping]);
      })
    } else if (object.textSearch && object.monthSearch === "") {
      const paramsSearch = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: { keywords: object.textSearch },
      }
      socketio.emit("client-search-personnel-timekeeping", paramsSearch)
      socketio.on("server-search-personnel-timekeeping", (dataSearch) => {
        setListPersonnel([...dataSearch])
      })
    }
    // set tiêu đề tháng cho bảng lương tháng.
    monthRef.current = moment(object.monthSearch).format("MM/YYYY")
  }
  useEffect(() => {
    return () => {
      socketio.off("client-search-personnel-timekeeping")
      socketio.off("server-search-personnel-timekeeping")

      socketio.off("client-search-list-month-timekeeping")
      socketio.off("server-search-list-month-timekeeping")
    }
  }, [listPersonnel, listTimekeeping])

  // Quản lý danh sách mắng mặt.
  const [modalListVangmat, setModalListVangmat] = useState(false)
  const handleModalListVangmat = () => {
    setModalListVangmat(true)
  }
  const handleCloseModalListVangmat = () => {
    setModalListVangmat(false)
  }
  // Quản lý đếm số lượng yêu cầu vắng mặt hôm nay (chưa duyệt).
  const countAccept = useRef(0);
  useLayoutEffect(() => {
    socketio.emit("client-count-accept-today-xinvangmat", { iden: process.env.REACT_APP_IDEN_DASH })
    socketio.on("server-count-accept-today-xinvangmat", (data) => {
      if(dataUser[0].list_vangmat === 1){
        countAccept.current = data[0].count
      }
    });
    return () => {
      socketio.off("client-count-accept-today-xinvangmat")
      socketio.off("server-count-accept-today-xinvangmat")
    }
  }, [])

  // Quản lý đếm notifi phiếu tăng ca hôm nay chưa duyệt.
  const countTangca = useRef(0)
  useLayoutEffect(() => {
    const paramsCountTC = {
      iden: process.env.REACT_APP_IDEN_DASH
    }
    socketio.emit("client-count-today-tangca", paramsCountTC)
    socketio.on("server-count-today-tangca", (dataCountTC) => {
      countTangca.current = dataCountTC[0].count
    });
    return () => {
      socketio.off("client-count-today-tangca")
      socketio.off("server-count-today-tangca")
    }
  }, [])

  // Quản lý đếm số lượng nghỉ việc tháng này.
  const countNghiviec = useRef(0)
  useLayoutEffect(() => {
    const paramsCountNV = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {month: moment().format("YYYY-MM")}
    }
    socketio.emit("client-count-data-month-donnghiviec", paramsCountNV)
    socketio.on("server-count-data-month-donnghiviec", (dataCountNV) => {
      countNghiviec.current = dataCountNV[0].count
    })
    return () => {
      socketio.off("client-count-data-month-donnghiviec")
      socketio.off("server-count-data-month-donnghiviec")
    }
  }, [])

  // Quản lý  danh sách tăng ca.
  const [modalListTangca, setModalListTangca] = useState(false);
  const handleModalListTangca = () => {
    setModalListTangca(true)
  }
  const handleCloseModalListTangca = () => {
    setModalListTangca(false)
  }
  const handleChangeListTangcaSuccess = (object) => {
    
  }

  // quản lý danh sách đơn xin nghỉ việc.
  const [modalListDonnghiviec, setModalListDonnghiviec] = useState(false);
  const handleModalListDonNghiviec = () => {
    setModalListDonnghiviec(true)
  }
  const handleCloseModalListDonnghiviec = () => {
    setModalListDonnghiviec(false)
  }
    return (
      <div>
        {/* Top */}
        <div className="d-flex justify-content-between">
          <div className={cx("d-flex align-items-center gap-3 fs-15 fw-5")}>
            <button
              className={cx(
                "border-0 bg-transparent fs-15 d-flex align-items-center op-8 p-0",
              )}
              onClick={handleGoBack}
            >
              <GrFormPreviousLink className="fs-21" />
              Quay lại
            </button>
            <span className="fw-5 fs-15 d-flex align-items-center gap-2">
              Bảng chấm công tháng{" "}
              <span className="text-primary">{monthRef.current}</span>
              {/* <span className="badge rounded-pill bg-outline-primary ms-1">
                <FiUnlock className="me-1" />
                Chưa khóa
              </span> */}
            </span>
          </div>

          <div className="d-flex gap-3 align-items-center">
            <div className="d-flex align-items-center gap-3">
              <button
                className="border-0 rounded-3 d-flex align-items-center gap-1 fs-14 position-relative"
                onClick={handleModalListVangmat}
              >
                <IoFileTrayFullOutline className="fs-16" />
                Danh sách vắng mặt
                {countAccept.current > 0 && dataUser[0].list_vangmat === 1 && (
                  <span className="text-danger fw-5">
                    ({countAccept.current})
                  </span>
                )}
              </button>
              <button
                className="border-0 rounded-3 d-flex align-items-center gap-1 fs-14"
                onClick={handleModalListTangca}
              >
                <IoStopwatchOutline className="fs-16" />
                Danh sách tăng ca
                {countTangca.current > 0 && dataUser[0].list_tangca === 1 && (
                  <span className="text-danger fw-5">
                    ({countTangca.current})
                  </span>
                )}
              </button>
              <button
                className="border-0 rounded-3 d-flex align-items-center gap-1 fs-14"
                onClick={handleModalListDonNghiviec}
              >
                <IoArrowRedoCircleOutline className="fs-16" />
                Danh sách xin nghỉ việc
                {countNghiviec.current > 0 &&
                  dataUser[0].list_xinnghi === 1 && (
                    <span className="text-danger fw-5">
                      ({countNghiviec.current})
                    </span>
                  )}
              </button>
            </div>
            <div className="d-flex align-items-center gap-3">
              <span className="d-flex align-items-center fs-14">
                <GoDotFill className="text-success-1 me-1 fs-18" />
                Đúng giờ
              </span>
              <span className="d-flex align-items-center fs-14">
                <GoDotFill className="text-danger me-1 fs-18" />
                Đi muộn, về sớm
              </span>
              {/* <span className="d-flex align-items-center fs-14">
                <GoDotFill className="text-gray-1 me-1 fs-18" />
                Nghỉ
              </span> */}
            </div>
          </div>
        </div>
        {/* chức năng */}
        <div className="d-flex justify-content-between mt-3">
          {/* Left */}
          <div className="col-3">
            {/* Tìm kiếm */}
            <SearchDataMonthChamcong
              resultSearch={handleResultSearchTimekeeping}
            />
          </div>
          {/* Right */}
          <div className="col-6">
            <div className="d-flex align-items-center justify-content-end gap-3">
              <button
                className={cx(
                  "border-0 rounded-3 d-flex align-items-center op-9",
                  "btn btn-purple-gradient btn-wave waves-effect waves-light",
                )}
                onClick={handleAddChamcong}
              >
                <IoFingerPrintOutline className="fs-15 me-1" />
                Chấm công
              </button>
              <button
                className={cx(
                  "border-0 rounded-3 d-flex align-items-center op-9",
                  "btn btn-primary-gradient btn-wave waves-effect waves-light",
                )}
                onClick={handleModalXinvangmat}
              >
                <TiWarningOutline className="fs-15 me-1" />
                Đơn vắng mặt
              </button>
              <button
                className={cx(
                  "border-0 rounded-3 d-flex align-items-center op-9",
                  "btn btn-teal-gradient btn-wave waves-effect waves-light",
                )}
                onClick={handleShowModalTangca}
              >
                <FiSend className="fs-15 me-1" />
                Lập phiếu tăng ca
              </button>
              <button
                className={cx(
                  "border-0 rounded-3 d-flex align-items-center op-9",
                  "btn btn-secondary-gradient btn-wave waves-effect waves-light",
                )}
                onClick={handleModalDonNghiviec}
              >
                <FiSend className="fs-15 me-1" />
                Đơn xin nghỉ việc
              </button>
            </div>
          </div>
        </div>
        {/* Danh sách */}
        {listTimekeeping.length > 0 && (
          <ListTimekeeping
            dataPersonnel={listPersonnel}
            dataTimekeeping={listTimekeeping}
            socketio={socketio}
            monthSearch={monthSearch}
          />
        )}

        {/* Modal cham cong */}
        <ModalReact
          modalTitle={
            <>
              <div className="d-flex gap-3 align-items-center">
                <div>
                  <span className="avatar avatar-rounded">
                    <img
                      src={"https://system.view360.vn/" + dataUser[0].avatar}
                      alt="avatar"
                    />
                  </span>
                </div>
                <div>
                  <span>{dataUser[0].hoten}</span>
                  <p className="mb-0 fs-14 text-muted mt-1">
                    Xác nhận chấm công <BsDot /> Ngày{" "}
                    {moment().format("DD/MM/YYYY")}
                  </p>
                </div>
              </div>
            </>
          }
          showModal={modalChamcong}
          handleClose={handleCloseModalChamcong}
          theme={
            <FormChamcong
              socketio={socketio}
              result={handleChamcongSuccess}
              dataUser={dataUser}
            />
          }
        />
        {/* Modal xin vắng mặt */}
        {modalXinvangmat && (
          <ModalReact
            modalTitle="Tạo đơn xin vắng mặt"
            showModal={modalXinvangmat}
            handleClose={handleCloseModalXinvangmat}
            theme={
              <FormXinvangmat
                socketio={socketio}
                dataUser={dataUser}
                result={handleAddXinvangmatSuccess}
              />
            }
          />
        )}
        {/* Modal tăng ca */}
        {modalTangca && (
          <ModalReact
            modalTitle="Phiếu tăng ca"
            showModal={modalTangca}
            handleClose={handleCloseModalTangca}
            theme={
              <FormTangca
                socketio={socketio}
                dataUser={dataUser}
                result={handleAddTangcaSuccess}
              />
            }
          />
        )}
        {/* Modal tạo đơn nghỉ việc */}
        {modalDonNghiviec && (
          <ModalReact
            modalTitle="Tạo đơn xin nghỉ việc"
            showModal={modalDonNghiviec}
            handleClose={handleCloseModalDonNghiviec}
            theme={
              <FormDonXinNghiviec socketio={socketio} dataUser={dataUser} />
            }
          />
        )}
        {/* Modal list vắng mặt */}
        {modalListVangmat && (
          <ModalReact
            modalTitle="Danh sách vắng mặt"
            showModal={modalListVangmat}
            handleClose={handleCloseModalListVangmat}
            theme={<ListVangmat />}
          />
        )}
        {/* Modal list tăng ca */}
        {modalListTangca && (
          <ModalReact
            modalTitle="Danh sách tăng ca"
            showModal={modalListTangca}
            handleClose={handleCloseModalListTangca}
            theme={<ListTangca />}
          />
        )}
        {/* Modal list đơn nghỉ việc */}
        {modalListDonnghiviec && (
          <ModalReact
            modalTitle="Danh sách đơn nghỉ việc"
            showModal={modalListDonnghiviec}
            handleClose={handleCloseModalListDonnghiviec}
            theme={<ListDonNghiviec socketio={socketio} dataUser={dataUser} />}
          />
        )}
      </div>
    )
}
export default BangChamCongPC

// List nhân viên chấm công
function ListTimekeeping({ socketio, dataPersonnel, dataTimekeeping, monthSearch }) {

  let year_ = moment(monthSearch).format("YYYY")
  let month_ =moment(monthSearch).format("MM")
  const arrDays = getDaysArray(year_, month_) // Ví dụ cho tháng 7 năm 2024
  const sortDays = arrDays.sort((a, b) => {
    return Number(b.date) - Number(a.date)
  })
  const filterDays = sortDays.filter((item) => {
    const limitShowDays = 5 * 24 * 60 * 60 * 1000; // 6 ngày
    const currentDay = new Date().getTime();
    const days_ = new Date(monthSearch + "-" + item.date).getTime()
    return days_ <= currentDay 
  });
  
  const days = filterDays

  // Quản lý giờ làm việc.
  const gioLamviec = useRef([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    // Check giờ làm viêc.
    const paramsGiolamviec = {
      iden: process.env.REACT_APP_IDEN_DASH,
    }
    socketio.emit("client-get-data-giolamviec", paramsGiolamviec)
    socketio.on("server-get-data-giolamviec", (data) => {
      gioLamviec.current = [...data]
      setLoading(false)
    })
    return () => {
      socketio.off("client-get-data-giolamviec")
      socketio.off("server-get-data-giolamviec")
    }
  }, []);

  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <div className="mt-3">
      <div className="table-responsive">
        <table
          className="table text-nowrap rounded-4 table-bordered"
          style={{ overflow: "hidden" }}
        >
          <thead>
            <tr className="text-center">
              <th scope="col" className="text-left">
                Nhân viên
              </th>
              <th scope="col">Công chuẩn</th>
              <th scope="col">Đi muộn (phút)</th>
              <th scope="col">Về sớm (phút)</th>
              {/* Xác định các ngày trong tháng */}
              {days.map((dayInfo, index) => {
                let currentDay = moment().format("DD")
                return (
                  <th
                    key={index}
                    className={cx(
                      "day-cell",
                      Number(currentDay) === Number(dayInfo.date)
                        ? "bg_today"
                        : "",
                    )}
                  >
                    <span className={cx("day-name", "text-muted")}>
                      {dayInfo.day.replace("Th", "Thứ")}
                    </span>
                    <div className={cx("day-date", "fs-16")}>
                      {dayInfo.date}/{moment(monthSearch).format("MM")}
                    </div>
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {dataPersonnel.map((item, index) => {
              const listTimekeeping = dataTimekeeping.filter((item_) => {
                return item_.user_id === item.id_user
              })
              // set công chuẩn.
              let arr_congchuan = listTimekeeping.map(
                (values) => values.cong_chuan,
              )
              let total_congchuan = 0
              if (arr_congchuan.length > 0) {
                total_congchuan = arr_congchuan.reduce((a, b) => {
                  return Number(a) + Number(b)
                })
              }
              // Set số phút đi muộn.
              let arr_dimuon = listTimekeeping.map((values) => values.di_muon)
              let total_dimuon = 0
              if (arr_dimuon.length > 0) {
                total_dimuon = arr_dimuon.reduce((a, b) =>
                  (Number(a) + Number(b)).toFixed(2),
                )
              }
              // Set số phút về sớm.
              let arr_vesom = listTimekeeping.map((values) => values.ve_som)
              let total_vesom = 0
              if (arr_vesom.length > 0) {
                total_vesom = arr_vesom.reduce((a, b) => Number(a) + Number(b))
              }
              return (
                <tr key={index}>
                  <th scope="row">
                    <div className="d-flex align-items-center">
                      <span
                        className={cx(
                          "avatar avatar-sm me-2 avatar-rounded",
                          item.onlineoffline === 1 ? "online" : "offline",
                        )}
                      >
                        <img src={"/" + item.avatar} alt="img" />
                      </span>
                      <span>
                        <span className={cx("fw-5 fs-15")}>{item.hoten}</span>
                        <div className="text-muted fs-14">
                          {item.sodienthoai}
                        </div>
                      </span>
                    </div>
                  </th>
                  <td className="text-center fw-5">
                    <span className="fs-18">{total_congchuan}</span>
                  </td>
                  <td className="text-center text-danger">
                    {total_dimuon}' ~ {(Number(total_dimuon) / 60).toFixed(1)}
                    <sup>h</sup>
                  </td>
                  <td className="text-center">{total_vesom}'</td>
                  {/* Xác định chấm công các ngày trong tháng tương ứng */}
                  {days.map((dayInfo, index) => {
                    let days_ = dayInfo.date
                    let dungsai = 5 * 60 * 1000 // dung sai 5 phút
                    let itemTimeKeeping = listTimekeeping.find((item__) => {
                      return moment(item__.addtime).format("DD").includes(days_)
                    })
                    // Biến hiển thị buổi sáng
                    let checkDaysMorningStart = (
                      <span className="text-muted op-6">--:--</span>
                    )
                    let checkDaysMorningEnd = (
                      <span className="text-muted op-6">--:--</span>
                    )

                    // Biến hiển thị buổi chiều
                    let checkDaysAfternoonStart = (
                      <span className="text-muted op-6">--:--</span>
                    )
                    let checkDaysAfternoonEnd = (
                      <span className="text-muted op-6">--:--</span>
                    )

                    // Kiểm tra itemTimeKeeping có tồn tại không
                    if (itemTimeKeeping) {
                      let setDay = moment(itemTimeKeeping.addtime).format("DD")

                      // Xác định thời gian theo ca làm việc (Sáng)
                      let setTimeMorningStart = moment(
                        itemTimeKeeping.ca_sang_a,
                      ).format("YYYY-MM-DD" + gioLamviec.current[0].ca_sang_a)
                      let formatTimeMorningStart = new Date(
                        setTimeMorningStart,
                      ).getTime()
                      let timekeepingMorningStart = new Date(
                        itemTimeKeeping.ca_sang_a,
                      ).getTime()
                      let setTimeMorningEnd = moment(
                        itemTimeKeeping.ca_sang_a,
                      ).format("YYYY-MM-DD" + gioLamviec.current[0].ca_sang_b)
                      let formatTimeMorningEnd = new Date(
                        setTimeMorningEnd,
                      ).getTime()
                      let timekeepingMorningEnd = new Date(
                        itemTimeKeeping.ca_sang_b,
                      ).getTime()

                      // Xử lý hiển thị ca làm việc sáng
                      if (Number(days_) === Number(setDay)) {
                        // Set đầu giờ làm việc
                        if (timekeepingMorningStart <= formatTimeMorningStart) {
                          checkDaysMorningStart = (
                            <span className="text-success-1 fw-5">
                              {moment(itemTimeKeeping.ca_sang_a).format(
                                "HH:mm",
                              )}
                            </span>
                          )
                        } else {
                          if (itemTimeKeeping.ca_sang_a) {
                            checkDaysMorningStart = (
                              <span className="text-danger fw-5">
                                {moment(itemTimeKeeping.ca_sang_a).format(
                                  "HH:mm",
                                )}
                              </span>
                            )
                          }
                        }
                        // Set cuối giờ làm việc.
                        if (itemTimeKeeping.ca_sang_b) {
                          if (timekeepingMorningEnd >= formatTimeMorningEnd) {
                            checkDaysMorningEnd = (
                              <span className="text-success-1 fw-5">
                                {moment(itemTimeKeeping.ca_sang_b).format(
                                  "HH:mm",
                                )}
                              </span>
                            )
                          } else {
                            checkDaysMorningEnd = (
                              <span className="text-danger fw-5">
                                {moment(itemTimeKeeping.ca_sang_b).format(
                                  "HH:mm",
                                )}
                              </span>
                            )
                          }
                        }
                      }

                      // Xác định thời gian theo ca làm việc (chiều)
                      let setTimeAfternoonStart = moment(
                        itemTimeKeeping.ca_chieu_a,
                      ).format("YYYY-MM-DD" + gioLamviec.current[0].ca_chieu_a)
                      let formatTimeAfternoonStart = new Date(
                        setTimeAfternoonStart,
                      ).getTime()
                      let timekeepingAfternoonStart = new Date(
                        itemTimeKeeping.ca_chieu_a,
                      ).getTime()
                      let setTimeAfternoonEnd = moment(
                        itemTimeKeeping.ca_chieu_a,
                      ).format("YYYY-MM-DD" + gioLamviec.current[0].ca_chieu_b)
                      let formatTimeAfternoonEnd = new Date(
                        setTimeAfternoonEnd,
                      ).getTime()
                      let timekeepingAfternoonEnd = new Date(
                        itemTimeKeeping.ca_chieu_b,
                      ).getTime()
                      // Xử lý hiển thị buổi chiều
                      if (Number(days_) === Number(setDay)) {
                        // Set đầu giờ làm việc
                        if (itemTimeKeeping.ca_chieu_a) {
                          if (
                            timekeepingAfternoonStart <=
                            formatTimeAfternoonStart
                          ) {
                            checkDaysAfternoonStart = (
                              <span className="text-success-1 fw-5">
                                {moment(itemTimeKeeping.ca_chieu_a).format(
                                  "HH:mm",
                                )}
                              </span>
                            )
                          } else {
                            checkDaysAfternoonStart = (
                              <span className="text-danger fw-5">
                                {moment(itemTimeKeeping.ca_chieu_a).format(
                                  "HH:mm",
                                )}
                              </span>
                            )
                          }
                        }
                        // Set cuối giờ làm việc.
                        if (itemTimeKeeping.ca_chieu_b) {
                          if (
                            timekeepingAfternoonEnd >= formatTimeAfternoonEnd
                          ) {
                            checkDaysAfternoonEnd = (
                              <span className="text-success-1 fw-5">
                                {moment(itemTimeKeeping.ca_chieu_b).format(
                                  "HH:mm",
                                )}
                              </span>
                            )
                          } else {
                            checkDaysAfternoonEnd = (
                              <span className="text-danger">
                                {moment(itemTimeKeeping.ca_chieu_b).format(
                                  "HH:mm",
                                )}
                              </span>
                            )
                          }
                        }
                      }
                    }
                    return (
                      <td key={index} className={cx("day-cell")}>
                        <div
                        // style={{ maxWidth: "100px" }}
                        >
                          <div className="d-flex flex-direction-column gap-0">
                            <div className="d-flex gap-1 align-items-center justify-content-center">
                              <span style={{ width: "39px" }}>
                                {checkDaysMorningStart}
                              </span>
                              <BsDot className="text-muted" />
                              <span style={{ width: "39px" }}>
                                {checkDaysMorningEnd}
                              </span>
                            </div>
                            <div className="d-flex gap-1 align-items-center justify-content-center">
                              <span style={{ width: "39px" }}>
                                {checkDaysAfternoonStart}
                              </span>
                              <BsDot className="text-muted" />
                              <span style={{ width: "39px" }}>
                                {checkDaysAfternoonEnd}
                              </span>
                            </div>
                          </div>
                        </div>
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}


