import classNames from "classnames/bind"
import { GoArrowUp, GoDotFill } from "react-icons/go";

import styles from "../Job.module.scss";
import { GoBack } from "../../../Components/Goback";
import { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { IoMdAdd, IoMdCheckmarkCircle, IoMdCheckmarkCircleOutline, IoMdTrendingUp } from "react-icons/io";
import moment from "moment";
import ModalReact from "../../../Components/Modal/ModalReact";
import { IoAddCircleOutline, IoCalendarOutline, IoCardOutline, IoDocumentTextOutline, IoNewspaperOutline, IoTicketOutline, IoWalletOutline } from "react-icons/io5";
import { GrLineChart } from "react-icons/gr";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { BsArrowLeftShort, BsArrowRightShort, BsArrowUp, BsDot } from "react-icons/bs";
import AppContext from "../../../handle-reuses/Contexts/CreateContexts";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../Components/Loading/LoadingSpinner";
import SearchContract from "../Component/Hopdong/SearchContract";
import Tippy from "@tippyjs/react";
import { AiOutlinePercentage } from "react-icons/ai";
import { LiaDoorClosedSolid } from "react-icons/lia";
import { HiOutlineUserCircle } from "react-icons/hi";
import { MdRadioButtonChecked } from "react-icons/md";
import FormAddContract from "../../DataSale/Component/FormAddContract";
import CardStatisticalContract from "../Component/Hopdong/CardStatisticalContract";
import DetailContract from "../Component/Hopdong/DetailContract";
import FormAddContractTech from "../Component/Hopdong/FormAddContractTech";
import ModalAddContractView from "../../View360/ContractsPage/Components/ModalAddContractView";
import FormAddContractOther  from "../Component/Hopdong/FormAddContractOther";
import FormEditContract from "../../DataSale/Component/FormEditContract";
import FormEditContractTech from "../Component/Hopdong/FormEditContractTech";
const cx = classNames.bind(styles);

function ListContractPC() {
  const { socketio, dataUser } = useContext(AppContext)
  const navigate = useNavigate()
  useLayoutEffect(() => {
    if (dataUser[0].hop_dong === 0) {
      navigate("/")
    }
  }, [])
  const [objectSearch, setObjectSearch] = useState({
    lastMonth: moment().subtract(1, "months").format("YYYY-MM"),
    daysSearch: "",
    monthSearch: moment().format("YYYY-MM"),
    textSearch: "",
  })

  // Quản lý danh mục.
  const [activeCate, setActiveCate] = useState("list-hop-dong")
  const handleActiveCate = (cate) => {
    setActiveCate(cate)
  }

  // Quản lý search data.
  const handleSearchMonthTextContract = (object) => {
    setObjectSearch({
      lastMonth: moment(object.monthSearch)
        .subtract(1, "months")
        .format("YYYY-MM"),
      daysSearch: object.daysSearch,
      monthSearch: object.monthSearch,
      textSearch: object.textSearch,
    })
  }

  // Quản lý thêm mới hợp đồng bất động sản.
  // const [realEstateContract, setRealEstateContract] = useState(false)
  // const [statusAddContractSuccess, setStatusAddContractSuccess] = useState(false)
  // const handleAddModalContractRealEstate = () => {
  //   setRealEstateContract(true)
  // }
  // const handleCloseModalContractRealEstate = () => {
  //   setRealEstateContract(false)
  // }
  // const handleAddSuccessRealEstate = (status_) => {
  //   if (status_) {
  //     setStatusAddContractSuccess(status_)
  //   }
  // }

  // Thêm mới hợp đồng quảng cáo bất động sản.
  const [modalAddAdsBDS, setModalAddAdsBDS] = useState(false)
  const handleAddContractAdsBDS = () => {
    setModalAddAdsBDS(true)
  }
  const handleCloseAddContractAdsBDS = () => setModalAddAdsBDS(false)
  const handleAddContractAdsBDSSuccess = (status_) => {
    if (status_) {
      setModalAddAdsBDS(false)
    }
  }

  // Thêm mới hợp đồng view 360.
  const [modalAddView360, setModalAddView360] = useState(false)
  const handleAddContractView360 = () => {
    setModalAddView360(true)
  }
  const handleCloseAddContractView360 = () => setModalAddView360(false)
  const handleAddContractView360Success = (status_) => {
    if (status_) {
      setModalAddView360(false)
    }
  }

  // Thêm mới hợp đồng công nghệ.
  const [modalAddTech, setModalAddTech] = useState(false)
  const handleAddContractTech = () => {
    setModalAddTech(true)
  }
  const handleCloseAddContractTech = () => setModalAddTech(false)
  const handleAddContractSuccess = (status_) => {
    if (status_) {
      setModalAddTech(false)
    }
  }

  // Thêm mới hợp đồng khác.
  const [modalAddOther, setModalAddOther] = useState(false)
  const handleAddContractOther = () => {
    setModalAddOther(true)
  }
  const handleCloseAddContractOther = () => setModalAddOther(false)
  const handleAddContractOtherSuccess = (status_) => {
    if (status_) {
      setModalAddOther(false)
    }
  }

  return (
    <>
      {/* Top 1 */}
      <div className={cx("d-flex justify-content-between align-items-center")}>
        <div className={cx("d-flex align-items-center gap-3 fs-15 fw-5")}>
          <GoBack />
          <span>
            Quản lý hợp đồng{" "}
            {moment(objectSearch.monthSearch).format("MM/YYYY")}
          </span>
        </div>
      </div>
      {/* top 2 */}
      <div
        className={cx("d-flex justify-content-between align-items-center mt-3")}
      >
        {/* Left */}
        <div className={cx("d-flex alig-items-center gap-3 fs-15 col-5")}>
          <button
            className={cx(
              "border-0 bg-transparent",
              activeCate === "list-hop-dong" ? "color-orange" : "color-gray",
            )}
            onClick={() => handleActiveCate("list-hop-dong")}
          >
            <span className="d-flex align-items-center">Thống kê hợp đồng</span>
          </button>
          <button
            className={cx(
              "border-0 bg-transparent",
              activeCate === "hopdong-bds" ? "color-orange" : "color-gray",
            )}
            onClick={() => handleActiveCate("hopdong-bds")}
          >
            <span className="d-flex align-items-center">Bất động sản</span>
          </button>
          <button
            className={cx(
              "border-0 bg-transparent",
              activeCate === "contract-tech" ? "color-orange" : "color-gray",
            )}
            onClick={() => handleActiveCate("contract-tech")}
          >
            <span className="d-flex align-items-center">Công nghệ</span>
          </button>
        </div>
        {/* Right */}
        <div
          className={cx(
            "d-flex align-items-center justify-content-end gap-3 col-5",
          )}
        >
          {/* Search*/}
          <div className="col-7">
            <SearchContract resultSearch={handleSearchMonthTextContract} />
          </div>
          <button
            className={cx(
              "btn btn-purple-gradient btn-wave",
              "border-0 rounded-3 d-flex align-items-center",
            )}
            data-bs-toggle="dropdown"
          >
            <IoMdAdd className="fs-15 me-1" />
            Thêm hợp đồng
          </button>
          <ul className="dropdown-menu">
            <li
              className="dropdown-item cursor-pointer"
              onClick={handleAddContractAdsBDS}
            >
              Ads bất động sản
            </li>
            <li
              className="dropdown-item cursor-pointer"
              onClick={handleAddContractView360}
            >
              View 360
            </li>
            <li
              className="dropdown-item cursor-pointer"
              onClick={handleAddContractTech}
            >
              Công nghệ
            </li>
            <li
              className="dropdown-item cursor-pointer"
              onClick={handleAddContractOther}
            >
              Loại khác
            </li>
          </ul>
        </div>
      </div>

      {/* Statistical */}
      {activeCate === "list-hop-dong" && (
        <div className="mt-3">
          <CardStatisticalContract
            socketio={socketio}
            dataUser={dataUser}
            objectSearch={objectSearch}
          />
        </div>
      )}

      {/* List content */}
      <div className={cx("mt-3")}>
        {/* Tất cả hợp đồng */}
        {activeCate === "list-hop-dong" && (
          <ListContractTypeTable
            socketio={socketio}
            dataUser={dataUser}
            objectSearch={objectSearch}
            contractTech={modalAddTech}
            contractAdsBDS={modalAddAdsBDS}
            contractView360={modalAddView360}
            contractOther={modalAddOther}
          />
        )}
        {/* Hợp đồng quảng cáo bds */}
        {activeCate === "hopdong-bds" && (
          <ListContractAdsBds
            socketio={socketio}
            dataUser={dataUser}
            objectSearch={objectSearch}
            contractTech={modalAddTech}
            contractAdsBDS={modalAddAdsBDS}
            contractView360={modalAddView360}
            contractOther={modalAddOther}
          />
        )}
        {/* Hợp đồng công nghệ */}
        {
          activeCate === "contract-tech" && (
            <ListContractTech socketio={socketio} dataUser={dataUser} objectSearch={objectSearch}/>
          )
        }
      </div>

      {/* Modal add contract ads bds */}
      {modalAddAdsBDS && (
        <ModalReact
          modalTitle="Thêm mới hợp đồng bất động sản"
          showModal={modalAddAdsBDS}
          handleClose={handleCloseAddContractAdsBDS}
          theme={
            <FormAddContract
              socketio={socketio}
              dataUser={dataUser}
              resultStatus={handleAddContractAdsBDSSuccess}
            />
          }
        />
      )}

      {/* Modal add contract view 360 */}
      {modalAddView360 && (
        <ModalReact
          modalTitle="Thêm mới hợp đồng view 360"
          showModal={modalAddView360}
          handleClose={handleCloseAddContractView360}
          theme={
            <ModalAddContractView
              checkChange={handleAddContractView360Success}
            />
          }
        />
      )}

      {/* Modal add contract tech */}
      {modalAddTech && (
        <ModalReact
          modalTitle="Thêm mới hợp đồng công nghệ"
          showModal={modalAddTech}
          handleClose={handleCloseAddContractTech}
          theme={
            <FormAddContractTech
              socketio={socketio}
              dataUser={dataUser}
              result={handleAddContractSuccess}
            />
          }
        />
      )}
      {/* Modal add contract other */}
      {modalAddOther && (
        <ModalReact
          modalTitle="Thêm mới hợp đồng khác"
          showModal={modalAddOther}
          handleClose={handleCloseAddContractOther}
          theme={
            <FormAddContractOther
              socketio={socketio}
              dataUser={dataUser}
              result={handleAddContractOtherSuccess}
            />
          }
        />
      )}
    </>
  )
}
export default ListContractPC





// Danh sách hợp đồng kiểu bảng.
function ListContractTypeTable({ socketio, dataUser, objectSearch, contractTech, contractAdsBDS, contractView360, contractOther }) {
  // biến cho component.
  const [dataContract, setDataContract] = useState([])
  const dataContractRef = useRef([])
  const totalRevenueMonth = useRef(0)
  const [loading, setLoading] = useState(true)
  const handleGetDataContract = (objectSearch_) => {
    const paramsContract = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        list_hopdong: dataUser[0].list_hopdong,
        user_id: dataUser[0].id_user,
        daysSearch: objectSearch.daysSearch,
        monthSearch: objectSearch_.monthSearch,
        textSearch: objectSearch_.textSearch,
        lastMonth: objectSearch_.lastMonth,
      },
    }
    // list contract
    socketio.emit("client-get-search-data-contract", paramsContract)
    socketio.on("server-get-search-data-contract", (data) => {
      setDataContract([...data]);
      dataContractRef.current = [...data]
      // set tổng doanh thu.
      if (data.length > 0) {
        let arrRevenueMonth = data.map((item) => item.price_service)
        totalRevenueMonth.current = arrRevenueMonth.reduce(
          (a, b) => Number(a) + Number(b),
        )
      }
      setLoading(false)
    })
  }

  // Get data contract
  useEffect(() => {
    handleGetDataContract(objectSearch)
    return () => {
      socketio.off("client-get-search-data-contract")
      socketio.off("server-get-search-data-contract")
    }
  }, [objectSearch, contractTech, contractAdsBDS, contractView360, contractOther]);

  // Quản lý chi tiết dự án.
  const [detailProject, setDetailProject] = useState(false)
  const objectDetail = useRef()
  const handleModalDetailProject = (idDetail, contractName) => {
    setDetailProject(true)
    objectDetail.current = {
      id: idDetail,
      contract_name: contractName
    }
  }
  const handleCloseModalDetailProject = () => {
    setDetailProject(false);
    objectDetail.current = undefined
  }

  // Quản lý duyệt hợp đồng.
  const handleStatusAcceptContract = (id, price, nvc_bophan, idHopdong, contract_name) => {
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        status: 1,
        tien_hoahong: (Number(price) * 10) / 100,
        hoahong_thitruong: (Number(price) * 10) /100,
        id: id,
        lastMonth: objectSearch.lastMonth,
        monthSearch: objectSearch.monthSearch,
      },
    }
    let checkAcceptContract = window.confirm("Bạn muốn duyệt hợp đồng này chứ ?")
    if (checkAcceptContract) {
      socketio.emit("client-edit-row-status-job-contract", params);
      // Phê duyệt bảng bds_hopdong
      if (contract_name === "Quảng cáo bất động sản") {
        const paramsStatus = {
          iden: process.env.REACT_APP_IDEN_DASH,
          object: {
            id_hopdong: idHopdong,
            duyethopdong: 1,
          },
        }
        socketio.emit("client-edit-row-duyethopdong-bds-contract", paramsStatus)
      }
    }
  }
  useEffect(() => {
    socketio.on("server-edit-row-status-job-contract", (dataUpdate) => {
      let dataUpdateContract = dataContractRef.current;
      let index = dataContractRef.current.findIndex((item) => item.id === dataUpdate[0].id);
      dataUpdateContract[index] = dataUpdate[0];
      // Cập nhật lại dữ liệu
      setDataContract([...dataUpdateContract]);
      let arrRevenueMonth = dataUpdateContract.map((item) => item.price_service)
      totalRevenueMonth.current = arrRevenueMonth.reduce(
        (a, b) => Number(a) + Number(b),
      );
      alert("Hợp duyệt thành công.")
    })
    return () => {
      socketio.off("client-edit-row-status-job-contract")
      socketio.off("server-edit-row-status-job-contract")

      socketio.off("client-edit-row-duyethopdong-bds-contract")
    }
  }, [])

  if (loading) {
    return <LoadingSpinner />
  }
  if (dataContract.length === 0) {
    return <div className="text-center fs-15">Chưa có dữ liệu</div>
  }
  return (
    <>
      <div className="table-responsive">
        <table
          className="table text-nowrap rounded-4"
          style={{ overflow: "hidden" }}
        >
          <tbody>
            {dataContract.map((item, index) => {
              // Set tổng doanh thu từng nhân viên.
              let userRevenue =
                (Number(item.price_service) /
                  Number(totalRevenueMonth.current)) *
                100;

              return (
                <tr key={index}>
                  <td scope="row">
                    <div className="d-flex align-items-center gap-2">
                      <span
                        className={cx(
                          "avatar avatar-md avatar-rounded",
                          item.nvc_onlineoffline === 1 && "online",
                        )}
                      >
                        <img
                          src={"./" + item.nvc_avatar}
                          alt="img"
                        />
                      </span>
                      {/* <div>
                  <span className="fw-5">Hoàng văn hiếu</span>
                  <p className="mb-0 text-muted fw-4">Thị trường, công nghệ</p>
                </div> */}
                    </div>
                  </td>
                  <td>
                    <p className="mb-0 fw-5">{item.nvc_name}</p>
                    <span className="text-warning">{item.nvc_bophan}</span>
                  </td>
                  <td>
                    <span className="d-flex align-items-center gap-3">
                      <button className={cx("border-0", "button_icon")}>
                        <IoDocumentTextOutline className="fs-21" />
                      </button>
                      <span>
                        <p className="mb-0 fw-5">Hợp đồng</p>
                        <span className="text-primary">
                          {item.contract_name}
                        </span>
                      </span>
                    </span>
                  </td>
                  {/* <td>
                    <p className="mb-0 fw-5">Địa chỉ</p>
                    <span className="text-muted">Chợ hàng, Hải phòng</span>
                  </td> */}
                  <td>
                    <span className="d-flex align-items-center gap-3">
                      {item.hinhthuc_thanhtoan === "card" ? (
                        <Tippy content="Chuyển khoản">
                          <button
                            className={cx(
                              "border-0",
                              "btn btn-icon btn-primary-transparent rounded-pill btn-wave waves-effect waves-light",
                            )}
                          >
                            <IoCardOutline
                              className="fs-21"
                              style={{ margin: "-.5rem" }}
                            />
                          </button>
                        </Tippy>
                      ) : (
                        <Tippy content="Tiền mặt">
                          <button
                            className={cx(
                              "border-0",
                              "btn btn-icon btn-warning-transparent rounded-pill btn-wave waves-effect waves-light",
                            )}
                          >
                            <IoWalletOutline
                              className="fs-21"
                              style={{ margin: "-.5rem" }}
                            />
                          </button>
                        </Tippy>
                      )}
                      <span>
                        <p className="mb-0 fw-5">Đơn giá</p>
                        <span className="text-danger">
                          {Number(item.price_service)
                            .toLocaleString()
                            .replace(/[.]/g, ",")}
                          đ
                        </span>
                      </span>
                    </span>
                  </td>

                  <td style={{ width: "50px" }}>
                    <Tippy content="Tỷ lệ so với doanh thu tổng">
                      <div>
                        <span
                          className={cx("text-muted text-success fw-5 fs-18")}
                        >
                          {Number.isInteger(userRevenue)
                            ? userRevenue
                            : userRevenue.toFixed(1)}
                          %
                        </span>
                        <div
                          className="progress progress-xs progress-animate"
                          role="progressbar"
                          aria-valuenow="10"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          <div
                            className="progress-bar progress-bar-striped bg-success"
                            style={{ width: userRevenue + "%" }}
                          ></div>
                        </div>
                      </div>
                    </Tippy>
                  </td>

                  <td>
                    <span className="d-flex align-items-center gap-3">
                      <button
                        className={cx(
                          "border-0",
                          "btn btn-icon btn-primary-transparent rounded-pill btn-wave waves-effect waves-light",
                        )}
                        onClick={() =>
                          handleModalDetailProject(
                            item.id_detail,
                            item.contract_name,
                          )
                        }
                      >
                        <GrLineChart
                          className="fs-18"
                          style={{ margin: "-.5rem" }}
                        />
                      </button>
                      <span>
                        <span className="fw-5">Chi tiết</span>
                        <p className="mb-0 text-muted">Chi tiết hợp đồng</p>
                      </span>
                    </span>
                  </td>
                  <td>
                    <span className="d-flex align-items-center gap-3">
                      <button className={cx("border-0", "button_icon")}>
                        <AiOutlinePercentage className="fs-21" />
                      </button>
                      <span>
                        <p className="mb-0 fw-5">Hoa hồng</p>
                        <span className="text-danger">
                          {Number(item.tien_hoahong)
                            .toLocaleString()
                            .replace(/[.]/g, ",")}
                          đ
                        </span>
                      </span>
                    </span>
                  </td>
                  <td>
                    <span className="d-flex align-items-center gap-3">
                      <button className={cx("border-0", "button_icon")}>
                        <IoCalendarOutline className="fs-21" />
                      </button>
                      <span>
                        <span className="fw-5">Ngày tạo</span>
                        <p className="mb-0 text-muted">
                          {moment(item.addtime).format("DD/MM/YYYY HH:mm")}
                        </p>
                      </span>
                    </span>
                  </td>
                  <td>
                    {dataUser[0].list_hopdong === 1 &&
                      (item.status === 0 ? (
                        <button
                          className={cx(
                            "bg-warning-1",
                            "border-0 rounded-1 p-1 px-2 fw-5 fs-14",
                          )}
                          style={{ width: "100%", color: "white" }}
                          onClick={() =>
                            handleStatusAcceptContract(
                              item.id,
                              item.price_service,
                              item.nvc_bophan,
                              item.id_detail,
                              item.contract_name
                            )
                          }
                        >
                          Chờ duyệt
                        </button>
                      ) : (
                        <button
                          className={cx(
                            "bg-success-1",
                            "border-0 rounded-1 p-1 px-2 fw-5 fs-14",
                          )}
                          style={{ width: "100%", color: "white" }}
                        >
                          Đã duyệt
                        </button>
                      ))}
                    {dataUser[0].list_hopdong !== 1 && (
                      item.status === 0 ? (
                        <button
                          className={cx(
                            "bg-warning-1",
                            "border-0 rounded-1 p-1 px-2 fw-5 fs-14",
                          )}
                          style={{ width: "100%", color: "white" }}
                        >
                          Chờ duyệt
                        </button>
                      ) : (
                        <button
                          className={cx(
                            "bg-success-1",
                            "border-0 rounded-1 p-1 px-2 fw-5 fs-14",
                          )}
                          style={{ width: "100%", color: "white" }}
                        >
                          Đã duyệt
                        </button>
                      )
                    )}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      {/* Modal detail project */}
      {detailProject && (
        <ModalReact
          modalTitle="Chi tiết hợp đồng"
          size="modal-md"
          showModal={detailProject}
          handleClose={handleCloseModalDetailProject}
          theme={
            <DetailContract
              socketio={socketio}
              dataUser={dataUser}
              objectDetail={objectDetail.current}
            />
          }
        />
      )}
    </>
  )
}


// Danh sách hợp đồng quảng cáo bất động sản
function ListContractAdsBds({
  socketio,
  dataUser,
  objectSearch,
}) {
  // Set biến.
  const [dataAdsBds, setDataAdsBds] = useState([]);
  const dataAdsBdsRef = useRef([])
  const [modalEditContractBDS, setModalEditContractBDS] = useState(false)
  const [loading, setLoading] = useState(true)
  let stt = 1

  // Get data contract bds
  useEffect(() => {
    const paramsGet = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        user_id: dataUser[0].id_user,
        list_hopdong: dataUser[0].list_hopdong,
        monthSearch: objectSearch.monthSearch,
        textSearch: objectSearch.textSearch,
      },
    };
    socketio.emit("client-get-data-bds-contract", paramsGet)
    socketio.on("server-get-data-bds-contract", (data) => {
      setDataAdsBds([...data])
      dataAdsBdsRef.current = [...data]
      setLoading(false)
    })
    return () => {
      socketio.off("client-get-data-bds-contract")
      socketio.off("server-get-data-bds-contract")
    }
  }, [objectSearch, modalEditContractBDS])

  // Quản lý edit contract bds.
  const dataEdit = useRef()
  const handleEditContractBDS = (object) => {
    setModalEditContractBDS(true);
    dataEdit.current = object
  }
  const handleCloseEditContractBDS = () => {
    setModalEditContractBDS(false)
    dataEdit.current = undefined
  }
  const handleEditContractBDSSuccess = (object) => {
    if (object.status) {
      // Update lại data.
      let dataUpdateContract = dataAdsBdsRef.current
      let index = dataUpdateContract.findIndex(
        (item) => item.id_hopdong === object.dataUpdate[0].id_hopdong,
      )
      dataUpdateContract[index] = object.dataUpdate[0]
      setDataAdsBds([...dataUpdateContract])
      dataAdsBdsRef.current = dataUpdateContract
      changeEditStatus.current = object
      setModalEditContractBDS(false)
    }
  }

  // Quả lý phê duyệt hợp đồng.
  const changeEditStatus = useRef()
  const handleEditStatusContractBds = (object) => {
    // Xử lý edit status.
    const checkEdit = window.confirm("Bạn muốn xác nhận hợp đồng này chứ ?");
    if (checkEdit) {
      const paramsStatus = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: {
          id_hopdong: object.id_hopdong,
          duyethopdong: 1
        }
      }
      socketio.emit("client-edit-row-duyethopdong-bds-contract", paramsStatus)
      socketio.on("server-edit-row-duyethopdong-bds-contract", (data) => {
        if (data.status === "success") {
          alert("Hợp đồng đã được duyệt.");
          // Update lại data.
          let dataUpdateStatus = dataAdsBdsRef.current
          let index = dataUpdateStatus.findIndex((item) => item.id_hopdong === data.data[0].id_hopdong)
          dataUpdateStatus[index] = data.data[0];
          setDataAdsBds([...dataUpdateStatus])
          dataAdsBdsRef.current = dataUpdateStatus
          changeEditStatus.current =object
        }
      })
    }
  }
  useEffect(() => {
    return () => {
      socketio.off("client-edit-row-duyethopdong-bds-contract")
      socketio.off("server-edit-row-duyethopdong-bds-contract")
    }

  }, [changeEditStatus.current])

  // Quả lý chi tiết hợp đồng.
  const [modalContractDetail, setModalContractDetail] = useState(false);
  const objectDetail = useRef()
  const handleContractDetail = (object) => {
    setModalContractDetail(true)
    objectDetail.current = {
      contract_name: "Quảng cáo bất động sản",
      id: object.id_hopdong
    }
  }
  const handleCloseContractDetail = () => {
    setModalContractDetail(false)
    objectDetail.current = undefined
  }


  if (loading) {
    return <LoadingSpinner />
  }
  if (dataAdsBds.length === 0) {
    return <div className="text-center fs-15 mt-3">Chưa có hợp đồng quảng cáo bất động sản nào !</div>
  }

  return (
    <div>
      <div className="table-responsive">
        <table className="table text-nowrap">
          {/* <thead>
            <tr>
              <th scope="row">Name</th>
              <th scope="row">Items Sold</th>
              <th scope="row">Total Value</th>
              <th scope="row">Action</th>
            </tr>
          </thead> */}
          <tbody>
            {dataAdsBds.map((item, index) => {
              // Set thời hạn.
              let dateStart = moment(item.date_start)
              let dateEnd = moment(item.date_end)
              let thoihan = dateEnd.diff(dateStart, "days")
              return (
                <tr key={index}>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="align-items-center">
                        <p className="mb-0 fw-semibold">
                          <span className="me-1 fs-16">{stt++}.</span>
                          {item.loaisanpham} {item.tuyenduong}
                          <i className="bi bi-patch-check-fill text-success ms-2"></i>
                        </p>
                        <span className="fs-14 text-muted">
                          {item.quanhuyen + " " + item.thanhpho}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="align-items-center">
                      <span className="fs-14 text-muted">Gói dịch vụ</span>
                      <p className="mb-0 fw-semibold">{item.loaitin}</p>
                    </div>
                  </td>
                  <td>
                    <div className="align-items-center">
                      <span className="fs-14 text-muted">Giá dịch vụ</span>
                      <p className="mb-0 fw-semibold">
                        {Number(item.giadichvu)
                          .toLocaleString()
                          .replace(/[.]/g, ",")}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div className="align-items-center">
                      <span className="fs-14 text-muted">Tên khách hàng</span>
                      <p className="mb-0 fw-semibold">{item.chusohuu}</p>
                    </div>
                  </td>
                  <td>
                    <div className="align-items-center">
                      <span className="fs-14 text-muted">Số điện thoại</span>
                      <p className="mb-0 fw-semibold">{item.sodienthoai}</p>
                    </div>
                  </td>
                  <td>
                    <div className="align-items-center">
                      <span className="fs-14 text-muted">Bắt đầu</span>
                      <p className="mb-0 fw-semibold">
                        {moment(item.date_start).format("DD/MM/YYYY")}
                      </p>
                    </div>
                  </td>

                  <td>
                    <div className="align-items-center">
                      <span className="fs-14 text-muted">Kết thúc</span>
                      <p className="mb-0 fw-semibold">
                        {moment(item.date_end).format("DD/MM/YYYY")}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div className="align-items-center">
                      <span className="fs-14 text-muted">Thời hạn</span>
                      <p className="mb-0 fw-semibold">{thoihan} ngày</p>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="lh-1">
                        <span className="avatar avatar-md avatar-rounded me-2">
                          <img src={"./" + item.nvc_avatar} alt="" />
                        </span>
                      </div>
                      <div className="align-items-center">
                        <p className="mb-0 fw-semibold">{item.nvc_name}</p>
                        <span className="fs-14 text-muted">Nhân viên chốt</span>
                      </div>
                    </div>
                  </td>

                  <td>
                    <button
                      className="badge bg-dark-transparent border-0 me-1"
                      onClick={() => handleContractDetail(item)}
                    >
                      Chi tiết
                    </button>
                    {item.duyethopdong === 0 && (
                      <>
                        {dataUser[0].list_hopdong === 1 ? (
                          <button
                            className="badge bg-warning border-0 me-1"
                            onClick={() => handleEditStatusContractBds(item)}
                          >
                            Chờ duyệt
                          </button>
                        ) : (
                          <button
                            className="badge bg-warning border-0 me-1"
                          >
                            Chờ duyệt
                          </button>
                        )}
                        {item.tacgia === dataUser[0].email && (
                          <>
                            <button
                              className="badge bg-info border-0"
                              onClick={() => handleEditContractBDS(item)}
                            >
                              Sửa
                            </button>
                          </>
                        )}
                      </>
                    )}
                    {item.duyethopdong === 1 && (
                      <>
                        <button className="badge bg-success border-0">
                          Đã duyệt
                        </button>
                        {dataUser[0].list_hopdong === 1 && (
                          <button
                            className="badge bg-info border-0 ms-1"
                            onClick={() => handleEditContractBDS(item)}
                          >
                            Sửa
                          </button>
                        )}
                      </>
                    )}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      {/* Modal edit contract bds */}
      {modalEditContractBDS && (
        <ModalReact
          modalTitle="Sửa thông tin hợp đồng"
          showModal={modalEditContractBDS}
          handleClose={handleCloseEditContractBDS}
          theme={
            <FormEditContract
              dataEdit={dataEdit.current}
              socketio={socketio}
              dataUser={dataUser}
              resultStatus={handleEditContractBDSSuccess}
            />
          }
        />
      )}
      {/* Chi tiết hợp đồng */}
      {modalContractDetail && (
        <ModalReact
          modalTitle="Chi tiết hợp đồng"
          showModal={modalContractDetail}
          handleClose={handleCloseContractDetail}
          theme={<DetailContract socketio={socketio} dataUser={dataUser} objectDetail={objectDetail.current}/>}
        />
      )}
    </div>
  )
}



// List contract tech.
function ListContractTech({ socketio, dataUser, objectSearch }) {
  const [dataContractTech, setDataContractTech] = useState([]);
  const dataContractTechRef = useRef([])
  const [loading, setLoading] = useState(true)
  let stt = 1

  // Get data contract tech.
  useEffect(() => {
    const paramsGet = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        user_id: dataUser[0].id_user,
        list_hopdong: dataUser[0].list_hopngdong,
        monthSearch: objectSearch.monthSearch,
        textSearch: objectSearch.textSearch
      }
      
    }
    socketio.emit("client-get-data-Search-role-contract-tech", paramsGet)
    socketio.on("server-get-data-Search-role-contract-tech", (data) => {
      setDataContractTech([...data]);
      dataContractTechRef.current = [...data]
      setLoading(false)
    })
    return () => {
      socketio.off("client-get-data-Search-role-contract-tech")
      socketio.off("server-get-data-Search-role-contract-tech")
    }
  }, [objectSearch]);

  // Quản lý chi tiết hợp đồng.
  const [detailContractTech, setDetailContractTech] = useState(false);
  const dataDetailContractTech = useRef()
  const handleDetailContractTech = (object) => {
    setDetailContractTech(true)
    dataDetailContractTech.current = { contract_name: "Công nghệ", id: object.id }
  }
  const handleCloseDetailContractTech = () => {
    setDetailContractTech(false)
    dataDetailContractTech.current = undefined
  }

  // Quản lý edit contract tech.
  const [editContractTech, setEditContractTech] = useState(false)
  const dataEditContractTech = useRef()
  const handleEditContractTech = (object) => {
    setEditContractTech(true)
    dataEditContractTech.current = object
  }
  const handleCloseEditContractTech = () => {
    setEditContractTech(false)
    dataEditContractTech.current = undefined
  }
  const handleEditContractTechSuccess = (object) => {
    if (object.status === "success") {
      let dataUpdateContractTech = dataContractTechRef.current;
      let index = dataUpdateContractTech.findIndex((item) => item.id === object.dataUpdate[0].id)
      dataUpdateContractTech[index] = object.dataUpdate[0];
      setDataContractTech([...dataUpdateContractTech])
      dataContractTechRef.current = [...dataUpdateContractTech]
      setEditContractTech(false)
    }
  }

  // Quản lý edit row status.
  const checkChangeEditStatus = useRef()
  const handleEditRowStatusContractTech = (id) => {
    const checkEditStatus = window.confirm("Bạn muốn duyệt hợp đồng này chứ ?");
    if (checkEditStatus) {
      const paramsEdit = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: {status: 1, id: id}
      }
      socketio.emit("client-edit-row-status-contract-tech", paramsEdit);
      socketio.on("server-edit-row-status-contract-tech", (data) => {
        if (data.status === "success") {
          alert("Duyệt thành công");
          let dataUpdateContractTech = dataContractTechRef.current
          let index = dataUpdateContractTech.findIndex(
            (item) => item.id === data.dataUpdate[0].id,
          )
          dataUpdateContractTech[index] = data.dataUpdate[0]
          setDataContractTech([...dataUpdateContractTech])
          dataContractTechRef.current = [...dataUpdateContractTech]
          checkChangeEditStatus.current = data.dataUpdate[0]
        }
      })
    }
  }
  useEffect(() => {
    return () => {
      socketio.off("client-edit-row-status-contract-tech")
      socketio.off("server-edit-row-status-contract-tech")
    }
  }, [checkChangeEditStatus.current])

  if (loading) {
    return <LoadingSpinner />
  }

  if (dataContractTech.length === 0) {
    return <div className="text-center mt-3 fs-15">Chưa có dữ liệu.</div>
  }


  return (
    <div>
      <div className="table-responsive">
        <table className="table text-nowrap">
          <tbody>
            {dataContractTech.map((item, index) => {
              // Set thời hạn.
              let dateStart = moment(item.date_start)
              let dateEnd = moment(item.date_end)
              let thoihan = dateEnd.diff(dateStart, "days")
              // Set thời gian bảo hành còn lại.
              let currentTime = moment()
              let baoHanh = dateEnd.diff(currentTime, "days")
              if (Number(baoHanh) < 0) {
                baoHanh = <span className="text-danger">0</span>
              } else {
                baoHanh = (
                  <span className="text-success fs-14">{baoHanh} ngày</span>
                )
              }
              return (
                <tr key={index}>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="align-items-center">
                        <p className="mb-0 fw-semibold">
                          <span className="me-1 fs-16">{stt++}.</span>
                          {item.contract_name}
                          <i className="bi bi-patch-check-fill text-success ms-2"></i>
                        </p>
                        <span className="fs-14 text-primary">
                          {item.contract_cate}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="align-items-center">
                      <span className="fs-14 text-muted">Giá dịch vụ</span>
                      <p className="mb-0 fw-semibold">
                        {Number(item.price_service)
                          .toLocaleString()
                          .replace(/[.]/g, ",")}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div className="align-items-center">
                      <span className="fs-14 text-muted">Tên khách hàng</span>
                      <p className="mb-0 fw-semibold">{item.customer_name}</p>
                    </div>
                  </td>
                  <td>
                    <div className="align-items-center">
                      <span className="fs-14 text-muted">Số điện thoại</span>
                      <p className="mb-0 fw-semibold">{item.customer_phone}</p>
                    </div>
                  </td>
                  <td>
                    <div className="align-items-center">
                      <span className="fs-14 text-muted">Bắt đầu</span>
                      <p className="mb-0 fw-semibold">
                        {moment(item.date_start).format("DD/MM/YYYY")}
                      </p>
                    </div>
                  </td>

                  <td>
                    <div className="align-items-center">
                      <span className="fs-14 text-muted">Kết thúc</span>
                      <p className="mb-0 fw-semibold">
                        {moment(item.date_end).format("DD/MM/YYYY")}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div className="align-items-center">
                      <span className="fs-14 text-muted">
                        Thời hạn bảo hành
                      </span>
                      <p className="mb-0 fw-semibold text-muted">
                        Còn lại <span>{baoHanh}</span>
                      </p>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="lh-1">
                        <span className="avatar avatar-md avatar-rounded me-2">
                          <img
                            src={"./" + item.avatar}
                            alt=""
                          />
                        </span>
                      </div>
                      <div className="align-items-center">
                        <p className="mb-0 fw-semibold">{item.hoten}</p>
                        <span className="fs-14 text-muted">Nhân viên chốt</span>
                      </div>
                    </div>
                  </td>

                  <td>
                    <button
                      className="badge bg-dark-transparent border-0 me-1"
                      onClick={() => handleDetailContractTech(item)}
                    >
                      Chi tiết
                    </button>
                    {item.status === 0 && (
                      <>
                        {item.user_id === dataUser[0].id_user && (
                          <>
                            <button
                              className="badge bg-warning border-0 me-1"
                              onClick={() =>
                                handleEditRowStatusContractTech(item.id)
                              }
                            >
                              Chờ duyệt
                            </button>
                            <button
                              className="badge bg-info border-0"
                              onClick={() => handleEditContractTech(item)}
                            >
                              Sửa
                            </button>
                          </>
                        )}
                      </>
                    )}
                    {item.status === 1 && (
                      <>
                        <button className="badge bg-success border-0">
                          Đã duyệt
                        </button>
                        {dataUser[0].list_hopdong === 1 && (
                          <button
                            className="badge bg-info border-0 ms-1"
                            onClick={() => handleEditContractTech(item)}
                          >
                            Sửa
                          </button>
                        )}
                      </>
                    )}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      {/* Modal detail */}
      {detailContractTech && (
        <ModalReact
          modalTitle="Chi tiết hợp đồng công nghệ"
          showModal={detailContractTech}
          handleClose={handleCloseDetailContractTech}
          theme={
            <DetailContract
              socketio={socketio}
              dataUser={dataUser}
              objectDetail={dataDetailContractTech.current}
            />
          }
        />
      )}

      {/* Modal Edit contract tech */}
      {editContractTech && (
        <ModalReact
          modalTitle="Sửa thông tin hợp đồng công nghệ"
          showModal={editContractTech}
          handleClose={handleCloseEditContractTech}
          theme={
            <FormEditContractTech
              socketio={socketio}
              dataUser={dataUser}
              dataEdit={dataEditContractTech.current}
              result={handleEditContractTechSuccess}
            />
          }
        />
      )}
    </div>
  )
}