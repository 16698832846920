import { useContext } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import {routerDataSale, routerJob, routerPublic, routerUser, routerUserAdmin, routerPathOther } from "./Router";
import "bootstrap/dist/css/bootstrap.min.css" // Import stylesheet
import "./assets/style/style.scss"
import "./assets/style/customer.scss"
import { isAuthen } from "./Router/CheckUser";
import "moment/locale/vi"
import "react-quill/dist/quill.snow.css"
import AppContext from "./handle-reuses/Contexts/CreateContexts";

function App() {
  const { dataUser } = useContext(AppContext);
  return (
    <Router>
      <Routes>
        {/* Router public */}
        {routerPublic.map((item, index) => {
          const PageLogin = item.component;
          return (
            <Route key={index} path={item.path} element={<PageLogin />} />
          )
        })}
        {/* Routes User */}
        {routerUser.map((item, index) => {
          const RouterUser = item.component
          return (
            <Route path={item.path} element={isAuthen ? <RouterUser /> : <Navigate to="/login" />} key={index} />
          )
        })}
        {/* Router user admin */}
        {
          routerUserAdmin.map((item, index) => {
            const RouterUserAdmin = item.component;
            return (
              <Route path={item.path} element={(isAuthen && dataUser[0].goidichvu_user === 1) ? <RouterUserAdmin /> : <Navigate to="/login" />} key={index}/>
            )
          })
        }
        {/* Router data sale */}
        {
          routerDataSale.map((item, index) => {
            const RouterDataSale = item.component
            return (
              <Route path={item.path} element={isAuthen ? <RouterDataSale /> : <Navigate to="/login" />} key={index} />
            )
          })
        }
        {/* Router job */}
        {
          routerJob.map((item, index) => {
            const RouterJob = item.component;
            return (
              <Route path={item.path} element={isAuthen ? <RouterJob /> : <Navigate to="/login" />} key={index}/>
            )
          })
        }
        {/* router other */}
        {routerPathOther.map((item, index) => {
          const RouterOther = item.component;
          return (
            <Route
              path={item.path}
              element={isAuthen ? <RouterOther /> : <Navigate to="/login" />}
              key={index}
            />
          )
        })}
      </Routes>
    </Router>
  );
}

export default App;
