import { IoFilterCircleOutline } from "react-icons/io5";
import SearchHorizontal from "../../../Components/Search/SearchHorizontal";
import ModalReact from "../../../Components/Modal/ModalReact";
import { useContext, useEffect, useRef, useState } from "react";
import SearchDataSale from "./SearchDataSale";
import { useLocation } from "react-router-dom";
import AppContext from "../../../handle-reuses/Contexts/CreateContexts";
import ButtonAddDataSale from "../PC/ButtonAddDataSale";

function SearchdFilterDataSale({resultSearchFast, resultSearchFilter}) {
  // Giá trị tìm kiếm
    const handleValueSearchFast = (keywords) => {
      resultSearchFast(keywords)
    }
  
    // Modal bộ lọc.
    const [filterDetail, setFilterDetail] = useState(false)
    const handleModelFilterDetail = () => {
        setFilterDetail(true)
    }
    const handleCloseModalFilterDetail = () => {
        setFilterDetail(false)
    }

  // Trả về data cho người dùng
  const handleResultDataFilter = (object) => {
    resultSearchFilter(object)
    setFilterDetail(false)
  }
  

    return (
      <>
        <div className="col-12 d-flex gap-3 d-flex justify-content-end">
          <div className="col-8 d-flex justify-content-end">
            <SearchHorizontal
              result={handleValueSearchFast}
              className="rounded-3"
              placeholder="Số điện thoại, họ tên, địa chỉ..."
            />
          </div>
          <div className="">
            <button
              type="button"
              className="border-0 rounded-3 fs-14 d-flex align-items-center op-6 px-2 fw-5 badge bg-primary-gradient"
              style={{ height: "40px", color: "white" }}
              onClick={handleModelFilterDetail}
            >
              <IoFilterCircleOutline className="me-1 fs-21" />
              <span className="me-1">Bộ lọc</span>
            </button>
          </div>
          <div className="d-flex justify-content-end">
            {/* Button add data */}
            <ButtonAddDataSale />
          </div>
        </div>
        {/* Modal bộ lọc */}
        <ModalReact
          modalTitle="Bộ lọc chi tiết"
          showModal={filterDetail}
          handleClose={handleCloseModalFilterDetail}
          theme={<SearchDataSale resultData={handleResultDataFilter} />}
        />
      </>
    )
}
export default SearchdFilterDataSale