import { useContext, useEffect, useRef, useState } from "react"
import AppContext from "../../../../handle-reuses/Contexts/CreateContexts"
import moment from "moment";
import CheckPCMobile from "../../../../handle-reuses/reuses/check.pc.mobile";
import ListContractPC from "./ListContractPC";

function ListContract({ active }) {
  const { socketio } = useContext(AppContext);
  const limit = 50;
  const page = useRef(1);
  const [dataCustomer, setDataCustomer] = useState([])
  const loading = useRef(false);
  const isLoading = useRef(false)
  // Get data.
  const handleGetDataContracts = (limit_, page_) => {
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        limit: limit_,
        offset: (page_ - 1) * limit_
      }
    }
    socketio.emit("client-get-list-data-contract-view360", params)
  }
  useEffect(() => {
    handleGetDataContracts(limit, page.current)
    return () => {
      socketio.off("client-get-list-data-contract-view360")
    }
  }, [])
  // Nhận data.
  useEffect(() => {
    socketio.on("server-get-list-data-contract-view360", (data) => {
      setDataCustomer((old) => [...old, ...data])
      // Kiểm tra nếu lấy hết dữ liệu
      if (limit > data.length) {
        isLoading.current = true
      }
      // Xác định đã lấy dữ liệu
      loading.current = true;

    })
    return () => {
      socketio.off("server-get-list-data-contract-view360")
    }
  }, [])


  // Quản lý get data revenue of user.
  const [dataRevenue, setDataRevenue] = useState([])
  const [dataRevenueYear, setDataRevenueYear] = useState([])
  const [optionUser, setOptionUser] = useState([])
  const handleGetDataRevenueOfUser = (monthYear, userid) => {
    // Doanh thu theo tháng
    const paramsRevenueMonth = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        month: monthYear,
        userid: userid
      }
    };
    socketio.emit("client-get-data-revenue-contract-view360-month", paramsRevenueMonth)
    // Doanh thu theo năm
    const paramsRevenueYear = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        year: moment(monthYear).format("YYYY"),
        userid: userid,
      },
    };
    socketio.emit("client-get-data-revenue-contract-view360-year", paramsRevenueYear)

    // Danh sách user.
    const paramsUser = {
      iden: process.env.REACT_APP_IDEN_DASH
    }
    socketio.emit("client-get-list-user", paramsUser)
  }
  useEffect(() => {
    const currentMonth = moment().format("YYYY-MM")
    handleGetDataRevenueOfUser(currentMonth, "")
    return () => {
      socketio.off("client-get-data-revenue-contract-view360-month")
      socketio.off("client-get-data-revenue-contract-view360-year")
      socketio.off("client-get-list-user")
    }
  }, [])
  useEffect(() => {
    // Doanh thu theo tháng
    socketio.on("server-get-data-revenue-contract-view360-month", (data) => {
      setDataRevenue(data)
    })
    // Doanh thu theo năm.
    socketio.on("server-get-data-revenue-contract-view360-year", (data) => {
      setDataRevenueYear(data)
    })
    // List user.
    socketio.on("server-get-list-user", (data) => {
      setOptionUser(data)
    })
    return () => {
      socketio.off("server-get-data-revenue-contract-view360-month")
      socketio.off("server-get-data-revenue-contract-view360-year")
      socketio.off("server-get-list-user")
    }
  }, [])



  // Quản lý search data.
  const checkSearch = useRef(false)
  const handleValueSearchData = (object) => {
    // Danh sách khách hàng
    checkSearch.current = true
    const paramsSearch = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: object
    }
    
    // Thống kê doanh thu.
    if (object.search_name === "" && object.search_text === "" && object.search_date === "") {
      // Reset lại ban đầu
      // page.current = 1
      setDataCustomer([])
      handleGetDataContracts(limit, 1)
      handleGetDataRevenueOfUser(moment().format("YYYY-MM"), "")
    } else {
      socketio.emit("client-search-data-contract-view360", paramsSearch)
      handleGetDataRevenueOfUser(object.search_date, object.search_name)
    }
  }
  useEffect(() => {
    socketio.on("server-search-data-contract-view360", (data) => {
      if (data.length > 0) {
        setDataCustomer(data)
      }
    })

    return () => {
      socketio.off("client-search-data-contract-view360")
      socketio.off("server-search-data-contract-view360")
    }
  }, [checkSearch.current])


  // Kiểm tra có sự thay đổi nào từ user không.
  const handleCheckChange = (status_) => {
    if (status_) {
      // Cập nhật lại dữ liệu.
      setDataCustomer([])
      handleGetDataContracts(limit, page.current)
    }
  }

  

  // Kiểm tra kết nối.
  if (!loading.current) {
    return (
      <div
        className="main-content app-content text-center"
        style={{ marginInlineStart: active && "6rem" }}
      >
        <div className="mt-5 fs-15">Đang tải...</div>
      </div>
    )
  }
  if (dataCustomer.length === 0) {
    return (
      <div
        className="main-content app-content text-center"
        style={{ marginInlineStart: active && "6rem" }}
      >
        <div className="mt-5 fs-15">Chưa có khách hàng.</div>
      </div>
    )
  }

    return (
      <CheckPCMobile>
        {(isMobile) =>
          isMobile ? (
            ""
          ) : (
            <ListContractPC
              active={active}
              dataCustomer={dataCustomer}
              dataRevenue={dataRevenue}
              dataRevenueYear={dataRevenueYear}
              optionUser={optionUser}
                result={handleValueSearchData}
                checkChange={handleCheckChange}
            />
          )
        }
      </CheckPCMobile>
    )
}
export default ListContract

