import classNames from "classnames/bind";
import { useState, useEffect, useRef, useContext } from "react"
import io from "socket.io-client"

import styles from "../Dashboard.module.scss"
import CheckPCMobile from "../../../handle-reuses/reuses/check.pc.mobile"
import ComponentTopPC from "./Pc/ComponentTopPC";
import ComponentTopMobile from "./Mobile/ComponentTopMobile";
import PageProductMobile from "./Mobile/PageProductMobile"
import PageProductPC from "./Pc/PageProductPc";
import AppContext from "../../../handle-reuses/Contexts/CreateContexts";
const cx = classNames.bind(styles);

function SanPham({active}) {
  const { socketioBDS} = useContext(AppContext);
    const [dataEstates, setDataEstates] = useState([])
    const [loading, setLoading] = useState(true)
    const limit = 12;
    const page = useRef(1)
    const overPage = useRef(false)
    // Quản lý get data.
    useEffect(() => {
        // data estate.
      const params = {
        iden: process.env.REACT_APP_IDEN_BDSVIEW,
        object: {
          quantity: limit,
          offset: (page.current - 1) * limit
        }
      };
        socketioBDS.emit("client-list-product-all", params)
        return () => {
            socketioBDS.off("client-list-product-all")
        }
    }, [])

    // Quản lý sự kiện scroll.
    useEffect(() => {
        // Sự kiện scroll auto data.
        const handleScrollProducts = () => {
            // tổng chiều dài của window.
            const totalHeight = window.innerHeight;
            const scrollY = window.scrollY;
            if (scrollY >= totalHeight && !overPage.current) {
                page.current = page.current + 1
                const params = {
                  iden: process.env.REACT_APP_IDEN_BDSVIEW,
                  object: {
                    quantity: limit,
                    offset: (page.current - 1) * limit,
                  },
                }
                socketioBDS.emit("client-list-product-all", params)
            }
        }
        // Sự kiện.
        window.addEventListener("scroll", handleScrollProducts)

        return () => {
            window.removeEventListener("scroll", handleScrollProducts)
            socketioBDS.off("client-list-product-all")
        }

    }, []);

    // Nút xem thêm chỉ danh cho pc.
    const handleloadMorePC = () => {
        page.current = page.current + 1
        const params = {
          iden: process.env.REACT_APP_IDEN_BDSVIEW,
          object: {
            quantity: limit,
            offset: (page.current - 1) * limit,
          },
        }
        socketioBDS.emit("client-list-product-all", params)
    }

    // Quản lý nhận data.
    useEffect(() => {
        // Data estate.
        socketioBDS.on("server-list-product-all", (data) => {
            setDataEstates((old)=> [...old, ...data])
            setLoading(false)
            if (data.length < limit) {
                overPage.current = true
            }
        })
        return () => {
            socketioBDS.off("server-list-product-all")
        }
    }, [])
  
  // Kết quả search .
  const handleResultSearch = (result) => {
    setDataEstates(result)
  }

  // Trả lại trạng thái thay đổi khi xác nhận đã giao dịch...
  const handleStatusChange = (status_) => {
    if (status_) {
      page.current = 1
      setDataEstates([])
      const params = {
        iden: process.env.REACT_APP_IDEN_BDSVIEW,
        object: {
          quantity: limit,
          offset: (page.current - 1) * limit,
        },
      }
      socketioBDS.emit("client-list-product-all", params)
    }
  }
  useEffect(() => {
    return () => {
      socketioBDS.off("client-list-product-all")
    }
  }, [dataEstates])

    if (loading) {
        return (
          <div
            className="text-center main-content app-content"
            style={{ marginInlineStart: active && "6rem" }}
          >
            <div className="mt-3">Đang tải dữ liệu...</div>
          </div>
        )
    }

  return (
    <div
      className="main-content app-content"
      style={{ marginInlineStart: active && "6rem" }}
    >
      <div className="container-fluid">
        <div className="main-mail-container p-2">
          {/* Content */}
          <CheckPCMobile>
            {(isMobile) =>
              isMobile ? (
                <>
                  <ComponentTopMobile
                    resultSearchData={handleResultSearch}
                    socketio={socketioBDS}
                  />
                  <PageProductMobile
                    loading={loading}
                    dataEstates={dataEstates}
                    socketio={socketioBDS}
                    result={handleStatusChange}
                  />
                </>
              ) : (
                <>
                  <ComponentTopPC
                    resultSearchData={handleResultSearch}
                    socketio={socketioBDS}
                  />
                  <PageProductPC
                    loading={loading}
                    dataEstates={dataEstates}
                    socketio={socketioBDS}
                    result={handleStatusChange}
                  />
                  {/* Nút xem thêm trên pc */}
                  {/* <div className="text-center">
                    <button
                      className="btn btn-outline-dark"
                      onClick={handleloadMorePC}
                    >
                      Xem thêm
                    </button>
                  </div> */}
                </>
              )
            }
          </CheckPCMobile>
        </div>
      </div>
    </div>
  )
}

export default SanPham
