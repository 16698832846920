import { useLayoutEffect, useRef, useState } from "react"
import LoadingSpinner from "../../../../../Components/Loading/LoadingSpinner"
import moment from "moment"
import CheckPCMobile from "../../../../../handle-reuses/reuses/check.pc.mobile"
import OverviewReportPC from "../OverviewReportPC"
import OverviewReportMobile from "../OverviewReportMobile"

function DataReport({ socketio, dataUser, objectSearch }) {
    const totalCountContract = useRef(0)
    const totalRevenueMonth = useRef(0)
    const totalExpenseMonth = useRef(0)
    const totalHoahong = useRef(0)
    const [listContractMonth, setListContractMonth] = useState([])
    const [dataCateRevenueMonth, setDataCateRevenueMonth] = useState([])
    const [dataCateExpenseMonth, setDataCateExpenseMonth] = useState([])
    const [dataRevenueYear, setDataRevenueYear] = useState([])
    const [dataExpenseYear, setDataExpenseYear] = useState([])
    const [dataTaisan, setDataTaisan] = useState([])
    const [dataTotalRevenue, setDataTotalRevenue] = useState([])
    const [dataTotalExpense, setDataTotalExpense] = useState([])
    const [dataCateRevenueYear, setDataCateRevenueYear] = useState([])
    const [dataCateExpenseYear, setDataCateExpenseYear] = useState([])
    const [loading, setLoading] = useState(true)

    // Quan ly get data
    useLayoutEffect(() => {
      if (objectSearch) {
        let params = {
          iden: process.env.REACT_APP_IDEN_DASH,
          object: {
            monthSearch: objectSearch.monthSearch,
            yearSearch: moment(objectSearch.monthSearch).format("YYYY"),
          },
        }
        // Get data doanh thu theo thang.
        socketio.emit(
          "client-get-data-count-revenue-month-contract-report",
          params,
        )
        socketio.on(
          "server-get-data-count-revenue-month-contract-report",
          (data) => {
            if (data.length > 0) {
              totalCountContract.current = data[0].total_contract
              totalRevenueMonth.current = data[0].total_revenue
              totalHoahong.current = data[0].hoahong
              // setDataRevenueMonth([...data])
            }
          },
        )

        // Get data chi phi theo thang.
        socketio.emit(
          "client-get-data-total-expense-month-ketoan-chiphi-report",
          params,
        )
        socketio.on(
          "server-get-data-total-expense-month-ketoan-chiphi-report",
          (data) => {
            if (data.length > 0) {
              totalExpenseMonth.current = data[0].total_expense
              // setDataExpenseMonth([...data])
            }
          },
        )

        // Get list contract month.
        socketio.emit("client-list-month-contract-report", params)
        socketio.on("server-list-month-contract-report", (data) => {
          if (data.length > 0) {
            setListContractMonth([...data])
          }
        })

        // Doanh thu theo danh muc theo thang.
        socketio.emit("client-cate-revenue-month-contract-report", params)
        socketio.on("server-cate-revenue-month-contract-report", (data) => {
          if (data.length > 0) {
            setDataCateRevenueMonth([...data])
          }
        })

        // Chi phí theo danh mục theo tháng.
        socketio.emit(
          "client-get-data-total-expense-cate-month-ketoan-chiphi-report",
          params,
        )
        socketio.on(
          "server-get-data-total-expense-cate-month-ketoan-chiphi-report",
          (data) => {
            if (data.length > 0) {
              setDataCateExpenseMonth([...data])
            }
          },
        )

        // Doanh thu theo năm.
        socketio.emit(
          "client-get-data-count-revenue-year-contract-report",
          params,
        )
        socketio.on(
          "server-get-data-count-revenue-year-contract-report",
          (data) => {
            setDataRevenueYear([...data])
          },
        )

        // Chi phí theo năm.
        socketio.emit(
          "client-get-data-total-expense-year-ketoan-chiphi-report",
          params,
        )
        socketio.on(
          "server-get-data-total-expense-year-ketoan-chiphi-report",
          (data) => {
            if (data.length > 0) {
              setDataExpenseYear([...data])
            }
          },
        )

        // Tổng tài sản.
        socketio.emit("client-total-taisan-ketoan-report", params)
        socketio.on("server-total-taisan-ketoan-report", (data) => {
          if (data.length > 0) {
            setDataTaisan([...data])
          }
        })

        // Tổng doanh thu từ trước đến nay.
        socketio.emit("client-total-revenue-contract-report", params)
        socketio.on("server-total-revenue-contract-report", (data) => {
          if (data.length > 0) {
            setDataTotalRevenue([...data])
          }
        })

        // Tổng chi phí từ trước đến nay.
        socketio.emit("client-total-expense-contract-report", params)
        socketio.on("server-total-expense-contract-report", (data) => {
          if (data.length > 0) {
            setDataTotalExpense([...data])
          }
        })

        // Doanh thu theo danh mục theo năm.
        socketio.emit("client-cate-revenue-year-contract-report", params)
        socketio.on("server-cate-revenue-year-contract-report", (data) => {
          setDataCateRevenueYear([...data])
        })

        // Chi phí theo danh mục theo năm.
        socketio.emit(
          "client-get-data-total-expense-cate-year-ketoan-chiphi-report",
          params,
        )
        socketio.on(
          "server-get-data-total-expense-cate-year-ketoan-chiphi-report",
          (data) => {
            setDataCateExpenseYear([...data])
            setLoading(false)
          },
        )
      }

      return () => {
        socketio.off("client-get-data-count-revenue-month-contract-report")
        socketio.off("server-get-data-count-revenue-month-contract-report")

        socketio.off("client-get-data-total-expense-month-ketoan-chiphi-report")
        socketio.off("server-get-data-total-expense-month-ketoan-chiphi-report")

        socketio.off("client-list-month-contract-report")
        socketio.off("server-list-month-contract-report")

        socketio.off("client-cate-revenue-month-contract-report")
        socketio.off("server-cate-revenue-month-contract-report")

        socketio.off(
          "client-get-data-total-expense-cate-month-ketoan-chiphi-report",
        )
        socketio.off(
          "server-get-data-total-expense-cate-month-ketoan-chiphi-report",
        )

        socketio.off("client-get-data-count-revenue-year-contract-report")
        socketio.off("server-get-data-count-revenue-year-contract-report")

        socketio.off("client-get-data-total-expense-year-ketoan-chiphi-report")
        socketio.off("server-get-data-total-expense-year-ketoan-chiphi-report")

        socketio.off("client-total-taisan-ketoan-report")
        socketio.off("server-total-taisan-ketoan-report")

        socketio.off("client-total-revenue-contract-report")
        socketio.off("server-total-revenue-contract-report")

        socketio.off("client-total-expense-contract-report")
        socketio.off("server-total-expense-contract-report")

        socketio.off("client-cate-revenue-year-contract-report")
        socketio.off("server-cate-revenue-year-contract-report")

        socketio.off(
          "client-get-data-total-expense-cate-year-ketoan-chiphi-report",
        )
        socketio.off(
          "server-get-data-total-expense-cate-year-ketoan-chiphi-report",
        )
      }
    }, [objectSearch])

    if (loading) {
      return <LoadingSpinner />
    }

    return (
      <CheckPCMobile>
        {(isMobile) =>
          !isMobile ? (
            <OverviewReportPC
              socketio={socketio}
              dataUser={dataUser}
              object={{
                totalContract: totalCountContract.current,
                totalRevenueMonth: totalRevenueMonth.current,
                totalExpenseMonth: totalExpenseMonth.current,
              }}
              listContractMonth={listContractMonth}
              dataCateRevenueMonth={dataCateRevenueMonth}
              dataCateExpenseMonth={dataCateExpenseMonth}
              dataTaisan={dataTaisan}
              dataTotalRevenue={dataTotalRevenue}
              dataTotalExpense={dataTotalExpense}
              dataRevenueYear={dataRevenueYear}
              dataExpenseYear={dataExpenseYear}
              dataCateRevenueYear={dataCateRevenueYear}
              dataCateExpenseYear={dataCateExpenseYear}
            />
          ) : (
            <OverviewReportMobile
              socketio={socketio}
              dataUser={dataUser}
              object={{
                totalContract: totalCountContract.current,
                totalRevenueMonth: totalRevenueMonth.current,
                totalExpenseMonth: totalExpenseMonth.current,
              }}
              listContractMonth={listContractMonth}
              dataCateRevenueMonth={dataCateRevenueMonth}
              dataCateExpenseMonth={dataCateExpenseMonth}
              dataTaisan={dataTaisan}
              dataTotalRevenue={dataTotalRevenue}
              dataTotalExpense={dataTotalExpense}
              dataRevenueYear={dataRevenueYear}
              dataExpenseYear={dataExpenseYear}
              dataCateRevenueYear={dataCateRevenueYear}
              dataCateExpenseYear={dataCateExpenseYear}
            />
          )
        }
      </CheckPCMobile>
    )
}

export default DataReport