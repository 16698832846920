import GoBackPage from "../Goback"
import SidebarMenuMobile from "../Sidebar/SidebarMenuMobile"
import AvatarUser from "../Info/AvatarUser"
import { Notification } from "../Notification"

function HeaderDashboardMobile({hiden}) {

  return (
    <header className="app-header" style={{ background: "#ffd4b9", borderBlockEnd: "0px solid" }}>
      {/* <!-- Start::main-header-container --> */}
      <div className="main-header-container container-fluid">
        {/* <!-- Start::header-content-left --> */}
        <div className="header-content-left">
          {/* <!-- Start::header-element --> */}
          <div className="header-element align-items-center">
            <div className="horizontal-logo">
              <GoBackPage />
            </div>
          </div>
          {/* <!-- End::header-element --> */}
        </div>
        {/* <!-- End::header-content-left --> */}

        {/* <!-- Start::header-content-right --> */}
        <div className="header-content-right gap-2">
          {/* <!-- Start::header-element --> */}
          {hiden === "hiden" ? (
            ""
          ) : (
            <div className="header-element header-menu">
              <SidebarMenuMobile size="fs-29" />
            </div>
          )}
          {/* <!-- End::header-element --> */}

          {/* <!-- Start::header-element --> */}
          <div className="header-element">
            {/* <!-- Start::header-link|dropdown-toggle --> */}
            <Notification sizeIcon="fs-29" />
            {/* <!-- End::header-link|dropdown-toggle --> */}
          </div>
          {/* <!-- End::header-element --> */}

          {/* <!-- Start::avartar user --> */}
          <AvatarUser />
          {/* <!-- End::avartar user --> */}
        </div>
        {/* <!-- End::header-content-right --> */}
      </div>
      {/* <!-- End::main-header-container --> */}
    </header>
  )
}

export default HeaderDashboardMobile


