
import classNames from "classnames/bind"
import io from "socket.io-client"
import { useEffect, useRef } from "react"
import { useState } from "react"
import { BsDot } from "react-icons/bs"
import moment from "moment"
import { Link } from "react-router-dom"

import styles from "./FilterEstate.module.scss"
import { LoadingPlace, LoadingSpinner } from "../Loading"
import {
  handleFormatPriceInput,
  handleFormatPrice,
  TextLimit,
  deleteAccentedText,
} from "../../handle-reuses/reuses"
import { images } from "../../assets/images"
import CheckPCMobile from "../../handle-reuses/reuses/check.pc.mobile"

const cx = classNames.bind(styles)

function FilterEstate() {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const handleFilterData = (dataEstate) => {
    setData(dataEstate)
    setLoading(true)
  }

  useEffect(() => {
    setLoading(false)
  }, [data])

  return (
    <div className={cx("row g-3")}>
      <div className={cx("wrapper_left", "col-xl-6 col-md-12")}>
        <FormFilterEstate result={handleFilterData} />
      </div>
      <div className={cx("wrapper_right", "col-xl-6 col-md-12")}>
        {!loading ? (
          <EstateResultLists data={data} />
        ) : (
          <div className="text-center">Hãy tìm kiếm theo yêu cầu của bạn.</div>
        )}
      </div>
    </div>
  )
}
export default FilterEstate

// Danh sanh kết quả cho bộ lọc.
function EstateResultLists({ data }) {
  const [object, setObject] = useState({
    loading: true,
    arr: data,
  })
  const socket = useRef()

  useEffect(() => {
    setTimeout(() => {
      setObject({
        loading: false,
        arr: data,
      })
    }, 500)
  }, [])

  // Khởi tạo socket
  useEffect(() => {
    socket.current = io(process.env.REACT_APP_DOMAIN_BDSVIEW360)
  }, [])
  // Quản lý click chi tiết
  const handleOpenDetail = (id_, countView) => {
    // window.open(path)
    const params = {
      countview: Number(countView) + 1,
      id: id_,
    }
    socket.current.emit("client-click-views", params)
  }
  // count view.
  const handleCountview = (id_, countView) => {
    const params = {
      countview: Number(countView) + 1,
      id: id_,
    }
    socket.current.emit("client-click-views", params)
  }

  if (object.loading) {
    return <LoadingSpinner />
  }
  if (object.arr.length === 0) {
    return (
      <div className="text-center fs-14">
        Rất xin lỗi hiện tại tôi không tìm thấy yêu cầu phù hợp.
      </div>
    )
  }

  return (
    <div className={cx("filterResult")}>
      <div className={cx("text-center mb-2 fs-15")}>
        Tôi đã tìm thấy {object.arr.length} kết quả phù hợp{" "}
      </div>
      <div className="mb-3 border-top border-block-start-dashed"></div>
      {object.arr.map((item) => {console.log(item.images)
        let listImages = []
        if (item.images) {
          listImages = item.images.split(",")
        }
        // format url detail.
        const formatCateUrl = deleteAccentedText(item.cate).toLowerCase()
        const cate = formatCateUrl.replace(/[ ]/g, "-")
        const formatAddress = deleteAccentedText(item.address).toLowerCase()
        const address = formatAddress.replace(/[ ]/g, "-")

        return (
          <div key={item.id} className={cx("row mb-3")}>
            <div className={cx("me-2 col-xl-4 col-12", "position-relative", "wrapper_images")}>
              <span className={cx("position-absolute", "cateEstate")}>
                {item.cate}
              </span>
              <img
                src={
                  listImages.length > 0
                    ? "https://batdongsan.view360.vn/" + listImages[0]
                    : images.noImagePro
                }
                alt={item.title}
                width="100%"
                className={cx("rounded-3")}
              />
            </div>
            <Link
              to={
                "https://batdongsan.view360.vn/" +
                cate +
                "-" +
                address +
                "/" +
                item.alias +
                "-pr" +
                item.id
              }
              onClick={() => handleOpenDetail(item.id, item.countview)}
              className="cursor-pointer col-xl-7 col-12"
              onMouseEnter={() => handleCountview(item.id, item.countview)}
            >
              <h6 className="fs-17">
                <TextLimit text={item.title} number={59} />
              </h6>
              <p className={cx("mb-0 text-muted")}>
                {item.form_cate}
                <BsDot />
                {item.districts}
                <BsDot />
                {item.city}
              </p>
              <p className={cx("mb-0")}>
                <span className={cx("text-muted")}>
                  Hướng: {item.direction}
                </span>
                <BsDot />
                <span className={cx("fw-6")}>
                  {item.price.length > 3
                    ? handleFormatPrice(Number(item.price))
                    : "Liên hệ"}
                </span>
              </p>
              <p className={cx("mb-0 text-muted")}>
                Cập nhật {moment(item.update_time).locale("vi").fromNow()}
              </p>
            </Link>
          </div>
        )
      })}
    </div>
  )
}

// form filter.
function FormFilterEstate({ result }) {
  const socket = useRef()
  const [objectInput, setObjectInput] = useState({
    address: "",
    cate: "",
    type: "",
    district: "",
    province: "",
    direction: "",
    priceMin: "",
    priceMax: "",
  })
  const [dataCate, setDataCate] = useState([])
  const [dataType, setDataType] = useState([])
  const [dataDistrict, setDataDistrict] = useState([])
  // Khởi tạo socket
  useEffect(() => {
    socket.current = io(process.env.REACT_APP_DOMAIN_BDSVIEW360)

    return () => {
      socket.current.disconnect()
    }
  }, [])

  // address.
  const handleValueAddress = (e) => {
    setObjectInput({
      address: e.target.value,
      cate: objectInput.cate,
      type: objectInput.type,
      district: objectInput.district,
      province: objectInput.province,
      direction: objectInput.direction,
      priceMin: objectInput.priceMin,
      priceMax: objectInput.priceMax,
    })
  }
  // cate
  const handleValueCate = (e) => {
    setObjectInput({
      address: objectInput.address,
      cate: e.target.value,
      type: objectInput.type,
      district: objectInput.district,
      province: objectInput.province,
      direction: objectInput.direction,
      priceMin: objectInput.priceMin,
      priceMax: objectInput.priceMax,
    })
  }
  // type
  const handleValueType = (e) => {
    setObjectInput({
      address: objectInput.address,
      cate: objectInput.cate,
      type: e.target.value,
      district: objectInput.district,
      province: objectInput.province,
      direction: objectInput.direction,
      priceMin: objectInput.priceMin,
      priceMax: objectInput.priceMax,
    })
  }

  // Gợi ý district.
  const [suggestDis, setSuggestDis] = useState("")
  const handleSuggestDistrict = (e) => {
    setSuggestDis(e.target.value)
    socket.current.emit("client-location-district", e.target.value)
    setObjectInput({
      address: objectInput.address,
      cate: objectInput.cate,
      type: objectInput.type,
      district: e.target.value,
      province: objectInput.province,
      direction: objectInput.direction,
      priceMin: objectInput.priceMin,
      priceMax: objectInput.priceMax,
    })
  }
  const resultSuggestDis = (result) => {
    setSuggestDis(result)
    setObjectInput({
      address: objectInput.address,
      cate: objectInput.cate,
      type: objectInput.type,
      district: result,
      province: objectInput.province,
      direction: objectInput.direction,
      priceMin: objectInput.priceMin,
      priceMax: objectInput.priceMax,
    })
  }

  // Gợi ý city
  const [suggestPro, setSuggestPro] = useState("")
  const [dataProvince, setDataProvince] = useState([])
  const handleSuggestProvince = (e) => {
    setSuggestPro(e.target.value)
    socket.current.emit("client-location-province", e.target.value)
    setObjectInput({
      address: objectInput.address,
      cate: objectInput.cate,
      type: objectInput.type,
      district: objectInput.district,
      province: e.target.value,
      direction: objectInput.direction,
      priceMin: objectInput.priceMin,
      priceMax: objectInput.priceMax,
    })
  }
  const resultSuggestPro = (result) => {
    setSuggestPro(result)
    setObjectInput({
      address: objectInput.address,
      cate: objectInput.cate,
      type: objectInput.type,
      district: objectInput.district,
      province: result,
      direction: objectInput.direction,
      priceMin: objectInput.priceMin,
      priceMax: objectInput.priceMax,
    })
  }

  // direction (hướng nhà)
  const handleValueDirection = (e) => {
    setObjectInput({
      address: objectInput.address,
      cate: objectInput.cate,
      type: objectInput.type,
      district: objectInput.district,
      province: objectInput.province,
      direction: e.target.value,
      priceMin: objectInput.priceMin,
      priceMax: objectInput.priceMax,
    })
  }

  // Gợi ý price min.
  const [priceMin, setPriceMin] = useState(0)
  const [checkInput, setCheckInput] = useState(false)
  const handleSuggestPriceMin = (e) => {
    const formatPrice = handleFormatPriceInput(e.target.value)
    setPriceMin(formatPrice)
    setCheckInput(true)
    setObjectInput({
      address: objectInput.address,
      cate: objectInput.cate,
      type: objectInput.type,
      district: objectInput.district,
      province: objectInput.province,
      direction: objectInput.direction,
      priceMin: e.target.value,
      priceMax: objectInput.priceMax,
    })
  }
  const resultSuggestPriceMin = (result) => {
    setPriceMin(result)
    setObjectInput({
      address: objectInput.address,
      cate: objectInput.cate,
      type: objectInput.type,
      district: objectInput.district,
      province: objectInput.province,
      direction: objectInput.direction,
      priceMin: result,
      priceMax: objectInput.priceMax,
    })
  }
  // gợi ý price max.
  const [priceMax, setPriceMax] = useState(0)
  const [checkInputMax, setCheckInputMax] = useState(false)
  const handleSuggestPriceMax = (e) => {
    const formatPrice = handleFormatPriceInput(e.target.value)
    setPriceMax(formatPrice)
    setCheckInputMax(true)
    setObjectInput({
      address: objectInput.address,
      cate: objectInput.cate,
      type: objectInput.type,
      district: objectInput.district,
      province: objectInput.province,
      direction: objectInput.direction,
      priceMin: objectInput.priceMin,
      priceMax: e.target.value,
    })
  }
  const resultSuggestPriceMax = (result) => {
    setPriceMax(result)
    setObjectInput({
      address: objectInput.address,
      cate: objectInput.cate,
      type: objectInput.type,
      district: objectInput.district,
      province: objectInput.province,
      direction: objectInput.direction,
      priceMin: objectInput.priceMin,
      priceMax: result,
    })
  }

  // quản lý nắng nghe từ server.
  useEffect(() => {
    // Data cate
    socket.current.on("server-data-cate", (data) => {
      setDataCate(data)
    })
    // data type
    socket.current.on("server-data-type", (data) => {
      setDataType(data)
    })
    // Data district.
    socket.current.on("server-location-district", (data) => {
      setDataDistrict(data)
    })
    // Data province.
    socket.current.on("server-location-province", (data) => {
      setDataProvince(data)
    })

    return () => {
      socket.current.off("server-data-cate")
      socket.current.off("server-data-type")
      socket.current.off("server-location-district")
      socket.current.off("server-location-province")
    }
  }, [])

  // Submit.
  const [updateObject, setUpdateObject] = useState()
  const handleSubmit = (e) => {
    e.preventDefault()
    // gửi lên server.
    socket.current.emit("client-filter-estate", objectInput.address)
    setUpdateObject(objectInput)
    // thống kê search keywords.
    const params = {
      cate: objectInput.cate,
      title: objectInput.address,
      districts: objectInput.district,
      city: objectInput.province,
    }
    socket.current.emit("client-add-all-search-keyword", params)
  }
  useEffect(() => {
    // Result data estate.
    socket.current.on("server-filter-estate", (data) => {
      // xử lý lọc dữ liệu.
      if (updateObject) {
        const resultDataEstate = handleFilterDataEstate(updateObject, data)
        result(resultDataEstate)
      }
    })

    return () => {
      socket.current.off("server-filter-estate")
    }
  }, [updateObject])

  if (dataCate.length === 0) {
    return <LoadingPlace />
  }

  return (
    <form onSubmit={handleSubmit}>
      {/* Tên đường phố, dự án */}
      <div className="col-xl-12 col-md-12">
        <div className="form-floating mb-3 fs-15">
          <input
            type="text"
            value={objectInput.address}
            className="form-control rounded-3 fs-16"
            placeholder="Tên đường phố hoặc dự án"
            onChange={handleValueAddress}
          />
          <label>Đường phố/Dự án</label>
        </div>
      </div>
      {/* Danh mục & Loại hình */}
      <div className="row g-2">
        <div className="col-md">
          <div className="form-floating fs-15">
            <select
              className="form-select rounded-3 fs-15"
              value={objectInput.cate}
              onChange={handleValueCate}
            >
              <option value="">Lựa chọn danh mục</option>
              {dataCate.map((item) => {
                return (
                  <option value={item.title} key={item.id}>
                    {item.title}
                  </option>
                )
              })}
            </select>
            <label>Danh mục</label>
          </div>
        </div>
        <div className="col-md">
          <div className="form-floating fs-15">
            <select
              className="form-select rounded-3 fs-15"
              value={objectInput.type}
              onChange={handleValueType}
            >
              <option value="">Lựa chọn loại hình</option>
              {dataType.map((item) => {
                return (
                  <option value={item.title} key={item.id}>
                    {item.title}
                  </option>
                )
              })}
            </select>
            <label>Loại hình</label>
          </div>
        </div>
      </div>
      {/* Quận huyện & Thành phố */}
      <div className="row g-2 mt-2">
        <div className="col-md">
          <div className="form-floating fs-15">
            <input
              type="text"
              value={suggestDis}
              className="form-control rounded-3 fs-16"
              placeholder="Chọn quận huyện"
              onChange={handleSuggestDistrict}
            />
            <label>Quận/Huyện</label>
          </div>
          {suggestDis.length > 0 && (
            <ListSuggestDistrict
              data={dataDistrict}
              result={resultSuggestDis}
            />
          )}
        </div>
        <div className="col-md">
          <div className="form-floating fs-15">
            <div className="form-floating fs-15">
              <input
                type="text"
                value={suggestPro}
                className="form-control rounded-3 fs-16"
                placeholder="Chọn thành phố"
                onChange={handleSuggestProvince}
              />
              <label>Thành phố</label>
            </div>
            {suggestPro.length > 0 && (
              <ListSuggestProvince
                data={dataProvince}
                result={resultSuggestPro}
              />
            )}
          </div>
        </div>
      </div>
      {/* Hướng nhà */}
      <div className="row g-2 mt-2">
        <div className="form-floating fs-15">
          <select
            className="form-select rounded-3 fs-15"
            value={objectInput.direction}
            onChange={handleValueDirection}
          >
            <option value="">Chọn hướng nhà</option>
            <option value="Đông">Đông</option>
            <option value="Nam">Nam</option>
            <option value="Bắc">Bắc</option>
            <option value="Đông nam">Đông nam</option>
            <option value="Đông bắc">Đông bắc</option>
            <option value="Tây bắc">Tây bắc</option>
            <option value="Tây nam">Tây nam</option>
            <option value="Tây">Tây</option>
          </select>
          <label>Hướng nhà</label>
        </div>
      </div>
      {/* Giá thấp nhất & Giá cao nhất */}
      <div className="row g-2 mt-2">
        <div className="col-md">
          <div className="form-floating fs-15">
            <input
              type="text"
              value={priceMin}
              className="form-control rounded-3 fs-16"
              placeholder="Chọn giá tối thiểu"
              onChange={handleSuggestPriceMin}
            />
            <label>
              Giá tối thiểu{" "}
              <span className={cx("fw-6 text-danger")}>
                {priceMin.length > 1 &&
                  handleFormatPrice(
                    Number(priceMin.toLocaleString().replace(/[,]/g, "")),
                  )}
              </span>
            </label>
            {priceMin.length > 1 && (
              <ListSuggestPriceMin
                value={priceMin}
                result={resultSuggestPriceMin}
                checkInput={checkInput}
              />
            )}
          </div>
        </div>
        <div className="col-md">
          <div className="form-floating fs-15">
            <input
              type="text"
              value={priceMax}
              className="form-control rounded-3 fs-16"
              placeholder="Chọn giá tối đa"
              onChange={handleSuggestPriceMax}
            />
            <label>
              Giá tối đa{" "}
              <span className={cx("fw-6 text-danger")}>
                {priceMax.length > 1 &&
                  handleFormatPrice(
                    Number(priceMax.toLocaleString().replace(/[,]/g, "")),
                  )}
              </span>
            </label>
            {priceMax.length > 1 && (
              <ListSuggestPriceMin
                value={priceMax}
                result={resultSuggestPriceMax}
                checkInput={checkInputMax}
              />
            )}
          </div>
        </div>
      </div>
      {/* Button */}
      <div className="px-4 mt-3 py-3 border-top border-block-start-dashed d-sm-flex justify-content-end">
        <button className="btn btn-primary-light m-1" type="submit">
          Hiển thị kết quả
        </button>
      </div>
    </form>
  )
}

// Danh sách gợi ý district.
function ListSuggestDistrict({ data, result }) {
  const [active, setActive] = useState("")

  const resultSuggestDis = (key) => {
    result(key)
    // Ẩn gợi ý.
    setActive("none")
  }
  useEffect(() => {
    if (data.length > 0) {
      setActive("block")
    } else {
      setActive("none")
    }
  }, [data])
  return (
    <div className="position-relative col-xl-12" style={{ display: active }}>
      <div className={cx("bg-tippy", "suggest", "suggestDis")}>
        <ul>
          {data.map((item) => {
            return (
              <li
                className="fs-15 py-1"
                key={item.id}
                onClick={() => resultSuggestDis(item.title)}
              >
                {item.title}
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

// Danh sách gợi ý city.
function ListSuggestProvince({ data, result }) {
  const [active, setActive] = useState("")

  const resultSuggestPro = (key) => {
    result(key)
    // Ẩn gợi ý.
    setActive("none")
  }
  useEffect(() => {
    if (data.length > 0) {
      setActive("block")
    } else {
      setActive("none")
    }
  }, [data])
  return (
    <div className="position-relative col-xl-12" style={{ display: active }}>
      <div className={cx("bg-tippy", "suggest", "suggestDis")}>
        <ul>
          {data.map((item) => {
            return (
              <li
                className="fs-15 py-1"
                key={item.id}
                onClick={() => resultSuggestPro(item.title)}
              >
                {item.title}
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

// suggest price.
function ListSuggestPriceMin({ value, result, checkInput }) {
  const [active, setActive] = useState("")
  const [data, setData] = useState([1000, 10000, 100000])
  const [clickItem, setClickItem] = useState(true)
  const resultSuggestPrice = (key) => {
    const price = Number(key) * Number(value.replace(/[,]/g, ""))
    result(price.toLocaleString().replace(/[.]/g, ","))
    // Ẩn gợi ý.
    setActive("none")
    setClickItem(false)
  }
  useEffect(() => {
    setClickItem(checkInput)
    if (value.length > 1 && clickItem && value.length < 9) {
      setActive("block")
    } else {
      setActive("none")
    }
  }, [value])

  return (
    <div className="position-relative col-xl-12" style={{ display: active }}>
      <div className={cx("bg-tippy", "suggest", "suggestDis")}>
        <ul>
          {data.map((item, index) => {
            return (
              <li
                className="fs-15 py-1"
                key={index}
                onClick={() => resultSuggestPrice(item)}
              >
                {handleFormatPrice(
                  Number(item) *
                    Number(value.toLocaleString().replace(/[., ]/g, "")),
                ).replace(/[.]/g, ",")}
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

// Xử lý filter data estate.
function handleFilterDataEstate(object, dataEstate) {
  let resultDataEstate = []

  if (dataEstate.length > 0) {
    if (
      object.cate !== "" &&
      object.type !== "" &&
      object.district !== "" &&
      object.province !== "" &&
      object.direction !== "" &&
      object.priceMin !== "" &&
      object.priceMax !== ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.cate.toLowerCase().includes(object.cate.toLowerCase()) &&
          item.form_cate.toLowerCase().includes(object.type.toLowerCase()) &&
          item.districts
            .toLowerCase()
            .includes(object.district.toLowerCase()) &&
          item.city.toLowerCase().includes(object.province.toLowerCase()) &&
          item.direction
            .toLowerCase()
            .includes(object.direction.toLowerCase()) &&
          Number(item.price) >= formatPriceMin &&
          Number(item.price) <= formatPriceMax
        )
      })
    } else if (
      object.cate === "" &&
      object.type !== "" &&
      object.district !== "" &&
      object.province !== "" &&
      object.direction !== "" &&
      object.priceMin !== "" &&
      object.priceMax !== ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.form_cate.toLowerCase().includes(object.type.toLowerCase()) &&
          item.districts
            .toLowerCase()
            .includes(object.district.toLowerCase()) &&
          item.city.toLowerCase().includes(object.province.toLowerCase()) &&
          item.direction
            .toLowerCase()
            .includes(object.direction.toLowerCase()) &&
          Number(item.price) >= formatPriceMin &&
          Number(item.price) <= formatPriceMax
        )
      })
    } else if (
      object.cate === "" &&
      object.type === "" &&
      object.district !== "" &&
      object.province !== "" &&
      object.direction !== "" &&
      object.priceMin !== "" &&
      object.priceMax !== ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.districts
            .toLowerCase()
            .includes(object.district.toLowerCase()) &&
          item.city.toLowerCase().includes(object.province.toLowerCase()) &&
          item.direction
            .toLowerCase()
            .includes(object.direction.toLowerCase()) &&
          Number(item.price) >= formatPriceMin &&
          Number(item.price) <= formatPriceMax
        )
      })
    } else if (
      object.cate === "" &&
      object.type === "" &&
      object.district === "" &&
      object.province !== "" &&
      object.direction !== "" &&
      object.priceMin !== "" &&
      object.priceMax !== ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.city.toLowerCase().includes(object.province.toLowerCase()) &&
          item.direction
            .toLowerCase()
            .includes(object.direction.toLowerCase()) &&
          Number(item.price) >= formatPriceMin &&
          Number(item.price) <= formatPriceMax
        )
      })
    } else if (
      object.cate === "" &&
      object.type === "" &&
      object.district === "" &&
      object.province === "" &&
      object.direction !== "" &&
      object.priceMin !== "" &&
      object.priceMax !== ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.direction
            .toLowerCase()
            .includes(object.direction.toLowerCase()) &&
          Number(item.price) >= formatPriceMin &&
          Number(item.price) <= formatPriceMax
        )
      })
    } else if (
      object.cate === "" &&
      object.type === "" &&
      object.district === "" &&
      object.province === "" &&
      object.direction === "" &&
      object.priceMin !== "" &&
      object.priceMax !== ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          Number(item.price) >= formatPriceMin &&
          Number(item.price) <= formatPriceMax
        )
      })
    } else if (
      object.cate === "" &&
      object.type === "" &&
      object.district === "" &&
      object.province === "" &&
      object.direction === "" &&
      object.priceMin === "" &&
      object.priceMax !== ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return Number(item.price) <= formatPriceMax
      })
    } else if (
      object.cate === "" &&
      object.type === "" &&
      object.district === "" &&
      object.province === "" &&
      object.direction !== "" &&
      object.priceMin === "" &&
      object.priceMax !== ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.direction
            .toLowerCase()
            .includes(object.direction.toLowerCase()) &&
          Number(item.price) <= formatPriceMax
        )
      })
    } else if (
      object.cate === "" &&
      object.type === "" &&
      object.district === "" &&
      object.province !== "" &&
      object.direction === "" &&
      object.priceMin === "" &&
      object.priceMax !== ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.city.toLowerCase().includes(object.province.toLowerCase()) &&
          Number(item.price) <= formatPriceMax
        )
      })
    } else if (
      object.cate === "" &&
      object.type === "" &&
      object.district !== "" &&
      object.province === "" &&
      object.direction === "" &&
      object.priceMin === "" &&
      object.priceMax !== ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.districts
            .toLowerCase()
            .includes(object.district.toLowerCase()) &&
          Number(item.price) <= formatPriceMax
        )
      })
    } else if (
      object.cate === "" &&
      object.type !== "" &&
      object.district === "" &&
      object.province === "" &&
      object.direction === "" &&
      object.priceMin === "" &&
      object.priceMax !== ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.form_cate.toLowerCase().includes(object.type.toLowerCase()) &&
          Number(item.price) <= formatPriceMax
        )
      })
    } else if (
      object.cate !== "" &&
      object.type === "" &&
      object.district === "" &&
      object.province === "" &&
      object.direction === "" &&
      object.priceMin === "" &&
      object.priceMax !== ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.cate.toLowerCase().includes(object.cate.toLowerCase()) &&
          Number(item.price) <= formatPriceMax
        )
      })
    } else if (
      object.cate !== "" &&
      object.type !== "" &&
      object.district !== "" &&
      object.province !== "" &&
      object.direction !== "" &&
      object.priceMin !== "" &&
      object.priceMax === ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.cate.toLowerCase().includes(object.cate.toLowerCase()) &&
          item.form_cate.toLowerCase().includes(object.type.toLowerCase()) &&
          item.districts
            .toLowerCase()
            .includes(object.district.toLowerCase()) &&
          item.city.toLowerCase().includes(object.province.toLowerCase()) &&
          item.direction
            .toLowerCase()
            .includes(object.direction.toLowerCase()) &&
          Number(item.price) >= formatPriceMin
        )
      })
    } else if (
      object.cate !== "" &&
      object.type !== "" &&
      object.district !== "" &&
      object.province !== "" &&
      object.direction !== "" &&
      object.priceMin === "" &&
      object.priceMax === ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.cate.toLowerCase().includes(object.cate.toLowerCase()) &&
          item.form_cate.toLowerCase().includes(object.type.toLowerCase()) &&
          item.districts
            .toLowerCase()
            .includes(object.district.toLowerCase()) &&
          item.city.toLowerCase().includes(object.province.toLowerCase()) &&
          item.direction.toLowerCase().includes(object.direction.toLowerCase())
        )
      })
    } else if (
      object.cate !== "" &&
      object.type !== "" &&
      object.district !== "" &&
      object.province !== "" &&
      object.direction === "" &&
      object.priceMin === "" &&
      object.priceMax === ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.cate.toLowerCase().includes(object.cate.toLowerCase()) &&
          item.form_cate.toLowerCase().includes(object.type.toLowerCase()) &&
          item.districts
            .toLowerCase()
            .includes(object.district.toLowerCase()) &&
          item.city.toLowerCase().includes(object.province.toLowerCase())
        )
      })
    } else if (
      object.cate !== "" &&
      object.type !== "" &&
      object.district !== "" &&
      object.province === "" &&
      object.direction === "" &&
      object.priceMin === "" &&
      object.priceMax === ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.cate.toLowerCase().includes(object.cate.toLowerCase()) &&
          item.form_cate.toLowerCase().includes(object.type.toLowerCase()) &&
          item.districts.toLowerCase().includes(object.district.toLowerCase())
        )
      })
    } else if (
      object.cate !== "" &&
      object.type !== "" &&
      object.district === "" &&
      object.province === "" &&
      object.direction === "" &&
      object.priceMin === "" &&
      object.priceMax === ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        console.log(item.cate)
        return (
          item.cate.toLowerCase().includes(object.cate.toLowerCase()) &&
          item.form_cate.toLowerCase().includes(object.type.toLowerCase())
        )
      })
      console.log(resultDataEstate)
    } else if (
      object.cate !== "" &&
      object.type === "" &&
      object.district === "" &&
      object.province === "" &&
      object.direction === "" &&
      object.priceMin === "" &&
      object.priceMax === ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return item.cate.toLowerCase().includes(object.cate.toLowerCase())
      })
    } else if (
      object.cate !== "" &&
      object.type === "" &&
      object.district !== "" &&
      object.province === "" &&
      object.direction === "" &&
      object.priceMin === "" &&
      object.priceMax === ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.cate.toLowerCase().includes(object.cate.toLowerCase()) &&
          item.districts.toLowerCase().includes(object.district.toLowerCase())
        )
      })
    } else if (
      object.cate !== "" &&
      object.type === "" &&
      object.district === "" &&
      object.province !== "" &&
      object.direction === "" &&
      object.priceMin === "" &&
      object.priceMax === ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.cate.toLowerCase().includes(object.cate.toLowerCase()) &&
          item.city.toLowerCase().includes(object.province.toLowerCase())
        )
      })
    } else if (
      object.cate !== "" &&
      object.type === "" &&
      object.district === "" &&
      object.province === "" &&
      object.direction !== "" &&
      object.priceMin === "" &&
      object.priceMax === ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.cate.toLowerCase().includes(object.cate.toLowerCase()) &&
          item.direction.toLowerCase().includes(object.direction.toLowerCase())
        )
      })
    } else if (
      object.cate !== "" &&
      object.type === "" &&
      object.district === "" &&
      object.province === "" &&
      object.direction === "" &&
      object.priceMin !== "" &&
      object.priceMax === ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.cate.toLowerCase().includes(object.cate.toLowerCase()) &&
          Number(item.price) >= formatPriceMin
        )
      })
    } else if (
      object.cate === "" &&
      object.type !== "" &&
      object.district === "" &&
      object.province === "" &&
      object.direction === "" &&
      object.priceMin !== "" &&
      object.priceMax === ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.form_cate.toLowerCase().includes(object.type.toLowerCase()) &&
          Number(item.price) >= formatPriceMin
        )
      })
    } else if (
      object.cate === "" &&
      object.type === "" &&
      object.district !== "" &&
      object.province === "" &&
      object.direction === "" &&
      object.priceMin !== "" &&
      object.priceMax === ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.districts
            .toLowerCase()
            .includes(object.district.toLowerCase()) &&
          Number(item.price) >= formatPriceMin
        )
      })
    } else if (
      object.cate === "" &&
      object.type === "" &&
      object.district === "" &&
      object.province !== "" &&
      object.direction === "" &&
      object.priceMin !== "" &&
      object.priceMax === ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.city.toLowerCase().includes(object.province.toLowerCase()) &&
          Number(item.price) >= formatPriceMin
        )
      })
    } else if (
      object.cate === "" &&
      object.type === "" &&
      object.district === "" &&
      object.province === "" &&
      object.direction !== "" &&
      object.priceMin !== "" &&
      object.priceMax === ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.direction
            .toLowerCase()
            .includes(object.direction.toLowerCase()) &&
          Number(item.price) >= formatPriceMin
        )
      })
    } else if (
      object.cate === "" &&
      object.type === "" &&
      object.district === "" &&
      object.province !== "" &&
      object.direction !== "" &&
      object.priceMin === "" &&
      object.priceMax === ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.city.toLowerCase().includes(object.province.toLowerCase()) &&
          item.direction.toLowerCase().includes(object.direction.toLowerCase())
        )
      })
    } else if (
      object.cate === "" &&
      object.type === "" &&
      object.district !== "" &&
      object.province === "" &&
      object.direction !== "" &&
      object.priceMin === "" &&
      object.priceMax === ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.districts
            .toLowerCase()
            .includes(object.district.toLowerCase()) &&
          item.direction.toLowerCase().includes(object.direction.toLowerCase())
        )
      })
    } else if (
      object.cate === "" &&
      object.type !== "" &&
      object.district === "" &&
      object.province === "" &&
      object.direction !== "" &&
      object.priceMin === "" &&
      object.priceMax === ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.form_cate.toLowerCase().includes(object.type.toLowerCase()) &&
          item.direction.toLowerCase().includes(object.direction.toLowerCase())
        )
      })
    } else if (
      object.cate === "" &&
      object.type !== "" &&
      object.district !== "" &&
      object.province === "" &&
      object.direction === "" &&
      object.priceMin === "" &&
      object.priceMax === ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.form_cate.toLowerCase().includes(object.type.toLowerCase()) &&
          item.districts.toLowerCase().includes(object.district.toLowerCase())
        )
      })
    } else if (
      object.cate === "" &&
      object.type !== "" &&
      object.district === "" &&
      object.province !== "" &&
      object.direction === "" &&
      object.priceMin === "" &&
      object.priceMax === ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.form_cate.toLowerCase().includes(object.type.toLowerCase()) &&
          item.city.toLowerCase().includes(object.province.toLowerCase())
        )
      })
    } else if (
      object.cate === "" &&
      object.type === "" &&
      object.district !== "" &&
      object.province !== "" &&
      object.direction === "" &&
      object.priceMin === "" &&
      object.priceMax === ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.districts
            .toLowerCase()
            .includes(object.district.toLowerCase()) &&
          item.city.toLowerCase().includes(object.province.toLowerCase())
        )
      })
    } else if (
      object.cate === "" &&
      object.type !== "" &&
      object.district === "" &&
      object.province === "" &&
      object.direction === "" &&
      object.priceMin === "" &&
      object.priceMax === ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return item.form_cate.toLowerCase().includes(object.type.toLowerCase())
      })
    } else if (
      object.cate !== "" &&
      object.type === "" &&
      object.district === "" &&
      object.province === "" &&
      object.direction === "" &&
      object.priceMin === "" &&
      object.priceMax === ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        console.log(item.cate)
        console.log(object.cate)
        return item.cate.toLowerCase().includes(object.cate.toLowerCase())
      })
    } else if (
      object.cate === "" &&
      object.type === "" &&
      object.district !== "" &&
      object.province === "" &&
      object.direction === "" &&
      object.priceMin === "" &&
      object.priceMax === ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return item.districts
          .toLowerCase()
          .includes(object.district.toLowerCase())
      })
    } else if (
      object.cate === "" &&
      object.type === "" &&
      object.district === "" &&
      object.province !== "" &&
      object.direction === "" &&
      object.priceMin === "" &&
      object.priceMax === ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return item.city.toLowerCase().includes(object.province.toLowerCase())
      })
    } else if (
      object.cate === "" &&
      object.type === "" &&
      object.district === "" &&
      object.province === "" &&
      object.direction !== "" &&
      object.priceMin === "" &&
      object.priceMax === ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return item.direction
          .toLowerCase()
          .includes(object.direction.toLowerCase())
      })
    } else if (
      object.cate === "" &&
      object.type === "" &&
      object.district === "" &&
      object.province === "" &&
      object.direction === "" &&
      object.priceMin !== "" &&
      object.priceMax === ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return Number(item.price) >= formatPriceMin
      })
    } else if (
      object.cate !== "" &&
      object.type !== "" &&
      object.district === "" &&
      object.province !== "" &&
      object.direction === "" &&
      object.priceMin === "" &&
      object.priceMax === ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.cate.toLowerCase().includes(object.cate.toLowerCase()) &&
          item.form_cate.toLowerCase().includes(object.type.toLowerCase()) &&
          item.city.toLowerCase().includes(object.province.toLowerCase())
        )
      })
    } else if (
      object.cate !== "" &&
      object.type !== "" &&
      object.district === "" &&
      object.province !== "" &&
      object.direction !== "" &&
      object.priceMin === "" &&
      object.priceMax === ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.cate.toLowerCase().includes(object.cate.toLowerCase()) &&
          item.form_cate.toLowerCase().includes(object.type.toLowerCase()) &&
          item.city.toLowerCase().includes(object.province.toLowerCase()) &&
          item.direction.toLowerCase().includes(object.direction.toLowerCase())
        )
      })
    } else if (
      object.cate !== "" &&
      object.type !== "" &&
      object.district === "" &&
      object.province === "" &&
      object.direction !== "" &&
      object.priceMin === "" &&
      object.priceMax === ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.cate.toLowerCase().includes(object.cate.toLowerCase()) &&
          item.form_cate.toLowerCase().includes(object.type.toLowerCase()) &&
          item.direction.toLowerCase().includes(object.direction.toLowerCase())
        )
      })
    } else if (
      object.cate !== "" &&
      object.type !== "" &&
      object.district === "" &&
      object.province !== "" &&
      object.direction === "" &&
      object.priceMin !== "" &&
      object.priceMax === ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.cate.toLowerCase().includes(object.cate.toLowerCase()) &&
          item.form_cate.toLowerCase().includes(object.type.toLowerCase()) &&
          Number(item.price) >= formatPriceMin
        )
      })
    } else if (
      object.cate !== "" &&
      object.type !== "" &&
      object.district === "" &&
      object.province !== "" &&
      object.direction === "" &&
      object.priceMin === "" &&
      object.priceMax !== ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.cate.toLowerCase().includes(object.cate.toLowerCase()) &&
          item.form_cate.toLowerCase().includes(object.type.toLowerCase()) &&
          item.city.toLowerCase().includes(object.province.toLowerCase()) &&
          Number(item.price) <= formatPriceMax
        )
      })
    } else if (
      object.cate !== "" &&
      object.type !== "" &&
      object.district === "" &&
      object.province !== "" &&
      object.direction !== "" &&
      object.priceMin !== "" &&
      object.priceMax === ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.cate.toLowerCase().includes(object.cate.toLowerCase()) &&
          item.form_cate.toLowerCase().includes(object.type.toLowerCase()) &&
          item.city.toLowerCase().includes(object.province.toLowerCase()) &&
          item.direction
            .toLowerCase()
            .includes(object.direction.toLowerCase()) &&
          Number(item.price) >= formatPriceMin
        )
      })
    } else if (
      object.cate !== "" &&
      object.type !== "" &&
      object.district === "" &&
      object.province !== "" &&
      object.direction !== "" &&
      object.priceMin === "" &&
      object.priceMax !== ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.cate.toLowerCase().includes(object.cate.toLowerCase()) &&
          item.form_cate.toLowerCase().includes(object.type.toLowerCase()) &&
          item.city.toLowerCase().includes(object.province.toLowerCase()) &&
          item.direction
            .toLowerCase()
            .includes(object.direction.toLowerCase()) &&
          Number(item.price) <= formatPriceMax
        )
      })
    } else if (
      object.cate !== "" &&
      object.type === "" &&
      object.district !== "" &&
      object.province !== "" &&
      object.direction !== "" &&
      object.priceMin !== "" &&
      object.priceMax !== ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.cate.toLowerCase().includes(object.cate.toLowerCase()) &&
          item.districts
            .toLowerCase()
            .includes(object.district.toLowerCase()) &&
          item.city.toLowerCase().includes(object.province.toLowerCase()) &&
          item.direction
            .toLowerCase()
            .includes(object.direction.toLowerCase()) &&
          Number(item.price) >= formatPriceMin &&
          Number(item.price) <= formatPriceMax
        )
      })
    } else if (
      object.cate !== "" &&
      object.type === "" &&
      object.district !== "" &&
      object.province !== "" &&
      object.direction === "" &&
      object.priceMin !== "" &&
      object.priceMax !== ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.cate.toLowerCase().includes(object.cate.toLowerCase()) &&
          item.districts
            .toLowerCase()
            .includes(object.district.toLowerCase()) &&
          item.city.toLowerCase().includes(object.province.toLowerCase()) &&
          Number(item.price) >= formatPriceMin &&
          Number(item.price) <= formatPriceMax
        )
      })
    } else if (
      object.cate !== "" &&
      object.type === "" &&
      object.district === "" &&
      object.province === "" &&
      object.direction === "" &&
      object.priceMin !== "" &&
      object.priceMax !== ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.cate.toLowerCase().includes(object.cate.toLowerCase()) &&
          Number(item.price) >= formatPriceMin &&
          Number(item.price) <= formatPriceMax
        )
      })
    }
    if (
      object.cate !== "" &&
      object.type === "" &&
      object.district === "" &&
      object.province === "" &&
      object.direction !== "" &&
      object.priceMin !== "" &&
      object.priceMax !== ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.cate.toLowerCase().includes(object.cate.toLowerCase()) &&
          item.direction
            .toLowerCase()
            .includes(object.direction.toLowerCase()) &&
          Number(item.price) >= formatPriceMin &&
          Number(item.price) <= formatPriceMax
        )
      })
    } else if (
      object.cate !== "" &&
      object.type !== "" &&
      object.district === "" &&
      object.province !== "" &&
      object.direction === "" &&
      object.priceMin !== "" &&
      object.priceMax !== ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.cate.toLowerCase().includes(object.cate.toLowerCase()) &&
          item.form_cate.toLowerCase().includes(object.type.toLowerCase()) &&
          item.city.toLowerCase().includes(object.province.toLowerCase()) &&
          Number(item.price) >= formatPriceMin &&
          Number(item.price) <= formatPriceMax
        )
      })
    } else if (
      object.cate === "" &&
      object.type === "" &&
      object.district === "" &&
      object.province !== "" &&
      object.direction === "" &&
      object.priceMin !== "" &&
      object.priceMax !== ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.city.toLowerCase().includes(object.province.toLowerCase()) &&
          Number(item.price) >= formatPriceMin &&
          Number(item.price) <= formatPriceMax
        )
      })
    } else if (
      object.cate === "" &&
      object.type === "" &&
      object.district !== "" &&
      object.province !== "" &&
      object.direction === "" &&
      object.priceMin !== "" &&
      object.priceMax !== ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.districts
            .toLowerCase()
            .includes(object.district.toLowerCase()) &&
          item.city.toLowerCase().includes(object.province.toLowerCase()) &&
          Number(item.price) >= formatPriceMin &&
          Number(item.price) <= formatPriceMax
        )
      })
    } else if (
      object.cate !== "" &&
      object.type !== "" &&
      object.district !== "" &&
      object.province !== "" &&
      object.direction === "" &&
      object.priceMin !== "" &&
      object.priceMax !== ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.cate.toLowerCase().includes(object.cate.toLowerCase()) &&
          item.form_cate.toLowerCase().includes(object.type.toLowerCase()) &&
          item.districts
            .toLowerCase()
            .includes(object.district.toLowerCase()) &&
          item.city.toLowerCase().includes(object.province.toLowerCase()) &&
          Number(item.price) >= formatPriceMin &&
          Number(item.price) <= formatPriceMax
        )
      })
    } else if (
      object.cate !== "" &&
      object.type === "" &&
      object.district === "" &&
      object.province !== "" &&
      object.direction === "" &&
      object.priceMin !== "" &&
      object.priceMax !== ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.cate.toLowerCase().includes(object.cate.toLowerCase()) &&
          item.city.toLowerCase().includes(object.province.toLowerCase()) &&
          Number(item.price) >= formatPriceMin &&
          Number(item.price) <= formatPriceMax
        )
      })
    } else if (
      object.cate !== "" &&
      object.type === "" &&
      object.district === "" &&
      object.province !== "" &&
      object.direction === "" &&
      object.priceMin !== "" &&
      object.priceMax === ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.cate.toLowerCase().includes(object.cate.toLowerCase()) &&
          item.city.toLowerCase().includes(object.province.toLowerCase()) &&
          Number(item.price) >= formatPriceMin
        )
      })
    } else if (
      object.cate !== "" &&
      object.type === "" &&
      object.district === "" &&
      object.province !== "" &&
      object.direction === "" &&
      object.priceMin === "" &&
      object.priceMax !== ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.cate.toLowerCase().includes(object.cate.toLowerCase()) &&
          item.city.toLowerCase().includes(object.province.toLowerCase()) &&
          Number(item.price) <= formatPriceMax
        )
      })
    } else if (
      object.cate !== "" &&
      object.type === "" &&
      object.district !== "" &&
      object.province === "" &&
      object.direction === "" &&
      object.priceMin !== "" &&
      object.priceMax === ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.cate.toLowerCase().includes(object.cate.toLowerCase()) &&
          item.districts
            .toLowerCase()
            .includes(object.district.toLowerCase()) &&
          item.city.toLowerCase().includes(object.province.toLowerCase()) &&
          Number(item.price) >= formatPriceMin
        )
      })
    } else if (
      object.cate !== "" &&
      object.type === "" &&
      object.district !== "" &&
      object.province === "" &&
      object.direction === "" &&
      object.priceMin === "" &&
      object.priceMax !== ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.cate.toLowerCase().includes(object.cate.toLowerCase()) &&
          item.districts
            .toLowerCase()
            .includes(object.district.toLowerCase()) &&
          Number(item.price) <= formatPriceMax
        )
      })
    } else if (
      object.cate !== "" &&
      object.type === "" &&
      object.district !== "" &&
      object.province === "" &&
      object.direction !== "" &&
      object.priceMin !== "" &&
      object.priceMax === ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.cate.toLowerCase().includes(object.cate.toLowerCase()) &&
          item.districts
            .toLowerCase()
            .includes(object.district.toLowerCase()) &&
          item.direction
            .toLowerCase()
            .includes(object.direction.toLowerCase()) &&
          Number(item.price) >= formatPriceMin
        )
      })
    } else if (
      object.cate !== "" &&
      object.type === "" &&
      object.district !== "" &&
      object.province === "" &&
      object.direction !== "" &&
      object.priceMin === "" &&
      object.priceMax !== ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.cate.toLowerCase().includes(object.cate.toLowerCase()) &&
          item.districts
            .toLowerCase()
            .includes(object.district.toLowerCase()) &&
          item.direction
            .toLowerCase()
            .includes(object.direction.toLowerCase()) &&
          Number(item.price) <= formatPriceMax
        )
      })
    } else if (
      object.cate !== "" &&
      object.type === "" &&
      object.district !== "" &&
      object.province === "" &&
      object.direction !== "" &&
      object.priceMin !== "" &&
      object.priceMax !== ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.cate.toLowerCase().includes(object.cate.toLowerCase()) &&
          item.districts
            .toLowerCase()
            .includes(object.district.toLowerCase()) &&
          item.direction
            .toLowerCase()
            .includes(object.direction.toLowerCase()) &&
          Number(item.price) >= formatPriceMin &&
          Number(item.price) <= formatPriceMax
        )
      })
    } else if (
      object.cate !== "" &&
      object.type === "" &&
      object.district !== "" &&
      object.province === "" &&
      object.direction === "" &&
      object.priceMin !== "" &&
      object.priceMax !== ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.cate.toLowerCase().includes(object.cate.toLowerCase()) &&
          item.districts
            .toLowerCase()
            .includes(object.district.toLowerCase()) &&
          Number(item.price) >= formatPriceMin &&
          Number(item.price) <= formatPriceMax
        )
      })
    } else if (
      object.cate !== "" &&
      object.type === "" &&
      object.district !== "" &&
      object.province === "" &&
      object.direction !== "" &&
      object.priceMin === "" &&
      object.priceMax === ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.cate.toLowerCase().includes(object.cate.toLowerCase()) &&
          item.districts
            .toLowerCase()
            .includes(object.district.toLowerCase()) &&
          item.direction.toLowerCase().includes(object.direction.toLowerCase())
        )
      })
    } else if (
      object.cate !== "" &&
      object.type !== "" &&
      object.district !== "" &&
      object.province === "" &&
      object.direction !== "" &&
      object.priceMin === "" &&
      object.priceMax === ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.cate.toLowerCase().includes(object.cate.toLowerCase()) &&
          item.form_cate.toLowerCase().includes(object.type.toLowerCase()) &&
          item.districts
            .toLowerCase()
            .includes(object.district.toLowerCase()) &&
          item.direction.toLowerCase().includes(object.direction.toLowerCase())
        )
      })
    } else if (
      object.cate !== "" &&
      object.type !== "" &&
      object.district !== "" &&
      object.province === "" &&
      object.direction !== "" &&
      object.priceMin !== "" &&
      object.priceMax !== ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.cate.toLowerCase().includes(object.cate.toLowerCase()) &&
          item.form_cate.toLowerCase().includes(object.type.toLowerCase()) &&
          item.districts
            .toLowerCase()
            .includes(object.district.toLowerCase()) &&
          item.direction
            .toLowerCase()
            .includes(object.direction.toLowerCase()) &&
          Number(item.price) >= formatPriceMin &&
          Number(item.price) <= formatPriceMax
        )
      })
    } else if (
      object.cate !== "" &&
      object.type !== "" &&
      object.district !== "" &&
      object.province === "" &&
      object.direction === "" &&
      object.priceMin !== "" &&
      object.priceMax !== ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.cate.toLowerCase().includes(object.cate.toLowerCase()) &&
          item.form_cate.toLowerCase().includes(object.type.toLowerCase()) &&
          item.districts
            .toLowerCase()
            .includes(object.district.toLowerCase()) &&
          Number(item.price) >= formatPriceMin &&
          Number(item.price) <= formatPriceMax
        )
      })
    } else if (
      object.cate !== "" &&
      object.type !== "" &&
      object.district !== "" &&
      object.province === "" &&
      object.direction === "" &&
      object.priceMin !== "" &&
      object.priceMax === ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.cate.toLowerCase().includes(object.cate.toLowerCase()) &&
          item.form_cate.toLowerCase().includes(object.type.toLowerCase()) &&
          item.districts
            .toLowerCase()
            .includes(object.district.toLowerCase()) &&
          Number(item.price) >= formatPriceMin
        )
      })
    } else if (
      object.cate !== "" &&
      object.type !== "" &&
      object.district !== "" &&
      object.province === "" &&
      object.direction === "" &&
      object.priceMin === "" &&
      object.priceMax !== ""
    ) {
      let formatPriceMin = Number(object.priceMin.replace(/[., ]/g, ""))
      let formatPriceMax = Number(object.priceMax.replace(/[., ]/g, ""))
      resultDataEstate = dataEstate.filter((item) => {
        return (
          item.cate.toLowerCase().includes(object.cate.toLowerCase()) &&
          item.form_cate.toLowerCase().includes(object.type.toLowerCase()) &&
          item.districts
            .toLowerCase()
            .includes(object.district.toLowerCase()) &&
          Number(item.price) <= formatPriceMax
        )
      })
    } else if (
      object.cate === "" &&
      object.type === "" &&
      object.district === "" &&
      object.province === "" &&
      object.direction === "" &&
      object.priceMin === "" &&
      object.priceMax === ""
    ) {
      resultDataEstate = dataEstate
    }
  }

  return resultDataEstate
}
