import { useState } from "react";
import GoBackPage from "../../../Components/Goback";
import SidebarMenuMobile from "../../../Components/Sidebar/SidebarMenuMobile";
import { IoMdAddCircle } from "react-icons/io";
import ModalReact from "../../../Components/Modal/ModalReact";
import FormAddStreet from "./FormStreet";

function HeaderStreet({ socketio, dataUser, result }) {
  // Quản lý thêm đường ngõ.
  const [modalAddStreet, setModalAddStreet] = useState(false)
  const handleAddStreet = () => {
    setModalAddStreet(true)
  }
  const handleCloseAddStreet = () => {
    setModalAddStreet(false)
  }
    const handleAddStreetSuccess = (object) => {
    result(object)
    }
  return (
    <>
      <header className="app-header" style={{ background: "#fdd5b9" }}>
        {/* <!-- Start::main-header-container --> */}
        <div className="main-header-container container-fluid align-items-center">
          {/* <!-- Start::header-content-left --> */}
          <div className="header-content-left">
            {/* <!-- Start::header-element --> */}
            <div className="header-element align-items-center">
              <div className="horizontal-logo">
                <GoBackPage />
              </div>
            </div>
            {/* <!-- End::header-element --> */}
          </div>
          {/* <!-- End::header-content-left --> */}

          <span className="fw-5">Trục đường ngõ</span>

          {/* <!-- Start::header-content-right --> */}
          <div className="header-content-right gap-2">
            {/* <!-- Start::header-element --> */}
            <div className="header-element">
              {/* <!-- Start::header-link|dropdown-toggle --> */}
              <button
                className="border-0 bg-transparent p-0 text-primary"
                onClick={handleAddStreet}
              >
                <IoMdAddCircle className="fs-29" />
              </button>
              {/* <!-- End::header-link|dropdown-toggle --> */}
            </div>
            {/* <!-- End::header-element --> */}
          </div>
          {/* <!-- End::header-content-right --> */}
        </div>
        {/* <!-- End::main-header-container --> */}
      </header>

      {/* Modal add street */}
      {modalAddStreet && (
        <ModalReact
          modalTitle="Thêm mới trục đường ngõ"
          showModal={modalAddStreet}
          handleClose={handleCloseAddStreet}
          theme={
            <FormAddStreet
              socketio={socketio}
              dataUser={dataUser}
              result={handleAddStreetSuccess}
            />
          }
        />
      )}
    </>
  )
}

export default HeaderStreet