import { useContext, useEffect, useState } from "react"
import moment from "moment"
import { GoDotFill } from "react-icons/go"
import { GoBack } from "../../../Components/Goback"
import classNames from "classnames/bind"
import styles from "../Job.module.scss"
import { IoPieChartOutline } from "react-icons/io5"
import SearchTaichinhcanhan from "../Component/Taichinhcanhan/SearchTaichinhcanhan"
import StatisticalCanhan from "../Component/Taichinhcanhan/StatisticalCanhan"
import AppContext from "../../../handle-reuses/Contexts/CreateContexts"
import ListDoanhthuChiphi from "../Component/Taichinhcanhan/ListDoanhthuChiphi"
import ListTargetCanhan from "../Component/Taichinhcanhan/ListTargetCanhan"
import { IoMdAdd } from "react-icons/io"
import ModalReact from "../../../Components/Modal/ModalReact"
import ListCateThunhap from "../Component/Taichinhcanhan/ListCateThunhap"
import ListCateChiphi from "../Component/Taichinhcanhan/ListCateChiphi"
import ListCateDongtien from "../Component/Taichinhcanhan/ListCateDongtien"
import FormAddThunhap, { FormAddChuyentien } from "../Component/Taichinhcanhan/FormThunhap"
import FormAddChiphi from "../Component/Taichinhcanhan/FormChiphi"
import ListPhanchiaDongtien from "../Component/Taichinhcanhan/Phanchia_dongtien/ListPhanchiaDongtien"
import FormAddPhanchiaDongtien from "../Component/Taichinhcanhan/Phanchia_dongtien/FormPhanchiaDongtien"
import LoadingSpinner from "../../../Components/Loading/LoadingSpinner"
import FormAddTarget from "../Component/Taichinhcanhan/FormTarget"
const cx = classNames.bind(styles)

function ListTaichinhcanhanPC() {
  const { socketio, dataUser } = useContext(AppContext)
  const [changeEdit, setChangeEdit] = useState()
  // Quản lý danh mục.
  const [activeCate, setActiveCate] = useState("tong-quan")
  const handleActiveCate = (cate) => {
    setActiveCate(cate)
  }

  // Quản lý search data.
  const [objectSearch, setObjectSearch] = useState({
    daySearch: "",
    monthSearch: moment().format("YYYY-MM"),
    textSearch: "",
    user_id: dataUser[0].id_user,
  })
  const handleGetValueSearch = (object) => {
    setObjectSearch({
      daySearch: object.daysSearch,
      monthSearch: object.monthSearch,
      textSearch: object.textSearch,
      user_id: dataUser[0].id_user,
    })
  }

  // Quản lý thêm danh mục thu nhập.
  const [addCateThunhap, setAddCateThunhap] = useState(false)
  const handleAddCateThunhap = () => {
    setAddCateThunhap(true)
  }
  const handleCloseAddCateThunhap = () => {
    setAddCateThunhap(false)
  }
  // Quản lý thêm danh mục chi phí.
  const [addCateChiphi, setAddCateChiphi] = useState(false)
  const handleAddCateChiphi = () => {
    setAddCateChiphi(true)
  }
  const handleCloseAddCateChiphi = () => {
    setAddCateChiphi(false)
  }
  // Quản lý thêm danh mục phân bổ dòng tiền.
  const [addCateDongtien, setAddCateDongtien] = useState(false)
  const handleAddCateDongtien = () => {
    setAddCateDongtien(true)
  }
  const handleCloseAddCateDongtien = () => {
    setAddCateDongtien(false)
  }

  // Quản lý thêm thu nhập.
  const [addThunhap, setAddThunhap] = useState(false)
  const [dataUpdateCreateSuccess, setDataUpdateCreateSuccess] = useState([])
  const handleAddThunhap = () => {
    setAddThunhap(true)
  }
  const handleCloseAddThunhap = () => {
    setAddThunhap(false)
  }
  const handleAddThunhapSuccess = (object) => {
    if (object.status) {
      setDataUpdateCreateSuccess(object.dataUpdate)
      setAddThunhap(false)
    }
  }

  // Quản lý thêm chi phí.
  const [addChiphi, setAddChiphi] = useState(false)
  const [dataUpdateCreateSuccessChiphi, setDataUpdateCreateSuccessChiphi] =
    useState([])
  const handleAddChiphi = () => {
    setAddChiphi(true)
  }
  const handleCloseAddChiphi = () => {
    setAddChiphi(false)
  }
  const handleAddChiphiSuccess = (object) => {
    if (object.status) {
      setDataUpdateCreateSuccessChiphi(object.dataUpdate)
      setAddChiphi(false)
    }
  }
  // Quản lý thêm mới phân chia dòng tiền.
  const [modalPhanchiaDongtien, setModalPhanchiaDongtien] = useState(false)
  const [dataUpdateCreateSuccessDongtien, setDataUpdateCreateSuccessDongtien] =
    useState([])
  const handleAddPhanchiaDongtien = () => {
    setModalPhanchiaDongtien(true)
  }
  const handleCloseAddPhanchiaDongtien = () => {
    setModalPhanchiaDongtien(false)
  }
  const handleAddPhanchiaDongtienSuccess = (object) => {
    if (object.status) {
      setDataUpdateCreateSuccessDongtien(object.dataUpdate)
      setModalPhanchiaDongtien(false)
    }
  }

  // Quan ly cate thu nhap, cate chi phi cho statistical.
  const [cateThunhap, setCateThunhap] = useState([])
  const [cateChiphi, setCateChiphi] = useState([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        user_id: dataUser[0].id_user,
        monthSearch: objectSearch.monthSearch,
      },
    }
    socketio.emit(
      "client-total-thunhap-cate-month-taichinhcanhan-thunhap",
      params,
    )
    socketio.on(
      "server-total-thunhap-cate-month-taichinhcanhan-thunhap",
      (data) => {
        setCateThunhap([...data])
      },
    )
    // Tong chi phi cate.
    socketio.emit(
      "client-total-expense-for-cate-month-taichinhcanhan-chiphi",
      params,
    )
    socketio.on(
      "server-total-expense-for-cate-month-taichinhcanhan-chiphi",
      (data) => {
        setCateChiphi([...data])
        setLoading(false)
      },
    )

    return () => {
      socketio.off("client-total-thunhap-cate-month-taichinhcanhan-thunhap")
      socketio.off("server-total-thunhap-cate-month-taichinhcanhan-thunhap")

      socketio.off("client-total-expense-for-cate-month-taichinhcanhan-chiphi")
      socketio.off("server-total-expense-for-cate-month-taichinhcanhan-chiphi")
    }
  }, [
    objectSearch,
    dataUpdateCreateSuccess,
    dataUpdateCreateSuccessChiphi,
    changeEdit,
  ])

  // Quản lý thêm mới mục tiêu.
  const [modalAddTarget, setModalTarget] = useState(false)
  const [dataUpdateCreateSuccessTarger, setDataUpdateCreateSuccessTarget] =
    useState([])
  const handleAddTarget = () => {
    setModalTarget(true)
  }
  const handleCloseAddTarget = () => setModalTarget(false)
  const handleAddTargetSuccess = (object) => {
    if (object.status) {
      setModalTarget(false)
      setDataUpdateCreateSuccessTarget(object.dataUpdate)
    }
  }

  // Quản lý thêm mới chuyển tiền (chuyển từ thẻ -> ví or ví -> thẻ).
  const [modalAddChuyentien, setModalAddChuyentien] = useState(false)
  const handleAddChuyentien = () => {
    setModalAddChuyentien(true)
  }
  const handleCloseAddChuyentien = () => {
    setModalAddChuyentien(false)
  }
  const handleAddChuyentienSuccess = (object) => {
    if (object.status) {
      setModalAddChuyentien(false)
      setChangeEdit(object.dataUpdate)
    }
  }

  // Quản lý edit data của doanh thu và chi phí (cập nhật lại dữ liệu)
  const handleChangeEditRevenueExpense = (object) => {
    setChangeEdit(object)
  }

  // Thay doi hoac them moi danh muc.
  const handleUpdateCate = (object) => {
    setChangeEdit(object)
  }

  if (loading) {
    return <LoadingSpinner />
  }
  return (
    <div>
      {/* Top 1 */}
      <div className={cx("d-flex justify-content-between align-items-center")}>
        <div className={cx("d-flex align-items-center gap-3 fs-15 fw-5")}>
          <GoBack />
          <span>
            Quản lý tài chính cá nhân{" "}
            {moment(objectSearch.monthSearch).format("MM/YYYY")}
          </span>
        </div>
        <div className="d-flex align-items-center gap-3">
          <button
            className="border-0 badge bg-primary-gradient fw-4 d-flex align-items-center fs-14"
            onClick={handleAddCateThunhap}
          >
            <IoMdAdd className="me-1 fs-18" />
            Danh mục thu nhập
          </button>
          <button
            className="border-0 badge bg-secondary-gradient fw-4 d-flex align-items-center fs-14"
            onClick={handleAddCateChiphi}
          >
            <IoMdAdd className="me-1 fs-18" />
            Danh mục chi phí
          </button>
          <button
            className="border-0 badge bg-primary-gradient op-8 fw-4 d-flex align-items-center fs-14"
            onClick={handleAddCateDongtien}
          >
            <IoMdAdd className="me-1 fs-18" />
            Danh mục phân chia
          </button>
        </div>
      </div>
      {/* top 2 */}
      <div
        className={cx("d-flex justify-content-between align-items-center mt-3")}
      >
        {/* Left */}
        <div className={cx("d-flex alig-items-center gap-3 fs-15")}>
          <button
            className={cx(
              "border-0 bg-transparent",
              activeCate === "tong-quan" ? "color-orange" : "color-gray",
            )}
            onClick={() => handleActiveCate("tong-quan")}
          >
            <span className="d-flex align-items-center gap-1">
              Báo cáo tổng quan
            </span>
          </button>
          <button
            className={cx(
              "border-0 bg-transparent",
              activeCate === "phanchia-dongtien"
                ? "color-orange"
                : "color-gray",
            )}
            onClick={() => handleActiveCate("phanchia-dongtien")}
          >
            <span className="d-flex align-items-center gap-1">
              Phân chia dòng tiền
            </span>
          </button>
          {/* <button
            className={cx(
              "border-0 bg-transparent",
              activeCate === "dulieu-phantich" ? "color-orange" : "color-gray",
            )}
            onClick={() => handleActiveCate("dulieu-phantich")}
          >
            <span className="d-flex align-items-center gap-1">
              Dữ liệu phân tích
            </span>
          </button> */}
        </div>
        {/* Right */}
        <div className={cx("wrapper_ground")}>
          {/* Search month*/}
          <div className="list_items">
            <SearchTaichinhcanhan resultSearch={handleGetValueSearch} />
          </div>

          {/* Button */}
          <div className="list_items">
            <button
              className={cx(
                "border-0 rounded-3 d-flex align-items-center op-9",
                "btn btn-primary-gradient btn-wave waves-effect waves-light",
              )}
              onClick={handleAddThunhap}
            >
              Thêm thu nhập
            </button>
          </div>
          <div className={cx("list_items")}>
            <button
              className={cx(
                "border-0 rounded-3 d-flex align-items-center op-9",
                "btn btn-secondary-gradient btn-wave waves-effect waves-light",
              )}
              onClick={handleAddChiphi}
            >
              Thêm chi phí
            </button>
          </div>
          <div className={cx("list_items")}>
            <button
              className={cx(
                "border-0 rounded-3 d-flex align-items-center op-9",
                "btn btn-primary-gradient op-9 btn-wave waves-effect waves-light",
              )}
              onClick={handleAddPhanchiaDongtien}
            >
              Thêm phân chia
            </button>
          </div>
          <div className={cx("list_items")}>
            <button
              className={cx(
                "border-0 rounded-3 d-flex align-items-center op-9",
                "btn btn-purple-gradient btn-wave waves-effect waves-light",
              )}
              onClick={handleAddTarget}
            >
              Thêm mục tiêu
            </button>
          </div>
          <div className={cx("list_items")}>
            <button
              className={cx(
                "border-0 rounded-3 d-flex align-items-center op-9",
                "btn btn-primary btn-wave waves-effect waves-light",
              )}
              onClick={handleAddChuyentien}
            >
              Thêm chuyển tiền
            </button>
          </div>
        </div>
      </div>

      {/* List content */}
      <div className="mt-3">
        {activeCate === "tong-quan" && (
          <div className="wrapper_ground gap-1">
            <div
              className="list_items col-12 col-xl-2 rounded-4 p-3 fs-16"
              style={{
                background: "white",
                height: "calc(100vh - 12rem)",
                overflow: "auto",
              }}
            >
              {/* statistical */}
              <StatisticalCanhan
                socketio={socketio}
                dataUser={dataUser}
                objectSearch={objectSearch}
                dataCateThunhap={cateThunhap}
                dataCateChiphi={cateChiphi}
                dataUpdateRevenueCreate={dataUpdateCreateSuccess}
                dataUpdateCreateSuccessChiphi={dataUpdateCreateSuccessChiphi}
              />
            </div>
            {/* Danh sách */}
            <div
              className="list_items col-12 col-xl-3 p-3 fs-16 rounded-4"
              style={{
                background: "white",
                height: "calc(100vh - 12rem)",
              }}
            >
              <ListDoanhthuChiphi
                socketio={socketio}
                dataUser={dataUser}
                objectSearch={objectSearch}
                dataCateChiphi={cateChiphi}
                dataUpdateRevenueCreate={dataUpdateCreateSuccess}
                dataUpdateCreateSuccessChiphi={dataUpdateCreateSuccessChiphi}
                resultChange={handleChangeEditRevenueExpense}
              />
            </div>
            <div
              className="list_items col-12 col-xl-3 rounded-4 p-3 fs-16"
              style={{
                background: "white",
                height: "calc(100vh - 12rem)",
              }}
            >
              <ListTargetCanhan
                socketio={socketio}
                dataUser={dataUser}
                objectSearch={objectSearch}
                result={handleAddTargetSuccess}
                dataUpdateCreate={dataUpdateCreateSuccessTarger}
                dataUpdateRevenueCreate={dataUpdateCreateSuccess}
                dataUpdateCreateSuccessChiphi={dataUpdateCreateSuccessChiphi}
                changeEdit={changeEdit}
              />
            </div>
          </div>
        )}
        {/* Phân chia dòng tiền */}
        {activeCate === "phanchia-dongtien" && (
          <ListPhanchiaDongtien
            socketio={socketio}
            dataUser={dataUser}
            objectSearch={objectSearch}
            dataUpdateCreate={dataUpdateCreateSuccessDongtien}
            dataUpdateRevenueCreate={dataUpdateCreateSuccess}
            dataUpdateCreateSuccessChiphi={dataUpdateCreateSuccessChiphi}
          />
        )}
      </div>

      {/* Modal add cate thu nhập */}
      {addCateThunhap && (
        <ModalReact
          modalTitle="Thêm mới danh mục thu nhập"
          showModal={addCateThunhap}
          handleClose={handleCloseAddCateThunhap}
          theme={
            <ListCateThunhap
              socketio={socketio}
              dataUser={dataUser}
              result={handleUpdateCate}
            />
          }
        />
      )}
      {/* Modal add cate chi phí */}
      {addCateChiphi && (
        <ModalReact
          modalTitle="Thêm mới danh mục chi phí"
          showModal={addCateChiphi}
          handleClose={handleCloseAddCateChiphi}
          theme={
            <ListCateChiphi
              socketio={socketio}
              dataUser={dataUser}
              result={handleUpdateCate}
            />
          }
        />
      )}
      {/* Modal add cate dòng tiền */}
      {addCateDongtien && (
        <ModalReact
          modalTitle="Thêm mới danh mục dòng tiền"
          showModal={addCateDongtien}
          handleClose={handleCloseAddCateDongtien}
          theme={
            <ListCateDongtien
              socketio={socketio}
              dataUser={dataUser}
              result={handleUpdateCate}
            />
          }
        />
      )}

      {/* Modal add thu nhập */}
      {addThunhap && (
        <ModalReact
          modalTitle="Thêm mới thu nhập"
          showModal={addThunhap}
          handleClose={handleCloseAddThunhap}
          theme={
            <FormAddThunhap
              socketio={socketio}
              dataUser={dataUser}
              result={handleAddThunhapSuccess}
            />
          }
        />
      )}
      {/* Modal add chi phí */}
      {addChiphi && (
        <ModalReact
          modalTitle="Thêm mới chi phí"
          showModal={addChiphi}
          handleClose={handleCloseAddChiphi}
          theme={
            <FormAddChiphi
              socketio={socketio}
              dataUser={dataUser}
              result={handleAddChiphiSuccess}
            />
          }
        />
      )}

      {/* Modal add phanchia_dongtien */}
      {modalPhanchiaDongtien && (
        <ModalReact
          modalTitle="Thêm mới chi phí"
          showModal={modalPhanchiaDongtien}
          handleClose={handleCloseAddPhanchiaDongtien}
          theme={
            <FormAddPhanchiaDongtien
              socketio={socketio}
              dataUser={dataUser}
              result={handleAddPhanchiaDongtienSuccess}
            />
          }
        />
      )}

      {/* modal add target */}
      {modalAddTarget && (
        <ModalReact
          modalTitle="Thêm mới mục tiêu"
          showModal={modalAddTarget}
          handleClose={handleCloseAddTarget}
          theme={
            <FormAddTarget
              socketio={socketio}
              dataUser={dataUser}
              result={handleAddTargetSuccess}
            />
          }
        />
      )}

      {/* Modal add chuyển tiền */}
      {modalAddChuyentien && (
        <ModalReact
          modalTitle="Thêm mới chuyển tiền"
          showModal={modalAddChuyentien}
          handleClose={handleCloseAddChuyentien}
          theme={
            <FormAddChuyentien
              socketio={socketio}
              dataUser={dataUser}
              result={handleAddChuyentienSuccess}
            />
          }
        />
      )}
    </div>
  )
}
export default ListTaichinhcanhanPC
