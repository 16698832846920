import ChartExpenseYear from "./ChartExpenseYear"
import ChartRevenueExpenseYear from "./ChartRevenueExpenseYear"
import ChartRevenueYear from "./ChartRevenueYear"
import StatisticalReport from "./StatisticalReport"

// Báo cáo tổng quan.
function OverviewReportPC({ ...props }) {
    const {
        socketio,
        dataUser,
        object,
        listContractMonth,
        dataCateRevenueMonth,
        dataCateExpenseMonth,
        dataTaisan,
        dataTotalRevenue,
        dataTotalExpense,
        dataRevenueYear,
        dataExpenseYear,
        dataCateRevenueYear,
        dataCateExpenseYear,
    } = props;

    return (
        <div>
            {/* Thống kê số liệu */}
            <StatisticalReport
                socketio={socketio}
                dataUser={dataUser}
                object={object}
                listContractMonth={listContractMonth}
                dataCateRevenueMonth={dataCateRevenueMonth}
                dataCateExpenseMonth={dataCateExpenseMonth}
                dataTaisan={dataTaisan}
                dataTotalRevenue={dataTotalRevenue}
                dataTotalExpense={dataTotalExpense}
            />

            {/* Biểu đồ doanh thu, chi phí, lợi nhuận năm */}
            {/* <!-- Start::row-2 --> */}
            <div className="row">
                {dataRevenueYear.length > 0 && dataExpenseYear.length > 0 ? (
                    <ChartRevenueExpenseYear
                        socketio={socketio}
                        dataUser={dataUser}
                        dataRevenueYear={dataRevenueYear}
                        dataExpenseYear={dataExpenseYear}
                    />
                ) : (
                    <div className="col-xxl-6 col-xl-6 col-12">
                        <div className="card custom-card">
                            <div className="card-body" style={{ height: "400px" }}>
                                Chưa đủ dữ liệu để phân tích
                            </div>
                        </div>
                    </div>
                )}

                <div className="col-xxl-3 col-xl-3">
                    {dataCateRevenueYear.length > 0 ? (
                        <ChartRevenueYear dataCateRevenueYear={dataCateRevenueYear} />
                    ) : (
                        <div className="card custom-card">
                            <div className="card-body" style={{ height: "400px" }}>
                                Chưa đủ dữ liệu để phân tích
                            </div>
                        </div>
                    )}
                </div>

                {/* Danh sách chi phí năm */}
                <div className="col-xxl-3 col-xl-3 col-12">
                    {dataCateExpenseYear.length > 0 ? (
                        <ChartExpenseYear dataCateExpenseYear={dataCateExpenseYear} />
                    ) : (
                        <div className="card custom-card">
                            <div className="card-body" style={{ height: "400px" }}>
                                Chưa đủ dữ liệu để phân tích
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {/* <!-- End::row-2 --> */}
        </div>
    );
}

export default OverviewReportPC