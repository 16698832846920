import classNames from "classnames/bind"
import styles from "../Job.module.scss";
import { GoBack } from "../../../Components/Goback";
import { GoDotFill } from "react-icons/go";
import { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import SearchHorizontal from "../../../Components/Search/SearchHorizontal";
import { IoMdAdd } from "react-icons/io";
import { MdEdit } from "react-icons/md";
import ModalReact from "../../../Components/Modal/ModalReact";
import { useForm } from "react-hook-form";
import AppContext from "../../../handle-reuses/Contexts/CreateContexts";
import moment from "moment/moment";
import LoadingSpinner from "../../../Components/Loading/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import FormAddRole, { FormAddBophan, FormAddChucvu, FormEditDataPersonnel } from "../Component/personnel/FormPersonnel";
const cx = classNames.bind(styles);

function ListPersonnalPC() {
  const { socketio, dataUser } = useContext(AppContext);
  const navigate = useNavigate()
  useLayoutEffect(() => {
    // Check tài khoản được truy cập vào component này.
    if (dataUser[0].nhan_su !== 1) {
      alert("Bạn không có quyền truy cập.")
      navigate("/")
    }
  }, [])

  // Quản lý danh sách nhân sự.
  const [dataPersonnel, setDataPersonnel] = useState([])
  const [loading, setLoading] = useState(true)

  function handleGetListPersonnel() {
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
    }
    socketio.emit("client-get-data-job-personnel", params)
  }
  useEffect(() => {
    // Get data ban đầu.
    handleGetListPersonnel()
  }, [])
  useEffect(() => {
    socketio.on("server-get-data-job-personnel", (data) => {
      setDataPersonnel([...data])
      setLoading(false)
    })
    return () => {
      socketio.off("client-get-data-job-personnel")
      socketio.off("server-get-data-job-personnel")
    }
  }, [])
  // Quản lý danh mục.
  const [activeCate, setActiveCate] = useState("nhan-su")
  const handleActiveCate = (cate) => {
    setActiveCate(cate)
  }
  // Quản lý search data.
  const handleGetValueSearchText = (keywords) => {
    const paramsSearch = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {keywords: keywords.replace(/[.,]/g, "")}
    }
    socketio.emit("client-search-data-personel", paramsSearch)
    setLoading(true)
  }
  useEffect(() => {
    socketio.on("server-search-data-personel", (dataSearch) => {
      setDataPersonnel([...dataSearch])
      setLoading(false)
    })
    return () => {
      socketio.off("client-search-data-personel")
      socketio.off("server-search-data-personel")
    }
  }, []);

  // Quản lý thêm mới
  const [modalAdd, setModalAdd] = useState(false)
  const handleAddRoleNew = () => {
    setModalAdd(true)
  }
  const handleCloseModalAdd = () => {
    setModalAdd(false)
  }
  // Quản lý thêm mới quyền thành công
  const handleResultStatus = (status_) => {
    if (status_) {
      setModalAdd(false)
      // Cập nhật lại dataPersonnel.
      handleGetListPersonnel()
    }
  }

  // Quản lý thêm mới bộ phận.
  const [addBophan, setAddBophan] = useState(false);
  const handleAddBophan = () => {
    setAddBophan(true)
  }
  const handleCloseBophan = () => {
    setAddBophan(false)
  }
  const handleResultStatusSuccessBophan = (status_) => {
    setAddBophan(false)
  }

  // Quản lý thêm mới chức vụ.
  const [addChucvu, setAddChucvu] = useState(false)
  const handleAddChucvu = () => {
    setAddChucvu(true)
  }
  const handleCloseChucvu = () => {
    setAddChucvu(false)
  }
  const handleResultStatusSuccessChucvu = (status_) => {
    setAddChucvu(false)
  }
  

  return (
    <>
      {/* Top 1 */}
      <div className={cx("d-flex justify-content-between align-items-center")}>
        <div className={cx("d-flex align-items-center gap-3 fs-15 fw-5")}>
          <GoBack />
          <span>Quản lý nhân sự</span>
        </div>
        <div className="d-flex align-items-center gap-3">
          <span className="d-flex align-items-center fs-14">
            <GoDotFill className="text-success-1 me-1 fs-18" />
            Cả ngày
          </span>
          <span className="d-flex align-items-center fs-14">
            <GoDotFill className="text-warning-1 me-1 fs-18" />
            Nửa ngày
          </span>
        </div>
      </div>
      <div
        className={cx("d-flex justify-content-between align-items-center mt-3")}
      >
        {/* Left */}
        <div className={cx("d-flex alig-items-center gap-3 fs-15")}>
          <button
            className={cx(
              "border-0 bg-transparent",
              activeCate === "nhan-su" ? "color-orange" : "color-gray",
            )}
            onClick={() => handleActiveCate("nhan-su")}
          >
            <span className="d-flex align-items-center">Nhân sự</span>
          </button>
        </div>
        {/* Right */}
        <div className={cx("d-flex align-items-center gap-3")}>
          <SearchHorizontal
            result={handleGetValueSearchText}
            className={cx("rounded-1 border-1")}
            style={{ background: "white" }}
          />
          {/* Thêm mới công việc */}
          <button
            className={cx(
              "button_color_b",
              "border-0 rounded-1 d-flex align-items-center",
            )}
            onClick={handleAddRoleNew}
          >
            <IoMdAdd className="fs-15 me-1" />
            Thêm quyền mới
          </button>
          {/* Thêm bộ phận */}
          <button
            className={cx(
              "button_color_a",
              "border-0 rounded-1 d-flex align-items-center",
            )}
            onClick={handleAddBophan}
          >
            <IoMdAdd className="fs-15 me-1" />
            Thêm bộ phân
          </button>
          {/* Thêm chức vụ */}
          <button
            className={cx(
              "bg-warning",
              "btn border-0 rounded-1 d-flex align-items-center",
            )}
            onClick={handleAddChucvu}
          >
            <IoMdAdd className="fs-15 me-1" />
            Thêm chức vụ
          </button>
        </div>
      </div>
      {/* List content */}
      <div className={cx("mt-3")}>
        {/* Tiến độ */}
        {loading && <LoadingSpinner />}
        {activeCate === "nhan-su" && !loading && (
          <ListPersonnalTypeTable
            socketio={socketio}
            dataPersonnel={dataPersonnel}
          />
        )}
        {/* {activeCate === "phong-ban" && <RolePersonal />}
        {activeCate === "ca-lam-viec" && <RolePersonal />} */}
      </div>

      {/* Modal add  */}
      <ModalReact
        modalTitle="Thêm mới quyền"
        showModal={modalAdd}
        handleClose={handleCloseModalAdd}
        theme={<FormAddRole socketio={socketio} result={handleResultStatus} />}
      />

      {/* Modal add bộ phận */}
      {addBophan && (
        <ModalReact
          modalTitle="Thêm mới bộ phận"
          showModal={addBophan}
          handleClose={handleCloseBophan}
          theme={
            <FormAddBophan
              socketio={socketio}
              result={handleResultStatusSuccessBophan}
            />
          }
        />
      )}

      {/* Modal add chức vụ */}
      {addChucvu && (
        <ModalReact
          modalTitle="Thêm mới bộ phận"
          showModal={addChucvu}
          handleClose={handleCloseChucvu}
          theme={
            <FormAddChucvu
              socketio={socketio}
              result={handleResultStatusSuccessChucvu}
            />
          }
        />
      )}
    </>
  )
}
export default ListPersonnalPC


// List nhân sự.
function ListPersonnalTypeTable({ socketio, dataPersonnel }) {
  // Quản lý edit data.
  const [editData, setEditData] = useState(false)
  const dataEditPersonnel = useRef()
  const handleShowModalEditData = (userId, index) => {
    setEditData(true)
    const filterData = dataPersonnel.find((item) => {
      return item.user_id === userId
    })
    filterData.index = index
    dataEditPersonnel.current = filterData
  }
  const handleCloseModalEditData = () => {
    setEditData(false)
  }
  // Cập nhật lại dataPersonnel
  const handleResultEditSuccess = (object) => {
    if (object.status) {
      const objectSuccessEdit = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: { user_id: object.user_id },
      }
      socketio.emit("client-update-data-job-personnel", objectSuccessEdit)
      socketio.on("server-update-data-job-personnel", (dataUpdate) => {
        dataPersonnel[object.index] = dataUpdate.find((item) => item)
        setEditData(false)
      })
    }
  }
  useEffect(() => {
    return () => {
      socketio.off("client-update-data-job-personnel")
      socketio.off("server-update-data-job-personnel")
    }
  }, [editData])

  // Quản lý thay đổi trạng thái cho các mục được truy cập
  const idPersonnel = useRef()
  const checkEditRole = useRef();
  // Edit doi-tac
  const handleCheckChangeRole = (personnelID, cate) => {
    idPersonnel.current = personnelID
    checkEditRole.current = cate
    const paramsDoitac = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: { id: personnelID },
    }
    socketio.emit("client-check-data-id-job-personnel", paramsDoitac)
  }
  // Xử lý edit các quyền truy cập
  useEffect(() => {
    socketio.on("server-check-data-id-job-personnel", (dataCheck) => {
      if (checkEditRole.current === "doi-tac") {
        let paramsEdit = {
          iden: process.env.REACT_APP_IDEN_DASH,
          object: {
            doi_tac: dataCheck[0].doi_tac === 1 ? 0 : 1,
            id: idPersonnel.current,
          },
        }
        // Xử lý edit đối tác.
        socketio.emit("client-edit-row-doitac-job-personnel", paramsEdit)
      } else if (checkEditRole.current === "bao-cao") {
        // Xử lý edit báo cáo.
        let paramsEdit = {
          iden: process.env.REACT_APP_IDEN_DASH,
          object: {
            bao_cao: dataCheck[0].bao_cao === 1 ? 0 : 1,
            id: idPersonnel.current,
          },
        }
        socketio.emit("client-edit-row-baocao-job-personnel", paramsEdit)
      } else if (checkEditRole.current === "ke-toan") {
        // Xử lý edit kế toán.
        let paramsEdit = {
          iden: process.env.REACT_APP_IDEN_DASH,
          object: {
            ke_toan: dataCheck[0].ke_toan === 1 ? 0 : 1,
            id: idPersonnel.current,
          },
        }
        socketio.emit("client-edit-row-ketoan-job-personnel", paramsEdit)
      } else if (checkEditRole.current === "role-ke-toan") {
        // Xử lý edit role kế toán.
        let paramsEdit = {
          iden: process.env.REACT_APP_IDEN_DASH,
          object: {
            role_ketoan: dataCheck[0].role_ketoan === 1 ? 0 : 1,
            id: idPersonnel.current,
          },
        }
        socketio.emit("client-edit-row-role-ketoan-job-personnel", paramsEdit)
      } else if (checkEditRole.current === "bang-luong") {
        // Xử lý edit bảng lương.
        let paramsEdit = {
          iden: process.env.REACT_APP_IDEN_DASH,
          object: {
            bang_luong: dataCheck[0].bang_luong === 1 ? 0 : 1,
            id: idPersonnel.current,
          },
        }
        socketio.emit("client-edit-row-bangluong-job-personnel", paramsEdit)
      } else if (checkEditRole.current === "list-bang-luong") {
        // Xử lý edit bảng lương.
        let paramsEdit = {
          iden: process.env.REACT_APP_IDEN_DASH,
          object: {
            list_bangluong: dataCheck[0].list_bangluong === 1 ? 0 : 1,
            id: idPersonnel.current,
          },
        }
        socketio.emit(
          "client-edit-row-list-bangluong-job-personnel",
          paramsEdit,
        )
      } else if (checkEditRole.current === "hop-dong") {
        // Xử lý edit hợp đồng.
        let paramsEdit = {
          iden: process.env.REACT_APP_IDEN_DASH,
          object: {
            hop_dong: dataCheck[0].hop_dong === 1 ? 0 : 1,
            id: idPersonnel.current,
          },
        }
        socketio.emit("client-edit-row-hopdong-job-personnel", paramsEdit)
      } else if (checkEditRole.current === "list-hop-dong") {
        // Xử lý edit list_hopdong.
        let paramsEdit = {
          iden: process.env.REACT_APP_IDEN_DASH,
          object: {
            list_hopdong: dataCheck[0].list_hopdong === 1 ? 0 : 1,
            id: idPersonnel.current,
          },
        }
        socketio.emit("client-edit-row-list-hopdong-job-personnel", paramsEdit)
      } else if (checkEditRole.current === "cong-viec") {
        // Xử lý edit công việc.
        let paramsEdit = {
          iden: process.env.REACT_APP_IDEN_DASH,
          object: {
            cong_viec: dataCheck[0].cong_viec === 1 ? 0 : 1,
            id: idPersonnel.current,
          },
        }
        socketio.emit("client-edit-row-congviec-job-personnel", paramsEdit)
      } else if (checkEditRole.current === "list-congviec") {
        // Xử lý edit list công việc.
        let paramsEdit = {
          iden: process.env.REACT_APP_IDEN_DASH,
          object: {
            list_congviec: dataCheck[0].list_congviec === 1 ? 0 : 1,
            id: idPersonnel.current,
          },
        }
        socketio.emit("client-edit-row-list-congviec-job-personnel", paramsEdit)
      } else if (checkEditRole.current === "giao-congviec") {
        // Xử lý edit giao công việc.
        let paramsEdit = {
          iden: process.env.REACT_APP_IDEN_DASH,
          object: {
            giao_congviec: dataCheck[0].giao_congviec === 1 ? 0 : 1,
            id: idPersonnel.current,
          },
        }
        socketio.emit("client-edit-row-giao-congviec-job-personnel", paramsEdit)
      } else if (checkEditRole.current === "list-tiendo") {
        // Xử lý edit list tiến độ.
        let paramsEdit = {
          iden: process.env.REACT_APP_IDEN_DASH,
          object: {
            list_tiendo: dataCheck[0].list_tiendo === 1 ? 0 : 1,
            id: idPersonnel.current,
          },
        }
        socketio.emit("client-edit-row-list-tiendo-job-personnel", paramsEdit)
      } else if (checkEditRole.current === "list-evaluate") {
        // Xử lý edit list đánh giá (evaluate).
        let paramsEdit = {
          iden: process.env.REACT_APP_IDEN_DASH,
          object: {
            list_evaluate: dataCheck[0].list_evaluate === 1 ? 0 : 1,
            id: idPersonnel.current,
          },
        }
        socketio.emit("client-edit-row-list-evaluate-job-personnel", paramsEdit)
      } else if (checkEditRole.current === "cham-cong") {
        // Xử lý edit chấm công.
        let paramsEdit = {
          iden: process.env.REACT_APP_IDEN_DASH,
          object: {
            cham_cong: dataCheck[0].cham_cong === 1 ? 0 : 1,
            id: idPersonnel.current,
          },
        }
        socketio.emit("client-edit-row-chamcong-job-personnel", paramsEdit)
      } else if (checkEditRole.current === "list-chamcong") {
        // Xử lý edit list chấm công (cho phép xem bảng chấm công của tất cả nhân sự).
        let paramsEdit = {
          iden: process.env.REACT_APP_IDEN_DASH,
          object: {
            list_chamcong: dataCheck[0].list_chamcong === 1 ? 0 : 1,
            id: idPersonnel.current,
          },
        }
        socketio.emit("client-edit-row-listchamcong-job-personnel", paramsEdit)
      } else if (checkEditRole.current === "list-vangmat") {
        // Xử lý edit list vắng mặt (cho phép xem bảng vắng mặt của tất cả nhân sự trong mục chấm công).
        let paramsEdit = {
          iden: process.env.REACT_APP_IDEN_DASH,
          object: {
            list_vangmat: dataCheck[0].list_vangmat === 1 ? 0 : 1,
            id: idPersonnel.current,
          },
        }
        socketio.emit("client-edit-row-listvangmat-job-personnel", paramsEdit)
      } else if (checkEditRole.current === "list-tangca") {
        // Xử lý edit list tăng ca (trong mục chấm công).
        let paramsEdit = {
          iden: process.env.REACT_APP_IDEN_DASH,
          object: {
            list_tangca: dataCheck[0].list_tangca === 1 ? 0 : 1,
            id: idPersonnel.current,
          },
        }
        socketio.emit("client-edit-row-listtangca-job-personnel", paramsEdit)
      } else if (checkEditRole.current === "list-xinnghi") {
        // Xử lý edit list xin nghỉ (trong mục chấm công).
        let paramsEdit = {
          iden: process.env.REACT_APP_IDEN_DASH,
          object: {
            list_xinnghi: dataCheck[0].list_xinnghi === 1 ? 0 : 1,
            id: idPersonnel.current,
          },
        }
        socketio.emit("client-edit-row-listxinnghi-job-personnel", paramsEdit)
      } else if (checkEditRole.current === "nhan-su") {
        // Xử lý edit công việc.
        let paramsEdit = {
          iden: process.env.REACT_APP_IDEN_DASH,
          object: {
            nhan_su: dataCheck[0].nhan_su === 1 ? 0 : 1,
            id: idPersonnel.current,
          },
        }
        socketio.emit("client-edit-row-nhansu-job-personnel", paramsEdit)
      } else if (checkEditRole.current === "data-sale") {
        // Xử lý edit công việc.
        let paramsEdit = {
          iden: process.env.REACT_APP_IDEN_DASH,
          object: {
            data_sale: dataCheck[0].data_sale === 1 ? 0 : 1,
            id: idPersonnel.current,
          },
        }
        socketio.emit("client-edit-row-datasale-job-personnel", paramsEdit)
      }
    })
    return () => {
      socketio.off("client-check-data-id-job-personnel")
      socketio.off("server-check-data-id-job-personnel")
      socketio.off("client-edit-row-doitac-job-personnel")
      socketio.off("client-edit-row-baocao-job-personnel")

      socketio.off("client-edit-row-ketoan-job-personnel")
      socketio.off("client-edit-row-role-ketoan-job-personnel")

      socketio.off("client-edit-row-bangluong-job-personnel")
      socketio.off("client-edit-row-hopdong-job-personnel")

      socketio.off("client-edit-row-congviec-job-personnel")
      socketio.off("client-edit-row-list-congviec-job-personnel")
      socketio.off("client-edit-row-giao-congviec-job-personnel")
      socketio.off("client-edit-row-list-tiendo-job-personnel")
      socketio.off("client-edit-row-list-evaluate-job-personnel")
      
      socketio.off("client-edit-row-chamcong-job-personnel")
      socketio.off("client-edit-row-listchamcong-job-personnel")
      socketio.off("client-edit-row-listvangmat-job-personnel")

      socketio.off("client-edit-row-nhansu-job-personnel")
      socketio.off("client-edit-row-datasale-job-personnel")
    }
  }, [])

  return (
    <div className="table-responsive">
      <table className="table text-nowrap table-bordered table-striped">
        <tbody>
          {dataPersonnel.map((item, index) => {
            // Set số ngày ký hợp đồng còn lại.
            let dateEnd = moment(item.den_ngay)
            let currentTime = moment()
            let diffTime = dateEnd.diff(currentTime, "days")
            return (
              <tr key={index}>
                <th scope="row" className="col-1">
                  <div className="d-flex align-items-center">
                    <span className="avatar avatar-md me-2 avatar-rounded">
                      <img
                        src={"https://system.view360.vn/" + item.avatar}
                        alt="img"
                      />
                    </span>
                    <div>
                      {item.hoten}
                      <button
                        className="border-0 bg-transparent"
                        onClick={() =>
                          handleShowModalEditData(item.user_id, index)
                        }
                      >
                        <MdEdit />
                      </button>
                      <p className="mb-0 text-muted">{item.sodienthoai}</p>
                    </div>
                  </div>
                </th>
                <td>
                  <p className="mb-0 text-muted fs-14">Bộ phận</p>
                  <p className="mb-0">{item.bo_phan ? item.bo_phan : "CXD"}</p>
                </td>
                <td>
                  <p className="mb-0 text-muted fs-14">Chức vụ</p>
                  <p className="mb-0">{item.chuc_vu ? item.chuc_vu : "CXD"}</p>
                </td>
                <td>
                  <p className="mb-0 text-muted fs-14">Từ ngày</p>
                  <p className="mb-0">
                    {moment(item.tu_ngay).format("DD/MM/YYYY")}
                  </p>
                </td>
                <td>
                  <p className="mb-0 text-muted fs-14">Đến ngày</p>
                  <p className="mb-0">
                    {moment(item.den_ngay).format("DD/MM/YYYY")}
                  </p>
                </td>
                <td>
                  <p className="mb-0 text-muted fs-14">Hợp đồng</p>
                  <p className="mb-0">
                    <GoDotFill
                      className={cx(
                        "me-1 fs-18",
                        item.ca_lamviec === 2
                          ? "text-success-1"
                          : "text-warning-1",
                      )}
                    />
                    {diffTime} ngày
                  </p>
                </td>
                <td>
                  <p className="mb-0 text-muted fs-14">Loại ca</p>
                  <p className="mb-0">
                    {item.loai_ca === "full" && "Cả ngày"}
                    {item.loai_ca === "sang" && "Ca sáng"}
                    {item.loai_ca === "chieu" && "Ca chiều"}
                  </p>
                </td>
                <td>
                  <p className="mb-0 text-muted fs-14">Data sale</p>
                  <p className="mb-0 text-center">
                    <input
                      type="checkbox"
                      defaultChecked={item.data_sale === 1 ? true : false}
                      onChange={() =>
                        handleCheckChangeRole(item.id, "data-sale")
                      }
                      className="rounded-checkbox"
                    />
                  </p>
                </td>
                <td>
                  <p className="mb-0 text-muted fs-14">Nhân sự</p>
                  <p className="mb-0 text-center">
                    <input
                      type="checkbox"
                      defaultChecked={item.nhan_su === 1 ? true : false}
                      onChange={() => handleCheckChangeRole(item.id, "nhan-su")}
                      className="rounded-checkbox"
                    />
                  </p>
                </td>
                <td>
                  <p className="mb-0 text-muted fs-14">Chấm công</p>
                  <p className="mb-0 text-center">
                    <input
                      type="checkbox"
                      defaultChecked={item.cham_cong === 1 ? true : false}
                      onChange={() =>
                        handleCheckChangeRole(item.id, "cham-cong")
                      }
                      className="rounded-checkbox"
                    />
                  </p>
                </td>
                <td>
                  <p className="mb-0 text-muted fs-14">List all chấm công</p>
                  <p className="mb-0 text-center">
                    <input
                      type="checkbox"
                      defaultChecked={item.list_chamcong === 1 ? true : false}
                      onChange={() =>
                        handleCheckChangeRole(item.id, "list-chamcong")
                      }
                      className="rounded-checkbox"
                    />
                  </p>
                </td>
                <td>
                  <p className="mb-0 text-muted fs-14">List all vắng mặt</p>
                  <p className="mb-0 text-center">
                    <input
                      type="checkbox"
                      defaultChecked={item.list_vangmat === 1 ? true : false}
                      onChange={() =>
                        handleCheckChangeRole(item.id, "list-vangmat")
                      }
                      className="rounded-checkbox"
                    />
                  </p>
                </td>
                <td>
                  <p className="mb-0 text-muted fs-14">List all tăng ca</p>
                  <p className="mb-0 text-center">
                    <input
                      type="checkbox"
                      defaultChecked={item.list_tangca === 1 ? true : false}
                      onChange={() =>
                        handleCheckChangeRole(item.id, "list-tangca")
                      }
                      className="rounded-checkbox"
                    />
                  </p>
                </td>
                <td>
                  <p className="mb-0 text-muted fs-14">List all xin nghỉ</p>
                  <p className="mb-0 text-center">
                    <input
                      type="checkbox"
                      defaultChecked={item.list_xinnghi === 1 ? true : false}
                      onChange={() =>
                        handleCheckChangeRole(item.id, "list-xinnghi")
                      }
                      className="rounded-checkbox"
                    />
                  </p>
                </td>
                <td>
                  <p className="mb-0 text-muted fs-14">Công việc</p>
                  <p className="mb-0 text-center">
                    <input
                      type="checkbox"
                      defaultChecked={item.cong_viec === 1 ? true : false}
                      onChange={() =>
                        handleCheckChangeRole(item.id, "cong-viec")
                      }
                      className="rounded-checkbox"
                    />
                  </p>
                </td>
                <td>
                  <p className="mb-0 text-muted fs-14">List all công việc</p>
                  <p className="mb-0 text-center">
                    <input
                      type="checkbox"
                      defaultChecked={item.list_congviec === 1 ? true : false}
                      onChange={() =>
                        handleCheckChangeRole(item.id, "list-congviec")
                      }
                      className="rounded-checkbox"
                    />
                  </p>
                </td>
                <td>
                  <p className="mb-0 text-muted fs-14">Giao công việc</p>
                  <p className="mb-0 text-center">
                    <input
                      type="checkbox"
                      defaultChecked={item.giao_congviec === 1 ? true : false}
                      onChange={() =>
                        handleCheckChangeRole(item.id, "giao-congviec")
                      }
                      className="rounded-checkbox"
                    />
                  </p>
                </td>
                <td>
                  <p className="mb-0 text-muted fs-14">List all tiến độ</p>
                  <p className="mb-0 text-center">
                    <input
                      type="checkbox"
                      defaultChecked={item.list_tiendo === 1 ? true : false}
                      onChange={() =>
                        handleCheckChangeRole(item.id, "list-tiendo")
                      }
                      className="rounded-checkbox"
                    />
                  </p>
                </td>
                <td>
                  <p className="mb-0 text-muted fs-14">List all đánh giá</p>
                  <p className="mb-0 text-center">
                    <input
                      type="checkbox"
                      defaultChecked={item.list_evaluate === 1 ? true : false}
                      onChange={() =>
                        handleCheckChangeRole(item.id, "list-evaluate")
                      }
                      className="rounded-checkbox"
                    />
                  </p>
                </td>
                <td>
                  <p className="mb-0 text-muted fs-14">Hợp đồng</p>
                  <p className="mb-0 text-center">
                    <input
                      type="checkbox"
                      defaultChecked={item.hop_dong === 1 ? true : false}
                      onChange={() =>
                        handleCheckChangeRole(item.id, "hop-dong")
                      }
                      className="rounded-checkbox"
                    />
                  </p>
                </td>
                <td>
                  <p className="mb-0 text-muted fs-14">List all hợp đồng</p>
                  <p className="mb-0 text-center">
                    <input
                      type="checkbox"
                      defaultChecked={item.list_hopdong === 1 ? true : false}
                      onChange={() =>
                        handleCheckChangeRole(item.id, "list-hop-dong")
                      }
                      className="rounded-checkbox"
                    />
                  </p>
                </td>
                <td>
                  <p className="mb-0 text-muted fs-14">Bảng lương</p>
                  <p className="mb-0 text-center">
                    <input
                      type="checkbox"
                      defaultChecked={item.bang_luong === 1 ? true : false}
                      onChange={() =>
                        handleCheckChangeRole(item.id, "bang-luong")
                      }
                      className="rounded-checkbox"
                    />
                  </p>
                </td>
                <td>
                  <p className="mb-0 text-muted fs-14">List all bảng lương</p>
                  <p className="mb-0 text-center">
                    <input
                      type="checkbox"
                      defaultChecked={item.list_bangluong === 1 ? true : false}
                      onChange={() =>
                        handleCheckChangeRole(item.id, "list-bang-luong")
                      }
                      className="rounded-checkbox"
                    />
                  </p>
                </td>
                <td>
                  <p className="mb-0 text-muted fs-14">Kế toán</p>
                  <p className="mb-0 text-center">
                    <input
                      type="checkbox"
                      defaultChecked={item.ke_toan === 1 ? true : false}
                      onChange={() => handleCheckChangeRole(item.id, "ke-toan")}
                      className="rounded-checkbox"
                    />
                  </p>
                </td>
                <td>
                  <p className="mb-0 text-muted fs-14">Duyệt chi phí kế toán</p>
                  <p className="mb-0 text-center">
                    <input
                      type="checkbox"
                      defaultChecked={item.role_ketoan === 1 ? true : false}
                      onChange={() => handleCheckChangeRole(item.id, "role-ke-toan")}
                      className="rounded-checkbox"
                    />
                  </p>
                </td>
                <td>
                  <p className="mb-0 text-muted fs-14">Báo cáo</p>
                  <p className="mb-0 text-center">
                    <input
                      type="checkbox"
                      defaultChecked={item.bao_cao === 1 ? true : false}
                      onChange={() => handleCheckChangeRole(item.id, "bao-cao")}
                      className="rounded-checkbox"
                    />
                  </p>
                </td>
                <td>
                  <p className="mb-0 text-muted fs-14">Đối tác</p>
                  <p className="mb-0 text-center">
                    <input
                      type="checkbox"
                      defaultChecked={item.doi_tac === 1 ? true : false}
                      onChange={() => handleCheckChangeRole(item.id, "doi-tac")}
                      className="rounded-checkbox"
                    />
                  </p>
                </td>
                <td>
                  <p className="mb-0 text-muted fs-14">Ngày tạo</p>
                  <p className="mb-0 text-center">
                    {moment(item.addtime).format("DD/MM/YYYY HH:mm:ss")}
                  </p>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      {/* Modal edit data */}
      {editData && (
        <ModalReact
          modalTitle="Sửa thông tin phân quyền"
          showModal={editData}
          handleClose={handleCloseModalEditData}
          theme={
            <FormEditDataPersonnel
              socketio={socketio}
              result={handleResultEditSuccess}
              dataEdit={dataEditPersonnel.current}
            />
          }
        />
      )}
    </div>
  )
}


