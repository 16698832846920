import HeaderDashboardMobile from "../../../Components/Header/HeaderDashboardMobile"
import { IoAddOutline } from "react-icons/io5"
import classNames from "classnames/bind"
import styles from "../DataSale.module.scss"
import { useState } from "react"
import ModalReact from "../../../Components/Modal/ModalReact"
import FormAddDataSale from "../Component/FormAddDataSale"
const cx = classNames.bind(styles)

function HeaderDataSaleMobile() {
    const [modalAddData, setModalAddData] = useState(false);
    const handleShowModalAddDataSale = () => {
        setModalAddData(true)
    }
    const handleCloseAddModalDataSale = () => { setModalAddData(false) }
    // Trạng thái form khi add success.
    const handleStatusForm = (status_) => {
        if (status_) {
            setModalAddData(false)
        }
    }
    return (
        <>
            <HeaderDashboardMobile hiden="hiden" />
            {/* Button thêm mới */}
            <div>
                <button className={cx("border-0 bg-transparent")} onClick={handleShowModalAddDataSale}>
                    <IoAddOutline className={cx("fs-29", "buttonIcon")} />
                </button>
            </div>
            {/* Modal add */}
            <ModalReact
                modalTitle="Thêm mới khách hàng"
                showModal={modalAddData}
                handleClose={handleCloseAddModalDataSale}
                theme={<FormAddDataSale resultStatus={handleStatusForm}/>}
            />
        </>
    );
}
export default HeaderDataSaleMobile