import { useNavigate } from "react-router-dom";
import { GrFormPrevious, GrFormPreviousLink } from "react-icons/gr"

// tren mobile
function GoBackPage({style}) {
    const navigate = useNavigate()
    const goBack = () => {
        navigate(-1)
    }
    return (
        <button className="border-0 bg-transparent d-flex align-items-center p-0 fs-15" onClick={goBack} style={style}>
           <GrFormPrevious className="fs-29"/>
        </button>
    )
}

export default GoBackPage


// Tre pc
export function GoBack() {
    const navigate = useNavigate()
    const goBack = () => {
      navigate(-1)
    }
    return (
      <button
        className="border-0 bg-transparent d-flex align-items-center p-0 fs-15 op-8"
        onClick={goBack}
      >
        <GrFormPreviousLink className="fs-21" />
        Quay lại
      </button>
    )
}