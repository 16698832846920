import { useLocation } from "react-router-dom"
import HeaderDashboardMobile from "../../../Components/Header/HeaderDashboardMobile"
import ListPersonnelMobile from "./ListPersonnelMobile";
import ListBangChamCongMobile from "./ListBangChamCongMobile";
import JobPageMobile from "./JobPageMobile";
import ListContractMobile from "./ListContractMobile";
import ListSalaryMobile from "./ListSalaryMobile";
import ListKetoanMobile from "./ListKetoanMobile";
import ListTaichinhcanhanMobile from "./ListTaichinhcanhanMobile";
import ListCateReportMobile from "./ListCateReportMobile";

function JobHomePageMobile() {
    const path = useLocation()
    const currentPath = path.pathname;

    return (
      <div>
        {/* Header */}
        {currentPath !== "/tai-chinh-ca-nhan" && (
          <HeaderDashboardMobile hiden="hiden" />
        )}
        {/* Content */}
        <div>
          {currentPath === "/tai-chinh-ca-nhan" && <ListTaichinhcanhanMobile />}
        </div>
        <div style={{ marginTop: "5rem" }} className="px-3">
          {currentPath === "/nhan-su" && <ListPersonnelMobile />}
          {currentPath === "/cham-cong" && <ListBangChamCongMobile />}
          {currentPath === "/cong-viec" && <JobPageMobile />}
          {currentPath === "/hop-dong" && <ListContractMobile />}
          {currentPath === "/bang-luong" && <ListSalaryMobile />}
          {currentPath === "/ke-toan" && <ListKetoanMobile />}
          {currentPath === "/bao-cao" && <ListCateReportMobile />}
        </div>
      </div>
    )
}
export default JobHomePageMobile