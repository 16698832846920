import { IoMdCheckmarkCircleOutline, IoMdTrendingUp } from "react-icons/io"
import { IoNewspaperOutline, IoTicketOutline } from "react-icons/io5"
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti"
import LoadingSpinner from "../../../../Components/Loading/LoadingSpinner"
import { useEffect, useRef, useState } from "react"

// Thống kê.
function CardStatisticalContractMobile({ socketio, dataUser, objectSearch }) {
  const [dataStatistical, setDataStatistical] = useState([])
  const dataStatisticalRef = useRef([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const paramsContract = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        list_hopdong: dataUser[0].list_hopdong,
        user_id: dataUser[0].id_user,
        daysSearch: objectSearch.daysSearch,
        monthSearch: objectSearch.monthSearch,
        textSearch: objectSearch.textSearch,
        lastMonth: objectSearch.lastMonth,
      },
    }
    // statistical contract.
    socketio.emit(
      "client-get-search-lastMonth-currentMonth-revenue-contract",
      paramsContract,
    )
    socketio.on(
      "server-get-search-lastMonth-currentMonth-revenue-contract",
      (dataStatistical) => {
        setDataStatistical([...dataStatistical])
        dataStatisticalRef.current = [...dataStatistical]
        setLoading(false)
      },
    )
    return () => {
      socketio.off("client-get-search-lastMonth-currentMonth-revenue-contract")
      socketio.off("server-get-search-lastMonth-currentMonth-revenue-contract")
    }
  }, [objectSearch])

  // Tính tủy suất so với tháng trước.
  const [objectStatistical, setObjectStatistical] = useState({
    tysuatLastCurrent: 0,
    revenueLastMonth: 0,
    revenueCurentMonth: 0,
    countContract: 0,
    hoahongMonth: 0,
    phantramHoahong: 0,
  })
  useEffect(() => {
    if (dataStatistical.length > 0) {
      let tysuat =
        ((Number(dataStatistical[0].total_revenue_current_month) -
          Number(dataStatistical[0].total_revenue_last_month)) /
          Number(dataStatistical[0].total_revenue_current_month)) *
        100
      let formatTySuat = Number.isInteger(tysuat) ? tysuat : tysuat.toFixed(1)
      // Doanh thu tháng trước
      let revenueLastMonth = Number(dataStatistical[0].total_revenue_last_month)
        .toLocaleString()
        .replace(/[.]/, ",")
      // Doanh thu tháng này.
      let revenueCurentMonth = Number(
        dataStatistical[0].total_revenue_current_month,
      )
        .toLocaleString()
        .replace(/[.]/g, ",")
      // số hợp đồng tháng.
      let countContract =
        dataStatistical[0].count_contract < 10
          ? "0" + dataStatistical[0].count_contract
          : Number(dataStatistical[0].count_contract)
              .toLocaleString()
              .replace(/[.]/, ",")
      // Hoa hồng tháng này.
      let hoahongMonth = Number(dataStatistical[0].total_hoahong)
        .toLocaleString()
        .replace(/[.]/, ",")
      // Phần trăm hoa hồng.
      let phantramHoahong =
        (Number(dataStatistical[0].total_hoahong) /
          Number(dataStatistical[0].total_revenue_current_month)) *
        100
      let formatHoahong = Number.isInteger(phantramHoahong)
        ? phantramHoahong
        : phantramHoahong.toFixed(2)

      setObjectStatistical({
        tysuatLastCurrent: formatTySuat,
        revenueLastMonth: revenueLastMonth,
        revenueCurentMonth: revenueCurentMonth,
        countContract: countContract,
        hoahongMonth: hoahongMonth,
        phantramHoahong: formatHoahong,
      })
    } else {
      setObjectStatistical({
        tysuatLastCurrent: 0,
        revenueLastMonth: 0,
        revenueCurentMonth: 0,
        countContract: 0,
        hoahongMonth: 0,
        phantramHoahong: 0,
      })
    }
  }, [dataStatistical])

  // Quản lý cập nhật lại danh thu.
  useEffect(() => {
    socketio.on("server-update-statistical-revenue-contract", (dataUpdate) => {
      setDataStatistical([...dataUpdate])
      dataStatisticalRef.current = [...dataUpdate]
    })
    return () => {
      socketio.off("server-update-statistical-revenue-contract")
    }
  }, [])

  if (loading) {
    return <LoadingSpinner />
  }
    return (
      // <div className="wrapper_ground">
      //   <div className="list_items">
      //     <div className="card custom-card mb-0 rounded-4">
      //       <div className="card-body">
      //         <div className="d-flex align-items-top">
      //           <div className="me-3">
      //             <span className="avatar avatar-md p-2 bg-warning">
      //               <IoMdTrendingUp />
      //             </span>
      //           </div>
      //           <div className="flex-fill">
      //             <div className="d-flex mb-1 align-items-top justify-content-between">
      //               <h5 className="fw-semibold mb-0 fs-18">
      //                 {objectStatistical.revenueLastMonth}đ
      //               </h5>
      //               {/* <div className="text-success fw-semibold">
      //                 <TiArrowSortedUp />
      //                 5.05%
      //               </div> */}
      //             </div>
      //             <p className="mb-0 fs-14 text-muted">Doanh thu tháng trước</p>
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      //   <div className="list_items">
      //     <div className="card custom-card mb-0 rounded-4">
      //       <div className="card-body">
      //         <div className="d-flex align-items-top">
      //           <div className="me-3">
      //             <span className="avatar avatar-md p-2 bg-success">
      //               <IoMdCheckmarkCircleOutline />
      //             </span>
      //           </div>
      //           <div className="flex-fill">
      //             <div className="d-flex mb-1 align-items-top justify-content-between">
      //               <h5 className="fw-semibold mb-0 fs-18">
      //                 {objectStatistical.revenueCurentMonth}đ
      //               </h5>
      //               <div>
      //                 {objectStatistical.tysuatLastCurrent > 0 ? (
      //                   <span className="text-success fw-semibold">
      //                     <TiArrowSortedUp />
      //                     {objectStatistical.tysuatLastCurrent}%
      //                   </span>
      //                 ) : (
      //                   <span className="text-danger fw-semibold">
      //                     <TiArrowSortedDown />
      //                     {objectStatistical.tysuatLastCurrent}%
      //                   </span>
      //                 )}
      //               </div>
      //             </div>
      //             <p className="mb-0 fs-14 text-muted">Doanh thu tháng này</p>
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      //   <div className="list_items">
      //     <div className="card custom-card mb-0 rounded-4">
      //       <div className="card-body">
      //         <div className="d-flex align-items-top">
      //           <div className="me-3">
      //             <span className="avatar avatar-md p-2 bg-primary">
      //               <IoNewspaperOutline />
      //             </span>
      //           </div>
      //           <div className="flex-fill">
      //             <div className="d-flex mb-1 align-items-top justify-content-between">
      //               <h5 className="fw-semibold mb-0 fs-18">
      //                 {objectStatistical.countContract}
      //               </h5>

      //               {/* <div className="text-danger fw-semibold">
      //                 <i className="ri-arrow-down-s-fill me-1 align-middle"></i>
      //                 -1.05%
      //               </div> */}
      //             </div>
      //             <p className="mb-0 fs-14 text-muted">
      //               <span className="me-1">Số lượng hợp đồng</span>
      //             </p>
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      //   <div className="list_items">
      //     <div className="card custom-card mb-0 rounded-4">
      //       <div className="card-body">
      //         <div className="d-flex align-items-top">
      //           <div className="me-3">
      //             <span className="avatar avatar-md p-2 bg-info">
      //               <IoTicketOutline />
      //             </span>
      //           </div>
      //           <div className="flex-fill">
      //             <div className="d-flex mb-1 align-items-top justify-content-between">
      //               <h5 className="fw-semibold mb-0 fs-18">
      //                 {objectStatistical.hoahongMonth}đ
      //               </h5>
      //               <div className="text-primary fw-semibold">
      //                 <i className="ri-arrow-down-s-fill me-1 align-middle"></i>
      //                 {objectStatistical.phantramHoahong}%
      //               </div>
      //             </div>
      //             <p className="mb-0 fs-14 text-muted">Hoa hồng nhận</p>
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      //   </div>
      <div>
        {/* Hoa hồng */}
        <div className="p-3 rounded-4" style={{ background: "#fafafa" }}>
          <div className="d-flex justify-content-between">
            <div>
              Hoa hồng nhận{" "}
              <span>
                {Number.isNaN(objectStatistical.phantramHoahong)
                  ? objectStatistical.phantramHoahong
                  : 0}
                %
              </span>
            </div>
            <div>{objectStatistical.hoahongMonth}đ</div>
          </div>
          <div
            style={{ borderBottom: "1px solid #ffa885ab" }}
            className="mt-2 mb-2"
          ></div>
          <div className="wrapper_ground">
            <div className="list_items">
              <div>Tháng trước</div>
              <p className="mb-0">{objectStatistical.revenueLastMonth}đ</p>
            </div>
            <div className="list_items">
              <div>
                Tháng này
                {objectStatistical.tysuatLastCurrent > 0 ? (
                  <span className="text-success fw-semibold fs-14">
                    <TiArrowSortedUp />
                    {isFinite(objectStatistical.tysuatLastCurrent) ? objectStatistical.tysuatLastCurrent : 0}%
                  </span>
                ) : (
                  <span className="text-danger fw-semibold fs-14">
                    <TiArrowSortedDown />
                    {isFinite(objectStatistical.tysuatLastCurrent)
                      ? objectStatistical.tysuatLastCurrent
                      : 0}
                    %
                  </span>
                )}
              </div>
              <p className="mb-0"> {objectStatistical.revenueCurentMonth}đ</p>
            </div>
            <div className="list_items">
              <div>Hợp đồng</div>
              <p className="mb-0">{objectStatistical.countContract}</p>
            </div>
          </div>
        </div>
      </div>
    )
}

export default CardStatisticalContractMobile
