import Tippy from "@tippyjs/react"

function ChartExpenseYear({ dataCateExpenseYear }) {
  // Tổng doan thu năm
  let arrExpense = dataCateExpenseYear.map((item) => item.total_expense)
  let totalExpenseYear = arrExpense.reduce((a, b) => Number(a) + Number(b))

  return (
    <div className="card custom-card">
      <div className="card-body">
        <div className="alert alert-danger text-center fs-16" role="alert">
          Biểu đồ chi phí năm
          <p className="mb-0">
            {totalExpenseYear.toLocaleString().replace(/[.]/g, ",")}đ
          </p>
        </div>
        {dataCateExpenseYear.length === 0 && (
          <div className="text-center mt-3">Chưa có dữ liệu.</div>
        )}
        {dataCateExpenseYear.length > 0 &&
          dataCateExpenseYear.map((item, index) => {
            //   set ty suat
            let tysuatExpense =
              (Number(item.total_expense) / totalExpenseYear) * 100
            return (
              <Tippy
                content={
                  "Chi phí " +
                  item.total_expense.toLocaleString().replace(/[.]/g, ",")
                }
                key={index}
              >
                <div
                  className="progress progress-xl progress-animate custom-progress-4 rounded-0 danger"
                  role="progressbar"
                  aria-valuenow="10"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ borderBottom: "1px solid white" }}
                >
                  <div
                    className="progress-bar rounded-0"
                    style={{
                      width: tysuatExpense + "%",
                      background: "#81ca9c",
                    }}
                  >
                    {Number.isInteger(tysuatExpense)
                      ? tysuatExpense
                      : tysuatExpense.toFixed(2)}
                    %
                  </div>
                  <div className="progress-bar-label">
                    {tysuatExpense < 10 && (
                      <span className="me-1">
                        {Number.isInteger(tysuatExpense)
                          ? tysuatExpense
                          : tysuatExpense.toFixed(2) + "%"}
                      </span>
                    )}
                    {item.loai_chiphi}
                  </div>
                </div>
              </Tippy>
            )
          })}
      </div>
    </div>
  )
}
export default ChartExpenseYear
