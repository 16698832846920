import classNames from "classnames/bind"
import styles from "../../Job.module.scss"
import SearchHorizontal from "../../../../Components/Search/SearchHorizontal";
import { useRef, useState } from "react";
import { TbCalendarSearch } from "react-icons/tb";
import { RiSearch2Line } from "react-icons/ri";
import moment from "moment";
const cx = classNames.bind(styles);


function SearchDataMonthChamcong({ resultSearch }) {
  const [active, setActive] = useState("text-search")
  const objectSearch = useRef({
    monthSearch: "",
    textSearch: "",
  })
  const [valueMonth, setValueMonth] = useState(moment().format("YYYY-MM"))
  const handleValueMonth = (e) => {
    setValueMonth(e.target.value)
    objectSearch.current = {
      monthSearch: e.target.value,
      textSearch: objectSearch.current.textSearch,
    }
    resultSearch(objectSearch.current)
  }
    const handleValueSearchText = (value) => {
      objectSearch.current = {
        monthSearch: objectSearch.current.monthSearch,
        textSearch: value,
      }
      resultSearch(objectSearch.current)
    }
  
  // Quản lý active search.
  const handleActiveSearch = (cate) => {
    setActive(cate)
  }
    return (
      <div className={cx("wrapper_ground")}>
        {/* Tìm kiếm theo tháng */}
        {active !== "month-search" && (
          <button
            onClick={() => handleActiveSearch("month-search")}
            className={cx(
              "rounded-3 p-0 btn btn-icon btn-primary-transparent btn-wave text-muted",
              "search-data",
            )}
            style={{ border: "2px solid #ff661d94" }}
          >
            <TbCalendarSearch className="fs-21 icon-svg" />
          </button>
        )}
        {active === "month-search" && (
          <div className="list_items col-9">
            <input
              type="month"
              className={cx(
                "class_input",
                "rounded-3 col-12",
                "search-data",
                active === "month-search" && "active-search",
              )}
              value={valueMonth}
              onChange={handleValueMonth}
              style={{ border: "2px solid #ff661d94" }}
            />
          </div>
        )}

        {/* Tìm kiếm theo text */}
        {active === "text-search" ? (
          <div className="list_items">
            <SearchHorizontal
              className={cx(
                "rounded-3",
                "search-data",
                active === "text-search" && "active-search",
              )}
              style={{
                backgroundColor: "white",
                border: "2px solid #ff661d94",
                height: "36.4px",
                width: "100%",
              }}
              result={handleValueSearchText}
            />
          </div>
        ) : (
          <button
            className={cx(
              "rounded-3 p-0 btn btn-icon btn-primary-transparent btn-wave text-muted",
              "search-data",
            )}
            onClick={() => handleActiveSearch("text-search")}
            style={{ border: "2px solid #ff661d94" }}
          >
            <RiSearch2Line className="fs-21 icon-svg" />
          </button>
        )}
      </div>
    )
}
export default SearchDataMonthChamcong