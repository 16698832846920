import moment from "moment";
import { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"

function FormXinvangmat({socketio, dataUser, result}) {
    const { register, handleSubmit, formState: { errors }, reset } = useForm()
  const checkChange = useRef();
  const giolamviec = useRef([])
  const [loading, setLoading] = useState(true)
  const [calamviec, setCalamviec] = useState("")
  const [listDonvangmat, setListDonvangmat] = useState({
    ca_sang: [],
    ca_chieu: [],
    ca_ngay: [],
    check_length: 0
  })
  
  // Quản lý thời gian bắt đầu.
  useEffect(() => {
    // thời gian làm việc
    const paramsGiolamviec = {
      iden: process.env.REACT_APP_IDEN_DASH,
    }
    socketio.emit("client-get-data-giolamviec", paramsGiolamviec)
    socketio.on("server-get-data-giolamviec", (data) => {
      giolamviec.current = [...data]
      setLoading(false)
    })

    // Check xem hôm nay đã gửi đơn vắng mặt chưa.
    const paramsCheck = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {user_id: dataUser[0].id_user}
    }
    socketio.emit("client-check-today-xinvangmat", paramsCheck)
    socketio.on("server-check-today-xinvangmat", (dataCheck) => {
      if (dataCheck.length > 0) {
        let arr_Casang = dataCheck.filter((item) => {
          return item.thoigianvangmat === "ca_sang"
        })
        let arr_Cachieu = dataCheck.filter((item) => {
          return item.thoigianvangmat === "ca_chieu"
        })
        let arr_Cangay = dataCheck.filter((item) => {
          return item.thoigianvangmat === "ca_ngay"
        })
        setListDonvangmat({
          ca_sang: [...arr_Casang],
          ca_chieu: [...arr_Cachieu],
          ca_ngay: [...arr_Cangay],
          check_length: dataCheck.length,
        })
      }
      
    })
    return () => {
      socketio.off("client-get-data-giolamviec")
      socketio.off("server-get-data-giolamviec")
      socketio.off("client-check-today-xinvangmat")
      socketio.off("server-check-today-xinvangmat")
    }
  }, []);

  // Quản lý ca làm việc.
  const handleOptionCalamviec = (e) => {
    setCalamviec(e.target.value);
  }

  // Quản lý submit
    const onSubmit = (object) => {
      object.user_id = dataUser[0].id_user
      if (dataUser[0].ca_lamviec === 2) {
        if (calamviec === "ca_sang") {
          object.date_start = moment().format("YYYY-MM-DD" + giolamviec.current[0].ca_sang_a)
          object.date_end = moment().format("YYYY-MM-DD" + giolamviec.current[0].ca_sang_b)
          object.ngay_nghi = 0.5
        } else if (calamviec === "ca_chieu") {
          object.date_start = moment().format("YYYY-MM-DD" + giolamviec.current[0].ca_chieu_a)
          object.date_end = moment().format("YYYY-MM-DD" + giolamviec.current[0].ca_chieu_b)
          object.ngay_nghi = 0.5
        } else {
          object.date_start = moment().format("YYYY-MM-DD" + giolamviec.current[0].ca_sang_a)
          object.date_end = moment().format("YYYY-MM-DD" + giolamviec.current[0].ca_chieu_b)
          object.ngay_nghi = 1
        }
      } else {
        object.date_start = moment().format("YYYY-MM-DD" + giolamviec.current[0].ca_sang_a)
        object.date_end = moment().format("YYYY-MM-DD" + giolamviec.current[0].ca_chieu_b)
        object.thoigianvangmat = "ca_ngay"
        object.ngay_nghi = 0.5
      }
      const paramsCreate = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: object,
      }
      socketio.emit("client-create-xinvangmat", paramsCreate)
      socketio.on("server-create-xinvangmat", (data) => {
        if (data === "success") {
          checkChange.current = object
          reset()
          alert("Đơn đã được gửi, quản lý sẽ xem xét và phản hồi bạn.")
          result(true)
        }
      })
    }
  useEffect(() => {
    return () => {
      socketio.off("client-check-today-xinvangmat")
      socketio.off("server-check-today-xinvangmat")
      socketio.off("client-create-xinvangmat")
      socketio.off("server-create-xinvangmat")
    }
  }, [checkChange.current]);

  if (loading) {
    return <div>Đang tải...</div>
  }
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="col-xl-12 col-md-12">
          <div className="row">
            <div className="form-floating mb-3 col-12">
              <input
                type="text"
                className="form-control"
                placeholder="Tên input"
                {...register("title", { required: true })}
              />
              <label className="fs-15 ms-2 text-muted">
                Ví dụ: Đơn vắng mặt ngày 26/07
              </label>
              {errors.title && (
                <span className="text-danger fs-12">
                  *Bạn cần nhập tiêu đề cho nội dung vắng mặt
                </span>
              )}
            </div>
            <div className="form-floating mb-3 col-12">
              <textarea
                className="form-control"
                placeholder="Lý do vắng mặt"
                style={{ height: "100px" }}
                {...register("content", { required: true })}
              ></textarea>
              <label className="fs-15 ms-2 text-muted">Nội dung vắng mặt</label>
              {errors.content && (
                <span className="text-danger fs-12">
                  *Bạn cần nhập nội dung vắng mặt
                </span>
              )}
            </div>
          </div>
        </div>
        {dataUser[0].ca_lamviec === 2 && (
          <div className="col-xl-12 col-md-12">
            <div className="row">
              <div className="form-floating mb-3 col-12">
                <select
                  className="form-select"
                  {...register("thoigianvangmat", { required: true })}
                  value={calamviec}
                  onChange={handleOptionCalamviec}
                >
                  <option value="">Chọn thời gian vắng mặt</option>
                  {listDonvangmat.check_length === 0 && (
                    <>
                      <option value="ca_sang">Ca sáng</option>
                      <option value="ca_chieu">Ca chiều</option>
                      <option value="ca_ngay">Cả ngày</option>
                    </>
                  )}
                  {listDonvangmat.ca_sang.length === 1 &&
                    listDonvangmat.ca_chieu.length === 0 && (
                      <>
                        <option value="ca_chieu">Ca chiều</option>
                      </>
                    )}
                  {listDonvangmat.ca_chieu.length === 1 &&
                    listDonvangmat.ca_sang.length === 0 && (
                      <>
                        <option value="ca_sang">Ca sáng</option>
                      </>
                    )}
                  {listDonvangmat.ca_sang.length === 1 &&
                    listDonvangmat.ca_chieu.length === 1 && (
                      <option value="">Hôm nay bạn đã nộp 2 đơn rồi</option>
                    )}
                </select>
                <label className="fs-15 ms-2 text-muted">
                  Bạn vắng mặt vào ca nào ?
                </label>
                {errors.thoigianvangmat && (
                  <span className="text-danger fs-12">
                    *Bạn cần chọn ca vắng mặt
                  </span>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="col-xl-12 col-md-12">
          <div className="row">
            <div className="form-floating mb-3 col-12">
              <input
                type="datetime-local"
                className="form-control"
                placeholder="Tên input"
                {...register("addtime", { required: true })}
              />
              <label className="fs-15 ms-2 text-muted">Ngày có hiệu lực</label>
              {errors.addtime && (
                <span className="text-danger fs-14">
                  *Bạn cần chọn thời gian bắt đầu có hiệu lực.
                </span>
              )}
            </div>
          </div>
        </div>
        {/* Button */}
        {dataUser[0].ca_lamviec === 1 && listDonvangmat.check_length === 0 && (
          <div className="px-4 py-3 border-top border-block-start-dashed d-flex justify-content-center">
            <button className="btn btn-primary-light m-1" type="submit">
              Nộp đơn vắng mặt
            </button>
          </div>
        )}

        {dataUser[0].ca_lamviec === 2 && listDonvangmat.check_length < 2 && (
          <div className="px-4 py-3 border-top border-block-start-dashed d-flex justify-content-center">
            <button className="btn btn-primary-light m-1" type="submit">
              Nộp đơn vắng mặt
            </button>
          </div>
        )}
      </form>
    )
}
export default FormXinvangmat