import React from "react"
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts"

// const data = [
//   {
//     name: "Tháng 1",
//     revenue: 4000,
//     expense: 2400,
//     profit: 1600,
//   },
//   {
//     name: "Tháng 2",
//     revenue: 3000,
//     expense: 1398,
//     profit: 1602,
//   },
//   {
//     name: "Tháng 3",
//     revenue: 2000,
//     expense: 9800,
//     profit: -7800,
//   },
//   {
//     name: "Tháng 4",
//     revenue: 2780,
//     expense: 3908,
//     profit: -1128,
//   },
//   {
//     name: "Tháng 5",
//     revenue: 1890,
//     expense: 4800,
//     profit: -2910,
//   },
//   {
//     name: "Tháng 6",
//     revenue: 2390,
//     expense: 3800,
//     profit: -1410,
//   },
//   {
//     name: "Tháng 7",
//     revenue: 3490,
//     expense: 4300,
//     profit: -810,
//   },
// ]
const formatCurrency = (value) => {
  return new Intl.NumberFormat("vi-VN", {
    style: "currency",
    currency: "VND",
  }).format(value)
}

const RevenueExpenseProfitChart = ({data}) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        data={data}
        margin={{
          top: 1,
          right: 1,
          left: 1,
          bottom: 33,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" tick={{ fontSize: 12 }} />
        {/* <YAxis tick={{ fontSize: 14 }}/> ẩn giá tiền bên trái*/}
        <Tooltip
          formatter={(value) => formatCurrency(value)}
          contentStyle={{ fontSize: 16 }}
        />
        <Tooltip />
        <Legend wrapperStyle={{ fontSize: 14 }} />
        <Bar dataKey="revenue" fill="#8884d8" name="Doanh thu" />
        <Bar dataKey="expense" fill="#82ca9d" name="Chi phí" />
        <Bar dataKey="profit" fill="#ffc658" name="Lợi nhuận" />
      </BarChart>
    </ResponsiveContainer>
  )
}

export default RevenueExpenseProfitChart
