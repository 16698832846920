import { useEffect, useRef, useState } from "react"
import AppContext from "./CreateContexts"
import Loading from "../../Components/Loading"
import io from "socket.io-client"

import { setMaDinhdanh } from "../reuses"
import { useNavigate } from "react-router-dom"

function ContextData({ children }) {
  const [isOnline, setIsOnline] = useState(navigator.onLine)
  const socket = useRef() // của hệ thống.
  const socketBDS = useRef() // của batdongsan
  const [getDataUser, setGetDataUser] = useState([])
  const [loading, setLoading] = useState(true)
  const checkLocal = localStorage.getItem("object")
  const checkDataUser = useRef([])

  let user
  if (checkLocal) {
    user = JSON.parse(localStorage.getItem("object"))
  }

  const phone = user ? user.p : ""
  const params = {
    phone: phone,
  };
  // khi get data từ sever cần gửi kèm token
  const token = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      identification: process.env.REACT_APP_IDEN_DASH,
    },
    body: JSON.stringify(params),
  }

  // Kiểm tra internet.
  useEffect(() => {
    const handleOnline = () => setIsOnline(true)
    const handleOffline = () => setIsOnline(false)

    window.addEventListener("online", handleOnline)
    window.addEventListener("offline", handleOffline)

    return () => {
      window.removeEventListener("online", handleOnline)
      window.removeEventListener("offline", handleOffline)
    }
  }, [isOnline])

  // connect socket
  useEffect(() => {
    if (isOnline) {
      socket.current = io(process.env.REACT_APP_DOMAIN)
      socketBDS.current = io(process.env.REACT_APP_DOMAIN_BDSVIEW360)

      return () => {
        socket.current.disconnect()
        socketBDS.current.disconnect()
      }
    }
    
  }, [])
  // Dùng để gọi dữ liệu
  useEffect(() => {
    // Kiểm tra kết nối internet
    if (isOnline) {
      // Get data user
      const fetchData = async () => {
        try {
          if (phone !== "") {
            const response = await fetch(
              process.env.REACT_APP_GET_MARKET_USER,
              token,
            )
            const dataRes = await response.json();
            if (dataRes.length > 0) {
              checkDataUser.current = [...dataRes];
              // Cập nhật trạng thái online offline
              socket.current.emit("client-connect-app", dataRes[0].id_user)
            } 
            
          } else {
            // Nếu chưa đăng nhập lần nào chuyển sang trang login
            setLoading(false)
          }
          
        } catch (error) {
          console.log(error)
        }
      }
      fetchData()
    }
  }, [isOnline])

  // Quản lý user online offline.
  useEffect(() => {
    if (isOnline) {
      // thay đổi trạng thái online offline khi vào hệ thống.
      socket.current.on("server-online-offline", (dataUserUpdate) => {
        const checkOnOff = checkDataUser.current.filter((item) => {
          return item.id_user === dataUserUpdate[0].id_user
        })
        if (checkOnOff.length > 0) {
          // loại bỏ row pass.
          const dataUserNew = dataUserUpdate.find((item) => item)
          delete dataUserNew.matkhau
          setGetDataUser([...dataUserUpdate])
          setLoading(false)
        }
      })
      return () => {
        socket.current.off("client-connect-app")
        socket.current.off("server-online-offline")
      }
    }
    
  }, [isOnline, checkDataUser.current])


  // Kiểm tra đường truyền internet.
  if (!isOnline) {
    return (
      <div className="text-center mt-3">
        Đường truyền internet không ổn định
      </div>
    )
  }
  if (loading) {
    // Kiểm tra xem loading data thành công chưa
    return <Loading className="border-2"/>
  }

  return (
    <AppContext.Provider
      value={{
        dataUser: getDataUser,
        connect: isOnline,
        socketio: socket.current,
        socketioBDS: socketBDS.current,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default ContextData


