import { useContext, useEffect, useLayoutEffect, useRef, useState } from "react"
import SearchDataMonthChamcong from "../Component/Chamcong/SearchDataMothChamcong"
import moment from "moment"
import { useNavigate } from "react-router-dom"
import classNames from "classnames/bind"
import styles from "../Job.module.scss"
import AppContext from "../../../handle-reuses/Contexts/CreateContexts"
import { PiNotepadLight } from "react-icons/pi"
import Tippy from "@tippyjs/react"
import { getDaysArray } from "../../../handle-reuses/reuses"
import { formatNumber } from "chart.js/helpers"
import { IoDocumentTextOutline, IoFingerPrintOutline } from "react-icons/io5"
import { TiWarningOutline } from "react-icons/ti"
import { FiSend } from "react-icons/fi"
import ModalReact from "../../../Components/Modal/ModalReact"
import FormDonXinNghiviec from "../Component/Chamcong/FormDonXinNghiviec"
import FormTangca from "../Component/Chamcong/FormTangca"
import FormXinvangmat from "../Component/Chamcong/FormXinvangmat"
import FormChamcong from "../Component/Chamcong/FormChamcong"
import { BsDot } from "react-icons/bs"
import ListVangmat from "../Component/Chamcong/ListVangmat"
import ListTangca from "../Component/Chamcong/ListTangca"
import ListDonNghiviec from "../Component/Chamcong/ListDonNghiviec"
const cx = classNames.bind(styles)

function ListBangChamCongMobile() {
    const { socketio, dataUser } = useContext(AppContext)
    const goBack = useNavigate()
    useEffect(() => {
      if (dataUser[0].cham_cong !== 1) {
        goBack("/")
        alert("Bạn không có quyền truy cập vào trang này.")
      }
    }, [])

    // Quản lý danh sách nhân viên chấm công.
    const [listPersonnel, setListPersonnel] = useState([])
    const [listTimekeeping, setListTimeKeeping] = useState([])
    const dataTimekeepingRef = useRef([])
    const listPersonnelRef = useRef([])
    const monthRef = useRef(moment().format("YYYY-MM"))
    const handleGetDataPersonnelTimekeeping = () => {
      // Điều kiện hiển thị danh sách chấm công.
      if (dataUser[0].list_chamcong === 1) {
        let params = {
          iden: process.env.REACT_APP_IDEN_DASH,
        }
        socketio.emit("client-get-list-all-personnel-timekeeping", params)
        socketio.on("server-get-list-all-personnel-timekeeping", (data) => {
          setListPersonnel([...data])
          listPersonnelRef.current = [...data]
        })
      } else {
        let params = {
          iden: process.env.REACT_APP_IDEN_DASH,
          object: { user_id: dataUser[0].id_user },
        }
        socketio.emit("client-get-list-id-personnel-timekeeing", params)
        socketio.on("server-get-list-id-personnel-timekeeing", (data) => {
          setListPersonnel([...data])
          listPersonnelRef.current = [...data]
        })
      }
    }
    useEffect(() => {
      // Get data ban đầu
      handleGetDataPersonnelTimekeeping()
      return () => {
        socketio.off("client-get-list-all-personnel-timekeeping")
        socketio.off("server-get-list-all-personnel-timekeeping")
        socketio.off("client-get-list-id-personnel-timekeeing")
        socketio.off("server-get-list-id-personnel-timekeeing")
      }
    }, [])

    // Quản lý get data timekeeping.
    const [monthSearch, setMonthSearch] = useState(moment().format("YYYY-MM"))
    const handleGetListTimekeeping = (monthSearch_) => {
      let arrTimekeeping = []
      for (let i = 0; i < listPersonnel.length; i++) {
        let paramsTimekeeping = {
          iden: process.env.REACT_APP_IDEN_DASH,
          object: {
            user_id: listPersonnel[i].id_user,
            addtime: monthSearch_,
          },
        }
        socketio.emit("client-get-list-id-month-timekeeping", paramsTimekeeping)
      }
      socketio.on("server-get-list-id-month-timekeeping", (dataTimekeeping) => {
        if (dataTimekeeping.length > 0) {
          arrTimekeeping.push(...dataTimekeeping)
          setListTimeKeeping([...arrTimekeeping])
          dataTimekeepingRef.current = [...arrTimekeeping]
        }
      })
    }
    useEffect(() => {
      if (listPersonnel.length > 0) {
        handleGetListTimekeeping(monthSearch)
      }
      return () => {
        socketio.off("client-get-list-id-month-timekeeping")
        socketio.off("server-get-list-id-month-timekeeping")
      }
    }, [listPersonnel])

    // Quản lý modal chấm công.
    const [modalChamcong, setModalChamcong] = useState(false)
    const handleAddChamcong = () => {
      setModalChamcong(true)
    }
    const handleCloseModalChamcong = () => {
      setModalChamcong(false)
    }
    const handleChamcongSuccess = (object) => {
      if (object.status) {
        // setModalChamcong(false)
        let dataUpdate = dataTimekeepingRef.current
        let checkIndex = dataUpdate.findIndex(
          (item) => item.id === object.dataUpdateTimekeeping[0].id,
        )
        if (checkIndex > 0) {
          dataUpdate[checkIndex] = object.dataUpdateTimekeeping[0]
          setListTimeKeeping([...dataUpdate])
          dataTimekeepingRef.current = [...dataUpdate]
        } else {
          dataUpdate.push(object.dataUpdateTimekeeping[0])
          setListTimeKeeping([...dataUpdate]);
          dataTimekeepingRef.current = [...dataUpdate]
        }
      }
    }

    // Quản lý xin vắng mặt.
    const [modalXinvangmat, setModalXinvangmat] = useState(false)
    const handleModalXinvangmat = () => {
      setModalXinvangmat(true)
    }
    const handleCloseModalXinvangmat = () => {
      setModalXinvangmat(false)
    }
    const handleAddXinvangmatSuccess = (status_) => {
      if (status_) {
        setModalXinvangmat(false)
      }
    }

    // Quản lý tăng ca thêm giờ.
    const [modalTangca, setModalTangca] = useState(false)
    const handleShowModalTangca = () => {
      setModalTangca(true)
    }
    const handleCloseModalTangca = () => {
      setModalTangca(false)
    }
    const handleAddTangcaSuccess = (status_) => {
      if (status_) {
        setModalTangca(false)
      }
    }

    // Quản lý tạo mới đơn xin nghỉ việc.
    const [modalDonNghiviec, setModalDonNghiviec] = useState(false)
    const handleModalDonNghiviec = () => {
      setModalDonNghiviec(true)
    }
    const handleCloseModalDonNghiviec = () => {
      setModalDonNghiviec(false)
    }

    // Quản lý search data timekeeping.
    const handleResultSearchTimekeeping = (object) => {
      if (object.textSearch && object.monthSearch) {
        const paramsSearch = {
          iden: process.env.REACT_APP_IDEN_DASH,
          object: { keywords: object.textSearch },
        }
        socketio.emit("client-search-personnel-timekeeping", paramsSearch)
        socketio.on("server-search-personnel-timekeeping", (dataSearch) => {
          setListPersonnel([...dataSearch])
        })
        // Set nếu month search.
        setMonthSearch(object.monthSearch)
        const paramsSearch_ = {
          iden: process.env.REACT_APP_IDEN_DASH,
          object: { addtime: object.monthSearch },
        }
        socketio.emit("client-search-list-month-timekeeping", paramsSearch_)
        socketio.on(
          "server-search-list-month-timekeeping",
          (updateTimekeeping) => {
            setListTimeKeeping([...updateTimekeeping])
          },
        )
      } else if (object.textSearch === "" && object.monthSearch === "") {
        setListPersonnel([...listPersonnelRef.current])
        setMonthSearch(moment().format("YYYY-MM"))
      } else if (object.textSearch === "" && object.monthSearch) {
        setMonthSearch(object.monthSearch)
        const paramsSearch_ = {
          iden: process.env.REACT_APP_IDEN_DASH,
          object: { addtime: object.monthSearch },
        }
        socketio.emit("client-search-list-month-timekeeping", paramsSearch_)
        socketio.on(
          "server-search-list-month-timekeeping",
          (updateTimekeeping) => {
            setListTimeKeeping([...updateTimekeeping])
          },
        )
      } else if (object.textSearch && object.monthSearch === "") {
        const paramsSearch = {
          iden: process.env.REACT_APP_IDEN_DASH,
          object: { keywords: object.textSearch },
        }
        socketio.emit("client-search-personnel-timekeeping", paramsSearch)
        socketio.on("server-search-personnel-timekeeping", (dataSearch) => {
          setListPersonnel([...dataSearch])
        })
      }
      // set tiêu đề thán cho bảng lương tháng.
      monthRef.current = object.monthSearch
    }
    useEffect(() => {
      return () => {
        socketio.off("client-search-personnel-timekeeping")
        socketio.off("server-search-personnel-timekeeping")

        socketio.off("client-search-list-month-timekeeping")
        socketio.off("server-search-list-month-timekeeping")
      }
    }, [listPersonnel, listTimekeeping])

    // Quản lý danh sách mắng mặt.
    const [modalListVangmat, setModalListVangmat] = useState(false)
    const handleModalListVangmat = () => {
      setModalListVangmat(true)
    }
    const handleCloseModalListVangmat = () => {
      setModalListVangmat(false)
    }
    // Quản lý đếm số lượng yêu cầu vắng mặt hôm nay (chưa duyệt).
    const countAccept = useRef(0)
    useLayoutEffect(() => {
      socketio.emit("client-count-accept-today-xinvangmat", {
        iden: process.env.REACT_APP_IDEN_DASH,
      })
      socketio.on("server-count-accept-today-xinvangmat", (data) => {
        if (dataUser[0].list_vangmat === 1) {
          countAccept.current = data[0].count
        }
      })
      return () => {
        socketio.off("client-count-accept-today-xinvangmat")
        socketio.off("server-count-accept-today-xinvangmat")
      }
    }, [])

    // Quản lý đếm notifi phiếu tăng ca hôm nay chưa duyệt.
    const countTangca = useRef(0)
    useLayoutEffect(() => {
      const paramsCountTC = {
        iden: process.env.REACT_APP_IDEN_DASH,
      }
      socketio.emit("client-count-today-tangca", paramsCountTC)
      socketio.on("server-count-today-tangca", (dataCountTC) => {
        countTangca.current = dataCountTC[0].count
      })
      return () => {
        socketio.off("client-count-today-tangca")
        socketio.off("server-count-today-tangca")
      }
    }, [])

    // Quản lý đếm số lượng nghỉ việc tháng này.
    const countNghiviec = useRef(0)
    useLayoutEffect(() => {
      const paramsCountNV = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: { month: moment().format("YYYY-MM") },
      }
      socketio.emit("client-count-data-month-donnghiviec", paramsCountNV)
      socketio.on("server-count-data-month-donnghiviec", (dataCountNV) => {
        countNghiviec.current = dataCountNV[0].count
      })
      return () => {
        socketio.off("client-count-data-month-donnghiviec")
        socketio.off("server-count-data-month-donnghiviec")
      }
    }, [])

    // Quản lý  danh sách tăng ca.
    const [modalListTangca, setModalListTangca] = useState(false)
    const handleModalListTangca = () => {
      setModalListTangca(true)
    }
    const handleCloseModalListTangca = () => {
      setModalListTangca(false)
    }

    // quản lý danh sách đơn xin nghỉ việc.
    const [modalListDonnghiviec, setModalListDonnghiviec] = useState(false)
    const handleModalListDonNghiviec = () => {
      setModalListDonnghiviec(true)
    }
    const handleCloseModalListDonnghiviec = () => {
      setModalListDonnghiviec(false)
    }
  return (
    <div>
      {/* Cate */}
      <div className="col-12 mb-3 wrapper_ground">
        {/* button timekeeping */}
        <div className="btn-group list_items">
          <button
            type="button"
            className="btn btn-purple-gradient dropdown-toggle d-flex align-items-center fw-5"
            onClick={handleAddChamcong}
          >
            <IoFingerPrintOutline className="fs-15 me-1" />
            Chấm công
          </button>
        </div>
        {/* button lập đơn từ */}
        <div className="btn-group list_items">
          <button
            type="button"
            className="btn btn-primary-gradient dropdown-toggle d-flex align-items-center justify-content-center fw-5"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <IoDocumentTextOutline className="fs-15 me-1" />
            Lập đơn từ
          </button>
          <ul className="dropdown-menu" style={{ width: "186px" }}>
            <li className="dropdown-item" onClick={handleModalXinvangmat}>
              Đơn vắng mặt
            </li>
            <li className="dropdown-item" onClick={handleShowModalTangca}>
              Lập phiếu Tăng ca
            </li>
            <li className="dropdown-item" onClick={handleModalDonNghiviec}>
              Đơn xin nghỉ việc
            </li>
          </ul>
        </div>
        {/* button danh sách */}
        <div className="btn-group list_items">
          <button
            type="button"
            className="btn btn-secondary-gradient dropdown-toggle d-flex align-items-center justify-content-center fw-5"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <IoDocumentTextOutline className="fs-15 me-1" />
            Danh sách đơn từ
          </button>
          <ul className="dropdown-menu" style={{ width: "210px" }}>
            <li className="dropdown-item" onClick={handleModalListVangmat}>
              Danh sách vắng mặt
              {countAccept.current > 0 && dataUser[0].list_vangmat === 1 && (
                <span className="text-danger fw-5">
                  ({countAccept.current})
                </span>
              )}
            </li>
            <li className="dropdown-item" onClick={handleModalListTangca}>
              Danh sách Tăng ca
              {countTangca.current > 0 && dataUser[0].list_tangca === 1 && (
                <span className="text-danger fw-5">
                  ({countTangca.current})
                </span>
              )}
            </li>
            <li className="dropdown-item" onClick={handleModalListDonNghiviec}>
              Danh sách xin nghỉ việc
              {countNghiviec.current > 0 && dataUser[0].list_xinnghi === 1 && (
                <span className="text-danger fw-5">
                  ({countNghiviec.current})
                </span>
              )}
            </li>
          </ul>
        </div>
      </div>
      {/* Search */}
      <SearchDataMonthChamcong resultSearch={handleResultSearchTimekeeping} />
      <div className="fw-5 text-center mt-3">
        Bảng chấm công tháng{" "}
        <span className="text-primary">
          {moment(monthRef.current).format("MM/YYYY")}
        </span>
      </div>

      {/* content Bảng chấm công */}
      <div className="mt-3 mb-3">
        <TableCalendarMobile
          socketio={socketio}
          monthSearch={monthRef.current}
          listPersonnel={listPersonnel}
          listTimekeeping={listTimekeeping}
        />
      </div>

      {/* Modal cham cong */}
      <ModalReact
        modalTitle={
          <>
            <div className="d-flex gap-3 align-items-center">
              <div>
                <span className="avatar avatar-rounded">
                  <img src={"/" + dataUser[0].avatar} alt="avatar" />
                </span>
              </div>
              <div>
                <span>{dataUser[0].hoten}</span>
                <p className="mb-0 fs-14 text-muted mt-1">
                  Xác nhận chấm công <BsDot /> Ngày{" "}
                  {moment().format("DD/MM/YYYY")}
                </p>
              </div>
            </div>
          </>
        }
        showModal={modalChamcong}
        handleClose={handleCloseModalChamcong}
        theme={
          <FormChamcong
            socketio={socketio}
            result={handleChamcongSuccess}
            dataUser={dataUser}
          />
        }
      />
      {/* Modal xin vắng mặt */}
      {modalXinvangmat && (
        <ModalReact
          modalTitle="Tạo đơn xin vắng mặt"
          showModal={modalXinvangmat}
          handleClose={handleCloseModalXinvangmat}
          theme={
            <FormXinvangmat
              socketio={socketio}
              dataUser={dataUser}
              result={handleAddXinvangmatSuccess}
            />
          }
        />
      )}
      {/* Modal tăng ca */}
      {modalTangca && (
        <ModalReact
          modalTitle="Phiếu tăng ca"
          showModal={modalTangca}
          handleClose={handleCloseModalTangca}
          theme={
            <FormTangca
              socketio={socketio}
              dataUser={dataUser}
              result={handleAddTangcaSuccess}
            />
          }
        />
      )}
      {/* Modal tạo đơn nghỉ việc */}
      {modalDonNghiviec && (
        <ModalReact
          modalTitle="Tạo đơn xin nghỉ việc"
          showModal={modalDonNghiviec}
          handleClose={handleCloseModalDonNghiviec}
          theme={<FormDonXinNghiviec socketio={socketio} dataUser={dataUser} />}
        />
      )}

      {/* Modal list vắng mặt */}
      {modalListVangmat && (
        <ModalReact
          modalTitle="Danh sách vắng mặt"
          showModal={modalListVangmat}
          handleClose={handleCloseModalListVangmat}
          theme={<ListVangmat />}
        />
      )}
      {/* Modal list tăng ca */}
      {modalListTangca && (
        <ModalReact
          modalTitle="Danh sách tăng ca"
          showModal={modalListTangca}
          handleClose={handleCloseModalListTangca}
          theme={<ListTangca />}
        />
      )}
      {/* Modal list đơn nghỉ việc */}
      {modalListDonnghiviec && (
        <ModalReact
          modalTitle="Danh sách đơn nghỉ việc"
          showModal={modalListDonnghiviec}
          handleClose={handleCloseModalListDonnghiviec}
          theme={<ListDonNghiviec socketio={socketio} dataUser={dataUser} />}
        />
      )}
    </div>
  )
}
export default ListBangChamCongMobile


// Bảng chấm công
function TableCalendarMobile({socketio, monthSearch, listPersonnel, listTimekeeping }) {
  // Tạo bảng chấm công.
  const year_ = moment(monthSearch).format("YYYY")
  const month_ = moment(monthSearch).format("MM")
  const arrDays = getDaysArray(year_, month_)
  // Quản lý hiển thị chi tiết ngày chấm công.
  const [modalDetail, setModalDetail] = useState(false)
  const [listDataDetail, setListDataDetail] = useState([])
  const ngayChon = useRef("")
  const handleDetailTimekeeping = (day) => {
    const formatDays = year_ + "-" + month_ + "-" + day;
    const filterTimekeeping = listTimekeeping.filter((item) => {
      return moment(item.addtime).format("YYYY-MM-DD") === formatDays
    })
    setModalDetail(true)
    setListDataDetail([...filterTimekeeping])
    ngayChon.current = moment(formatDays).format("DD/MM/YYYY")
  }
  const handleCloseModalDetail = () => {
    setModalDetail(false)
    setListDataDetail([])
    ngayChon.current = ""
  }

  // Quản lý giờ làm việc.
  const gioLamviec = useRef([])
  useEffect(() => {
    // Check giờ làm viêc.
    const paramsGiolamviec = {
      iden: process.env.REACT_APP_IDEN_DASH,
    }
    socketio.emit("client-get-data-giolamviec", paramsGiolamviec)
    socketio.on("server-get-data-giolamviec", (data) => {
      gioLamviec.current = [...data]
    })
    return () => {
      socketio.off("client-get-data-giolamviec")
      socketio.off("server-get-data-giolamviec")
    }
  }, [])
  return (
    <>
      <div className={cx("wrapper_ground")}>
        {arrDays.map((item, index) => {
          let currentDay = moment().format("DD")
          // đủ công.
          return (
            <div
              className={cx("wrapper_item")}
              key={index}
              onClick={() => handleDetailTimekeeping(item.date)}
            >
              <div
                className={cx(
                  "text-center cursor-pointer",
                  Number(item.date) === Number(currentDay) &&
                    "text-danger-date",
                  Number(item.date) < Number(currentDay) && "bg-success-date",
                )}
              >
                <div className="p-1">
                  <span
                    className={cx(
                      Number(item.date) === Number(currentDay)
                        ? "text-danger-date"
                        : "",
                    )}
                  >
                    <span className="fs-14 fw-5">
                      {item.day.replace("Th", "Thứ")}
                    </span>
                  </span>
                  <div
                    className={cx(
                      "mb-1 fw-5 fs-21 d-flex justify-content-center align-items-center gap-1",
                      Number(item.date) > Number(currentDay) &&
                        "text-muted op-6",
                    )}
                    style={{ height: "21px" }}
                  >
                    {item.date}
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
      {/* Modal detail */}
      {modalDetail && (
        <ModalReact
          modalTitle={"Danh sách chấm công"}
          showModal={modalDetail}
          handleClose={handleCloseModalDetail}
          theme={
            <ListTimekeepingMobile
              gioLamviec={gioLamviec.current}
              ngayChon={ngayChon.current}
              listPersonnel={listPersonnel}
              listTimekeeping={listDataDetail}
            />
          }
        />
      )}
    </>
  )
}

// Danh sách chấm công mobile.
function ListTimekeepingMobile({gioLamviec, ngayChon, listPersonnel, listTimekeeping }) {
  return (
    <>
      <div className="alert alert-primary text-center fs-15">
        Danh sách chấm công <span className="fw-5">{ngayChon}</span>
      </div>
      <ul className="list-unstyled timeline-widget mb-0 my-3">
        {listPersonnel.map((item, index) => {
          let listTime = listTimekeeping.find((item_) => {
            return item_.user_id === item.id_user
          });
          let ca_sang_a = <span className="text-muted">--:--</span>
          let ca_sang_b = <span className="text-muted">--:--</span>
          let ca_chieu_a = <span className="text-muted">--:--</span>
          let ca_chieu_b = <span className="text-muted">--:--</span>
          let objectCaSang = { colorStart: "", colorEnd: "" }
          let objectCaChieu = {colorStart: "", colorEnd: ""}
          if (listTime) {
            objectCaSang = handleTimekeepingMorningColor(listTime, gioLamviec);
            objectCaChieu = handleTimekeepingEndColor(listTime, gioLamviec)
            ca_sang_a = listTime.ca_sang_a ? moment(listTime.ca_sang_a).format("HH:mm"): ca_sang_a
            ca_sang_b = listTime.ca_sang_b ? moment(listTime.ca_sang_b).format("HH:mm") : ca_sang_b
            ca_chieu_a = listTime.ca_chieu_a ? moment(listTime.ca_chieu_a).format("HH:mm"): ca_chieu_a
            ca_chieu_b = listTime.ca_chieu_b ? moment(listTime.ca_chieu_b).format("HH:mm") : ca_chieu_b
          }
          return (
            <li className="timeline-widget-list" key={index}>
              <div className="d-flex align-items-top">
                <div className="me-5 text-center">
                  <span
                    className={cx(
                      "avatar avatar-md me-2 avatar-rounded",
                      item.onlineoffline === 1 ? "online" : "offline",
                    )}
                  >
                    <img
                      src={"/" + item.avatar}
                      alt="img"
                    />
                  </span>
                </div>
                <div className="d-flex flex-wrap flex-fill align-items-top justify-content-between">
                  <div>
                    <p className="mb-1 text-truncate timeline-widget-content text-wrap d-flex align-items-center gap-2">
                      <span style={{ width: "50px" }}>Sáng:</span>
                      <span
                        style={{ width: "50px" }}
                        className={cx(objectCaSang.colorStart)}
                      >
                        {ca_sang_a}
                      </span>
                      <BsDot className="text-muted" />
                      <span
                        style={{ width: "50px" }}
                        className={cx("text-end", objectCaSang.colorEnd)}
                      >
                        {ca_sang_b}
                      </span>
                    </p>
                    <p className="mb-1 text-truncate timeline-widget-content text-wrap d-flex align-items-center gap-2">
                      <span style={{ width: "50px" }}>Chiều:</span>
                      <span
                        style={{ width: "50px" }}
                        className={cx(objectCaChieu.colorStart)}
                      >
                        {ca_chieu_a}
                      </span>
                      <BsDot className="text-muted" />
                      <span
                        style={{ width: "50px" }}
                        className={cx("text-end", objectCaChieu.colorEnd)}
                      >
                        {ca_chieu_b}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </li>
          )
        })}
      </ul>
    </>
  )
}

// Xử lý mầu cho giờ chấm công.
function handleTimekeepingMorningColor(listTime, gioLamviec) {
  let object = {colorStart: "", colorEnd: ""};

  let gioCaSangStart = moment(listTime.ca_sang_a).format(
    "YYYY-MM-DD" + gioLamviec[0].ca_sang_a,
  )
  let timeCaSangStart = new Date(gioCaSangStart).getTime()
  let gioCaSangEnd = moment(listTime.ca_sang_b).format(
    "YYYY-MM-DD" + gioLamviec[0].ca_sang_b,
  )
  let timeCaSangEnd = new Date(gioCaSangEnd).getTime()

  if (new Date(listTime.ca_sang_a).getTime() <= timeCaSangStart) {
    object.colorStart = "text-success"
  } else {
    object.colorStart = "text-danger"
  }
  if (new Date(listTime.ca_sang_b).getTime() >= timeCaSangEnd) {
    object.colorEnd = "text-success"
  } else {
    object.colorEnd = "text-danger"
  }
  return object
}

// Xử lý mầu cho chấm công ca chiều.
function handleTimekeepingEndColor(listTime, gioLamviec) {
  let object = { colorStart: "", colorEnd: "" }

  let gioCaChieuStart = moment(listTime.ca_chieu_a).format(
    "YYYY-MM-DD" + gioLamviec[0].ca_chieu_a,
  )
  let timeCaChieuStart = new Date(gioCaChieuStart).getTime()
  let gioCaChieuEnd = moment(listTime.ca_chieu_b).format(
    "YYYY-MM-DD" + gioLamviec[0].ca_chieu_b,
  )
  let timeCaChieuEnd = new Date(gioCaChieuEnd).getTime()
  if (new Date(listTime.ca_chieu_a).getTime() <= timeCaChieuStart) {
    object.colorStart = "text-success"
  } else {
    object.colorStart = "text-danger"
  }
  if (new Date(listTime.ca_chieu_b).getTime() >= timeCaChieuEnd) {
    object.colorEnd = "text-success"
  } else {
    object.colorEnd = "text-danger"
  }
  return object
}