import { memo, useContext, useEffect, useRef, useState } from "react"
import { List } from "react-virtualized"
import classNames from "classnames/bind"
import { Dropdown } from "react-bootstrap"
import moment from "moment"
import { BsDot } from "react-icons/bs"
import { IoIosAddCircleOutline, IoMdCheckmarkCircle } from "react-icons/io"
import { IoCalendarOutline, IoColorWand, IoDuplicateOutline } from "react-icons/io5"
import { MdOutlineWorkHistory } from "react-icons/md"
import { LiaEdit } from "react-icons/lia"
import { FiCheckCircle } from "react-icons/fi"
import { AiOutlineCloseCircle } from "react-icons/ai"

import styles from "../DataSale.module.scss"
import { TextLimit, handleColorTextSearch, handleFormatPrice } from "../../../handle-reuses/reuses"
import ModalReact from "../../../Components/Modal/ModalReact"
import { useForm } from "react-hook-form"
import AppContext from "../../../handle-reuses/Contexts/CreateContexts"
import FormEditDataSale from "../Component/FormEditDataSale"
import LoadingSpinner from "../../../Components/Loading/LoadingSpinner"
import FormAddContract from "../Component/FormAddContract"
import { images } from "../../../assets/images"
import DetailDataSale from "../Component/DetailDataSale"


const cx = classNames.bind(styles)

// List cate home
function ListDataSaleCateHomePC({ cate, dataCustomer, socketio, isLoading, dataGetNote, checkSearch, keywordsColor, keywordsColorFilter, dataKhachhen }) {
  const { dataUser } = useContext(AppContext)
  const [dataCusSale, setDataCusSale] = useState([])
  const dataCusSaleRef = useRef([])
  const checkAddNote = useRef(false)
  const [dataNote, setDataNote] = useState()
  const idSanpham = useRef()
  // Quản lý nhận và hiển thị dữ liệu.
  useEffect(() => {
    setDataCusSale([...dataCustomer])
    dataCusSaleRef.current = [...dataCustomer]
    if (checkSearch) {
      setDataNote()
    }
  }, [cate, dataCustomer])
  // *Quản lý nhận và hiển thị dữ liệu.

  // Quản lý nhận dữ liệu phụ trợ.
  const [dataDausomang, setDataDausomang] = useState([])
  useEffect(() => {
    const paramsMethod = {
      iden: process.env.REACT_APP_IDEN_DASH,
    }
    // get data đầu số mạng.
    socketio.emit("client-get-data-dausomang", paramsMethod)
    socketio.on("server-get-data-dausomang", (data) => {
      setDataDausomang([...data])
    })
    return () => {
      socketio.off("client-get-data-dausomang")
      socketio.off("server-get-data-dausomang")
    }
  }, [])
  // *Quản lý nhận dữ liệu phụ trợ.

  // Quản lý nhận dữ liệu ghi chú.
  const arrNote = useRef([])
  useEffect(() => {
    for (let i = 0; i < dataGetNote.length; i++) {
      const paramsNote = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: {
          kn_sanpham: dataGetNote[i].id_sp,
        },
      }
      socketio.emit("client-get-data-note-ipsp-sanpham", paramsNote)
    }
    socketio.on("server-get-data-note-ipsp-sanpham", (data) => {
      if (data.length > 0) {
        let arr = [...arrNote.current]
        for (let i = 0; i < data.length; i++) {
          let filter = arr.filter((item) => {
            return item.id_ghichu === data[i].id_ghichu
          })
          if (filter.length === 0) {
            arrNote.current.push(data[i])
          }
        }
        setDataNote([...arrNote.current])
      }
    })
    return () => {
      socketio.off("client-get-data-note-ipsp-sanpham")
      socketio.off("server-get-data-note-ipsp-sanpham")
    }
  }, [dataGetNote])

  // *Quản lý nhận dữ liệu ghi chú.

  /** Quản lý Thêm object add note vào data note để hiển thị. */
  const handleResultStatus = (status_) => {
    if (status_) {
      // Xử lý cập nhật dữ liệu thêm note mới
      socketio.on("server-get-data-note-success-sanpham", (dataNoteNew) => {
        let dataNoteUpdate = [];
        if (dataNote) {
          dataNoteUpdate = [...dataNote]
        }
        for (let i = 0; i < dataNoteNew.length; i++) {
          let filterNoteUpdate = dataNoteUpdate.filter((item) => {
            return item.id_ghichu === dataNoteNew[i].id_ghichu
          })
          if (filterNoteUpdate.length === 0) {
            dataNoteUpdate.push(dataNoteNew[i])
          }
        }
        let sortDataNoteUpdate = dataNoteUpdate.sort((a, b) => {
          let min_ = new Date(a.ngaycapnhat).getTime()
          let max_ = new Date(b.ngaycapnhat).getTime()
          return max_ - min_
        })
        setDataNote([...sortDataNoteUpdate])
        checkAddNote.current = dataNoteNew // xác định sự thay đổi
      })
    }
    // Xử lý cập nhật dữ liệu edit note mới.
    socketio.on("server-get-data-note-edit-success-sanpham", (dataNoteEdit) => {
      let dataNoteUpdateEdit = [...dataNote]
      let filterDataNoteEdit = dataNoteUpdateEdit.filter((item) => {
        return item.id_ghichu !== dataNoteEdit[0].id_ghichu
      })
      if (filterDataNoteEdit.length > 0) {
        filterDataNoteEdit.push(dataNoteEdit[0])
      }
      let sortDataNoteUpdate = filterDataNoteEdit.sort((a, b) => {
        let min_ = new Date(a.ngaycapnhat).getTime()
        let max_ = new Date(b.ngaycapnhat).getTime()
        return max_ - min_
      })
      setDataNote([...sortDataNoteUpdate])
      checkAddNote.current = dataNoteEdit // xác định sự thay đổi
    })
  }
  useEffect(() => {
    return () => {
      socketio.off("client-get-data-note-success-sanpham")
      socketio.off("server-get-data-note-success-sanpham")
      socketio.off("client-get-data-note-edit-success-sanpham")
      socketio.off("server-get-data-note-edit-success-sanpham")
    }
  }, [checkAddNote.current])

  /** * === Quản lý Thêm object add note vào data note để hiển thị. ==== */
  const [modalAds, setModalAds] = useState(false)
  const idSanphamAds = useRef()
  // Chuyển đổi trạng thái quảng cáo
  const [modalStatusAds, setModalStatusAds] = useState(false)
  const handleStatusAds = (idSP, index) => {
    setModalStatusAds(true)
    idSanphamAds.current = { idSP: idSP, index: index }
  }
  const handleCloseStatusAds = () => {
    setModalStatusAds(false)
    idSanphamAds.current = undefined
  }
  const handleStatusAdsSuccess = (object) => {
    const paramsCheck = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: object,
    }
    socketio.emit("client-edit-row-expired-ads-bds", paramsCheck)
  }
  
  // Tạo hợp đồng quảng cáo.
  const handleAddBdsContract = (idSP, index) => {
    setModalAds(true)
    idSanphamAds.current = {idSP: idSP, index: index}
  }
  const handleCloseStatusModalAds = () => {
    setModalAds(false)
    idSanphamAds.current = undefined
  }
  const handleAddDataContractSuccess = (status_) => {
    if (status_) {
      setModalAds(false)
    }
  }

  // Quản lý cập nhật lại dữ liệu hiển thị
  useEffect(() => {
    socketio.on("server-edit-status-sanpham", (object) => {
      if (object.status === "success") {
        // Cập nhật lại dữ liệu hiển thị
        let dataCusUpdate = [...dataCusSale]
        dataCusUpdate[idSanphamAds.current.index] = object.data[0]
        // gán giá cập nhật.
        setDataCusSale([...dataCusUpdate])
        // Trả về trạng thái modal ban đầu
        setModalStatusAds(false)
        setModalAds(false)
        idSanphamAds.current = undefined
      }
    })
    return () => {
      socketio.off("client-edit-row-expired-ads-bds")
      socketio.off("server-edit-status-sanpham")
    }
  }, [dataCusSale])

  /** * ==== Quản lý thay đổi status quảng cáo ===== */

  /** Quản lý thay đổi tình trạng (đã bán) */
  const handleChangeTinhtrang = (idSP) => {
    const checkChangeTinhtrang = window.confirm(
      "Bạn muốn chuyển sang trạng thái đã bán ?",
    )
    if (checkChangeTinhtrang) {
      const paramsCheck = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: { id_sp: idSP },
      }
      socketio.emit(
        "client-check-get-data-status-tinhtrang-sanpham",
        paramsCheck,
      )
      socketio.on("server-check-get-data-status-tinhtrang-sanpham", (data) => {
        const paramsEditTinhtrang = {
          iden: process.env.REACT_APP_IDEN_DASH,
          object: {
            tinhtrang: data[0].tinhtrang === 1 ? 0 : 1,
            id_sp: idSP,
          },
        }
        socketio.emit("client-edit-tinhtrang-sanpham", paramsEditTinhtrang)
        socketio.on("server-edit-tinhtrang-sanpham", (object) => {
          if (object.status === "success") {
            // Cập nhật lại dữ liệu hiển thị
            let dataCusUpdate = [...dataCusSale]
            let filterDataCusUpdate = dataCusUpdate.filter((item) => {
              return item.id_sp !== idSP
            })
            filterDataCusUpdate.push(object.data[0])
            let sortFilterDataCusUpdate = filterDataCusUpdate.sort((a, b) => {
              let min_ = new Date(a.ngaycapnhat).getTime()
              let max_ = new Date(b.ngaycapnhat).getTime()
              return max_ - min_
            })
            // gán giá cập nhật.
            setDataCusSale([...sortFilterDataCusUpdate])
          }
        })
      })
    }
  }
  /** *Quản lý thay đổi tình trạng (đã bán) */

  /** Quản lý tắt sự kiện cho edit quảng cáo, đã bán */
  useEffect(() => {
    return () => {
      socketio.off("client-check-get-data-status-tinhtrang-sanpham")
      socketio.off("server-check-get-data-status-tinhtrang-sanpham")

      socketio.off("client-edit-tinhtrang-sanpham")
      socketio.off("server-edit-tinhtrang-sanpham")
    }
  }, [dataCusSale])
  /** *Quản lý tắt sự kiện cho edit quảng cáo, đã bán */

  /** Quản lý lịch sử */
  const [modalHistory, setModalHistory] = useState(false)

  const dataSanphamHis = useRef([])
  const handleShowModalHistory = (idSP) => {
    setModalHistory(true)
    idSanpham.current = idSP
    dataSanphamHis.current = dataCusSale.filter((item) => {
      return item.id_sp === idSP
    })
  }
  const handleCloseModalHistory = () => {
    setModalHistory(false)
    idSanpham.current = undefined
    dataSanphamHis.current = []
  }
  /** *Quản lý lịch sử */

  /** Quản lý get data cate product ban đầu */
  const [dataCateProduct, setDataCateProduct] = useState([])
  useEffect(() => {
    for (let i = 0; i < dataGetNote.length; i++) {
      const paramsGetCate = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: {
          kn_sanpham: dataGetNote[i].id_sp,
          author: dataUser[0].id_user,
        },
      }
      socketio.emit(
        "client-get-data-cate-product-author-knsanpham",
        paramsGetCate,
      )
    }
    socketio.on(
      "server-get-data-cate-product-author-knsanpham",
      (dataKinhdoanh) => {
        setDataCateProduct((old) => [...old, ...dataKinhdoanh])
      },
    )
    return () => {
      socketio.off("client-get-data-cate-product-author-knsanpham")
      socketio.off("server-get-data-cate-product-author-knsanpham")
    }
  }, [dataGetNote])
  /** *Quản lý get data cate product ban đầu */

  /** Quản lý thêm vào mục khách kinh doanh, khách nét */
  const handleAddKhachkinhdoanhKhachnet = (idSP, category) => {
    let checkAddCateKinhdoanh = window.confirm(
      "Thêm vào mục khách kinh doanh ?",
    )
    if (checkAddCateKinhdoanh) {
      const paramsCateKinhdoanh = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: {
          kn_sanpham: idSP,
          tacgia: dataUser[0].id_user,
        },
      }
      socketio.emit("client-check-data-cate-product", paramsCateKinhdoanh)
      socketio.on("server-check-data-cate-product", (dataCate) => {
        if (dataCate.length === 0) {
          // Xử lý thêm mới
          let paramsAddCateKinhdoanh
          if (category === "khachkinhdoanh") {
            paramsAddCateKinhdoanh = {
              iden: process.env.REACT_APP_IDEN_DASH,
              object: {
                kn_sanpham: idSP,
                tacgia: dataUser[0].id_user,
                kinhdoanh: 1,
              },
            }
          } else if (category === "khachnet") {
            paramsAddCateKinhdoanh = {
              iden: process.env.REACT_APP_IDEN_DASH,
              object: {
                kn_sanpham: idSP,
                tacgia: dataUser[0].id_user,
                khachnet: 1,
              },
            }
          }
          socketio.emit(
            "client-create-data-cate-product",
            paramsAddCateKinhdoanh,
          )
          socketio.on(
            "server-update-data-cate-product-success",
            (dataCateUpdate) => {
              // Cập nhật data cho cate product.
              setDataCateProduct((old) => [...old, ...dataCateUpdate])
            },
          )
        } else {
          // Xử lý Edit.
          let paramsEditCate
          if (category === "khachkinhdoanh") {
            paramsEditCate = {
              iden: process.env.REACT_APP_IDEN_DASH,
              object: {
                id_cate_product: dataCate[0].id_cate_product,
                kinhdoanh: dataCate[0].kinhdoanh === 1 ? 0 : 1,
                kn_sanpham: idSP,
                tacgia: dataUser[0].id_user,
              },
            }
            // Xửu lý edit khách kinh doanh
            socketio.emit(
              "client-edit-row-kinhdoanh-cate-product",
              paramsEditCate,
            )
          } else if (category === "khachnet") {
            paramsEditCate = {
              iden: process.env.REACT_APP_IDEN_DASH,
              object: {
                id_cate_product: dataCate[0].id_cate_product,
                khachnet: dataCate[0].khachnet === 1 ? 0 : 1,
                kn_sanpham: idSP,
                tacgia: dataUser[0].id_user,
              },
            }
            // Xửu lý edit khachnet.
            socketio.emit(
              "client-edit-row-khachnet-cate-product",
              paramsEditCate,
            )
          }
          // Xử lý cập nhật lại dữ liệu
          socketio.on(
            "server-update-data-cate-kinhdoanh-product-success",
            (dataUpdate) => {
              let dataUpdateKinhdoanh = [...dataCateProduct]
              let filter_ = dataUpdateKinhdoanh.filter((item) => {
                return item.id_cate_product !== dataUpdate[0].id_cate_product
              })
              filter_.push(dataUpdate[0])
              setDataCateProduct([...filter_])
            },
          )
        }
      })
    }
  }

  /** Quản lý thêm vào mục khách kinh doanh, khách nét */

  // Quản lý tắt sự kiện socketio (khách kinh doanh, khách nét).
  useEffect(() => {
    return () => {
      socketio.off("client-check-data-cate-product")
      socketio.off("server-check-data-cate-product")
      socketio.off("client-create-data-cate-product")
      socketio.off("server-update-data-cate-product-success")
      socketio.off("client-edit-row-kinhdoanh-cate-product")
      socketio.off("server-update-data-cate-kinhdoanh-product-success")
      socketio.off("client-edit-row-khachnet-cate-product")
    }
  }, [dataCateProduct])

  /** Quản lý đặt lịch hẹn */
  const [modalBooking, setModalBooking] = useState(false)
  const bookingIDSanpham = useRef()
  const handleShowBookingModal = (idSP) => {
    setModalBooking(true)
    bookingIDSanpham.current = idSP
  }
  const handleCloseBookingModal = () => {
    setModalBooking(false)
    bookingIDSanpham.current = undefined
  }
  const handleResponseBooking = (dataUpdateBooking) => {
    if (dataUpdateBooking.length > 0) {
      const dataUpdateCatePro = [...dataCateProduct]
      let filterDataUpdateCatePro = dataUpdateCatePro.filter((item) => {
        return item.id_cate_product !== dataUpdateBooking[0].id_cate_product
      })
      filterDataUpdateCatePro.push(dataUpdateBooking[0])
      setDataCateProduct([...filterDataUpdateCatePro])
      setModalBooking(false)
      bookingIDSanpham.current = undefined
    }
  }
  /** *Quản lý đặt lịch hẹn */

  /** Quản lý modal edit data sale */
  const [modalEditDataSale, setModalEditDataSale] = useState(false)
  const dataSaleEdit = useRef([])
  const indexEdit = useRef()
  const handleShowModalFormEditDataSale = (idSP, index) => {
    setModalEditDataSale(true)
    dataSaleEdit.current = dataCusSale.filter((item) => {
      return item.id_sp === idSP
    })
    indexEdit.current = index;
  }
  const handleCloseModalEditDataSale = () => {
    setModalEditDataSale(false);
    dataSaleEdit.current = []
  }
  

  const handleEditDataSaleSuccess = (object) => {
    if (object.status) {
      const paramsUpdate = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: {
          id_sp: object.id_sp,
        },
      }
      socketio.emit("client-filter-data-sale-idsp-sanpham", paramsUpdate)
      socketio.on("server-filter-data-sale-idsp-sanpham", (dataUpdate) => {
        const dataUpdateEdit = [...dataCusSale]
        dataUpdateEdit[object.index] = dataUpdate[0];
        setDataCusSale([...dataUpdateEdit])
        setModalEditDataSale(false)
        dataSaleEdit.current = []
      })
    }
  }
  useEffect(() => {
    return () => {
      socketio.off("client-filter-data-sale-idsp-sanpham")
      socketio.off("server-filter-data-sale-idsp-sanpham")
    }
  }, [dataCusSale])
 
  /** *Quản lý modal edit data sale */
  if (dataCusSale.length === 0 && isLoading) {
    return <div className="text-center mt-3 fs-15">Chưa có dữ liệu.</div>
  }

  
  return (
    <div className={cx("wrapper_listItem")}>
      <div className={cx("count-sticky", "mb-1 modal-active")}>
        <div
          className="alert alert-primary rounded-0"
          style={{ height: "30px"}}
        >
          Số lượng dữ liệu: <span className="fw-5 me-1 ms-1">{dataCusSale.length}</span> khách
        </div>
      </div>
      <div style={{marginTop: "133px"}}></div>
      {dataCusSale.map((item, index) => {
        // Xác định thời gian.
        let addtime = moment(item.ngaycapnhat)
        let currentTime = moment()
        let diffTime = currentTime.diff(addtime, "days")
        let addtime_
        if (diffTime <= 2) {
          addtime_ = moment(item.ngaycapnhat)
            .local("vi")
            .fromNow()
            .replace("một", "1")
        } else {
          addtime_ = moment(item.ngaycapnhat).format("DD-MM-YYYY")
        }
        // Xác định đầu số mạng.
        let arrPhone = [];
        if (item.sodienthoai) {
          arrPhone = item.sodienthoai.trim().split("-")
        }
        let phone_1 = dataDausomang.filter((item_) => {
          return item_.dauso === arrPhone[0].trim().slice(0, 3)
        })
        let phone_2 = dataDausomang.filter((item_) => {
          if (arrPhone[1]) {
            return item_.dauso === arrPhone[1].trim().slice(0, 3)
          }
        })

        // format price.
        let formatPrice = item.giasanpham.replace(/[.,]/g, "")

        // List note.
        let listNote
        if (dataNote) {
          listNote = dataNote.filter((item__) => {
            return item__.kn_sanpham === item.id_sp
          })
        } else {
          listNote = []
        }

        // Xác định data cate product.
        let filterCateProduct = dataCateProduct.filter((itemCate) => {
          return itemCate.kn_sanpham === item.id_sp
        })
        let checkKhachhen
        if (filterCateProduct.length > 0) {
          let booking = moment(filterCateProduct[0].booking)
          let currentTime = moment()
          let diffTime = booking.diff(currentTime, "days")
          if (diffTime >= 0) {
            checkKhachhen = (
              <span className="text-success">
                Khách hẹn <IoMdCheckmarkCircle />
              </span>
            )
          } else {
            checkKhachhen = <span>Khách hẹn</span>
          }
        } else {
          checkKhachhen = <span>Khách hẹn</span>
        }

        // Tô mầu cho từ khóa tìm kiếm fast.
        let colorText = keywordsColor || keywordsColorFilter
        let hoten = item.hoten
        let diachi = item.diachi
        let phone = item.sodienthoai
        if (colorText) {
          hoten = handleColorTextSearch(colorText, hoten)
          diachi = handleColorTextSearch(colorText, diachi)
          phone = handleColorTextSearch(colorText, phone)
        }

        // Set thời gian khách hẹn.
        let arrKhachhen
        if (dataKhachhen && cate === "/khach-hen") {
          if (dataKhachhen.length > 0) {
            arrKhachhen = dataKhachhen.filter((item_) => {
              return item_.kn_sanpham === item.id_sp
            })
          }
        }

        return (
          <div
            className={cx("dataSaleItem")}
            key={item.id_sp}
            style={{ height: "100px" }}
          >
            <div
              className={cx("d-flex align-items-center", "container-item")}
              style={{ height: "100%" }}
            >
              {/* Info data */}
              <div
                className={cx(
                  "col-5 p-1 d-flex align-items-center",
                  "item-a",
                  item.status === 1 && "bg-warning-tran",
                  item.tinhtrang === 1 && "bg-danger-tran",
                )}
                style={{ height: "100%" }}
              >
                <div>
                  <div className="d-flex align-items-center">
                    <span
                      className={cx(
                        "avatar avatar-xs me-2 avatar-rounded",
                        item.onlineoffline === 1 ? "online" : "",
                      )}
                    >
                      <img src={"/" + item.avatar_author} alt="img" />
                    </span>
                    <span className="fs-14 fw-5">{diachi}</span>
                    {diffTime <= 1 && (
                      <span className="badge bg-primary ms-1 rounded-5">
                        Mới
                      </span>
                    )}
                  </div>
                  {/* <h6>Chính chủ cần bán nhà đường tuyệt vời Ngô Quyền Hải Phòng</h6> */}
                  <p className="mb-0 fs-14" style={{ paddingLeft: "0.5rem" }}>
                    <span className="text-muted">
                      {addtime_}
                      <BsDot />
                      <span className="text-secondary">{item.quanhuyen}</span>
                      <BsDot />
                    </span>
                    <span className="fw-5">
                      {hoten}: {phone}
                    </span>
                    <BsDot />
                    <span className="text-muted">
                      {phone_1.length > 0 && phone_1[0].tennhamang}
                    </span>
                    <span className="text-muted">
                      {phone_2.length > 0 && " - " + phone_2[0].tennhamang}
                    </span>
                  </p>
                  {/* Handle */}
                  <div
                    className="d-flex gap-0 align-items-center"
                    style={{ height: " 26px" }}
                  >
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="outline-light border-0 p-0 py-0 transparent text-info-1 px-2 d-flex align-items-center" // class mặc định
                        bsPrefix="custom-dropdown-toggle"
                        style={{
                          border: "1px solid rgb(232, 232, 232)",
                          height: "30px",
                        }}
                      >
                        <IoColorWand />
                        Tình trạng
                      </Dropdown.Toggle>
                      <Dropdown.Menu style={{ backgroundColor: "#fceab3" }}>
                        <li
                          className="dropdown-item d-flex fs-14 fw-5"
                          onClick={() => handleStatusAds(item.id_sp, index)}
                        >
                          {item.status === 1 ? (
                            <span className="text-success">Đã quảng cáo</span>
                          ) : (
                            "Đã quảng cáo"
                          )}
                        </li>
                        <li
                          className="dropdown-item d-flex fs-14 fw-5"
                          onClick={() => handleAddBdsContract(item.id_sp, index)}
                        >
                          Tạo hợp đồng
                        </li>
                        <li
                          className="dropdown-item d-flex fs-14 fw-5"
                          onClick={() => handleChangeTinhtrang(item.id_sp, index)}
                        >
                          {item.tinhtrang === 1 ? (
                            <span className="text-danger">Đã bán</span>
                          ) : (
                            "Đã bán"
                          )}
                        </li>
                      </Dropdown.Menu>
                    </Dropdown>
                    <button
                      className="text-info-1 btn-outline-light border-0 bg-transparent fs-14 px-2 p-0 rounded-2 d-flex align-items-center"
                      style={{ border: "1px solid rgb(232, 232, 232)" }}
                      onClick={() => handleShowModalHistory(item.id_sp)}
                    >
                      <MdOutlineWorkHistory className="me-1" />
                      Lịch sử
                    </button>
                    {/* <button
                    className="text-info-1 btn-outline-light border-0 bg-transparent fs-14 p-0 rounded-2 me-2"
                    style={{ border: "1px solid rgb(232, 232, 232)" }}
                  >
                    Xin thông tin
                  </button> */}
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="outline-light border-0 p-0 px-2 py-0 transparent text-info-1 d-flex align-items-center" // class mặc định
                        bsPrefix="custom-dropdown-toggle"
                        style={{
                          border: "1px solid rgb(232, 232, 232)",
                          height: "30px",
                        }}
                      >
                        <IoDuplicateOutline className="me-1" />
                        Thêm vào mục
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        style={{ width: "186px", backgroundColor: "#fceab3" }}
                      >
                        <li
                          className="dropdown-item d-flex fs-14 fw-5"
                          onClick={() =>
                            handleAddKhachkinhdoanhKhachnet(
                              item.id_sp,
                              "khachkinhdoanh",
                            )
                          }
                        >
                          {filterCateProduct.length > 0 ? (
                            filterCateProduct[0].kinhdoanh === 1 ? (
                              <span className="text-success">
                                Khách kinh doanh <IoMdCheckmarkCircle />
                              </span>
                            ) : (
                              "Khách kinh doanh"
                            )
                          ) : (
                            "Khách kinh doanh"
                          )}
                        </li>
                        <li
                          className="dropdown-item d-flex fs-14 fw-5"
                          onClick={() =>
                            handleAddKhachkinhdoanhKhachnet(
                              item.id_sp,
                              "khachnet",
                            )
                          }
                        >
                          {filterCateProduct.length > 0 ? (
                            filterCateProduct[0].khachnet === 1 ? (
                              <span className="text-success">
                                Khách nét <IoMdCheckmarkCircle />
                              </span>
                            ) : (
                              "Khách nét"
                            )
                          ) : (
                            "Khách nét"
                          )}
                        </li>
                        <li
                          className="dropdown-item d-flex fs-14 fw-5"
                          onClick={() => handleShowBookingModal(item.id_sp)}
                        >
                          {checkKhachhen}
                        </li>
                      </Dropdown.Menu>
                    </Dropdown>

                    <button
                      className="text-info-1 btn-outline-light border-0 bg-transparent fs-14 p-0 rounded-2 px-2 d-flex align-items-center"
                      style={{ border: "1px solid rgb(232, 232, 232)" }}
                      onClick={() =>
                        handleShowModalFormEditDataSale(item.id_sp, index)
                      }
                    >
                      <LiaEdit className="me-1" />
                      Sửa
                    </button>
                    {/* show thời gian hẹn */}
                    {cate === "/khach-hen" &&
                      arrKhachhen &&
                      (arrKhachhen.length > 0 ? (
                        <div className="fs-14 text-danger d-flex align-items-center ms-2">
                          <IoCalendarOutline className="me-1" />
                          {moment(arrKhachhen[0].booking)
                            .locale("vi")
                            .fromNow()
                            .replace("một", "1")}
                        </div>
                      ) : (
                        ""
                      ))}
                    {/* Show thời gian hết hạn */}
                    {cate === "/khach-het-han" && (
                      <div className="fs-14 text-danger d-flex align-items-center ms-2">
                        {moment(item.expired)
                          .locale("vi")
                          .fromNow()
                          .replace("một", "1")}
                      </div>
                    )}
                  </div>
                  {/* *Handle */}
                </div>
              </div>
              <div
                className={cx(
                  "col-2 px-2 fs-14 p-1 d-flex align-items-center",
                  "item-b",
                )}
                style={{ height: "100%" }}
              >
                <div>
                  <div>
                    {item.duongngo && (
                      <span>Đường/Ngõ {item.duogngospan} </span>
                    )}
                    <span>Giá {handleFormatPrice(Number(formatPrice))}</span>
                    <BsDot />
                    <span>
                      Dtmb {item.dtmb_mt}m<sup>2</sup>
                    </span>

                    {item.huongnha && (
                      <>
                        <BsDot />
                        <span>Hướng {item.huongnha}</span>
                      </>
                    )}
                    {item.phaply && (
                      <>
                        <BsDot />
                        <span>Pháp lý {item.phaply}</span>
                      </>
                    )}
                    {/* Chi tiết sản phẩm */}
                    <DetailDataSale socketio={socketio} dataItem={item} />
                  </div>
                  <div>
                    {item.status === 1 && (
                      <span className="badge bg-success me-1 rounded-5">
                        Đã quảng cáo
                      </span>
                    )}
                    {item.tinhtrang === 1 && (
                      <span className="badge bg-danger rounded-5">Đã bán</span>
                    )}
                  </div>
                </div>
              </div>
              {/* Note data */}
              <div className={cx("mw-1000", "p-1 d-flex")}>
                {listNote && (
                  <ListNoteDataSalePC
                    dataNote={listNote}
                    socketio={socketio}
                    idSanpham={item.id_sp}
                    indexNote={index}
                    result={handleResultStatus}
                    keywordsColor={keywordsColorFilter}
                  />
                )}
                {!listNote && (
                  <div className="text-center mt-3 fs-15">Đang tải...</div>
                )}
              </div>
            </div>
          </div>
        )
      })}

      {/* Kiểm tra tải thêm dữ liệu mới */}
      {!isLoading && (
        <div className="text-center mt-3 mb-3 fs-15">
          <LoadingSpinner />
        </div>
      )}
      {/* Xác nhận đã lấy hết data */}
      {isLoading && (
        <div className="text-center mt-3 mb-3 fs-15">Bạn đã xem hết rồi.</div>
      )}

      {/* Modal lịch sử */}
      {modalHistory && (
        <ModalReact
          modalTitle="Lịch sử sửa sản phẩm"
          showModal={modalHistory}
          handleClose={handleCloseModalHistory}
          theme={
            <ListHistoryProduct
              idSanpham={idSanpham.current}
              socketio={socketio}
              dataSanpham={dataSanphamHis.current}
            />
          }
        />
      )}
      {/* Modal Booking */}
      {modalBooking && (
        <ModalReact
          modalTitle="Đặt lịch hẹn"
          showModal={modalBooking}
          handleClose={handleCloseBookingModal}
          theme={
            <FormBooking
              idSanpham={bookingIDSanpham.current}
              socketio={socketio}
              response={handleResponseBooking}
            />
          }
        />
      )}
      {/* Modal edit data sale */}
      {modalEditDataSale && (
        <ModalReact
          modalTitle="Sửa thông tin sản phẩm"
          showModal={modalEditDataSale}
          handleClose={handleCloseModalEditDataSale}
          theme={
            <FormEditDataSale
              dataSaleEdit={dataSaleEdit.current}
              indexEdit = {indexEdit.current}
              resultStatus={handleEditDataSaleSuccess}
            />
          }
        />
      )}
      {/* Modal status ads */}
      {modalStatusAds && (
        <ModalReact
          modalTitle="Chuyển sang đã quảng cáo"
          showModal={modalStatusAds}
          handleClose={handleCloseStatusAds}
          theme={
            <FormStatusAds
              idSanpham={idSanphamAds.current}
              socketio={socketio}
              dataUser={dataUser}
              resultObject={handleStatusAdsSuccess}
            />
          }
        />
      )}
      {/* Modal ads contract */}
      {modalAds && idSanphamAds.current && (
        <ModalReact
          modalTitle="Tạo hợp đồng quảng cáo"
          showModal={modalAds}
          handleClose={handleCloseStatusModalAds}
          theme={
            <FormAddContract
              idSanpham={idSanphamAds.current}
              socketio={socketio}
              dataUser={dataUser}
              resultStatus={handleAddDataContractSuccess}
            />
          }
        />
      )}
    </div>
  )
}
export {ListDataSaleCateHomePC}

// List cate other
function ListDataSaleCateOtherPC({ cate, dataCustomer, socketio, isLoading, dataGetNote, checkSearch, keywordsColor, keywordsColorFilter, dataKhachhen }) {
  const { dataUser } = useContext(AppContext)
  const [dataCusSale, setDataCusSale] = useState([])
  const dataCusSaleRef = useRef([])
  const checkAddNote = useRef(false)
  const [dataNote, setDataNote] = useState()
  const idSanpham = useRef()
  
  // Quản lý nhận và hiển thị dữ liệu.
  useEffect(() => {
    setDataCusSale([...dataCustomer])
    dataCusSaleRef.current = [...dataCustomer]
    if (checkSearch) {
      setDataNote()
    }
  }, [cate, dataCustomer])
  // *Quản lý nhận và hiển thị dữ liệu.

  // Quản lý nhận dữ liệu phụ trợ.
  const [dataDausomang, setDataDausomang] = useState([])
  useEffect(() => {
    const paramsMethod = {
      iden: process.env.REACT_APP_IDEN_DASH,
    }
    // get data đầu số mạng.
    socketio.emit("client-get-data-dausomang", paramsMethod)
    socketio.on("server-get-data-dausomang", (data) => {
      setDataDausomang([...data])
    })
    return () => {
      socketio.off("client-get-data-dausomang")
      socketio.off("server-get-data-dausomang")
    }
  }, [])
  // *Quản lý nhận dữ liệu phụ trợ.

  // Quản lý nhận dữ liệu ghi chú.
  const arrNote = useRef([])
  useEffect(() => {
    for (let i = 0; i < dataGetNote.length; i++) {
      const paramsNote = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: {
          kn_sanpham: dataGetNote[i].id_sp,
        },
      }
      socketio.emit("client-get-data-note-ipsp-sanpham", paramsNote)
    }
    socketio.on("server-get-data-note-ipsp-sanpham", (data) => {
      if (data.length > 0) {
        let arr = [...arrNote.current]
        for (let i = 0; i < data.length; i++) {
          let filter = arr.filter((item) => {
            return item.id_ghichu === data[i].id_ghichu
          })
          if (filter.length === 0) {
            arrNote.current.push(data[i])
          }
        }
        setDataNote([...arrNote.current])
      }
    })
    return () => {
      socketio.off("client-get-data-note-ipsp-sanpham")
      socketio.off("server-get-data-note-ipsp-sanpham")
    }
  }, [dataGetNote])

  // *Quản lý nhận dữ liệu ghi chú.

  /** Quản lý Thêm object add note vào data note để hiển thị. */
  const handleResultStatus = (status_) => {
    if (status_) {
      // Xử lý cập nhật dữ liệu thêm note mới
      socketio.on("server-get-data-note-success-sanpham", (dataNoteNew) => {
        let dataNoteUpdate = []
        if (dataNote) {
          dataNoteUpdate = [...dataNote]
        }
        for (let i = 0; i < dataNoteNew.length; i++) {
          let filterNoteUpdate = dataNoteUpdate.filter((item) => {
            return item.id_ghichu === dataNoteNew[i].id_ghichu
          })
          if (filterNoteUpdate.length === 0) {
            dataNoteUpdate.push(dataNoteNew[i])
          }
        }
        let sortDataNoteUpdate = dataNoteUpdate.sort((a, b) => {
          let min_ = new Date(a.ngaycapnhat).getTime()
          let max_ = new Date(b.ngaycapnhat).getTime()
          return max_ - min_
        })
        setDataNote([...sortDataNoteUpdate])
        checkAddNote.current = dataNoteNew // xác định sự thay đổi
      })
    }
    // Xử lý cập nhật dữ liệu edit note mới.
    socketio.on("server-get-data-note-edit-success-sanpham", (dataNoteEdit) => {
      let dataNoteUpdateEdit = [...dataNote]
      let filterDataNoteEdit = dataNoteUpdateEdit.filter((item) => {
        return item.id_ghichu !== dataNoteEdit[0].id_ghichu
      })
      if (filterDataNoteEdit.length > 0) {
        filterDataNoteEdit.push(dataNoteEdit[0])
      }
      let sortDataNoteUpdate = filterDataNoteEdit.sort((a, b) => {
        let min_ = new Date(a.ngaycapnhat).getTime()
        let max_ = new Date(b.ngaycapnhat).getTime()
        return max_ - min_
      })
      setDataNote([...sortDataNoteUpdate])
      checkAddNote.current = dataNoteEdit // xác định sự thay đổi
    })
  }
  useEffect(() => {
    return () => {
      socketio.off("client-get-data-note-success-sanpham")
      socketio.off("server-get-data-note-success-sanpham")
      socketio.off("client-get-data-note-edit-success-sanpham")
      socketio.off("server-get-data-note-edit-success-sanpham")
    }
  }, [checkAddNote.current])

  /** * === Quản lý Thêm object add note vào data note để hiển thị. ==== */
  const [modalAds, setModalAds] = useState(false)
  const idSanphamAds = useRef()
  // Chuyển đổi trạng thái quảng cáo
  const [modalStatusAds, setModalStatusAds] = useState(false)
  const handleStatusAds = (idSP, index) => {
    setModalStatusAds(true)
    idSanphamAds.current = { idSP: idSP, index: index }
  }
  const handleCloseStatusAds = () => {
    setModalStatusAds(false)
    idSanphamAds.current = undefined
  }
  const handleStatusAdsSuccess = (object) => {
    const paramsCheck = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: object,
    }
    socketio.emit("client-edit-row-expired-ads-bds", paramsCheck)
  }

  // Tạo hợp đồng quảng cáo.
  const handleAddBdsContract = (idSP,index) => {
    setModalAds(true)
    idSanphamAds.current = { idSP: idSP , index: index}
  }
  const handleCloseStatusModalAds = () => {
    setModalAds(false)
    idSanphamAds.current = undefined
  }
  const handleAddDataContractSuccess = (status_) => {
    if (status_) {
      setModalAds(false)
    }
  }

  // Quản lý cập nhật lại dữ liệu hiển thị
  useEffect(() => {
    socketio.on("server-edit-status-sanpham", (object) => {
      if (object.status === "success") {
        // Cập nhật lại dữ liệu hiển thị
        let dataCusUpdate = [...dataCusSale]
        dataCusUpdate[idSanphamAds.current.index] = object.data[0]
        // gán giá cập nhật.
        setDataCusSale([...dataCusUpdate])
        // Trả về trạng thái modal ban đầu
        setModalStatusAds(false)
        setModalAds(false)
        idSanphamAds.current = undefined
      }
    })
    return () => {
      socketio.off("client-edit-row-expired-ads-bds")
      socketio.off("server-edit-status-sanpham")
    }
  }, [dataCusSale])

  /** * ==== Quản lý thay đổi status quảng cáo ===== */

  /** Quản lý thay đổi tình trạng (đã bán) */
  const handleChangeTinhtrang = (idSP, index) => {
    const checkChangeTinhtrang = window.confirm(
      "Bạn muốn chuyển sang trạng thái đã bán ?",
    )
    if (checkChangeTinhtrang) {
      const paramsCheck = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: { id_sp: idSP },
      }
      socketio.emit(
        "client-check-get-data-status-tinhtrang-sanpham",
        paramsCheck,
      )
      socketio.on("server-check-get-data-status-tinhtrang-sanpham", (data) => {
        const paramsEditTinhtrang = {
          iden: process.env.REACT_APP_IDEN_DASH,
          object: {
            tinhtrang: data[0].tinhtrang === 1 ? 0 : 1,
            id_sp: idSP,
          },
        }
        socketio.emit("client-edit-tinhtrang-sanpham", paramsEditTinhtrang)
        socketio.on("server-edit-tinhtrang-sanpham", (object) => {
          if (object.status === "success") {
            // Cập nhật lại dữ liệu hiển thị
            let dataCusUpdate = [...dataCusSale]
            dataCusUpdate[index] = object.data[0]
            // gán giá cập nhật.
            setDataCusSale([...dataCusUpdate])
          }
        })
      })
    }
  }
  /** *Quản lý thay đổi tình trạng (đã bán) */

  /** Quản lý tắt sự kiện cho edit quảng cáo, đã bán */
  useEffect(() => {
    return () => {
      socketio.off("client-check-get-data-status-tinhtrang-sanpham")
      socketio.off("server-check-get-data-status-tinhtrang-sanpham")

      socketio.off("client-edit-tinhtrang-sanpham")
      socketio.off("server-edit-tinhtrang-sanpham")
    }
  }, [dataCusSale])
  /** *Quản lý tắt sự kiện cho edit quảng cáo, đã bán */

  /** Quản lý lịch sử */
  const [modalHistory, setModalHistory] = useState(false)

  const dataSanphamHis = useRef([])
  const handleShowModalHistory = (idSP) => {
    setModalHistory(true)
    idSanpham.current = idSP
    dataSanphamHis.current = dataCusSale.filter((item) => {
      return item.id_sp === idSP
    })
  }
  const handleCloseModalHistory = () => {
    setModalHistory(false)
    idSanpham.current = undefined
    dataSanphamHis.current = []
  }
  /** *Quản lý lịch sử */

  /** Quản lý get data cate product ban đầu */
  const [dataCateProduct, setDataCateProduct] = useState([])
  useEffect(() => {
    for (let i = 0; i < dataGetNote.length; i++) {
      const paramsGetCate = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: {
          kn_sanpham: dataGetNote[i].id_sp,
          author: dataUser[0].id_user,
        },
      }
      socketio.emit(
        "client-get-data-cate-product-author-knsanpham",
        paramsGetCate,
      )
    }
    socketio.on(
      "server-get-data-cate-product-author-knsanpham",
      (dataKinhdoanh) => {
        setDataCateProduct((old) => [...old, ...dataKinhdoanh])
      },
    )
    return () => {
      socketio.off("client-get-data-cate-product-author-knsanpham")
      socketio.off("server-get-data-cate-product-author-knsanpham")
    }
  }, [dataGetNote])
  /** *Quản lý get data cate product ban đầu */

  /** Quản lý thêm vào mục khách kinh doanh, khách nét */
  const handleAddKhachkinhdoanhKhachnet = (idSP, category) => {
    let checkAddCateKinhdoanh = window.confirm(
      "Thêm vào mục khách kinh doanh ?",
    )
    if (checkAddCateKinhdoanh) {
      const paramsCateKinhdoanh = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: {
          kn_sanpham: idSP,
          tacgia: dataUser[0].id_user,
        },
      }
      socketio.emit("client-check-data-cate-product", paramsCateKinhdoanh)
      socketio.on("server-check-data-cate-product", (dataCate) => {
        if (dataCate.length === 0) {
          // Xử lý thêm mới
          let paramsAddCateKinhdoanh
          if (category === "khachkinhdoanh") {
            paramsAddCateKinhdoanh = {
              iden: process.env.REACT_APP_IDEN_DASH,
              object: {
                kn_sanpham: idSP,
                tacgia: dataUser[0].id_user,
                kinhdoanh: 1,
              },
            }
          } else if (category === "khachnet") {
            paramsAddCateKinhdoanh = {
              iden: process.env.REACT_APP_IDEN_DASH,
              object: {
                kn_sanpham: idSP,
                tacgia: dataUser[0].id_user,
                khachnet: 1,
              },
            }
          }
          socketio.emit(
            "client-create-data-cate-product",
            paramsAddCateKinhdoanh,
          )
          socketio.on(
            "server-update-data-cate-product-success",
            (dataCateUpdate) => {
              // Cập nhật data cho cate product.
              setDataCateProduct((old) => [...old, ...dataCateUpdate])
            },
          )
        } else {
          // Xử lý Edit.
          let paramsEditCate
          if (category === "khachkinhdoanh") {
            paramsEditCate = {
              iden: process.env.REACT_APP_IDEN_DASH,
              object: {
                id_cate_product: dataCate[0].id_cate_product,
                kinhdoanh: dataCate[0].kinhdoanh === 1 ? 0 : 1,
                kn_sanpham: idSP,
                tacgia: dataUser[0].id_user,
              },
            }
            // Xửu lý edit khách kinh doanh
            socketio.emit(
              "client-edit-row-kinhdoanh-cate-product",
              paramsEditCate,
            )
          } else if (category === "khachnet") {
            paramsEditCate = {
              iden: process.env.REACT_APP_IDEN_DASH,
              object: {
                id_cate_product: dataCate[0].id_cate_product,
                khachnet: dataCate[0].khachnet === 1 ? 0 : 1,
                kn_sanpham: idSP,
                tacgia: dataUser[0].id_user,
              },
            }
            // Xửu lý edit khachnet.
            socketio.emit(
              "client-edit-row-khachnet-cate-product",
              paramsEditCate,
            )
          }
          // Xử lý cập nhật lại dữ liệu
          socketio.on(
            "server-update-data-cate-kinhdoanh-product-success",
            (dataUpdate) => {
              let dataUpdateKinhdoanh = [...dataCateProduct]
              let filter_ = dataUpdateKinhdoanh.filter((item) => {
                return item.id_cate_product !== dataUpdate[0].id_cate_product
              })
              filter_.push(dataUpdate[0])
              setDataCateProduct([...filter_])
            },
          )
        }
      })
    }
  }

  /** Quản lý thêm vào mục khách kinh doanh, khách nét */

  // Quản lý tắt sự kiện socketio (khách kinh doanh, khách nét).
  useEffect(() => {
    return () => {
      socketio.off("client-check-data-cate-product")
      socketio.off("server-check-data-cate-product")
      socketio.off("client-create-data-cate-product")
      socketio.off("server-update-data-cate-product-success")
      socketio.off("client-edit-row-kinhdoanh-cate-product")
      socketio.off("server-update-data-cate-kinhdoanh-product-success")
      socketio.off("client-edit-row-khachnet-cate-product")
    }
  }, [dataCateProduct])

  /** Quản lý đặt lịch hẹn */
  const [modalBooking, setModalBooking] = useState(false)
  const bookingIDSanpham = useRef()
  const handleShowBookingModal = (idSP) => {
    setModalBooking(true)
    bookingIDSanpham.current = idSP
  }
  const handleCloseBookingModal = () => {
    setModalBooking(false)
    bookingIDSanpham.current = undefined
  }
  const handleResponseBooking = (dataUpdateBooking) => {
    if (dataUpdateBooking.length > 0) {
      const dataUpdateCatePro = [...dataCateProduct]
      let filterDataUpdateCatePro = dataUpdateCatePro.filter((item) => {
        return item.id_cate_product !== dataUpdateBooking[0].id_cate_product
      })
      filterDataUpdateCatePro.push(dataUpdateBooking[0])
      setDataCateProduct([...filterDataUpdateCatePro])
      setModalBooking(false)
      bookingIDSanpham.current = undefined
    }
  }
  /** *Quản lý đặt lịch hẹn */

  /** Quản lý modal edit data sale */
  const [modalEditDataSale, setModalEditDataSale] = useState(false)
  const dataSaleEdit = useRef([])
  const indexEdit = useRef()
  const handleShowModalFormEditDataSale = (idSP, index) => {
    setModalEditDataSale(true)
    dataSaleEdit.current = dataCusSale.filter((item) => {
      return item.id_sp === idSP
    })
    indexEdit.current = index;
  }
  const handleCloseModalEditDataSale = () => {
    setModalEditDataSale(false)
    dataSaleEdit.current = []
    indexEdit.current = undefined
  }

  const handleEditDataSaleSuccess = (object) => {
    if (object.status) {
      const paramsUpdate = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: {
          id_sp: object.id_sp,
        },
      }
      socketio.emit("client-filter-data-sale-idsp-sanpham", paramsUpdate)
      socketio.on("server-filter-data-sale-idsp-sanpham", (dataUpdate) => {
        const dataUpdateEdit = [...dataCusSale]
        dataUpdateEdit[object.index] = dataUpdate[0]
        setDataCusSale([...dataUpdateEdit])
        setModalEditDataSale(false)
        dataSaleEdit.current = []
      })
    }
  }
  useEffect(() => {
    return () => {
      socketio.off("client-filter-data-sale-idsp-sanpham")
      socketio.off("server-filter-data-sale-idsp-sanpham")
    }
  }, [dataCusSale])

  



  /** *Quản lý modal edit data sale */
  if (dataCusSale.length === 0 && isLoading) {
    return <div className="text-center mt-3 fs-15">Chưa có dữ liệu.</div>
  }

  return (
    <div className={cx("wrapper_listItem")}>
      <div className={cx("count-sticky", "modal-active")}>
        <div
          className="alert alert-primary rounded-0"
          style={{ height: "30px" }}
        >
          Số lượng dữ liệu:{" "}
          <span className="fw-5 me-1 ms-1">{dataCusSale.length}</span> khách
        </div>
      </div>
      <div>
        <List
          width={window.innerWidth - 40}
          height={window.innerHeight}
          rowCount={dataCusSale.length}
          rowHeight={100} // chiều cao của từng sản phẩm
          //overscanRowCount={9} //tải thêm 5 sản phẩm ngoài viewport
          rowRenderer={({ index, key, style }) => {
            const item = dataCusSale[index]
            // Xác định thời gian.
            let addtime = moment(item.ngaycapnhat)
            let currentTime = moment()
            let diffTime = currentTime.diff(addtime, "days")
            let addtime_
            if (diffTime <= 2) {
              addtime_ = moment(item.ngaycapnhat)
                .local("vi")
                .fromNow()
                .replace("một", "1")
            } else {
              addtime_ = moment(item.ngaycapnhat).format("DD-MM-YY")
            }
            // Xác định đầu số mạng.
            let arrPhone = item.sodienthoai.trim().split("-")
            let phone_1 = dataDausomang.filter((item_) => {
              return item_.dauso === arrPhone[0].trim().slice(0, 3)
            })
            let phone_2 = dataDausomang.filter((item_) => {
              if (arrPhone[1]) {
                return item_.dauso === arrPhone[1].trim().slice(0, 3)
              }
            })

            // format price.
            let formatPrice = item.giasanpham.replace(/[.,]/g, "")

            // List note.
            let listNote
            if (dataNote) {
              listNote = dataNote.filter((item__) => {
                return item__.kn_sanpham === item.id_sp
              })
            } else {
              listNote = []
            }

            // Xác định data cate product.
            let filterCateProduct = dataCateProduct.filter((itemCate) => {
              return itemCate.kn_sanpham === item.id_sp
            })
            let checkKhachhen
            if (filterCateProduct.length > 0) {
              let booking = moment(filterCateProduct[0].booking)
              let currentTime = moment()
              let diffTime = booking.diff(currentTime, "days")
              if (diffTime >= 0) {
                checkKhachhen = (
                  <span className="text-success">
                    Khách hẹn <IoMdCheckmarkCircle />
                  </span>
                )
              } else {
                checkKhachhen = <span>Khách hẹn</span>
              }
            } else {
              checkKhachhen = <span>Khách hẹn</span>
            }

            // Tô mầu cho từ khóa tìm kiếm fast.
            let colorText = keywordsColor || keywordsColorFilter
            let hoten = item.hoten
            let diachi = item.diachi
            let phone = item.sodienthoai
            if (colorText) {
              hoten = handleColorTextSearch(colorText, hoten)
              diachi = handleColorTextSearch(colorText, diachi)
              phone = handleColorTextSearch(colorText, phone)
            }

            // Set thời gian khách hẹn.
            let arrKhachhen
            if (dataKhachhen && cate === "/khach-hen") {
              if (dataKhachhen.length > 0) {
                arrKhachhen = dataKhachhen.filter((item_) => {
                  return item_.kn_sanpham === item.id_sp
                })
              }
            }

            return (
              <div
                className={cx("dataSaleItem")}
                key={item.id_sp}
                style={style}
              >
                <div
                  className={cx("d-flex align-items-center", "container-item")}
                  style={{ height: "100%" }}
                >
                  {/* Info data */}
                  <div
                    className={cx(
                      "col-5 p-1 d-flex align-items-center",
                      "item-a",
                      item.status === 1 && "bg-warning-tran",
                      item.tinhtrang === 1 && "bg-danger-tran",
                    )}
                    style={{ height: "100%" }}
                  >
                    <div>
                      <div className="d-flex align-items-center">
                        <span
                          className={cx(
                            "avatar avatar-xs me-2 avatar-rounded",
                            item.onlineoffline === 1 ? "online" : "",
                          )}
                        >
                          <img src={"/" + item.avatar_author} alt="img" />
                        </span>
                        <span className="fs-14 fw-5">
                          {<TextLimit text={diachi} number={86} />}
                        </span>
                        {diffTime <= 1 && (
                          <span className="badge bg-primary ms-1 rounded-5">
                            Mới
                          </span>
                        )}
                      </div>
                      {/* <h6>Chính chủ cần bán nhà đường tuyệt vời Ngô Quyền Hải Phòng</h6> */}
                      <p
                        className="mb-0 fs-14"
                        style={{ paddingLeft: "0.5rem" }}
                      >
                        <span className="text-muted">
                          {addtime_}
                          <BsDot />
                          <span className="text-secondary">
                            {item.quanhuyen}
                          </span>
                          <BsDot />
                        </span>
                        <span className="fw-5">
                          {hoten}: {phone}
                        </span>
                        <BsDot />
                        <span className="text-muted">
                          {phone_1.length > 0 && phone_1[0].tennhamang}
                        </span>
                        <span className="text-muted">
                          {phone_2.length > 0 && " - " + phone_2[0].tennhamang}
                        </span>
                      </p>
                      {/* Handle */}
                      <div
                        className="d-flex gap-0 align-items-center"
                        style={{ height: " 26px" }}
                      >
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="outline-light border-0 p-0 py-0 transparent text-info-1 px-2 d-flex align-items-center" // class mặc định
                            bsPrefix="custom-dropdown-toggle"
                            style={{
                              border: "1px solid rgb(232, 232, 232)",
                              height: "30px",
                            }}
                          >
                            <IoColorWand />
                            Tình trạng
                          </Dropdown.Toggle>
                          <Dropdown.Menu style={{ backgroundColor: "#fceab3" }}>
                            <li
                              className="dropdown-item d-flex fs-14 fw-5"
                              onClick={() => handleStatusAds(item.id_sp, index)}
                            >
                              {item.status === 1 ? (
                                <span className="text-success">
                                  Đã quảng cáo
                                </span>
                              ) : (
                                "Đã quảng cáo"
                              )}
                            </li>
                            <li
                              className="dropdown-item d-flex fs-14 fw-5"
                              onClick={() =>
                                handleAddBdsContract(item.id_sp, index)
                              }
                            >
                              Tạo hợp đồng
                            </li>
                            <li
                              className="dropdown-item d-flex fs-14 fw-5"
                              onClick={() =>
                                handleChangeTinhtrang(item.id_sp, index)
                              }
                            >
                              {item.tinhtrang === 1 ? (
                                <span className="text-danger">Đã bán</span>
                              ) : (
                                "Đã bán"
                              )}
                            </li>
                          </Dropdown.Menu>
                        </Dropdown>
                        <button
                          className="text-info-1 btn-outline-light border-0 bg-transparent fs-14 px-2 p-0 rounded-2 d-flex align-items-center"
                          style={{ border: "1px solid rgb(232, 232, 232)" }}
                          onClick={() => handleShowModalHistory(item.id_sp)}
                        >
                          <MdOutlineWorkHistory className="me-1" />
                          Lịch sử
                        </button>
                        {/* <button
                    className="text-info-1 btn-outline-light border-0 bg-transparent fs-14 p-0 rounded-2 me-2"
                    style={{ border: "1px solid rgb(232, 232, 232)" }}
                  >
                    Xin thông tin
                  </button> */}
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="outline-light border-0 p-0 px-2 py-0 transparent text-info-1 d-flex align-items-center" // class mặc định
                            bsPrefix="custom-dropdown-toggle"
                            style={{
                              border: "1px solid rgb(232, 232, 232)",
                              height: "30px",
                            }}
                          >
                            <IoDuplicateOutline className="me-1" />
                            Thêm vào mục
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            style={{
                              width: "186px",
                              backgroundColor: "#fceab3",
                            }}
                          >
                            <li
                              className="dropdown-item d-flex fs-14 fw-5"
                              onClick={() =>
                                handleAddKhachkinhdoanhKhachnet(
                                  item.id_sp,
                                  "khachkinhdoanh",
                                )
                              }
                            >
                              {filterCateProduct.length > 0 ? (
                                filterCateProduct[0].kinhdoanh === 1 ? (
                                  <span className="text-success">
                                    Khách kinh doanh <IoMdCheckmarkCircle />
                                  </span>
                                ) : (
                                  "Khách kinh doanh"
                                )
                              ) : (
                                "Khách kinh doanh"
                              )}
                            </li>
                            <li
                              className="dropdown-item d-flex fs-14 fw-5"
                              onClick={() =>
                                handleAddKhachkinhdoanhKhachnet(
                                  item.id_sp,
                                  "khachnet",
                                )
                              }
                            >
                              {filterCateProduct.length > 0 ? (
                                filterCateProduct[0].khachnet === 1 ? (
                                  <span className="text-success">
                                    Khách nét <IoMdCheckmarkCircle />
                                  </span>
                                ) : (
                                  "Khách nét"
                                )
                              ) : (
                                "Khách nét"
                              )}
                            </li>
                            <li
                              className="dropdown-item d-flex fs-14 fw-5"
                              onClick={() => handleShowBookingModal(item.id_sp)}
                            >
                              {checkKhachhen}
                            </li>
                          </Dropdown.Menu>
                        </Dropdown>

                        <button
                          className="text-info-1 btn-outline-light border-0 bg-transparent fs-14 p-0 rounded-2 px-2 d-flex align-items-center"
                          style={{ border: "1px solid rgb(232, 232, 232)" }}
                          onClick={() =>
                            handleShowModalFormEditDataSale(item.id_sp, index)
                          }
                        >
                          <LiaEdit className="me-1" />
                          Sửa
                        </button>

                        {/* show thời gian hẹn */}
                        {cate === "/khach-hen" &&
                          arrKhachhen &&
                          (arrKhachhen.length > 0 ? (
                            <div className="fs-14 text-danger d-flex align-items-center ms-2">
                              <IoCalendarOutline className="me-1" />
                              {moment(arrKhachhen[0].booking)
                                .locale("vi")
                                .fromNow()
                                .replace("một", "1")}
                            </div>
                          ) : (
                            ""
                          ))}
                        {/* Show thời gian hết hạn */}
                        {cate === "/khach-het-han" && (
                          <div className="fs-14 text-danger d-flex align-items-center ms-2">
                            {moment(item.expired)
                              .locale("vi")
                              .fromNow()
                              .replace("một", "1")}
                          </div>
                        )}
                      </div>
                      {/* *Handle */}
                    </div>
                  </div>
                  <div
                    className={cx(
                      "col-2 px-2 fs-14 p-1 d-flex align-items-center",
                      "item-b",
                    )}
                    style={{ height: "100%" }}
                  >
                    <div>
                      <div>
                        {item.duongngo && (
                          <span>Đường/Ngõ {item.duogngospan} </span>
                        )}
                        <span>
                          Giá {handleFormatPrice(Number(formatPrice))}
                        </span>
                        <BsDot />
                        <span>
                          Dtmb {item.dtmb_mt}m<sup>2</sup>
                        </span>

                        {item.huongnha && (
                          <>
                            <BsDot />
                            <span>Hướng {item.huongnha}</span>
                          </>
                        )}
                        {item.phaply && (
                          <>
                            <BsDot />
                            <span>Pháp lý {item.phaply}</span>
                          </>
                        )}
                        {/* Chi tiết sản phẩm */}
                        <DetailDataSale socketio={socketio} dataItem={item} />
                      </div>
                      <div>
                        {item.status === 1 && (
                          <span className="badge bg-success me-1 rounded-5">
                            Đã quảng cáo
                          </span>
                        )}
                        {item.tinhtrang === 1 && (
                          <span className="badge bg-danger rounded-5">
                            Đã bán
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* Note data */}
                  <div className={cx("mw-1000", "p-1 d-flex")}>
                    {listNote && (
                      <ListNoteDataSalePC
                        dataNote={listNote}
                        socketio={socketio}
                        idSanpham={item.id_sp}
                        indexNote={index}
                        result={handleResultStatus}
                        keywordsColor={keywordsColorFilter}
                      />
                    )}
                    {!listNote && (
                      <div className="text-center mt-3 fs-15">Đang tải...</div>
                    )}
                  </div>
                </div>
              </div>
            )
          }}
        />
      </div>

      {/* Kiểm tra tải thêm dữ liệu mới */}
      {/* {!isLoading && (
        <div className="text-center mt-3 mb-3 fs-15">
          <LoadingSpinner />
        </div>
      )} */}
      {/* Xác nhận đã lấy hết data */}
      {isLoading && (
        <div className="text-center mt-3 mb-3 fs-15">Bạn đã xem hết rồi.</div>
      )}

      {/* Modal lịch sử */}
      {modalHistory && (
        <ModalReact
          modalTitle="Lịch sử sửa sản phẩm"
          showModal={modalHistory}
          handleClose={handleCloseModalHistory}
          theme={
            <ListHistoryProduct
              idSanpham={idSanpham.current}
              socketio={socketio}
              dataSanpham={dataSanphamHis.current}
            />
          }
        />
      )}
      {/* Modal Booking */}
      {modalBooking && (
        <ModalReact
          modalTitle="Đặt lịch hẹn"
          showModal={modalBooking}
          handleClose={handleCloseBookingModal}
          theme={
            <FormBooking
              idSanpham={bookingIDSanpham.current}
              socketio={socketio}
              response={handleResponseBooking}
            />
          }
        />
      )}
      {/* Modal edit data sale */}
      {modalEditDataSale && (
        <ModalReact
          modalTitle="Sửa thông tin sản phẩm"
          showModal={modalEditDataSale}
          handleClose={handleCloseModalEditDataSale}
          theme={
            <FormEditDataSale
              dataSaleEdit={dataSaleEdit.current}
              indexEdit={indexEdit.current}
              resultStatus={handleEditDataSaleSuccess}
            />
          }
        />
      )}
      {/* Modal status ads */}
      {modalStatusAds && (
        <ModalReact
          modalTitle="Chuyển sang đã quảng cáo"
          showModal={modalStatusAds}
          handleClose={handleCloseStatusAds}
          theme={
            <FormStatusAds
              idSanpham={idSanphamAds.current}
              socketio={socketio}
              dataUser={dataUser}
              resultObject={handleStatusAdsSuccess}
            />
          }
        />
      )}
      {/* Modal ads contract */}
      {modalAds && idSanphamAds.current && (
        <ModalReact
          modalTitle="Tạo hợp đồng quảng cáo"
          showModal={modalAds}
          handleClose={handleCloseStatusModalAds}
          theme={
            <FormAddContract
              idSanpham={idSanphamAds.current}
              socketio={socketio}
              dataUser={dataUser}
              resultStatus={handleAddDataContractSuccess}
            />
          }
        />
      )}
    </div>
  )
}
export default memo(ListDataSaleCateOtherPC)



// Chuyển đổi trạng thái quảng cáo.
function FormStatusAds({idSanpham, socketio, dataUser, resultObject}) {
  const { register, handleSubmit, formState: { errors }} = useForm()

  const handleOnSubmit = (object) => {
    let expired_ = moment(object.expired)
    let currentTime = moment();
    let diffTime = expired_.diff(currentTime, "days");
    if (diffTime > 0) {
      object.status = 1
    } else {
      object.status = 0
    }
    object.author_expired = dataUser[0].id_user;
    object.id_sp = idSanpham.idSP
    alert("Chuyển trạng thái thành công.")
    // Trả về object để edit.
    resultObject(object)
  }
  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <div className="col-12">
        <div className="form-floating mb-3 col-12">
          <input
            type="datetime-local"
            className="form-control"
            placeholder="Tên input"
            {...register("expired", { required: true })}
          />
          <label className="fs-15 text-muted">Ngày kết thúc</label>
          {errors.expired && (
            <span className="text-danger fs-12">
              * Bạn cần chọn thời hạn kết thúc.
            </span>
          )}
        </div>
      </div>
      {/* Button */}
      <div className="px-4 py-3 border-top border-block-start-dashed d-sm-flex justify-content-center">
        <button className="btn btn-primary-light m-1" type="submit">
          Lưu lại
        </button>
      </div>
    </form>
  )
}

// List ghi chú.
function ListNoteDataSalePC({dataNote, socketio, idSanpham, indexNote, result, keywordsColor }) {
  /** Quản lý trạng thái thay đổi ghi chú. */
  const [editNote, setEditNote] = useState(false)
  const [valueNoteEdit, setValueNoteEdit] = useState("")
  const checkEditNote = useRef()
  const handleValueNoteEdit = (e) => {
    setValueNoteEdit(e.target.value)
    setEditNote(true)
  }
  // Hủy edit note
  const handleCancelEditNote = (noteText) => {
    setEditNote(false)
    setValueNoteEdit(noteText)
  }
  // Xác nhận edit note.
  const handleEditNoteSuccess = (id_) => {
    setEditNote(false);
    const paramsEditNote = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        id_ghichu: id_,
        ghichu: valueNoteEdit,
        ngaycapnhat: moment().format("YYYY-MM-DD HH:mm:ss")
      }
    };
    // Xử lý edit note.
    socketio.emit("client-edit-data-note-sanpham", paramsEditNote)
    socketio.on("server-edit-data-note-sanpham", (data) => {
      if (data === "success") {
        // Cập nhật lại dữ liệu note mới.
        const paramsNoteNew = {
          iden: process.env.REACT_APP_IDEN_DASH,
          object: {
            id_ghichu: id_,
          },
        };
        socketio.emit("client-get-data-note-edit-success-sanpham", paramsNoteNew)
        result(true)
        checkEditNote.current = id_ // xác nhận id thay đổi đồng nghĩa sửa ghi chú khác
      }
    })
  }
  const checkIDEditNote = useRef()
  const handleCheckIDNote = (idNote) => {
    checkIDEditNote.current = idNote
  }
  useEffect(() => {
    return () => {
      socketio.off("client-edit-data-note-sanpham")
      socketio.off("server-edit-data-note-sanpham")
    }
  }, [checkEditNote.current])
  /** *Quản lý trạng thái thay đổi ghi chú. */


  /** Quản lý thêm ghi chú mới. */
  const [modalAddNote, setModalAddNote] = useState(false)
  const objectSanpham = useRef()
  const handleShowModalAddNote = (idSP, index) => {
    setModalAddNote(true)
    objectSanpham.current = {
      id_sp: idSP,
      index: index,
    }
  }
  const handleCloseModalAddNote = () => {
    setModalAddNote(false)
    objectSanpham.current = undefined
  }
  // Add note success.
  const handleResultAddNoteSuccess = (status_) => {
    if (status_) {
      setModalAddNote(false)
      objectSanpham.current = undefined
      result(status_)
    }
  }

  /** *Quản lý thêm ghi chú mới. */

  return (
    <div
      className={cx("d-flex gap-2 align-items-center position-relative")}
      style={{ paddingLeft: "9px" }}
    >
      {/* Button add */}
      <button
        className={cx(
          "buttonAddNote",
          "border-1 rounded-3 btn btn-primary-light btn-wave",
        )}
        style={{ height: "60px" }}
        onClick={() => handleShowModalAddNote(idSanpham, indexNote)}
      >
        <IoIosAddCircleOutline className="fs-21" />
      </button>

      {/* List note */}
      {dataNote.length > 0 &&
        dataNote.map((item) => {
          // format time.
          let addtime = moment(item.ngaycapnhat)
          let currentTime = moment();
          let diffTime = addtime.diff(currentTime, "days");
          // đổi mầu cho text search.
          let textSearch = keywordsColor
          let note = item.ghichu
          let showNote
          if (textSearch) {
            if (textSearch.toLowerCase().includes(note.toLowerCase())) {
              showNote = handleColorTextSearch(textSearch, note)
            }
            
          };
          return (
            <div className={cx("d-flex gap-2")} key={item.id_ghichu}>
              <div className={cx("d-flex align-items-center me-3", "w-350")}>
                <div
                  style={{ width: "15%" }}
                  className="d-flex align-items-center flex-direction-column"
                >
                  <span
                    className={cx(
                      "avatar avatar-xs avatar-rounded mb-2",
                      item.onlineoffline === 1 ? "online" : "",
                    )}
                  >
                    <img
                      src={item.avatar ? "/" + item.avatar : images.user}
                      alt="avatar"
                    />
                  </span>
                  <div
                    className="fs-9 fw-5 text-muted"
                    style={{ textTransform: "uppercase" }}
                  >
                    <span
                      className={cx(
                        "text-muted",
                        diffTime === 0 && "text-primary",
                      )}
                    >
                      {moment(item.ngaycapnhat).locale("vi").format("dddd")}
                    </span>
                  </div>
                  <div
                    className={cx(
                      "fs-15 fw-5 text-muted",
                      diffTime === 0 && "fs-15 fw-5 text-muted text-primary",
                    )}
                  >
                    {moment(item.ngaycapnhat).format("DD-MM")}
                  </div>
                </div>
                <div className={cx("bulkhead")}></div>
                <div style={{ width: "86%" }}>
                  <div className="mb-0 d-flex align-items-center">
                    {/* <span
                      className={cx(
                        "avatar avatar-xs avatar-rounded me-2",
                        item.onlineoffline === 1 ? "online" : "",
                      )}
                    >
                      <img
                        src={item.avatar ? "/" + item.avatar : images.user}
                        alt="avatar"
                      />
                    </span> */}
                    <span
                      className={cx(
                        "text-muted",
                        diffTime === 0 && "text-primary",
                      )}
                    >
                      <span>{item.hoten}</span>
                      {/* <BsDot />
                      {moment(item.ngaycapnhat).locale("vi").format("dddd")}
                      <BsDot />
                      {moment(item.ngaycapnhat).format("DD-MM")} */}
                      <BsDot />
                      {moment(item.ngaycapnhat)
                        .locale("vi")
                        .fromNow()
                        .replace("một", "1")}
                      <span className="ms-1">{showNote ? showNote : ""}</span>
                    </span>
                    {/* Xác nhận sửa */}
                    <div
                      className={cx(
                        "d-flex",
                        editNote
                          ? checkIDEditNote.current === item.id_ghichu
                            ? "d-block"
                            : "d-none"
                          : "d-none",
                      )}
                    >
                      <button
                        className="border-0 bg-transparent text-success"
                        onClick={() => handleEditNoteSuccess(item.id_ghichu)}
                      >
                        <FiCheckCircle className="fs-18" />
                      </button>
                      <button
                        className="border-0 bg-transparent text-danger"
                        onClick={() => handleCancelEditNote(item.ghichu)}
                      >
                        <AiOutlineCloseCircle className="fs-18" />
                      </button>
                    </div>
                  </div>
                  {/* Nội dung ghi chú */}
                  <div className="mb-1 mt-1 border-top border-block-start-dashed"></div>
                  <div
                    className={cx("fs-14 d-flex align-items-center", "h-41")}
                  >
                    <textarea
                      value={
                        valueNoteEdit
                          ? checkIDEditNote.current === item.id_ghichu
                            ? valueNoteEdit
                            : item.ghichu
                          : item.ghichu
                      }
                      className={cx(
                        "border-0 px-1 py-0 fw-5",
                        diffTime === 0 && "text-primary",
                      )}
                      style={{
                        width: "100%",
                        height: "41px",
                        color: "#485056",
                      }}
                      onChange={handleValueNoteEdit}
                      onClick={() => handleCheckIDNote(item.id_ghichu)}
                    />
                  </div>
                  {/* *Nội dung ghi chú */}
                </div>
              </div>
            </div>
          )
        })}
      {/* Modal add note */}
      {modalAddNote && (
        <ModalReact
          modalTitle="Thêm mới ghi chú"
          showModal={modalAddNote}
          handleClose={handleCloseModalAddNote}
          theme={
            <FormAddNote
              object={objectSanpham.current}
              socketio={socketio}
              result={handleResultAddNoteSuccess}
            />
          }
        />
      )}
    </div>
  )
}

// Thêm mới ghi chú.
function FormAddNote({ object, socketio, result }) {
  const {dataUser} = useContext(AppContext)
  const { register, handleSubmit, formState: { errors }, reset } = useForm()
  const checkAddNote = useRef();

  const handleOnSubmit = (objectAdd) => {
    objectAdd.kn_sanpham = object.id_sp
    objectAdd.tacgia = dataUser[0].id_user;
    // Xử lý thêm mới.
    const paramsAddNote = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: objectAdd
    }
    socketio.emit("client-create-data-note-sanpham", paramsAddNote)
    socketio.on("server-create-data-note-sanpham", (data) => {
      if (data === "success") {
        const paramsNoteNew = {
          iden: process.env.REACT_APP_IDEN_DASH,
          object: {
            kn_sanpham: object.id_sp
          }
        }
        socketio.emit("client-get-data-note-success-sanpham", paramsNoteNew)
        result(true)
        // Xác nhận create success .
        checkAddNote.current = objectAdd
      }
    })

  }
  useEffect(() => {
    return () => {
      socketio.off("client-create-data-note-sanpham")
      socketio.off("server-create-data-note-sanpham")
    }
  }, [checkAddNote.current])
  return (
    <div>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <div className="col-xl-12 col-md-12">
          <div className="form-floating mb-4 mt-3">
            <textarea
              className="form-control mt-2"
              placeholder="Nhập ghi chú"
              style={{ height: "99px" }}
              {...register("ghichu", { required: true })}
            />
            {errors.ghichu && (
              <span className="text-danger fs-14">Bạn chưa nhập ghi chú.</span>
            )}
          </div>
        </div>
        {/* Button */}
        <div className="px-4 py-3 text-center border-top border-block-start-dashed d-sm-flex justify-content-center">
          <button className="btn btn-primary-light m-1" type="submit">
            Lưu lại
          </button>
        </div>
      </form>
    </div>
  )
}

// Lịch sử sửa thông tin sản phẩm
function ListHistoryProduct({ idSanpham, socketio, dataSanpham }) {
  const [listHistory, setListHistory] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const paramsHistory = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        kn_sanpham: idSanpham,
      },
    }
    socketio.emit("client-get-data-knsanpham-history", paramsHistory)
    socketio.on("server-get-data-knsanpham-history", (dataHistory) => {
      setListHistory([...dataHistory])
      setLoading(false)
    })
    return () => {
      socketio.off("client-get-data-knsanpham-history")
      socketio.off("server-get-data-knsanpham-history")
    }
  }, [])
  if (loading) {
    return <div className="mt-3 text-center">Đang tải...</div>
  }
  if (listHistory.length === 0) {
    return <div className="mt-3 text-center">Chưa có dữ liệu.</div>
  }
  return (
    <div className="card-body">
      <div>
        <ul className="list-unstyled mb-0 crm-recent-activity">
          {/* Tiêu đề sản phẩm */}
          <div className="text-center mb-3">
            <h6>{dataSanpham[0].diachi}</h6>
          </div>
          {listHistory.map((item) => {
            return (
              <li className="crm-recent-activity-content" key={item.id_history}>
                <div className="d-flex align-items-top">
                  <div className="me-3">
                    <span className="avatar avatar-xs bg-primary-transparent avatar-rounded">
                      {/* <GoDotFill className="fs-21" /> */}
                      <img src={"/" + item.avatar} alt="avatar" />
                    </span>
                  </div>
                  <div className="crm-timeline-content">
                    <span className="fw-semibold fs-15">{item.tieude}</span>
                    <p className="fs-15 mb-0">
                      <span className="text-primary">{item.note_old}</span>{" "}
                      <span className="text-muted">chuyển thành</span>{" "}
                      <span className="text-success">{item.note_new}</span>
                    </p>
                  </div>
                  <div className="flex-fill text-end">
                    <span className="d-block text-muted fs-14 op-7">
                      {moment(item.ngaycapnhat)
                        .locale("vi")
                        .fromNow()
                        .replace("một", "1")}
                    </span>
                  </div>
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}


// Form đặt lịch hẹn sản phẩm.
function FormBooking({ idSanpham, socketio, response }) {
  const { dataUser } = useContext(AppContext)
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const [dataCate, setDataCate] = useState([])
  const checkSuccess = useRef()
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (idSanpham) {
      const paramsCateKinhdoanh = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: {
          kn_sanpham: idSanpham,
          tacgia: dataUser[0].id_user,
        },
      }
      socketio.emit("client-check-data-cate-product", paramsCateKinhdoanh)
      socketio.on("server-check-data-cate-product", (dataCate) => {
        setDataCate([...dataCate])
        setLoading(false)
      })
    }
    return () => {
      socketio.off("client-check-data-cate-product")
      socketio.off("server-check-data-cate-product")
    }
  }, [])

  const handleOnSubmit = (object) => {
    if (dataCate.length > 0) {
      // xử lý edit
      const paramsEdit = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: {
          booking: object.booking,
          id_cate_product: dataCate[0].id_cate_product,
          kn_sanpham: idSanpham,
          tacgia: dataUser[0].id_user
        }
      }
      socketio.emit("client-edit-row-booking-cate-product", paramsEdit)
      socketio.on("server-update-data-cate-booking-product-success", (dataUpdate) => {
        response(dataUpdate);
        // Xác nhận booking success.
        checkSuccess.current = dataUpdate;
        alert("Thay đổi lịch hẹn thành công.")
      })
    } else {
      // Xử lý thêm mới.
      let paramsAddCateBooing = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: {
          kn_sanpham: idSanpham,
          tacgia: dataUser[0].id_user,
          booking: object.booking,
        },
      }
      socketio.emit("client-create-data-cate-product", paramsAddCateBooing)
      socketio.on("server-update-data-cate-product-success", (dataBookingNew) => {
        response(dataBookingNew)
        // Xác nhận booking success.
        checkSuccess.current = dataBookingNew
        alert("Thêm lịch hẹn thành công.")
      })
    }
  }

  useEffect(() => {
    return () => {
      socketio.off("client-edit-row-booking-cate-product")
      socketio.off("server-update-data-cate-booking-product-success")
      socketio.off("client-create-data-cate-product")
      socketio.off("server-update-data-cate-product-success")
    }
  }, [checkSuccess.current])
  if (loading) {
    return <div className="text-center mt-3">Đang tải...</div>
  }

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <div className="col-xl-12 col-md-12">
        <div className="form-floating mb-3">
          <input
            type="datetime-local"
            defaultValue={
              dataCate.length > 0
                ? moment(dataCate[0].booking).format("YYYY-MM-DDTHH:mm")
                : ""
            }
            className="form-control"
            placeholder="Tên input"
            {...register("booking", { required: true })}
          />
          <label>Ngày hẹn</label>
          {errors.booking && (
            <span className="text-danger fs-14">
              Bạn cần chọn thời gian hẹn.
            </span>
          )}
        </div>
      </div>
      {/* Button */}
      <div className="px-4 py-3 border-top border-block-start-dashed d-sm-flex justify-content-center">
        <button className="btn btn-primary-light m-1" type="submit">
          Lưu lại
        </button>
      </div>
    </form>
  )
}


// Modal tạo quảng cáo.
function ComponentStatusAds({ idSanham, socketio, dataUser }) {
  const {register, handleSubmit, formState: {errors}, reset} = useForm()
  
  const handleOnSubmit = (dobject) => {
    // Xử lý edit expired & author_expired

    // Xử lý chuyển thành trạng thái quảng cáo
    // const paramsCheck = {
    //   iden: process.env.REACT_APP_IDEN_DASH,
    //   object: { id_sp: idSP },
    // }
    // socketio.emit(
    //   "client-check-get-data-status-tinhtrang-sanpham",
    //   paramsCheck,
    // )
    // socketio.on("server-check-get-data-status-tinhtrang-sanpham", (data) => {
    //   const paramsEditStatus = {
    //     iden: process.env.REACT_APP_IDEN_DASH,
    //     object: {
    //       status: data[0].status === 1 ? 0 : 1,
    //       id_sp: idSP,
    //     },
    //   }
    //   socketio.emit("client-edit-status-sanpham", paramsEditStatus)
    //   idSanpham.current = idSP
    // })
  }
  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <div className="col-xl-12 col-md-12">
        <div className="form-floating mb-3">
          <input
            type="datetime-local"
            className="form-control"
            placeholder="Tên input"
            {...register("expired", { required: true })}
          />
          <label>Thời hạn quảng cáo</label>
          {errors.expired && (
            <span className="text-danger fs-14">
              Bạn cần chọn thời hạn cho quảng cáo.
            </span>
          )}
        </div>
      </div>
      {/* Button */}
      <div className="px-4 py-3 border-top border-block-start-dashed d-sm-flex justify-content-center">
        <button className="btn btn-primary-light m-1" type="submit">
          Lưu lại
        </button>
      </div>
    </form>
  )
}