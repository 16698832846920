import { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"

function FormEditTaisan({ socketio, dataEdit, result }) {
    const {
      register,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm()

    // set format money.
    const [formatMoney, setFormatMoney] = useState(Number(dataEdit.money).toLocaleString().replace(/[.,]/g, ""))
    const handleValueMoney = (e) => {
      let formatMoney_ = e.target.value.replace(/[.,]/g, "")
      if (Number(formatMoney_)) {
        setFormatMoney(
          Number(formatMoney_).toLocaleString().replace(/[.]/g, ","),
        )
      } else {
        alert("Bạn cần nhập dạng số.")
      }
    }

    const checkOnChange = useRef()
    const handleOnSubmit = (object) => {
      object.money = object.money.replace(/[,]/g, "")
        object.id = dataEdit.id
      // Xử lý edit.
        const paramsCreate = {
            iden: process.env.REACT_APP_IDEN_DASH,
            object: object,
        };
      socketio.emit("client-edit-data-ketoan-taisan", paramsCreate)
      socketio.on("server-edit-data-ketoan-taisan", (data) => {
        if (data.status === "success") {
          alert("Sửa thông tin thành công.")
          result({
            status: true,
            dataUpdate: data.data,
          })
          checkOnChange.current = object
        }
      })
    }
    useEffect(() => {
      return () => {
        socketio.off("client-edit-data-ketoan-taisan")
        socketio.off("server-edit-data-ketoan-taisan")
      }
    }, [checkOnChange.object])

    return (
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <div className="col-xl-12 col-md-12">
          <div className="row">
            <div className="form-floating mb-3 col-12">
              <input
                type="text"
                className="form-control rounded-3"
                placeholder="Tên input"
                {...register("loai_taisan", { required: true })}
                defaultValue={dataEdit.loai_taisan}
              />
              <label className="fs-15 ms-2 text-muted">
                Loại tài sản <span className="text-danger">*</span>
              </label>
              {errors.loai_taisan && (
                <span className="text-danger fs-14">
                  Bạn cần chọn loại tài sản.
                </span>
              )}
            </div>
            <div className="form-floating mb-3 col-12">
              <input
                type="text"
                className="form-control rounded-3"
                placeholder="Tên input"
                {...register("money", { required: true })}
                value={formatMoney}
                onChange={handleValueMoney}
              />
              <label className="fs-15 ms-2 text-muted">
                Số vốn <span className="text-danger">*</span>
              </label>
              {errors.money && (
                <span className="text-danger fs-14">Bạn cần số vốn.</span>
              )}
            </div>
          </div>
        </div>
        {/* Ghi chú */}
        <div className="form-floating mb-4 mt-3">
          <textarea
            className="form-control"
            placeholder="Nhập ghi chú"
            style={{ height: "100px" }}
            {...register("ghichu", { required: true })}
            defaultValue={dataEdit.ghichu}
          ></textarea>
          <label className="fs-15 text-muted">
            Ghi chú <span className="text-danger">*</span>
          </label>
          {errors.ghichu && (
            <span className="text-danger fs-14">
              Bạn cần ghi chú cho khoản bổ sung này.
            </span>
          )}
        </div>
        {/* Button */}
        <div className="px-4 py-3 border-top border-block-start-dashed d-flex justify-content-center">
          <button
            className="btn btn-secondary-gradient btn-wave waves-effect waves-light"
            type="submit"
          >
            Lưu lại
          </button>
        </div>
      </form>
    )
}

export default FormEditTaisan