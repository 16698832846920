import { useState } from "react"
import classNames from "classnames/bind"
import styles from "../Dashboard.module.scss"
import SidebarDashboard2 from "../../../Components/Sidebar/SidebarDashboard-2"
import HeaderDashboard2 from "../../../Components/Header/HeaderDashboard-2"
import ThanhToan from "./ThanhToan"
import CheckPCMobile from "../../../handle-reuses/reuses/check.pc.mobile"
import HeaderDashboardMobile from "../../../Components/Header/HeaderDashboardMobile"
import FooterDashboard from "../../../Components/Footer/FooterDashboard"
const cx = classNames.bind(styles)

function PageThanhToan2() {
  const [hideShowSidebar, setHideShowSidebar] = useState(false)
  const handleResultHideShowSidebar = (key) => {
    setHideShowSidebar(key)
  }
  return (
    <>
      <div className="page">
        <CheckPCMobile>
          {(isMobile) =>
            isMobile ? (
              // Giao diện trên mobile
              <>
                {/* header */}
                <HeaderDashboardMobile />
                {/* Content */}
                <ThanhToan />
              </>
            ) : (
              // Giao diện trên pc
              <>
                {/* header */}
                <HeaderDashboard2 result={handleResultHideShowSidebar} />
                {/* sidebar */}
                <SidebarDashboard2 active={hideShowSidebar} />
                {/* Content */}
                <ThanhToan active={hideShowSidebar} />
              </>
            )
          }
        </CheckPCMobile>

        {/* <!-- Footer Start --> */}
        <FooterDashboard />
        {/* <!-- Footer End --> */}
      </div>
    </>
  )
}

export default PageThanhToan2
