import { useContext, useEffect, useRef, useState } from "react"
import { Dropdown } from "react-bootstrap"
import { MdOutlineSwitchAccessShortcutAdd } from "react-icons/md"
import ModalReact from "../../../Components/Modal/ModalReact";
import AppContext from "../../../handle-reuses/Contexts/CreateContexts";
import { useForm } from "react-hook-form";
import moment from "moment";
import { BsDot } from "react-icons/bs";
import { LiaEdit } from "react-icons/lia";

function ComponentMission() {
    const {dataUser, socketio} = useContext(AppContext)
    // Quản lý thêm nhiệm vụ.
    const [modalAddMission, setModalAddMission] = useState(false);
    const handleShowModalAddMission = () => {
        setModalAddMission(true)
    }
    const handleCloseModalAddMission = () => {
        setModalAddMission(false)
    }
    const handleAddMissionSuccess = (status_) => {
        if (status_) {
            setModalAddMission(false)
        }
    }

    // Quản lý hiển thị modal danh sách nhiệm vụ.
    const [listMission, setListMission] = useState(false);
    const handleShowListMission = () => {
        setListMission(true)
    }
    const handleCloseListMission = () => {
        setListMission(false)
    }
    return (
      <>
        <Dropdown>
          <Dropdown.Toggle
            variant="light btn-wave border-0 d-flex align-items-center fw-5" // class mặc định
            id="dropdown-basic"
            bsPrefix="custom-dropdown-toggle" // đặt tên cho class fix loại bỏ class dropdown-toggle
          >
            <MdOutlineSwitchAccessShortcutAdd className="me-1 fs-21" />
            <span>Nhiệm vụ</span>
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ width: "200px" }} align="end">
            <li
              className="dropdown-item d-flex fs-15 align-items-center cursor-pointer"
              onClick={handleShowModalAddMission}
            >
              Thêm nhiệm vụ
            </li>
            <li
              className="dropdown-item d-flex fs-15 cursor-pointer"
              onClick={handleShowListMission}
            >
              Danh sách nhiệm vụ
            </li>
          </Dropdown.Menu>
        </Dropdown>
        {/* Modal add mission */}
        {modalAddMission && (
          <ModalReact
            modalTitle="Điều phối data"
            showModal={modalAddMission}
            handleClose={handleCloseModalAddMission}
            theme={
              <FormAddMission
                socketio={socketio}
                dataUser={dataUser}
                result={handleAddMissionSuccess}
              />
            }
          />
        )}
        {/* Modal list mission */}
        {listMission && (
          <ModalReact
            modalTitle="Danh sách nhiệm vụ"
            showModal={listMission}
            handleClose={handleCloseListMission}
            theme={
              <ComponentListMission socketio={socketio} dataUser={dataUser} />
            }
          />
        )}
      </>
    )
}
export default ComponentMission


// Form add mission.
function FormAddMission({ socketio, dataUser, result, dataEdit }) {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [listUser, setListUser] = useState([])
    const checkSuccess = useRef()
    const checkEditSuccess = useRef()
    // suggest list user.
    useEffect(() => {
        const paramsUser = {
          iden: process.env.REACT_APP_IDEN_DASH,
        }
        socketio.emit("client-get-data-user-kiemduyet", paramsUser)
        socketio.on("server-get-data-user-kiemduyet", (data) => {
            setListUser([...data])
        });
        return () => {
            socketio.off("client-get-data-user-kiemduyet")
            socketio.off("server-get-data-user-kiemduyet")
        }
    }, [])

    // xử lý submit
    const handleOnSubmit = (object) => {
        if (!dataEdit) {
            object.nguoi_giao = dataUser[0].id_user
            // Xử lý thêm mới.
            const paramsCreate = {
              iden: process.env.REACT_APP_IDEN_DASH,
              object: object,
            }
            socketio.emit("client-create-datasale-mission", paramsCreate)
            checkSuccess.current = object
        } else {
            // Xử lý edit.
            object.id = dataEdit[0].id
            object.addtime = moment().format("YYYY-MM-DD HH:mm:ss")
            delete object.nguoi_nhan
            const paramsEdit = {
                iden: process.env.REACT_APP_IDEN_DASH,
                object: object
            };
            socketio.emit("client-edit-datasale-mission", paramsEdit)
            checkEditSuccess.current = object
        }
    }

    // quản lý Ẩn socket khi thêm mới thành công
    useEffect(() => {
        socketio.on("server-create-datasale-mission", (data) => {
          if (data === "success") {
              result(true)
              alert("Bạn đã thêm nhiệm vụ thành công.")
          }
        })
        return () => {
            socketio.off("client-create-datasale-mission")
            socketio.off("server-create-datasale-mission")
        }
    }, [checkSuccess.current])

    // Quản lý ẩn socket khi edit success.
    useEffect(() => {
        socketio.on("server-edit-datasale-mission", (data) => {
            if (data === "success") {
                result(true)
                alert("Bạn đã cập nhật thành công.")
            }
        })
        return () => {
            socketio.off("client-edit-datasale-mission")
            socketio.off("server-edit-datasale-mission")
        }
    }, [checkEditSuccess.current])

    if (listUser.length === 0) {
        return 
    }

    return (
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <div className="col-xl-12 col-md-12">
          <div className="row">
            <div className="form-floating mb-3 col-6">
              <input
                type="date"
                className="form-control"
                placeholder="Tên input"
                defaultValue={
                  dataEdit
                    ? moment(dataEdit[0].date_start).format("YYYY-MM-DD")
                    : ""
                }
                {...register("date_start", { required: true })}
              />
              <label className="fs-15 ms-2 text-muted">Data bắt đầu</label>
              {errors.date_start && (
                <span className="text-danger fs-12">
                  * Bạn cần chọn khoảng thời hạn bắt đầu.
                </span>
              )}
            </div>
            <div className="form-floating mb-3 col-6">
              <input
                type="date"
                className="form-control"
                placeholder="Tên input"
                defaultValue={
                  dataEdit
                    ? moment(dataEdit[0].date_end).format("YYYY-MM-DD")
                    : ""
                }
                {...register("date_end", { required: true })}
              />
              <label className="fs-15 ms-2 text-muted">Data kết thúc</label>
              {errors.date_end && (
                <span className="text-danger fs-12">
                  * Bạn cần chọn khoảng thời hạn kết thúc.
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="col-xl-12 col-md-12">
          <div className="row">
            <div className="form-floating mb-3 col-12">
              <select
                className="form-select"
                defaultValue={dataEdit ? `${dataEdit[0].nguoi_nhan}` : ""}
                {...register("nguoi_nhan", { required: true })}
              >
                <option value="">Chọn người nhận</option>
                {listUser.map((item, index) => {
                  return (
                    <option value={item.id_user} key={index}>
                      {item.hoten}
                    </option>
                  )
                })}
              </select>
              <label className="fs-15 ms-2 text-muted">
                Người nhận <span className="text-danger">*</span>
              </label>
              {errors.nguoi_nhan && (
                <span className="text-danger fs-12">
                  * Bạn cần chọn người nhận nhiệm vụ.
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="col-xl-12 col-md-12">
          <div className="row">
            <div className="form-floating mb-3 col-6">
              <input
                type="datetime-local"
                className="form-control"
                placeholder="Tên input"
                defaultValue={
                  dataEdit
                    ? moment(dataEdit[0].show_time_start).format(
                        "YYYY-MM-DDTHH:mm",
                      )
                    : ""
                }
                {...register("show_time_start", { required: true })}
              />
              <label className="fs-15 ms-2 text-muted">Bắt đầu hiển thị</label>
              {errors.show_time_start && (
                <span className="text-danger fs-12">
                  * Bạn cần chọn thời gian bắt đầu hiển thị data.
                </span>
              )}
            </div>
            <div className="form-floating mb-3 col-6">
              <input
                type="datetime-local"
                className="form-control"
                placeholder="Tên input"
                defaultValue={
                  dataEdit
                    ? moment(dataEdit[0].show_time_end).format(
                        "YYYY-MM-DDTHH:mm",
                      )
                    : ""
                }
                {...register("show_time_end", { required: true })}
              />
              <label className="fs-15 ms-2 text-muted">Kết thúc hiển thị</label>
              {errors.show_time_end && (
                <span className="text-danger fs-12">
                  * Bạn cần chọn thời gian kết thúc hiển thị data.
                </span>
              )}
            </div>
          </div>
        </div>
        {/* Button */}
        <div className="px-4 py-3 border-top border-block-start-dashed d-sm-flex justify-content-center">
          <button className="btn btn-primary-light m-1" type="submit">
            {dataEdit ? "Lưu lại" : "Thêm mới"}
          </button>
        </div>
      </form>
    )
}


// List mission.
function ComponentListMission({ socketio, dataUser }) {
    const [listMission, setListMission] = useState([])
    // Quản lý data.
    useEffect(() => {
        const params = {
            iden: process.env.REACT_APP_IDEN_DASH,
            object: {
                author: dataUser[0].id_user
            }
        }
        socketio.emit("client-get-list-datasale-mission", params)
        socketio.on("server-get-list-datasale-mission", (data) => {
            setListMission([...data])
        })
        return () => {
            socketio.off("client-get-list-datasale-mission")
            socketio.off("server-get-list-datasale-mission")
        }
    }, [])

    // Quản lý edit data.
    const [modalEdit, setModelEdit] = useState(false)
    const dataEdit = useRef([])
    const handleShowModelEditMission = (idMission) => {
        let filterData = listMission.filter((item) => {
            return item.id === idMission
        });
        setModelEdit(true)
        dataEdit.current = [...filterData]
    }
    const handleCloseModelEditMission = () => {
        setModelEdit(false);
        dataEdit.current = []
    }
    const handleEditSuccess = (status_) => {
        if (status_) {
            setModelEdit(false)
            dataEdit.current = []
        }
    }
    if (listMission.length === 0) {
        return <div className="text-center mt-3">Chưa có nhiệm vụ</div>
    }
    return (
      <div className="card-body">
        <div>
          <ul className="list-unstyled mb-0 crm-recent-activity">
            {listMission.map((item, index) => {
              return (
                <li className="crm-recent-activity-content" key={index}>
                  <div className="d-flex align-items-top">
                    <div className="me-3">
                      <span className="avatar avatar-xs bg-primary-transparent avatar-rounded">
                        <img src={"/" + item.avatar} alt="avatar" />
                      </span>
                    </div>
                    <div className="crm-timeline-content fs-15">
                      <span className="fw-semibold">{item.ten_nguoi_nhan}</span>
                      <div className="mb-0">
                        <p className="mb-0 text-muted">
                          {" "}
                          Nhận data sale trong khoảng
                        </p>
                        <p className="mb-0">
                          {moment(item.date_start).format("DD-MM-YYYY")}{" "}
                          <BsDot />
                          {moment(item.date_end).format("DD-MM-YYYY")}
                        </p>
                      </div>
                    </div>
                    <div className="flex-fill text-end">
                      <span className="d-block text-muted fs-15 op-7">
                        {moment(item.addtime).locale("vi").fromNow()}
                      </span>
                      <button
                        className="border-0 rounded-5 p-2"
                        onClick={() => handleShowModelEditMission(item.id)}
                      >
                        <LiaEdit className="fs-21" />
                      </button>
                    </div>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
        {/* Model edit */}
        {modalEdit && (
          <ModalReact
            modalTitle="Sửa nhiệm vụ"
            showModal={modalEdit}
            handleClose={handleCloseModelEditMission}
            theme={
              <FormAddMission
                socketio={socketio}
                dataUser={dataUser}
                result={handleEditSuccess}
                dataEdit={dataEdit.current}
              />
            }
          />
        )}
      </div>
    )
}