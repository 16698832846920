import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"
import { PiWarningCircleFill } from "react-icons/pi"

import "./Slider.scss"
import { ImageModal } from "./Detail-Images"
import { useState } from "react"

function SliderImage({ data }) {
  const settings = {
    dots: true,
    infinite: false,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  }
  // sắp xếp data.
  const dataSort = data.sort((a, b) => {
    return a.updatetime - b.updatetime
  })

  return (
    <div className="slider-container" style={{ height: "283px" }}>
      <Slider {...settings}>
        {dataSort.map((value, index) => (
          <div key={value.id ? value.id : index}>
            <img
              src={value.path ? value.path : value}
              className="d-block w-100"
              alt="..."
              height={283}
              style={{ borderRadius: "15px" }}
            />
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default SliderImage

// Kiểm chỉ có đường dẫn.
function SliderImageList({ data, height, web }) {
  const settings = {
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  }
  // Xử lý modal detail images.
  const [modalDetailImages, setModalDetailImages] = useState(false)
  const handleModalDetailImages = () => {
    setModalDetailImages(true)
  }
  const handleCloseModalDetailImages = ()=>setModalDetailImages(false)

  return (
    <div
      className="slider-container"
      style={{ height: height ? height + "px" : "283px" }}
    >
      <Slider {...settings}>
        {data.map((value, index) => (
          <div key={index} onMouseUp={handleModalDetailImages}>
            <LazyLoadImage
              alt="Image 1"
              effect="blur"
              src={web ? web + value : "/" + value}
              className="d-block w-100 fh"
              style={{
                borderRadius: "15px",
                maxHeight: height ? height + "px" : "",
                height: height ? height + "px" : "",
              }}
            />
          </div>
        ))}
      </Slider>
      {/* Modal */}
      {modalDetailImages && (
        <ImageModal
          data={data}
          onClose={handleCloseModalDetailImages}
          web={web}
        />
      )}
    </div>
  )
}

export { SliderImageList }


// Mẫu data cho banner slide
const dataBanner = [
  {
    id: 1,
    title: "Không gian 3D",
    info: "View360.vn",
    info1: "Tài trợ",
    class: "bg-card",
    image: "path",
    width: 250,
    path: ""
  },
]
// Slide banner.
function SlideBanner({ data }) {
  const settings = {
    className: "slider variable-width",
    dots: false,
    infinite: true,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    nextArrow: <></>,
    prevArrow: <></>,
  }
  return (
    <div className="slider-container">
      <Slider {...settings}>
        {data.map((item) => {
          return (
            <a href={item.path} key={item.id} className="me-3">
              <div
                className={"box-gift-card " + item.class}
                style={{ width: item.width }}
              >
                <ul className="itemSlide">
                  <li>{item.title}</li>
                  <li className="text-success fw-6">{item.info}</li>
                  <li className="fs-11 text-muted"><PiWarningCircleFill className="me-1" />{item.info1}</li>
                </ul>

                <div className="img-gift">
                  <img src={item.image} alt="" width="100%" height="100%"/>
                </div>
              </div>
            </a>
          )
        })}
      </Slider>
    </div>
  )
}
export { SlideBanner }





// Custom next arrow component
const CustomNextArrow = (props) => {
  const { onClick } = props
  return (
    <div
      className="slick-next"
      style={{ right: "10px", zIndex: 1 }}
      onClick={onClick}
    >
      Next
    </div>
  )
}

// Custom previous arrow component
const CustomPrevArrow = (props) => {
  const { onClick } = props
  return (
    <div
      className="slick-prev"
      style={{ left: "10px", zIndex: 1 }}
      onClick={onClick}
    >
      Prev
    </div>
  )
}
