import { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import InputColor from "../../../../Components/FromColor/InputColor"

function FormAddCateDongtien({ socketio, dataUser, result }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  // Set mầu.
  const [color, setColor] = useState("")
  const handleInputColor = (color_) => {
    setColor(color_)
  }

  const changeCreate = useRef()
  const handleOnSubmit = (object) => {
    object.user_id = dataUser[0].id_user
    object.color = color
    const paramsCreate = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: object,
    }
    socketio.emit(
      "client-create-data-taichinhcanhan-cate-dongtien",
      paramsCreate,
    )
    socketio.on("server-create-data-taichinhcanhan-cate-dongtien", (data) => {
      result(data)
      changeCreate.current = object
    })
  }
  useEffect(() => {
    return () => {
      socketio.off("client-create-data-taichinhcanhan-cate-dongtien")
      socketio.off("server-create-data-taichinhcanhan-cate-dongtien")
    }
  }, [changeCreate.current])
  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <div className="col-xl-12 col-md-12">
        <div className="row">
          <div className="form-floating mb-3 col-10">
            <input
              type="text"
              className="form-control rounded-3"
              placeholder="Tên input"
              {...register("title", { required: true })}
              style={{ color: color, fontSize: "18px" }}
            />
            <label className="fs-15 ms-2 text-muted">Tên danh mục</label>
            {errors.title && (
              <span className="text-danger fs-12">
                *Bạn cần nhập tên danh mục
              </span>
            )}
          </div>
          <div className="mb-3 col-2 d-flex align-items-center flex-direction-column p-0">
            <InputColor result={handleInputColor} />
            <span className="fs-12">Chọn mầu</span>
          </div>
        </div>
      </div>

      {/* Button */}
      <div className="px-4 py-3 border-top border-block-start-dashed d-flex justify-content-center">
        <button className="btn btn-primary-light m-1" type="submit">
          Thêm mới
        </button>
      </div>
    </form>
  )
}
export default FormAddCateDongtien

// Form edit.
export function FormEditCateDongtien({ socketio, dataUser, dataEdit, result }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  // Set mầu.
  const [color, setColor] = useState(dataEdit.color)
  const handleInputColor = (color_) => {
    setColor(color_)
  }

  const changeEdit = useRef()
  const handleOnSubmit = (object) => {
    object.id = dataEdit.id
    object.user_id = dataUser[0].id_user
    object.color = color
    const paramsEdit = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: object,
    }
    socketio.emit("client-edit-data-taichinhcanhan-cate-dongtien", paramsEdit)
    socketio.on("server-edit-data-taichinhcanhan-cate-dongtien", (data) => {
      result({
        status: true,
        dataUpdate: data,
      })
      changeEdit.current = object
    })
  }
  useEffect(() => {
    return () => {
      socketio.off("client-edit-data-taichinhcanhan-cate-dongtien")
      socketio.off("server-edit-data-taichinhcanhan-cate-dongtien")
    }
  }, [changeEdit.current])
  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <div className="col-xl-12 col-md-12">
        <div className="row">
          <div className="form-floating mb-3 col-10">
            <input
              type="text"
              className="form-control rounded-3"
              placeholder="Tên input"
              {...register("title", { required: true })}
              defaultValue={dataEdit.title}
              style={{
                color: color ? color : dataEdit.color,
                fontSize: "18px",
              }}
            />
            <label className="fs-15 ms-2 text-muted">Tên danh mục</label>
            {errors.title && (
              <span className="text-danger fs-12">
                *Bạn cần nhập tên danh mục
              </span>
            )}
          </div>
          <div className="mb-3 col-2 d-flex align-items-center flex-direction-column p-0">
            <InputColor
              result={handleInputColor}
              colorDefault={dataEdit.color}
            />
            <span className="fs-12">Chọn mầu</span>
          </div>
        </div>
      </div>

      {/* Button */}
      <div className="px-4 py-3 border-top border-block-start-dashed d-flex justify-content-center">
        <button className="btn btn-primary-light m-1" type="submit">
          Lưu lại
        </button>
      </div>
    </form>
  )
}
