import SearchHorizontal from "../../../../Components/Search/SearchHorizontal"

function ComponentTopUser({ result }) {
    const handleResultSearchUser = (valueText) => {
        result(valueText)
    }
    return (
        <div className="d-flex justify-content-between align-items-center mt-4 mb-4">
            <h6>Danh sách user</h6>
            <div className="d-flex">
                <div>
                    <SearchHorizontal
                        className="rounded-3"
                        style={{ border: "1px solid #485056" }}
                        result={handleResultSearchUser}
                    />
                </div>
            </div>
        </div>
    )
}

export default ComponentTopUser