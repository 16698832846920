import { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { FaFaceFrownOpen } from "react-icons/fa6";
import { IoMdCheckmarkCircle } from "react-icons/io";

function FormDonXinNghiviec({ socketio, dataUser }) {
  const { register, handleSubmit, formState: { errors }, reset } = useForm()
  const checkChage = useRef();
  const [alertSuccess, setAlertSuccess] = useState(false)
  const [dateEnd, setDateEnd] = useState("");
  const daysNumber = useRef(0);
  const [checkDataNghiviec, setCheckDataNghiviec] = useState(false)
    
  // Quản lý check xem user đã gửi đơn hay chưa.
  useEffect(() => {
    const paramsCheck = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {user_id: dataUser[0].id_user}
    }
    socketio.emit("client-check-data-donnghiviec", paramsCheck)
    socketio.on("server-check-data-donnghiviec", (dataCheck) => {
      if (dataCheck.length > 0) {
        setCheckDataNghiviec(true)
      }
    })
  }, [])

    const handleGetDateEnd = (e) => {
        setDateEnd(e.target.value);
        // set thời gian báo trước.
        let thoigiannghi = new Date(e.target.value).getTime()
        let currentTime = new Date().getTime()
        let days_ = ((thoigiannghi - currentTime) / (24 * 60 * 60 * 1000)).toFixed();
        daysNumber.current = days_
    }

    const onSubmit = (object) => {
        object.user_id = dataUser[0].id_user;
        // set thời gian báo trước.
        let thoigiannghi = new Date(object.date_end).getTime()
        let currentTime = new Date().getTime()
        let days_ = ((thoigiannghi - currentTime) / (24 * 60 * 60 * 1000)).toFixed()
        object.days = Number(days_)
        // Xử lý thêm mới.
        const params = {
            iden: process.env.REACT_APP_IDEN_DASH,
            object: object
        }
        socketio.emit("client-create-data-donnghiviec", params)
        socketio.on("server-create-data-donnghiviec", (data) => {
            if (data === "success") {
                checkChage.current = object
                setAlertSuccess(true)
                setDateEnd("")
                reset()
            }
        })
    }
    useEffect(() => {
        return () => {
            socketio.off("client-create-data-donnghiviec")
            socketio.off("server-create-data-donnghiviec")
        }
    }, [checkChage.current])
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="col-xl-12 col-md-12">
          <div className="row">
            <div className="form-floating mb-3 col-12">
              <input
                type="text"
                className="form-control"
                placeholder="Tên input"
                {...register("title", { required: true })}
              />
              <label className="fs-15 ms-2 text-muted">Tiêu đề</label>
              {errors.title && (
                <span className="text-danger fs-14">
                  *Bạn cần nhập tiêu đề.
                </span>
              )}
            </div>
            <div className="form-floating mb-4 mt-3">
              <textarea
                className="form-control"
                placeholder="Nhập ghi chú"
                style={{ height: "100px" }}
                {...register("content", { required: true })}
              ></textarea>
              <label className="fs-15 ms-2 text-muted">Nội dung</label>
              {errors.content && (
                <span className="text-danger fs-14">
                  *Bạn cần nhập nội dung nghỉ.
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="col-xl-12 col-md-12">
          <div className="row">
            <div className="form-floating mb-3 col-12">
              <input
                type="datetime-local"
                className="form-control"
                placeholder="Tên input"
                {...register("date_end", { required: true })}
                value={dateEnd}
                onChange={handleGetDateEnd}
              />
              <label className="fs-15 ms-2 text-muted">Ngày bắt đầu nghỉ</label>
              {errors.date_end && (
                <span className="text-danger fs-14">
                  *Bạn cần chọn thời hạn bắt đầu nghỉ việc.
                </span>
              )}
            </div>
          </div>
        </div>
        {!alertSuccess && daysNumber.current > 0 && (
          <>
            <div className="alert alert-primary fs-15">
              <FaFaceFrownOpen className="me-1 fs-21" />
              Thời gian báo trước của bạn đang là{" "}
              <span className="fw-5 fs-18">{daysNumber.current}</span> ngày
            </div>
            <div className="alert alert-danger">
              <span className="fw-5">Lưu ý: </span>
              <span className="fs-15">
                Nhân viên báo nghỉ cần báo trước ít nhất{" "}
                <span className="fs-18">15</span> ngày, để có thể tất toán
                lương.
              </span>
            </div>
          </>
        )}
        {alertSuccess && (
          <div
            className="alert alert-success fs-15 d-flex flex-direction-column align-items-center"
            role="alert"
          >
            <IoMdCheckmarkCircle style={{ fontSize: "51px" }} />
            <div className="fw-5" style={{ textTransform: "uppercase" }}>
              Đơn đã được gửi
            </div>
            <p className="mb-0">Quản lý sẽ xem xét và phản hồi bạn sớm nhất.</p>
          </div>
        )}
        {!checkDataNghiviec ? (
          <div className="px-4 py-3 border-top border-block-start-dashed d-flex justify-content-center">
            <button className="btn btn-primary-light m-1" type="submit">
              Nộp đơn xin nghỉ việc
            </button>
          </div>
        ) : (
          <div className="text-center fs-14 py-3 border-top border-block-start-dashed">
            <span className="alert alert-primary">
              Đơn thôi việc của bạn đã tồn tại không thể gửi tiếp.
            </span>
          </div>
        )}
      </form>
    )
}
export default FormDonXinNghiviec