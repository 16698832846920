import { useState, useEffect, useContext } from "react"
import Tippy from "@tippyjs/react/headless"
import { IoNotificationsOutline } from "react-icons/io5"
import { BsDot } from "react-icons/bs"

import { images } from "../../assets/images";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import AppContext from "../../handle-reuses/Contexts/CreateContexts";

function Notification({sizeIcon, style}) {
  const { socketioBDS, socketio, dataUser } = useContext(AppContext);
  const [dataPayHis, setDataPayHis] = useState([])
  const [dataSupport, setDataSupport] = useState([])
  const [dataExtendContract, setDataExtendContract] = useState([])
  // Get thông báo.
  useEffect(() => {
    const params = {
      iden: process.env.REACT_APP_IDEN_BDSVIEW,
    }
    // thanh toán ban đầu.
    socketioBDS.emit("client-notifi-list-pay-his", params)
    // List support ban đầu
    socketioBDS.emit("client-list-support-notifi", params)

    // List extend contract.
    const paramsDash = {
      iden: process.env.REACT_APP_IDEN_DASH,
      userid: dataUser[0].id_user
    };
    socketio.emit("client-notification-contract-view360", paramsDash)

    return () => {
      socketioBDS.off("client-notifi-list-pay-his")
      socketioBDS.off("client-list-support-notifi")
      socketio.off("client-notification-contract-view360")
    }
  }, [])


  // Nhận thông báo.
  useEffect(() => {
    // Nhận thông báo thanh toán.
    socketioBDS.on("server-notifi-pay-his", (data) => {
      setDataPayHis(data)
    })
    // Nhận thông báo hỗ trợ.
    socketioBDS.on("server-send-support-notifi", (data) => {
      setDataSupport(data)
    })
    // Nhận list extend contract.
    socketio.on("server-notification-contract-view360", (data) => {
      setDataExtendContract(data);
    })

    return () => {
      socketioBDS.off("server-notifi-pay-his")
      socketioBDS.off("server-send-support-notifi")
    }
  }, [])


  return (
    <>
      <Tippy
        interactive
        delay={[0, 0]}
        placement="bottom-start"
        trigger="click"
        render={() => (
          <NotificationList
            dataPayHis={dataPayHis}
            dataSupport={dataSupport}
            dataContract={dataExtendContract}
          />
        )}
      >
        <button
          className="position-relative border-0 bg-transparent"
          style={style}
        >
          <IoNotificationsOutline className={sizeIcon ? sizeIcon : ""} style={style}/>
          {Number(dataPayHis.length) + Number(dataSupport.length) > 0 && (
            <span
              className="badge bg-danger rounded-pill header-icon-badge pulse pulse-danger"
              style={{ right: "3px", top: "0" }}
            >
              {Number(dataPayHis.length) +
                Number(dataSupport.length) +
                Number(dataExtendContract.length)}
            </span>
          )}
        </button>
      </Tippy>
    </>
  )
}
export default Notification

// Danh sách thông báo.
function NotificationList({ dataPayHis, dataSupport, dataContract }) {
  
  return (
    <div className="bg-tippy-a" style={{width: "360px", height: "calc(100vh - 6rem)", overflow: "auto"}}>
      <div className="p-3">
        <div className="d-flex align-items-center justify-content-between">
          <p className="mb-0 fs-17 fw-semibold">Thông báo</p>
        </div>
      </div>
      <div className="dropdown-divider"></div>
      <ul className="list-unstyled mb-0" id="header-notification-scroll">
        {/* list pay his */}
        {dataPayHis.length > 0 &&
          dataPayHis.map((item, index) => {
            return (
              <li className="dropdown-item item-notifi" key={"pay" + index}>
                <div className="d-flex align-items-start">
                  <div className="pe-2">
                    <span className="avatar avatar-md bg-primary-transparent avatar-rounded">
                      <img
                        src={
                          item.avatar
                            ? "https://batdongsan.view360.vn/" + item.avatar
                            : images.user
                        }
                        alt=""
                      />
                    </span>
                  </div>
                  <div className="flex-grow-1 d-flex align-items-center justify-content-between gap-3">
                    <Link to="/bdsview360/thanh-toan">
                      <p className="mb-0 fw-semibold">{item.hoten}</p>
                      <span className="op-9 fw-normal fs-15 header-notification-text">
                        {item.title} +
                        {Number(item.deposit)
                          .toLocaleString()
                          .replace(/[.]/g, ",")}
                        đ{/* <BsDot /> */}
                        <p className="text-info mb-0">{moment(item.addtime).locale("vi").fromNow()}</p>
                      </span>
                    </Link>
                    {/* <div>
                      <button className="border-0 bg-transparent">
                        <IoIosClose className="fs-25" />
                      </button>
                    </div> */}
                  </div>
                </div>
              </li>
            )
          })}
        
        {/* List notifi support */}
        {dataSupport.length > 0 && (
          dataSupport.map((item, index) => {
            return (
              <li className="dropdown-item item-notifi" key={"sup"+index}>
                <div className="d-flex align-items-start">
                  <div className="pe-2">
                    <span className="avatar avatar-md bg-primary-transparent avatar-rounded">
                      <img src={images.notifi} alt="" />
                    </span>
                  </div>
                  <div className="flex-grow-1 d-flex align-items-center justify-content-between gap-3">
                    <Link to="/bdsview360/ho-tro">
                      <p className="mb-0 fw-semibold">
                        {item.fullname}
                        <BsDot />
                        <span className="fs-14 text-info fw-4"> To Support</span>
                      </p>
                      <span className="text-muted fw-normal fs-15 header-notification-text">
                        {item.title}
                        <BsDot />
                        {moment(item.addtime).locale("vi").fromNow()}
                      </span>
                    </Link>
                  </div>
                </div>
              </li>
            )
          })
        )}

        {/* List extend contract */}
        {dataContract.length > 0 && (
          dataContract.map((item, index) => {
            return (
              <li className="dropdown-item item-notifi" key={"contract" + index}>
                <div className="d-flex align-items-start">
                  <div className="pe-2">
                    <span className="avatar avatar-md bg-primary-transparent avatar-rounded">
                      <img src={images.contract} alt="" />
                    </span>
                  </div>
                  <div className="flex-grow-1 d-flex align-items-center justify-content-between gap-3">
                    <Link to="/view360">
                      <p className="mb-0 fw-semibold">
                        {item.hoten}
                        <BsDot />
                        <span className="fs-14 text-danger fw-4 op-6">
                          Hết hạn view360.vn
                        </span>
                      </p>
                      <p className="text-muted mb-0">{item.email}</p>
                      <span className="text-muted fw-normal fs-15 header-notification-text">
                        {item.sodienthoai}
                        <BsDot />
                        {moment(item.ngayketthuc).locale("vi").fromNow()}
                      </span>
                    </Link>
                  </div>
                </div>
              </li>
            )
          })
        )}
      </ul>
    </div>
  )
}
