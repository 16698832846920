import io from "socket.io-client";
import { useRef, useEffect, useState } from "react";
import classNames from "classnames/bind";
import styles from "../Dashboard.module.scss"
import CheckPCMobile from "../../../handle-reuses/reuses/check.pc.mobile"
import PageSupportPC from "./PC";
import PageSupportMobile from "./Mobile";
import SupportTop from "./Component/SupportTop";

const cx = classNames.bind(styles);

function HoTro({active}) {
    const socket = useRef();
    const [loading, setLoading] = useState(true)

    // Khởi tạo socket.
    useEffect(() => {
        socket.current = io(process.env.REACT_APP_DOMAIN_BDSVIEW360)
        if (socket.current) {
            setLoading(false)
        }
        return () => {
            socket.current.disconnect()
        }
    }, [])
    
    if (loading) {
        return <div className="text-center mt-5">Đang tải...</div>
    }

    return (
      <div
        className="main-content app-content"
        style={{ marginInlineStart: active && "6rem" }}
      >
        <div className="mt-3 mb-3">
          <SupportTop />
        </div>
        <div className="container-fluid border rounded-3">
          <CheckPCMobile>
            {(isMobile) =>
              isMobile ? (
                <PageSupportMobile socketio={socket.current} />
              ) : (
                <PageSupportPC socketio={socket.current} />
              )
            }
          </CheckPCMobile>
        </div>
      </div>
    )
}

export default HoTro