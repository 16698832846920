import { useContext, useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom";
import AvatarUser from "../../../Components/Info/AvatarUser"
import { Notification } from "../../../Components/Notification"
import AppContext from "../../../handle-reuses/Contexts/CreateContexts"
import ComponentMission from "../Component/ComponentMission";
import { IoChevronBackOutline, IoReload} from "react-icons/io5";
import ComponentAddTuyenduong from "../Component/ComponentAddTuyenduong";
import Tippy from "@tippyjs/react/headless";
import { LuPieChart } from "react-icons/lu";
import { NotifiTimekeeing } from "../../../Components/Notification/NotificationSystem";
import { VscCompassActive } from "react-icons/vsc";
import ModalReact from "../../../Components/Modal/ModalReact";
import { HandleResult } from "../../../Components/Phongthuy/Phongthuy";
import { TfiReload } from "react-icons/tfi";
import { LoadingSpinner } from "../../../Components/Loading";
import { BsDot } from "react-icons/bs";
import moment from "moment";
import { useForm } from "react-hook-form";

function HeaderDataSalePC() {
  const { socketio, dataUser } = useContext(AppContext);
  const [totalPro, setTotalPro] = useState(0)
  const [dataCount, setDataCount] = useState([])
  // Quản lsy get data.
  useEffect(() => {
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
    }
    socketio.emit("client-count-loaibds-sanpham", params)
    socketio.on("server-count-loaibds-sanpham", (data) => {
      let filter = data.map((item) => {
        return item.quantity
      });
      let totalPro_ = filter.reduce((a, b) => {
        return a + b
      });
      setTotalPro(totalPro_)
      setDataCount([...data])
    });
    return () => {
      socketio.off("client-count-loaibds-sanpham")
      socketio.off("server-count-loaibds-sanpham")
    }
  }, [])

  // Show modal phong thuy.
  const [modalPhongthuy, setModalPhongthuy] = useState(false)
  const handleShowModalPhongthuy = () => setModalPhongthuy(true)
  const handleCloseModalPhongthuy = () => setModalPhongthuy(false)




    return (
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ height: "56px" }}
      >
        {/* Left header */}
        <div className="d-flex gap-3">
          <div>
            <Link to="/" className="d-flex align-items-center fw-5">
              <IoChevronBackOutline className="me-1" />
              Home
            </Link>
          </div>
          <div>
            Tổng <span>{totalPro.toLocaleString().replace(/[.]/g, ",")}</span>
          </div>
          <Tippy
            interactive
            delay={[0, 0]}
            render={() =>
              totalPro && (
                <ListCateCount dataCount={dataCount} total={totalPro} />
              )
            }
          >
            <button
              className="border-0 bg-transparent d-flex align-items-center gap-1"
              style={{ color: "#485056" }}
            >
              <LuPieChart />
              Thống kê
            </button>
          </Tippy>
        </div>
        {/* right header */}
        <div className="d-flex justify-content-end col-8 align-items-center gap-2">
          {/* Nhắc lịch kích tin những khách đã quảng cáo và còn hạn*/}
          <NotificationKichtin socketio={socketio} dataUser={dataUser} />
          {/* Xem hướng nhà theo năm sinh */}
          <button
            className="btn btn-light btn-wave fw-5 border-0 d-flex align-items-center gap-1"
            onClick={handleShowModalPhongthuy}
          >
            <VscCompassActive className="fs-18" />
            Phong thủy
          </button>
          {/* Thêm nhiệm vụ */}
          {dataUser[0].goidichvu_user === 1 && <ComponentMission />}
          {/* Thêm tuyến đường */}
          <ComponentAddTuyenduong />
          {/* Notification */}
          <Notification style={{ fontSize: "26px" }} />
          {/* info user */}
          <AvatarUser />
        </div>

        {/* notifi Chấm công khi chưa chấm công hôm nay */}
        <NotifiTimekeeing />

        {/* Modal phong thủy */}
        {modalPhongthuy && (
          <ModalReact
            modalTitle="Xem hướng theo năm sinh"
            showModal={modalPhongthuy}
            handleClose={handleCloseModalPhongthuy}
            theme={<HandleResult />}
          />
        )}

        
      </div>
    )
}
export default HeaderDataSalePC


// Thông kê danh mục.
function ListCateCount({ dataCount, total }) {
  const styles = {
    backgroundColor: "white",
    boxShadow: "3px 7px 6px -5px #c9c4c4",
    borderBottomLeftRadius: "12px",
    borderBottomRightRadius: "12px",
    padding: "3px 12px"
  }
  return (
    <div style={styles}>
      {dataCount.map((item, index) => {
        let cate = item.loaibds
        return (
          <div key={index} className="mt-2">
            <div className="d-flex justify-content-between align-items-center gap-3">
              <span>{cate}</span>
              <span>
                {item.loaibds &&
                  item.quantity.toLocaleString().replace(/[.]/g, ",")}
                {item.loaibds && (
                  <span className="text-primary ms-2">
                    <span className="me-2">~</span>
                    {((Number(item.quantity) / Number(total)) * 100).toFixed(2)}
                    %
                  </span>
                )}
              </span>
            </div>
          </div>
        )
      })}
    </div>
  )
}



// Thong bao danh sach kich tin.
function NotificationKichtin({ socketio, dataUser }) {
  const [dataKichtin, setDataKichtin] = useState([])
  const [updateData, setUpdateData] = useState(false)
  useEffect(() => {
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
    }
    socketio.emit("client-get-kichtin-bds-contract", params)
    socketio.on("server-get-kichtin-bds-contract", (data) => {
      setDataKichtin([...data])
    })
    return () => {
      socketio.off("client-get-kichtin-bds-contract")
      socketio.off("server-get-kichtin-bds-contract")
    }
  }, [updateData])

  // Danh sách cần kích tin.
  const [listKichtin, setListKichtin] = useState(false)
  const handleListKichtinContract = () => {
    setListKichtin(true)
  }
  const handleCloseListKichtinContract = () => setListKichtin(false)
  const handleUpdateDataEditSuccess = (status_) => {
    if (status_) {
      setUpdateData(true)
    }
  }

  return (
    <>
      <button
        className="btn btn-light btn-wave fw-5 border-0 d-flex align-items-center gap-1 position-relative"
        onClick={handleListKichtinContract}
      >
        <IoReload className="fs-15" />
        Lịch kích tin{" "}
        <span
          className="position-absolute badge bg-danger rounded-5"
          style={{ top: "-5px", right: 0 }}
        >
          {dataKichtin.length}
        </span>
      </button>

      {/* Modal list kichs tin */}
      {listKichtin && (
        <ModalReact
          modalTitle="Danh sách cần kích tin, làm mới"
          showModal={listKichtin}
          handleClose={handleCloseListKichtinContract}
          theme={
            <ListKichtinContract
              socketio={socketio}
              dataUser={dataUser}
              dataKichtin={dataKichtin}
              result={handleUpdateDataEditSuccess}
            />
          }
        />
      )}
    </>
  )
}

function ListKichtinContract({socketio, dataUser, dataKichtin, result}) {


  // Quản lý edit lịch nhắc.
  const [modalEditLichNhac, setModalEditLichNhac] = useState(false);
  const dataEditLichNhac = useRef()
  const handleEditLichNhac = (object) => {
    setModalEditLichNhac(true)
    dataEditLichNhac.current = object
  }
  const handleCloseEditLichNhac = () => {
    setModalEditLichNhac(false);
    dataEditLichNhac.current = undefined
  }
  const handleEditLichNhacSuccess = (status_) => {
    if (status_) {
      setModalEditLichNhac(false)
      result(true)
    }
  }


  if (dataKichtin.length === 0) {
    return <div className="text-center mt-3 fs-16">Hôm nay không có khách nào cần kích tin.</div>
  }

  return (
    <div>
      {dataKichtin.map((item, index) => {
        return (
          <div key={index} className="mb-2 border-bottom py-3">
            <h6 className="mb-1">{item.sonha + " " + item.tuyenduong}</h6>
            <div>
              <p className="mb-0 text-muted">
                {item.chusohuu} <BsDot /> {item.sodienthoai}
                <BsDot />
                {moment(item.date_start).format("DD/MM/YYYY")}
              </p>
              <p className="mb-0 text-muted">
                Thời gian nhắc: {item.ngay_kichtin} ngày 1 lần
                <BsDot />
                {item.loaitin}
                <BsDot />
                <button
                  className="badge bg-primary-transparent border-0"
                  onClick={() => handleEditLichNhac(item)}
                >
                  Sửa lịch nhắc
                </button>
              </p>
            </div>
          </div>
        )
      })}
      {/* Modal edit lịch nhắc */}
      {modalEditLichNhac && (
        <ModalReact
          modalTitle="Sửa số ngày cần nhắc làm mới tin"
          showModal={modalEditLichNhac}
          handleClose={handleCloseEditLichNhac}
          theme={<FormEditLichnhac dataEdit={dataEditLichNhac.current} socketio={socketio} dataUser={dataUser} result={handleEditLichNhacSuccess}/>}
        />
      )}
    </div>
  )
}

// Form edit lịch nhắc.
function FormEditLichnhac({ dataEdit, socketio, dataUser, result }) {
  const { register, handleSubmit, formState: { errors } } = useForm()
  // Submit.
  const changeEdit = useRef()
  const handleOnSubmit = (object) => {
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        id_hopdong: dataEdit.id_hopdong,
        ngay_kichtin: object.ngay_kichtin
      }
    }
    if (dataUser[0].bao_cao === 1) {
      socketio.emit("client-edit-row-ngaykichtin-bds-contract", params)
      socketio.on("server-edit-row-ngaykichtin-bds-contract", (data) => {
        if (data === "success") {
          alert("Cập nhật lại thời gian nhắc thành công.")
          result(true)
          changeEdit.current = object
        }
      })
    } else {
      alert("Bạn không có quyền thay đổi.")
    }
  }
  useEffect(() => {
    return () => {
      socketio.off("client-edit-row-ngaykichtin-bds-contract")
      socketio.off("server-edit-row-ngaykichtin-bds-contract")
    }
  }, [changeEdit.current])

  
  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <div className="text-center mb-3">{dataEdit.sonha + " " + dataEdit.tuyenduong}</div>
      <div className="form-floating mb-3 col-12">
        <input
          type="number"
          className="form-control"
          placeholder="Tên input"
          {...register("ngay_kichtin")}
          defaultValue={dataEdit.ngay_kichtin}
        />
        <label className="fs-15 text-muted">
          Số ngày nhắc kich tin <span className="text-danger">*</span>
        </label>
        {errors.ngay_kichtin && (
          <span className="text-danger fs-14">
            Bạn cần nhập số ngay cần kích tin.
          </span>
        )}
      </div>

      {/* Button */}
      <div className="px-4 py-3 border-top border-block-start-dashed d-flex justify-content-center">
        <button className="btn btn-primary-light m-1" type="submit">
          Lưu lại
        </button>
      </div>
    </form>
  )
}