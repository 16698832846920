import { useContext, useEffect, useRef, useState } from "react"
import classNames from "classnames/bind";
import DateDay from "../../../Components/Date/DateDay"
import SearchHorizontal from "../../../Components/Search/SearchHorizontal"
import CheckPCMobile from "../../../handle-reuses/reuses/check.pc.mobile"
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import AppContext from "../../../handle-reuses/Contexts/CreateContexts";
import styles from "../DataSale.module.scss"
import { FiSearch } from "react-icons/fi";
import { MdCancel } from "react-icons/md";
import moment from "moment";
const cx = classNames.bind(styles)

function SearchDataSale({ result, resultData}) {
  const { dataUser, socketio } = useContext(AppContext)
  const path = useLocation()
  const currentPath = path.pathname
  const [dataFilterDetail, setDataFilterDetail] = useState([])
  const checkFilterDetail = useRef();
  const [checkLengthFilter, setCheckLengthFilter] = useState(false)
  const dataFilterResult = useRef([])
  // filter detail (bộ lọc).
  const handleFilterDetail = (objectFilter) => {
    // Xử lý tìm kiếm bộ lọc
    checkFilterDetail.current = objectFilter
    // setObjectFilter(objectFilter)
    if (objectFilter.note === "note") {
      const paramsNote = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: {
          goidichvu: dataUser[0].goidichvu_user,
          author: dataUser[0].id_user,
          note: objectFilter.text,
        },
      }
      // Chỉ tìm trong ghi chú
      if (currentPath === "/data-sale") {
        socketio.emit("client-filter-note-datasale-sanpham", paramsNote)
        // Tìm theo ghi chú
        socketio.on("server-filter-note-datasale-sanpham", (dataFilter) => {
          let dataFilterNote = []
          if (dataFilter.length > 0) {
            let i = 0
            while (i < dataFilter.length) {
              const paramsDataSanpham = {
                iden: process.env.REACT_APP_IDEN_DASH,
                object: {
                  id_sp: dataFilter[i].kn_sanpham,
                },
              }
              socketio.emit(
                "client-filter-data-sale-idsp-sanpham",
                paramsDataSanpham,
              )
              i++
            }
            
            // Nhận dữ liệu.
            socketio.on("server-filter-data-sale-idsp-sanpham-pc", (data) => {
              if (data.length > 0) {
                let filterNote = dataFilterNote.filter((item) => {
                  return item.id_sp === data[0].id_sp
                })
                if (filterNote.length === 0) {
                  dataFilterNote.push(data[0])
                }

                // Lọc theo các giá trị khác của sản phẩm.
                let filterDataNote = []
                if (
                  checkFilterDetail.current.dateStart &&
                  checkFilterDetail.current.dateEnd
                ) {
                  filterDataNote = dataFilterNote.filter((item) => {
                    let dateStart = new Date(
                      checkFilterDetail.current.dateStart,
                    ).getTime()
                    let dateEnd = new Date(
                      checkFilterDetail.current.dateEnd,
                    ).getTime()
                    let addtime = new Date(item.ngaycapnhat).getTime()
                    return addtime >= dateStart && addtime <= dateEnd
                  })
                  setDataFilterDetail([...filterDataNote])
                } else if (
                  checkFilterDetail.current.dateStart &&
                  !checkFilterDetail.current.dateEnd
                ) {
                  filterDataNote = dataFilterNote.filter((item) => {
                    let dateStart = new Date(
                      checkFilterDetail.current.dateStart,
                    ).getTime()
                    let addtime = new Date(item.ngaycapnhat).getTime()
                    return addtime >= dateStart
                  })
                  // Kiểm tra có tồn tại kết quả không
                  setDataFilterDetail([...filterDataNote])
                } else if (
                  !checkFilterDetail.current.dateStart &&
                  checkFilterDetail.current.dateEnd
                ) {
                  filterDataNote = dataFilterNote.filter((item) => {
                    let dateEnd = new Date(
                      checkFilterDetail.current.dateEnd,
                    ).getTime()
                    let addtime = new Date(item.ngaycapnhat).getTime()
                    return addtime <= dateEnd
                  })
                  // Kiểm tra có tồn tại kết quả không
                  setDataFilterDetail([...filterDataNote])
                } else if (
                  !checkFilterDetail.current.dateStart &&
                  !checkFilterDetail.current.dateEnd
                ) {
                  setDataFilterDetail([...dataFilterNote])
                }
              }
            });
            // Điều kiện hiển thị cuối cùng
            
          } else {
            alert("Không có kết quả phù hợp")
          }

          
        })
      }
    } else if (objectFilter.note === "data-sale") {
      // Chỉ tìm trong data sale
      const paramsDataSale = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: {
          goidichvu: dataUser[0].goidichvu_user,
          author: dataUser[0].id_user,
          dateStart: objectFilter.dateStart,
          dateEnd: objectFilter.dateEnd,
          keywords: objectFilter.text,
        },
      }
      socketio.emit("client-filter-data-sale-detail-sanpham", paramsDataSale)
      // Tìm theo data sale
      socketio.on("server-filter-data-sale-detail-sanpham", (data) => {
        if (data.length > 0) {
          setDataFilterDetail([...data])
        } else {
          alert("Không có kết quả phù hợp.")
        }
      })
    }
  }


  // Trả về data cho người dùng.
  useEffect(() => {
    if (dataFilterDetail.length > 0) {
      resultData({
        text: checkFilterDetail.current.text,
        data: [...dataFilterDetail],
      })
    }
    return () => {
      socketio.off("client-filter-note-datasale-sanpham")
      socketio.off("server-filter-note-datasale-sanpham")

      socketio.off("client-filter-data-sale-idsp-sanpham")
      socketio.off("server-filter-data-sale-idsp-sanpham")

      socketio.off("client-filter-data-sale-detail-sanpham")
      socketio.off("server-filter-data-sale-detail-sanpham")
    }
  }, [dataFilterDetail])

  return (
    <div className="col-12">
      <CheckPCMobile>
        {(isMobile) =>
          isMobile ? (
            <ComponentSearchMobile resultFilter={result} />
          ) : (
            <ComponentSearchPC resultFilter={handleFilterDetail} />
          )
        }
      </CheckPCMobile>
    </div>
  )
}

export default SearchDataSale


// Component search pc.
function ComponentSearchPC({ resultFilter }) {
  const {register, handleSubmit, formState: {errors}} = useForm()
  const path = useLocation()
  const currentPath = path.pathname;

  const [objectFilter, setObjectFilter] = useState({
    dateStart: "",
    dateEnd: "",
    text: "",
  })
  const resultDateStart = (e) => {
    setObjectFilter({
      dateStart: e.target.value,
      dateEnd: objectFilter.dateEnd,
      text: objectFilter.text,
    })
  }
  const resultDateEnd = (e) => {
    setObjectFilter({
      dateStart: objectFilter.dateStart,
      dateEnd: e.target.value,
      text: objectFilter.text,
    })
  }
  const [valueText, setValueText] = useState("")
  const handleClearInputText = () => {
    setValueText("")
  }
  const handleGetValueText = (e) => {
    setValueText(e.target.value)
    setObjectFilter({
      dateStart: objectFilter.dateStart,
      dateEnd: objectFilter.dateEnd,
      text: e.target.value,
    })
  }


  const handleOnSubmit = (data) => {
    data.dateStart = moment(objectFilter.dateStart).format("YYYY-MM-DD")
    data.dateEnd = moment(objectFilter.dateEnd).format("YYYY-MM-DD")
    data.text = objectFilter.text
    if (currentPath === "/data-sale") {
      resultFilter(data)
    } else {
      alert("Bộ lọc chỉ dành cho mục tất cả.")
    }
  }

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <div className="col-12 d-flex">
        <div className="col-12">
          <div className="row">
            <div className="col-2 col-xl-6 col-md-12">
              <label className="form-label">
                Chọn ngày bắt đầu <span className="text-danger">*</span>
              </label>
              <div className={cx("Search_Wrapper_horizontal", "rounded-3")}>
                <input
                  type="date"
                  placeholder="Ngày bắt đầu *"
                  className={cx("col-12", "Search_Input")}
                  {...register("dateStart")}
                  value={objectFilter.dateStart}
                  onChange={resultDateStart}
                />
              </div>

              {/* <DateDay
                result={resultDateStart}
                placeholderText="Ngày bắt đầu *"
              /> */}
            </div>
            <div className="col-2 col-xl-6 col-md-12">
              <label className="form-label">
                Chọn ngày kết thúc <span className="text-danger">*</span>
              </label>
              <div className={cx("Search_Wrapper_horizontal", "rounded-3")}>
                <input
                  type="date"
                  placeholder="Ngày kết thúc *"
                  className={cx("col-12", "Search_Input")}
                  {...register("dateEnd")}
                  value={objectFilter.dateEnd}
                  onChange={resultDateEnd}
                />
              </div>

              {/* <DateDay
                result={resultDateEnd}
                placeholderText="Ngày kết thúc *"
              /> */}
            </div>
          </div>
          <div className="col-2 col-xl-12 mt-3">
            <select
              className="form-select rounded-3"
              style={{ height: "40px", border: "2px solid #e8e8e8" }}
              {...register("note")}
            >
              <option value="data-sale">Data sale</option>
              <option value="note">Ghi chú</option>
            </select>
          </div>
          {/* text */}
          <div className="col-5 col-xl-12 mt-3">
            <div
              className={cx(
                "Search_Wrapper_horizontal",
                "d-flex justify-content-center align-items-center rounded-3",
              )}
            >
              <div className={cx("SearchIcon")}>
                <FiSearch className="fs-15" />
              </div>
              <input
                type="text"
                placeholder={"Địa chỉ, số điện thoại..."}
                className={cx("col-12", "Search_Input")}
                {...register("textSearch")}
                value={valueText}
                onChange={handleGetValueText}
              />
              <div className={cx("Search_ClearText")}>
                {valueText !== "" ? (
                  <MdCancel onClick={handleClearInputText} className="fs-21" />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Submit */}
      <div className="px-4 mt-3 d-flex py-3 border-top border-block-start-dashed d-sm-flex justify-content-center">
        <button
          className="btn btn-primary-light m-1 fs-18 fw-6"
          type="submit"
          id="submitProduct"
        >
          Tìm kiếm
        </button>
      </div>
    </form>
  )
}

// Component search mobile.
function ComponentSearchMobile({ resultFilter }) {
  const path = useLocation()
  const currentPath = path.pathname
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const [objectFilter, setObjectFilter] = useState({
    dateStart: "",
    dateEnd: "",
    text: "",
  })
  const resultDateStart = (dateStart) => {
    setObjectFilter({
      dateStart: dateStart,
      dateEnd: objectFilter.dateEnd,
      text: objectFilter.text,
    })
  }
  const resultDateEnd = (dateEnd) => {
    setObjectFilter({
      dateStart: objectFilter.dateStart,
      dateEnd: dateEnd,
      text: objectFilter.text,
    })
  }
  const resultTextSearch = (textSearch) => {
    setObjectFilter({
      dateStart: objectFilter.dateStart,
      dateEnd: objectFilter.dateEnd,
      text: textSearch,
    })
  }

  const handleOnSubmit = (data) => {
    data.dateStart = objectFilter.dateStart
    data.dateEnd = objectFilter.dateEnd
    data.text = objectFilter.text
    
    if (currentPath === "/data-sale") {
      resultFilter(data)
    } else {
      alert("Bộ lọc chỉ dành cho mục tất cả.")
    }
  }
  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <div className="col-12">
        <div className="col-12 col-md-12 mb-3">
          <DateDay result={resultDateStart} placeholderText="Ngày bắt đầu" />
        </div>
        <div className="col-12 col-md-12 mb-3">
          <DateDay result={resultDateEnd} placeholderText="Ngày kết thúc" />
        </div>
        <div className="col-12 mb-3">
          <select
            className="form-select rounded-3"
            style={{ height: "40px", border: "2px solid #e8e8e8" }}
            {...register("note")}
          >
            <option value="data-sale">Data sale</option>
            <option value="note">Ghi chú</option>
          </select>
        </div>
        <div className="col-12 mb-3">
          <SearchHorizontal
            result={resultTextSearch}
            placeholder="Địa chỉ, số điện thoại..."
            className="rounded-3"
          />
        </div>
        {/* Submit */}
        <div className="px-4 d-flex py-3 border-top border-block-start-dashed d-sm-flex justify-content-center">
          <button
            className="btn btn-primary-light m-1 fs-18 fw-6"
            type="submit"
            id="submitProduct"
          >
            Tìm kiếm
          </button>
        </div>
      </div>
    </form>
  )
}