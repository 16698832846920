import { useContext, useLayoutEffect, useState } from "react"
import SearchReport from "../Component/Report/SearchReport"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import AppContext from "../../../handle-reuses/Contexts/CreateContexts"
import classNames from "classnames/bind"
import styles from "../Job.module.scss"
import DataReport from "../Component/Report/data/DataReport"
const cx = classNames.bind(styles);

function ListCateReportMobile() {
    const { socketio, dataUser } = useContext(AppContext)
    const navigate = useNavigate()
    // Kiểm tra quyền truy cập.
    useLayoutEffect(() => {
      if (dataUser[0].bao_cao === 0) {
        navigate("/")
      }
    }, [])

    const [objectSearch, setObjectSearch] = useState({
      monthSearch: moment().format("YYYY-MM"),
    })
    // Quản lý search data.
    const handleGetValueSearch = (object) => {
      setObjectSearch({
        monthSearch: object.monthSearch,
      })
    }

    return (
      <div>
        <div className="alert alert-primary text-center fs-16">
          <span>
            Báo cáo tài chính{" "}
            {moment(objectSearch.monthSearch).format("MM/YYYY")}
          </span>
        </div>
        {/* Timf kieems */}
        <div>
          <SearchReport resultSearch={handleGetValueSearch} />
        </div>
            {/* Category */}
            <div>
                <DataReport socketio={socketio} dataUser={dataUser} objectSearch={objectSearch} />
            </div>
        
      </div>
    )
}
export default ListCateReportMobile


