import classNames from "classnames/bind"
import styles from "../../Job.module.scss";
const cx = classNames.bind(styles);

function SearchVangmat() {
    return (
      <div className={cx("d-flex align-items-center gap-3")}>
        <input type="date" className={cx("border-1 fs-15", "class_input")} />
        <input type="month" className={cx("border-1 fs-15", "class_input")} />
      </div>
    )
}

export default SearchVangmat