function ListCustomer({active}) {
    return (
      <div
        className="main-content app-content"
        style={{ marginInlineStart: active && "6rem" }}
      >
        <div className="container-fluid mt-3">
            Danh sách
        </div>
      </div>
    )
}
export default ListCustomer