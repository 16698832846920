import { useEffect, useState } from "react"
import classNames from "classnames/bind"
import styles from "../Job.module.scss"
import HeaderJob from "../Component/HeaderJob"
import JobPagePC from "./JobPagePC"
import { useLocation } from "react-router-dom"
import BangChamCongPC from "./BangChamCongPC"
import ListVangmatPC from "./ListVangmatPC"
import ListXinnghiPC from "./ListXinnghiPC"
import ListProjectPC from "./ListProjectPC"
import ListContractPC from "./ListContractPC"
import ListSalaryTablePC from "./ListSalaryTablePC"
import ListPersonnalPC from "./ListPersonnalPC"
import ListCateReportPC from "./ListCateReportPC"
import KetoanPC from "./KetoanPC"
import ListTaichinhcanhanPC from "./ListTaichinhcanhanPC"
const cx = classNames.bind(styles)

function JobHomePagePC() {
    const path = useLocation()
    const currentPath = path.pathname
    // Quản lý hieer thị theo cate
    const [cateActive, setCateActive] = useState("cong-viec")
    useEffect(() => {
        setCateActive(currentPath)
    }, [currentPath])
    return (
      <div className={cx("main-content p-0", "bg-container")}>
        {/* Header */}
        <HeaderJob />
        {/* Content */}
        <div className="container-fluid p-3">
          {cateActive === "/nhan-su" && <ListPersonnalPC />}
          {cateActive === "/cham-cong" && <BangChamCongPC />}
          {cateActive === "/cham-cong/vang-mat" && <ListVangmatPC />}
          {cateActive === "/cham-cong/xin-nghi" && <ListXinnghiPC />}
          {cateActive === "/cong-viec" && <JobPagePC />}
          {cateActive === "/cong-viec/du-an-kinh-doanh" && <ListProjectPC />}
          {cateActive === "/hop-dong" && <ListContractPC />}
          {cateActive === "/bang-luong" && <ListSalaryTablePC />}
          {cateActive === "/ke-toan" && <KetoanPC />}
          {cateActive === "/bao-cao" && <ListCateReportPC />}
          {cateActive === "/tai-chinh-ca-nhan" && <ListTaichinhcanhanPC />}
        </div>
      </div>
    )
}
export default JobHomePagePC