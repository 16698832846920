import { Link } from "react-router-dom"
import { Dropdown } from "react-bootstrap"
import { VscDashboard } from "react-icons/vsc"
import {
  IoLayersOutline,
  IoFolderOpenOutline,
  IoLogoReact,
  IoCalendarOutline,
  IoCardOutline,
} from "react-icons/io5"
import { AiOutlineUsergroupAdd } from "react-icons/ai"
import { HiOutlineUserAdd } from "react-icons/hi"
import { BiSupport } from "react-icons/bi"

function SidebarMenuMobile({size}) {
    return (
      <Dropdown className="d-flex align-items-center">
        <Dropdown.Toggle
          variant="outline-line text-black border-0 p-2 py-1" // class mặc định
          bsPrefix="custom-dropdown-toggle" // đặt tên cho class fix loại bỏ class dropdown-toggle
        >
          <IoLayersOutline className={size} />
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          <Link to="/bdsview360/dashboard">
            <li className="dropdown-item d-flex fs-16 align-items-center">
              <VscDashboard className="me-2 fs-21" />
              Báo cáo
            </li>
          </Link>
          <Link to="/bdsview360/san-pham">
            <li className="dropdown-item d-flex fs-16 align-items-center">
              <IoFolderOpenOutline className="me-2 fs-21" />
              Sản phẩm
            </li>
          </Link>
          <Link to="/bdsview360/lich-hen">
            <li className="dropdown-item d-flex fs-16 align-items-center">
              <IoCalendarOutline className="me-2 fs-21" />
              Lịch hẹn
            </li>
          </Link>
          <Link to="/bdsview360/khach-hang">
            <li className="dropdown-item d-flex fs-16 align-items-center">
              <AiOutlineUsergroupAdd className="me-2 fs-21" />
              Khách hàng
            </li>
          </Link>
          <Link to="/bdsview360/tai-khoan">
            <li className="dropdown-item d-flex fs-16 align-items-center">
              <HiOutlineUserAdd className="me-2 fs-21" />
              Tài khoản
            </li>
          </Link>
          <Link to="/bdsview360/thanh-toan">
            <li className="dropdown-item d-flex fs-16 align-items-center">
              <IoCardOutline className="me-2 fs-21" />
              Thanh toán
            </li>
          </Link>
          <Link to="/bdsview360/ho-tro">
            <li className="dropdown-item d-flex fs-16 align-items-center">
              <BiSupport className="me-2 fs-21" />
              Hỗ trợ
            </li>
          </Link>
          <Link to="/bdsview360/content-ai">
            <li className="dropdown-item d-flex fs-16 align-items-center">
              <IoLogoReact className="me-2 fs-21" />
              Content AI
            </li>
          </Link>
        </Dropdown.Menu>
      </Dropdown>
    )
}

export default SidebarMenuMobile