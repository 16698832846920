import io from "socket.io-client"
import { useState, useEffect, useRef, useContext, useLayoutEffect } from "react"
import moment from "moment";
import { useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import { AiOutlineEdit } from "react-icons/ai"

import SearchHorizontal from "../../../../Components/Search/SearchHorizontal";
import ModalReact from "../../../../Components/Modal/ModalReact"
import { deleteAccentedText } from "../../../../handle-reuses/reuses"
import AppContent from "../../../../handle-reuses/Contexts/CreateContexts"
function ComponentContentAI({ active }) {
  const socket = useRef();
  const [dataContentAI, setDataContentAI] = useState([])
  const limit = 50;
  const page = useRef(1)
  const [loading, setLoading] = useState(true)
  const [dataCount, setDataCount] = useState([])
  const [dataStatistical, setDataStatistical] = useState([])
  let stt = 1
  // Khởi tạo socket.
  useEffect(() => {
    socket.current = io(process.env.REACT_APP_DOMAIN_BDSVIEW360)
    return () => {
      socket.current.disconnect()
    }
  }, []);

  // Quản lý get data.
  useEffect(() => {
    const params = {
      iden: process.env.REACT_APP_IDEN_BDSVIEW,
      object: {
        quantity: limit,
        offset: (page.current - 1) * limit
      }
    };
    // data content AI.
    socket.current.emit("client-get-content-ai-for-admin", params)
    // data count content AI.
    socket.current.emit("client-count-content-ai-for-admin", params)
    // Data content ai statistical.
    socket.current.emit("client-list-report-content-ai", params)
    return () => {
      socket.current.off("client-get-content-ai-for-admin")
      socket.current.off("client-count-content-ai-for-admin")
      socket.current.off("client-list-report-content-ai")
    }
  }, [])

  // Quản lý nhận data.
  useEffect(() => {
    // Data content ai
    socket.current.on("server-get-content-ai-for-admin", (data) => {
      setDataContentAI(data)
    })

    // Data count content ai.
    socket.current.on("server-count-content-ai-for-admin", (data) => {
      setDataCount(data)
    })

    // Data content ai statistical.
    socket.current.on("server-list-report-content-ai", (data) => {
      setDataStatistical(data)
      setLoading(false)
    })

    
    return () => {
      socket.current.off("server-get-content-ai-for-admin")
      socket.current.off("server-count-content-ai-for-admin")
    }
  }, [])

  // Chức năng thêm mới (modal).
  const [modalAdd, setModalAdd] = useState(false)
  const handleModalAddContentAI = () => {
    setModalAdd(true)
  }
  const handleCloseModalAdd = () => setModalAdd(false)
  
  // Chức năng edit content ai.
  const [modalEdit, setModalEdit] = useState(false)
  const [dataEditContent, setDataEditContent] = useState([])
  const handleModalEditContentAI = (id_) => {
    setModalEdit(true)
    let filterData = dataContentAI.filter((item) => item.id === id_);
    setDataEditContent(filterData)
  }
  const handleCloseModalEdit = () => {
    setModalEdit(false)
    setDataEditContent([])
  }

  // Chức năng xem chi tiết content AI.
  const [modalDetail, setModalDetail] = useState(false)
  const [dataDetail, setDataDetail] = useState([])
  const handleDetailContentAI = (id_) => {
    setModalDetail(true)
    let filterDetail = dataContentAI.filter((item) => item.id === id_);
    setDataDetail(filterDetail)
  }
  const handleCloseDetailContentAI = () => {
    setModalDetail(false)
    setDataDetail([])
  }

  // Chức năng tìm kiếm.
  const handleValueSearch = (valueSearch) => {
    const params = {
      iden: process.env.REACT_APP_IDEN_BDSVIEW,
      object: {
        keyword: valueSearch,
      },
    }
    socket.current.emit("client-search-content-ai", params)
    // Search data content ai
    socket.current.on("server-search-content-ai", (data) => {
      if (data.length > 0) {
        setDataContentAI(data)
      }
    })
  }


  if (loading) {
    return (
      <div className="main-content text-center mt-5">
        <div className="mt-5">Đang tải dữ liệu...</div>
      </div>
    )
  }

  return (
    <div
      className="main-content app-content"
      style={{ marginInlineStart: active && "6rem" }}
    >
      <div className="container-fluid">
        <div className="main-mail-container p-2 gap-2 row justify-content-center">
          <div className="mail-navigation border p-0">
            <div
              className="p-3 d-flex align-items-center border-bottom"
              style={{ height: "71.4px" }}
            >
              <h6>Danh sách yêu cầu</h6>
            </div>
            <div>
              <ul className="list-unstyled mail-main-nav p-0">
                {dataStatistical.map((item, index) => {
                  let contentSuccess = dataCount.filter((item_) => {
                    let address =
                      item_.streets + " " + item_.districts + " " + item_.city
                    return item.title
                      .toLowerCase()
                      .includes(address.toLowerCase())
                  })
                  return (
                    <li className="active mail-type" key={index}>
                      <div className="d-flex align-items-center">
                        <span className="me-2 lh-1">
                          <i className="ri-inbox-archive-line align-middle fs-14"></i>
                        </span>
                        <span className="flex-fill text-black d-flex">
                          <span className="fs-29 me-2 text-muted">{stt++}</span>
                          <div>
                            <span className="fs-15">{item.title}</span>
                            <p className="text-muted mb-0">
                              Lượt yêu cầu{" "}
                              {Number(item.quantity)
                                .toLocaleString()
                                .replace(/[.]/g, ",")}
                            </p>
                          </div>
                        </span>
                        <span className="badge bg-dark-transparent rounded-pill fs-12">
                          {contentSuccess.length > 0
                            ? contentSuccess[0].quantity
                            : 0}
                        </span>
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
          <div className="total-mails border" style={{width: "50%"}}>
            <div className="p-3 d-flex align-items-center border-bottom">
              <div>
                <button
                  className="btn btn-outline-dark"
                  onClick={handleModalAddContentAI}
                >
                  Thêm mới
                </button>
              </div>
            </div>
            <div className="p-3 border-bottom">
              <div className="input-group">
                <SearchHorizontal
                  className="rounded-3"
                  result={handleValueSearch}
                />
              </div>
            </div>
            <div className="mail-messages" id="mail-messages">
              <ul className="list-unstyled mb-0 mail-messages-container">
                {dataContentAI.map((item) => {
                  let addressContent =
                    item.streets + " " + item.districts + " " + item.city
                  let dataCount_ = dataCount.filter((item) => {
                    let address =
                      item.streets + " " + item.districts + " " + item.city
                    return address
                      .toLowerCase()
                      .includes(addressContent.toLowerCase())
                  })
                  return (
                    <li key={item.id}>
                      <div className="d-flex align-items-top">
                        <div className="flex-fill">
                          <div>
                            <p className="mb-1 fs-15">
                              {item.hoten}
                              <button
                                className="border-0 bg-transparent"
                                onClick={() =>
                                  handleModalEditContentAI(item.id)
                                }
                              >
                                <AiOutlineEdit />
                                <span>Sửa</span>
                              </button>
                              <span className="float-end text-muted fw-normal fs-15">
                                {moment(item.addtime).locale("vi").fromNow()},{" "}
                                {moment(item.addtime).format("HH:mm")}
                              </span>
                            </p>
                          </div>
                          <p
                            className="mb-0"
                            onClick={() => handleDetailContentAI(item.id)}
                          >
                            <span className="d-block mb-0 fw-semibold text-truncate fs-15">
                              {item.streets}, {item.districts}, {item.city}
                              <span className="text-muted ms-2">
                                (
                                {dataCount_.length > 0
                                  ? dataCount_[0].quantity
                                  : 0}
                                )
                              </span>
                            </span>
                            <span className="fs-14 text-muted text-wrap text-truncate">
                              {item.title}
                              <button className="btn p-0 lh-1 mail-starred border-0 float-end">
                                <i className="ri-star-fill fs-14"></i>
                              </button>
                            </span>
                          </p>
                        </div>
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
          
        </div>
      </div>
      {/* Modal add content ai */}
      <ModalReact
        modalTitle="Thêm mới nội dung"
        showModal={modalAdd}
        handleClose={handleCloseModalAdd}
        theme={
          <ComponentAddContentAI
            socketio={socket.current}
            limit={limit}
            page={page.current}
          />
        }
      />
      {/* Modal edit content Ai */}
      {dataEditContent.length > 0 && (
        <ModalReact
          modalTitle="Sửa nội dung"
          showModal={modalEdit}
          handleClose={handleCloseModalEdit}
          theme={
            <ComponentEditContentAI
              socketio={socket.current}
              limit={limit}
              page={page.current}
              dataEdit={dataEditContent}
            />
          }
        />
      )}
      {/* Modal detail content AI */}
      {dataDetail.length > 0 && (
        <ModalReact
          modalTitle="Chi tiết content AI"
          showModal={modalDetail}
          handleClose={handleCloseDetailContentAI}
          theme={
            <ComponentDetailContentAI
              dataDetail={dataDetail}
            />
          }
        />
      )}
    </div>
  )
}

export default ComponentContentAI


// Detail content AI.
function ComponentDetailContentAI({ dataDetail }) {

  useLayoutEffect(() => { 
    let location = document.querySelector("#location")
    location.innerHTML = dataDetail[0].content_location
    // advantage.
    document.querySelector("#advantage").innerHTML = dataDetail[0].content_advantage
    // contact.
    document.querySelector("#contact").innerHTML =
      dataDetail[0].content_contact
  }, [])
  return (
    <div className="mails-information border">
      <div className="mail-info-header d-flex flex-wrap gap-2 align-items-center">
        <div className="me-1">
          <span className="avatar avatar-md me-2 avatar-rounded mail-msg-avatar">
            <img
              src={"https://batdongsan.view360.vn/" + dataDetail[0].avatar}
              alt=""
            />
          </span>
        </div>
        <div className="flex-fill">
          <h6 className="mb-0 fw-semibold">{dataDetail[0].hoten}</h6>
          {/* <span className="text-muted fs-12">michaeljeremy2194@gmail.com</span> */}
        </div>
      </div>
      <div className="mail-info-body p-4" id="mail-info-body">
        <div className="d-sm-flex d-block align-items-center justify-content-between mb-4">
          <div>
            <p className="fs-20 fw-semibold mb-0">
              {dataDetail[0].streets}, {dataDetail[0].districts},{" "}
              {dataDetail[0].city}
            </p>
          </div>
          <div className="float-end text-end">
            <span className="me-2 fs-14 text-muted">
              {moment(dataDetail[0].addtime).locale("vi").fromNow()},{" "}
              {moment(dataDetail[0].addtime).format("DD/MM/YYYY")}
            </span>
          </div>
        </div>
        <div className="main-mail-content mb-4">
          <p className="fs-14 fw-semibold mb-4">
            {dataDetail[0].title} &#128400;
          </p>
          <p className="mb-2 fs-16 text-muted">
            <span id="location"></span>
          </p>
          <p className="mb-2 fs-16 text-muted">
            <span id="advantage"></span>
          </p>
          <p className="mb-2 fs-16 text-muted">
            <span id="contact"></span>
          </p>
          <p className="mb-0 mt-4 text-muted">
            <span className="d-block">Keywords</span>
            {dataDetail[0].keywords}
          </p>
        </div>
      </div>
    </div>
  )
}


// Xử lý form add content ai.
function ComponentAddContentAI({ socketio, limit, page }) {
  const { dataUser } = useContext(AppContent);
  const { register, handleSubmit,formState: { errors }, reset } = useForm()
  const [ discription, setDiscription ] = useState({
    content_location: "",
    content_advantage: "",
    content_contact: ""
  })
  const handleEditorChangeLocation = (value) => {
    setDiscription({
      content_location: value,
      content_advantage: discription.content_advantage,
      content_contact: discription.content_contact,
    })
  }
  const handleEditorChangeAdvantage = (value) => {
    setDiscription({
      content_location: discription.content_location,
      content_advantage: value,
      content_contact: discription.content_contact,
    })
  }
  const handleEditorChangeContact = (value) => {
    setDiscription({
      content_location: discription.content_location,
      content_advantage: discription.content_advantage,
      content_contact: value,
    })
  }
  const buttonSubmit = (data) => {
    data.content_location = discription.content_location
    data.content_advantage = discription.content_advantage
    data.content_contact = discription.content_contact
    let alias_ = deleteAccentedText(data.title)
      .toLowerCase()
      .replace(/[,.]/g, "")
      .replace(/[ ]/g, "-")
    data.alias = alias_
    data.author = dataUser[0].id_user

    // Xử lý thêm mới
    const params = {
      iden: process.env.REACT_APP_IDEN_BDSVIEW,
      object: data,
      objectUpdate: {
        quantity: limit,
        offset: (page - 1) * limit,
      },
    }
    if (data.content_location !== "" && data.content_advantage !== "" && data.content_contact !== "") {
      // Xử lý thêm mới
      socketio.emit("client-create-content-ai", params);
      // Cập nhật lại Data content ai statistical.
      socketio.emit("client-list-report-content-ai", params)
      alert("Bạn đã thêm thành công.")
      setTimeout(() => {
        reset()
      }, 100);
    } else {
      alert("Bạn chưa tạo nội dung cho mô tả vị trí, ưu điểm, liên hệ.")
    }
  }
  return (
    <form onSubmit={handleSubmit(buttonSubmit)}>
      <div className="row">
        <div className="col-xl-12 col-md-12">
          <div className="form-floating mb-3">
            <input
              type="text"
              {...register("title", { required: true })}
              className="form-control"
              placeholder="Tiêu đề nội dung AI"
            />
            {errors.title && (
              <span className="text-danger fs-12">*Bạn cần tạo tiêu đề</span>
            )}
            <label>Tiêu đề</label>
          </div>
        </div>
        <div className="col-xl-12 col-md-12">
          <div className="form-floating mb-3">
            <input
              type="text"
              {...register("streets", { required: true })}
              className="form-control"
              placeholder="Tên đường/Phố"
            />
            {errors.streets && (
              <span className="text-danger fs-12">*Bạn chưa có tên đường</span>
            )}
            <label>Tên đường/Phố</label>
          </div>
        </div>
        <div className="col-xl-6 col-12 col-md-12">
          <div className="form-floating mb-3">
            <input
              type="text"
              {...register("districts", { required: true })}
              className="form-control"
              placeholder="Tên quận huyện"
            />
            {errors.districts && (
              <span className="text-danger fs-12">*Bạn chưa có quận huyện</span>
            )}
            <label>Quận/Huyện</label>
          </div>
        </div>
        <div className="col-xl-6 col-12 col-md-12">
          <div className="form-floating mb-3">
            <input
              type="text"
              {...register("city", { required: true })}
              className="form-control"
              placeholder="Tên thành phố"
            />
            {errors.city && (
              <span className="text-danger fs-12">
                *Bạn chưa có tên thành phố
              </span>
            )}
            <label>Thành phố</label>
          </div>
        </div>
        {/* Mô tả vị trí */}
        <div className="col-xl-12">
          <label className="form-label mb-1 d-flex justify-content-between">
            <span className="text-muted">
              Mô tả vị trí<sup className="text-danger">*</sup>
            </span>
          </label>
          <ReactQuill
            theme="snow"
            value={discription.content_location}
            onChange={handleEditorChangeLocation}
          />
        </div>
        {/* Mô tả ưu điểm */}
        <div className="col-xl-12 mt-3">
          <label className="form-label mb-1 d-flex justify-content-between">
            <span className="text-muted">
              Mô tả ưu điểm<sup className="text-danger">*</sup>
            </span>
          </label>
          <ReactQuill
            theme="snow"
            value={discription.content_advantage}
            onChange={handleEditorChangeAdvantage}
          />
        </div>
        {/* Mô tả liên hệ */}
        <div className="col-xl-12 mt-3">
          <label className="form-label mb-1 d-flex justify-content-between">
            <span className="text-muted">
              Mô tả liên hệ<sup className="text-danger">*</sup>
            </span>
          </label>
          <ReactQuill
            theme="snow"
            value={discription.content_contact}
            onChange={handleEditorChangeContact}
          />
        </div>
        {/* Keywords */}
        <div className="col-xl-12 col-12">
          <div className="form-floating mb-4 mt-3">
            <textarea
              {...register("keywords", { required: true })}
              className="form-control"
              placeholder="Nhập từ khóa"
            ></textarea>
            {errors.keywords && (
              <span className="text-danger fs-12">*Bạn chưa nhập từ khóa</span>
            )}
            <label>Keywords</label>
          </div>
        </div>
      </div>

      {/* Button submit */}
      <div className="px-4 mt-3 d-flex py-3 border-top border-block-start-dashed d-sm-flex justify-content-center">
        <button className="btn btn-primary-light m-1" type="submit">
          Thêm mới
        </button>
      </div>
    </form>
  )
}


// Xử lý edit data content ai.
function ComponentEditContentAI({ socketio, limit, page, dataEdit }) {
  const { dataUser } = useContext(AppContent)
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()
  const [discription, setDiscription] = useState({
    content_location: dataEdit[0].content_location,
    content_advantage: dataEdit[0].content_advantage,
    content_contact: dataEdit[0].content_contact,
  })
  const handleEditorChangeLocation = (value) => {
    setDiscription({
      content_location: value,
      content_advantage: discription.content_advantage,
      content_contact: discription.content_contact,
    })
  }
  const handleEditorChangeAdvantage = (value) => {
    setDiscription({
      content_location: discription.content_location,
      content_advantage: value,
      content_contact: discription.content_contact,
    })
  }
  const handleEditorChangeContact = (value) => {
    setDiscription({
      content_location: discription.content_location,
      content_advantage: discription.content_advantage,
      content_contact: value,
    })
  }
  const buttonSubmit = (data) => {
    data.content_location = discription.content_location
    data.content_advantage = discription.content_advantage
    data.content_contact = discription.content_contact
    let alias_ = deleteAccentedText(data.title)
      .toLowerCase()
      .replace(/[,.]/g, "")
      .replace(/[ ]/g, "-")
    data.alias = alias_
    data.author = dataUser[0].id
    data.id = dataEdit[0].id
    data.content_defect = ""
    // Xử lý thêm mới
    const params = {
      iden: process.env.REACT_APP_IDEN_BDSVIEW,
      object: data,
      objectUpdate: {
        quantity: limit,
        offset: (page - 1) * limit,
      },
    }
    if (
      data.content_location !== "" &&
      data.content_advantage !== "" &&
      data.content_contact !== ""
    ) {
      // Xử lý thêm mới
      socketio.emit("client-edit-content-ai", params)
      alert("Bạn đã cập nhật thành công.")
      setTimeout(() => {
        reset()
      }, 100)
    } else {
      alert("Bạn chưa tạo nội dung cho mô tả vị trí, ưu điểm, liên hệ.")
    }
  }
  return (
    <form onSubmit={handleSubmit(buttonSubmit)}>
      <div className="row">
        <div className="col-xl-12 col-md-12">
          <div className="form-floating mb-3">
            <input
              type="text"
              defaultValue={dataEdit[0].title}
              {...register("title", { required: true })}
              className="form-control"
              placeholder="Tiêu đề nội dung AI"
            />
            {errors.title && (
              <span className="text-danger fs-12">*Bạn cần tạo tiêu đề</span>
            )}
            <label>Tiêu đề</label>
          </div>
        </div>
        <div className="col-xl-12 col-md-12">
          <div className="form-floating mb-3">
            <input
              type="text"
              defaultValue={dataEdit[0].streets}
              {...register("streets", { required: true })}
              className="form-control"
              placeholder="Tên đường/Phố"
            />
            {errors.streets && (
              <span className="text-danger fs-12">*Bạn chưa có tên đường</span>
            )}
            <label>Tên đường/Phố</label>
          </div>
        </div>
        <div className="col-xl-6 col-12 col-md-12">
          <div className="form-floating mb-3">
            <input
              type="text"
              defaultValue={dataEdit[0].districts}
              {...register("districts", { required: true })}
              className="form-control"
              placeholder="Tên quận huyện"
            />
            {errors.districts && (
              <span className="text-danger fs-12">*Bạn chưa có quận huyện</span>
            )}
            <label>Quận/Huyện</label>
          </div>
        </div>
        <div className="col-xl-6 col-12 col-md-12">
          <div className="form-floating mb-3">
            <input
              type="text"
              defaultValue={dataEdit[0].city}
              {...register("city", { required: true })}
              className="form-control"
              placeholder="Tên thành phố"
            />
            {errors.city && (
              <span className="text-danger fs-12">
                *Bạn chưa có tên thành phố
              </span>
            )}
            <label>Thành phố</label>
          </div>
        </div>
        {/* Mô tả vị trí */}
        <div className="col-xl-12">
          <label className="form-label mb-1 d-flex justify-content-between">
            <span className="text-muted">
              Mô tả vị trí<sup className="text-danger">*</sup>
            </span>
          </label>
          <ReactQuill
            theme="snow"
            value={
              discription.content_location !== ""
                ? discription.content_location
                : dataEdit[0].content_location
            }
            onChange={handleEditorChangeLocation}
          />
        </div>
        {/* Mô tả ưu điểm */}
        <div className="col-xl-12 mt-3">
          <label className="form-label mb-1 d-flex justify-content-between">
            <span className="text-muted">
              Mô tả ưu điểm<sup className="text-danger">*</sup>
            </span>
          </label>
          <ReactQuill
            theme="snow"
            value={
              discription.content_advantage !== ""
                ? discription.content_advantage
                : dataEdit[0].content_advantage
            }
            onChange={handleEditorChangeAdvantage}
          />
        </div>
        {/* Mô tả liên hệ */}
        <div className="col-xl-12 mt-3">
          <label className="form-label mb-1 d-flex justify-content-between">
            <span className="text-muted">
              Mô tả liên hệ<sup className="text-danger">*</sup>
            </span>
          </label>
          <ReactQuill
            theme="snow"
            value={
              discription.content_contact !== ""
                ? discription.content_contact
                : dataEdit[0].content_contact
            }
            onChange={handleEditorChangeContact}
          />
        </div>
        {/* Keywords */}
        <div className="col-xl-12 col-12">
          <div className="form-floating mb-4 mt-3">
            <textarea
              defaultValue={dataEdit[0].keywords}
              {...register("keywords", { required: true })}
              className="form-control"
              placeholder="Nhập từ khóa"
            ></textarea>
            {errors.keywords && (
              <span className="text-danger fs-12">*Bạn chưa nhập từ khóa</span>
            )}
            <label>Keywords</label>
          </div>
        </div>
      </div>

      {/* Button submit */}
      <div className="px-4 mt-3 d-flex py-3 border-top border-block-start-dashed d-sm-flex justify-content-center">
        <button className="btn btn-primary-light m-1" type="submit">
          Lưu lại
        </button>
      </div>
    </form>
  )
}

