import Tippy from "@tippyjs/react";

function ChartRevenueYear({ dataCateRevenueYear }) {
    // Tổng doanh thu năm
    let arrRevenue = dataCateRevenueYear.map((item) => item.total_revenue);
    let totalRevenueYear = arrRevenue.reduce((a, b)=> Number(a) + Number(b))

  return (
    <div className="card custom-card">
      <div className="card-body">
        <div className="alert alert-primary text-center fs-16" role="alert">
          Biểu đồ doanh thu năm
          <p className="mb-0">{totalRevenueYear.toLocaleString().replace(/[.]/g, ",")}đ</p>
        </div>
        {dataCateRevenueYear.length === 0 && (
          <div className="text-center mt-3">Chưa có dữ liệu.</div>
        )}
        {dataCateRevenueYear.length > 0 &&
          dataCateRevenueYear.map((item, index) => {
            //   set ty suat
            let tysuatRevenue =
              (Number(item.total_revenue) / totalRevenueYear) * 100
            return (
              <Tippy
                content={
                  "Doanh thu " +
                  item.total_revenue.toLocaleString().replace(/[.]/g, ",")
                }
                key={index}
              >
                <div
                  className="progress progress-xl progress-animate custom-progress-4 rounded-0"
                  role="progressbar"
                  aria-valuenow="10"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ borderBottom: "1px solid white" }}
                >
                  <div
                    className="progress-bar rounded-0"
                    style={{
                      width: tysuatRevenue + "%",
                      background: "#8784d8",
                    }}
                  >
                    {Number.isInteger(tysuatRevenue)
                      ? tysuatRevenue
                      : tysuatRevenue.toFixed(2)}
                    %
                  </div>
                  <div className="progress-bar-label">
                    {tysuatRevenue < 10 && (
                      <span className="me-1">
                        {Number.isInteger(tysuatRevenue)
                          ? tysuatRevenue
                          : tysuatRevenue.toFixed(2) + "%"}
                      </span>
                    )}
                    {item.contract_name}
                  </div>
                </div>
              </Tippy>
            )
          })}

        {/* <div
          className="progress progress-xl progress-animate custom-progress-4 rounded-0"
          role="progressbar"
          aria-valuenow="10"
          aria-valuemin="0"
          aria-valuemax="100"
          style={{ borderBottom: "1px solid white" }}
        >
          <div
            className="progress-bar bg-success rounded-0"
            style={{ width: "52%" }}
          >
            52%
          </div>
          <div className="progress-bar-label">Công nghệ</div>
        </div>
        <div
          className="progress progress-xl progress-animate custom-progress-4 rounded-0"
          role="progressbar"
          aria-valuenow="10"
          aria-valuemin="0"
          aria-valuemax="100"
          style={{ borderBottom: "1px solid white" }}
        >
          <div
            className="progress-bar bg-danger rounded-0"
            style={{ width: "22%" }}
          >
            22%
          </div>
          <div className="progress-bar-label">View 360</div>
        </div> */}
      </div>
    </div>
  )
}
export default ChartRevenueYear