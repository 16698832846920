import { useState } from "react"
import { IoFileTrayFullOutline, IoLayersOutline, IoServerOutline } from "react-icons/io5"
import { PiShoppingCart } from "react-icons/pi"
import ModalReact from "../../../../Components/Modal/ModalReact";
import FormAddDataSale from "../../../DataSale/Component/FormAddDataSale";
import FormAddContractOther from "../../../Job/Component/Hopdong/FormAddContractOther";
import FormAddContractTech from "../../../Job/Component/Hopdong/FormAddContractTech";
import ModalAddContractView from "../../../View360/ContractsPage/Components/ModalAddContractView";
import FormAddContract from "../../../DataSale/Component/FormAddContract";
import FormAddChiphi from "../../../Job/Component/Ketoan/FormAddChiphi";
import FormAddGiavang from "../../../Job/Component/Taichinhcanhan/FormGiavang";

function CateAddMobile({ socketio, dataUser }) {
  // Quản lý thêm mới khách hàng bán.
  const [modalAddDataSale, setModalAddDataSale] = useState(false)
  const handleAddDataSale = () => {
    setModalAddDataSale(true)
  }
  const handleCloseAddDataSale = () => setModalAddDataSale(false)
  const handleAddDataSaleSuccess = (object) => {
    setModalAddDataSale(false)
  }

  // Thêm mới hợp đồng quảng cáo bất động sản.
  const [modalAddAdsBDS, setModalAddAdsBDS] = useState(false)
  const handleAddContractAdsBDS = () => {
    setModalAddAdsBDS(true)
  }
  const handleCloseAddContractAdsBDS = () => setModalAddAdsBDS(false)
  const handleAddContractAdsBDSSuccess = (status_) => {
    if (status_) {
      setModalAddAdsBDS(false)
    }
  }

  // Thêm mới hợp đồng view 360.
  const [modalAddView360, setModalAddView360] = useState(false)
  const handleAddContractView360 = () => {
    setModalAddView360(true)
  }
  const handleCloseAddContractView360 = () => setModalAddView360(false)
  const handleAddContractView360Success = (status_) => {
    if (status_) {
      setModalAddView360(false)
    }
  }

  // Thêm mới hợp đồng công nghệ.
  const [modalAddTech, setModalAddTech] = useState(false)
  const handleAddContractTech = () => {
    setModalAddTech(true)
  }
  const handleCloseAddContractTech = () => setModalAddTech(false)
  const handleAddContractSuccess = (status_) => {
    if (status_) {
      setModalAddTech(false)
    }
  }

  // Thêm mới hợp đồng khác.
  const [modalAddOther, setModalAddOther] = useState(false)
  const handleAddContractOther = () => {
    setModalAddOther(true)
  }
  const handleCloseAddContractOther = () => setModalAddOther(false)
  const handleAddContractOtherSuccess = (status_) => {
    if (status_) {
      setModalAddOther(false)
    }
  }

  // Quản lý thêm mới chi phí.
  const [modalAddChiphi, setModalAddChiphi] = useState(false)
  const handleAddChiphi = () => {
    if (dataUser[0].ke_toan === 1) {
      setModalAddChiphi(true)
    } else {
      alert("Bạn không có quyền thêm chi phí.")
    }
  }
  const handleCloseAddModalChiphi = () => {
    setModalAddChiphi(false)
  }
  const handleUpdateDataCreateSuccess = (object) => {
    if (object.status) {
      setModalAddChiphi(false)
    }
  }

  // Quan ly them gia vang.
  const [modalAddGiavang, setModalAddGiavang] = useState(false)
  const handleModalAddGiavang = () => {
    setModalAddGiavang(true)
  }
  const handleCloseModalAddGiavang = () => setModalAddGiavang(false)
  const handleAddGiavangSuccess = (object) => {
    if (object.status) {
      setModalAddGiavang(false)
    }
  }

  return (
    <>
      <div className="wrapper_ground">
        <button
          className="col-2 d-flex align-items-center flex-direction-column list_items border-0 bg-transparent gap-1"
          onClick={handleAddDataSale}
        >
          <div
            className="btn btn-icon btn-outline-primary rounded-pill btn-wave waves-effect waves-light rounded-5"
            style={{ width: "3rem", height: " 3rem" }}
          >
            <IoLayersOutline
              style={{ fontSize: "1.8rem", margin: "-.5rem", marginTop: "0" }}
            />
          </div>

          <div className="fs-14 fw-5 text-center">Khách bán</div>
        </button>
        <button
          className="col-2 d-flex align-items-center flex-direction-column list_items border-0 bg-transparent gap-1"
          data-bs-toggle="dropdown"
        >
          <div
            className="btn btn-icon btn-outline-primary rounded-pill btn-wave waves-effect waves-light rounded-5"
            style={{ width: "3rem", height: " 3rem" }}
          >
            <IoFileTrayFullOutline
              style={{ fontSize: "1.8rem", margin: "-.5rem", marginTop: "0" }}
            />
          </div>

          <div className="fs-14 fw-5 text-center">Hợp đồng</div>
        </button>
        <ul className="dropdown-menu">
          <li className="dropdown-item" onClick={handleAddContractAdsBDS}>
            Bất động sản
          </li>
          <li className="dropdown-item" onClick={handleAddContractView360}>
            View 360
          </li>
          <li className="dropdown-item" onClick={handleAddContractTech}>
            Công nghệ
          </li>
          <li className="dropdown-item" onClick={handleAddContractOther}>
            Loại khác
          </li>
        </ul>

        <button
          className="col-2 d-flex align-items-center flex-direction-column list_items border-0 bg-transparent gap-1"
          onClick={handleAddChiphi}
        >
          <div
            className="btn btn-icon btn-outline-primary rounded-pill btn-wave waves-effect waves-light rounded-5"
            style={{ width: "3rem", height: " 3rem" }}
          >
            <PiShoppingCart
              style={{ fontSize: "1.8rem", margin: "-.5rem", marginTop: "0" }}
            />
          </div>

          <div className="fs-14 fw-5 text-center">Chi phí</div>
        </button>
        <button
          className="col-2 d-flex align-items-center flex-direction-column list_items border-0 bg-transparent gap-1"
          onClick={handleModalAddGiavang}
        >
          <div
            className="btn btn-icon btn-outline-primary rounded-pill btn-wave waves-effect waves-light rounded-5"
            style={{ width: "3rem", height: " 3rem" }}
          >
            <IoServerOutline
              style={{ fontSize: "1.8rem", margin: "-.5rem", marginTop: "0" }}
            />
          </div>

          <div className="fs-14 fw-5 text-center">Giá vàng</div>
        </button>
      </div>

      {/* Modal add datasale */}
      {modalAddDataSale && (
        <ModalReact
          modalTitle="Thêm mới khách hàng"
          showModal={modalAddDataSale}
          handleClose={handleCloseAddDataSale}
          theme={<FormAddDataSale resultStatus={handleAddDataSaleSuccess} />}
        />
      )}
      {/* Modal add contract ads bds */}
      {modalAddAdsBDS && (
        <ModalReact
          modalTitle="Thêm mới hợp đồng bất động sản"
          showModal={modalAddAdsBDS}
          handleClose={handleCloseAddContractAdsBDS}
          theme={
            <FormAddContract
              socketio={socketio}
              dataUser={dataUser}
              resultStatus={handleAddContractAdsBDSSuccess}
            />
          }
        />
      )}

      {/* Modal add contract view 360 */}
      {modalAddView360 && (
        <ModalReact
          modalTitle="Thêm mới hợp đồng view 360"
          showModal={modalAddView360}
          handleClose={handleCloseAddContractView360}
          theme={
            <ModalAddContractView
              checkChange={handleAddContractView360Success}
            />
          }
        />
      )}

      {/* Modal add contract tech */}
      {modalAddTech && (
        <ModalReact
          modalTitle="Thêm mới hợp đồng công nghệ"
          showModal={modalAddTech}
          handleClose={handleCloseAddContractTech}
          theme={
            <FormAddContractTech
              socketio={socketio}
              dataUser={dataUser}
              result={handleAddContractSuccess}
            />
          }
        />
      )}
      {/* Modal add contract other */}
      {modalAddOther && (
        <ModalReact
          modalTitle="Thêm mới hợp đồng khác"
          showModal={modalAddOther}
          handleClose={handleCloseAddContractOther}
          theme={
            <FormAddContractOther
              socketio={socketio}
              dataUser={dataUser}
              result={handleAddContractOtherSuccess}
            />
          }
        />
      )}

      {/* Modal add chi phí */}
      {modalAddChiphi && (
        <ModalReact
          modalTitle="Thêm mới chi phí"
          showModal={modalAddChiphi}
          handleClose={handleCloseAddModalChiphi}
          theme={
            <FormAddChiphi
              socketio={socketio}
              dataUser={dataUser}
              result={handleUpdateDataCreateSuccess}
            />
          }
        />
      )}

      {/* Modal add gia vang */}
      {modalAddGiavang && (
        <ModalReact
          modalTitle="Thêm mới giá vàng"
          showModal={modalAddGiavang}
          handleClose={handleCloseModalAddGiavang}
          theme={
            <FormAddGiavang
              socketio={socketio}
              dataUser={dataUser}
              result={handleAddGiavangSuccess}
            />
          }
        />
      )}
    </>
  )
}
export default CateAddMobile