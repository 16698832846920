import classNames from "classnames/bind"
import { TbArrowBadgeUpFilled, TbArrowBigDownLinesFilled, TbArrowBigUpLinesFilled } from "react-icons/tb"
import LoadingSpinner from "../../../../Components/Loading/LoadingSpinner"
import ModalReact from "../../../../Components/Modal/ModalReact"
import { FaStar, FaStarHalf } from "react-icons/fa"
import { useEffect, useRef, useState } from "react"
import styles from "../../Job.module.scss"
import Tippy from "@tippyjs/react"
import { IoAlertCircle, IoAlertCircleOutline, IoRibbon } from "react-icons/io5"
const cx = classNames.bind(styles)

function ListEvaluate({ socketio, dataUser, objectSearch }) {
  const [dataPersonnel, setDataPersonel] = useState([])
  // Quản lý danh sách user.
  useEffect(() => {
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        role: dataUser[0].list_evaluate,
        user_id: dataUser[0].id_user,
        textSearch: objectSearch.textSearch,
      },
    }
    socketio.emit("client-get-search-data-for-user-evaluate-job", params)
    socketio.on("server-get-search-data-for-user-evaluate-job", (data) => {
      setDataPersonel([...data])
    })
    return () => {
      socketio.off("client-get-search-data-for-user-evaluate-job")
      socketio.off("server-get-search-data-for-user-evaluate-job")
    }
  }, [objectSearch])

  // Quản lý đánh giá chi tiết.
  const [modalDetail, setModalDetail] = useState(false)
  const idUserPersonnel = useRef(0)
  const handleDetailEvaluate = (idUser) => {
    setModalDetail(true)
    idUserPersonnel.current = idUser
  }
  const handleCloseDetailEvaluate = () => {
    setModalDetail(false)
  }
  return (
    <div
      className="row gy-4 m-1 rounded-3"
      style={{ backgroundColor: "#ffffffab", minHeight: "calc(100vh - 12rem)" }}
    >
      {dataPersonnel.map((item, index) => {
        // Set evaluate.
        let successJob = item.success_job
        let totalJob = item.total_job
        let tysuat = (successJob / totalJob) * 100
        let formatTysuat = Number.isInteger(tysuat) ? tysuat : tysuat.toFixed(1)
        let evaluate_ = ""
        if (tysuat < 20) {
          evaluate_ = <FaStarHalf className="fs-15" />
        } else if (tysuat >= 20 && tysuat < 30) {
          evaluate_ = <FaStar className="fs-15" />
        } else if (tysuat >= 30 && tysuat < 40) {
          evaluate_ = (
            <span className="d-flex gap-1">
              <FaStar className="fs-15" />
              <FaStarHalf className="fs-15" />
            </span>
          )
        } else if (tysuat >= 40 && tysuat < 50) {
          evaluate_ = (
            <span className="d-flex gap-1">
              <FaStar className="fs-15" />
              <FaStar className="fs-15" />
            </span>
          )
        } else if (tysuat >= 50 && tysuat < 60) {
          evaluate_ = (
            <span className="d-flex gap-1">
              <FaStar className="fs-15" />
              <FaStar className="fs-15" />
              <FaStarHalf className="fs-15" />
            </span>
          )
        } else if (tysuat >= 60 && tysuat < 70) {
          evaluate_ = (
            <span className="d-flex gap-1">
              <FaStar className="fs-15" />
              <FaStar className="fs-15" />
              <FaStar className="fs-15" />
            </span>
          )
        } else if (tysuat >= 70 && tysuat < 80) {
          evaluate_ = (
            <span className="d-flex gap-1">
              <FaStar className="fs-15" />
              <FaStar className="fs-15" />
              <FaStar className="fs-15" />
              <FaStarHalf className="fs-15" />
            </span>
          )
        } else if (tysuat >= 80 && tysuat < 90) {
          evaluate_ = (
            <span className="d-flex gap-1">
              <FaStar className="fs-15" />
              <FaStar className="fs-15" />
              <FaStar className="fs-15" />
              <FaStar className="fs-15" />
            </span>
          )
        } else if (tysuat >= 90 && tysuat < 100) {
          evaluate_ = (
            <span className="d-flex gap-1">
              <FaStar className="fs-15" />
              <FaStar className="fs-15" />
              <FaStar className="fs-15" />
              <FaStar className="fs-15" />
              <FaStarHalf className="fs-15" />
            </span>
          )
        } else if (tysuat >= 100) {
          evaluate_ = (
            <span className="d-flex gap-1">
              <FaStar className="fs-15" />
              <FaStar className="fs-15" />
              <FaStar className="fs-15" />
              <FaStar className="fs-15" />
              <FaStar className="fs-15" />
            </span>
          )
        }
        return (
          <div
            className="text-center col-xxl-2 col-xl-6 col-lg-6 col-md-6 col-sm-6"
            key={index}
          >
            <div className="d-flex align-items-center mb-3 flex-direction-column">
              <div className="position-relative">
                <span className="avatar avatar-lg avatar-rounded">
                  <img
                    src={"https://system.view360.vn/" + item.avatar}
                    alt=""
                  />
                </span>
                {tysuat >= 90 && (
                  <Tippy content="Xét duyệt lên chức">
                    <span className="position-absolute">
                      <TbArrowBigUpLinesFilled className="text-success fs-18" />
                    </span>
                  </Tippy>
                )}
                {tysuat >= 80 && tysuat < 90 && (
                  <Tippy content="Hiệu suất khá">
                    <span className="position-absolute">
                      <TbArrowBadgeUpFilled className="text-success fs-18" />
                    </span>
                  </Tippy>
                )}
                {tysuat > 50 && tysuat < 80 && (
                  <Tippy content="Hiệu suất trung bình">
                    <span className="position-absolute">
                      <TbArrowBadgeUpFilled className="text-primary fs-18" />
                    </span>
                  </Tippy>
                )}
                {tysuat > 30 && tysuat <= 50 && (
                  <Tippy content="Hiệu suất thấp">
                    <span className="position-absolute">
                      <IoAlertCircleOutline className="text-danger fs-18" />
                    </span>
                  </Tippy>
                )}
                {tysuat <= 30 && (
                  <Tippy content="Báo động">
                    <span className="position-absolute">
                      <TbArrowBigDownLinesFilled className="text-danger fs-18" />
                    </span>
                  </Tippy>
                )}
              </div>
              <div>
                <p className="mb-0 fw-semibold text-primary text-center">
                  {item.hoten}
                </p>
                <p className="mb-0 text-center">Chức vụ: {item.chuc_vu}</p>
                <p className="mb-1 text-center">Bộ phận: {item.bo_phan}</p>
                <p className="d-flex gap-1 justify-content-center text-warning mb-0">
                  {evaluate_}
                </p>
                <p className="mb-1 text-center">Năng lực {formatTysuat}%</p>
                <button
                  className="badge bg-primary-gradient border-0 fw-4 rounded-5 px-2"
                  onClick={() => handleDetailEvaluate(item.id_user)}
                >
                  Phân tích
                </button>
              </div>
            </div>
          </div>
        )
      })}
      {/* Modal detail */}
      {modalDetail && (
        <ModalReact
          modalTitle={
            <div className="alert alert-primary fs-15 fw-5" role="alert">
              <IoRibbon className="me-2 fs-21" />
              Phân tích năng lực nhân viên
            </div>
          }
          size="modal-dialog-centered"
          showModal={modalDetail}
          handleClose={handleCloseDetailEvaluate}
          theme={
            <ListEvaluatePersonnel
              idUser={idUserPersonnel.current}
              socketio={socketio}
            />
          }
        />
      )}
    </div>
  )
}

export default ListEvaluate

// List evaluate.
function ListEvaluatePersonnel({ socketio, idUser }) {
  // Quản lý get data.
    const [listEvaluate, setListEvaluate] = useState([])
    const evaluateUser = useRef(0)
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        user_id: idUser,
      },
    }
    socketio.emit("client-get-search-data-cate-evalute-job", params)
    socketio.on("server-get-search-data-cate-evalute-job", (data) => {
        setListEvaluate([...data]);
        // Đánh giá nhân viên.
        let arrSuccessJob = data.map((item) => item.success_job);
        let totalSuccessJob = arrSuccessJob.reduce((a, b) => Number(a) + Number(b));
        let arrTotalJob = data.map((item) => item.total_job);
        let totalJob = arrTotalJob.reduce((a, b) => Number(a) + Number(b));
        let evaluate_ = (totalSuccessJob / totalJob) * 100;
        evaluateUser.current = Number.isInteger(evaluate_) ? evaluate_ : evaluate_.toFixed(1)
      setLoading(false)
    })
    return () => {
      socketio.off("client-get-search-data-cate-evalute-job")
      socketio.off("server-get-search-data-cate-evalute-job")
    }
  }, [])

  if (loading) {
    return <LoadingSpinner />
  }
  if (listEvaluate.length === 0) {
    return <div className="text-center fs-15">Chưa có dữ liệu để đánh giá.</div>
  }
  return (
    <div>
      <div className="text-center">
        <span
          className={cx(
            "avatar avatar-xl avatar-rounded",
            listEvaluate[0].onlineoffline === 1 && "online",
          )}
        >
          <img
            src={"https://system.view360.vn/" + listEvaluate[0].avatar}
            alt="avatar"
          />
        </span>
        <p className="mb-1 fs-18 fw-5 text-primary mb-0">
          {listEvaluate[0].hoten}
        </p>
        <p className="fs-14 mb-0">
          Đánh giá: {evaluateUser.current}%{" "}
          <span>
            {evaluateUser.current >= 99 && (
              <span className="text-success fw-5">Hoàn hảo</span>
            )}
            {evaluateUser.current >= 90 && evaluateUser.current < 99 && (
              <span className="text-success fw-5">Tốt</span>
            )}
            {evaluateUser.current >= 80 && evaluateUser.current < 90 && (
              <span className="text-primary fw-5">Khá</span>
            )}
            {evaluateUser.current >= 50 && evaluateUser.current < 80 && (
              <span className="">
                <span className="text-warning fw-5">Trung bình</span>
                <Tippy content="Bạn cần hoàn thành các nhiệm vụ được giao để tăng kỹ năng">
                  <span className="ms-1 text-warning">
                    <IoAlertCircle className="fs-15" />
                  </span>
                </Tippy>
              </span>
            )}
            {evaluateUser.current < 50 && (
              <span className="">
                <span className="text-danger fw-5">Báo động</span>
                <Tippy content="Hãy cố gắng hoàn thành các nhiệm vụ được giao để tăng kỹ năng">
                  <span className="ms-1 text-danger">
                    <IoAlertCircle className="fs-15" />
                  </span>
                </Tippy>
              </span>
            )}
          </span>
        </p>
        <p className="fs-14 text-primary fs-14">
          Bộ phận: {listEvaluate[0].bo_phan}
        </p>
      </div>
      {/* List evaluate */}
      <div className="mt-3">
        {listEvaluate.map((item, index) => {
          let evaluate = (item.success_job / item.total_job) * 100
          let formatEvaluate = Number.isInteger(evaluate)
            ? evaluate
            : evaluate.toFixed(1)
          if (formatEvaluate >= 90) {
            formatEvaluate = (
              <div className="text-success d-flex align-items-center gap-1 progress-animate">
                <div className="col-3 fw-5">{formatEvaluate}%</div>
                <div
                  className="progress col-5"
                  role="progressbar"
                  aria-valuenow="0"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  <div
                    className="progress-bar bg-success progress-bar-striped progress-bar-animated"
                    style={{ width: formatEvaluate + "%" }}
                  ></div>
                </div>
                <div className="fs-12 fw-5">Tốt</div>
              </div>
            )
          } else if (formatEvaluate > 75 && formatEvaluate < 90) {
            formatEvaluate = (
              <div className="text-info d-flex align-items-center gap-1 progress-animate">
                <div className="col-3 fw-5">{formatEvaluate}%</div>
                <div
                  className="progress col-5"
                  role="progressbar"
                  aria-valuenow="0"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  <div
                    className="progress-bar bg-info progress-bar-striped progress-bar-animated"
                    style={{ width: formatEvaluate + "%" }}
                  ></div>
                </div>
                <div className="fs-12 fw-5">Ổn</div>
              </div>
            )
          } else if (formatEvaluate > 60 && formatEvaluate <= 75) {
            formatEvaluate = (
              <div className="text-warning d-flex align-items-center gap-1 progress-animate">
                <div className="col-3 fw-5">{formatEvaluate}%</div>
                <div
                  className="progress col-5"
                  role="progressbar"
                  aria-valuenow="0"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  <div
                    className="progress-bar bg-warning progress-bar-striped progress-bar-animated"
                    style={{ width: formatEvaluate + "%" }}
                  ></div>
                </div>
                <div className="fs-12 fw-5">T.Bình</div>
              </div>
            )
          } else {
            formatEvaluate = (
              <div className="text-danger d-flex align-items-center gap-1 progress-animate">
                <div className="col-3 fw-5">{formatEvaluate}%</div>
                <div
                  className="progress col-5"
                  role="progressbar"
                  aria-valuenow="0"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  <div
                    className="progress-bar bg-danger progress-bar-striped progress-bar-animated"
                    style={{ width: formatEvaluate + "%" }}
                  ></div>
                </div>
                <div className="fs-12 fw-5">Kém</div>
              </div>
            )
          }
          return (
            <div className="d-flex gap-4 col-12 mb-2" key={index}>
              <span className="fs-15 text-muted col-4 text-end">
                {item.loaicongviec}
              </span>
              <div className="fs-16 col-7">{formatEvaluate}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
