import { useState, useEffect, useRef, useContext } from "react";
import io from "socket.io-client"
import classNames from "classnames/bind";
import styles from "../Dashboard.module.scss"
import CheckPCMobile from "../../../handle-reuses/reuses/check.pc.mobile"
import PagePayHisMobile from "./Mobile";
import PagePayHisPC from "./PC";
import AppContext from "../../../handle-reuses/Contexts/CreateContexts";
const cx = classNames.bind(styles);

function ThanhToan({active}) {
    const {socketioBDS} = useContext(AppContext)


  return (
    <div
      className="main-content app-content"
      style={{ marginInlineStart: active && "6rem" }}
    >
      <div className="container-fluid">
        <div className="main-mail-container p-2">
          <CheckPCMobile>
            {(isMobile) =>
              isMobile ? (
                <PagePayHisMobile socketio={socketioBDS} />
              ) : (
                <PagePayHisPC socketio={socketioBDS} />
              )
            }
          </CheckPCMobile>
        </div>
      </div>
    </div>
  )
}

export default ThanhToan