import classNames from "classnames/bind"

import styles from "../Sanpham.module.scss";
import ListProduct from "../Component/ListProduct"
import { LoadingSpinner } from "../../../../Components/Loading"
const cx = classNames.bind(styles);

function PageProductMobile({ loading, dataEstates, socketio, result }) {
  return (
    <div className={cx("mt-3")}>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <ListProduct dataEstates={dataEstates} socketio={socketio} result={result}/>
      )}
    </div>
  )
}

export default PageProductMobile