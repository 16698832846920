import moment from "moment"
import { useEffect, useRef } from "react"
import { useForm } from "react-hook-form"

function FormTangca({ socketio, dataUser, result }) {
    const { register, handleSubmit, formState: {errors}, reset } = useForm()
    const checkHandle = useRef()
    // Quản lý submit.
    const onSubmit = (object) => {
        object.user_id = dataUser[0].id_user
        // Set thời gian tăng ca.
        let dateStart = moment(object.date_start).format("YYYY-MM-DDTHH:mm:ss")
        let dateEnd = moment(object.date_end).format("YYYY-MM-DDTHH:mm:ss")
        let sophuttangca = (new Date(dateEnd).getTime() - new Date(dateStart).getTime()) / (60 * 1000);
      object.sophut_tangca = sophuttangca
      const paramsCreate = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: object
      }
      socketio.emit("client-create-tangca", paramsCreate)
      socketio.on("server-create-tangca", (data) => {
        if (data === "success") {
          result(true)
          checkHandle.current = object
          alert("Bạn đã nộp đơn tăng ca thành công, cần chờ duyệt.")
        }
      })

    }
  useEffect(() => {
    return () => {
      socketio.off("client-create-tangca")
      socketio.off("server-create-tangca")
    }
  }, [checkHandle.current])
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="col-xl-12 col-md-12">
          <div className="row">
            <div className="form-floating mb-3 col-12">
              <input
                type="text"
                className="form-control"
                placeholder="Tên input"
                {...register("title", {required: true})}
              />
              <label className="fs-15 ms-2 text-muted">Tiêu đề</label>
              {errors.title && (
                <span className="text-danger fs-14">
                  *Bạn cần nhập tiêu đề.
                </span>
              )}
            </div>
            <div className="form-floating mb-4 mt-3">
              <textarea
                className="form-control"
                placeholder="Nhập ghi chú"
                style={{ height: "100px" }}
                {...register("content", {required: true})}
              ></textarea>
              <label className="fs-15 ms-2 text-muted">Ghi chú</label>
              {errors.content && (
                <span className="text-danger fs-14">
                  *Bạn cần nhập ghi chú.
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="col-xl-12 col-md-12">
          <div className="row">
            <div className="form-floating mb-3 col-6">
              <input
                type="datetime-local"
                className="form-control"
                placeholder="Tên input"
                {...register("date_start", { required: true })}
              />
              <label className="fs-15 ms-2 text-muted">Bắt đầu tăng ca</label>
              {errors.date_start && (
                <span className="text-danger fs-14">
                  *Bạn cần chọn thời hạn bắt đầu.
                </span>
              )}
            </div>
            <div className="form-floating mb-3 col-6">
              <input
                type="datetime-local"
                className="form-control"
                placeholder="Tên input"
                {...register("date_end", { required: true })}
              />
              <label className="fs-15 ms-2 text-muted">Kết thúc tăng ca</label>
              {errors.date_end && (
                <span className="text-danger fs-14">
                  *Bạn cần chọn thời hạn kết thúc.
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="px-4 py-3 border-top border-block-start-dashed d-flex justify-content-center">
          <button className="btn btn-primary-light m-1" type="submit">
            Nộp đơn tăng ca
          </button>
        </div>
      </form>
    )
}
export default FormTangca