import { useState } from "react"
import classNames from "classnames/bind"
import styles from "../../Job.module.scss";
import StatisticalReport from "./StatisticalReport";
import ChartExpenseYear from "./ChartExpenseYear";
import ChartRevenueYear from "./ChartRevenueYear";
import ChartRevenueExpenseYear from "./ChartRevenueExpenseYear";
const cx = classNames.bind(styles);

function OverviewReportMobile({ ...props }) {
  const {
    socketio,
    dataUser,
    object,
    listContractMonth,
    dataCateRevenueMonth,
    dataCateExpenseMonth,
    dataTaisan,
    dataTotalRevenue,
    dataTotalExpense,
    dataRevenueYear,
    dataExpenseYear,
    dataCateRevenueYear,
    dataCateExpenseYear,
  } = props

  // Quản lý danh mục.
  const [activeCate, setActiveCate] = useState("month-report")
  const handleActiveCate = (cate) => {
    setActiveCate(cate)
  }

    return (
      <div>
        {/* category */}
        <div className="wrapper_ground mt-3">
          <button
            className={cx(
              "border-0 rounded-1 list_items badge bg-success py-2",
              activeCate === "month-report"
                ? "bg-success"
                : "bg-light text-dark op-6",
            )}
            onClick={() => handleActiveCate("month-report")}
          >
            Báo cáo tháng
          </button>
          <button
            className={cx(
              "border-0 rounded-1 list_items badge bg-success py-2",
              activeCate === "year-report"
                ? "bg-success"
                : "bg-light text-dark op-6",
            )}
            onClick={() => handleActiveCate("year-report")}
          >
            Báo cáo năm
          </button>
        </div>
        {/* List content */}
        <div className="mt-3">
          {/* Báo cáo tháng */}
          {activeCate === "month-report" && (
            <StatisticalReport
              socketio={socketio}
              dataUser={dataUser}
              object={object}
              listContractMonth={listContractMonth}
              dataCateRevenueMonth={dataCateRevenueMonth}
              dataCateExpenseMonth={dataCateExpenseMonth}
              dataTaisan={dataTaisan}
              dataTotalRevenue={dataTotalRevenue}
              dataTotalExpense={dataTotalExpense}
            />
          )}
          {/* Báo cáo năm */}
          {activeCate === "year-report" && (
            <>
              <div className="row">
                {dataRevenueYear.length > 0 && dataExpenseYear.length > 0 ? (
                  <ChartRevenueExpenseYear
                    socketio={socketio}
                    dataUser={dataUser}
                    dataRevenueYear={dataRevenueYear}
                    dataExpenseYear={dataExpenseYear}
                  />
                ) : (
                  <div className="col-xxl-6 col-xl-6 col-12">
                    <div className="card custom-card">
                      <div className="card-body" style={{ height: "400px" }}>
                        Chưa đủ dữ liệu để phân tích
                      </div>
                    </div>
                  </div>
                )}

                <div className="col-xxl-3 col-xl-3">
                  {dataCateRevenueYear.length > 0 ? (
                    <ChartRevenueYear
                      dataCateRevenueYear={dataCateRevenueYear}
                    />
                  ) : (
                    <div className="card custom-card">
                      <div className="card-body" style={{ height: "400px" }}>
                        Chưa đủ dữ liệu để phân tích
                      </div>
                    </div>
                  )}
                </div>

                {/* Danh sách chi phí năm */}
                <div className="col-xxl-3 col-xl-3 col-12">
                  {dataCateExpenseYear.length > 0 ? (
                    <ChartExpenseYear
                      dataCateExpenseYear={dataCateExpenseYear}
                    />
                  ) : (
                    <div className="card custom-card">
                      <div className="card-body" style={{ height: "400px" }}>
                        Chưa đủ dữ liệu để phân tích
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    )
}

export default OverviewReportMobile