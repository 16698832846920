import { useContext } from "react"
import PageDashboard2 from "./PageDashboard-2"
import AppContext from "../../../handle-reuses/Contexts/CreateContexts"

function HotroPage() {
  const { dataUser } = useContext(AppContext)
  if (dataUser[0].goidichvu_user !== 1) {
    return (
      <div className="text-danger mt-3 text-center fs-15">
        Bạn không có quyền truy cập vào trang này.
      </div>
    )
  }
  return <PageDashboard2 />
}

export default HotroPage