import { useContext, useEffect, useRef, useState } from "react"
import classNames from "classnames/bind";
import { useForm } from "react-hook-form" 
import { BsDot } from "react-icons/bs"

import { handleFormatPrice } from "../../../handle-reuses/reuses";
import AppContext from "../../../handle-reuses/Contexts/CreateContexts";
import styles from "../DataSale.module.scss"
import moment from "moment/moment";
import ListSuggestTuyenduong from "./ListSuggestTuyenduong";
import ListSuggestPhuongxa from "./ListSuggestPhuongxa";
import ListSuggestQuanhuyen from "./ListSuggestQuanhuyen";
const cx = classNames.bind(styles);

function FormAddDataSale({resultStatus}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()
  const { socketio, dataUser } = useContext(AppContext)
  // Xử lý format price.
  const [formatPrice, setFormatPrice] = useState("")
  const showPrice = useRef()
  const [formatPriceM, setFormatPriceM] = useState("")

  const handleFormatPriceForm = (e) => {
    let price_ = e.target.value.replace(/[.,]/g, "")
    let formatPrice_ = Number(price_).toLocaleString().replace(/[.]/g, ",")
    setFormatPrice(formatPrice_)
    // Hiển thị dạng chữ.
    showPrice.current = handleFormatPrice(Number(price_))
    setFormatPriceM("")
  }

  // Hiển thị trường nhập giá trên m2.
  const [showInputM, setShowInputM] = useState(false)
  const handleShowGiaMetvuong = (key) => {
    if (key === "giam2") {
      setShowInputM(true)
    } else {
      setShowInputM(false)
    }
  }
  // Xử lý format giá trên m2
  const handleFormatPriceFormM = (e) => {
    let format_ = e.target.value.replace(/[.,]/g, "")
    let format__ = Number(format_).toLocaleString().replace(/[.]/g, ",")
    setFormatPriceM(format__)
    setFormatPrice("")
  }

  /** Xử lý gợi ý tuyenduong. */
  const [suggestTuyenduong, setSuggestTuyenduong] = useState("")
  const [dataTuyenduong, setDataTuyenduong] = useState([])
  const handleGetValueTuyenduong = (e) => {
    setSuggestTuyenduong(e.target.value)
  }
  useEffect(() => {
    if (suggestTuyenduong !== "") {
      // xử lý gợi ý.
      const paramsTuyenduong = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: {
          tuyenduong: suggestTuyenduong,
        },
      }
      socketio.emit("client-get-data-tuyenduong", paramsTuyenduong)
      socketio.on("server-get-data-tuyenduong", (dataSuggest) => {
        setDataTuyenduong([...dataSuggest])
      })
      return () => {
        socketio.off("client-get-data-tuyenduong")
        socketio.off("server-get-data-tuyenduong")
      }
    }
  }, [suggestTuyenduong])
  // Set value tuyenduong.
  const handleSetValueTuyenduong = (key) => {
    setSuggestTuyenduong(key)
  }
  /** *Xử lý gợi ý tuyenduong. */

  /** Quản lý gợi ý phuongxa */
  const [suggestPhuongxa, setSuggestPhuongxa] = useState("")
  const handleGetValuePhuongxa = (e) => {
    setSuggestPhuongxa(e.target.value)
  }
  const handleSetValuePhuongxa = (key) => {
    setSuggestPhuongxa(key)
  }

  /** Quản lý gợi ý phuongxa */

  /** Quản lý gợi ý Quanhuyen */
  const [suggestQuanhuyen, setSuggestQuanhuyen] = useState("")
  const handleGetValueQuanhuyen = (e) => {
    setSuggestQuanhuyen(e.target.value)
  }
  const handleSetValueQuanhuyen = (key) => {
    setSuggestQuanhuyen(key)
  }
  /** Quản lý gợi ý Quanhuyen */

  /** Quản lý kiểm tra data giống nhau */
  const [dataGiongnhau, setDataGiongnhau] = useState([])
  const handleGetValuePhone = (e) => {
    // Tìm lọc sản phẩm giống nhau.
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        tuyenduong: suggestTuyenduong,
        quanhuyen: suggestQuanhuyen,
        sodienthoai: e.target.value.replace(/[., ]/g, ""),
      },
    }
    socketio.emit("client-check-data-sale-giongnhau-sanpham", params)
    socketio.on("server-check-data-sale-giongnhau-sanpham", (data) => {
      setDataGiongnhau([...data])
    })
  }
  useEffect(() => {
    return () => {
      socketio.off("client-check-data-sale-giongnhau-sanpham")
      socketio.off("server-check-data-sale-giongnhau-sanpham")
    }
  }, [dataGiongnhau])

  /** *Quản lý kiểm tra data giống nhau */

  const checkAddSuccess = useRef()
  const handleOnSubmit = (data) => {
    data.tacgia = dataUser[0].id_user
    if (formatPriceM) {
      let xacdinh =
        Number(data.dtmb_mt) * Number(data.giam2.replace(/[,.]/g, ""))
      data.giasanpham = Number(xacdinh).toLocaleString().replace(/[.]/g, ",")
    }
    delete data.giam2
    data.tuyenduong = suggestTuyenduong
    data.phuongxa = suggestPhuongxa
    data.quanhuyen = suggestQuanhuyen
    data.trucngo = data.trucngo.replace(/[,]/g, ".")
    data.dtmb_mt = data.dtmb_mt.replace(/[,]/g, ".")
    data.mattien = data.mattien.replace(/[,]/g, ".")
    data.sotang = data.sotang.replace(/[,]/g, ".")
    data.dt_xd = data.dt_xd.replace(/[,]/g, ".")
    data.hoten = data.hoten ? data.hoten : "Liên hệ"

    // Xử lý thêm mới data.
    const paramsObject = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: data,
    }
    socketio.emit("client-create-data-sale-sanpham", paramsObject)
    alert("Thêm khách hàng thành công.")
    setTimeout(() => {
      reset()
      resultStatus(true)
      checkAddSuccess.current = data
    }, 300)
  }

  useEffect(() => {
    return () => {
      socketio.off("client-create-data-sale-sanpham")
    }
  }, [checkAddSuccess.current])

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      {/* Địa chỉ */}
      <div>
        <h6 className="text-muted">Địa chỉ</h6>
      </div>
      <div className="col-xl-12 col-md-12">
        <div className="form-floating mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Tên input"
            {...register("diachi", { required: true })}
          />
          <label className="fs-15 text-muted">
            Địa chỉ <span className="text-danger">*</span>
          </label>
          {errors.diachi && (
            <span className="fs-12 text-danger">Bạn chưa nhập địa chỉ</span>
          )}
        </div>
      </div>
      <div className="col-xl-12 col-md-12">
        <div className="row">
          <div className="form-floating mb-3 col-6">
            <input
              type="text"
              className="form-control"
              placeholder="Tên input"
              {...register("tuyenduong", { required: true })}
              value={suggestTuyenduong}
              onChange={handleGetValueTuyenduong}
            />
            <label className="fs-15 ms-2 text-muted">
              Tuyến đường <span className="text-danger">*</span>
            </label>
            {errors.tuyenduong && (
              <span className="fs-12 text-danger">
                Bạn chưa nhập tuyến đường
              </span>
            )}
            {/* Gợi ý */}
            {dataTuyenduong.length > 0 && (
              <ListSuggestTuyenduong
                dataList={dataTuyenduong}
                valueInput={suggestTuyenduong}
                result={handleSetValueTuyenduong}
              />
            )}
          </div>
          <div className="form-floating mb-3 col-6">
            <input
              type="text"
              className="form-control"
              placeholder="Tên input"
              {...register("phuongxa")}
              value={suggestPhuongxa}
              onChange={handleGetValuePhuongxa}
            />
            <label className="fs-15 ms-2 text-muted">Phường/Xã</label>
            {/* Gợi ý */}
            {dataTuyenduong.length > 0 && (
              <ListSuggestPhuongxa
                dataList={dataTuyenduong}
                valueInput={suggestPhuongxa}
                result={handleSetValuePhuongxa}
              />
            )}
          </div>
        </div>
      </div>
      <div className="col-xl-12 col-md-12">
        <div className="row">
          <div className="form-floating mb-3 col-6">
            <input
              type="text"
              className="form-control"
              placeholder="Tên input"
              {...register("quanhuyen", { required: true })}
              value={suggestQuanhuyen}
              onChange={handleGetValueQuanhuyen}
            />
            <label className="fs-15 ms-2 text-muted">
              Quận huyện <span className="text-danger">*</span>
            </label>
            {errors.quanhuyen && (
              <span className="fs-12 text-danger">
                Bạn chưa nhập quận huyện
              </span>
            )}
            {/* Gợi ý */}
            {dataTuyenduong.length > 0 && (
              <ListSuggestQuanhuyen
                dataList={dataTuyenduong}
                valueInput={suggestQuanhuyen}
                result={handleSetValueQuanhuyen}
              />
            )}
          </div>
          <div className="form-floating mb-3 col-6">
            <input
              type="text"
              defaultValue="Hải phòng"
              className="form-control"
              placeholder="Tên input"
              {...register("thanhpho", { required: true })}
            />
            <label className="fs-15 ms-2 text-muted">
              Thành phố <span className="text-danger">*</span>
            </label>
            {errors.thanhpho && (
              <span className="fs-12 text-danger">Bạn chưa nhập thành phố</span>
            )}
          </div>
        </div>
      </div>
      {/* Thông tin */}
      <div className="border-top border-block-start-dashed mt-3 mb-3"></div>
      <div>
        <h6 className="text-muted">Thông tin</h6>
      </div>
      <div className="col-xl-12 col-md-12">
        <div className="row">
          <div className="form-floating mb-3 col-6">
            <input
              type="number"
              step="0.1"
              className="form-control"
              placeholder="Tên input"
              {...register("trucngo")}
            />
            <label className="fs-15 ms-2 text-muted">Trục ngõ</label>
          </div>
          <div className="form-floating mb-3 col-6">
            <input
              type="text"
              className="form-control"
              placeholder="Tên input"
              {...register("dtmb_mt")}
            />
            <label className="fs-15 ms-2 text-muted">Diện tích</label>
          </div>
        </div>
      </div>
      <div className="col-xl-12 col-md-12">
        <div className="row">
          <div className="form-floating mb-3 col-6">
            <input
              type="text"
              className="form-control"
              placeholder="Tên input"
              {...register("mattien")}
            />
            <label className="fs-15 ms-2 text-muted">Mặt tiền</label>
          </div>
          <div className="form-floating mb-3 col-6">
            <input
              type="text"
              className="form-control"
              placeholder="Tên input"
              {...register("sotang")}
            />
            <label className="fs-15 ms-2 text-muted">Số tầng</label>
          </div>
        </div>
      </div>
      <div className="col-xl-12 col-md-12">
        <div className="row">
          <div className="form-floating mb-3 col-6">
            <input
              type="text"
              className="form-control"
              placeholder="Tên input"
              {...register("dt_xd")}
            />
            <label className="fs-15 ms-2 text-muted">Diện tích XD</label>
          </div>
          <div className="form-floating mb-3 col-6">
            <select className="form-select" {...register("huongnha")}>
              <option value="">Chọn hướng</option>
              <option value="Đông">Đông</option>
              <option value="Nam">Nam</option>
              <option value="Bắc">Bắc</option>
              <option value="Tây">Tây</option>
              <option value="Đông nam">Đông nam</option>
              <option value="Tây nam">Tây nam</option>
              <option value="Đông bắc">Đông bắc</option>
              <option value="Tây bắc">Tây bắc</option>
            </select>
            <label className="fs-15 ms-2">Hướng nhà</label>
          </div>
        </div>
      </div>
      <div className="col-xl-12 col-md-12">
        <div className="row">
          <div className="form-floating mb-3 col-6">
            <select className="form-select" {...register("phaply")}>
              <option value="">Chọn pháp lý</option>
              <option value="Sổ hồng">Sổ hồng</option>
              {/* <option value="Sổ đỏ">Sổ đỏ</option> */}
              <option value="Trích đo">Trích đo</option>
              <option value="Chủ đầu tư">Chủ đầu tư</option>
              <option value="Viết tay">Viết tay</option>
              <option value="Hợp lệ">Hợp lệ</option>
              <option value="Loại khác">Loại khác</option>
            </select>
            <label className="fs-15 ms-2 text-muted">Giấy tờ</label>
          </div>
          <div className="form-floating mb-3 col-6">
            <select className="form-select" {...register("loaibds")}>
              <option value="">Chọn loại BDS</option>
              <option value="Bán nhà">Bán nhà</option>
              <option value="Bán đất nền">Bán đất nền</option>
              <option value="Bán đất TDC">Bán đất TDC</option>
              <option value="Bán căn hộ">Bán căn hộ</option>
              <option value="Bán chung cư">Bán chung cư</option>
              <option value="Cho thuê nhà">Cho thuê nhà</option>
              <option value="Cho thuê căn hộ">Cho thuê căn hộ</option>
              <option value="Cho thuê phòng trọ">Cho thuê phòng trọ</option>
              <option value="Cho thuê đất">Cho thuê đất</option>
              <option value="Cho thuê mặt bằng">Cho thuê mặt bằng</option>
              <option value="Sang nhượng">Sang nhượng</option>
            </select>
            <label className="fs-15 ms-2 text-muted">Loại BĐS</label>
          </div>
        </div>
      </div>
      <div className="col-xl-12 col-md-12">
        <div className="mb-2 d-flex justify-content-end align-items-center">
          <button
            type="button"
            className={cx(
              "border-0 rounded-1 me-2 fs-14 px-3",
              showInputM ? "active" : "",
            )}
            onClick={() => handleShowGiaMetvuong("giam2")}
          >
            Giá/m<sup>2</sup>
          </button>
          <button
            type="button"
            className={cx(
              "border-0 rounded-1 fs-14 px-3",
              !showInputM ? "active" : "",
            )}
            onClick={() => handleShowGiaMetvuong("giatong")}
          >
            Giá tổng
          </button>
        </div>
        <div
          className="form-floating mb-3 col-12"
          style={{ display: showInputM ? "block" : "none" }}
        >
          <input
            type="text"
            className="form-control"
            value={formatPriceM}
            placeholder="Tên input"
            {...register("giam2")}
            onChange={handleFormatPriceFormM}
          />
          <label className="fs-15 text-muted">
            Giá/m<sup>2</sup>
          </label>
        </div>
        <div
          className="form-floating mb-3 col-12"
          style={{ display: showInputM ? "none" : "block" }}
        >
          <input
            type="text"
            className="form-control"
            value={formatPrice}
            placeholder="Tên input"
            {...register("giasanpham")}
            onChange={handleFormatPriceForm}
          />
          <label className="fs-15 text-muted">
            Giá sản phẩm{" "}
            <span className="text-danger">{showPrice.current}</span>
          </label>
        </div>
      </div>
      {/* Liên hệ */}
      <div className="border-top border-block-start-dashed mt-3 mb-3"></div>
      <div>
        <h6 className="text-muted">Liên hệ</h6>
      </div>
      <div className="col-xl-12 col-md-12">
        <div className="row">
          <div className="form-floating mb-3 col-6">
            <input
              type="text"
              className="form-control"
              placeholder="Tên input"
              {...register("hoten")}
            />
            <label className="fs-15 ms-2 text-muted">
              Họ tên
            </label>
            {/* {errors.hoten && (
              <span className="fs-12 text-danger">
                Bạn chưa nhập tên liên hệ
              </span>
            )} */}
          </div>
          <div className="form-floating mb-3 col-6">
            <input
              type="text"
              className="form-control"
              placeholder="Tên input"
              {...register("sodienthoai", { required: true })}
              onChange={handleGetValuePhone}
            />
            <label className="fs-15 ms-2 text-muted">
              Số điện thoại <span className="text-danger">*</span>
            </label>
            {errors.sodienthoai && (
              <span className="fs-12 text-danger">
                Bạn chưa nhập số điện thoại
              </span>
            )}
          </div>
        </div>
      </div>
      {/* Data giống nhau */}
      {dataGiongnhau.length > 0 && <ListDataGiongnhau data={dataGiongnhau} />}
      {/* Button */}
      <div className="px-4 text-center py-3 border-top border-block-start-dashed d-sm-flex justify-content-center">
        <button className="btn btn-primary-light m-1" type="submit">
          Thêm mới
        </button>
      </div>
    </form>
  )
}

export default FormAddDataSale




 

 

// Hiển thị list data giống nhau.
function ListDataGiongnhau({data}) {
  return (
    <div className="card custom-card">
      <div className="text-danger card-body text-center">
        Đang có <span className="fs-21">{data.length}</span> sản phẩm tương tự
        nhau, bạn hãy kiểm tra
      </div>
      {/* Danh sách */}
      <div className="px-5 mb-5">
        <ul className="list-unstyled mb-0 crm-recent-activity">
          {data.map((item, index) => {
            return (
              <li className="crm-recent-activity-content" key={index}>
                <div className="d-flex align-items-top">
                  <div className="me-3">
                    <span className="avatar avatar-xs bg-primary-transparent avatar-rounded">
                      <img src={item.avatar_author} alt="avatar"/>
                    </span>
                  </div>
                  <div className="crm-timeline-content fs-15">
                    <span className="fw-semibold">
                      {item.tuyenduong + ", " + item.quanhuyen}
                    </span>
                    <p className="mb-0">{item.hoten + ": " + item.sodienthoai}</p>
                    <p className="mb-0">{item.dtmb_mt && (
                      <>
                        {item.dtmb_mt}m<sup>2</sup>
                        <BsDot />
                      </>
                    )}
                      {item.huongnha && (
                        <>
                          {item.huongnha}
                          <BsDot />
                        </>
                      )}
                      {handleFormatPrice(Number(item.giasanpham.replace(/[.,]/g, "")))}</p>
                  </div>
                  <div className="flex-fill text-end">
                    <span className="d-block text-muted fs-15 op-7">{moment(item.ngaycapnhat).format("DD-MM-YYYY HH:mm")}</span>
                  </div>
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}