import moment from "moment"
import { useEffect, useRef, useState } from "react"
import { BsDot } from "react-icons/bs"
import { GoDotFill } from "react-icons/go"
import { IoTrashOutline } from "react-icons/io5"
import { MdModeEditOutline } from "react-icons/md"
import ModalReact from "../../../../Components/Modal/ModalReact"
import FormEditJob from "./FormEditJob"
import { SlOptionsVertical } from "react-icons/sl"

function ListJobPersonnel({ userID, listJob, dataUser, socketio }) {
  // Quản lý list job.
  const [listJobDetail, setListJobDetail] = useState([...listJob])
  const listJobUpdate = useRef([...listJob])

  // Quản lý edit data.
  const [modalEdit, setModalEdit] = useState(false)
  const dataEdit = useRef([])
  const handleEditDataJob = (id) => {
    setModalEdit(true)
    let filterData = listJob.filter((item) => {
      return item.id === id
    });
    dataEdit.current = [...filterData]
  }
  const handleCloseModalEditDataJob = () => {
    setModalEdit(false)
    dataEdit.current = []
  }

  // Quản lý delete job.
  const handleDeleteDataJob = (id) => {
    let checkDelete = window.confirm("Bạn muốn xóa công việc này chứ?.")
    if (checkDelete) {
      let paramsDelete = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: { id: id, delete_job: 1 },
      }
      socketio.emit("client-edit-row-delete-job", paramsDelete)
      alert("Bạn đã xóa thành công.")
    }
  }
  // Xử lý nộp nhiệm vụ.
  const changeStatus = useRef()
  const handleNopNhiemvu = (id) => {
    let check = window.confirm("Bạn đã hoàn tất nhiệm vụ này ?")
    if (check) {
      let paramsReceiverStatus = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: {
          id: id,
          receiver_status: 1
        }
      }
      socketio.emit("client-edit-row-receiver-status-job", paramsReceiverStatus)
      changeStatus.current = "nop-nhiem-vu"
    }
  }
  // Xử lý nhiệm vụ hoàn thành.
  const handleCompletedJob = (id) => {
    let check = window.confirm("Xác nhận nhiệm vụ đã hoàn thành ?")
    if (check) {
      let paramsSenderStatus = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: {
          id: id,
          sender_status: 1,
        },
      }
      socketio.emit("client-edit-row-sender-status-job", paramsSenderStatus);
      changeStatus.current = "nhiem-vu-hoan-tat"
    }
  }
  // Xử lý nhiệm vụ thất bại.
  const handleFailureJob = (id) => {
    let check = window.confirm("Xác nhận nhiệm vụ đã hoàn thành ?")
    if (check) {
      let paramsSenderStatus = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: {
          id: id,
          sender_status: -1,
        },
      }
      socketio.emit("client-edit-row-sender-status-job", paramsSenderStatus)
      changeStatus.current = "nhiem-vu-that-bai"
    }
  }

  // Quản lý ẩn socketio.
  useEffect(() => {
    socketio.on("server-update-data-edit-job", (dataUpdate) => {
      let dataUpdateJob = [...listJobUpdate.current]
      let index = dataUpdateJob.findIndex(
        (item) => item.id === dataUpdate[0].id,
      );
      // update lại dữ liệu
      dataUpdateJob[index] = dataUpdate[0]
      setListJobDetail([...dataUpdateJob])
      listJobUpdate.current = [...dataUpdateJob]
    })

    return () => {
      socketio.off("client-edit-row-receiver-status-job")
      socketio.off("client-edit-row-sender-status-job")
      // socketio.off("server-update-data-edit-job")
    }
  }, [changeStatus.current]);
  return (
    <div className="card-body">
      <ul className="list-unstyled timeline-widget mb-0 my-3">
        {listJobDetail.map((item, index) => {
          // Set thời gian nộp nhiệm vụ.
          let dateEnd = new Date(item.date_end).getTime()
          let currentTime = new Date().getTime()
          // Set số ngày để hoàn thành nhiệm vụ.
          let dateStart_ = moment(item.date_start)
          let dateEnd_ = moment(item.date_end);
          let diffDays = dateEnd_.diff(dateStart_, "days");
          if (diffDays < 1) {
            diffDays = dateEnd_.diff(dateStart_, "hours") + " giờ"
          } else {
            diffDays = diffDays + " ngày"
          }
          // set thời gian còn lại.
          let currentTime_ = moment()
          let motGio = 60 * 60 * 1000; // 1 giờ
          let motNgay = 24 * 60 * 60 * 1000; // 1 ngày.
          let dateTimeEnd = new Date(item.date_end).getTime()
          let thoigianConlai = dateTimeEnd - currentTime
          let diffTimeConlai = 0;
          if (currentTime <= dateTimeEnd) {
            if (thoigianConlai > motNgay) {
              // Nếu thời gian còn lại lớn hơn 1 ngày.
              diffTimeConlai = dateEnd_.diff(currentTime_, "days") + " ngày"
            } else {
              // Nếu thời gian còn lại lớn hơn 1 giờ
              if (thoigianConlai > motGio && thoigianConlai <= motNgay) {
                diffTimeConlai = dateEnd_.diff(currentTime, "hours") + " giờ"
              }
              // Nếu thời gian còn lại nhỏ hơn 1 giờ
              if (thoigianConlai <= motGio && thoigianConlai > 0) {
                diffTimeConlai = dateEnd_.diff(currentTime, "minutes") + " phút"
              }
            }
          } else {
            diffTimeConlai = undefined
          } 

          return (
            <li className="timeline-widget-list" key={index}>
              <div className="d-flex align-items-top">
                <div className="me-3 text-center" style={{ width: "45px" }}>
                  <span className="d-block fs-15 fw-semibold text-primary">
                    {moment(item.date_start).format("HH:mm")}
                  </span>
                  <span className="d-block fs-14 text-danger">
                    {moment(item.date_end).format("HH:mm")}
                  </span>
                </div>
                <div className="ms-4 d-flex flex-wrap flex-fill align-items-top justify-content-between">
                  <div className="alert alert-dark">
                    <div className="mb-1 text-truncate timeline-widget-content text-wrap fw-5 fs-16">
                      {item.title}
                    </div>
                    <div
                      style={{ whiteSpace: "pre-wrap" }}
                      className="mb-0 fs-17"
                    >
                      {item.content}
                    </div>
                    <div className="mb-1 mt-3 border-top border-block-start-dashed bg-warning"></div>

                    <div className="wrapper_ground mt-1 fs-15">
                      <span className="list_items">
                        Từ{" "}
                        <span className="text-primary">
                          {moment(item.date_start).format("DD/MM/YYYY HH:mm")}
                        </span>
                      </span>
                      <span>
                        đến{" "}
                        <span className="text-primary">
                          {moment(item.date_end).format("DD/MM/YYYY HH:mm")}
                        </span>
                      </span>
                    </div>
                    {/* Số ngày hoàn thành nhiệm vụ */}
                    <div className="text-danger">
                      <span className="fs-14 me-1">Hoàn thành trong</span>
                      <span className="fs-15 fw-5">{diffDays}</span>
                      <BsDot />
                      {diffTimeConlai && (
                        <span className="text-primary fw-5">
                          còn lại {diffTimeConlai}
                        </span>
                      )}
                      {!diffTimeConlai && (
                        <span className="text-danger fw-5">Hết hạn</span>
                      )}
                    </div>
                    {/* Giao việc bởi */}
                    <div className="mb-1 mt-3 border-top border-block-start-dashed bg-warning"></div>
                    <div className="mt-1">
                      <span className="d-flex align-items-center justify-content-end">
                        <span className="me-1">Giao việc bởi:</span>
                        <span className="me-1 fw-5">{item.sender_name}</span>
                        <span className="avatar avatar-xs me-2 avatar-rounded">
                          <img
                            src={
                              "https://system.view360.vn/" + item.sender_avatar
                            }
                            alt="img"
                          />
                        </span>
                      </span>
                    </div>

                    <div className="mb-2 mt-1 border-top border-block-start-dashed bg-warning"></div>
                    <div className="d-flex gap-2 justify-content-between align-items-center">
                      {/* Nộp job */}
                      {item.receiver === dataUser[0].id_user &&
                        item.receiver_status === 0 &&
                        item.sender_status === 0 &&
                        dateEnd >= currentTime && (
                          <button
                            className="badge bg-secondary border-0 fs-12"
                            onClick={() => handleNopNhiemvu(item.id)}
                          >
                            Nộp nhiệm vụ
                          </button>
                        )}
                      {item.receiver_status === 0 &&
                        item.sender_status === 0 &&
                        dateEnd < currentTime && (
                          <span className="badge bg-danger border-0 fs-12">
                            Thất bại
                          </span>
                        )}
                      {item.receiver_status === 1 &&
                        item.sender_status === 0 && (
                          <span className="badge bg-warning border-0 fs-12">
                            Chờ xử lý
                          </span>
                        )}
                      {item.receiver_status === 1 &&
                        item.sender_status === 1 && (
                          <span className="badge bg-success border-0 fs-12">
                            Hoàn thành
                          </span>
                        )}
                      {item.receiver_status === 1 &&
                        item.sender_status === -1 && (
                          <span className="badge bg-danger border-0 fs-12">
                            Thất bại
                          </span>
                        )}
                      {item.receiver_status === 0 &&
                        item.sender_status === -1 && (
                          <span className="badge bg-danger border-0 fs-12">
                            Thất bại
                          </span>
                        )}
                      {/* Sửa, xóa */}
                      {dataUser[0].id_user === item.sender && (
                        <div className="d-flex gap-2 justify-content-end">
                          <button
                            className="btn btn-icon-1 btn-success-transparent rounded-pill btn-wave waves-effect waves-light"
                            onClick={() => handleEditDataJob(item.id)}
                          >
                            <MdModeEditOutline style={{ margin: "-.5rem" }} />
                          </button>
                          <button
                            className="btn btn-icon-1 btn-danger-transparent rounded-pill btn-wave waves-effect waves-light"
                            onClick={() => handleDeleteDataJob(item.id)}
                          >
                            <IoTrashOutline style={{ margin: "-.5rem" }} />
                          </button>
                          {/* Điểu kiện hiển thị button xác nhận */}
                          {item.receiver_status === 1 && (
                            <>
                              <button
                                className="btn btn-icon-1 btn-primary-transparent rounded-pill btn-wave waves-effect waves-light"
                                data-bs-toggle="dropdown"
                              >
                                <SlOptionsVertical
                                  style={{ margin: "-.5rem" }}
                                />
                              </button>
                              <ul className="dropdown-menu">
                                <li
                                  className="dropdown-item cursor-pointer"
                                  onClick={() => handleCompletedJob(item.id)}
                                >
                                  Nhiệm vụ hoàn thành
                                </li>
                                <li
                                  className="dropdown-item cursor-pointer"
                                  onClick={() => handleFailureJob(item.id)}
                                >
                                  Không duyệt
                                </li>
                              </ul>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </li>
          )
        })}
      </ul>
      {/* Modal edit job */}
      {modalEdit && (
        <ModalReact
          modalTitle="Sửa công việc"
          showModal={modalEdit}
          handleClose={handleCloseModalEditDataJob}
          theme={
            <FormEditJob
              socketio={socketio}
              dataUser={dataUser}
              dataEdit={dataEdit.current}
            />
          }
        />
      )}
    </div>
  )
}

export default ListJobPersonnel