
import moment from "moment";
import RevenueExpenseProfitChart from "../../../../Components/ReChart/RevenueExpenseProfitChart";

function ChartRevenueExpenseYear({ socketio, dataUser, dataRevenueYear, dataExpenseYear }) {
    // const dataRevenue = dataRevenueYear.map((item) => item.total_revenue)
    const monthRevenue = dataRevenueYear.map((item) => item.month);
    // const dataExpense = dataExpenseYear.map((item) => item.total_expense)
    const monthExpense = dataExpenseYear.map((item) => item.month);
    // Loại bỏ các giá trị giống nhau với 2 mảng với( new set)
    let dataLabels = [...new Set([...monthRevenue, ...monthExpense])];

    // Set mảng lợi nhuận.
    // let dataProfit = dataRevenue.map((revenue, index) => {
    //     return revenue - dataExpense[index]
    // });
    const dataChart = dataLabels.map((item) => {
        let revenue = dataRevenueYear.find((value) => value.month === item);
        let expense = dataExpenseYear.find((value) => value.month === item);

        let object = {
            name: moment(item).format("MM/YYYY"),
            revenue: revenue.total_revenue,
            expense: expense.total_expense,
            profit: Number(revenue.total_revenue) - Number(expense.total_expense)
        }
        return object
    });

    return (
        <div className="col-xxl-6 col-xl-6 col-12">
            <div className="card custom-card">
                <div className="card-body" style={{ height: "400px" }}>
                    <RevenueExpenseProfitChart data={dataChart}/>
                </div>
            </div>
        </div>
    );
}

export default ChartRevenueExpenseYear