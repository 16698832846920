import classNames from "classnames/bind"
import { Link } from "react-router-dom";

import { imagesCate, jobCate } from "../../../../assets/images";
import styles from "../../Home.module.scss"
import CheckPCMobile from "../../../../handle-reuses/reuses/check.pc.mobile";
const cx = classNames.bind(styles);

const listMenu = [
  {
    title: "Data sale",
    image: imagesCate.datasale,
    path: "/data-sale",
  },
  {
    title: "BĐS view",
    image: imagesCate.realestate,
    path: "/bdsview360/dashboard",
  },
  {
    title: "View 360",
    image: imagesCate.view360,
    path: "/view360",
  },
  {
    title: "Đường/Ngõ",
    image: imagesCate.street,
    path: "/street-real-estate",
  },
  {
    title: "Khảo giá",
    image: imagesCate.khaogia,
    path: "/",
  },
  {
    title: "Công nghệ",
    image: imagesCate.tech,
    path: "/",
  },

]

const arrCate = [
  { title: "Nhân sự", image: jobCate.personnel, path: "/nhan-su" },
  { title: "Chấm công", image: jobCate.timekeeping, path: "/cham-cong" },
  { title: "Công việc", image: jobCate.job, path: "/cong-viec" },
  { title: "Hợp đồng", image: jobCate.contract, path: "/hop-dong" },
  { title: "Bảng lương", image: jobCate.wallet, path: "/bang-luong" },
  { title: "Kế toán", image: jobCate.ketoan, path: "/ke-toan" },
  { title: "Báo cáo", image: jobCate.report, path: "/bao-cao" },
  {
    title: "Tài chính cá nhân",
    image: jobCate.investerment,
    path: "/tai-chinh-ca-nhan",
  },
]

function CateMenuApp() {
    
    return (
        <CheckPCMobile>
            {isMobile => isMobile ? <CateMenuAppMobile /> : <CateMenuAppPC />}
        </CheckPCMobile>
    )
}

export default CateMenuApp


// Giao diện trên Mobile.
function CateMenuAppMobile() {
    return (
      <div className={cx("")}>
        <ul className={cx("row")}>
          {listMenu.map((item, index) => {
            return (
              <li key={index} className="col-3">
                <Link
                  to={item.path}
                  className={cx(
                    "d-flex flex-direction-column align-items-center mb-3 gap-2",
                  )}
                >
                  <span className="avatar avatar-md">
                    <img
                      src={item.image}
                      alt="imag"
                      // width={50}
                      // height={50}
                      className="rounded-3"
                    />
                  </span>

                  <div className="text-center fs-14 fw-5">{item.title}</div>
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    )
}

// Giao diện trên PC
function CateMenuAppPC() {
    return (
      <div className={cx("bg-home")}>
        <div className={cx("row")}>
          {listMenu.map((item, index) => {
            return (
              <Link
                to={item.path}
                // className={cx(
                //   "d-flex flex-direction-column align-items-center gap-2",
                // )}
                key={index}
                className="d-flex align-items-center flex-direction-column col-3 gap-1 mb-5"
              >
                <img
                  src={item.image}
                  alt=""
                  width={50}
                  height={50}
                  className="rounded-3"
                />
                <span className="fs-16" style={{ color: "white" }}>
                  {item.title}
                </span>
              </Link>
            )
          })}
        </div>
        
        <div className="row">
          {arrCate.map((item, index) => {
            return (
              <Link
                to={item.path}
                key={index}
                className="d-flex align-items-center flex-direction-column col-3 gap-1 mb-5"
              >
                <span className="avatar avatar-lg">
                  <img src={item.image} alt="img" />
                </span>
                <div className="text-center fs-14 fw-5" style={{ color: "white" }}>
                  {item.title}
                </div>
              </Link>
            )
          })}
        </div>
      </div>
    )
}