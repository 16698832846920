import { IoFilterCircleOutline } from "react-icons/io5";
import SearchHorizontal from "../../../Components/Search/SearchHorizontal";
import ModalReact from "../../../Components/Modal/ModalReact";
import { useRef, useState } from "react";
import SearchDataSale from "../Component/SearchDataSale";

function SearchDataSaleMobile({resultSearchFast, resultSearchFilter}) {
  // Giá trị tìm kiếm
    const handleValueSearchFast = (keywords) => {
      resultSearchFast(keywords)
    }
  
    // Modal bộ lọc.
    const [filterDetail, setFilterDetail] = useState(false)
    const handleModelFilterDetail = () => {
        setFilterDetail(true)
    }
    const handleCloseModalFilterDetail = () => {
        setFilterDetail(false)
    }
  const handleValueFilterDetail = (objectFilter) => {
    resultSearchFilter(objectFilter)
    setFilterDetail(false)
  }
    return (
      <>
        <div className="col-12 d-flex justify-content-between gap-2">
          <div className="col-9">
            <SearchHorizontal
              result={handleValueSearchFast}
              className="rounded-3"
              placeholder="Số điện thoại..."
            />
          </div>
          <div className="">
            <button
              className="border-0 rounded-3 fs-15 fw-5 d-flex align-items-center op-6"
              style={{ height: "40px" }}
              onClick={handleModelFilterDetail}
            >
              <IoFilterCircleOutline className="me-1 fs-21" />
              Bộ lọc
            </button>
          </div>
            </div>
            {/* Modal bộ lọc */}
            <ModalReact
                modalTitle="Bộ lọc chi tiết"
                showModal={filterDetail}
                handleClose={handleCloseModalFilterDetail}
                theme={<SearchDataSale result={handleValueFilterDetail}/>}
            />
      </>
    )
}
export default SearchDataSaleMobile