import { useState } from "react"
import HeaderDashboard2 from "../../../../Components/Header/HeaderDashboard-2"
import HeaderDashboardMobile from "../../../../Components/Header/HeaderDashboardMobile"
import CheckPCMobile from "../../../../handle-reuses/reuses/check.pc.mobile"
import FooterDashboard from "../../../../Components/Footer/FooterDashboard"
import ListContract from "../Components/ListContract"
import SidebarView360 from "../../Components/SidebarView360/SidebarView360"


function ContractsView360() {
    const [hideShowSidebar, setHideShowSidebar] = useState(false)
    const handleResultHideShowSidebar = (key) => {
      setHideShowSidebar(key)
    }
    return (
      <div className="page">
        <CheckPCMobile>
          {(isMobile) =>
            isMobile ? (
              // Giao diện trên mobile
              <>
                {/* header */}
                <HeaderDashboardMobile />
                {/* Content */}
                <ListContract />
              </>
            ) : (
              // Giao diện trên pc
              <>
                {/* header */}
                <HeaderDashboard2 result={handleResultHideShowSidebar} />
                {/* sidebar */}
                <SidebarView360 active={hideShowSidebar} />
                {/* Content */}
                <ListContract active={hideShowSidebar} />
              </>
            )
          }
        </CheckPCMobile>

        {/* <!-- Footer Start --> */}
        <FooterDashboard />
        {/* <!-- Footer End --> */}
      </div>
    )
}

export default ContractsView360