 // Tính tổng tiền.(card + wallet);
export function handleTotalCardWallet(data) {
  // Tổng thu nhập thẻ
  let arrCard = data.map((item) => item.card_payment)
  let totalCard = arrCard.reduce((a, b) => Number(a) + Number(b))
  // tổng thu nhập ví
  let arrWallet = data.map((item) => item.wallet_payment)
  let totalWallet = arrWallet.reduce((a, b) => Number(a) + Number(b))

  return totalCard + totalWallet
}