import io from "socket.io-client"
import classNames from "classnames/bind";
import styles from "../Dashboard.module.scss"
import { useEffect, useRef, useState, useContext } from "react";
import moment from "moment/moment";
import AppContext from "../../../handle-reuses/Contexts/CreateContexts"

const cx = classNames.bind(styles);

function BaoCao({active}) {
  const {socketioBDS} = useContext(AppContext)
  return (
    <div
      className="main-content app-content"
      style={{ marginInlineStart: active && "6rem" }}
    >
      <div className="container-fluid">
        <div className="main-mail-container p-2">
          {/* Lượt truy cập */}
          <div className={cx("row mt-3")}>
            {/* <!-- Start::row-1 --> */}
            <LayoutLeftBaocao socketio={socketioBDS} />
            {/* <!--End::row-1 --> */}

            {/* Main */}
            <LayoutMainBaocao1 socketio={socketioBDS} />
            <LayoutMainBaocao socketio={socketioBDS} />
            {/* Right */}
            <LayoutRightBaocao socketio={socketioBDS} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BaoCao


// Layout left bao cao.
function LayoutLeftBaocao({socketio}) {
  // Lượng truy cập
  const [currentConnect, setCurrentConnect] = useState(0)
  const [totalConnect, setTotalConnect] = useState([])
  const [monthConnect, setMonthConnect] = useState(0)
  // Bài viết và doanh thu.
  const [postToday, setPostToday] = useState([])
  const [postMonth, setPostMonth] = useState([])
  
  // Quản lý dữ liệu.
  useEffect(() => {
    // xác định lượt truy cập hiện tại.
    socketio.on("server-connect-traffic", function (data) {
      setCurrentConnect(data.length)
    })
    // Tổng lượt truy cập hôm nay, tháng hiện tại.
    socketio.emit("client-traffic-today-month", true)
    // Hôm nay
    socketio.on("server-traffic-today", (data) => {
      setTotalConnect(data)
    })
    // Tháng hiện tại.
    socketio.on("server-traffic-month", (data) => {
      setMonthConnect(data)
    })
    // tổng bài viết, doanh thu, hôm nay và tháng hiện tại.
    socketio.emit("client-estate-post-today-month", true)
    // hôm nay
    socketio.on("server-estate-post-today", (data) => {
      setPostToday(data)
    })
    // Tháng hiện tại.
    socketio.on("server-estate-post-month", (data) => {
      setPostMonth(data)
    })

    return () => {
      socketio.off("server-connect-traffic")
      socketio.off("client-traffic-today-month")
      socketio.off("server-traffic-today")
      socketio.off("server-traffic-month")

      socketio.off("client-estate-post-today-month")
      socketio.off("server-estate-post-today")
      socketio.off("server-estate-post-month")
    }
  }, [])


  return (
      
      <div className="col-xl-3 col-12">
        <div className="col-12">
          <h6>Thống kê</h6>
        </div>
        <div className="col-xxl-12 col-xl-12 col-lg-12 fs-15">
          <div className="card custom-card">
            <div className="card-body">
              <div className="d-flex align-items-center mb-4">
                <div className="d-flex align-items-center">
                  <div className="me-2">
                    <span className="avatar avatar-md avatar-rounded bg-light p-2">
                      <img
                        src="../assets/images/crypto-currencies/regular/Bitcoin.svg"
                        alt=""
                      />
                    </span>
                  </div>
                  <div className="mb-0 fw-semibold">Hôm nay</div>
                </div>
                <div className="ms-auto">
                  <div id="bitcoin-chart"></div>
                </div>
              </div>
              <div className="d-flex align-items-end">
                <div>
                  <p className="mb-1">Đang online</p>
                  <p className="fs-20 mb-0 fw-semibold lh-1 text-primary">
                    {currentConnect}
                  </p>
                </div>
                <div className="ms-auto text-end">
                  <p className="mb-0">Tổng truy cập</p>
                  <p className="mb-0 text-muted">
                    <span className="text-muted fs-15">
                      {totalConnect.length > 0
                        ? Number(totalConnect[0].total_traffic)
                            .toLocaleString()
                            .replace(/[.]/g, ",")
                        : 0}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="card-footer p-0">
              <div className="list-group border-0">
                <div
                  className="list-group-item flex-column align-items-start border-top-0 border-start-0 border-end-0 border-bottom py-3"
                >
                  <div className="d-flex w-100 justify-content-between align-items-center">
                    <p className="fs-15 mb-0 font-weight-semibold text-dark">
                      Tin đăng
                    </p>
                    <p className="text-success mb-0 font-weight-normal fs-15">
                      <span className="numberfont">
                        {postToday.length > 0
                          ? "+" + postToday[0].total_post
                          : 0}
                      </span>{" "}
                      bài viết
                    </p>
                  </div>
                </div>
                <div
                  className="list-group-item flex-column align-items-start border-top-0 border-start-0 border-end-0 border-bottom py-3"
                >
                  <div className="d-flex w-100 justify-content-between align-items-center">
                    <p className="fs-15 mb-0 font-weight-semibold text-dark">
                      Doanh thu
                    </p>
                    <p className="text-dark mb-0 fs-15">
                      <span className="numberfont">
                        {postToday.length > 0
                          ? postToday[0].total_money
                            ? "+" +
                              Number(postToday[0].total_money)
                                .toLocaleString()
                                .replace(/[.]/g, ",")
                            : 0
                          : 0}
                        đ
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xxl-12 col-xl-12 col-lg-12 fs-15">
          <div className="card custom-card">
            <div className="card-body">
              <div className="d-flex align-items-center mb-4">
                <div className="d-flex align-items-center">
                  <div className="me-2">
                    <span className="avatar avatar-md avatar-rounded bg-light p-2">
                      <img
                        src="../assets/images/crypto-currencies/regular/Ethereum.svg"
                        alt=""
                      />
                    </span>
                  </div>
                  <div className="mb-0 fw-semibold">Tháng này</div>
                </div>
                <div className="ms-auto">
                  <div id="etherium-chart"></div>
                </div>
              </div>
              <div className="d-flex align-items-end">
                <div>
                  <p className="mb-1">Tổng truy cập</p>
                  <p className="fs-20 mb-0 fw-semibold lh-1 text-primary">
                    {monthConnect.length > 0
                      ? Number(monthConnect[0].total_traffic_month).toLocaleString().replace(/[.]/g, ",")
                      : 0}
                  </p>
                </div>
                {/* <div className="ms-auto text-end">
                  <p className="mb-0">Tăng 10%</p>
                  <p className="mb-0 text-muted">
                    <span className="text-muted">Vol:</span>(+13.45%)
                  </p>
                </div> */}
              </div>
            </div>
            <div className="card-footer p-0">
              <div className="list-group border-0">
                <div
                  className="list-group-item flex-column align-items-start border-top-0 border-start-0 border-end-0 border-bottom py-3"
                >
                  <div className="d-flex w-100 justify-content-between align-items-center">
                    <p className="fs-15 mb-0 font-weight-semibold text-dark">
                      Tin đăng
                    </p>
                    <p className="text-success mb-0 font-weight-normal fs-15">
                      <span className="numberfont">
                        {postMonth.length > 0
                          ? "+" + postMonth[0].total_post
                          : 0}
                      </span>{" "}
                      bài viết
                    </p>
                  </div>
                </div>
                <div
                  className="list-group-item flex-column align-items-start border-top-0 border-start-0 border-end-0 border-bottom py-3"
                >
                  <div className="d-flex w-100 justify-content-between align-items-center">
                    <p className="fs-15 mb-0 font-weight-semibold text-dark">
                      Doanh thu
                    </p>
                    <p className="text-dark mb-0 fs-15">
                      <span className="numberfont">
                        {postMonth.length > 0
                          ? postMonth[0].total_money
                            ? "+" +
                              Number(postMonth[0].total_money)
                                .toLocaleString()
                                .replace(/[.]/g, ",")
                            : 0
                          : 0}
                        đ
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-xxl-6 col-xl-6 col-lg-12">
          <div className="card custom-card">
            <div className="card-body">
              <div className="d-flex align-items-center mb-4">
                <div className="d-flex align-items-center">
                  <div className="me-2">
                    <span className="avatar avatar-md avatar-rounded bg-light p-2">
                      <img
                        src="../assets/images/crypto-currencies/regular/Dash.svg"
                        alt=""
                      />
                    </span>
                  </div>
                  <div className="mb-0 fw-semibold">Tài khoản</div>
                </div>
                <div className="ms-auto">
                  <div id="dashcoin-chart"></div>
                </div>
              </div>
              <div className="d-flex align-items-end">
                <div>
                  <p className="mb-1">Đang online</p>
                  <p className="fs-20 mb-0 fw-semibold lh-1 text-primary">
                    100
                  </p>
                </div>
                <div className="ms-auto text-end">
                  <p className="mb-0">$12.32</p>
                  <p className="mb-0 text-muted">
                    <span className="text-muted">Vol:</span>(+112.95%)
                  </p>
                </div>
              </div>
            </div>
            <div className="card-footer p-0">
              <div className="list-group border-0">
                <a
                  href="#"
                  className="list-group-item flex-column align-items-start border-top-0 border-start-0 border-end-0 border-bottom py-3"
                >
                  <div className="d-flex w-100 justify-content-between align-items-center">
                    <p className="tx-14 mb-0 font-weight-semibold text-dark">
                      Đăng ký mới
                    </p>
                    <p className="text-success mb-0 font-weight-normal tx-13">
                      <span className="numberfont">+200</span>{" "}
                      <i className="fa fa-arrow-up"></i> user
                    </p>
                  </div>
                </a>
                <a
                  href="#"
                  className="list-group-item flex-column align-items-start border-top-0 border-start-0 border-end-0 border-bottom py-3"
                >
                  <div className="d-flex w-100 justify-content-between align-items-center">
                    <p className="tx-14 mb-0 font-weight-semibold text-dark">
                      Tổng user
                    </p>
                    <p className="text-dark mb-0 tx-15">
                      <span className="numberfont">1,000</span>
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-12">
          <div className="card custom-card">
            <div className="card-body">
              <div className="d-flex align-items-center mb-4">
                <div className="d-flex align-items-center">
                  <div className="me-2">
                    <span className="avatar avatar-md avatar-rounded bg-light p-2">
                      <img
                        src="../assets/images/crypto-currencies/regular/Dash.svg"
                        alt=""
                      />
                    </span>
                  </div>
                  <div className="mb-0 fw-semibold">Tổng tài khoản</div>
                </div>
                <div className="ms-auto">
                  <div id="dashcoin-chart"></div>
                </div>
              </div>
              <div className="d-flex align-items-end">
                <div>
                  <p className="mb-1">Tổng user</p>
                  <p className="fs-20 mb-0 fw-semibold lh-1 text-primary">
                    1,000
                  </p>
                </div>
                <div className="ms-auto text-end">
                  <p className="mb-0">$12.32</p>
                  <p className="mb-0 text-muted">
                    <span className="text-muted">Chuyển đổi:</span>
                    (+112.95%)
                  </p>
                </div>
              </div>
            </div>
            <div className="card-footer p-0">
              <div className="list-group border-0">
                <a
                  href="#"
                  className="list-group-item flex-column align-items-start border-top-0 border-start-0 border-end-0 border-bottom py-3"
                >
                  <div className="d-flex w-100 justify-content-between align-items-center">
                    <p className="tx-14 mb-0 font-weight-semibold text-dark">
                      Miễn phí
                    </p>
                    <p className="text-success mb-0 font-weight-normal tx-13">
                      <span className="numberfont">200</span>{" "}
                      <i className="fa fa-arrow-up"></i> user
                    </p>
                  </div>
                </a>
                <a
                  href="#"
                  className="list-group-item flex-column align-items-start border-top-0 border-start-0 border-end-0 border-bottom py-3"
                >
                  <div className="d-flex w-100 justify-content-between align-items-center">
                    <p className="tx-14 mb-0 font-weight-semibold text-dark">
                      Trả phí
                    </p>
                    <p className="text-dark mb-0 tx-15">
                      <span className="numberfont">800 user</span>
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    )
}

// Layout main 1
function LayoutMainBaocao1({ socketio }) {
  const [dataSearch, setDataSearch] = useState([])
  const [loading, setLoading] = useState(true)
  let count = 1
  // Get data.
  useEffect(() => {
    const params = {
      iden: process.env.REACT_APP_IDEN_BDSVIEW,
      object: {
        quantity: 100,
        offset: 0,
      },
    }
    socketio.emit("client-get-list-data-search-keyword-new", params)
    socketio.on("server-get-list-data-search-keyword-new", (data) => {
      setDataSearch([...data])
      setLoading(false)
    })
  }, [])

  return (
    <div className="col-xl-4 col-12 mb-3">
      <div className="card-body p-0 ">
        <div className="col-12">
          <h6>Tìm kiếm hôm nay</h6>
        </div>
        <div
          className="list-group"
          style={{
            border: "1px solid #f3f3f3",
            overflow: "auto",
            height: "calc(79vh - 0.5rem)",
          }}
        >
          {loading && (
            <div className="text-center p-5">Đang tải dữ liệu...</div>
          )}
          {/* Đã tải xong dữ liệu */}
          {!loading && (
            <ul>
              {dataSearch.map((item, index) => {
                // Xác đinh thời gian.
                let colorTime = "text-muted"
                let currentTime = moment()
                let addtime = moment(item.addtime)
                let diffTime = currentTime.diff(addtime, "days")
                if (diffTime < 3) {
                  colorTime = "text-success"
                }
                return (
                  <li
                    key={item.id}
                    className="list-group-item flex-column align-items-start border-top-0 border-start-0 border-end-0 border-bottom"
                  >
                    <div className="d-flex w-100 justify-content-between align-items-center">
                      <p className="fs-15 mb-0 font-weight-semibold text-dark">
                        <span className="me-3 fs-15"># {count++}</span>
                        {item.title} {item.districts} {item.city}
                        {diffTime < 3 && (
                          <span className="badge bg-primary-transparent ms-3 text-primary">
                            New
                          </span>
                        )}
                      </p>
                      <div className="d-flex">
                        <p className="text-dark mb-0 font-weight-normal fs-15 me-3">
                          <span className="numberfont">
                            {Number(item.quantity)
                              .toLocaleString()
                              .replace(/[.]/g, ",")}
                          </span>
                        </p>
                        <p
                          className={cx(
                            "mb-0 font-weight-normal fs-15",
                            colorTime,
                          )}
                        >
                          <span className="numberfont">
                            {moment(item.addtime).format("DD/MM/YYYY")}
                          </span>
                        </p>
                      </div>
                    </div>
                  </li>
                )
              })}
            </ul>
          )}
        </div>
      </div>
    </div>
  )
}


// Layout main.
function LayoutMainBaocao({ socketio }) {
  const limit = 100;
  const [dataSearch, setDataSearch] = useState([])
  const [loading, setLoading] = useState(true)
  let count = 1
  useEffect(() => {
    const params = {
      iden: process.env.REACT_APP_IDEN_BDSVIEW,
      object: {
        quantity: limit,
        offset: 0
      }
    }
    socketio.emit("client-get-list-search-keyword", params)
    socketio.on("server-get-list-search-keyword", (data) => {
      setDataSearch(data)
      setLoading(false)
    })


    return () => {
      socketio.off("client-get-list-search-keyword")
      socketio.off("server-get-list-search-keyword")
    }
  }, [])


  return (
    <div className="col-xl-5 col-12 mb-3">
      <div className="card-body p-0 ">
        <div className="col-12">
          <h6>Top 50 từ khóa tìm kiếm nhiều nhất</h6>
        </div>
        <div
          className="list-group"
          style={{
            border: "1px solid #f3f3f3",
            overflow: "auto",
            height: "calc(79vh - 0.5rem)",
          }}
        >
          {loading && <div className="text-center p-5">Đang tải dữ liệu...</div>}
          {/* Đã tải xong dữ liệu */}
          {!loading && (
            <ul>
              {dataSearch.map((item, index) => {
                // Xác đinh thời gian.
                let colorTime = "text-muted"
                let currentTime = moment()
                let addtime = moment(item.addtime)
                let diffTime = currentTime.diff(addtime, "days")
                if (diffTime < 3) {
                  colorTime = "text-success"
                }
                return (
                  <li
                    key={item.id}
                    className="list-group-item flex-column align-items-start border-top-0 border-start-0 border-end-0 border-bottom"
                  >
                    <div className="d-flex w-100 justify-content-between align-items-center">
                      <p className="fs-15 mb-0 font-weight-semibold text-dark">
                        <span className="me-3 fs-15"># {count++}</span>
                        {item.title} {item.districts} {item.city}
                        {diffTime < 3 && (
                          <span className="badge bg-primary-transparent ms-3 text-primary">
                            New
                          </span>
                        )}
                      </p>
                      <div className="d-flex">
                        <p className="text-dark mb-0 font-weight-normal fs-15 me-3">
                          <span className="numberfont">
                            {Number(item.quantity)
                              .toLocaleString()
                              .replace(/[.]/g, ",")}
                          </span>
                        </p>
                        <p
                          className={cx(
                            "mb-0 font-weight-normal fs-15",
                            colorTime,
                          )}
                        >
                          <span className="numberfont">
                            {moment(item.addtime).format("DD/MM/YYYY")}
                          </span>
                        </p>
                      </div>
                    </div>
                  </li>
                )
              })}
            </ul>
          )}
        </div>
      </div>
    </div>
  )
}

// Layout right.
function LayoutRightBaocao({ socketio }) {
  const [listContentAI, setListContentAI] = useState([]);
  const [loading, setLoading] = useState(true)
  let count = 1;
  useEffect(() => {
    socketio.emit("client-list-report-content-ai", { iden: process.env.REACT_APP_IDEN_BDSVIEW })
    socketio.on("server-list-report-content-ai", (data) => {
      setListContentAI(data)
      setLoading(false)
    })
    return () => {
      socketio.off("client-list-report-content-ai")
      socketio.off("server-list-report-content-ai")
    }
  }, [])



  return (
    <div className="col-xl-4 col-12 mb-3">
      <div className="card-body p-0 ">
        <div className="col-12">
          <h6>Địa chỉ cần content AI</h6>
        </div>
        <div className="list-group" style={{ border: "1px solid #f3f3f3" }}>
          {loading && <div className="text-center p-5">Đang tải dữ liệu</div>}
          {/* Đã tải xong dữ liệu */}
          {!loading && (
            <ul>
              {listContentAI.map((item) => {
                return (
                  <li
                    key={item.id}
                    className="list-group-item flex-column align-items-start border-top-0 border-start-0 border-end-0 border-bottom"
                  >
                    <div className="d-flex w-100 justify-content-between align-items-center">
                      <p className="fs-14 mb-0 font-weight-semibold text-dark">
                        <span className="me-3"># {count++}</span>
                        {item.title}
                      </p>
                      <p className="text-dark mb-0 font-weight-normal fs-15">
                        <span className="numberfont">
                          {Number(item.quantity)
                            .toLocaleString()
                            .replace(/[.]/g, ",")}
                        </span>
                      </p>
                    </div>
                  </li>
                )
              })}
            </ul>
          )}
        </div>
      </div>
    </div>
  )
}