
import imageCompression from "browser-image-compression"
import { handleMethodCreateData, handleMethodUploadFile } from "./method"

export function handleRegister(dataInput, file, reset) {
    const apiUser = process.env.REACT_APP_APIGetDataMarketUser
    fetch(apiUser)
        .then((reponse) => {
            return reponse.json()
        })
        .then((dataUser) => {
            // Xử lý kiểm tra dữ liệu có tồn tại hay không.
            handleCheckUserExist(dataInput, file, reset, dataUser)
        })
    
    
}

// Xử lý kiểm tra user.
async function handleCheckUserExist(dataInput, file,reset, dataUser) {
    const filterUser = dataUser.filter((row) => {
        return row.sodienthoai === dataInput.sodienthoai || row.email === dataInput.email
    });

    if (filterUser.length > 0) {
        alert("Tài khoản đã tồn tại")
    } else {

        if (file) {
          const formData = new FormData()
          formData.append("file", file[0], file[0].name) // Thêm file vào formData với tên file gốc
          // Set kích thước image
          const options = {
            maxSizeMB: 3,
            maxWidthOrHeight: 500,
            useWebWorker: true,
          }

          try {
            // Thực hiện nén ảnh
            const compressedFile = await imageCompression(file[0], options)
            formData.set("file", compressedFile, file[0].name) //thêm file sau khi được nén vào FormData()
            // Tải file lên hosting
            const apiUploadFile = process.env.REACT_APP_APICreateRowMarketUploadFileUser
              handleMethodUploadFile(formData, () => {
                
                // Thêm dữ liệu
                handleMethodCreateData(dataInput, () => {
                    alert("Đăng ký thành công. Chờ duyệt tài khoản")
                    reset()
                    window.location.reload()
                }, process.env.REACT_APP_APICreateDataMarketUser)

            }, apiUploadFile)

              
          } catch (error) {
            console.error("Error compressing image:", error)
          }
        } else {
            // alert("Bạn chưa thêm ảnh đại diện")
            // Thêm dữ liệu
            handleMethodCreateData(dataInput, () => {
                alert("Đăng ký thành công. Chờ duyệt tài khoản")
                reset()
                window.location.reload()
            }, process.env.REACT_APP_APICreateDataMarketUser)
        }
    }
}