import { useContext, useEffect, useLayoutEffect, useRef, useState } from "react"
import SearchSalary from "../Component/Bangluong/SearchSalary"
import moment from "moment"
import { useNavigate } from "react-router-dom"
import classNames from "classnames/bind"
import styles from "../Job.module.scss"
import AppContext from "../../../handle-reuses/Contexts/CreateContexts"
import { IoMdAdd } from "react-icons/io"
import ModalReact from "../../../Components/Modal/ModalReact"
import FormAddChiphi from "../Component/Ketoan/FormAddChiphi"
import ListBangcandoi from "../Component/Ketoan/ListBangcandoi"
import LoadingSpinner from "../../../Components/Loading/LoadingSpinner"
import { MdOutlineAddHome } from "react-icons/md"
import { IoCafeOutline, IoCardOutline, IoMegaphoneOutline, IoRocketOutline } from "react-icons/io5"
import { BsDot } from "react-icons/bs"
import { TbArrowBigDownLines, TbArrowBigUpLines } from "react-icons/tb"
import FormEditChiphi from "../Component/Ketoan/FormEditChiphi"
const cx = classNames.bind(styles)

function ListKetoanMobile() {
    const { dataUser, socketio } = useContext(AppContext)
    const navigate = useNavigate()
    useLayoutEffect(() => {
      if (dataUser[0].ke_toan !== 1) {
        navigate("/")
      }
    }, [])
    const [objectSearch, setObjectSearch] = useState({
      monthSearch: moment().format("YYYY-MM"),
      textSearch: "",
    })
    // Quản lý danh mục.
    const [activeCate, setActiveCate] = useState("chi-phi")
    const handleActiveCate = (cate) => {
      setActiveCate(cate)
    }

    // Quản lý search data.
    const handleGetValueSearchText = (object) => {
      setObjectSearch({
        monthSearch: object.monthSearch,
        textSearch: object.textSearch,
        daysSearch: object.daysSearch,
      })
    }

    // Quản lý thêm mới chi phí.
    const [modalAddChiphi, setModalAddChiphi] = useState(false)
    const handleAddChiphi = () => {
      setModalAddChiphi(true)
    }
    const handleCloseAddModalChiphi = () => {
      setModalAddChiphi(false)
    }
    const [dataUpdate, setDataUpdate] = useState([])
    const handleUpdateDataCreateSuccess = (object) => {
      if (object.status) {
        setDataUpdate([...object.dataUpdate])
      }
    }

    // Quản lý bảng cân đối.
    const [modalBangcandoi, setModalBangcandoi] = useState(false)
    const handleModalBangcandoi = () => {
      setModalBangcandoi(true)
    }
    const handleCloseModalBangcandoi = () => setModalBangcandoi(false)

    // Quản lý tọa độ scroll.
    const [stickySearch, setStickySearch] = useState("")
    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            if (scrollTop >= 8) {
                setStickySearch("position-sticky-top-60")
            } else {
                setStickySearch("")
            }
        }
        window.addEventListener("scroll", handleScroll)

        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [])

    return (
      <div>
        {/* Search month*/}
        <div className={cx("wrapper_ground", stickySearch)}>
          <div className="list_items">
            <SearchSalary resultSearch={handleGetValueSearchText} />
          </div>
          <div className="list_items">
            <button
              className={cx(
                "border-0 rounded-3 d-flex align-items-center op-9 justify-content-center col-12",
                "btn btn-secondary-gradient btn-wave waves-effect waves-light",
              )}
              onClick={handleAddChiphi}
            >
              <IoMdAdd className="fs-21" />
            </button>
          </div>
        </div>
        <div className="alert alert-primary mt-3 text-center">
          <span className="fs-18 fw-5">
            Bảng kế toán {moment(objectSearch.monthSearch).format("MM/YYYY")}
          </span>
        </div>

        {/* List chi phí */}
        <div>
          <ListChiphiTimeline
            socketio={socketio}
            dataUser={dataUser}
            objectSearch={objectSearch}
            dataUpdate={dataUpdate}
          />
        </div>

        {/* Modal add chi phí */}
        {modalAddChiphi && (
          <ModalReact
            modalTitle="Thêm mới chi phí"
            showModal={modalAddChiphi}
            handleClose={handleCloseAddModalChiphi}
            theme={
              <FormAddChiphi
                socketio={socketio}
                dataUser={dataUser}
                result={handleUpdateDataCreateSuccess}
              />
            }
          />
        )}

        {/* Modal bảng cân đối */}
        <ModalReact
          modalTitle="Bảng cân đối"
          showModal={modalBangcandoi}
          handleClose={handleCloseModalBangcandoi}
          theme={<ListBangcandoi socketio={socketio} dataUser={dataUser} />}
        />
      </div>
    )
}
export default ListKetoanMobile


// List chi phí timeline.
function ListChiphiTimeline({ socketio, dataUser, objectSearch, dataUpdate }) {
    const [dataExpense, setDataExpense] = useState([])
    const dataExpenseRef = useRef([])
    const totalExpense = useRef(0)
    const totalRevenue = useRef(0)
    const tysuatExpense = useRef(0)
    const tysuatRevenue = useRef(0)
    const totalProfit = useRef(0)
    const [loading, setLoading] = useState(true)
    const [changeEdit, setChangeEdit] = useState()
    const [objectUpdate, setObjectUpdate] = useState({
      totalRevenue: 0,
      totalExpense: 0,
      tysuatRevenue: 0,
      tysuatExpense: 0,
      totalProfit: 0,
    })

    // Get data chi phí.
    useEffect(() => {
      const params = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: {
          monthSearch: objectSearch.monthSearch,
          textSearch: objectSearch.textSearch,
          daysSearch: objectSearch.daysSearch,
        },
      }; 
      socketio.emit("client-get-search-data-ketoan-chiphi", params)
      socketio.on("server-get-search-data-ketoan-chiphi", (data) => {
        setDataExpense([...data])
        dataExpenseRef.current = [...data]
        setLoading(true)
      })
      return () => {
        socketio.off("client-get-search-data-ketoan-chiphi")
        socketio.off("server-get-search-data-ketoan-chiphi")
      }
    }, [objectSearch])

    // Quản lý tổng doanh thu, tổng chi phí
    useEffect(() => {
      // Tổng chi phí theo tháng.
      const paramsTotalExpense = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: {
          monthSearch: objectSearch.monthSearch,
        },
      }
      socketio.emit(
        "client-get-total-expense-ketoan-chiphi",
        paramsTotalExpense,
      )
      socketio.on("server-get-total-expense-ketoan-chiphi", (data) => {
        if (data.length > 0) {
          totalExpense.current = data[0].total_expense
        } else {
          totalExpense.current = 0
        }
      })

      // Tổng doanh thu tháng.
      socketio.emit("client-get-total-revenue-job-contract", paramsTotalExpense)
      socketio.on("server-get-total-revenue-job-contract", (data) => {
        if (data.length > 0) {
          totalRevenue.current = data[0].total_revenue
          tysuatExpense.current =
            (Number(totalExpense.current) / Number(totalRevenue.current)) * 100
          tysuatRevenue.current = 100 - tysuatExpense.current
          totalProfit.current =
            Number(totalRevenue.current) - Number(totalExpense.current)

          setObjectUpdate({
            totalRevenue: totalRevenue.current,
            totalExpense: totalExpense.current,
            tysuatRevenue: Number.isFinite(tysuatRevenue.current) ? tysuatRevenue.current : 0,
            tysuatExpense: Number.isFinite(tysuatExpense.current) ? tysuatExpense.current : 0,
            totalProfit: totalProfit.current,
          })
        } else {
          totalRevenue.current = 0
          tysuatExpense.current = 0
          tysuatRevenue.current = 0
          totalProfit.current = 0
          setObjectUpdate({
            totalRevenue: 0,
            totalExpense: objectUpdate.totalExpense,
            tysuatRevenue: 0,
            tysuatExpense: 0,
            totalProfit: 0,
          })
        }
        setLoading(false)
      })
      return () => {
        socketio.off("client-get-total-expense-ketoan-chiphi")
        socketio.off("server-get-total-expense-ketoan-chiphi")

        socketio.off("client-get-total-revenue-job-contract")
        socketio.off("server-get-total-revenue-job-contract")
      }
    }, [objectSearch, changeEdit])

    // Quản lý update data khi thêm mới
    useEffect(() => {
      if (dataUpdate.length > 0) {
        let dataNew = dataExpenseRef.current
        dataNew.push(dataUpdate[0])
        let sortDataNew = dataNew.sort((a, b) => {
          let min_ = new Date(a.addtime).getTime()
          let max_ = new Date(b.addtime).getTime()
          return max_ - min_
        })
        setDataExpense([...sortDataNew])
        setChangeEdit(dataUpdate[0])
        dataExpenseRef.current = [...sortDataNew]
      }
    }, [dataUpdate])

    // Quản lý phê duyệt chí phí,
    const handleAcceptExpense = (id, status_) => {
      let checkAccept

      if (status_ === "accept") {
        checkAccept = window.confirm("Bạn đồng ý khoản chi phí này chứ ?")
      } else if (status_ === "cancel") {
        checkAccept = window.confirm("Bạn không đồng ý với khoản chi này ?")
      }

      if (checkAccept) {
        const paramsEdit = {
          iden: process.env.REACT_APP_IDEN_DASH,
          object: {
            status: status_ === "accept" ? 1 : 2,
            id: id,
          },
        }
        socketio.emit("client-edit-row-status-ketoan-chiphi", paramsEdit)
        socketio.on("server-edit-row-status-ketoan-chiphi", (object) => {
          if (object.status === "success") {
            // Thực hiện update data expense.
            let dataUpdateEdit = dataExpenseRef.current
            let index = dataExpenseRef.current.findIndex(
              (item) => item.id === object.data[0].id,
            )
            dataUpdateEdit[index] = object.data[0]
            setDataExpense([...dataUpdateEdit])
            setChangeEdit(object)
            dataExpenseRef.current = [...dataUpdateEdit]
          }
        })
      }
    }
    useEffect(() => {
      return () => {
        socketio.off("client-edit-row-status-ketoan-chiphi")
        socketio.off("server-edit-row-status-ketoan-chiphi")
      }
    }, [dataExpense])

    // Quản lý edit data.
    const [modalEdit, setModalEdit] = useState(false)
    const dataEditExpense = useRef()
    const handleEditModelExpense = (object) => {
        setModalEdit(true)
        dataEditExpense.current = object;
    }
    const handleCloseEditModelExpense = () => setModalEdit(false)
    const handleEditSuccess = (object) => {
      if (object.status) {
        let dataUpdateEdit = dataExpenseRef.current;
        let index = dataUpdateEdit.findIndex((item) => item.id === object.dataUpdate[0].id)
        dataUpdateEdit[index] = object.dataUpdate[0];
        setDataExpense([...dataUpdateEdit])
        dataExpenseRef.current = [...dataUpdateEdit]
        setModalEdit(false)
      }
    }

    if (loading) {
      return <LoadingSpinner />
    }

    if (dataExpense.length === 0) {
      return <div className="text-center mt-3">Chưa có dữ liệu.</div>
    }
    return (
      <div className="card-body mb-5">
        <div
          className="wrapper_ground"
          style={{
            background: "#f5f5f5",
            padding: "1rem",
            borderRadius: "12px",
          }}
        >
          <div className="list_items text-center">
            <span className="fw-5">Doanh thu</span>
            <div className="mb-0">
              <span className="fw-5">
                {Number(objectUpdate.totalRevenue)
                  .toLocaleString()
                  .replace(/[.]/g, ",")}
              </span>
              <p className="text-muted mb-0">Triệu đồng</p>
            </div>
          </div>
          <div className="list_items text-center">
            <span className="fw-5">Chi phí</span>
            <div className="mb-0">
              <span className="fw-5 text-danger">
                {Number(objectUpdate.totalExpense)
                  .toLocaleString()
                  .replace(/[.]/g, ",")}
              </span>
              <p className="text-danger mb-0">
                {Number.isInteger(objectUpdate.tysuatExpense)
                  ? objectUpdate.tysuatExpense
                  : objectUpdate.tysuatExpense.toFixed(1)}
                %
              </p>
            </div>
          </div>
          <div className="list_items text-center">
            <span className="fw-5">Lợi nhuận</span>
            <div className="mb-0 text-center">
              <div
                className={cx(
                  "fs-18 fw-5",
                  objectUpdate.totalProfit > 0 && "text-success",
                )}
              >
                {objectUpdate.totalProfit.toLocaleString().replace(/[.]/g, ",")}
              </div>
              <span
                className={cx(
                  "fw-5 d-flex align-items-center justify-content-center gap-1",
                  objectUpdate.totalProfit > 0 && "text-success",
                )}
              >
                {Number.isInteger(objectUpdate.tysuatRevenue)
                  ? objectUpdate.tysuatRevenue
                  : objectUpdate.tysuatRevenue.toFixed(1)}
                %
                {objectUpdate.totalProfit > 0 ? (
                  <TbArrowBigUpLines />
                ) : (
                  <TbArrowBigDownLines />
                )}
              </span>
            </div>
          </div>
        </div>
        <ul className="list-unstyled timeline-widget mb-0 my-3">
          {dataExpense.map((item, index) => {
            // Set chi phí đã chiếm
            let tysuat =
              (Number(item.money) / Number(totalExpense.current)) * 100
            // Set limit tỷ suất chi phí được phép chi
            let percentLimit = Number(item.percent_limit)
            // Set tỷ suất đã chi.
            let tysuatDachi =
              (Number(item.money) / Number(objectUpdate.totalRevenue)) * 100
            // Set tỷ suất còn lại có thể chi.
            let tysuatConlai = percentLimit - tysuatDachi
            // Set số tiền còn có thể chi.
            let soTienCotheChi =
              Number(objectUpdate.totalRevenue) *
              (Number(tysuatConlai.toFixed(2)) / 100)
            
            return (
              <li
                className={cx(
                  "timeline-widget-list",
                  item.loai_chiphi === "Tiền thuê văn phòng" && "icon-timeline",
                  item.loai_chiphi === "Ngoại giao" && "icon-timeline",
                  item.loai_chiphi === "Marketing" && "icon-timeline",
                  item.loai_chiphi === "Lương nhân viên" && "icon-timeline",
                  item.loai_chiphi === "Văn hóa công ty" && "icon-timeline",
                )}
                key={index}
              >
                <div className={cx("icon_timeline_expense")}>
                  {item.loai_chiphi === "Tiền thuê văn phòng" && (
                    <MdOutlineAddHome className="text-muted" />
                  )}
                  {item.loai_chiphi === "Ngoại giao" && (
                    <IoCafeOutline className="text-muted" />
                  )}
                  {item.loai_chiphi === "Marketing" && (
                    <IoRocketOutline className="text-muted" />
                  )}
                  {item.loai_chiphi === "Lương nhân viên" && (
                    <IoCardOutline className="text-muted" />
                  )}
                  {item.loai_chiphi === "Văn hóa công ty" && (
                    <IoMegaphoneOutline className="text-muted" />
                  )}
                </div>
                <div className="d-flex align-items-top gap-4 col-12">
                  <div className="me-0 text-center col-2">
                    <span className="d-block fs-16 fw-semibold text-primary">
                      {moment(item.addtime).format("DD")}/
                      {moment(item.addtime).format("MM")}
                    </span>
                    {/* <span className="d-block fs-12 fw-5 text-muted">
                      Tháng {moment(item.addtime).format("MM")}
                    </span> */}
                    <span className="text-muted fs-16">
                      {moment(item.addtime).format("HH:mm")}
                    </span>
                    <span className="avatar avatar-sm me-2 avatar-rounded">
                      <img
                        src={"https://system.view360.vn/" + item.avatar}
                        alt="img"
                      />
                    </span>
                  </div>
                  <div className="d-flex flex-wrap flex-fill align-items-top justify-content-between col-9">
                    <div className="col-12">
                      <span className="fw-5">
                        {item.loai_chiphi}
                        <BsDot />
                        <span className="">
                          {Number(item.money)
                            .toLocaleString()
                            .replace(/[.]/g, ",")}
                        </span>
                      </span>
                      <div className="mb-0 text-truncate timeline-widget-content text-wrap text-muted">
                        {item.noidung_chiphi}
                      </div>
                      <span className="text-muted fw-4">
                        {moment(item.addtime)
                          .locale("vi")
                          .fromNow()
                          .replace("một", "1")}
                      </span>
                      {/* Phân tích */}
                      <div className="border-top border-block-start-dashed mt-1 mb-1"></div>
                      <div>
                        <div className="text-primary">
                          {/* Giới hạn {percentLimit + "%"} */}
                          {/* <BsDot />
                          <span
                            className={cx(
                              "fw-5",
                              tysuatDachi <= percentLimit
                                ? "text-success"
                                : "text-danger",
                            )}
                          >
                            <span>
                              Đã chi{" "}
                              {Number.isInteger(tysuatDachi)
                                ? tysuatDachi
                                : tysuatDachi.toFixed(2)}
                              %
                            </span>
                          </span> */}
                          {/* <BsDot /> */}
                          {/* <span
                            className={cx(
                              "mb-0 fs-12 fw-5",
                              tysuatDachi <= percentLimit
                                ? "text-success"
                                : "text-danger",
                            )}
                          >
                            {tysuatConlai > 0 ? " Còn lại " : "Đã vượt "}
                            {Number.isInteger(tysuatConlai)
                              ? tysuatConlai
                              : tysuatConlai.toFixed(2)}
                            %
                          </span> */}
                          {/* <div>
                            <span className="me-0">
                              {tysuatConlai > 0 ? (
                                <span className="text-success">
                                  <span className="mb-0 fs-12 me-1">
                                    Được chi
                                  </span>
                                  <span className="mb-0 fw-5">
                                    {Number(soTienCotheChi)
                                      .toLocaleString()
                                      .replace(/[.]/g, ",")}
                                  </span>
                                </span>
                              ) : (
                                <span className="text-danger">
                                  <p className="mb-0 fs-12">
                                    Cần tăng doanh thu
                                  </p>
                                </span>
                              )}
                            </span>
                            <p className="fs-12 mb-0">
                              Chi phí này đã chiếm{" "}
                              {Number.isInteger(tysuat)
                                ? tysuat
                                : tysuat.toFixed(2)}
                              %
                            </p>
                          </div> */}
                        </div>
                      </div>

                      {/* <div className="border-top border-block-start-dashed mt-1 mb-1"></div> */}
                      <p className="mb-0 fs-16 lh-1 text-muted d-flex align-items-center">
                        {item.status === 0 && (
                          <>
                            {/* Kiểm tra ai được duyệt chi phí này */}
                            {dataUser[0].role_ketoan === 1 ? (
                              <>
                                <button
                                  className="badge bg-warning-transparent rounded-2 border-0 fs-16"
                                  onClick={() =>
                                    handleAcceptExpense(item.id, "accept")
                                  }
                                >
                                  Chờ duyệt
                                </button>
                                <button
                                  className="border-0 badge bg-danger-transparent rounded-2 ms-2 fs-16"
                                  onClick={() =>
                                    handleAcceptExpense(item.id, "cancel")
                                  }
                                >
                                  Hủy
                                </button>
                              </>
                            ) : (
                              <button className="badge bg-warning-transparent rounded-2 border-0 fs-16">
                                Chờ duyệt
                              </button>
                            )}

                            {item.nhanvien === dataUser[0].id_user && (
                              <button
                                className="badge bg-info-transparent rounded-2 ms-2 border-0 fs-16"
                                onClick={() => handleEditModelExpense(item)}
                              >
                                Sửa
                              </button>
                            )}
                          </>
                        )}
                        {item.status === 1 && (
                          <span className="badge bg-success-transparent rounded-2 fs-16">
                            Đã duyệt
                          </span>
                        )}
                        {item.status === 2 && (
                          <span className="badge bg-danger-transparent rounded-2 fs-16">
                            Đã hủy
                          </span>
                        )}
                      </p>
                    </div>
                    {/* <div className="dropdown col-2 text-end">
                              <button
                                aria-label="anchor"
                                className="p-2 fs-16 text-muted border-0 rounded-5 btn-icon"
                                data-bs-toggle="dropdown"
                              >
                                <CgOptions />
                              </button>
                              <ul className="dropdown-menu">
                                <li className="dropdown-item">Chờ duyệt</li>
                                <li className="dropdown-item">Hủy chi phí</li>
                                <li className="dropdown-item">Sửa thông tin</li>
                              </ul>
                            </div> */}
                  </div>
                </div>
              </li>
            )
          })}
        </ul>
        {/* Model edit expense */}
        {modalEdit && (
          <ModalReact
            modalTitle="Sửa dữ liệu"
            showModal={modalEdit}
            handleClose={handleCloseEditModelExpense}
            theme={
              <FormEditChiphi
                dataEdit={dataEditExpense.current}
                result={handleEditSuccess}
              />
            }
          />
        )}
      </div>
    )
}