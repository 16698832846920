
import { useContext, useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import classNames from "classnames/bind"
import { GoDotFill } from "react-icons/go"
import AppContext from "../../../handle-reuses/Contexts/CreateContexts"
import styles from "../DataSale.module.scss"
import { LuRefreshCw } from "react-icons/lu"
import moment from "moment"
const cx = classNames.bind(styles);

function ListCateDataSalePC({response, dataAllCusSale}) {
  const { socketio, dataUser } = useContext(AppContext)
  const path = useLocation()
  const currentPath = path.pathname
  const [notifiData, setNotifiData] = useState(false)
  const [notifiBooking, setNotifiBooking] = useState(0)
  const [notifiExpired, setNotifiExpired] = useState(0)
  // Quản lý Hiệu ứng khi có sản phẩm mới.
  useEffect(() => {
    socketio.on("server-notification-data-sale-new", (notifi) => {
      if (notifi === "data-sale-new") {
        setNotifiData(true)
      }
    })
    return () => {
      socketio.off("server-notification-data-sale-new")
    }
  }, []);

  // Cập nhật lại dữ liệu mới.
  const handleUpdateDataSale = (cate) => {
    setNotifiData(false)
    response(true)
    // statusCate(cate)
  }

  // Trả về trạng thái danh mục.
  const handleStatusCate = (cate) => {
    // statusCate(cate)
  }

  // Quản lý thông báo khách hẹn
  useEffect(() => {
    // Notifi booking
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        author: dataUser[0].id_user
      }
    }
    socketio.emit("client-get-data-cate-product-booking", params);
    socketio.on("server-get-data-cate-product-booking", (dataBooking) => {
      let filterDataBooking = dataBooking.filter((item) => {
        let booking = moment(item.booking).format("DD-MM-YYYY");
        let currentTime = moment().format("DD-MM-YYYY")
        return booking === currentTime
      })
      setNotifiBooking(filterDataBooking.length)
    })
    // notifi expired.
    const paramsExpired = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        goidichvu: dataUser[0].goidichvu_user,
        author: dataUser[0].id_user
      }
    }
    socketio.emit("client-data-expired-ads-data-sale-sanpham", paramsExpired)
    socketio.on("server-data-expired-ads-data-sale-sanpham", (dataExpired) => {
      const filterNotifi = dataExpired.filter((item) => {
        let expired = moment(item.expired)
        let currentTime = moment();
        let diffTime = expired.diff(currentTime, "days");
        return diffTime === 0
      })
      setNotifiExpired(filterNotifi.length)
    })
    return () => {
      socketio.off("client-get-data-cate-product-booking")
      socketio.off("server-get-data-cate-product-booking")
      socketio.off("client-data-expired-ads-data-sale-sanpham")
      socketio.off("server-data-expired-ads-data-sale-sanpham")
    }
  }, [])

  return (
    <div
      className={cx("d-flex align-items-center gap-2", "list_cate")}
      // style={{ overflowX: "auto" }}
    >
      <Link to="/data-sale" state={{ data: dataAllCusSale }}>
        <button
          className={cx(
            "btn btn-light btn-wave rounded-3 position-relative border-0 d-flex justify-content-center align-items-center gap-1 fw-5",
            "buttonCate",
            currentPath === "/data-sale" && "active",
          )}
          // style={{
          //   height: "40px",
          //   width: "121px",
          //   // border: "2px solid #e8e8e8",
          // }}
          onClick={() => handleUpdateDataSale("data-sale")}
        >
          {/* <LuRefreshCw /> */}
          Trang chủ
          <span className="text-danger">
            {notifiData && (
              <GoDotFill
                className="fs-25"
                style={{ position: "absolute", top: "-11px", right: "-11px" }}
              />
            )}
          </span>
        </button>
      </Link>
      <Link to="/nhiem-vu">
        <button
          className={cx(
            "btn btn-light btn-wave rounded-3 position-relative border-0 d-flex justify-content-center align-items-center gap-1 fw-5",
            "buttonCate",
            currentPath === "/nhiem-vu" && "active",
          )}
          // style={{
          //   height: "40px",
          //   width: "121px",
          //   // border: "2px solid #e8e8e8",
          // }}
        >
          Nhiệm vụ
        </button>
      </Link>
      <Link to="/khach-kinh-doanh">
        <button
          className={cx(
            "btn btn-light btn-wave rounded-3 position-relative border-0 d-flex justify-content-center align-items-center gap-1 fw-5",
            "buttonCate",
            currentPath === "/khach-kinh-doanh" && "active",
          )}
          // style={{
          //   height: "40px",
          //   width: "121px",
          //   // border: "2px solid #e8e8e8",
          // }}
          onClick={() => handleStatusCate("khach-kinh-doanh")}
        >
          Kinh doanh
        </button>
      </Link>
      <Link to="/khach-net">
        <button
          className={cx(
            "btn btn-light btn-wave rounded-3 position-relative border-0 d-flex justify-content-center align-items-center gap-1 fw-5",
            "buttonCate",
            currentPath === "/khach-net" && "active",
          )}
          // style={{
          //   height: "40px",
          //   width: "121px",
          //   // border: "2px solid #e8e8e8",
          // }}
          onClick={() => handleStatusCate("khach-net")}
        >
          Khách nét
        </button>
      </Link>
      <Link to="/khach-hen">
        <button
          className={cx(
            "btn btn-light btn-wave rounded-3 position-relative border-0 d-flex justify-content-center align-items-center gap-1 fw-5",
            "buttonCate",
            currentPath === "/khach-hen" && "active",
          )}
          // style={{
          //   height: "40px",
          //   width: "121px",
          //   // border: "2px solid #e8e8e8",
          // }}
          onClick={() => handleStatusCate("khach-hen")}
        >
          Khách hẹn
          <span className="text-danger">
            {notifiBooking > 0 && (
              <span className={cx("notifiCate")}>{notifiBooking}</span>
            )}
          </span>
        </button>
      </Link>
      <Link to="/khach-het-han">
        <button
          className={cx(
            "btn btn-light btn-wave rounded-3 position-relative border-0 d-flex justify-content-center align-items-center gap-1 fw-5",
            "buttonCate",
            currentPath === "/khach-het-han" && "active",
          )}
          // style={{
          //   height: "40px",
          //   width: "121px",
          //   // border: "2px solid #e8e8e8",
          // }}
          onClick={() => handleStatusCate("khach-het-han")}
        >
          K.Hết hạn
          <span className="text-danger">
            {notifiExpired > 0 && (
              <span className={cx("notifiCate")}>{notifiExpired}</span>
            )}
          </span>
        </button>
      </Link>
    </div>
  )
}
export default ListCateDataSalePC