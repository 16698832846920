import classNames from "classnames/bind"
import { BsDot, BsThreeDotsVertical } from "react-icons/bs"
import { TbClockHour3 } from "react-icons/tb"

import styles from "../DataSale.module.scss"
import { Dropdown } from "react-bootstrap";
import { TextLimit, handleFormatPrice } from "../../../handle-reuses/reuses";
import moment from "moment";
import { memo, useContext, useEffect, useRef, useState } from "react";
import ModalReact from "../../../Components/Modal/ModalReact";
import AppContext from "../../../handle-reuses/Contexts/CreateContexts"
import FormEditDataSale from "../Component/FormEditDataSale"
const cx = classNames.bind(styles);

function ListDataSaleMobile({ dataCusSale, dataKhachhen, isLoading, dataSearchFast, checkSearchFast, dataFilterDetail, socketio, resultStatus }) {
  const { dataUser } = useContext(AppContext)
  const [dataCustomerSale, setDataCustomerSale] = useState([])
  // Quản lý xem chi tiết sản phẩm
  const [modalDetail, setModalDetail] = useState(false)
  const [dataDetail, setDataDetail] = useState([])
  const handleShowModalDetail = (idSP) => {
    const filterDataDetail = dataCusSale.filter((item) => item.id_sp === idSP)
    setDataDetail(filterDataDetail)
    setModalDetail(true)
  }
  const handleCloseModalDetail = () => {
    setDataDetail([])
    setModalDetail(false)
  }

  // Quản lý hiển thị dữ liệu.
  useEffect(() => {
    if (checkSearchFast === "" && dataFilterDetail.length === 0) {
      setDataCustomerSale([...dataCusSale]);
    } else if (checkSearchFast !== "" && dataFilterDetail.length === 0) {
      setDataCustomerSale([...dataSearchFast])
    } else if (checkSearchFast === "" && dataFilterDetail.length > 0) {
      setDataCustomerSale([...dataFilterDetail])
    }
  }, [dataSearchFast, dataCusSale, dataFilterDetail])

  // Quản lý hiển thị ghi chú.
  const [modalNote, setModalNote] = useState(false)
  const dataDetailNote = useRef([])
  const handleDetailNote = (idSP) => {
    setModalNote(true)
    let filterDetailNote = dataCustomerSale.filter((item) => {
      return item.id_sp === idSP
    })
    dataDetailNote.current = filterDetailNote
  }
  const handleCloseModalDetailNote = () => {
    setModalNote(false)
    dataDetailNote.current = []
  }
  const handleNoteSuccess = (status_) => {
    if (status_) {
      
    }
  }

  // Quản lý xác định đã bán hoặc chưa bán.
  const editIdSanpham = useRef(0)
  const indexItemSanpham = useRef()
  const handleTinhtrangSaleOrBuy = (idSP, index) => {
    let checkTinhtrang = window.confirm("Sản phẩm này đã bán rồi chứ ?")
    if (checkTinhtrang) {
      const paramsEditTinhtrang = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: {
          id_sp: idSP,
          tinhtrang: 1,
        },
      }
      socketio.emit("client-edit-tinhtrang-sanpham", paramsEditTinhtrang)
      editIdSanpham.current = idSP
      indexItemSanpham.current = index
    }
  }
  // Chuyển trạng thái đã quảng cáo.
  const handleStatusAdsCusSale = (idSP, index) => {
    let checkStatus = window.confirm("Sản phẩm này đã quảng cáo ?")
    if (checkStatus) {
      const paramsEditStatus = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: {
          id_sp: idSP,
          status: 1,
        },
      }
      socketio.emit("client-edit-status-sanpham", paramsEditStatus)
      editIdSanpham.current = idSP
      indexItemSanpham.current = index
    }
  }

  // quản lý trạng thái đã bán, quảng cáo.
  useEffect(() => {
    // Xác nhận chuyển trạng thái đã bán
    socketio.on("server-edit-tinhtrang-sanpham", (data) => {
      if (data === "success") {
        // Cập nhật lại dữ liệu khi edit thành công
        const setDataCusSaleUpdate = dataCusSale.find(
          (item) => item.id_sp === editIdSanpham.current,
        )
        setDataCusSaleUpdate.tinhtrang = 1
        dataCusSale[indexItemSanpham.current] = setDataCusSaleUpdate
        setDataCustomerSale([...dataCusSale])
        alert("Chuyển trang thái đã bán thành công.")
      }
    })

    // Xác nhận chuyển trạng thái đã quảng cáo.
    socketio.on("server-edit-status-sanpham", (data) => {
      if (data === "success") {
        // Cập nhật lại dữ liệu khi edit thành công
        const setDataCusSaleUpdate = dataCusSale.find(
          (item) => item.id_sp === editIdSanpham.current,
        )
        setDataCusSaleUpdate.status = 1
        dataCusSale[indexItemSanpham.current] = setDataCusSaleUpdate
        setDataCustomerSale([...dataCusSale])
        alert("Chuyển trang thái đã bán thành công.")
      }
    })
    return () => {
      socketio.off("client-edit-tinhtrang-sanpham")
      socketio.off("server-edit-tinhtrang-sanpham")

      socketio.off("client-edit-status-sanpham")
      socketio.off("server-edit-status-sanpham")
    }
  }, [])

  /** Quản lý thêm vào mục khách hàng kinh doanh, khách net, khách hẹn */
  // Xử lý thêm vào khách hàng kinh doanh.
  const idSanpham = useRef()
  const checkAddCate = useRef("")
  const [modalAddBooking, setModalAddBooking] = useState(false)
  const dataModalAddBooking = useRef([])
  const handleCloseModalAddBooking = () => {
    setModalAddBooking(false)
  }
  const handleAddCateProduct = (knSanpham, cateText, index) => {
    let check_ = window.confirm("Bạn muốn chuyển vào danh mục này chứ ?")
    idSanpham.current = knSanpham
    checkAddCate.current = cateText
    if (check_) {
      const paramsKD = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: {
          kn_sanpham: knSanpham,
          tacgia: dataUser[0].id_user,
        },
      }
      socketio.emit("client-check-data-cate-product", paramsKD)
    }
  }
  // Quản lý check data cate product và thêm khách hàng vào các danh mục.
  useEffect(() => {
    socketio.on("server-check-data-cate-product", (data) => {
      if (data.length > 0) {
        if (checkAddCate.current === "addCateKinhdoanh") {
          // Nếu data tồn tại thì thành sửa
          const paramsEdit = {
            iden: process.env.REACT_APP_IDEN_DASH,
            object: {
              id_cate_product: data[0].id_cate_product,
              kinhdoanh: data[0].kinhdoanh === 1 ? 0 : 1,
            },
          }
          // Xử lý edit data.
          socketio.emit("client-edit-row-kinhdoanh-cate-product", paramsEdit)
          if (data[0].kinhdoanh === 1) {
            alert("Đã xóa khỏi mục kinh doanh.")
          } else {
            alert("Đã thêm vào mục kinh doanh.")
          }
        } else if (checkAddCate.current === "addCateKhachnet") {
          // Xử lý thêm vào mục khách net
          const paramsEdit = {
            iden: process.env.REACT_APP_IDEN_DASH,
            object: {
              id_cate_product: data[0].id_cate_product,
              khachnet: data[0].khachnet === 1 ? 0 : 1,
            },
          }
          // Xử lý edit data.
          socketio.emit("client-edit-row-khachnet-cate-product", paramsEdit)
          if (data[0].khachnet === 1) {
            alert("Đã xóa khỏi mục khách nét.")
          } else {
            alert("Đã thêm vào mục khách nét.")
          }
        } else if (checkAddCate.current === "addCateKhachhen") {
          // Xử lý thêm vào mục khách hẹn.
          setModalAddBooking(true)
          dataModalAddBooking.current = data
        }
      } else {
        if (checkAddCate.current === "addCateKinhdoanh") {
          // Nếu chưa tồn tại thì thêm mới.
          const paramsCreate = {
            iden: process.env.REACT_APP_IDEN_DASH,
            object: {
              kn_sanpham: idSanpham.current,
              kinhdoanh: 1,
              tacgia: dataUser[0].id_user,
            },
          }
          // Thêm mới.
          socketio.emit("client-create-data-cate-product", paramsCreate)
          alert("Đã thêm mới vào mục khách kinh doanh.")
        } else if (checkAddCate.current === "addCateKhachnet") {
          // Xử lý thêm mới khách net.
          const paramsCreate = {
            iden: process.env.REACT_APP_IDEN_DASH,
            object: {
              kn_sanpham: idSanpham.current,
              khachnet: 1,
              tacgia: dataUser[0].id_user,
            },
          }
          // Thêm mới.
          socketio.emit("client-create-data-cate-product", paramsCreate)
          alert("Đã thêm mới vào mục khách khách net.")
        }
      }
    })

    return () => {
      socketio.off("client-edit-row-kinhdoanh-cate-product")
      socketio.off("client-create-data-cate-product")
      socketio.off("client-edit-row-khachnet-cate-product")

      socketio.off("client-check-data-cate-product")
      socketio.off("server-check-data-cate-product")
    }
  }, [checkAddCate.current])

  // ẩn modal đặt lịch hẹn.
  const handleHidenModalAddBooking = (status_) => {
    setModalAddBooking(false)
  }
  /** *Quản lý thêm vào mục khách hàng kinh doanh, khách net, khách hẹn */

  /** Quản lý sử thông tin khách hàng */
  const [modalEditSale, setModalEditSale] = useState(false)
  const editDataSale = useRef([])
  const handleModalEditDataSale = (idSP, index) => {
    setModalEditSale(true);
    const filterData = dataCusSale.filter((item) => {
      return item.id_sp === idSP
    });
    editDataSale.current = filterData
  }
  const handleCloseModalEditDataSale = () => {
    setModalEditSale(false)
    editDataSale.current = []
  }
  const handleResultStatusEdit = (object) => {
    if (object.status) {
      setModalEditSale(false)
    }
  }
  /** *Quản lý sửa thông tin khách hàng */

  return (
    <>
      {/* Hiển thị kết quả tìm kiếm */}
      {dataSearchFast.length > 0 && (
        <div className="text-center mt-3">
          Kết quả tìm kiếm: {dataSearchFast.length}
        </div>
      )}
      {dataFilterDetail.length > 0 && (
        <div className="text-center mt-3">
          Kết quả tìm kiếm: {dataFilterDetail.length}
        </div>
      )}
      {/* Danh sách sản phẩm */}
      {dataCustomerSale.map((item, index) => {
        let formatPrice = item.giasanpham.replace(/[,]/g, "")
        let expired_ = new Date(item.expired).getTime()
        // Filter data khách hẹn.
        let filterDataKhachhen = dataKhachhen.find((khachhen) => {
          return khachhen.kn_sanpham === item.id_sp
        })
        return (
          <div
            className={cx(
              "mt-3 d-flex justify-content-between mb-3",
              "listDataSaleMobile",
            )}
            key={item.id_sp}
            style={{
              backgroundColor:
                expired_ > 0 ? "rgba(var(--warning-rgb),.1)" : "",
            }}
          >
            <div onClick={() => handleShowModalDetail(item.id_sp)}>
              <h6 className="fs-18">
                {item.tinhtrang === 1 && (
                  <span className="badge bg-danger me-1 fs-9">Đã bán</span>
                )}
                {item.status === 1 && (
                  <span className="badge bg-success me-1 fs-9">Ads</span>
                )}
                <TextLimit text={item.diachi} number={63} />
                {", " + item.quanhuyen}
              </h6>
              <p className="mb-0 fs-18">
                {item.hoten}: {item.sodienthoai}
              </p>
              <p className="mb-0 fs-18 text-muted d-flex align-items-center">
                {expired_ > 0 && (
                  <span className="text-danger">
                    Hết hạn:{" "}
                    {moment(item.expired)
                      .locale("vi")
                      .fromNow()
                      .replace("một", "1")}
                  </span>
                )}
                {!expired_ &&
                  moment(item.ngaycapnhat)
                    .local("vi")
                    .fromNow()
                    .replace("một", "1")}{" "}
                {/* <BsDot /> {item.quanhuyen} */}
                <BsDot /> {handleFormatPrice(Number(formatPrice))}
                {filterDataKhachhen && (
                  <>
                    <BsDot />
                    <span className="text-orange">
                      <TbClockHour3 />{" "}
                      {moment(filterDataKhachhen.booking)
                        .locale("vi")
                        .fromNow()
                        .replace("một", "1")}
                    </span>
                  </>
                )}
              </p>
            </div>
            <div>
              <Dropdown>
                <Dropdown.Toggle
                  variant=" border-0 rounded-5 bg-transparent" // class mặc định
                  bsPrefix="custom-dropdown-toggle"
                >
                  <BsThreeDotsVertical
                    style={{ backgroundColor: "rgba(255, 255, 255, 0.9)" }}
                    className="text-muted fs-29 p-1 rounded-5"
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ minWidth: "200px" }}>
                  <li
                    className="dropdown-item d-flex fs-18"
                    onClick={() => handleDetailNote(item.id_sp)}
                  >
                    Ghi chú
                  </li>
                  <li
                    className="dropdown-item d-flex fs-18"
                    onClick={() => handleTinhtrangSaleOrBuy(item.id_sp, index)}
                  >
                    Xác nhận đã bán
                  </li>
                  <li
                    className="dropdown-item d-flex fs-18"
                    onClick={() => handleStatusAdsCusSale(item.id_sp, index)}
                  >
                    Xác nhận Đã quảng cáo
                  </li>
                  <li
                    className="dropdown-item d-flex fs-18"
                    onClick={() =>
                      handleAddCateProduct(
                        item.id_sp,
                        "addCateKinhdoanh",
                        index,
                      )
                    }
                  >
                    Thêm vào khách kinh doanh
                  </li>
                  <li
                    className="dropdown-item d-flex fs-18"
                    onClick={() =>
                      handleAddCateProduct(item.id_sp, "addCateKhachnet", index)
                    }
                  >
                    Thêm vào khách nét
                  </li>
                  <li
                    className="dropdown-item d-flex fs-18"
                    onClick={() =>
                      handleAddCateProduct(item.id_sp, "addCateKhachhen", index)
                    }
                  >
                    Thêm vào khách hẹn
                  </li>
                  <li
                    className="dropdown-item d-flex fs-18"
                    onClick={() => handleModalEditDataSale(item.id_sp, index)}
                  >
                    Sửa thông tin
                  </li>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        )
      })}
      {/* Hiệu ứng tải thêm dữ liệu */}
      {(!isLoading) ? (
        dataCusSale.length > 50 &&
        (dataCustomerSale.length > 0 ? (
          dataCustomerSale.length >= 50 ? (
            <div className="mt-3 mb-3 text-center">Đang tải...</div>
          ) : (
            ""
          )
        ) : (
          <div className="mt-3 mb-3 text-center">Chưa có dữ liệu.</div>
        ))
      ) : (
          dataCusSale.length >0 && <div className="mt-3 mb-3 text-center">Bạn đã xem hết rồi.</div>
      )}
      {dataCusSale.length === 0 && (
        <div className="mt-3 mb-3 text-center">Chưa có khách hàng.</div>
      )}
      {/* Modal detail */}
      {dataDetail.length > 0 && (
        <ModalReact
          modalTitle="Thôn tin chi tiết"
          showModal={modalDetail}
          handleClose={handleCloseModalDetail}
          theme={<DetailInfoCustomer data={dataDetail} />}
        />
      )}
      {/* Modal note */}
      {modalNote && (
        <ModalReact
          modalTitle="Danh sách ghi chú"
          showModal={modalNote}
          handleClose={handleCloseModalDetailNote}
          theme={
            <ListDetailNoteSanpham
              dataNote={dataDetailNote.current}
              socketio={socketio}
              result={handleNoteSuccess}
            />
          }
        />
      )}
      {/* Modal đặt lịch hẹn */}
      {modalAddBooking && (
        <ModalReact
          modalTitle="Đặt lịch hẹn"
          showModal={modalAddBooking}
          handleClose={handleCloseModalAddBooking}
          theme={
            <AddBookingCateProduct
              checkData={dataModalAddBooking.current}
              knSanpham={idSanpham}
              dataUser={dataUser}
              socketio={socketio}
              resultStatus={handleHidenModalAddBooking}
            />
          }
        />
      )}
      {/* Modal edit data sale */}
      {modalEditSale && (
        <ModalReact
          modalTitle="Sửa thông tin"
          showModal={modalEditSale}
          handleClose={handleCloseModalEditDataSale}
          theme={
            <FormEditDataSale
              dataSaleEdit={editDataSale.current}
              resultStatus={handleResultStatusEdit}
            />
          }
        />
      )}
    </>
  )
}
export default memo(ListDataSaleMobile)


// Chi tiết khách hàng.
function DetailInfoCustomer({data}) {
  return (
    <div>
      <h6>{data[0].diachi}</h6>
      <p className="mb-0">{data[0].quanhuyen + ", " + data[0].thanhpho}</p>
      <div className="mb-3 mt-3 border-top border-block-start-dashed"></div>
      <div>
        <p className="mb-0">Diện tích & mặt tiền: {data[0].dtmb_mt}</p>
        <p className="mb-0">
          Hướng: {data[0].huongnha !== "0" ? data[0].huongnha : "Liên hệ"}
        </p>
        <p className="mb-0">
          Giá:{" "}
          {handleFormatPrice(Number(data[0].giasanpham.replace(/[,]/g, "")))}
        </p>
      </div>
      <div className="mb-3 mt-3 border-top border-block-start-dashed"></div>
      <div>
        <p>{data[0].hoten + ": " + data[0].sodienthoai}</p>
      </div>
      <div className="mb-3 mt-3 border-top border-block-start-dashed"></div>
      <div className="text-muted">
        <p className="mb-1">
          Tình trạng:{" "}
          {data[0].tinhtrang === 0 ? (
            "Còn hàng"
          ) : (
            <span className="text-danger">Đã bán</span>
          )}
        </p>
        <p className="mb-1">
          Trạng thái:{" "}
          {data[0].status === 0 ? (
            "Đang sale"
          ) : (
            <span className="text-success">Đã quảng cáo</span>
          )}
        </p>
        <p className="mb-1">
          Cập nhật:{" "}
          {moment(data[0].ngaycapnhat)
            .locale("vi")
            .fromNow()
            .replace("một", "1")}
        </p>
      </div>
      <div className="mb-3 mt-3 border-top border-block-start-dashed"></div>
      <div className="d-flex align-items-center gap-3 text-muted justify-content-end">
        <span>Tác giả:</span>
        <div className="d-flex align-items-center gap-1">
          <span
            className={cx(
              "avatar avatar-sm me-2 avatar-rounded",
              data[0].online_offline === 1 && "online",
            )}
          >
            <img src={"/" + data[0].avatar_author} alt="img" />
          </span>
          <span>{data[0].name_author}</span>
        </div>
      </div>
    </div>
  )
}

// Danh sách ghi chú chi tiết sản phẩm
function ListDetailNoteSanpham({ dataNote, socketio, result }) {
  const [dataListNote, setDataListNote] = useState([])
  const { dataUser } = useContext(AppContext)
  const [valueAddNote, setValueAddNote] = useState("")
  const [showAddNote, setShowAddNote] = useState(false)
  const [checkEditSuccess, setCheckEditSuccess] = useState(false)
  // Quản lý hiển thị danh sách note.
  useEffect(() => {
    if (dataNote.length > 0) {
      const params = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: {
          kn_sanpham: dataNote[0].id_sp,
        },
      }
      socketio.emit("client-get-data-note-ipsp-sanpham", params)
      socketio.on("server-get-data-note-ipsp-sanpham", (data) => {
        setDataListNote([...data])
      })
    }
    return () => {
      socketio.off("client-get-data-note-ipsp-sanpham")
      socketio.off("server-get-data-note-ipsp-sanpham")
    }
  }, [showAddNote, checkEditSuccess])

  // Quản lý thêm mới ghi chú.
  // Hiển thị trường nhập note.
  const handleShowAddNote = () => {
    setShowAddNote(true)
  }
  const handleValueAddNote = (e) => {
    setValueAddNote(e.target.value)
  }
  // Xử lý lưu lại ghi chú (thêm ghi chú mới).
  const handleSavedNote = () => {
    // xử lý thêm mới ghi chú
    const paramsAddNote = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        kn_sanpham: dataNote[0].id_sp,
        ghichu: valueAddNote,
        tacgia: dataUser[0].id_user
      }
    };
    
    socketio.emit("client-create-data-note-sanpham", paramsAddNote)
    // Ẩn trường nhập note.
    setShowAddNote(false)
    setValueAddNote("")
  }

  // Quản lý cập nhật dữ liệu mới cho chi chú.
  useEffect(() => {
    return () => {
      socketio.off("client-create-data-note-sanpham")
    }
  }, [showAddNote, checkEditSuccess])

  // Quản lý edit ghi chú.
  const [valueEdit, setValueEdit] = useState("")
  const handleButtonSavedEditNote = (idNote) => {
    const paramsObject = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        id_ghichu: idNote,
        ghichu: valueEdit,
        ngaycapnhat: moment().format("YYYY-MM-DD HH:mm:ss"),
      },
    }
    socketio.emit("client-edit-data-note-sanpham", paramsObject)
    alert("Lưu thành công.")
    setTimeout(() => {
      setCheckEditSuccess(true)
    }, 100);
  }
  // check Edit note.
  const checkNoteEdit = useRef()
  const handleCheckEdit = (idNote) => {
    checkNoteEdit.current = idNote
  }
  // Quản lý hủy socket edit note
  useEffect(() => {
    return () => {
      socketio.off("client-edit-data-note-sanpham")
    }
  }, [checkNoteEdit.current])

  return (
    <div>
      <div className="text-center">
        {!showAddNote && (
          <button
            type="button"
            className="border-0 rounded-3 px-3 py-2"
            onClick={handleShowAddNote}
          >
            Thêm ghi chú
          </button>
        )}
        <div style={{ display: showAddNote ? "block" : "none" }}>
          <textarea
            type="text"
            placeholder="Nhập ghi chú..."
            value={valueAddNote}
            onChange={handleValueAddNote}
            className="form-control mt-3"
            rows={3}
          />
          <button
            type="button"
            onClick={handleSavedNote}
            className="btn btn-outline-dark mt-3"
          >
            Lưu lại
          </button>
        </div>
      </div>
      {dataNote.length === 0 && (
        <div className="text-center">Chưa có ghi chú</div>
      )}
      {dataNote.length > 0 && (
        <div className="mt-3">
          <div className="text-center">
            <h6 className="fs-18">{dataNote[0].diachi}</h6>
          </div>
          <div className="border-top border-block-start-dashed mb-3 mt-3"></div>
          <div className="card-body">
            <div>
              <ul className="list-unstyled mb-0 crm-recent-activity">
                {dataListNote.length > 0 &&
                  dataListNote.map((item) => {
                    return (
                      <li
                        className="crm-recent-activity-content"
                        key={item.id_ghichu}
                      >
                        <div className="d-flex align-items-top">
                          <div className="me-3">
                            <span
                              className={cx(
                                "avatar avatar-sm me-2 avatar-rounded",
                                item.onlineoffline === 1 && "online",
                              )}
                            >
                              <img src={"/" + item.avatar} alt="img" />
                            </span>
                            {/* <span className="avatar avatar-xs bg-primary-transparent avatar-rounded">
                              <GoDotFill className="fs-18" />
                              
                            </span> */}
                          </div>
                          <div className="crm-timeline-content">
                            <div className="d-flex align-items-center mb-1">
                              <span className="fw-5">{item.hoten}</span>
                            </div>
                            {/* <span style={{ display: !showEditNote ? "block" : "note" }}>{item.ghichu}</span> */}
                            <textarea
                              type="text"
                              className="border-0 op-6"
                              value={
                                item.ghichu
                                  ? checkNoteEdit.current === item.id_ghichu
                                    ? valueEdit
                                    : item.ghichu
                                  : item.ghichu
                              }
                              onChange={(e) => { setValueEdit(e.target.value); setCheckEditSuccess(false)}}
                              onClick={() => handleCheckEdit(item.id_ghichu)}
                            />

                            {(valueEdit && checkNoteEdit.current === item.id_ghichu && !checkEditSuccess) && (
                              <button
                                className="border-0 rounded-3 px-3 fs-12 text-success"
                                onClick={() =>
                                  handleButtonSavedEditNote(item.id_ghichu)
                                }
                              >
                               Lưu lại
                              </button>
                            )}
                          </div>
                          <div className="flex-fill text-end">
                            <span className="d-block text-muted fs-18 op-7">
                              {/* {moment(item.ngaycapnhat).format(
                                "DD-MM-YYYY HH:mm:ss",
                              )} */}
                              {moment(item.ngaycapnhat)
                                .locale("vi")
                                .fromNow()
                                .replace("một", "1")}
                            </span>
                          </div>
                        </div>
                      </li>
                    )
                  })}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

// Xử lý đặt lịch hẹn.
function AddBookingCateProduct({ checkData, knSanpham, dataUser, socketio, resultStatus }) {
  const [valueDate, setValueDate] = useState("")
  const handleGetValueDate = (e) => {
    setValueDate(e.target.value);
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    if (valueDate) {
      // Kiểm tra xem đã tồn tại dữ liệu chưa.
      if (checkData.length > 0) {
        // Xử lý edit booking.
        const params = {
          iden: process.env.REACT_APP_IDEN_DASH,
          object: {
            id_cate_product: checkData[0].id_cate_product,
            booking: valueDate,
          },
        }
        socketio.emit("client-edit-row-booking-cate-product", params)
        alert("Bạn đã thêm vào khách hẹn")
        resultStatus(true) // ẩn modal đặt lịch hẹn
      } else {
        // Xử lý create data.
        const params = {
          iden: process.env.REACT_APP_IDEN_DASH,
          object: {
            kn_sanpham: knSanpham,
            booking: valueDate,
            tacgia: dataUser[0].id_user,
          },
        }
        socketio.emit("client-create-data-cate-product", params)
        alert("Bạn đã thêm mới vào mục khách hẹn.")
        resultStatus(true) // ẩn modal đặt lịch hẹn
      }
    } else {
      alert("Bạn cần nhập thời gian hẹn.")
    }
  }
  useEffect(() => {
    return () => {
      socketio.off("client-edit-row-booking-cate-product")
      socketio.off("client-create-data-cate-product")
    }
  }, [valueDate])

  return (
    <form onSubmit={handleSubmit}>
      <div className="col-xl-12 col-md-12">
        <div className="form-floating mb-3">
          <input type="datetime-local" value={valueDate} onChange={handleGetValueDate} className="form-control" placeholder="Tên input" />
          <label>Lịch hẹn</label>
        </div>
      </div>
      {/* Button */}
      <div className="px-4 text-center py-3 border-top border-block-start-dashed d-sm-flex justify-content-end">
        <button className="btn btn-primary-light m-1" type="submit">
          Lưu lại
        </button>
      </div>
    </form>
  )
}