import { useEffect, useRef, useState } from "react";
import { List } from "react-virtualized";
import LoadingSpinner from "../../../../Components/Loading/LoadingSpinner";
import moment from "moment";
import { BsDot } from "react-icons/bs";

function ListContractReport({ socketio, dataUser, dataContract }) {
    const divContainer = useRef();
    const [loading, setLoading] = useState(true)
 
    useEffect(() => {
        if (divContainer.current) {
            setLoading(false)
        }
        
    }, [])


    return (
        <div
            className="col-12"
            ref={divContainer}
            style={{ width: "100%", height: "81vh" }}
        >
            <ul className="list-group my-2">
                {loading && <LoadingSpinner />}
                {/* Hien thi du lieu */}
                {!loading && (
                    <List
                        width={divContainer.current.offsetWidth}
                        height={divContainer.current.offsetHeight}
                        rowCount={dataContract.length} // số lượng sản phẩm
                        rowHeight={69} // chiều cao của từng sản phẩm
                        //overscanRowCount={9} //tải thêm 5 sản phẩm ngoài viewport
                        rowRenderer={({ index, key, style }) => {
                            // Set item
                            let item = dataContract[index]
                            return (
                              <li
                                className="list-group-item border-top-0 border-start-0 border-end-0 p-0"
                                key={key}
                                style={style}
                              >
                                <div className="d-flex align-items-top">
                                  <img
                                    className="avatar me-2 avatar-rounded"
                                    src={
                                      "https://system.view360.vn/" +
                                      item.nvc_avatar
                                    }
                                    alt="Image description"
                                  />
                                  <div className="mt-0">
                                    <p className="mb-0 fw-semibold">
                                      {item.contract_name}
                                    </p>
                                    <p className="mb-0 text-muted fs-16">
                                      {moment(item.addtime)
                                        .locale("vi")
                                        .fromNow()
                                        .replace("một", "1")}
                                      <BsDot />
                                      {moment(item.addtime).format(
                                        "DD/MM/YYYY",
                                      )}
                                    </p>
                                  </div>
                                  <span className="ms-auto">
                                    <span className="float-end text-than fw-semibold">
                                      {Number(item.price_service)
                                        .toLocaleString()
                                        .replace(/[.]/g, ",")}
                                    </span>
                                  </span>
                                </div>
                              </li>
                            )
                        }}
                    />
                )}
            </ul>
        </div>
    );
}

export default ListContractReport