import { BsCreditCard } from "react-icons/bs"
import { BsCardChecklist } from "react-icons/bs"
import SearchHorizontal from "../../../../Components/Search/SearchHorizontal"
import { useEffect, useState } from "react"

function PayHisUserTop({socketio, result}) {
    const [revenue, setRevenue] = useState([])
    const [revenueSuccess, setRevenueSuccess] = useState([])
    const [yearRevenueSuccess, setYearRevenueSuccess] = useState([])

    // Get data
    useEffect(() => {
        const params = { iden: process.env.REACT_APP_IDEN_BDSVIEW }
        // Doanh thu theo tháng (tạm tính)
        socketio.emit("client-month-revenue-payhis", params)
        // doanh thu theo tháng đã duyệt
        socketio.emit("client-month-revenue-success-payhis", params)
        // Doanh thu theo năm đã duyệt
        socketio.emit("client-year-revenue-success-payhis", params)
        
        return () => {
            socketio.off("client-data-for-admin")
            socketio.off("client-month-revenue-payhis")
            socketio.off("client-year-revenue-success-payhis")
        }
    }, []);

    // Quản lý Nhận dữ liệu
    useEffect(() => {
        // Doanh thu theo tháng
        socketio.on("server-month-revenue-payhis", (data) => {
            setRevenue(data)
        })
        // Doanh thu theo tháng đã duyệt
        socketio.on("server-month-revenue-success-payhis", (data) => {
            setRevenueSuccess(data)
        })
        // Doanh thu theo năm đã duyệt.
        socketio.on("server-year-revenue-success-payhis", (data) => {
            setYearRevenueSuccess(data)
        })
        
        return () => {
            socketio.off("server-month-revenue-payhis")
            socketio.off("server-month-revenue-success-payhis")
            socketio.off("server-year-revenue-success-payhis")
        }
    }, []);
  
  // Giá trị tìm kiếm.
  const handleResult = (keyword) => {
    result(keyword)
  }


    return (
      <div className="mb-2">
        <div className="row">
          <div className="col-xl-4 col-12">
            <div className="card custom-card text-fixed-white">
              <div className="card-body p-0">
                <div className="d-flex align-items-top p-4 flex-wrap">
                  <div className="me-3 lh-1">
                    <span
                      className="avatar avatar-md avatar-rounded text-white shadow-sm"
                      style={{ background: "rgb(244 123 44 / 71%)" }}
                    >
                      <BsCreditCard />
                    </span>
                  </div>
                  <div className="flex-fill">
                    <h6 className="fw-semibold mb-1">
                      {revenue.length > 0
                        ? Number(revenue[0].total_month_revenue)
                            .toLocaleString()
                            .replace(/[.]/g, ",")
                        : 0}
                      đ
                    </h6>
                    <p className="op-7 mb-0 fs-15">Danh thu tháng tạm tính</p>
                  </div>
                  {/* <div>
                    <span className="badge bg-success">
                      <i className="ri-arrow-up-s-line align-middle me-1 d-inline-block"></i>
                      14.69%
                    </span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-12">
            <div className="card custom-card text-fixed-white">
              <div className="card-body p-0">
                <div className="d-flex align-items-top p-4 flex-wrap">
                  <div className="me-3 lh-1">
                    <span className="avatar avatar-md avatar-rounded text-white shadow-sm bg-success">
                      <BsCardChecklist />
                    </span>
                  </div>
                  <div className="flex-fill">
                    <h6 className="fw-semibold mb-1">
                      {revenueSuccess.length > 0
                        ? Number(revenueSuccess[0].total_month_revenue)
                            .toLocaleString()
                            .replace(/[.]/g, ",")
                        : 0}
                      đ
                    </h6>
                    <p className="op-7 mb-0 fs-15">Danh thu tháng đã duyệt</p>
                  </div>
                  {/* <div>
                    <span className="badge bg-success">
                      <i className="ri-arrow-up-s-line align-middle me-1 d-inline-block"></i>
                      14.69%
                    </span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-12">
            <div className="card custom-card text-fixed-white">
              <div className="card-body p-0">
                <div className="d-flex align-items-top p-4 flex-wrap">
                  <div className="me-3 lh-1">
                    <span
                      className="avatar avatar-md avatar-rounded text-white shadow-sm"
                      style={{ background: "rgb(102 44 244 / 71%)" }}
                    >
                      <BsCreditCard />
                    </span>
                  </div>
                  <div className="flex-fill">
                    <h6 className="fw-semibold mb-1">
                      {yearRevenueSuccess.length > 0
                        ? Number(yearRevenueSuccess[0].total_month_revenue)
                            .toLocaleString()
                            .replace(/[.]/g, ",")
                        : 0}
                      đ
                    </h6>
                    <p className="op-7 mb-0 fs-15">Danh thu năm</p>
                  </div>
                  {/* <div>
                    <span className="badge bg-success">
                      <i className="ri-arrow-up-s-line align-middle me-1 d-inline-block"></i>
                      14.69%
                    </span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <SearchHorizontal className="border-1 rounded-3" result={handleResult} placeholder="Họ tên, số điện thoại"/>
        </div>
      </div>
    )
}

export default PayHisUserTop
