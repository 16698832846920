import { useState, useContext } from "react"
import { IoAddOutline } from "react-icons/io5"
import { MdOutlineSupportAgent } from "react-icons/md"
import { Tb360View } from "react-icons/tb"

import { Link, useNavigate } from "react-router-dom"
import classNames from "classnames/bind"

import ModalReact from "../../../Components/Modal/ModalReact"
import styles from "../DataSale.module.scss"
import FormAddDataSale from "../Component/FormAddDataSale"


const cx = classNames.bind(styles)

function ButtonAddDataSale() {
  // Button Add Product.
  const [modalAddProduct, setModalAddProduct] = useState(false)
  
  const handleShowModalAddProduct = () => {
    setModalAddProduct(true)
  }
  const handleCloseAddProduct = () => setModalAddProduct(false)



  // Ẩn modal nếu đăng tin thành công.
  const checkAddEstateSuccess = (status_) => {
    if (status_) {
      setModalAddProduct(false)
    }
  }

  return (
    <>
      <button
        className={cx("AddProduct", "border-0")}
        onClick={handleShowModalAddProduct}
      >
        <IoAddOutline className={cx("fs-29", "IconAdd")} />
      </button>
      {/* <div className={cx("SidebarWrap")}>
        <div className={cx("Sidebar_Wrapper", "gap-3")}>
          <div className="d-flex flex-direction-column align-items-center gap-1">
            <button
              className={cx("AddProduct", "border-0")}
              style={{ position: "fixed", top: "100px" }}
              onClick={handleShowModalAddProduct}
            >
              <IoAddOutline className={cx("fs-29", "IconAdd")} />
            </button>
            <span className={cx("fw-5 fs-15")}>Thêm mới</span>
          </div>
        </div>
      </div> */}
      {/* Modal add data sale product */}
      <ModalReact
        modalTitle="Thêm mới khách hàng"
        showModal={modalAddProduct}
        handleClose={handleCloseAddProduct}
        theme={<FormAddDataSale resultStatus={checkAddEstateSuccess} />}
      />
    </>
  )
}

export default ButtonAddDataSale

