import classNames from "classnames/bind";
import styles from "../../Home.module.scss";
import { images } from "../../../../assets/images"
import CateMenuApp from "../Component/CateMenuApp";

const cx = classNames.bind(styles);
// Giao diện PC.
function HomePage1PC() {

    return (
        <div
            className={cx("wrapper_HP")}
            style={{ background: "url(" + images.bgImage + ")" }}
        >
            <div className={cx("text-center mt-3")}>
                <h3 style={{ color: "white" }} className="fs-21">Chào mừng bạn đến với hệ thống !</h3>
            </div>
            {/* content */}
            <div className={cx("d-flex mt-3 justify-content-center")}>
                <CateMenuApp />
            </div>
        </div>
    );
}

export default HomePage1PC
