import moment from "moment"
import { useContext, useEffect, useLayoutEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import classNames from "classnames/bind"
import { IoCalendarOutline, IoDocumentTextOutline, IoRocketOutline } from "react-icons/io5"
import { GiProgression } from "react-icons/gi"

import AppContext from "../../../handle-reuses/Contexts/CreateContexts"
import styles from "../Job.module.scss";
import SearchHorizontal from "../../../Components/Search/SearchHorizontal"
import { IoMdAdd } from "react-icons/io"
import SearchDataJobMobile from "../Component/Job/SearchDataJobMobile"
import ModalReact from "../../../Components/Modal/ModalReact"
import FormAddJob from "../Component/Job/FormAddJob"
import ListTongquan from "../Component/Job/ListTongquan"
import ListEvaluate from "../Component/Job/ListEvaluate"
import LoadingSpinner from "../../../Components/Loading/LoadingSpinner"
import { BsDot } from "react-icons/bs"
const cx = classNames.bind(styles);

function JobPageMobile() {
    const { socketio, dataUser } = useContext(AppContext)
    const navigate = useNavigate()
    useLayoutEffect(() => {
      if (dataUser[0].congviec === 0) {
        navigate("/")
      }
    }, [])

    const [objectSearch, setObjectSearch] = useState({
      monthSearch: moment().format("YYYY-MM"),
      textSearch: "",
    })

    // Quản lý search.
    const handleValueObjectSearch = (object) => {
        setObjectSearch({
            monthSearch: object.monthSearch,
            textSearch: object.textSearch
        })
    }

    // Quản lý danh mục.
    const [activeCate, setActiveCate] = useState("list-congviec")
    const handleActiveCate = (cate) => {
      setActiveCate(cate)
    }
    // Quản lý thêm mới công việc.
    const [modalAddJob, setModalAddJob] = useState(false)
    const handleModalAddJob = () => {
      setModalAddJob(true)
    }
    const handleCloseModalAddJob = () => {
      setModalAddJob(false)
    }
    return (
      <div>
        {/* Cate */}
        <div className="button-scroll">
          <div
            className={cx("d-flex alig-items-center gap-3 mb-3")}
            style={{ width: "360px" }}
          >
            <button
              className={cx(
                "border-0 bg-transparent",
                activeCate === "list-congviec" ? "color-orange" : "color-gray",
              )}
              onClick={() => handleActiveCate("list-congviec")}
            >
              <div className="d-flex align-items-center">
                <IoCalendarOutline className="me-1" />
                Công việc
              </div>
            </button>
            <button
              className={cx(
                "border-0 bg-transparent",
                activeCate === "tien-do" ? "color-orange" : "color-gray",
              )}
              onClick={() => handleActiveCate("tien-do")}
            >
              <div className="d-flex align-items-center">
                <GiProgression className="me-1" />
                Tiến độ
              </div>
            </button>
            <button
              className={cx(
                "border-0 bg-transparent",
                activeCate === "danh-gia" ? "color-orange" : "color-gray",
              )}
              onClick={() => handleActiveCate("danh-gia")}
            >
              <div className="d-flex align-items-center">
                <IoRocketOutline className="me-1" />
                Đánh giá
              </div>
            </button>
          </div>
        </div>
        {/* Search */}
        <div className={cx("wrapper_ground")}>
          {/* Search month*/}
          <div className="list_items">
            <SearchDataJobMobile resultSearch={handleValueObjectSearch} />
          </div>
          <div className="list_items">
            <button
              className={cx(
                "btn btn-purple-gradient btn-wave col-12",
                "border-0 rounded-3 d-flex align-items-center justify-content-center",
              )}
              onClick={handleModalAddJob}
            >
              <IoMdAdd className="fs-21" />
            </button>
          </div>
        </div>

        <div className="text-center mb-3 mt-3">
          <div className="fs-16 alert alert-primary">
            Quản lý công việc tháng{" "}
            <span className="fw-5">
              {moment(objectSearch.monthSearch).format("MM/YYYY")}
            </span>
          </div>
        </div>
        {/* List content */}
        <div className={cx("mt-3")}>
          {/* Tiến độ */}
          {activeCate === "list-congviec" && objectSearch && (
            <ListTongquan
              objectSearch={objectSearch}
              socketio={socketio}
              dataUser={dataUser}
            />
          )}
          {activeCate === "tien-do" && (
            <ListContentProgress
              objectSearch={objectSearch}
              socketio={socketio}
              dataUser={dataUser}
            />
          )}
          {activeCate === "danh-gia" && (
            <ListEvaluate
              objectSearch={objectSearch}
              socketio={socketio}
              dataUser={dataUser}
            />
          )}
        </div>
        {/* Modal add job */}
        {modalAddJob && (
          <ModalReact
            modalTitle="Thêm mới công việc"
            showModal={modalAddJob}
            handleClose={handleCloseModalAddJob}
            theme={<FormAddJob socketio={socketio} dataUser={dataUser} />}
          />
        )}
      </div>
    )
}
export default JobPageMobile

// List tiến độ.
function ListContentProgress({objectSearch, socketio, dataUser}) {
  // Quản lý list progress.
  const [dataProgress, setDataProgress] = useState([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        role: dataUser[0].list_tiendo,
        month: objectSearch.monthSearch,
        textSearch: objectSearch.textSearch,
        user_id: dataUser[0].id_user,
      },
    }
    socketio.emit("client-get-search-data-role-progress-job", params)
    socketio.on("server-get-search-data-role-progress-job", (data) => {
      setDataProgress([...data])
      setLoading(false)
    })
    return () => {
      socketio.off("client-get-search-data-role-progress-job")
      socketio.off("server-get-search-data-role-progress-job")
    }
  }, [objectSearch])

  if (loading) {
    return <LoadingSpinner />
  }
    
    return <div>
        {dataProgress.map((item, index) => {
          // Set tiến độ.
          let progress = (
            (Number(item.success_job) / Number(item.total_job)) *
            100
          ).toFixed(2)
          return (
            <div className="mb-3" key={index}>
              <div className="alert alert-light fw-5 mb-2 fs-15 d-flex align-items-center">
                <span
                  className={cx(
                    "avatar avatar-lg avatar-rounded me-2",
                    item.onlineoffline === 1 && "online",
                  )}
                >
                  <img
                    src={"https://system.view360.vn/" + item.avatar}
                    alt="img"
                    style={{ border: "2px solid white" }}
                  />
                </span>
                <div>
                  {item.hoten}
                  <p className="fw-4 fs-14 mb-0 text-muted">
                    Tiến độ đạt <span className="fw-5 fs-15">{progress}%</span>
                    <BsDot />
                    <span>
                      {progress >= 99 && (
                        <span className="text-success fs-14">Tốt</span>
                      )}
                      {progress < 99 && progress > 70 && (
                        <span className="text-warning fs-14">Cần cố gắng</span>
                      )}
                      {progress <= 70 && (
                        <span className="text-danger fs-14">Báo động</span>
                      )}
                    </span>
                  </p>
                </div>
              </div>
              <div
                className="progress progress-lg custom-progress-3 progress-animate"
                role="progressbar"
                aria-valuenow="50"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                <div
                  className={cx(
                    "progress-bar m-0 progress-bar-striped",
                    progress >= 99 && "bg-success-1",
                    progress < 99 && progress > 70 && "bg-warning-1",
                    progress <= 70 && "bg-danger",
                  )}
                  style={{ width: progress + "%" }}
                >
                  <div
                    className="progress-bar-value border-0 d-flex align-items-center justify-content-end"
                    style={{ background: "none" }}
                  >
                    <span
                      className={cx(
                        "avatar avatar-sm avatar-rounded",
                        item.onlineoffline === 1 && "online",
                      )}
                    >
                      <img
                        src={"https://system.view360.vn/" + item.avatar}
                        alt="img"
                        style={{ border: "2px solid white" }}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
  </div>
}