import { useContext } from "react"
import ContractsView360 from "./Page"
import AppContext from "../../../handle-reuses/Contexts/CreateContexts"

function ContractsView360Page() {
    const { dataUser } = useContext(AppContext)

    if (dataUser[0].goidichvu_user !== 1) {
      return (
        <div className="text-center mt-3 fs-15 text-danger">
          Bạn không có quyền truy cập trang này.
        </div>
      )
    } else {
      return <ContractsView360 />
    }
    
}
export default ContractsView360Page