import classNames from "classnames/bind";
import styles from "../DataSale.module.scss"
import { Link, useLocation } from "react-router-dom";
import { useContext, useLayoutEffect, useState } from "react";
import moment from "moment";
import AppContext from "../../../handle-reuses/Contexts/CreateContexts";
const cx = classNames.bind(styles);

function ListCateDataSaleMobile() {
  const {dataUser, socketio} = useContext(AppContext)
  const path = useLocation()
  const currentPath = path.pathname;
  const [activeCate, setActiveCate] = useState(currentPath)
  const handleActiveCate = (cate) => {
    setActiveCate(cate)
  }


  // Quản lý thông báo khách hẹn.
  const [notifiKhachhen, setNotifiKhachhen] = useState(0)
  const [notifiHethan, setNotifiHethan] = useState(0)
  useLayoutEffect(() => {
    if (dataUser.length > 0) {
      const paramsCate = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: {
          author: dataUser[0].id_user,
          goidichvu: dataUser[0].goidichvu_user,
        },
      }
      // Notifi Khách hẹn
      socketio.emit("client-get-data-cate-product-booking", paramsCate)
      socketio.on("server-get-data-cate-product-booking", (dataKhachhen) => {
        const filterDataKhachhen = dataKhachhen.filter((item) => {
          let booking_ = moment(item.booking)
          let currentTime = moment()
          let diffBooking = booking_.diff(currentTime, "days")
          return diffBooking === 0
        });
        setNotifiKhachhen(filterDataKhachhen.length)
      })
      // Notifi khách hết hạn.
      socketio.emit("client-data-expired-ads-data-sale-sanpham", paramsCate)
      socketio.on("server-data-expired-ads-data-sale-sanpham", (dataKhachhethan) => {
        const filterDataKhachhethan = dataKhachhethan.filter((item) => {
          let booking_ = moment(item.expired)
          let currentTime = moment()
          let diffBooking = booking_.diff(currentTime, "days")
          return diffBooking === 0
        })
        setNotifiHethan(filterDataKhachhethan.length)
      })
    }
    return () => {
      socketio.off("client-get-data-cate-product-booking")
      socketio.off("server-get-data-cate-product-booking")
      socketio.off("client-data-expired-ads-data-sale-sanpham")
      socketio.off("server-data-expired-ads-data-sale-sanpham")
    }
  }, [currentPath]);

    return (
      <div className={cx("mb-3", "listCateMobile")}>
        <ul className="d-flex align-items-center gap-2">
          <li onClick={() => handleActiveCate("/data-sale")}>
            <Link
              to="/data-sale"
              className={cx(
                "itemCateMobile",
                activeCate === "/data-sale" ? "active" : "",
              )}
            >
              Tất cả
            </Link>
          </li>
          <li onClick={() => handleActiveCate("/khach-kinh-doanh")}>
            <Link
              to="/khach-kinh-doanh"
              className={cx(
                "itemCateMobile",
                activeCate === "/khach-kinh-doanh" ? "active" : "",
              )}
            >
              <span>K.Kinh doanh</span>
            </Link>
          </li>
          <li onClick={() => handleActiveCate("/khach-net")}>
            <Link
              to="/khach-net"
              className={cx(
                "itemCateMobile",
                activeCate === "/khach-net" ? "active" : "",
              )}
            >
              Khách nét
            </Link>
          </li>
          <li onClick={() => handleActiveCate("/khach-hen")}>
            <Link
              to="/khach-hen"
              className={cx(
                "position-relative",
                "itemCateMobile",
                activeCate === "/khach-hen" ? "active" : "",
              )}
            >
              Khách hẹn
              {notifiKhachhen > 0 && (
                <span className={cx("ms-1", "notifi")}>
                  {notifiKhachhen}
                </span>
              )}
            </Link>
          </li>
          <li onClick={() => handleActiveCate("/khach-het-han")}>
            <Link
              to="/khach-het-han"
              className={cx(
                "position-relative",
                "itemCateMobile",
                activeCate === "/khach-het-han" ? "active" : "",
              )}
            >
              K.Hết hạn
              {notifiHethan > 0 && (
                <span className={cx("ms-1", "notifi")}>
                  {notifiHethan}
                </span>
              )}
            </Link>
          </li>
        </ul>
      </div>
    )
}
export default ListCateDataSaleMobile