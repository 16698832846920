import moment from "moment/moment";
import { useState, useEffect, useRef } from "react";


function ListSupport({ socketio }) {
    const [dataSupport, setDataSupport] = useState()
    const limit = 100;
    const page = useRef(1)
    //Quản lý Get data
    useEffect(() => {
        const params = {
            iden: process.env.REACT_APP_IDEN_BDSVIEW,
            object: {
                quantity: limit,
                offset: (page.current - 1) * limit
            }
        }
        socketio.emit("client-get-support-admin", params)
        return () => {
            socketio.off("client-get-support-admin")
        }
    }, [])
    //Quản lý Nhận data
    useEffect(() => {
        socketio.on("server-get-support-admin", (data) => {
            setDataSupport(data);
        })
        return () => {
            socketio.off("server-get-support-admin")
        }
    }, [])

    // Xác nhận đã giải quyết.
    const handleResponse = (id_) => {
        let check_ = window.confirm("Bạn đã giải quyết xong rồi chứ?.")
        if (check_) {
            const params = {
                iden: process.env.REACT_APP_IDEN_BDSVIEW,
                object: {
                    id: id_,
                    status: 1
                },
                objectUpdate: {
                    quantity: limit,
                    offset: (page.current - 1) * limit
                }
            }
            socketio.emit("client-edit-status-support", params)
          // Cập nhận lại thông báo.
          socketio.emit("client-send-support-notifi", params)
        }
    }

    if (!dataSupport) {
        return <div className="text-center mt-5">Đang tải dữ liệu...</div>
    }
    if (dataSupport.length === 0) {
        return <div className="text-center mt-5">Chưa có yêu cầu hỗ trợ nào.</div>
    }
    return (
        <div className="table-responsive">
            <table className="table text-nowrap">
                <thead>
                    <tr>
                        <th scope="col">Thông tin yêu cầu</th>
                        <th scope="col">Người gửi đến</th>
                        <th scope="col">Thời gian</th>
                        <th scope="col">Lựa chọn</th>
                    </tr>
                </thead>
                <tbody className="table-group-divider">
                    {dataSupport.map((item) => {
                        return (
                          <tr key={item.id}>
                            <th scope="row">
                              <div className="flex-fill">
                                <p className="mail-msg mb-0">
                                  <span className="d-block mb-0 fw-semibold text-truncate">
                                    {item.title}
                                  </span>
                                  <span className="text-muted text-wrap fw-4 text-truncate">
                                    {item.discription}
                                  </span>
                                </p>
                              </div>
                            </th>
                            <td>
                              <div className="flex-fill">
                                <p className="mail-msg mb-0">
                                  <span className="d-block mb-0 fw-semibold text-truncate">
                                    {item.fullname}
                                  </span>
                                  <span className="text-muted text-wrap text-truncate">
                                    {item.fullphone}
                                  </span>
                                </p>
                              </div>
                            </td>
                            <td>
                              <p className="mb-1">
                                <span className="float-start text-muted fw-normal">
                                  <span className="me-2">
                                    <i className="ri-attachment-2 align-middle"></i>
                                  </span>
                                  {moment(item.addtime).locale("vi").fromNow()}
                                </span>
                              </p>
                            </td>
                            <td>
                              {item.status === 0 ? (
                                <button
                                  className="btn btn-outline-dark"
                                  onClick={() => handleResponse(item.id)}
                                >
                                  Giải quyết
                                </button>
                              ) : (
                                <button
                                  className="btn btn-outline-success"
                                >
                                  Đã xử lý
                                </button>
                              )}
                            </td>
                          </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    );
}

export default ListSupport