import { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"

function FormAddContractTech({ socketio, dataUser, result }) {
    const { register, handleSubmit, formState: { errors }, reset } = useForm()
    
    // Set format price service.
    const [valuePrice, setValuePrice] = useState("")
    const handleFormatPriceService = (e) => {
        
        let formatPrice = e.target.value.replace(/[.,]/g, "");
        if (Number(formatPrice) >= 1000) {
            let formatPrice_ = Number(formatPrice).toLocaleString().replace(/[.]/g, ",")
            setValuePrice(formatPrice_)
        } else {
            if (Number(formatPrice)) {
                setValuePrice(formatPrice)
            } else {
                if (formatPrice.length > 0) {
                    alert("Bạn cần nhập dạng số")
                } else {
                    setValuePrice(e.target.value)
                }
            }
        }
    }

    const checkChangeCreate = useRef()
    const handleOnSubmit = (object) => {
        object.price_service = object.price_service.replace(/[.,]/g, "")
        object.user_id = dataUser[0].id_user
        // Xử lý thêm mới
        const paramsCreate = {
            iden: process.env.REACT_APP_IDEN_DASH,
            object: object
        }
        socketio.emit("client-create-data-contract-tech", paramsCreate)
        socketio.on("server-create-data-contract-tech", (data) => {
            if (data === "success") {
              alert("Thêm hợp đồng công nghệ thành công.")
              socketio.emit("client-get-data-new-create-success-contract-tech",paramsCreate)
              checkChangeCreate.current = object
            }
        })
    }
    useEffect(() => {
      // Xử lý thêm mới hợp đồng (module_job_contract)
      socketio.on("server-get-data-new-create-success-contract-tech",(data) => {
          if (data.length > 0) {
            let paramsCreateContract = {
              iden: process.env.REACT_APP_IDEN_DASH,
              object: {
                contract_name: "Công nghệ",
                id_detail: data[0].id,
                price_service: data[0].price_service,
                nhanvien_chot: data[0].user_id,
                nhanvien_thitruong: data[0].user_id,
                tien_hoahong: 0,
              },
            }
              socketio.emit("client-create-data-contract", paramsCreateContract)
              result(true)
          }
        },
      )
      // *Xử lý thêm mới hợp đồng (module_job_contract)
      return () => {
        socketio.off("client-create-data-contract-tech")
        socketio.off("server-create-data-contract-tech")

        socketio.off("client-get-data-new-create-success-contract-tech")
        socketio.off("server-get-data-new-create-success-contract-tech")
        socketio.off("client-create-data-contract")
      }
    }, [checkChangeCreate.current])
    return (
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <div className="col-xl-12 col-md-12">
          <div className="row">
            <div className="form-floating mb-3 col-12">
              <input
                type="text"
                className="form-control rounded-3"
                placeholder="Tên input"
                {...register("contract_name", { required: true })}
              />
              <label className="fs-15 ms-2 text-muted">
                Tên hợp đồng <span className="text-danger">*</span>
              </label>
              {errors.contract_name && (
                <span className="text-danger fs-14">
                  Bạn cần nhập tên hợp đồng
                </span>
              )}
            </div>
            <div className="form-floating mb-3 col-12">
              <input
                type="text"
                className="form-control rounded-3"
                placeholder="Tên input"
                {...register("contract_cate")}
              />
              <label className="fs-15 ms-2 text-muted">
                Loại hợp đồng <span className="text-danger">*</span>
              </label>
              {errors.contract_cate && (
                <span className="text-danger fs-14">
                  Bạn cần nhập loại hợp đồng.
                </span>
              )}
            </div>
          </div>

          <div className="row">
            <div className="form-floating mb-3 col-6">
              <input
                type="datetime-local"
                className="form-control rounded-3"
                placeholder="Tên input"
                {...register("date_start", { required: true })}
              />
              <label className="fs-15 ms-2 text-muted">
                Ngày bắt đầu <span className="text-danger">*</span>
              </label>
              {errors.date_start && (
                <span className="text-danger fs-14">
                  Bạn cần chọn ngày bắt đầu
                </span>
              )}
            </div>
            <div className="form-floating mb-3 col-6">
              <input
                type="datetime-local"
                className="form-control rounded-3"
                placeholder="Tên input"
                {...register("date_end")}
              />
              <label className="fs-15 ms-2 text-muted">
                Ngày kết thúc <span className="text-danger">*</span>
              </label>
              {errors.date_end && (
                <span className="text-danger fs-14">
                  Bạn cần chọn ngày kết thúc.
                </span>
              )}
            </div>
          </div>

          <div className="row">
            <div className="form-floating mb-3 col-12">
              <input
                type="text"
                className="form-control rounded-3"
                placeholder="Tên input"
                {...register("price_service", { required: true })}
                value={valuePrice}
                onChange={handleFormatPriceService}
              />
              <label className="fs-15 ms-2 text-muted">
                Giá dịch vụ <span className="text-danger">*</span>
              </label>
              {errors.price_service && (
                <span className="text-danger fs-14">
                  Bạn cần nhập giá dịch vụ
                </span>
              )}
            </div>
          </div>

          <div className="row">
            <div className="form-floating mb-3 col-6">
              <input
                type="text"
                className="form-control rounded-3"
                placeholder="Tên input"
                {...register("customer_name", { required: true })}
              />
              <label className="fs-15 ms-2 text-muted">
                Tên khách hàng <span className="text-danger">*</span>
              </label>
              {errors.customer_name && (
                <span className="text-danger fs-14">
                  Bạn cần chọn tên khách hàng
                </span>
              )}
            </div>
            <div className="form-floating mb-3 col-6">
              <input
                type="text"
                className="form-control rounded-3"
                placeholder="Tên input"
                {...register("customer_phone")}
              />
              <label className="fs-15 ms-2 text-muted">
                Số điện thoại <span className="text-danger">*</span>
              </label>
              {errors.customer_phone && (
                <span className="text-danger fs-14">
                  Bạn cần thêm số điện thoại khách.
                </span>
              )}
            </div>
          </div>

          <div className="row">
            <div className="form-floating mb-3 col-12">
              <input
                type="text"
                className="form-control rounded-3"
                placeholder="Tên input"
                {...register("customer_email")}
              />
              <label className="fs-15 ms-2 text-muted">Email</label>
            </div>
          </div>
        </div>

        {/* Button */}
        <div className="px-4 py-3 border-top border-block-start-dashed d-flex justify-content-center">
          <button className="btn btn-purple-gradient m-1" type="submit">
            Thêm mới
          </button>
        </div>
      </form>
    )
}
export default FormAddContractTech