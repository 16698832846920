import moment from "moment"
import { useEffect, useState } from "react"
import classNames from "classnames/bind"
import { BsArrowUp, BsDot } from "react-icons/bs"
import { HiOutlineUserCircle } from "react-icons/hi"
import { IoMdCheckmarkCircle } from "react-icons/io"
import { IoDocumentTextOutline } from "react-icons/io5"
import { MdRadioButtonChecked } from "react-icons/md"

import LoadingSpinner from "../../../../Components/Loading/LoadingSpinner"
import styles from "../../Job.module.scss"
import Tippy from "@tippyjs/react"
const cx = classNames.bind(styles);

function DetailContract({ socketio, dataUser, objectDetail }) {
  const [dataDetailBds, setDataDetailBds] = useState([])
  const [dataDetailView360, setDataDetailView360] = useState([])
  const [dataDetailTech, setDataDetailTech] = useState([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (objectDetail.contract_name === "Quảng cáo bất động sản") {
      // Tìm kiếm trong bảng hợp đồng bất động sản.
      const paramsBds = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: { id: objectDetail.id },
      }
      socketio.emit("client-get-data-id-bds-contract", paramsBds)
      socketio.on("server-get-data-id-bds-contract", (data) => {
        setDataDetailBds([...data])
        setLoading(false)
      })
    } else if (objectDetail.contract_name === "View 360") {
      // Tìm kiếm trong bảng hợp đồng view 360
      const paramsView = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: { id: objectDetail.id },
      }
      socketio.emit("client-get-search-data-id-contract-view360", paramsView)
      socketio.on("server-get-search-data-id-contract-view360", (data) => {
        setDataDetailView360([...data])
      })
    } else if (objectDetail.contract_name === "Công nghệ") {
      // Tìm kiếm trong bảng hợp đồng tech
      const paramsView = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: { id: objectDetail.id },
      }
      socketio.emit("client-get-data-contract-tech", paramsView)
      socketio.on("server-get-data-contract-tech", (data) => {
        setDataDetailTech([...data])
        setLoading(false)
      })
    } else {
      alert("Chưa được thiết lập hợp đồng liên hệ với quản trị viên.")
    }
    return () => {
      socketio.off("client-get-data-id-bds-contract")
      socketio.off("server-get-data-id-bds-contract")

      socketio.off("client-get-search-data-id-contract-view360")
      socketio.off("server-get-search-data-id-contract-view360")

      socketio.off("client-get-data-contract-tech")
      socketio.off("server-get-data-contract-tech")
    }
  }, [objectDetail])

  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <div>
      {/* Giao diện chi tiết hợp đồng bất động sản */}
      {dataDetailBds.length > 0 && (
        <div className="col-xxl-12">
          <div className="card custom-card">
            <div className="card-header align-items-center">
              {/* <div className="me-2">
                <span className="avatar avatar-rounded p-1 bg-danger-transparent">
                  <img src="../assets/images/company-logos/1.png" alt="" />
                </span>
              </div> */}
              <div className="flex-fill">
                <h6>
                  {dataDetailBds[0].loaisanpham +
                    " " +
                    dataDetailBds[0].tuyenduong +
                    "," +
                    dataDetailBds[0].quanhuyen +
                    "," +
                    dataDetailBds[0].thanhpho}
                </h6>
                <span className="text-muted d-block fs-14">
                  Bắt đầu:{" "}
                  {moment(dataDetailBds[0].date_start).format("DD/MM/YYYY")}
                  <BsDot />
                  Kết thúc:{" "}
                  {moment(dataDetailBds[0].date_end).format("DD/MM/YYYY")}
                </span>
              </div>
            </div>
            <div className="card-body">
              {/* <div className="d-flex align-items-center justify-content-between mb-3">
                <div>
                  <div className="fw-semibold mb-1 fs-15">Tham gia :</div>
                  <div className="avatar-list-stacked">
                    <span className="avatar avatar-sm avatar-rounded">
                      <img src="../assets/images/faces/2.jpg" alt="img" />
                    </span>
                    <span className="avatar avatar-sm avatar-rounded">
                      <img src="../assets/images/faces/8.jpg" alt="img" />
                    </span>
                    <span className="avatar avatar-sm avatar-rounded">
                      <img src="../assets/images/faces/2.jpg" alt="img" />
                    </span>
                    <span className="avatar avatar-sm avatar-rounded">
                      <img src="../assets/images/faces/10.jpg" alt="img" />
                    </span>
                  </div>
                </div>
                <div className="text-end">
                  <div className="fw-semibold mb-1">Priority :</div>
                  <span className="badge bg-success-transparent">Low</span>
                </div>
              </div> */}

              <div className="wrapper_ground align-items-center">
                <div className="list_items col-12">
                  <div className="fw-semibold mb-1 fs-15">
                    Thông số giấy tờ :
                  </div>
                  <div className="wrapper_ground">
                    <div className="list-group list_items">
                      <label className="list-group-item d-flex justify-content-between fs-14 p-2">
                        <span>Đường/Ngõ:</span>
                        <span className="fw-5">
                          {dataDetailBds[0].duongngo}m
                        </span>
                      </label>
                      <label className="list-group-item d-flex justify-content-between fs-14 p-2">
                        <span>Mặt tiền:</span>
                        <span className="fw-5">
                          {dataDetailBds[0].mattien}m
                        </span>
                      </label>
                      <label className="list-group-item d-flex justify-content-between fs-14 p-2">
                        <span>Diện tích:</span>
                        <span className="fw-5">
                          {dataDetailBds[0].dtmb}m<sup>2</sup>
                        </span>
                      </label>
                    </div>
                    <div className="list-group list_items">
                      <label className="list-group-item d-flex justify-content-between fs-14 p-2">
                        <span>Số tầng:</span>
                        <span className="fw-5">{dataDetailBds[0].sotang}</span>
                      </label>
                      <label className="list-group-item d-flex justify-content-between fs-14 p-2">
                        <span>Hướng:</span>
                        <span className="fw-5">
                          {dataDetailBds[0].huongnha}
                        </span>
                      </label>
                      <label className="list-group-item d-flex justify-content-between fs-14 p-2">
                        <span>Pháp lý:</span>
                        <span className="fw-5">{dataDetailBds[0].phaply}</span>
                      </label>
                    </div>
                  </div>
                </div>
                {/* Sơ đồ */}
                <div className="list_items col-12 position-relative">
                  <div className="p-5 mt-3 d-flex justify-content-center">
                    <div
                      className="position-absolute d-flex align-items-center text-primary fw-5 fs-15"
                      style={{ right: 0, top: "21px" }}
                    >
                      <BsArrowUp /> Bắc
                    </div>
                    <div
                      style={{
                        border: "2px solid red",
                        height: dataDetailBds[0].mattien * 10 + "px",
                        maxHeight: "150px",
                        width:
                          (
                            (Number(dataDetailBds[0].dtmb) /
                              Number(dataDetailBds[0].mattien)) *
                            10
                          ).toFixed(1) + "px",
                        maxWidth: "150px",
                        background: "#ffd000",
                      }}
                      className={cx(
                        "d-flex justify-content-between",
                        dataDetailBds[0].huongnha === "Đông" && "dong",
                        dataDetailBds[0].huongnha === "Tây" && "tay",
                        dataDetailBds[0].huongnha === "Nam" && "nam",
                        dataDetailBds[0].huongnha === "Bắc" && "bac",
                        dataDetailBds[0].huongnha === "Đông nam" && "dongnam",
                        dataDetailBds[0].huongnha === "Tây nam" && "taynam",
                        dataDetailBds[0].huongnha === "Đông bắc" && "dongbac",
                        dataDetailBds[0].huongnha === "Tây bắc" && "taybac",
                      )}
                    >
                      <Tippy
                        content={"Mặt tiền, hướng " + dataDetailBds[0].huongnha}
                      >
                        <div
                          className={cx("ms-1 d-flex align-items-center fs-15")}
                        >
                          {dataDetailBds[0].mattien + "m"}
                        </div>
                      </Tippy>
                      <Tippy content="Chiều dài">
                        <div className="text-center">
                          <span className="fs-14">
                            {(
                              Number(dataDetailBds[0].dtmb) /
                              Number(dataDetailBds[0].mattien)
                            ).toFixed(1)}
                            m
                          </span>
                        </div>
                      </Tippy>
                      <div
                        className={cx("ms-1 d-flex align-items-center")}
                      ></div>
                    </div>
                  </div>
                  <div className="text-center mt-3 text-muted fs-14">
                    Sơ đồ minh họa
                  </div>
                </div>
              </div>
              {/* Thông tin cấu trúc */}
              <div className="mt-3">
                <div className="fw-semibold mb-1 fs-15">Cấu trúc :</div>
                <div className="wrapper_ground">
                  <div className="list-group list_items">
                    <label className="list-group-item d-flex justify-content-between fs-14 p-2">
                      <span>Gara:</span>
                      <span className="fw-5">{dataDetailBds[0].gara}</span>
                    </label>
                    <label className="list-group-item d-flex justify-content-between fs-14 p-2">
                      <span>Sân cổng:</span>
                      <span className="fw-5">{dataDetailBds[0].sancong}</span>
                    </label>
                    <label className="list-group-item d-flex justify-content-between fs-14 p-2">
                      <span>Phòng khách:</span>
                      <span className="fw-5">
                        {dataDetailBds[0].phongkhach}
                      </span>
                    </label>
                    <label className="list-group-item d-flex justify-content-between fs-14 p-2">
                      <span>Phòng ăn:</span>
                      <span className="fw-5">{dataDetailBds[0].phongan}</span>
                    </label>
                  </div>
                  <div className="list-group list_items">
                    <label className="list-group-item d-flex justify-content-between fs-14 p-2">
                      <span>Phòng ngủ:</span>
                      <span className="fw-5">{dataDetailBds[0].phongngu}</span>
                    </label>
                    <label className="list-group-item d-flex justify-content-between fs-14 p-2">
                      <span>Phòng tắm:</span>
                      <span className="fw-5">{dataDetailBds[0].wc}</span>
                    </label>
                    <label className="list-group-item d-flex justify-content-between fs-14 p-2">
                      <span>Phòng thờ:</span>
                      <span className="fw-5">{dataDetailBds[0].phongtho}</span>
                    </label>
                    <label className="list-group-item d-flex justify-content-between fs-14 p-2">
                      <span>Sân phơi:</span>
                      <span className="fw-5">{dataDetailBds[0].sanphoi}</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="wrapper_ground mt-3">
                <div className="list-group list_items">
                  {/* <div className="fw-semibold mb-1 fs-15">Cấu trúc :</div> */}
                  <label className="list-group-item d-flex justify-content-between fs-14 p-2">
                    <span>Trạng thái</span>
                    <span className="fw-5">
                      {dataDetailBds[0].duyethopdong === 1 ? (
                        <span className="text-success">Đã duyệt</span>
                      ) : (
                        <span className="text-warning">Chờ duyệt</span>
                      )}
                    </span>
                  </label>
                  <label className="list-group-item d-flex justify-content-between fs-14 p-2">
                    <span>Loại tin:</span>
                    <span className="fw-5">{dataDetailBds[0].loaitin}</span>
                  </label>
                  <label className="list-group-item d-flex justify-content-between fs-14 p-2">
                    <span>Giá dịch vụ:</span>
                    <span className="fw-5">
                      {Number(dataDetailBds[0].giadichvu)
                        .toLocaleString()
                        .replace(/[.]/g, ",")}
                      đ
                    </span>
                  </label>
                  <label className="list-group-item d-flex justify-content-between fs-14 p-2">
                    <span>Thời gian kết thúc:</span>
                    <span className="fw-5">
                      {moment(dataDetailBds[0].date_end).format("DD/MM/YYYY")}
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div className="card-footer d-flex justify-content-center align-items-center">
              <div className="text-center">
                <div className="mb-1 fs-15">{dataDetailBds[0].ghichu}</div>
                <span className="fw-semibold d-block fs-15 alert alert-primary">
                  <span className="d-flex align-items-center">
                    <HiOutlineUserCircle className="me-1 fs-21" />
                    {dataDetailBds[0].chusohuu}
                    <BsDot />
                    <span>{dataDetailBds[0].sodienthoai}</span>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Giao diện hợp đồng view 360 */}
      {dataDetailView360.length > 0 && (
        <div>
          <div className="text-center">
            <span className="avatar avatar-xl avatar-rounded p-1 bg-danger-transparent">
              <IoDocumentTextOutline className="fs-29" />
            </span>
            <p className="fs-16 fw-5 mb-0">HỢP ĐỒNG VIEW 360</p>
            <p className="fs-15 mb-0">
              Khách hàng: {dataDetailView360[0].hoten}
              <BsDot />
              {dataDetailView360[0].sodienthoai}
            </p>
            <p className="fw-5 fs-15">
              Gói dịch vụ: {dataDetailView360[0].goidichvu}
              <BsDot />
              {Number(dataDetailView360[0].giadichvu)
                .toLocaleString()
                .replace(/[.]/g, ",")}
              đ
            </p>
          </div>

          <div className="border-top border-block-start-dashed mt-3 mb-3"></div>

          <div className="fs-15">
            {/* Thông tin khách hàng */}
            <div>
              <span>Thông tin khách hàng</span>
              <div className="mt-2">
                <p className="mb-1 fs-15">
                  <BsDot className="me-1 fs-21" />
                  Họ tên: {dataDetailView360[0].hoten}
                </p>
                <p className="mb-1 fs-15">
                  <BsDot className="me-1 fs-21" />
                  Số điện thoại: {dataDetailView360[0].sodienthoai}
                </p>
                <p className="mb-1 fs-15">
                  <BsDot className="me-1 fs-21" />
                  Email: {dataDetailView360[0].email}
                </p>
              </div>
            </div>

            <div className="border-top border-block-start-dashed mt-3 mb-3"></div>
            {/* Chi tiết gói dịch vụ */}
            <div className="mb-2">Gói dịch vụ</div>
            <div>
              <p className="mb-1 d-flex align-items-center fs-15">
                <IoMdCheckmarkCircle className="me-1 text-success" />
                <span style={{ width: "120px" }}>Gói dịch vụ:</span>{" "}
                {dataDetailView360[0].goidichvu}
              </p>
              <p className="mb-1 d-flex align-items-center fs-15">
                <IoMdCheckmarkCircle className="me-1 text-success" />
                <span style={{ width: "120px" }}>Giá dịch vụ:</span>{" "}
                {Number(dataDetailView360[0].giadichvu)
                  .toLocaleString()
                  .replace(/[.]/g, ",")}
                đ
              </p>
              <p className="mb-1 d-flex align-items-center fs-15">
                <IoMdCheckmarkCircle className="me-1 text-success" />
                <span style={{ width: "120px" }}>Số dự án:</span>{" "}
                {dataDetailView360[0].soduan} dự án
              </p>
              <p className="mb-1 d-flex align-items-center fs-15">
                <IoMdCheckmarkCircle className="me-1 text-success" />
                <span style={{ width: "120px" }}>Ngày bắt đầu:</span>{" "}
                {moment(dataDetailView360[0].ngaybatdau).format("DD/MM/YYYY")}
              </p>
              <p className="mb-1 d-flex align-items-center fs-15">
                <IoMdCheckmarkCircle className="me-1 text-success" />
                <span style={{ width: "120px" }}>Ngày kết thúc:</span>{" "}
                {moment(dataDetailView360[0].ngayketthuc).format("DD/MM/YYYY")}
              </p>
              <p className="mb-1 d-flex align-items-center fs-15">
                <IoMdCheckmarkCircle className="me-1 text-success" />
                <span style={{ width: "120px" }}>Trạng thái:</span>{" "}
                {dataDetailView360[0].giahan === 1 ? "Gia hạn" : "Đăng ký mới"}
              </p>
              {dataDetailView360[0].noidung.length > 0 && (
                <p className="mb-1 d-flex align-items-center fs-15">
                  <IoMdCheckmarkCircle className="me-1 text-success" />
                  <span style={{ width: "120px" }}>Nội dung:</span>{" "}
                  {dataDetailView360[0].noidung}
                </p>
              )}
            </div>

            <div className="border-top border-block-start-dashed mt-3 mb-3"></div>
            <div>
              {/* Người lên hợp đồng */}
              <div className="mb-2">Soạn hợp đồng</div>
              <div>
                <p className="mb-1 d-flex align-items-center fs-15">
                  <MdRadioButtonChecked className="me-1 text-primary" />
                  <span style={{ width: "120px" }}>Họ tên:</span>{" "}
                  {dataDetailView360[0].author_name}
                </p>
                <p className="mb-1 d-flex align-items-center fs-15">
                  <MdRadioButtonChecked className="me-1 text-primary" />
                  <span style={{ width: "120px" }}>Email:</span>{" "}
                  {dataDetailView360[0].tacgia}
                </p>
                <p className="mb-1 d-flex align-items-center fs-15">
                  <MdRadioButtonChecked className="me-1 text-primary" />
                  <span style={{ width: "120px" }}>Ngày lập phiếu:</span>{" "}
                  {moment(dataDetailView360[0].ngaythem).format(
                    "DD/MM/YYYY HH:mm",
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Giao diện hợp đồng tech */}
      {dataDetailTech.length > 0 && (
        <div>
          <div className="text-center">
            <span className="avatar avatar-xl avatar-rounded p-1 bg-danger-transparent">
              <IoDocumentTextOutline className="fs-29" />
            </span>
            <p className="fs-16 fw-5 mb-0">HỢP ĐỒNG CÔNG NGHỆ</p>
            <p className="fs-15 mb-0">
              Khách hàng: {dataDetailTech[0].customer_name}
              <BsDot />
              {dataDetailTech[0].customer_phone}
            </p>
            <p className="fw-5 fs-15">
              Giá hợp đồng:
              <BsDot />
              {Number(dataDetailTech[0].price_service)
                .toLocaleString()
                .replace(/[.]/g, ",")}
              đ
            </p>
          </div>

          <div className="border-top border-block-start-dashed mt-3 mb-3"></div>

          <div className="fs-15">
            {/* Thông tin khách hàng */}
            <div>
              <span>Thông tin khách hàng</span>
              <div className="mt-2">
                <p className="mb-1 fs-15">
                  <MdRadioButtonChecked className="me-1 text-warning" />
                  Họ tên: {dataDetailTech[0].customer_name}
                </p>
                <p className="mb-1 fs-15">
                  <MdRadioButtonChecked className="me-1 text-warning" />
                  Số điện thoại: {dataDetailTech[0].customer_phone}
                </p>
                <p className="mb-1 fs-15">
                  <MdRadioButtonChecked className="me-1 text-warning" />
                  Email: {dataDetailTech[0].customer_email}
                </p>
              </div>
            </div>

            <div className="border-top border-block-start-dashed mt-3 mb-3"></div>
            {/* Chi tiết gói dịch vụ */}
            <div className="mb-2">Giá hợp đồng</div>
            <div>
              <p className="mb-1 d-flex align-items-center fs-15">
                <IoMdCheckmarkCircle className="me-1 text-success" />
                <span style={{ width: "120px" }}>Giá dịch vụ:</span>{" "}
                {Number(dataDetailTech[0].price_service)
                  .toLocaleString()
                  .replace(/[.]/g, ",")}
                đ
              </p>
              <p className="mb-1 d-flex align-items-center fs-15">
                <IoMdCheckmarkCircle className="me-1 text-success" />
                <span style={{ width: "120px" }}>Ngày bắt đầu:</span>{" "}
                {moment(dataDetailTech[0].date_start).format("DD/MM/YYYY")}
              </p>
              <p className="mb-1 d-flex align-items-center fs-15">
                <IoMdCheckmarkCircle className="me-1 text-success" />
                <span style={{ width: "120px" }}>Ngày kết thúc:</span>{" "}
                {moment(dataDetailTech[0].date_end).format("DD/MM/YYYY")}
              </p>
              <p className="mb-1 d-flex align-items-center fs-15">
                <IoMdCheckmarkCircle className="me-1 text-success" />
                <span style={{ width: "120px" }}>Trạng thái:</span>{" "}
                {dataDetailTech[0].status === 1 ? (
                  <span className="text-success">Đã duyệt</span>
                ) : (
                  <span className="text-warning">Chờ duyệt</span>
                )}
              </p>
              
            </div>

            <div className="border-top border-block-start-dashed mt-3 mb-3"></div>
            <div>
              {/* Người lên hợp đồng */}
              <div className="mb-2">Soạn hợp đồng</div>
              <div>
                <p className="mb-1 d-flex align-items-center fs-15">
                  <MdRadioButtonChecked className="me-1 text-primary" />
                  <span style={{ width: "120px" }}>Họ tên:</span>{" "}
                  {dataDetailTech[0].hoten}
                </p>
                <p className="mb-1 d-flex align-items-center fs-15">
                  <MdRadioButtonChecked className="me-1 text-primary" />
                  <span style={{ width: "120px" }}>Số điện thoại:</span>{" "}
                  {dataDetailTech[0].sodienthoai}
                </p>
                <p className="mb-1 d-flex align-items-center fs-15">
                  <MdRadioButtonChecked className="me-1 text-primary" />
                  <span style={{ width: "120px" }}>Ngày lập phiếu:</span>{" "}
                  {moment(dataDetailTech[0].addtime).format(
                    "DD/MM/YYYY HH:mm",
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}


export default DetailContract