import { useLayoutEffect, useState } from "react"
import { IoCloseSharp } from "react-icons/io5"
import { VscListSelection } from "react-icons/vsc"

import GoBackPage from "../Goback";
import SidebarMenuMobile from "../Sidebar/SidebarMenuMobile"
import AvatarUser from "../Info/AvatarUser"
import { Notification } from "../Notification";

function HeaderDashboard2({result}) {
  // Chức năng ẩn hiện sidebar trên pc.
  const [hideShowSidebar, setHideShowSidebar] = useState(true)
  const handleHideShowSidebar = (key) => {
    result(key)
    setHideShowSidebar(key)
  }

  return (
    <header
      className="app-header"
      style={{ paddingInlineStart: hideShowSidebar && "6rem" }}
    >
      {/* <!-- Start::main-header-container --> */}
      <div className="main-header-container container-fluid">
        {/* <!-- Start::header-content-left --> */}
        <div className="header-content-left">
          {/* <!-- Start::header-element --> */}
          <div className="header-element align-items-center">
            <div className="horizontal-logo">
              <GoBackPage />
            </div>
          </div>
          {/* <!-- End::header-element --> */}

          {/* Start:: ẩn hiện sidebar */}
          <HideShowSideBar result={handleHideShowSidebar} />
          {/* <!-- End::ẩn hiện sidebar --> */}
        </div>
        {/* <!-- End::header-content-left --> */}

        {/* <!-- Start::header-content-right --> */}
        <div className="header-content-right gap-3">
          {/* <!-- Start::header-element --> */}
          <div className="header-element header-menu">
            <SidebarMenuMobile size="fs-25" />
          </div>
          {/* <!-- End::header-element --> */}

          {/* <!-- Start::header-element --> */}
          <div className="header-element">
            {/* <!-- Start::header-link|dropdown-toggle --> */}
            <Notification />
            {/* <!-- End::header-link|dropdown-toggle --> */}
          </div>
          {/* <!-- End::header-element --> */}

          {/* <!-- Start::avartar user --> */}
          <AvatarUser />
          {/* <!-- End::avartar user --> */}
        </div>
        {/* <!-- End::header-content-right --> */}
      </div>
      {/* <!-- End::main-header-container --> */}
    </header>
  )
}

export default HeaderDashboard2



// Ẩn hiển sidebar trên pc.
function HideShowSideBar({result}) {

  const [hideShowSidebar, setHideShowSidebar] = useState(true)
  // Ẩn sidebar.
  const handleHideSidebar = (key) => {
    setHideShowSidebar(key)
    result(key)
  }
  // Hiển thị.
  const handleShowSidebar = (key) => {
    setHideShowSidebar(key)
    result(key)
  }
  useLayoutEffect(() => {
    // Hiển thị ban đầu.
    result(hideShowSidebar)
  }, [])

  return (
    <>
      {/* <!-- Start::ẩn hiện sidebar --> */}
      <div className="header-element">
        {/* <!-- Start:: hide-show sidebar --> */}
        {hideShowSidebar ? (
          <button
            className="sidemenu-toggle show animated-arrow hor-toggle horizontal-navtoggle border-0 bg-transparent"
            onClick={() => handleShowSidebar(false)}
          >
            <VscListSelection />
          </button>
        ) : (
          <button
            className="sidemenu-toggle hide animated-arrow hor-toggle horizontal-navtoggle border-0 bg-transparent"
            onClick={() => handleHideSidebar(true)}
          >
            <IoCloseSharp />
          </button>
        )}
        {/* <!-- End:: hide-show sidebar --> */}
      </div>
    </>
  )
}