import classNames from "classnames/bind";
import styles from "../Job.module.scss";
import { GoBack } from "../../../Components/Goback";
import moment from "moment";
import { GoDotFill } from "react-icons/go";
import { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoPieChartOutline } from "react-icons/io5";
import PieChart from "../../../Components/Chart/PieChart";
import { BsDot } from "react-icons/bs";
import SearchReport from "../Component/Report/SearchReport";
import AppContext from "../../../handle-reuses/Contexts/CreateContexts";
import LoadingSpinner from "../../../Components/Loading/LoadingSpinner";
import StatisticalReport from "../Component/Report/StatisticalReport";
import ChartRevenueExpenseYear from "../Component/Report/ChartRevenueExpenseYear";
import ChartRevenueYear from "../Component/Report/ChartRevenueYear";
import ChartExpenseYear from "../Component/Report/ChartExpenseYear";
import DataReport from "../Component/Report/data/DataReport";
const cx = classNames.bind(styles);

function ListCateReportPC() {
  const { socketio, dataUser } = useContext(AppContext)
  const navigate = useNavigate()
  // Kiểm tra quyền truy cập.
  useLayoutEffect(() => {
    if (dataUser[0].bao_cao === 0) {
      navigate("/")
    }
  }, [])

  const [objectSearch, setObjectSearch] = useState({
    monthSearch: moment().format("YYYY-MM"),
  })
  // Quản lý danh mục.
  const [activeCate, setActiveCate] = useState("bao-cao-tong-quan")
  const handleActiveCate = (cate) => {
    setActiveCate(cate)
  }

  // Quản lý search data.
  const handleGetValueSearch = (object) => {
    setObjectSearch({
      monthSearch: object.monthSearch,
    })
  }

  return (
    <>
      {/* Top 1 */}
      <div className={cx("d-flex justify-content-between align-items-center")}>
        <div className={cx("d-flex align-items-center gap-3 fs-15 fw-5")}>
          <GoBack />
          <span>Báo cáo tài chính {moment(objectSearch.monthSearch).format("MM/YYYY")}</span>
        </div>
        {/* <div className="d-flex align-items-center gap-3">
          <span className="d-flex align-items-center fs-14">
            <GoDotFill className="text-success-1 me-1 fs-18" />
            Thành công
          </span>
          <span className="d-flex align-items-center fs-14">
            <GoDotFill className="text-warning-1 me-1 fs-18" />
            Chờ duyệt
          </span>
          <span className="d-flex align-items-center fs-14">
            <GoDotFill className="text-danger me-1 fs-18" />
            Đã hủy bỏ
          </span>
        </div> */}
      </div>
      {/* top 2 */}
      <div
        className={cx("d-flex justify-content-between align-items-center mt-3")}
      >
        {/* Left */}
        <div className={cx("d-flex alig-items-center gap-3 fs-15")}>
          <button
            className={cx(
              "border-0 bg-transparent",
              activeCate === "bao-cao-tong-quan"
                ? "color-orange"
                : "color-gray",
            )}
            onClick={() => handleActiveCate("bao-cao-tong-quan")}
          >
            <span className="d-flex align-items-center gap-1">
              <IoPieChartOutline />
              Báo cáo tổng quan
            </span>
          </button>
        </div>
        {/* Right */}
        <div className={cx("d-flex align-items-center gap-3")}>
          {/* Search month*/}
          <SearchReport resultSearch={handleGetValueSearch} />
        </div>
      </div>

      {/* List content */}
      <div className={cx("mt-3")}>
        {/* Tiến độ */}
        {activeCate === "bao-cao-tong-quan" && <DataReport socketio={socketio} dataUser={dataUser} objectSearch={objectSearch} />}
      </div>
    </>
  )
}
export default ListCateReportPC



