import io from "socket.io-client"
import { useState, useEffect, useRef } from "react";
import classNames from "classnames/bind";

import styles from "../Dashboard.module.scss"
import CheckPCMobile from "../../../handle-reuses/reuses/check.pc.mobile"
import ComponentTopUser from "./PC/ComponentTopUser";
import ComponentTopUserMobile from "./Mobile/ComponentTopUserMobile";
import ListUser from "./Component/ListUser";

const cx = classNames.bind(styles);

function TaiKhoan({active}) {
    const socket = useRef();
    const [dataUser, setDataUser] = useState([])
    const [loading, setLoading] = useState(true)
    const limit = 100;
    const page = useRef()
    // Khởi tạo socket.
    useEffect(() => {
        socket.current = io(process.env.REACT_APP_DOMAIN_BDSVIEW360)
        return () => {
            socket.current.disconnect()
        }
    }, [])

    // Quản lý get data.
    useEffect(() => {
        let params = {
            quantity: limit,
            offset: (page.current - 1) * limit
        }
        // get data.
        socket.current.emit("client-for-admin-user", params)

        return () => {
            socket.current.off("")
        }
    }, [])

    // Quản lý nhận data.
    useEffect(() => {
        // Data user.
        socket.current.on("server-for-admin-user", (data) => {
            setDataUser(data)
            setLoading(false)
        })
    }, [])

    // Quản lý tìm kiếm.
    const handleResultSearchUser = (result) => {
        const object = {
            iden: process.env.REACT_APP_IDEN_BDSVIEW,
            keyword: result
        }
        socket.current.emit("client-search-user", object)
    }
    useEffect(() => {
        socket.current.on("server-search-user", (data) => {
            setDataUser(data)
        })
        return () => {
            socket.current.off("server-search-user")
        }
    }, [])

    if (loading) {
        return <div className="text-center mt-5">Đang tải dữ liệu...</div>
    }

  return (
    <div
      className="main-content app-content"
      style={{ marginInlineStart: active && "6rem" }}
    >
      <div className="container-fluid">
        <div className="main-mail-container p-2">
          <CheckPCMobile>
            {(isMobile) =>
              isMobile ? (
                <>
                  {/* Top */}
                  <ComponentTopUserMobile result={handleResultSearchUser} />
                  {/* List user */}
                  <ListUser dataUser={dataUser} socketio={socket.current} />
                </>
              ) : (
                <>
                  {/* top */}
                  <ComponentTopUser result={handleResultSearchUser} />
                  {/* Danh sách user */}
                  <ListUser dataUser={dataUser} socketio={socket.current} />
                </>
              )
            }
          </CheckPCMobile>
        </div>
      </div>
    </div>
  )
}

export default TaiKhoan