import { useContext } from "react"
import { Dropdown } from "react-bootstrap"
import { BsDatabaseAdd } from "react-icons/bs"
import { LiaStreetViewSolid } from "react-icons/lia"
import { LuLandmark } from "react-icons/lu"
import { GrTechnology } from "react-icons/gr"
import { IoBriefcaseOutline, IoPieChartOutline } from "react-icons/io5"
import { IoIosLogOut } from "react-icons/io"
import { GiMoneyStack } from "react-icons/gi"

import { images } from "../../assets/images"
import AppContext from "../../handle-reuses/Contexts/CreateContexts"
import { Link } from "react-router-dom"

function AvatarUser({ width, height }) {
  const { dataUser } = useContext(AppContext)

  return (
    <div className="header-element">
      {/* <!-- Start::header-link|dropdown-toggle --> */}
      <Dropdown className="d-flex align-items-center">
        <Dropdown.Toggle
          variant="outline-line text-black border-0 px-0" // class mặc định
          bsPrefix="custom-dropdown-toggle"
        >
          <div className="d-flex align-items-center">
            <div className="me-0">
              <img
                src={
                  dataUser[0].avatar
                    ? "https://system.view360.vn/" + dataUser[0].avatar
                    : images.user
                }
                alt="img"
                width={width ? width : "33"}
                height={height ? height : "33"}
                className="rounded-circle"
                style={{
                  border: "2px solid rgb(255 255 255)",
                  overflow: " hidden",
                }}
              />
            </div>
            {/* <div className="d-sm-block d-none">
                <p className="fw-semibold mb-0 lh-1 fs-16">
                  {dataUser[0].hoten}
                </p>
                <span className="op-7 fw-normal d-block fs-15">
                  {dataUser[0].sodienthoai}
                </span>
              </div> */}
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu style={{ minWidth: "200px" }} align="end">
          <li>
            <Link to="/data-sale" className="dropdown-item d-flex">
              <BsDatabaseAdd className="fs-19 me-2" />
              Data sale
            </Link>
          </li>
          <li>
            <Link to="" className="dropdown-item d-flex">
              <GiMoneyStack className="me-2 fs-19" />
              Bảng giá nhà đất
            </Link>
          </li>
          <li>
            <Link to="/bdsview360/dashboard" className="dropdown-item d-flex">
              <LuLandmark className="me-2 fs-19" />
              BDS view 360
            </Link>
          </li>
          <li>
            <Link
              to="/view360"
              className="dropdown-item d-flex border-block-end"
            >
              <LiaStreetViewSolid className="fs-19 me-2" />
              View 360
            </Link>
          </li>
          <li>
            <Link to="/tech" className="dropdown-item d-flex">
              <GrTechnology className="me-2 fs-19" />
              Công nghệ
            </Link>
          </li>
          <li>
            <Link to="/job" className="dropdown-item d-flex border-block-end">
              <IoBriefcaseOutline className="fs-19 me-2" />
              Công việc
            </Link>
          </li>
          <li>
            <Link to="/report" className="dropdown-item d-flex">
              <IoPieChartOutline className="fs-19 me-2" />
              Báo cáo
            </Link>
          </li>
          <li>
            <Link to="/logout" className="dropdown-item d-flex">
              <IoIosLogOut className="me-2 fs-19" />
              Đăng xuất
            </Link>
          </li>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default AvatarUser