import classNames from "classnames/bind";
import styles from "../Dashboard.module.scss"

const cx = classNames.bind(styles);

function LichHen() {
    return (
      <div className={cx("wrapper_container")}>
        <div className="row align-items-center justify-content-center h-100">
          <div className="col-xl-7">
            <p className="error-text mb-sm-0 mb-2">NEXT</p>
            <p className="fs-18 fw-semibold mb-3">
              Bạn hãy chuyển tiếp nếu muốn truy cập vào lịch hẹn.
            </p>
            <div className="mb-5">
              <div className="col-xl-6">
                <p className="mb-0 op-7">
                  Lịch hẹn là nơi chưa thông tin yêu cầu từ người dùng, khi vào bài
                  viết của bạn.
                </p>
              </div>
            </div>
            <div
              className="btn btn-primary"
              onClick={() =>
                window.open("https://batdongsan.view360.vn/lich-hen")
              }
            >
              CHUYỂN TIẾP
            </div>
          </div>
        </div>
        {/* <!-- Start::error-page --> */}
      </div>
    )
}

export default LichHen