import { useEffect, useRef, useState } from "react"
import moment from "moment/moment"
import { Dropdown } from "react-bootstrap"
import { IoIosCheckmarkCircleOutline } from "react-icons/io"
import { MdOutlineCancel } from "react-icons/md"
import { SlRefresh } from "react-icons/sl"

import { images } from "../../../../assets/images"
import {handleFormatPrice} from "../../../../handle-reuses/reuses"

function ListPayHisUser({ socketio, keyword }) {
  const [dataPayHis, setDataPayHis] = useState([])
  const limit = 50
  const page = useRef(1)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // Get data (danh sách thanh toán).
    const params = {
      iden: process.env.REACT_APP_IDEN_BDSVIEW,
      object: {
        quantity: limit,
        offset: (page.current - 1) * limit,
      },
    }
      socketio.emit("client-data-for-admin", params)
      return () => {
          socketio.off("client-data-for-admin")
      }
  }, [loading])

  // Quản lý nhận dữ liệu từ socket.
    useEffect(() => {
        //danh sách thanh toán
        socketio.on("server-data-for-admin", (data) => {
            setDataPayHis(data)
            setLoading(false)
        })

        return () => {
            socketio.off("server-data-for-admin")
        }
    }, []);

    // Xác nhận thanh toán.
    const handleCheckPayUser = (id_, userid, deposit) => {
        const params = {
            iden: process.env.REACT_APP_IDEN_BDSVIEW,
            userid: userid,
            objectEdit: {
                id: id_,
                status: 1
            },
            objectUpdate: {
                id: id_,
                quantity: limit,
                offset: (page.current - 1) * limit
            }
        }
        // Xử lý chuyển đổi trạng thái + update data.
        socketio.emit("client-edit-status-user-pay", params)
        
        // Xử lý cộng tiền vào tài khoản cho khách hàng.
        const objectPay = {
          iden: process.env.REACT_APP_IDEN_BDSVIEW,
          userid: userid,
          deposit: deposit
        }
        socketio.emit("client-edit-money-user-pay", objectPay)
        // Cập nhật lại số dư top (gửi lên server).
        socketio.emit("client-month-revenue-payhis", params)
        socketio.emit("client-month-revenue-success-payhis", params)
        socketio.emit("client-year-revenue-success-payhis", params)
    }
  // Xác nhận hủy bỏ.
  const handleCheckCancelPayUser = (id_, userid) => {
    const params = {
      iden: process.env.REACT_APP_IDEN_BDSVIEW,
      userid: userid,
      objectEdit: {
        id: id_,
        status: "-1",
      },
      objectUpdate: {
        quantity: limit,
        offset: (page.current - 1) * limit,
      },
    }
    // Xử lý chuyển đổi trạng thái + update data.
    socketio.emit("client-edit-status-user-pay", params)
  }

  // Quản lý tìm kiếm.
  useEffect(() => {
    if (keyword !== "") {
      const params = {
        iden: process.env.REACT_APP_IDEN_BDSVIEW,
        object: {
          keyword: keyword,
        },
      }
      socketio.emit("client-search-user-pay-his", params)
      socketio.on("server-search-user-pay-his", (data) => {
        setDataPayHis(data)
      })

      return () => {
        socketio.off("client-search-user-pay-his")
        socketio.off("server-search-user-pay-his")
      }
    }
  }, [keyword])
  
  // Làm mới dữ liệu.
  const handleRefreshData = () => {
    setLoading(true)
  }
  // Điều kiện chuyển tiếp.
  if (loading) {
    return <div className="text-center mt-5">Đang tải dữ liệu...</div>
  }
    if (dataPayHis.length === 0) {
        return <div className="text-center mt-5">Chưa có dữ liệu.</div>
    }

  return (
    <div className="table-responsive">
      <div className="mb-2 d-flex justify-content-end" style={{position: "absolute",top: "60px",right: "21px"}}>
        <button className="p-2 border-0 rounded-5" onClick={handleRefreshData}><SlRefresh className="fs-21"/></button>
      </div>
      <table className="table text-nowrap table-bordered">
        <thead>
          <tr>
            <th scope="col">Họ tên</th>
            <th scope="col">Tổng nạp</th>
            <th scope="col">Tổng số dư</th>
            <th scope="col">Yêu cầu nạp</th>
            <th scope="col">Thời gian</th>
            <th scope="col">Cập nhật</th>
            <th scope="col">Trạng thái</th>
          </tr>
        </thead>
        <tbody>
          {dataPayHis.map((item) => {
            let colorStatus = ""
            if (item.status === 1) {
              colorStatus = "text-muted"
            }
            return (
              <tr key={item.id} className="bg-warning">
                <th scope="row">
                  <div className="d-flex align-items-center">
                    <span className="avatar avatar-md me-2 avatar-rounded">
                      <img
                        src={
                          item.avatar
                            ? "https://batdongsan.view360.vn/" + item.avatar
                            : images.user
                        }
                        alt="img"
                      />
                    </span>
                    <div className={colorStatus}>
                      <span className="fs-15">{item.hoten}</span>
                      <p className="mb-0 text-muted">{item.sodienthoai}</p>
                    </div>
                  </div>
                </th>
                <td className={colorStatus}>
                  <span className="fw-5 fs-15">
                    {handleFormatPrice(Number(item.total_deposit))}
                  </span>
                </td>
                <td className={colorStatus}>
                  <span className="fw-5 fs-15">
                    {handleFormatPrice(Number(item.total_balance))}
                  </span>
                </td>
                <td className={colorStatus}>
                  <span className="fw-5 fs-15">
                    {handleFormatPrice(Number(item.deposit))}
                  </span>
                </td>
                <td className={colorStatus}>
                  <span className={"fw-5 fs-15 " + colorStatus}>
                    {moment(item.addtime).locale("vi").fromNow()}
                  </span>
                </td>
                <td className={colorStatus}>
                  <span className={"fw-5 fs-15 " + colorStatus}>
                    {moment(item.update_time).locale("vi").fromNow()}
                  </span>
                </td>
                <td>
                  {item.status === 1 ? (
                    <span className="badge bg-success-transparent fs-15 p-2">
                      Đã thanh toán
                    </span>
                  ) : (
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="outline-dark border-1 p-2 py-1" // class mặc định
                        id="dropdown-basic"
                        bsPrefix="custom-dropdown-toggle" // đặt tên cho class fix loại bỏ class dropdown-toggle
                      >
                        Chờ duyệt
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <li
                          className="dropdown-item d-flex fs-14 align-items-center cursor-pointer"
                          onClick={() =>
                            handleCheckPayUser(
                              item.id,
                              item.userid,
                              item.deposit,
                            )
                          }
                        >
                          <IoIosCheckmarkCircleOutline className="me-2 fs-18" />
                          Xác nhận thanh toán
                        </li>
                        <li
                          className="dropdown-item d-flex fs-14 align-items-center cursor-pointer"
                          onClick={() => handleCheckCancelPayUser(item.id, item.userid)}
                        >
                          <MdOutlineCancel className="me-2 fs-18" />
                          Hủy bỏ
                        </li>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default ListPayHisUser