import moment from "moment"
import { useContext, useEffect, useRef, useState } from "react"
import { MdCancel } from "react-icons/md"
import { useLocation } from "react-router-dom"
import AppContext from "../../handle-reuses/Contexts/CreateContexts"
import ModalReact from "../Modal/ModalReact"
import { BsDot } from "react-icons/bs"
import FormChamcong from "../../Pages/Job/Component/Chamcong/FormChamcong"

/** ================ Loại thông báo kiểu alert ============ */

// Thông báo cuối tháng
function NotificationSystem({ textAlert }) {
  const path = useLocation()
  const currentPath = path.pathname;
  // Thông báo nếu chưa duyệt bảng lương cuối tháng.
  const [alertSalary, setAlertSalary] = useState(false)
  useEffect(() => {
    // Bắt đầu thông báo từ lúc 07:00 sáng
    let dateEndMonth = moment().endOf('month').set({
      hour: 7,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    let limitTime = new Date(dateEndMonth).getTime()
    let currentTime = new Date().getTime()
    if (currentTime >= limitTime) {
      setAlertSalary(true);
    }
  }, [currentPath]);

  // Ẩn notifi và set lại state.
  const handleCloseNotifi = () => {
    setAlertSalary(false)
  }
  
  if (!alertSalary) {
    return
  }

  return (
    <div
      className=" fs-15 alert alert-solid-secondary rounded-pill alert-dismissible fade show position-absolute top-0 start-50 translate-middle-x btn btn-secondary-gradient btn-wave"
      style={{ marginTop: "86px", zIndex: "21" }}
    >
      {textAlert}
      <button
        type="button"
        className="btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
        onClick={handleCloseNotifi}
      >
        <MdCancel className="fs-21" />
      </button>
    </div>
  )
}

export default NotificationSystem


// Notification timekeeing.
export function NotifiTimekeeing() {
  const {dataUser, socketio} = useContext(AppContext)
  const [modalTimekeeing, setModalTimekeeing] = useState(false);
  const path = useLocation()
  const pathCurrent = path.pathname
  const alertText = useRef("")

  // Set hiển thị thông báo.
  useEffect(() => {
    // set giờ làm việc.
    const paramsGiolamviec = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {user_id: dataUser[0].id_user, addtime: moment().format("YYYY-MM-DD")}
    }
    
    socketio.emit("client-get-list-id-day-timekeeping", paramsGiolamviec)
    socketio.on("server-get-list-id-day-timekeeping", (data) => {
      
      // Check giờ làm việc
      socketio.emit("client-get-data-giolamviec", paramsGiolamviec)
      socketio.on("server-get-data-giolamviec", (dataTime) => {
        // dung sai chấm công.
        let dungsai30p = 30 * 60 * 1000
        let dungsai5p = 5 * 60 * 1000
        // ca sáng.
        let ca_sang_a = moment().format("YYYY-MM-DD" + dataTime[0].ca_sang_a)
        let formatSangA = new Date(ca_sang_a).getTime()
        let ca_sang_b = moment().format("YYYY-MM-DD" + dataTime[0].ca_sang_b)
        let formatSangB = new Date(ca_sang_b).getTime()
        // Ca chiều
        let ca_chieu_a = moment().format("YYYY-MM-DD" + dataTime[0].ca_chieu_a)
        let formatChieuA = new Date(ca_chieu_a).getTime()
        let ca_chieu_b = moment().format("YYYY-MM-DD" + dataTime[0].ca_chieu_b)
        let formatChieuB = new Date(ca_chieu_b).getTime()

        let currentTime = new Date().getTime()

        // Điều kiện thông báo
        if (data.length === 0) {
          // Nếu chưa chấm công hôm nay
          if (currentTime >= (formatSangA - dungsai5p) && currentTime <= (formatSangA + dungsai30p + dungsai30p)) {
            // Thông báo trong khoảng kém 5 phút và hơn 30 phút so với giờ chuẩn làm việc
            setModalTimekeeing(true)
            alertText.current = "ơi hôm nay bạn chưa chấm công !"
          }
        } else {
          // Nếu đã chấm công nhưng cuối giờ sáng, đầu giờ chiêu, cuối giờ chiều chưa chấm công
          if (
            currentTime >= formatSangB &&
            currentTime <= (formatSangB + dungsai30p)
          ) {
            // Trường hợp chấm công cuối giờ sáng
            if (!data[0].ca_sang_b) {
              setModalTimekeeing(true)
              alertText.current = "ơi đã kết thúc ca sáng rồi."
            }
          } else if (
            currentTime >= (formatChieuA - dungsai5p) &&
            currentTime < (formatChieuA + dungsai30p)
          ) {
            // Trường hợp đầu giờ chiều chưa chấm công
            if (!data[0].ca_chieu_a) {
              setModalTimekeeing(true)
              alertText.current = "ơi chiều nay bạn chưa chấm công."
            }
          } else if (
            currentTime >= formatChieuB &&
            currentTime <= (formatChieuB + dungsai30p)
          ) {
            // Trường hợp chấm công cuối giờ
            if (!data[0].ca_chieu_b) {
              setModalTimekeeing(true)
              alertText.current = "ơi đã kết thúc ca chiều rồi."
            }
          }
        }
      })
    })
    
    
    return () => {
      socketio.off("client-get-data-giolamviec")
      socketio.off("server-get-data-giolamviec")

      socketio.off("client-get-list-id-day-timekeeping")
      socketio.off("server-get-list-id-day-timekeeping")
    }
  }, [pathCurrent])

  // Ẩn modal chấm công.
  const handleCloseModalChamcong = () => {
    setModalTimekeeing(false)
  }
  // Trả về trạng thái chấm công thành công.
  const handleChamcongSuccess = (object) => {
    if (object.status) {
      // alert("Bạn đã chấm công thành công.");
    }
  }

  if (!modalTimekeeing) {
    return
  }

  return (
    <ModalReact
      modalTitle={
        <>
          <div className="d-flex gap-3 align-items-center">
            <div>
              <span className="avatar avatar-rounded">
                <img
                  src={"https://system.view360.vn/" + dataUser[0].avatar}
                  alt="avatar"
                />
              </span>
            </div>
            <div>
              <span className="fw-5">{dataUser[0].hoten}</span>
              <span className="fs-14 ms-1 text-danger">
                {alertText.current}
              </span>
              <p className="mb-0 fs-14 text-muted mt-1">
                Xác nhận chấm công <BsDot /> Ngày{" "}
                {moment().format("DD/MM/YYYY")}
              </p>
            </div>
          </div>
        </>
      }
      showModal={modalTimekeeing}
      handleClose={handleCloseModalChamcong}
      theme={
        <FormChamcong
          socketio={socketio}
          result={handleChamcongSuccess}
          dataUser={dataUser}
        />
      }
    />
  )
}