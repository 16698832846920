import { useContext, useEffect, useRef, useState } from "react"
import moment from "moment"
import classNames from "classnames/bind"
import SearchContract from "../Component/Hopdong/SearchContract"
import AppContext from "../../../handle-reuses/Contexts/CreateContexts"
import { useNavigate } from "react-router-dom"
import styles from "../Job.module.scss"
import CardStatisticalContractMobile from "../Component/Hopdong/CardStatisticalContractMobile"
import LoadingSpinner from "../../../Components/Loading/LoadingSpinner"
import { BsDot } from "react-icons/bs"
import ModalReact from "../../../Components/Modal/ModalReact"
import DetailContract from "../Component/Hopdong/DetailContract"
import { IoMdAdd } from "react-icons/io"
import FormAddContractOther from "../Component/Hopdong/FormAddContractOther"
import FormAddContractTech from "../Component/Hopdong/FormAddContractTech"
import ModalAddContractView from "../../View360/ContractsPage/Components/ModalAddContractView"
import FormAddContract from "../../DataSale/Component/FormAddContract"
import FormEditContract from "../../DataSale/Component/FormEditContract"
import FormEditContractTech from "../Component/Hopdong/FormEditContractTech"
const cx = classNames.bind(styles);

function ListContractMobile() {
  const { socketio, dataUser } = useContext(AppContext)
  const navigate = useNavigate()
  if (dataUser[0].hopdong === 0) {
    navigate("/")
  }
  const [objectSearch, setObjectSearch] = useState({
    lastMonth: moment().subtract(1, "months").format("YYYY-MM"),
    daysSearch: "",
    monthSearch: moment().format("YYYY-MM"),
    textSearch: "",
  })

  // Quản lý danh mục.
  const [activeCate, setActiveCate] = useState("list-hop-dong")
  const handleActiveCate = (cate) => {
    setActiveCate(cate)
  }

  // Quản lý search data.
  const handleSearchMonthTextContract = (object) => {
    setObjectSearch({
      lastMonth: moment(object.monthSearch)
        .subtract(1, "months")
        .format("YYYY-MM"),
      daysSearch: object.daysSearch,
      monthSearch: object.monthSearch,
      textSearch: object.textSearch,
    })
  }

  // Thêm mới hợp đồng quảng cáo bất động sản.
  const [modalAddAdsBDS, setModalAddAdsBDS] = useState(false)
  const handleAddContractAdsBDS = () => {
    setModalAddAdsBDS(true)
  }
  const handleCloseAddContractAdsBDS = () => setModalAddAdsBDS(false)
  const handleAddContractAdsBDSSuccess = (status_) => {
    if (status_) {
      setModalAddAdsBDS(false)
    }
  }

  // Thêm mới hợp đồng view 360.
  const [modalAddView360, setModalAddView360] = useState(false)
  const handleAddContractView360 = () => {
    setModalAddView360(true)
  }
  const handleCloseAddContractView360 = () => setModalAddView360(false)
  const handleAddContractView360Success = (status_) => {
    if (status_) {
      setModalAddView360(false)
    }
  }

  // Thêm mới hợp đồng công nghệ.
  const [modalAddTech, setModalAddTech] = useState(false)
  const handleAddContractTech = () => {
    setModalAddTech(true)
  }
  const handleCloseAddContractTech = () => setModalAddTech(false)
  const handleAddContractSuccess = (status_) => {
    if (status_) {
      setModalAddTech(false)
    }
  }

  // Thêm mới hợp đồng khác.
  const [modalAddOther, setModalAddOther] = useState(false)
  const handleAddContractOther = () => {
    setModalAddOther(true)
  }
  const handleCloseAddContractOther = () => setModalAddOther(false)
  const handleAddContractOtherSuccess = (status_) => {
    if (status_) {
      setModalAddOther(false)
    }
  }
  return (
    <div>
      {/* Search */}
      <div className="wrapper_ground">
        <div className="list_items col-10">
          <SearchContract resultSearch={handleSearchMonthTextContract} />
        </div>
        <div className="list_items col-1">
          <button
            className={cx(
              "btn btn-purple-gradient btn-wave col-12",
              "border-0 rounded-3 d-flex align-items-center p-2",
            )}
            data-bs-toggle="dropdown"
          >
            <IoMdAdd className="fs-21" />
          </button>
          <ul className="dropdown-menu">
            <li
              className="dropdown-item cursor-pointer"
              onClick={handleAddContractAdsBDS}
            >
              Ads bất động sản
            </li>
            <li
              className="dropdown-item cursor-pointer"
              onClick={handleAddContractView360}
            >
              View 360
            </li>
            <li
              className="dropdown-item cursor-pointer"
              onClick={handleAddContractTech}
            >
              Công nghệ
            </li>
            <li
              className="dropdown-item cursor-pointer"
              onClick={handleAddContractOther}
            >
              Loại khác
            </li>
          </ul>
        </div>
      </div>
      {/* cate */}
      <div className="button-scroll">
        <div className={cx("d-flex alig-items-center gap-3 fs-15 col-12 mt-3")}>
          <button
            className={cx(
              "border-0 bg-transparent",
              activeCate === "list-hop-dong" ? "color-orange" : "color-gray",
            )}
            onClick={() => handleActiveCate("list-hop-dong")}
            style={{ minWidth: "150px" }}
          >
            <span className="d-flex align-items-center">Tất cả hợp đồng</span>
          </button>
          <button
            className={cx(
              "border-0 bg-transparent",
              activeCate === "hopdong-bds" ? "color-orange" : "color-gray",
            )}
            onClick={() => handleActiveCate("hopdong-bds")}
            style={{ minWidth: "130px" }}
          >
            <span className="d-flex align-items-center">Bất động sản</span>
          </button>
          <button
            className={cx(
              "border-0 bg-transparent",
              activeCate === "contract-tech" ? "color-orange" : "color-gray",
            )}
            onClick={() => handleActiveCate("contract-tech")}
            style={{ minWidth: "120px" }}
          >
            <span className="d-flex align-items-center">Công nghệ</span>
          </button>
        </div>
      </div>

      <div className="text-center mt-3 fs-15 alert alert-primary">
        <span className="fw-5">
          Quản lý hợp đồng {moment(objectSearch.monthSearch).format("MM/YYYY")}
        </span>
      </div>
      {/* Statistical */}
      {activeCate === "list-hop-dong" && (
        <div className="mt-3">
          <CardStatisticalContractMobile
            socketio={socketio}
            dataUser={dataUser}
            objectSearch={objectSearch}
          />
        </div>
      )}
      {/* List content */}
      <div className={cx("mt-3")}>
        {/* Thống kê tất cả */}
        {activeCate === "list-hop-dong" && (
          <ListContractTypeTable
            socketio={socketio}
            dataUser={dataUser}
            objectSearch={objectSearch}
          />
        )}
        {/* Contract bds */}
        {activeCate === "hopdong-bds" && (
          <ListContractBDS
            socketio={socketio}
            dataUser={dataUser}
            objectSearch={objectSearch}
            changeContractBds={modalAddAdsBDS}
          />
        )}
        {/* Contract tech */}
        {activeCate === "contract-tech" && (
          <ListContractTech
            socketio={socketio}
            dataUser={dataUser}
            objectSearch={objectSearch}
            changeTech={modalAddTech}
          />
        )}
      </div>

      {/* Modal add contract ads bds */}
      {modalAddAdsBDS && (
        <ModalReact
          modalTitle="Thêm mới hợp đồng bất động sản"
          showModal={modalAddAdsBDS}
          handleClose={handleCloseAddContractAdsBDS}
          theme={
            <FormAddContract
              socketio={socketio}
              dataUser={dataUser}
              resultStatus={handleAddContractAdsBDSSuccess}
            />
          }
        />
      )}

      {/* Modal add contract view 360 */}
      {modalAddView360 && (
        <ModalReact
          modalTitle="Thêm mới hợp đồng view 360"
          showModal={modalAddView360}
          handleClose={handleCloseAddContractView360}
          theme={
            <ModalAddContractView
              checkChange={handleAddContractView360Success}
            />
          }
        />
      )}

      {/* Modal add contract tech */}
      {modalAddTech && (
        <ModalReact
          modalTitle="Thêm mới hợp đồng công nghệ"
          showModal={modalAddTech}
          handleClose={handleCloseAddContractTech}
          theme={
            <FormAddContractTech
              socketio={socketio}
              dataUser={dataUser}
              result={handleAddContractSuccess}
            />
          }
        />
      )}
      {/* Modal add contract other */}
      {modalAddOther && (
        <ModalReact
          modalTitle="Thêm mới hợp đồng khác"
          showModal={modalAddOther}
          handleClose={handleCloseAddContractOther}
          theme={
            <FormAddContractOther
              socketio={socketio}
              dataUser={dataUser}
              result={handleAddContractOtherSuccess}
            />
          }
        />
      )}
    </div>
  )
}
export default ListContractMobile

function ListContractTypeTable({ socketio, dataUser, objectSearch }) {
  // biến cho component.
  const [dataContract, setDataContract] = useState([])
  const dataContractRef = useRef([])
  const totalRevenueMonth = useRef(0)
  const [loading, setLoading] = useState(true)
  const handleGetDataContract = (objectSearch_) => {
    const paramsContract = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        list_hopdong: dataUser[0].list_hopdong,
        user_id: dataUser[0].id_user,
        daysSearch: objectSearch_.daysSearch,
        monthSearch: objectSearch_.monthSearch,
        textSearch: objectSearch_.textSearch,
        lastMonth: objectSearch_.lastMonth,
      },
    }
    // list contract
    socketio.emit("client-get-search-data-contract", paramsContract)
    socketio.on("server-get-search-data-contract", (data) => {
      setDataContract([...data])
      dataContractRef.current = [...data]
      // set tổng doanh thu.
      if (data.length > 0) {
        let arrRevenueMonth = data.map((item) => item.price_service)
        totalRevenueMonth.current = arrRevenueMonth.reduce(
          (a, b) => Number(a) + Number(b),
        )
      }
      setLoading(false)
    })
  }

  // Get data contract
  useEffect(() => {
    handleGetDataContract(objectSearch)
    return () => {
      socketio.off("client-get-search-data-contract")
      socketio.off("server-get-search-data-contract")
    }
  }, [objectSearch])

  // Quản lý chi tiết dự án.
  const [detailProject, setDetailProject] = useState(false)
  const objectDetail = useRef()
  const handleModalDetailProject = (idDetail, contractName) => {
    setDetailProject(true)
    objectDetail.current = {
      id: idDetail,
      contract_name: contractName,
    }
  }
  const handleCloseModalDetailProject = () => {
    setDetailProject(false)
    objectDetail.current = undefined
  }

  // Quản lý duyệt hợp đồng.
  const handleStatusAcceptContract = (id, price) => {
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        status: 1,
        tien_hoahong: (Number(price) * 10) / 100,
        hoahong_thitruong: (Number(price) * 10) / 100,
        id: id,
        lastMonth: objectSearch.lastMonth,
        monthSearch: objectSearch.monthSearch,
      },
    }
    let checkAcceptContract = window.confirm(
      "Bạn muốn duyệt hợp đồng này chứ ?",
    )
    if (checkAcceptContract) {
      socketio.emit("client-edit-row-status-job-contract", params)
    }
  }
  useEffect(() => {
    socketio.on("server-edit-row-status-job-contract", (dataUpdate) => {
      let dataUpdateContract = dataContractRef.current
      let index = dataContractRef.current.findIndex(
        (item) => item.id === dataUpdate[0].id,
      )
      dataUpdateContract[index] = dataUpdate[0]
      // Cập nhật lại dữ liệu
      setDataContract([...dataUpdateContract])
      let arrRevenueMonth = dataUpdateContract.map((item) => item.price_service)
      totalRevenueMonth.current = arrRevenueMonth.reduce(
        (a, b) => Number(a) + Number(b),
      )
      alert("Hợp duyệt thành công.")
    })
    return () => {
      socketio.off("client-edit-row-status-job-contract")
      socketio.off("server-edit-row-status-job-contract")
    }
  }, [])

  if (loading) {
    return <LoadingSpinner />
  }
  if (dataContract.length === 0) {
    return <div className="text-center fs-15">Chưa có dữ liệu</div>
  }
    
    return (
      <div>
        {/* <div className="fs-15 mb-2">Danh sách hợp đồng</div> */}
        <div>
          {dataContract.map((item, index) => {
            return (
              <div
                className="col-xl-3"
                key={index}
                onClick={() =>
                  handleModalDetailProject(item.id_detail, item.contract_name)
                }
              >
                <div className="card custom-card card-bg-light mb-2">
                  <div className="card-body p-2">
                    <div className="d-flex align-items-center w-100">
                      <div className="me-2">
                        <span className="avatar avatar-rounded">
                          <img
                            src={"./" + item.nvc_avatar}
                            alt="img"
                          />
                        </span>
                      </div>
                      <div className="">
                        <div className="fw-semibold d-flex gap-2 align-items-center">
                          {item.nvc_name}
                          <div>
                            {dataUser[0].list_hopdong === 1 &&
                              (item.status === 0 ? (
                                <button
                                  className={cx(
                                    "badge bg-warning",
                                    "border-0 rounded-1 p-1 px-2 fw-5",
                                  )}
                                  style={{ width: "100%", color: "white" }}
                                  onClick={() =>
                                    handleStatusAcceptContract(
                                      item.id,
                                      item.price_service,
                                    )
                                  }
                                >
                                  Chờ duyệt
                                </button>
                              ) : (
                                <button
                                  className={cx(
                                    "badge bg-success",
                                    "border-0 rounded-1 p-1 px-2 fw-5",
                                  )}
                                  style={{ width: "100%", color: "white" }}
                                >
                                  Đã duyệt
                                </button>
                              ))}
                            {dataUser[0].list_hopdong !== 1 &&
                              (item.status === 0 ? (
                                <button
                                  className={cx(
                                    "badge bg-warning",
                                    "border-0 rounded-1 p-1 px-2 fw-5",
                                  )}
                                  style={{ width: "100%", color: "white" }}
                                >
                                  Chờ duyệt
                                </button>
                              ) : (
                                <button
                                  className={cx(
                                    "badge bg-success",
                                    "border-0 rounded-1 p-1 px-2 fw-5",
                                  )}
                                  style={{ width: "100%", color: "white" }}
                                >
                                  Đã duyệt
                                </button>
                              ))}
                          </div>
                        </div>
                        <span className="mb-0 text-muted op-7 fs-16">
                          {item.contract_name}
                          <BsDot />
                          {moment(item.addtime).locale("vi").fromNow()}
                        </span>
                        <div>
                          <span className="fs-16">
                            Đơn giá:{" "}
                            {Number(item.price_service)
                              .toLocaleString()
                              .replace(/[.]/g, ",")}
                            đ
                          </span>
                          <BsDot />
                          <span className="fs-15 text-success">
                            Hoa hồng:{" "}
                            {Number(item.tien_hoahong)
                              .toLocaleString()
                              .replace(/[.]/g, ",")}
                            đ
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        {/* Modal detail project */}
        {detailProject && (
          <ModalReact
            modalTitle="Chi tiết hợp đồng"
            size="modal-md"
            showModal={detailProject}
            handleClose={handleCloseModalDetailProject}
            theme={
              <DetailContract
                socketio={socketio}
                dataUser={dataUser}
                objectDetail={objectDetail.current}
              />
            }
          />
        )}
      </div>
    )
}


// danh sách hợp đồng bds.
function ListContractBDS({
  socketio,
  dataUser,
  objectSearch,
  changeContractBds,
}) {
  // Set biến.
  const [dataAdsBds, setDataAdsBds] = useState([])
  const dataAdsBdsRef = useRef([])
  const [modalEditContractBDS, setModalEditContractBDS] = useState(false)
  const [loading, setLoading] = useState(true)
  let stt = 1

  // Get data contract bds
  useEffect(() => {
    const paramsGet = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        user_id: dataUser[0].id_user,
        list_hopdong: dataUser[0].list_hopdong,
        daysSearch: objectSearch.daysSearch,
        monthSearch: objectSearch.monthSearch,
        textSearch: objectSearch.textSearch,
      },
    }
    socketio.emit("client-get-data-bds-contract", paramsGet)
    socketio.on("server-get-data-bds-contract", (data) => {
      setDataAdsBds([...data])
      dataAdsBdsRef.current = [...data]
      setLoading(false)
    })
    return () => {
      socketio.off("client-get-data-bds-contract")
      socketio.off("server-get-data-bds-contract")
    }
  }, [objectSearch, modalEditContractBDS, changeContractBds])

  // Quản lý edit contract bds.
  const dataEdit = useRef()
  const handleEditContractBDS = (object) => {
    setModalEditContractBDS(true)
    dataEdit.current = object
  }
  const handleCloseEditContractBDS = () => {
    setModalEditContractBDS(false)
    dataEdit.current = undefined
  }
  const handleEditContractBDSSuccess = (object) => {
    if (object.status) {
      // Update lại data.
      let dataUpdateContract = dataAdsBdsRef.current
      let index = dataUpdateContract.findIndex(
        (item) => item.id_hopdong === object.dataUpdate[0].id_hopdong,
      )
      dataUpdateContract[index] = object.dataUpdate[0]
      setDataAdsBds([...dataUpdateContract])
      dataAdsBdsRef.current = dataUpdateContract
      changeEditStatus.current = object
      setModalEditContractBDS(false)
    }
  }

  // Quả lý phê duyệt hợp đồng.
  const changeEditStatus = useRef()
  const handleEditStatusContractBds = (object) => {
    // Xử lý edit status.
    const checkEdit = window.confirm("Bạn muốn xác nhận hợp đồng này chứ ?")
    if (checkEdit) {
      const paramsStatus = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: {
          id_hopdong: object.id_hopdong,
          duyethopdong: 1,
        },
      }
      socketio.emit("client-edit-row-duyethopdong-bds-contract", paramsStatus)
      socketio.on("server-edit-row-duyethopdong-bds-contract", (data) => {
        if (data.status === "success") {
          alert("Hợp đồng đã được duyệt.")
          // Update lại data.
          let dataUpdateStatus = dataAdsBdsRef.current
          let index = dataUpdateStatus.findIndex(
            (item) => item.id_hopdong === data.data[0].id_hopdong,
          )
          dataUpdateStatus[index] = data.data[0]
          setDataAdsBds([...dataUpdateStatus])
          dataAdsBdsRef.current = dataUpdateStatus
          changeEditStatus.current = object
        }
      })
    }
  }
  useEffect(() => {
    return () => {
      socketio.off("client-edit-row-duyethopdong-bds-contract")
      socketio.off("server-edit-row-duyethopdong-bds-contract")
    }
  }, [changeEditStatus.current])

  // Quả lý chi tiết hợp đồng.
  const [modalContractDetail, setModalContractDetail] = useState(false)
  const objectDetail = useRef()
  const handleContractDetail = (object) => {
    setModalContractDetail(true)
    objectDetail.current = {
      contract_name: "Quảng cáo bất động sản",
      id: object.id_hopdong,
    }
  }
  const handleCloseContractDetail = () => {
    setModalContractDetail(false)
    objectDetail.current = undefined
  }

  if (loading) {
    return <LoadingSpinner />
  }
  if (dataAdsBds.length === 0) {
    return (
      <div className="text-center mt-3">
        Chưa có hợp đồng quảng cáo bất động sản nào !
      </div>
    )
  }
  return (
    <div>
      {dataAdsBds.map((item, index) => {
        return (
          <div
            className="card card-bg-light mb-2 rounded-3"
            key={index}
            style={{ background: "#f6f6f6" }}
          >
            <div className="card-body p-2">
              <div className="d-flex align-items-center w-100">
                <div
                  onClick={() => handleContractDetail(item)}
                  className="d-flex align-items-center"
                >
                  <div className="me-2">
                    <span className="avatar avatar-rounded">
                      <img src={"./" + item.nvc_avatar} alt="img" />
                    </span>
                  </div>
                  <div className="">
                    <div className="fw-semibold">
                      {item.loaisanpham + " " + item.tuyenduong}{" "}
                    </div>
                    <p className="mb-0 text-muted op-7">
                      {item.loaitin}
                      <span className="fs-16 text-muted">
                        <BsDot />
                        {moment(item.ngaycapnhat).locale("vi").fromNow()}
                      </span>
                    </p>
                  </div>
                </div>

                <div className="ms-auto text-success fs-16 fw-5">
                  <div className="text-end">
                    +
                    {Number(item.giadichvu)
                      .toLocaleString()
                      .replace(/[.]/g, ",")}
                  </div>
                  <div className="text-end">
                    {/* <button
                      className="badge bg-dark-transparent border-0 me-1"
                      
                    >
                      Chi tiết
                    </button> */}
                    {item.duyethopdong === 0 && (
                      <>
                        {item.tacgia === dataUser[0].email && (
                          <>
                            <button
                              className="badge bg-warning border-0 me-1"
                              onClick={() => handleEditStatusContractBds(item)}
                            >
                              Chờ duyệt
                            </button>
                            <button
                              className="badge bg-info border-0"
                              onClick={() => handleEditContractBDS(item)}
                            >
                              Sửa
                            </button>
                          </>
                        )}
                      </>
                    )}
                    {item.duyethopdong === 1 && (
                      <>
                        <button className="badge bg-success border-0">
                          Đã duyệt
                        </button>
                        {dataUser[0].list_hopdong === 1 && (
                          <button
                            className="badge bg-info border-0 ms-1"
                            onClick={() => handleEditContractBDS(item)}
                          >
                            Sửa
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      })}
      {/* Modal edit contract bds */}
      {modalEditContractBDS && (
        <ModalReact
          modalTitle="Sửa thông tin hợp đồng"
          showModal={modalEditContractBDS}
          handleClose={handleCloseEditContractBDS}
          theme={
            <FormEditContract
              dataEdit={dataEdit.current}
              socketio={socketio}
              dataUser={dataUser}
              resultStatus={handleEditContractBDSSuccess}
            />
          }
        />
      )}
      {/* Chi tiết hợp đồng */}
      {modalContractDetail && (
        <ModalReact
          modalTitle="Chi tiết hợp đồng"
          showModal={modalContractDetail}
          handleClose={handleCloseContractDetail}
          theme={
            <DetailContract
              socketio={socketio}
              dataUser={dataUser}
              objectDetail={objectDetail.current}
            />
          }
        />
      )}
    </div>
  )
}


// Danh sách hợp đồng công nghệ
function ListContractTech({ socketio, dataUser, objectSearch, changeTech }) {
  const [dataContractTech, setDataContractTech] = useState([])
  const dataContractTechRef = useRef([])
  const [loading, setLoading] = useState(true)
  let stt = 1

  // Get data contract tech.
  useEffect(() => {
    const paramsGet = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        user_id: dataUser[0].id_user,
        list_hopdong: dataUser[0].list_hopngdong,
        daysSearch: objectSearch.daysSearch,
        monthSearch: objectSearch.monthSearch,
        textSearch: objectSearch.textSearch,
      },
    }
    socketio.emit("client-get-data-Search-role-contract-tech", paramsGet)
    socketio.on("server-get-data-Search-role-contract-tech", (data) => {
      setDataContractTech([...data])
      dataContractTechRef.current = [...data]
      setLoading(false)
    })
    return () => {
      socketio.off("client-get-data-Search-role-contract-tech")
      socketio.off("server-get-data-Search-role-contract-tech")
    }
  }, [objectSearch, changeTech])

  // Quản lý chi tiết hợp đồng.
  const [detailContractTech, setDetailContractTech] = useState(false)
  const dataDetailContractTech = useRef()
  const handleDetailContractTech = (object) => {
    setDetailContractTech(true)
    dataDetailContractTech.current = {
      contract_name: "Công nghệ",
      id: object.id,
    }
  }
  const handleCloseDetailContractTech = () => {
    setDetailContractTech(false)
    dataDetailContractTech.current = undefined
  }

  // Quản lý edit contract tech.
  const [editContractTech, setEditContractTech] = useState(false)
  const dataEditContractTech = useRef()
  const handleEditContractTech = (object) => {
    setEditContractTech(true)
    dataEditContractTech.current = object
  }
  const handleCloseEditContractTech = () => {
    setEditContractTech(false)
    dataEditContractTech.current = undefined
  }
  const handleEditContractTechSuccess = (object) => {
    if (object.status === "success") {
      let dataUpdateContractTech = dataContractTechRef.current
      let index = dataUpdateContractTech.findIndex(
        (item) => item.id === object.dataUpdate[0].id,
      )
      dataUpdateContractTech[index] = object.dataUpdate[0]
      setDataContractTech([...dataUpdateContractTech])
      dataContractTechRef.current = [...dataUpdateContractTech]
      setEditContractTech(false)
    }
  }

  // Quản lý edit row status.
  const checkChangeEditStatus = useRef()
  const handleEditRowStatusContractTech = (id) => {
    const checkEditStatus = window.confirm("Bạn muốn duyệt hợp đồng này chứ ?")
    if (checkEditStatus) {
      const paramsEdit = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: { status: 1, id: id },
      }
      socketio.emit("client-edit-row-status-contract-tech", paramsEdit)
      socketio.on("server-edit-row-status-contract-tech", (data) => {
        if (data.status === "success") {
          alert("Duyệt thành công")
          let dataUpdateContractTech = dataContractTechRef.current
          let index = dataUpdateContractTech.findIndex(
            (item) => item.id === data.dataUpdate[0].id,
          )
          dataUpdateContractTech[index] = data.dataUpdate[0]
          setDataContractTech([...dataUpdateContractTech])
          dataContractTechRef.current = [...dataUpdateContractTech]
          checkChangeEditStatus.current = data.dataUpdate[0]
        }
      })
    }
  }
  useEffect(() => {
    return () => {
      socketio.off("client-edit-row-status-contract-tech")
      socketio.off("server-edit-row-status-contract-tech")
    }
  }, [checkChangeEditStatus.current])

  if (loading) {
    return <LoadingSpinner />
  }

  if (dataContractTech.length === 0) {
    return <div className="text-center mt-3">Chưa có dữ liệu.</div>
  }


  return (
    <div>
      {dataContractTech.map((item, index) => {
        return (
          <div
            className="card card-bg-light mb-2 rounded-3"
            key={index}
            style={{ background: "#f6f6f6" }}
          >
            <div className="card-body p-2">
              <div className="d-flex align-items-center w-100">
                <div
                  onClick={() => handleDetailContractTech(item)}
                  className="d-flex align-items-center"
                >
                  <div className="me-2">
                    <span className="avatar avatar-rounded">
                      <img
                        src={"./" + item.avatar}
                        alt="img"
                      />
                    </span>
                  </div>
                  <div className="">
                    <div className="fw-semibold">
                      {item.contract_name}{" "}
                    </div>
                    <p className="mb-0 text-muted op-7 fs-16">
                      {moment(item.addtime).locale("vi").fromNow()}
                    </p>
                  </div>
                </div>
                <div className="ms-auto text-success fw-5">
                  <div className="text-end">
                    +
                    {Number(item.price_service)
                      .toLocaleString()
                      .replace(/[.]/g, ",")}
                  </div>
                  <div className="text-end">
                    {/* <button className="badge bg-dark-transparent border-0 me-1">
                      Chi tiết
                    </button> */}
                    {item.status === 0 && (
                      <>
                        {item.user_id === dataUser[0].id_user && (
                          <>
                            <button
                              className="badge bg-warning border-0 me-1"
                              onClick={() =>
                                handleEditRowStatusContractTech(item.id)
                              }
                            >
                              Chờ duyệt
                            </button>
                            <button
                              className="badge bg-info border-0"
                              onClick={() => handleEditContractTech(item)}
                            >
                              Sửa
                            </button>
                          </>
                        )}
                      </>
                    )}
                    {item.status === 1 && (
                      <>
                        <button className="badge bg-success border-0">
                          Đã duyệt
                        </button>
                        {dataUser[0].list_hopdong === 1 && (
                          <button
                            className="badge bg-info border-0 ms-1"
                            onClick={() => handleEditContractTech(item)}
                          >
                            Sửa
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      })}
      {/* Modal detail */}
      {detailContractTech && (
        <ModalReact
          modalTitle="Chi tiết hợp đồng công nghệ"
          showModal={detailContractTech}
          handleClose={handleCloseDetailContractTech}
          theme={
            <DetailContract
              socketio={socketio}
              dataUser={dataUser}
              objectDetail={dataDetailContractTech.current}
            />
          }
        />
      )}

      {/* Modal Edit contract tech */}
      {editContractTech && (
        <ModalReact
          modalTitle="Sửa thông tin hợp đồng công nghệ"
          showModal={editContractTech}
          handleClose={handleCloseEditContractTech}
          theme={
            <FormEditContractTech
              socketio={socketio}
              dataUser={dataUser}
              dataEdit={dataEditContractTech.current}
              result={handleEditContractTechSuccess}
            />
          }
        />
      )}
    </div>
  )
}