import { useContext, useEffect, useRef, useState } from "react"
import { Dropdown } from "react-bootstrap"
import { LiaStreetViewSolid } from "react-icons/lia"
import ModalReact from "../../../Components/Modal/ModalReact"
import AppContext from "../../../handle-reuses/Contexts/CreateContexts"
import { useForm } from "react-hook-form"
import SearchHorizontal from "../../../Components/Search/SearchHorizontal"
import moment from "moment"
import { handleColorTextSearch } from "../../../handle-reuses/reuses"
import { BsDot } from "react-icons/bs"

function ComponentAddTuyenduong() {
    const {socketio, dataUser} = useContext(AppContext)
    // Quản lý modal add tuyenduong.
    const [modalAddTuyenduong, setModalAddTuyenduong] = useState(false)
    const handleShowModalAddTuyenduong = () => {
        setModalAddTuyenduong(true)
    }
    const handleCloseModalAddTuyenduong = () => {
        setModalAddTuyenduong(false)
    }
    const handleAddTuyenduongSuccess = (status_) => {
        if (status_) {
            setModalAddTuyenduong(false)
        }
    }

    // Quản lý hiển thị danh sách tuyến đường.
    const [listTuyenduong, setListTuyenduong] = useState(false)
    const handleShowListTuyenduong = () => {
        setListTuyenduong(true)
    }
    const handleCloseListTuyenduong = () => {
        setListTuyenduong(false)
    }
  
  // Quản lý danh sách đường ngõ.
  const [modalDuongngo, setModalDuongngo] = useState(false);
  const handleShowModalDuongngo = () => {
    setModalDuongngo(true)
  }
  const handleCloseModalDuongngo = () => {
    setModalDuongngo(false)
  }

    return (
      <>
        <Dropdown>
          <Dropdown.Toggle
            variant="light fw-5 border-0 d-flex align-items-center" // class mặc định
            id="dropdown-basic"
            bsPrefix="custom-dropdown-toggle" // đặt tên cho class fix loại bỏ class dropdown-toggle
          >
            <LiaStreetViewSolid className="me-1 fs-21" />
            <span>Tuyến đường</span>
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ width: "200px" }} align="end">
            {dataUser[0].goidichvu_user === 1 && (
              <li
                className="dropdown-item d-flex fs-15 align-items-center cursor-pointer"
                onClick={handleShowModalAddTuyenduong}
              >
                Thêm tuyến đường
              </li>
            )}
            <li
              className="dropdown-item d-flex fs-15 cursor-pointer"
              onClick={handleShowListTuyenduong}
            >
              Danh sách tuyến đường
            </li>
            <li
              className="dropdown-item d-flex fs-15 cursor-pointer"
              onClick={handleShowModalDuongngo}
            >
              Danh sách đường ngõ
            </li>
          </Dropdown.Menu>
        </Dropdown>
        {/* Modal add tuyenduong */}
        {modalAddTuyenduong && (
          <ModalReact
            modalTitle="Thêm tuyến đường mới"
            showModal={modalAddTuyenduong}
            handleClose={handleCloseModalAddTuyenduong}
            theme={
              <FormAddTuyenduong
                socketio={socketio}
                result={handleAddTuyenduongSuccess}
              />
            }
          />
        )}
        {/* Modal list tuyenduong */}
        {listTuyenduong && (
          <ModalReact
            modalTitle="Danh sách tuyến đường"
            size="modal-xl"
            showModal={listTuyenduong}
            handleClose={handleCloseListTuyenduong}
            theme={<ComponentListTuyenduong socketio={socketio} />}
          />
        )}
        {/* List duongngo */}
        {modalDuongngo && (
          <ModalReact
            modalTitle="Danh sách đường ngõ"
            showModal={modalDuongngo}
            handleClose={handleCloseModalDuongngo}
            theme={<ListDuongngo socketio={socketio} />}
          />
        )}
      </>
    )
}
export default ComponentAddTuyenduong

// Form add tuyenduong.
function FormAddTuyenduong({ socketio, result }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
    // Quản lý gợi ý phường xã.
    const [valuePhuongxa, setValuePhuongxa] = useState("")
    const [listPhuongxa, setListPhuongxa] = useState([])
    const idPhuongxa = useRef()
    const handleGetValueInputPhuongxa = (e) => {
      setValuePhuongxa(e.target.value)
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: { keywords: e.target.value },
    }
    socketio.emit("client-search-tenphuongxa", params)
        socketio.on("server-search-tenphuongxa", (dataPhuongxa) => {
            setListPhuongxa([...dataPhuongxa])
        
        });
  }
    useEffect(() => {
        return () => {
            socketio.off("client-search-tenphuongxa")
            socketio.off("server-search-tenphuongxa")
        }
    }, [listPhuongxa])
    const handleResultPX = (key, idPX) => {
        setValuePhuongxa(key)
        idPhuongxa.current = idPX
    }

    // Quản lý submit thêm mới
    const checkSuccess = useRef()
    const handleOnSubmit = (object) => {
        object.kn_phuongxa = idPhuongxa.current
        delete object.phuongxa
        const paramsCreate = {
            iden: process.env.REACT_APP_IDEN_DASH,
            object: object
        }
        socketio.emit("client-create-data-tuyenduong", paramsCreate)
        socketio.on("server-create-data-tuyenduong", (data) => {
            if (data === "success") {
                checkSuccess.current = object
                alert("Thêm tuyến đường thành công.")
                idPhuongxa.current = undefined
                result(true)
            }
        })
    }
    useEffect(() => {
        return () => {
            socketio.off("client-create-data-tuyenduong")
            socketio.off("client-create-data-tuyenduong")
        }
    }, [checkSuccess.current])

    return (
        <form onSubmit={handleSubmit(handleOnSubmit)}>
            <div className="col-xl-12 col-md-12">
                <div className="row">
                    <div className="form-floating mb-3 col-6">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Tên input"
                            {...register("tuyenduong", { required: true })}
                        />
                        <label className="fs-15 ms-2 text-muted">
                            Tên đường <span className="text-danger">*</span>
                        </label>
                        {errors.tuyenduong && (
                            <span className="text-danger fs-14">Bạn cần chọn phường/xã.</span>
                        )}
                    </div>
                    <div className="form-floating mb-3 col-6">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Tên input"
                            {...register("phuongxa")}
                            value={valuePhuongxa}
                            onChange={handleGetValueInputPhuongxa}
                        />
                        <label className="fs-15 ms-2 text-muted">
                            Phường/Xã <span className="text-danger">*</span>
                        </label>
                        {errors.phuongxa && (
                            <span className="text-danger fs-14">Bạn cần chọn phường/xã.</span>
                        )}
                        {/* Gợi ý */}
                        {listPhuongxa.length > 0 && (
                            <ListSuggestPhuongxa dataList={listPhuongxa} valueInput={valuePhuongxa} result={handleResultPX}/>
                        )}
                    </div>
                </div>
            </div>
            {/* Button */}
            <div className="px-4 py-3 border-top border-block-start-dashed d-sm-flex justify-content-center">
                <button className="btn btn-primary-light m-1" type="submit">
                    Lưu lại
                </button>
            </div>
        </form>
    );
}

 // Component suggest phuongxa
const ListSuggestPhuongxa = ({ dataList, valueInput, result }) => {
  const [showHide, setShowHide] = useState(true)
  const checkValue = useRef("")
  const [dataSuggest, setDataSuggest] = useState([])
  // Quản lý xử lý danh sách trùng nhau.
  useEffect(() => {
    const dataFilter = dataList.map((item) => {
      return item.tenphuongxa
    })
    const listSuggest = dataFilter.reduce((khoitao, value) => {
      if (khoitao.indexOf(value) === -1) {
        khoitao.push(value)
      }
      return khoitao
    }, [])
    setDataSuggest(dataList)
  }, [dataList, valueInput])

  const handleSetValue = (key, idPX) => {
    result(key, idPX)
    setShowHide(false)
    checkValue.current = key
  }
  useEffect(() => {
    if (checkValue.current !== valueInput) {
      setShowHide(true)
    } else {
      setShowHide(false)
    }
  }, [dataList, checkValue.current, valueInput])

  // Quản lý ẩn gợi ý khi click ra ngoài.
  useEffect(() => {
    window.addEventListener("click", function () {
      setShowHide(false)
    })

  }, [])

  return (
    <ul
      className="list_suggest"
      style={{ display: showHide ? "block" : "none" }}
    >
      {dataSuggest.map((item, index) => {
        return (
          <li
            key={index}
            className="dropdown-item d-flex fs-14"
            onClick={() => handleSetValue(item.tenphuongxa, item.id_phuongxa)}
          >
            {item.tenphuongxa}
          </li>
        )
      })}
    </ul>
  )
}


// List tuyenduong.
function ComponentListTuyenduong({ socketio }) {
    const checkSearch = useRef();
    const [dataTuyenduong, setDataTuyenduong] = useState([])
    const handleResultValueSearch = (keywords) => {
        if (keywords.length > 0) {
            const params = {
              iden: process.env.REACT_APP_IDEN_DASH,
              object: { keywords: keywords },
            }
            socketio.emit("client-search-address-tuyenduong", params)
            socketio.on("server-search-address-tuyenduong", (data) => {
                setDataTuyenduong([...data])
              checkSearch.current = data
            })
        }
    }
    useEffect(() => {
        return () => {
            socketio.off("client-search-address-tuyenduong")
            socketio.off("server-search-address-tuyenduong")
        }
    }, [checkSearch.current])


    if (dataTuyenduong.length === 0 && checkSearch.current) {
      alert("Chưa có dữu liệu.")
    }
    return (
        <div>
            {/* Tìm kiếm */}
            <div className="mb-3">
                <SearchHorizontal
                    className="rounded-3"
                    result={handleResultValueSearch}
                />
            </div>
            {/* Danh sách */}
            <div className="table-responsive">
                <table className="table text-nowrap table-striped-columns">
                    <thead>
                        <tr>
                            <th scope="col">Tên đường</th>
                            <th scope="col">Phường/xã</th>
                            <th scope="col">Diện tích phường</th>
                            <th scope="col">Quận huyện</th>
                            <th scope="col">Diện tích quận</th>
                            <th scope="col">Ngày cập nhật</th>
                            {/* <th scope="col">Action</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {dataTuyenduong.length > 0 &&
                            dataTuyenduong.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <th scope="row">{item.tuyenduong}</th>
                                        <td>{item.tenphuongxa}</td>
                                        <td>{item.dientich_phuongxa} ha</td>
                                        <td>{item.quanhuyen}</td>
                                        <td>{item.dientich_quanhuyen} ha</td>
                                        <td>
                                            {moment(item.ngaycapnhat).format("DD-MM-YYYY HH:mm:ss")}
                                        </td>
                                        {/* <td>
                                        <button className="btn btn-sm btn-danger btn-wave">
                                            <i className="ri-delete-bin-line align-middle me-2 d-inline-block"></i>
                                            Delete
                                        </button>
                                    </td> */}
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

// List dường ngõ.
function ListDuongngo({ socketio }) {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true);
  const searchText = useRef("")
  const handleValueDuongngo = (keywords) => {
    if (keywords) {
      const params = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: {keywords: keywords}
      }
      socketio.emit("client-search-duongngo", params)
      socketio.on("server-search-duongngo", (data) => {
        setData([...data])
        setLoading(false)
        searchText.current = keywords
      })
    }
  }
  useEffect(() => {
    return () => {
      socketio.off("client-search-duongngo")
      socketio.off("server-search-duongngo")
    }
  }, [searchText.current])
  
  return (
    <div>
      <SearchHorizontal result={handleValueDuongngo} className="rounded-3" />
      {loading ? (
        <div className="text-center fs-14 mt-3">
          Nhập từ khóa tuyến đường để tra cứu.
        </div>
      ) : (
        <div style={{    height: "calc(100vh - 12rem)", overflow: "auto"}}>
          {data.length > 0 && (
            <div className="text-primary text-center mt-3 mb-3 alert alert-primary">
              Kết quả có: <span className="fw-5">{data.length}</span> tuyến đường phù hợp
            </div>
          )}
          {data.length > 0 ? (
            data.map((item, index) => {
              let colorAddress = item.tuyenduong
              if (colorAddress) {
                colorAddress = handleColorTextSearch(
                  searchText.current,
                  item.tuyenduong,
                )
              }
              return (
                <div
                  className="alert alert-primary fs-14"
                  role="alert"
                  key={index}
                >
                  <span className="fw-5">
                    Số {item.diachi}, {colorAddress}
                  </span>
                  <p className="mb-0">
                    Đường/Ngõ:{" "}
                    <span className="fw-5">{item.tongduongngo}m</span> <BsDot />
                    <span>Vỉa hè: {item.viahe}m</span>
                    <BsDot />
                    <span>
                      {moment(item.ngaythem)
                        .locale("vi")
                        .fromNow()
                        .replace("một", "1")}
                    </span>
                  </p>
                </div>
              )
            })
          ) : (
            <div className="text-center mt-3 fs-14">
              Không có kết quả phù hợp
            </div>
          )}
        </div>
      )}
    </div>
  )
}