import { useContext, useState } from "react"
import moment from "moment"
import classNames from "classnames/bind"
import styles from "../../Home.module.scss"
import { Notification } from "../../../../Components/Notification"
import AvatarUser from "../../../../Components/Info/AvatarUser"
import AppContext from "../../../../handle-reuses/Contexts/CreateContexts"
import { FiPhoneCall } from "react-icons/fi"
import { jobCate } from "../../../../assets/images"
import CateMenuApp from "../Component/CateMenuApp"
import { Link } from "react-router-dom"
import CateAddMobile from "../Component/CateAddMobile"
import TimeKeepingAndOther from "../Component/TimeKeepingAndOther"
import { Helmet } from "react-helmet"
const cx = classNames.bind(styles)

function HomePageMobile() {
  const { socketio, dataUser } = useContext(AppContext)

  const arrCate = [
    { title: "Nhân sự", image: jobCate.personnel, path: "/nhan-su" },
    { title: "Chấm công", image: jobCate.timekeeping, path: "/cham-cong" },
    { title: "Công việc", image: jobCate.job, path: "/cong-viec" },
    { title: "Hợp đồng", image: jobCate.contract, path: "/hop-dong" },
    { title: "Bảng lương", image: jobCate.wallet, path: "/bang-luong" },
    { title: "Kế toán", image: jobCate.ketoan, path: "/ke-toan" },
    { title: "Báo cáo", image: jobCate.report, path: "/bao-cao" },
    {
      title: "Tài chính cá nhân",
      image: jobCate.investerment,
      path: "/tai-chinh-ca-nhan",
    },

    // { title: "Đối tác", image: jobCate.partner, path: "/doi-tac" },
  ]

  return (
    <>
      <div className={cx("homepagemobile")}>
        <div className={cx("background-image")}></div>
        <div
          className={cx("p-2 px-3 ")}
          style={{
            //   backgroundImage: "url("+backgroundMobile.background+")",
            background: "#ffdfc829",
            height: "calc(100vh)"
          }}
        >
          <div className="d-flex justify-content-between py-3 px-3">
            <div className="" style={{ color: "rgb(74 74 74)" }}>
              <span className="fw-5 fs-21">
                Xin chào ! <span>{dataUser[0].hoten}</span>
              </span>
              <div>
                <p className="mb-0 fs-16">
                  <FiPhoneCall className="me-1 fs-18" />
                  {dataUser[0].sodienthoai}
                </p>
              </div>
            </div>
            <div className="d-flex align-items-center gap-3">
              <Notification style={{ color: "rgb(74 74 74)", fontSize: "36px" }} />
            </div>
          </div>

          {/* Số dư tài khoản*/}
          <div
            className="card custom-card mt-3 mb-3 rounded-4"
            // style={{ boxShadow: "none" }}
          >
            <div className="card-header border-bottom-0 pb-0 d-flex justify-content-center">
              <div
                className="wrapper_ground"
                style={{ position: "absolute", top: "-39px" }}
              >
                <AvatarUser width="68px" height="68px" />
              </div>
            </div>
            <div className="card-body pt-1 p-2 mt-3">
              <CateAddMobile socketio={socketio} dataUser={dataUser} />
            </div>
            <div className="card-footer">
              <TimeKeepingAndOther socketio={socketio} dataUser={dataUser} />
            </div>
          </div>
          {/* Kho dữ liệu  */}
          <div className="mt-4">
            <h6 className="fs-16">Kho dữ liệu</h6>
            <CateMenuApp />
          </div>
          {/* content category */}
          <div className="mt-3">
            <h6 className="fs-16 mb-3">Danh mục công việc</h6>
            <div className="row">
              {arrCate.map((item, index) => {
                return (
                  <Link
                    to={item.path}
                    key={index}
                    className="d-flex align-items-center flex-direction-column col-3 gap-1 mb-4"
                  >
                    <span className="avatar avatar-md">
                      <img src={item.image} alt="img" />
                    </span>
                    <div className="fs-14 fw-5 text-center">{item.title}</div>
                  </Link>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HomePageMobile
