import classNames from "classnames/bind";
import styles from "../../Dashboard.module.scss"
import { LoadingSpinner } from "../../../../Components/Loading"
import ListProduct from "../Component/ListProduct";
const cx = classNames.bind(styles)

function PageProductPC({ loading, dataEstates, socketio, result }) {
    return (
      <div className={cx("mt-3")}>
        {loading ? (
          <LoadingSpinner />
        ) : (
            <ListProduct dataEstates={dataEstates} socketio={socketio} result={result} />
        )}
      </div>
    )
}

export default PageProductPC