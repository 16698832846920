import classNames from "classnames/bind";
import styles from "./Loading.module.scss"

const cx = classNames.bind(styles);

function LoadingSample() {
    return (
        <div className={cx("loading")}>
            <div className="spinner-border border-2" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    );
}

export default LoadingSample;