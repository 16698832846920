import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form"

function FormAddContractOther({ socketio, dataUser, result }) {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    // Set format price_service;
    const [priceService, setPriceService] = useState("");
    const handleFormatPriceService = (e) => {
        let formatPrice = e.target.value.replace(/[.,]/g, "");
        if (Number(formatPrice) >= 1000) {
            let formatPrice_ = Number(formatPrice).toLocaleString().replace(/[.]/g, ",")
            setPriceService(formatPrice_)
        } else {
            if (Number(formatPrice)) {
                setPriceService(formatPrice)
            } else {
                if (formatPrice.length > 0) {
                    alert("Bạn cần nhập dạng số")
                } else {
                    setPriceService(e.target.value)
                }
            }
        }
    }
    const checkSuccess = useRef()
    const handleOnSubmit = (object) => {
        object.id_detail = 0;
        object.hinhthuc_thanhtoan = 0;
        object.nhanvien_chot = dataUser[0].id_user;
        object.nhanvien_thitruong = dataUser[0].id_user;
        object.tien_hoahong = 0;
        object.hoahong_thitruong = 0;
        object.price_service = object.price_service.replace(/[.,]/g, "")
        
        const paramsCreate = {
            iden: process.env.REACT_APP_IDEN_DASH,
            object: object
        }
        socketio.emit("client-create-data-contract", paramsCreate)
        socketio.on("server-create-data-contract", (data) => {
            if (data === "success") {
                alert("Thêm hợp đồng thành công.")
                checkSuccess.current = object
                result(true)
            }
        })
    }
    useEffect(() => {
        return () => {
            socketio.off("client-create-data-contract")
            socketio.off("server-create-data-contract")
        }

    }, [checkSuccess.current])

    return (
        <form onSubmit={handleSubmit(handleOnSubmit)}>
            <div className="col-xl-12 col-md-12">
                <div className="row">
                    <div className="form-floating rounded-3 mb-3 col-12">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Tên input"
                            {...register("contract_name", { required: true })}
                        />
                        <label className="fs-15 ms-2 text-muted">Tên hợp đồng <span className="text-danger">*</span></label>
                        {errors.contract_name && (
                            <span className="text-danger fs-14">
                                Bạn cần nhập tên hợp đồng.
                            </span>
                        )}
                    </div>
                    <div className="form-floating rounded-3 mb-3 col-12">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Tên input"
                            {...register("price_service")}
                            value={priceService}
                            onChange={handleFormatPriceService}
                        />
                        <label className="fs-15 ms-2 text-muted">Giá hợp đồng <span className="text-danger">*</span></label>
                        {errors.price_service && (
                            <span className="text-danger fs-14">
                                Bạn cần nhập giá hợp đồng.
                            </span>
                        )}
                    </div>
                </div>
            </div>
            {/* Button */}
            <div className="px-4 py-3 border-top border-block-start-dashed d-flex justify-content-center">
                <button className="btn btn-primary-light m-1" type="submit">
                    Thêm mới
                </button>
            </div>
        </form>
    );
}
export default FormAddContractOther