import { useContext } from "react"
import ContentAIPage2 from "./ContentAIPage2"
import AppContext from "../../../handle-reuses/Contexts/CreateContexts"

function ContentAIPage() {
  const { dataUser } = useContext(AppContext)
  if (dataUser[0].goidichvu_user !== 1) {
    return (
      <div className="text-danger mt-3 text-center fs-15">
        Bạn không có quyền truy cập vào trang này.
      </div>
    )
  }
  return <ContentAIPage2 />
}

export default ContentAIPage