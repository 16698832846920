import { useContext, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import classNames from "classnames/bind"
import styles from "../Job.module.scss"
import AppContext from "../../../handle-reuses/Contexts/CreateContexts"
import SearchSalary from "../Component/Bangluong/SearchSalary"
import ThueThuNhapCaNhan from "../Component/Bangluong/ThueThuNhapCaNhan"
import LoadingSpinner from "../../../Components/Loading/LoadingSpinner"
import { BsDot } from "react-icons/bs"
import ModalReact from "../../../Components/Modal/ModalReact"
import DetailPhieuluong from "../Component/Bangluong/DetailPhieuluong"
const cx = classNames.bind(styles);

function ListSalaryMobile() {
    const { dataUser, socketio } = useContext(AppContext)
    const navigate = useNavigate()
    useLayoutEffect(() => {
      if (dataUser[0].bang_luong !== 1) {
        navigate("/")
      }
    }, [])
    const [objectSearch, setObjectSearch] = useState({
      monthSearch: moment().format("YYYY-MM"),
      textSearch: "",
    })
    // Quản lý danh mục.
    const [activeCate, setActiveCate] = useState("bang-luong")
    const handleActiveCate = (cate) => {
      setActiveCate(cate)
    }

    // Quản lý search data.
    const handleGetValueSearchText = (object) => {
        setObjectSearch({
        monthSearch: object.monthSearch,
        textSearch: object.textSearch
        })
    }
    
    return (
      <div>
        {/* Search */}
        <SearchSalary resultSearch={handleGetValueSearchText} />
        {/* Title */}
        <div className="text-center mt-3 fs-15 alert alert-primary">
          <span>
            Bảng lương{" "}
            <span className="fw-5 fs-18">{moment(objectSearch.monthSearch).format("MM/YYYY")}</span>
          </span>
        </div>
        {/* Category */}
        <div className={cx("d-flex alig-items-center gap-3 mt-3")}>
          <button
            className={cx(
              "border-0 bg-transparent",
              activeCate === "bang-luong" ? "color-orange" : "color-gray",
            )}
            onClick={() => handleActiveCate("bang-luong")}
          >
            <span className="d-flex align-items-center">Bảng lương</span>
          </button>
          <button
            className={cx(
              "border-0 bg-transparent",
              activeCate === "thue-tncn" ? "color-orange" : "color-gray",
            )}
            onClick={() => handleActiveCate("thue-tncn")}
          >
            <span className="d-flex align-items-center">Tính thuế TNCN</span>
          </button>
        </div>

        {/* List content */}
        <div className={cx("mt-3")}>
          {/* Tiến độ */}
          {activeCate === "bang-luong" && (
            <ListSalaryTypeCard
              socketio={socketio}
              dataUser={dataUser}
              objectSearch={objectSearch}
            />
          )}
          {activeCate === "thue-tncn" && (
            <ThueThuNhapCaNhan socketio={socketio} dataUser={dataUser} />
          )}
        </div>
      </div>
    )
}

export default ListSalaryMobile


//Danh sách bảng lương.
function ListSalaryTypeCard({ socketio, dataUser, objectSearch }) {
    const [arrSalaryOld, setArrSalaryOld] = useState([]);
    const [loading, setLoading] = useState(false)
    // Set data đã lưu lại bảng lương.
    useEffect(() => {
        const paramsSalary = {
            iden: process.env.REACT_APP_IDEN_DASH,
            object: {
                list_bangluong: dataUser[0].list_bangluong,
                user_id: dataUser[0].id_user,
                monthSearch: objectSearch.monthSearch,
                textSearch: objectSearch.textSearch
            }
        }
        socketio.emit("client-get-data-month-job-bangluong", paramsSalary)
        socketio.on("server-get-data-month-job-bangluong", (data) => {
            setArrSalaryOld([...data])
            setLoading(false)
        });

        return () => {
            socketio.off("client-get-data-month-job-bangluong")
            socketio.off("server-get-data-month-job-bangluong")
        }
    }, [])

    // Chi tiết bảng lương đã phê duyệt.
    const [modalDetail, setModalDetail] = useState(false)
    const dataDetail = useRef()
    const handleDetailPhieuluong = (object) => {
        dataDetail.current = object
        setModalDetail(true)
    }
    const handleCloseModalDetail = () => {
        setModalDetail(false)
        dataDetail.current = undefined
    }

    if (loading) {
        return <LoadingSpinner />
    }
    if (arrSalaryOld.length === 0) {
        return (
            <div className="text-center">Chưa có bảng lương</div>
        )
    }
    
    return (
        <div>
            {arrSalaryOld.map((item,index)=>{
                return (
                  <div className="col-xl-3 cursor-pointer" key={index} onClick={()=>handleDetailPhieuluong(item)}>
                    <div className="card custom-card card-bg-light">
                      <div className="card-body p-2">
                        <div className="d-flex align-items-center w-100">
                          <div className="me-2">
                            <span className="avatar avatar-rounded">
                              <img
                                src={"https://system.view360.vn/"+ item.avatar}
                                alt="img"
                              />
                            </span>
                          </div>
                          <div className="">
                            <div className="fw-semibold">{item.hoten}</div>
                            <p className="mb-0 text-muted fs-16">
                              {item.sodienthoai}<BsDot />Tiên độ {item.tiendo_kpi}%
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
            })}
            {/* Model detail phiếu lương */}
            {modalDetail && (
                <ModalReact
                    modalTitle="Phiếu lương nhân viên"
                    showModal={modalDetail}
                    handleClose={handleCloseModalDetail}
                    theme={<DetailPhieuluong object={dataDetail.current} />}
                />
            )}
        </div>
    );
}