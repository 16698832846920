import { useEffect, useRef, useState } from "react"
import ModalReact from "../../../Components/Modal/ModalReact"
import { handleFormatPrice } from "../../../handle-reuses/reuses"
import moment from "moment"

function DetailDataSale({ socketio, dataItem }) {
  const [modalDetail, setModalDetail] = useState(false)
  const handleShowModalDetail = () => {
    setModalDetail(true)
  }
  const handleCloseModalDetail = () => {
    setModalDetail(false)
  }

  return (
    <>
      <button
        className="border-0 bg-transparent text-info-1"
        onClick={handleShowModalDetail}
      >
        Xem thêm
      </button>
      {/* Modal detail */}
      <ModalReact
        modalTitle="Chi tiết sản phẩm"
        showModal={modalDetail}
        handleClose={handleCloseModalDetail}
        theme={<InfoDetailDataSale socketio={socketio} dataItem={dataItem} />}
      />
    </>
  )
}
export default DetailDataSale

function InfoDetailDataSale({ socketio, dataItem }) {
  const [dataItemNew, setDataItemNew] = useState()
    // Quản lý dữ liệu thống kê giá.
    const [comparePrice, setComparePrice] = useState(0)
    const [checkComparePrice, setCheckComparePrice] = useState(false)
    const totalQuantity = useRef(0)
  // Get dataItem mới nhất.
  useEffect(() => {
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: { id_sp: dataItem.id_sp }
    }
    socketio.emit("client-filter-data-sale-idsp-sanpham", params)
    socketio.on("server-filter-data-sale-idsp-sanpham", (data) => {
      setDataItemNew(...data)
    })
    return () => {
      socketio.off("client-filter-data-sale-idsp-sanpham")
      socketio.off("server-filter-data-sale-idsp-sanpham")
    }
  }, []);

  // Quản lý get data compare price
    useEffect(() => {
      if (dataItemNew) {
          if (
            dataItemNew.tuyenduong &&
            dataItemNew.quanhuyen &&
            dataItemNew.thanhpho &&
            dataItemNew.trucngo &&
            dataItemNew.phaply &&
            dataItemNew.loaibds
          ) {
            const params = {
              iden: process.env.REACT_APP_IDEN_DASH,
              object: {
                tuyenduong: dataItemNew.tuyenduong,
                quanhuyen: dataItemNew.quanhuyen,
                thanhpho: dataItemNew.thanhpho,
                trucngo: Number(dataItemNew.trucngo),
                phaply: dataItemNew.phaply,
                loaibds: dataItemNew.loaibds,
              },
            }
            socketio.emit("client-search-compare-price-product", params)
            socketio.on("server-search-compare-price-product", (data) => {
              let giaThitruong = 0
              if (data.length > 0) {
                giaThitruong =
                  Number(data[0].giasanpham) / Number(data[0].quantity)
                const giaSanpham = Number(
                  dataItemNew.giasanpham.replace(/[.,]/g, ""),
                )
                const resultComparePrice = giaSanpham - giaThitruong
                if (resultComparePrice !== 0) {
                  const tysuat =
                    ((giaSanpham - giaThitruong) / giaThitruong) * 100
                  setComparePrice(tysuat.toFixed(2))
                  setCheckComparePrice(true)
                } else {
                  setCheckComparePrice(true)
                }
                totalQuantity.current = (data[0].quantity / 100) * 100
              }
            })
          }
          return () => {
            socketio.off("client-search-compare-price-product")
            socketio.off("server-search-compare-price-product")
          }
        }
    }, [dataItemNew])
  
  if (!dataItemNew) {
    return <div className="text-center mt-3">Đang tải...</div>
  }
  return (
    <div>
      <div className="card custom-card">
        <div className="card-header align-items-center">
          <div className="me-2">
            <span className="avatar avatar-rounded p-1 bg-danger-transparent">
              <img src={"/" + dataItemNew.avatar_author} alt="avatar" />
            </span>
          </div>
          <div className="flex-fill">
            <span className="fw-semibold fs-15 d-block text-truncate">
              {dataItemNew.status === 1 && (
                <span className="badge bg-success me-1 fs-12">Ads</span>
              )}
              {dataItemNew.diachi}
            </span>
            <span className="text-muted d-block fs-15">
              Địa chỉ:{" "}
              <span className="text-default fw-5">
                {dataItemNew.tuyenduong}
              </span>{" "}
              {dataItemNew.quanhuyen}, {dataItemNew.thanhpho}
            </span>
          </div>
        </div>
        <div className="card-body">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <div className="fw-semibold mb-1 fs-15">Loại BDS</div>
              <div className="fs-15">{dataItemNew.loaibds}</div>
            </div>
            <div className="text-end fs-15">
              <div className="fw-semibold mb-1">Tình trạng</div>
              {dataItemNew.tinhtrang === 0 ? (
                <span className="text-success">Còn hàng</span>
              ) : (
                <span className="text-danger">Đã bán</span>
              )}
            </div>
          </div>
          <div className="mb-3 mt-3 border-top border-block-start-dashed"></div>
          <div className=" mb-1 fs-15">Thông tin sản phẩm :</div>
          <div className="d-flex fs-15 text-muted justify-content-between mt-2">
            <div>
              DTMB: {dataItemNew.dtmb_mt}m<sup>2</sup>
            </div>
            <div>Mặt tiền: {dataItemNew.mattien}m</div>
            <div>Số tầng: {dataItemNew.sotang}</div>
          </div>
          <div className="d-flex fs-15 text-muted justify-content-between mt-2">
            <div>Trục ngõ: {dataItemNew.trucngo}m</div>
            <div>Hướng: {dataItemNew.huongnha}</div>
            <div>Pháp lý: {dataItemNew.phaply}</div>
          </div>

          <div className="mb-3 mt-3 border-top border-block-start-dashed"></div>
          <div className="fw-semibold mb-1 fs-14 d-flex justify-content-between">
            <span>
              Giá :{" "}
              {handleFormatPrice(dataItemNew.giasanpham.replace(/[.,]/g, ""))}
              <span className="ms-1 text-success">
                {handleFormatPrice(
                  Number(dataItemNew.giasanpham.replace(/[.,]/g, "")) /
                    dataItemNew.dtmb_mt,
                )}
                /m<sup>2</sup>
              </span>
            </span>
            <span className="text-muted fw-4">
              <span className="me-1">Mức độ chính xác</span>

              {totalQuantity.current <= 15 && (
                <span className="text-danger">
                  {totalQuantity.current}% rất thấp
                </span>
              )}
              {totalQuantity.current > 15 && totalQuantity.current < 50 && (
                <span className="text-warning">
                  {totalQuantity.current}% thấp
                </span>
              )}
              {totalQuantity.current >= 50 && totalQuantity.current < 100 && (
                <span className="text-info">
                  {totalQuantity.current}% trung bình
                </span>
              )}
              {totalQuantity.current >= 100 && totalQuantity.current < 500 && (
                <span className="text-success">
                  {totalQuantity.current}% tốt
                </span>
              )}
              {totalQuantity.current >= 500 && (
                <span className="text-success">
                  {totalQuantity.current}% rất tốt
                </span>
              )}
            </span>
          </div>
          <div>
            <div
              className="progress progress-xs progress-animate progress-bar-striped"
              role="progressbar"
              aria-valuenow="80"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <div
                className="progress-bar progress-bar-striped progress-bar-animated op-8"
                style={{ width: comparePrice + "%" }}
              ></div>
            </div>
            <div className="mt-1 fs-14">
              <span className="text-primary fw-semibold">
                {!checkComparePrice && "Chưa đủ dữ liệu để thống kê"}
                {comparePrice > 0 && "Cao hơn"}
                {comparePrice === 0 &&
                  checkComparePrice &&
                  "Bằng giá thị trường"}
                {comparePrice < 0 && "Thấp hơn"}
              </span>{" "}
              {checkComparePrice && (
                <>
                  <span
                    className={
                      comparePrice > 0
                        ? "text-danger fw-5 me-1"
                        : "text-success fw-5 me-1"
                    }
                  >
                    {comparePrice}%
                  </span>
                  <span className="text-muted">
                    so với các sản phẩm tương tự
                  </span>
                </>
              )}
            </div>
            {/* <p className="fs-14 text-success fw-semibold mb-0 d-flex align-items-center mt-2">
              <span className="me-1">
                Giá tính trên m<sup>2</sup>:
              </span>
              {handleFormatPrice(
                Number(dataItemNew.giasanpham.replace(/[.,]/g, "")) /
                  dataItemNew.dtmb_mt,
              )}
              /m<sup>2</sup>
            </p> */}
          </div>
        </div>
        <div className="card-footer d-flex align-items-center justify-content-between">
          <div>
            <span className="text-muted fs-15 d-block">Ngày cập nhật :</span>
            <span className="text-muted fs-15 d-block">
              {moment(dataItemNew.ngaycapnhat).format("DD-MM-YYYY HH:mm:ss")}
            </span>
          </div>
          <div className="text-end">
            <span className="text-muted fs-15 d-block">Liên hệ</span>
            <span className="fw-semibold fs-15 d-block">
              {dataItemNew.hoten} {dataItemNew.sodienthoai}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
