import { useEffect, useState } from "react"
import ListDulieuPhantich from "./ListDulieuPhantich"
import ListPhanchia from "./ListPhanchia"
import ListPhantich from "./ListPhantich"
import LoadingSpinner from "../../../../../Components/Loading/LoadingSpinner"
import { handleTotalCardWallet } from "../reuse_taichinhcanha"

function ListPhanchiaDongtien({
  socketio,
  dataUser,
  objectSearch,
  dataUpdateCreate,
  dataUpdateRevenueCreate,
  dataUpdateCreateSuccessChiphi,
}) {
  // Set toong thu nhap all.
  const [thunhapAll, setThunhapAll] = useState(0)
  const [phanboAll, setPhanboAll] = useState(0)
  const [loading, setLoading] = useState(true)
  const [changeStatus, setChangeStatus] = useState();
  const [dataCateThunhap, setDataCateThunhap] = useState([])
  const [dataCateExpese, setDataCateExpense] = useState([])

  const handleChangeStatus = (object) => {
    setChangeStatus(object)
  }

  useEffect(() => {
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: { user_id: dataUser[0].id_user },
    }
    // tong thu nhap all
    socketio.emit("client-total-thunhap-all-taichinhcanhan-thunhap", params)
    socketio.on("server-total-thunhap-all-taichinhcanhan-thunhap", (data) => {
      if (data.length > 0) {
        let totalThunhap = handleTotalCardWallet(data)
        setThunhapAll(totalThunhap)
      }
    })
    // tong da phan bo.
    socketio.emit("client-get-total-money-All-taichinhcanha-dongtien", params)
    socketio.on("server-get-total-money-All-taichinhcanha-dongtien", (data) => {
      if (data.length > 0) {
        setPhanboAll(data[0].total_money)
      }
      
    });
    // Data tổng thu nhập theo danh mục.
    socketio.emit(
      "client-total-thunhap-for-cate-taichinhcanhan-thunhap",
      params,
    )
    socketio.on(
      "server-total-thunhap-for-cate-taichinhcanhan-thunhap",
      (data) => {
        setDataCateThunhap([...data])
      },
    )

    // data tổng chi phí theo danh mục.
    socketio.emit("client-total-chiphi-for-cate-taichinhcanhan-chiphi", params)
    socketio.on("server-total-chiphi-for-cate-taichinhcanhan-chiphi", (data) => {
      if (data.length > 0) {
        setDataCateExpense([...data])
      }
      setLoading(false)
    })
    return () => {
      socketio.off("client-total-thunhap-all-taichinhcanhan-thunhap")
      socketio.off("server-total-thunhap-all-taichinhcanhan-thunhap")

      socketio.off("client-total-thunhap-for-cate-taichinhcanhan-thunhap")
      socketio.off("server-total-thunhap-for-cate-taichinhcanhan-thunhap")

      socketio.off("client-total-chiphi-for-cate-taichinhcanhan-chiphi")
      socketio.off("server-total-chiphi-for-cate-taichinhcanhan-chiphi")
    }
  }, [dataUpdateCreate, changeStatus, dataUpdateRevenueCreate, dataUpdateCreateSuccessChiphi])

  // Quan ly su thay doi khi them moi gia vang thanh cong.
  const handleChangeStatusGiavang = (object) => {
    setChangeStatus(object)
  }

  if (loading) {
    return <LoadingSpinner />
  }
  if (thunhapAll === 0) {
    return <div className="text-center mt-5 fs-16">Bạn chưa có thu nhập</div>
  }

  return (
    <div className="wrapper_ground">
      <div
        className="list_items col-12 col-xl-3 p-3 rounded-4"
        style={{
          background: "white",
          height: "calc(100vh - 12rem)",
          overflow: "auto",
        }}
      >
        <ListPhantich
          socketio={socketio}
          dataUser={dataUser}
          objectSearch={objectSearch}
          dataUpdateCreate={dataUpdateCreate}
          totalThunhapAll={thunhapAll}
          totalPhanboAll={phanboAll}
          changeStatus={handleChangeStatus}
          dataCateThunhap={dataCateThunhap}
          dataCateExpense={dataCateExpese}
        />
      </div>
      <div
        className="list_items col-12 col-xl-3 p-3 fs-16 rounded-4"
        style={{
          background: "white",
          height: "calc(100vh - 12rem)",
          overflow: "auto",
        }}
      >
        <ListPhanchia
          socketio={socketio}
          dataUser={dataUser}
          objectSearch={objectSearch}
          dataUpdateCreate={dataUpdateCreate}
          totalThunhapAll={thunhapAll}
          totalPhanboAll={phanboAll}
          changeStatus={handleChangeStatus}
          dataCateThunhap={dataCateThunhap}
          dataCateExpense={dataCateExpese}
        />
      </div>

      <div
        className="list_items col-12 col-xl-3 p-3 fs-16 rounded-4"
        style={{
          background: "white",
          height: "calc(100vh - 12rem)",
          overflow: "auto",
        }}
      >
        <ListDulieuPhantich
          socketio={socketio}
          dataUser={dataUser}
          objectSearch={objectSearch}
          result={handleChangeStatusGiavang}
        />
      </div>
    </div>
  )
}
export default ListPhanchiaDongtien