
import LoadingSpinner from "../../../../../Components/Loading/LoadingSpinner";
import { useEffect, useState } from "react";
import PieChart from "../../../../../Components/VongTySuat/PieChart";

function CardWalletStatistical({ socketio, dataUser, totalPhanchia, changeStatus }) {
  const [totalThunhapCard, setTotalThunhapCard] = useState(0)
  const [totalThunhapWallet, setTotalThunhapWallet] = useState(0)
  const [totalChiphiCard, setTotalChiphiCard] = useState(0)
  const [totalChiphiWallet, setTotalChiphiWallet] = useState(0)
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        user_id: dataUser[0].id_user,
      },
    }
    // Set thu nhap
    socketio.emit("client-total-thunhap-all-taichinhcanhan-thunhap", params)
    socketio.on("server-total-thunhap-all-taichinhcanhan-thunhap", (data) => {
      if (data.length > 0) {
        setTotalThunhapCard(data[0].card_payment)
        setTotalThunhapWallet(data[0].wallet_payment)
      }
    })
    // set chi phi.
    socketio.emit("client-total-chiphi-all-taichinhcanhan-chiphi", params)
    socketio.on("server-total-chiphi-all-taichinhcanhan-chiphi", (data) => {
      if (data.length > 0) {
        setTotalChiphiCard(data[0].card_payment)
        setTotalChiphiWallet(data[0].wallet_payment)
      }
      setLoading(false)
    })
    

    return () => {
      socketio.off("client-total-thunhap-all-taichinhcanhan-thunhap")
      socketio.off("server-total-thunhap-all-taichinhcanhan-thunhap")

      socketio.off("client-total-chiphi-all-taichinhcanhan-chiphi")
      socketio.off("server-total-chiphi-all-taichinhcanhan-chiphi")

    }
  }, [changeStatus])

  if (loading) {
    return <LoadingSpinner />
  }

  const loinhuan =
    totalThunhapCard +
    totalThunhapWallet -
    (totalChiphiCard + totalChiphiWallet)
  const tysuatCard =
    ((totalThunhapCard - totalPhanchia - totalChiphiCard) / loinhuan) * 100
  const tysuatWallet =
    ((totalThunhapWallet - totalChiphiWallet) / loinhuan) * 100
  const objectCard = {
    sizeChart: 55,
    rate: tysuatCard
      ? Number.isInteger(tysuatCard)
        ? tysuatCard
        : tysuatCard.toFixed(2)
      : 0,
    colorMain: "#845adf",
    color: "#efefef",
  }
  const objectWallet = {
    sizeChart: 55,
    rate: tysuatWallet
      ? Number.isInteger(tysuatWallet)
        ? tysuatWallet
        : tysuatWallet.toFixed(2)
      : 0,
    colorMain: "#845adf",
    color: "#efefef",
  }
  return (
    <div className="wrapper_ground justify-content-between">
      <div className="list-items d-flex align-items-center gap-2">
        <div>
          <PieChart data={objectCard} />
        </div>
        <div>
          <span className="fs-17">Số dư thẻ</span>
          <p className="mb-0 fs-17">
            {(totalThunhapCard - totalPhanchia - totalChiphiCard)
              .toLocaleString()
              .replace(/[.]/g, ",")}
          </p>
        </div>
      </div>
      <div className="list-items d-flex align-items-center gap-2">
        <div>
          <PieChart data={objectWallet} />
        </div>
        <div>
          <span className="fs-17">Số dư ví</span>
          <p className="mb-0 fs-17">
            {(totalThunhapWallet - totalChiphiWallet)
              .toLocaleString()
              .replace(/[.]/g, ",")}
          </p>
        </div>
      </div>
    </div>
  )
}

export default CardWalletStatistical