import PayHisUserTop from "../Component/PayHisUserTop"
import ListPayHisUser from "../Component/ListPayHisUser"
import { useState } from "react"

function PagePayHisMobile({ socketio }) {

  const [valueText, setValueText] = useState("")
  // Giá trị tìm kiếm trả về
  const handleResultSearch = (keyword) => {
    setValueText(keyword)
  }
    return (
      <div className="mt-4">
        <div className="mb-1">
          <PayHisUserTop socketio={socketio} result={handleResultSearch} />
        </div>
        <ListPayHisUser socketio={socketio} keyword={valueText} />
      </div>
    )
}

export default PagePayHisMobile