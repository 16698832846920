import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";

function FormAddTarget({ socketio, dataUser, result }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  // Format money.
  const [formatMoneyTarget, setFormatMoneyTarget] = useState("")
  const handleFormatMoneyTarget = (e) => {
    let format = e.target.value.replace(/[.,]/g, "")
    if (Number(format)) {
      let format_ = Number(format).toLocaleString().replace(/[.]/g, ",")
      setFormatMoneyTarget(format_)
    } else {
      alert("Bạn cần nhập dạng số.")
    }
  }


    // Xu ly button submit.
    const changeCreateSuccess = useRef()
    const handleOnSubmit = (object) => {
        object.money_target = object.money_target.replace(/[.,]/g, "")
        object.user_id = dataUser[0].id_user

        // Xu ly them moi.
        const params = {
            iden: process.env.REACT_APP_IDEN_DASH,
            object: object
        }
        socketio.emit("client-create-data-taichinhcanhan-target", params)
        socketio.on("server-create-data-taichinhcanhan-target", (data) => {
            result({
                status: true,
                dataUpdate: data
            });
            alert("Thêm mới thành công.");
            changeCreateSuccess.current = object;
        })
    }
    useEffect(() => {
        return () => {
            socketio.off("client-create-data-taichinhcanhan-target")
            socketio.off("server-create-data-taichinhcanhan-target")
        }
    }, [changeCreateSuccess.current])
    
  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <div className="col-xl-12 col-md-12">
        <div className="row">
          <div className="form-floating mb-3 col-12">
            <input
              type="text"
              className="form-control rounded-3"
              placeholder="Tên input"
              {...register("target_cate", { required: true })}
            />
            <label className="fs-15 ms-2 text-muted">
              Loại mục tiêu<span className="text-danger ms-1">*</span>
            </label>
            {errors.target_cate && (
              <span className="text-danger fs-12">
                *Bạn cần nhập tên loại mục tiêu
              </span>
            )}
          </div>
          <div className="form-floating mb-3 col-12">
            <input
              type="text"
              className="form-control rounded-3"
              placeholder="Tên input"
              {...register("money_target", { required: true })}
              value={formatMoneyTarget}
              onChange={handleFormatMoneyTarget}
            />
            <label className="fs-15 ms-2 text-muted">
              Số tiền mục tiêu<span className="text-danger ms-1">*</span>
            </label>
            {errors.money_target && (
              <span className="text-danger fs-12">
                *Bạn cần nhập số tiền cho mục tiêu
              </span>
            )}
          </div>
          <div className="form-floating mb-3 col-12">
            <input
              type="datetime-local"
              className="form-control rounded-3"
              placeholder="Tên input"
              {...register("date_start", { required: true })}
            />
            <label className="fs-15 ms-2 text-muted">
              Bắt đầu<span className="text-danger ms-1">*</span>
            </label>
            {errors.date_start && (
              <span className="text-danger fs-12">
                *Bạn cần chọn ngày bắt đầu
              </span>
            )}
          </div>
          <div className="form-floating mb-3 col-12">
            <input
              type="datetime-local"
              className="form-control rounded-3"
              placeholder="Tên input"
              {...register("date_end", { required: true })}
            />
            <label className="fs-15 ms-2 text-muted">
              Kết thúc<span className="text-danger ms-1">*</span>
            </label>
            {errors.date_end && (
              <span className="text-danger fs-12">
                *Bạn cần chọn ngày kết thúc
              </span>
            )}
          </div>
        </div>
        {/* Ghi chú */}
        <div className="form-floating mb-4">
          <textarea
            className="form-control rounded-3"
            placeholder="Nhập ghi chú"
            style={{ height: "100px" }}
            {...register("note")}
          ></textarea>
          <label className="fs-15 text-muted">Phần thưởng</label>
        </div>
        {/* Button */}
        <div className="px-4 py-3 border-top border-block-start-dashed d-flex justify-content-center">
          <button
            className="btn btn-purple-gradient btn-wave waves-effect waves-light"
            type="submit"
          >
            Thêm mới
          </button>
        </div>
      </div>
    </form>
  )
}
export default FormAddTarget


// Form edit data.
export function FormEditTarget({ socketio, dataUser, dataEdit, result }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  // Format money.
  const [formatMoneyTarget, setFormatMoneyTarget] = useState(Number(dataEdit.money_target).toLocaleString().replace(/[.]/g, ","))
  const handleFormatMoneyTarget = (e) => {
    let format = e.target.value.replace(/[.,]/g, "")
    if (Number(format)) {
      let format_ = Number(format).toLocaleString().replace(/[.]/g, ",")
      setFormatMoneyTarget(format_)
    } else {
      alert("Bạn cần nhập dạng số.")
    }
  }

  // Xu ly button submit.
  const changeEditSuccess = useRef()
  const handleOnSubmit = (object) => {
    object.money_target = object.money_target.replace(/[.,]/g, "")
    object.user_id = dataUser[0].id_user
    object.id = dataEdit.id

    // Xu ly them moi.
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: object,
    }
    socketio.emit("client-edit-data-taichinhcanhan-target", params)
    socketio.on("server-edit-data-taichinhcanhan-target", (data) => {
      result({
        status: "edit success",
        dataUpdate: data,
      })
      alert("Sửa thông tin thành công.")
      changeEditSuccess.current = object
    })
  }
  useEffect(() => {
    return () => {
      socketio.off("client-edit-data-taichinhcanhan-target")
      socketio.off("server-edit-data-taichinhcanhan-target")
    }
  }, [changeEditSuccess.current])

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <div className="col-xl-12 col-md-12">
        <div className="row">
          <div className="form-floating mb-3 col-12">
            <input
              type="text"
              className="form-control rounded-3"
              placeholder="Tên input"
              {...register("target_cate", { required: true })}
              defaultValue={dataEdit.target_cate}
            />
            <label className="fs-15 ms-2 text-muted">
              Loại mục tiêu<span className="text-danger ms-1">*</span>
            </label>
            {errors.target_cate && (
              <span className="text-danger fs-12">
                *Bạn cần nhập tên loại mục tiêu
              </span>
            )}
          </div>
          <div className="form-floating mb-3 col-12">
            <input
              type="text"
              className="form-control rounded-3"
              placeholder="Tên input"
              {...register("money_target", { required: true })}
              value={formatMoneyTarget}
              onChange={handleFormatMoneyTarget}
            />
            <label className="fs-15 ms-2 text-muted">
              Số tiền mục tiêu<span className="text-danger ms-1">*</span>
            </label>
            {errors.money_target && (
              <span className="text-danger fs-12">
                *Bạn cần nhập số tiền cho mục tiêu
              </span>
            )}
          </div>
          <div className="form-floating mb-3 col-12">
            <input
              type="datetime-local"
              className="form-control rounded-3"
              placeholder="Tên input"
              {...register("date_start", { required: true })}
              defaultValue={moment(dataEdit.date_start).format(
                "YYYY-MM-DD HH:mm",
              )}
            />
            <label className="fs-15 ms-2 text-muted">
              Bắt đầu<span className="text-danger ms-1">*</span>
            </label>
            {errors.date_start && (
              <span className="text-danger fs-12">
                *Bạn cần chọn ngày bắt đầu
              </span>
            )}
          </div>
          <div className="form-floating mb-3 col-12">
            <input
              type="datetime-local"
              className="form-control rounded-3"
              placeholder="Tên input"
              {...register("date_end", { required: true })}
              defaultValue={moment(dataEdit.date_end).format(
                "YYYY-MM-DD HH:mm",
              )}
            />
            <label className="fs-15 ms-2 text-muted">
              Kết thúc<span className="text-danger ms-1">*</span>
            </label>
            {errors.date_end && (
              <span className="text-danger fs-12">
                *Bạn cần chọn ngày kết thúc
              </span>
            )}
          </div>
        </div>
        {/* Ghi chú */}
        <div className="form-floating mb-4">
          <textarea
            className="form-control rounded-3"
            placeholder="Nhập ghi chú"
            style={{ height: "100px" }}
            {...register("note")}
            defaultValue={dataEdit.note}
          ></textarea>
          <label className="fs-15 text-muted">Phần thưởng</label>
        </div>
        {/* Button */}
        <div className="px-4 py-3 border-top border-block-start-dashed d-flex justify-content-center">
          <button
            className="btn btn-purple-gradient btn-wave waves-effect waves-light"
            type="submit"
          >
            Lưu lại
          </button>
        </div>
      </div>
    </form>
  )
}