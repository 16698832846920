import { LoginPage1 } from "../Pages/Login"
// Quản lý data sale.
import DataSalePage from "../Pages/DataSale"
// Hệ thống trang quản lý BDS view 360
import HomePage1 from "../Pages/Home/Home-1"
import DashboardPage from "../Pages/Dashboard/BaoCao"
import SanPhamPage from "../Pages/Dashboard/SanPham"
import LichhenPage from "../Pages/Dashboard/LichHen"
import KhachhangPage from "../Pages/Dashboard/KhachHang"
import TaikhoanPage from "../Pages/Dashboard/TaiKhoan"
import ThanhtoanPage from "../Pages/Dashboard/ThanhToan"
import HotroPage from "../Pages/Dashboard/HoTro"
import ContentAIPage from "../Pages/Dashboard/ContentAI"
// Hệ thống quản lý trang View 360.
import CustomersView360Page from "../Pages/View360"
import ContractsView360Page from "../Pages/View360/ContractsPage"
// Quản lý router công việc (job)
import JobHomePage from "../Pages/Job"
import PageStreetRealEstate from "../Pages/ListStreet"


// Đường dẫn công khai.
const routerPublic = [{ path: "/login", component: LoginPage1 }]

// Quản lý đường dẫn data sale.
const routerDataSale = [
  { path: "/data-sale", component: DataSalePage },
  { path: "/nhiem-vu", component: DataSalePage },
  { path: "/khach-kinh-doanh", component: DataSalePage },
  { path: "/khach-net", component: DataSalePage },
  { path: "/khach-hen", component: DataSalePage },
  { path: "/khach-het-han", component: DataSalePage },
]

// Đường dẫn dành cho user (quản lý đường dẫn bdsview360)
const routerUser = [
  { path: "/", component: HomePage1 },
  { path: "/bdsview360/dashboard", component: DashboardPage },
  { path: "/bdsview360/san-pham", component: SanPhamPage },
  { path: "/bdsview360/lich-hen", component: LichhenPage },
  { path: "/bdsview360/khach-hang", component: KhachhangPage },
  { path: "/bdsview360/tai-khoan", component: TaikhoanPage },
  { path: "/bdsview360/thanh-toan", component: ThanhtoanPage },
  { path: "/bdsview360/ho-tro", component: HotroPage },
  { path: "/bdsview360/content-ai", component: ContentAIPage },
]

// Đường dẫn danh cho quản trị viên. (quản lý đường dẫn view 360)
const routerUserAdmin = [
  { path: "/view360", component: ContractsView360Page },
  { path: "/view360/khach-hang", component: CustomersView360Page },
]

// Quản lý đường dẫn công việc job.
const routerJob = [
  { path: "/cham-cong", component: JobHomePage },

  { path: "/cong-viec", component: JobHomePage },
  { path: "/cong-viec/du-an-kinh-doanh", component: JobHomePage },
  { path: "/cong-viec/quan-ly-thue", component: JobHomePage },

  { path: "/bang-luong", component: JobHomePage },

  { path: "/hop-dong", component: JobHomePage },
  { path: "/hop-dong/quang-cao-bat-dong-san", component: JobHomePage },
  { path: "/hop-dong/view-360", component: JobHomePage },

  { path: "/nhan-su", component: JobHomePage },

  { path: "/bao-cao", component: JobHomePage },
  { path: "/tai-chinh-ca-nhan", component: JobHomePage },
  { path: "/ke-toan", component: JobHomePage },
  { path: "/setting", component: JobHomePage },
]

// Quản lý các đường dẫn khác.
const routerPathOther = [
  { path: "/street-real-estate", component: PageStreetRealEstate },
  // {path: "/khao-gia", component: ""}
]
export {routerPublic, routerDataSale, routerUser, routerUserAdmin, routerJob, routerPathOther}
