import { useEffect, useRef, useState } from "react"
import classNames from "classnames/bind"
import styles from "../../Job.module.scss"
import PieChart from "../../../../Components/VongTySuat/PieChart"
import { BsDot } from "react-icons/bs"
import Tippy from "@tippyjs/react"
import LoadingSpinner from "../../../../Components/Loading/LoadingSpinner"
import moment from "moment"
import ModalReact from "../../../../Components/Modal/ModalReact"
import { FormEditChuyentien, FormEditThunhap } from "./FormThunhap"
import { FormEditChiphi } from "./FormChiphi"
const cx = classNames.bind(styles)

function ListDoanhthuChiphi({
  socketio,
  dataUser,
  objectSearch,
  dataCateChiphi,
  dataUpdateRevenueCreate,
  dataUpdateCreateSuccessChiphi,
  resultChange,
  changeStatus,
}) {
  // quản lý danh sách
  const [activeCate, setActiveCate] = useState("expense")
  const handleActiveCateList = (cate) => {
    setActiveCate(cate)
  }

  return (
    <div>
      <div className="wrapper_ground fs-17" style={{ height: "30px" }}>
        <button
          className={cx(
            "border-0 bg-transparent",
            activeCate === "revenue" ? "color-orange" : "color-gray",
          )}
          onClick={() => handleActiveCateList("revenue")}
        >
          Doanh thu
        </button>
        <button
          className={cx(
            "border-0 bg-transparent",
            activeCate === "expense" ? "color-orange" : "color-gray",
          )}
          onClick={() => handleActiveCateList("expense")}
        >
          Chi phí
        </button>
        <button
          className={cx(
            "border-0 bg-transparent",
            activeCate === "expenseChart" ? "color-orange" : "color-gray",
          )}
          onClick={() => handleActiveCateList("expenseChart")}
        >
          Biểu đồ chi phí
        </button>
      </div>
      <div className="border-top border-block-start-dashed mt-2 mb-2"></div>

      {/* List content */}
      <div
        className="mt-3 px-1"
        style={{ height: "calc(100vh - 17rem)", overflow: "auto" }}
      >
        {activeCate === "revenue" && (
          <ListRevenue
            socketio={socketio}
            dataUser={dataUser}
            objectSearch={objectSearch}
            dataUpdateCreate={dataUpdateRevenueCreate}
            result={resultChange}
            changeStatus={changeStatus}
          />
        )}
        {activeCate === "expense" && (
          <ListExpense
            socketio={socketio}
            dataUser={dataUser}
            objectSearch={objectSearch}
            dataCateChiphi={dataCateChiphi}
            dataUpdateCreate={dataUpdateCreateSuccessChiphi}
            result={resultChange}
            changeStatus={changeStatus}
          />
        )}
        {activeCate === "expenseChart" && (
          <ListExpenseChart
            socketio={socketio}
            dataUser={dataUser}
            objectSearch={objectSearch}
            dataCateChiphi={dataCateChiphi}
            changeStatus={changeStatus}
          />
        )}
      </div>
    </div>
  )
}
export default ListDoanhthuChiphi

// Danh sách doanh thu.
function ListRevenue({
  socketio,
  dataUser,
  objectSearch,
  dataUpdateCreate,
  result,
  changeStatus,
}) {
  const [dataRevenue, setDataRevenue] = useState([])
  const dataRevenueRef = useRef([])
  const totalRevenue = useRef(0)
  const [loading, setLoading] = useState(true)
  // Xử lý get data.
  const handleGetDataRevenue = () => {
    const paramsGet = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: objectSearch,
    }
    socketio.emit("client-get-data-taichinhcanhan-thunhap", paramsGet)
    socketio.on("server-get-data-taichinhcanhan-thunhap", (data) => {
      setDataRevenue([...data])
      dataRevenueRef.current = [...data]
    })
  }
  useEffect(() => {
    // Get data ban đầu.
    handleGetDataRevenue()
    return () => {
      socketio.off("client-get-data-taichinhcanhan-thunhap")
      socketio.off("server-get-data-taichinhcanhan-thunhap")
    }
  }, [objectSearch, changeStatus])

  // Set data tổng thu nhập.
  useEffect(() => {
    const paramsTotal = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        user_id: dataUser[0].id_user,
        monthSearch: objectSearch.monthSearch,
      },
    };
    socketio.emit("client-total-thunhap-taichinhcanhan-thunhap", paramsTotal)
    socketio.on("server-total-thunhap-taichinhcanhan-thunhap", (data) => {
      // set tổng thu nhập.
      if (data.length > 0) {
        if (data[0].card_payment && data[0].wallet_payment) {
          let totalRevenue_ = handleTotalCardWallet(data)
          totalRevenue.current = totalRevenue_
        }
      }
      setLoading(false)
    })
    return () => {
      socketio.off("client-total-thunhap-taichinhcanhan-thunhap")
      socketio.off("server-total-thunhap-taichinhcanhan-thunhap")
    }
  }, [objectSearch, dataUpdateCreate, changeStatus])

  // Update data khi thêm mới thành công.
  useEffect(() => {
    if (dataUpdateCreate.length > 0) {
      let dataUpdate = dataRevenueRef.current
      dataUpdate.push(dataUpdateCreate[0])
      let sortDataUpdate = dataUpdate.sort((a, b) => {
        let min_ = new Date(a.addtime).getTime()
        let max_ = new Date(b.addtime).getTime()
        return max_ - min_
      })
      // Update data
      setDataRevenue([...sortDataUpdate])
      dataRevenueRef.current = [...sortDataUpdate]
    }
  }, [dataUpdateCreate])

  // Quản lý edit data.
  const [modalEditData, setModalEditData] = useState(false)
  const dataEdit = useRef([])
  const handleEditData = (object) => {
    setModalEditData(true)
    dataEdit.current = object
  }
  const handleCloseEditData = () => {
    setModalEditData(false)
    dataEdit.current = undefined
  }
  const handleEditDataSuccess = (object) => {
    if (object.status) {
      let dataUpdateEdit = dataRevenueRef.current
      let index = dataUpdateEdit.findIndex(
        (item) => item.id === object.dataUpdate[0].id,
      )
      dataUpdateEdit[index] = object.dataUpdate[0]
      setDataRevenue([...dataUpdateEdit])
      dataRevenueRef.current = [...dataUpdateEdit]
      setModalEditData(false)
      result(object.dataUpadte)
    }
  }

  // Quản lý edit data kiểu chuyển tiền.
  const [modalEditDataChuyentien, setModalEditDataChuyentien] = useState(false)
  const dataEditChuyentien = useRef([])
  const handleEditDataChuyentien = (object) => {
    setModalEditDataChuyentien(true)
    dataEditChuyentien.current = object
  }
  const handleCloseEditDataChuyentien = () => {
    setModalEditDataChuyentien(false)
    dataEditChuyentien.current = undefined
  }
  const handleEditDataChuyentienSuccess = (object) => {
    if (object.status) {
      let dataUpdateEdit = dataRevenueRef.current
      let index = dataUpdateEdit.findIndex(
        (item) => item.id === object.dataUpdate[0].id,
      )
      dataUpdateEdit[index] = object.dataUpdate[0]
      setDataRevenue([...dataUpdateEdit])
      dataRevenueRef.current = [...dataUpdateEdit]
      setModalEditDataChuyentien(false)
      result(object.dataUpadte)
    }
  }

  // Quản lý Delete data;
  const checkChangeDelete = useRef()
  const handleDeleteData = (id) => {
    let checkDelete = window.confirm("Bạn muốn xóa ?")
    if (checkDelete) {
      const paramsDelete = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: {
          id: id,
          delete_thunhap: 1,
          monthSearch: objectSearch.monthSearch,
          user_id: dataUser[0].id_user,
        },
      }
      socketio.emit(
        "client-row-delete-thunhap-data-taichinhcanhan-thunhap",
        paramsDelete,
      )
      socketio.on(
        "server-row-delete-thunhap-get-data-taichinhcanhan-thunhap",
        (data) => {
          if (data === "success") {
            let dataUpdateDelete = dataRevenueRef.current.filter(
              (item) => item.id !== id,
            )
            setDataRevenue([...dataUpdateDelete])
            dataRevenueRef.current = [...dataUpdateDelete]
            checkChangeDelete.current = id
            result(id)
          }
        },
      )
    }
  }
  useEffect(() => {
    return () => {
      socketio.off("client-row-delete-thunhap-data-taichinhcanhan-thunhap")
      socketio.off("server-row-delete-thunhap-data-taichinhcanhan-thunhap")
    }
  }, [checkChangeDelete.current])

  if (loading) {
    return <LoadingSpinner />
  }
  if (dataRevenue.length === 0) {
    return <div className="text-center mt-3">Chưa có dữ liệu.</div>
  }

  return (
    <div>
      {/* <div className="text-end text-than">
        Tổng thu:{" "}
        <span className="fw-5">
          {totalRevenue.toLocaleString().replace(/[.]/g, ",")}
        </span>
      </div> */}
      {/* <div className="border-top border-block-start-dashed mt-1 mb-1"></div> */}
      <ul className="list-group list-group-flush">
        {dataRevenue.map((item, index) => {
          // Set money.
          let cardPayment = Number(item.card_payment)
          let walletPayment = Number(item.wallet_payment)

          let totalCardWallet = 0
          let rateFormat = 0;
          if (item.chuyentien === 0) {
            totalCardWallet = cardPayment + walletPayment
            rateFormat = (totalCardWallet / totalRevenue.current) * 100
          }
          let object = {
            sizeChart: 55,
            colorMain: item.color ? item.color : "#02d355",
            color: "#efefef",
            rate: Number.isInteger(rateFormat)
              ? rateFormat
              : rateFormat.toFixed(1),
          };

          return (
            <li
              className="list-group-item border-top-0 border-start-0 border-end-0 px-0"
              key={index}
            >
              <div>
                <div className="d-flex align-items-center">
                  <Tippy content="Thu nhập chiếm">
                    <div className="me-2 lh-1">
                      <PieChart data={object} />
                    </div>
                  </Tippy>
                  <div className="flex-fill">
                    <p
                      className="mb-0 fw-semibold text-than"
                      style={{ color: item.color }}
                    >
                      {item.title}
                    </p>

                    <p className="text-muted mb-0">
                      {moment(item.addtime).format("DD/MM/YY")}
                      {window.innerWidth >= 700 && (
                        <>
                          <span className="text-muted fw-4">
                            <BsDot />
                            {moment(item.addtime)
                              .locale("vi")
                              .fromNow()
                              .replace("một", "1")}
                          </span>
                        </>
                      )}
                    </p>
                    <p className="mb-0 text-muted fs-16">{item.note}</p>
                  </div>
                  <div className="text-end">
                    <p className="mb-0 fw-5 text-than">
                      {totalCardWallet.toLocaleString().replace(/[.]/g, ",")}
                    </p>
                    <div className="d-flex gap-1 justify-content-end">
                      {item.chuyentien === 0 && (
                        <button
                          className="badge bg-success-transparent border-0 fw-4 fs-16"
                          onClick={() => handleEditData(item)}
                        >
                          Sửa
                        </button>
                      )}
                      {item.chuyentien === 1 && (
                        <button
                          className="badge bg-success-transparent border-0 fw-4 fs-16"
                          onClick={() => handleEditDataChuyentien(item)}
                        >
                          Sửa
                        </button>
                      )}
                      <button
                        className="badge bg-danger-transparent border-0 fw-4 fs-16"
                        onClick={() => handleDeleteData(item.id)}
                      >
                        Xóa
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          )
        })}
      </ul>

      {/* Modal Edit data */}
      {modalEditData && (
        <ModalReact
          modalTitle="Sửa thông tin thu nhập"
          showModal={modalEditData}
          handleClose={handleCloseEditData}
          theme={
            <FormEditThunhap
              socketio={socketio}
              dataUser={dataUser}
              dataEdit={dataEdit.current}
              result={handleEditDataSuccess}
              objectSearch={objectSearch}
            />
          }
        />
      )}
      {/* Modal Edit data chuyển tiền */}
      {modalEditDataChuyentien && (
        <ModalReact
          modalTitle="Sửa thông tin thu nhập"
          showModal={modalEditDataChuyentien}
          handleClose={handleCloseEditDataChuyentien}
          theme={
            <FormEditChuyentien
              socketio={socketio}
              dataUser={dataUser}
              dataEdit={dataEditChuyentien.current}
              result={handleEditDataChuyentienSuccess}
              objectSearch={objectSearch}
            />
          }
        />
      )}
    </div>
  )
}
// Tính tổng tiền.(card + wallet);
function handleTotalCardWallet(data) {
  // Tổng thu nhập thẻ
  let arrCard = data.map((item) => item.card_payment)
  let totalCard = arrCard.reduce((a, b) => Number(a) + Number(b))
  // tổng thu nhập ví
  let arrWallet = data.map((item) => item.wallet_payment)
  let totalWallet = arrWallet.reduce((a, b) => Number(a) + Number(b))

  return totalCard + totalWallet
}


// Danh sách chi phí.
function ListExpense({
  socketio,
  dataUser,
  objectSearch,
  dataCateChiphi,
  dataUpdateCreate,
  result,
  changeStatus,
}) {
  const [dataExpense, setDataExpense] = useState([])
  const dataExpenseRef = useRef([])
  const [totalExpense, setTotalExpense] = useState(0)
  const [loading, setLoading] = useState(true)
  // Xử lý get data.
  const handleGetDataExpense = () => {
    const paramsGet = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: objectSearch,
    }
    socketio.emit("client-get-data-taichinhcanhan-chiphi", paramsGet)
    socketio.on("server-get-data-taichinhcanhan-chiphi", (data) => {
      setDataExpense([...data])
      dataExpenseRef.current = [...data]
      setLoading(false)
    })
  }
  useEffect(() => {
    // Get data ban đầu.
    handleGetDataExpense()
    return () => {
      socketio.off("client-get-data-taichinhcanhan-chiphi")
      socketio.off("server-get-data-taichinhcanhan-chiphi")
    }
  }, [objectSearch, changeStatus])

  // Set data tổng chi phí.
  // useEffect(() => {
  //   const paramsTotal = {
  //     iden: process.env.REACT_APP_IDEN_DASH,
  //     object: {
  //       user_id: dataUser[0].id_user,
  //       monthSearch: objectSearch.monthSearch,
  //     },
  //   }
  //   socketio.emit("client-total-chiphi-taichinhcanhan-chiphi", paramsTotal)
  //   socketio.on("server-total-chiphi-taichinhcanhan-chiphi", (data) => {
  //     // set tổng thu nhập.
  //     if (data.length > 0) {
  //       let totalExpense_ = handleTotalCardWallet(data)
  //       setTotalExpense(totalExpense_)
  //     }
  //     setLoading(false)
  //   });

  //   return () => {
  //     socketio.off("client-total-chiphi-taichinhcanhan-chiphi")
  //     socketio.off("server-total-chiphi-taichinhcanhan-chiphi")
  //   }
  // }, [objectSearch, dataUpdateCreate])

  // Update data khi thêm mới thành công.
  useEffect(() => {
    if (dataUpdateCreate.length > 0) {
      let dataUpdate = dataExpenseRef.current
      dataUpdate.push(dataUpdateCreate[0])
      let sortDataUpdate = dataUpdate.sort((a, b) => {
        let min_ = new Date(a.addtime).getTime()
        let max_ = new Date(b.addtime).getTime()
        return max_ - min_
      })

      // Update data
      setDataExpense([...sortDataUpdate])
      dataExpenseRef.current = [...sortDataUpdate]
    }
  }, [dataUpdateCreate])

  // Quản lý edit data.
  const [modalEditData, setModalEditData] = useState(false)
  const dataEdit = useRef([])
  const handleEditData = (object) => {
    setModalEditData(true)
    dataEdit.current = object
  }
  const handleCloseEditData = () => {
    setModalEditData(false)
    dataEdit.current = undefined
  }
  const handleEditDataSuccess = (object) => {
    if (object.status) {
      let dataUpdateEdit = dataExpenseRef.current
      let index = dataUpdateEdit.findIndex(
        (item) => item.id === object.dataUpdate[0].id,
      )
      dataUpdateEdit[index] = object.dataUpdate[0]
      setDataExpense([...dataUpdateEdit])
      dataExpenseRef.current = [...dataUpdateEdit]
      setModalEditData(false)
      result(object.dataUpdate)
    }
  }

  // Quản lý Delete data;
  const checkChangeDelete = useRef()
  const handleDeleteData = (id) => {
    let checkDelete = window.confirm("Bạn muốn xóa ?")
    if (checkDelete) {
      const paramsDelete = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: {
          id: id,
          delete_chiphi: 1,
          monthSearch: objectSearch.monthSearch,
          user_id: dataUser[0].id_user,
        },
      }
      socketio.emit(
        "client-row-delete-chiphi-data-taichinhcanhan-chiphi",
        paramsDelete,
      )
      socketio.on(
        "server-row-delete-chiphi-get-data-taichinhcanhan-chiphi",
        (data) => {
          if (data === "success") {
            let dataUpdateDelete = dataExpenseRef.current.filter(
              (item) => item.id !== id,
            )
            setDataExpense([...dataUpdateDelete])
            dataExpenseRef.current = [...dataUpdateDelete]
            checkChangeDelete.current = id
            result(id)
          }
        },
      )
    }
  }
  useEffect(() => {
    return () => {
      socketio.off("client-row-delete-chiphi-data-taichinhcanhan-chiphi")
      socketio.off("server-row-delete-chiphi-data-taichinhcanhan-chiphi")
    }
  }, [checkChangeDelete.current])

  if (loading) {
    return <LoadingSpinner />
  }
  if (dataExpense.length === 0) {
    return <div className="text-center mt-3">Chưa có dữ liệu.</div>
  }

  return (
    <div>
      {/* <div className="text-end text-than">
        Tổng chi:{" "}
        <span className="fw-5">
          {totalExpense.toLocaleString().replace(/[.]/g, ",")}
        </span>
      </div> */}
      {/* <div className="border-top border-block-start-dashed mt-1 mb-1"></div> */}
      <ul className="list-group list-group-flush">
        {dataExpense.map((item, index) => {
          // Set money.
          let cardPayment = Number(item.card_payment)
          let walletPayment = Number(item.wallet_payment)
          let totalCardWallet = cardPayment + walletPayment

          // set tổng chi phí theo danh mục trong tháng.
          let itemExpenseCate = dataCateChiphi.find(
            (item_) => item_.cate_chi === item.cate_chi,
          )
          let totalExpenseCate = 0
          let expenseLimit = Number(item.money_limit)
          if (itemExpenseCate) {
            totalExpenseCate = itemExpenseCate.total_money
          }

          let rateFormat = (totalExpenseCate / Number(item.money_limit)) * 100
          let object = {
            sizeChart: 55,
            colorMain: item.color ? item.color : "#4d5b97",
            color: "#efefef",
            rate: Number.isInteger(rateFormat)
              ? rateFormat
              : rateFormat.toFixed(1),
          }

          return (
            <li
              className="list-group-item border-top-0 border-start-0 border-end-0 px-0"
              key={index}
            >
              <div>
                <div className="d-flex align-items-center">
                  <Tippy content="Tổng chi / hạn mức">
                    <div className="me-2 lh-1">
                      <PieChart data={object} />
                    </div>
                  </Tippy>
                  <div className="flex-fill">
                    <p
                      className="mb-0 fw-semibold text-than"
                      style={{ color: item.color }}
                    >
                      {item.title}
                    </p>
                    <p className="text-muted mb-0">
                      {moment(item.addtime).format("DD/MM/YYYY")}
                      {window.innerWidth >= 700 && (
                        <>
                          <BsDot />
                          {moment(item.addtime)
                            .locale("vi")
                            .fromNow()
                            .replace("một", "1")}
                        </>
                      )}
                    </p>
                    <p className="mb-0 text-muted">{item.note}</p>
                  </div>
                  <div className="text-end">
                    <p className="mb-0 fw-5 text-than">
                      {totalCardWallet.toLocaleString().replace(/[.]/g, ",")}
                    </p>
                    <p className="text-muted mb-0">
                      <Tippy content="Tổng đã chi">
                        <span>
                          {totalExpenseCate
                            .toLocaleString()
                            .replace(/[.]/g, ",") + " / "}
                        </span>
                      </Tippy>
                      <Tippy content="Hạn mức chi">
                        <span className="text-danger">
                          {expenseLimit.toLocaleString().replace(/[.]/g, ",")}
                        </span>
                      </Tippy>
                    </p>
                    <div className="d-flex gap-1 justify-content-end">
                      <button
                        className="badge bg-success-transparent border-0 fs-16 fw-4 me-1"
                        onClick={() => handleEditData(item)}
                      >
                        Sửa
                      </button>
                      <button
                        className="badge bg-danger-transparent border-0 fs-16 fw-4"
                        onClick={() => handleDeleteData(item.id)}
                      >
                        Xóa
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          )
        })}
      </ul>
      {/* Modal Edit data */}
      {modalEditData && (
        <ModalReact
          modalTitle="Sửa thông tin chi phí"
          showModal={modalEditData}
          handleClose={handleCloseEditData}
          theme={
            <FormEditChiphi
              socketio={socketio}
              dataUser={dataUser}
              dataEdit={dataEdit.current}
              result={handleEditDataSuccess}
              objectSearch={objectSearch}
            />
          }
        />
      )}
    </div>
  )
}



// List bieu do chi phi.
function ListExpenseChart({
  socketio,
  dataUser,
  objectSearch,
  dataCateChiphi,
  changeStatus,
}) {
  // set tong thu nhap theo thang.
  const [totalThunhap, setTotalThunhap] = useState([])
  const [loading, setLoading] = useState(true)
  const handleTotalThunhap = (object) => {
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        user_id: dataUser[0].id_user,
        monthSearch: object.monthSearch,
      },
    }
    socketio.emit("client-total-thunhap-taichinhcanhan-thunhap", params)
    socketio.on("server-total-thunhap-taichinhcanhan-thunhap", (data) => {
      setTotalThunhap([...data])
      setLoading(false)
    })
  }
  useEffect(() => {
    handleTotalThunhap(objectSearch)
    return () => {
      socketio.off("client-total-thunhap-taichinhcanhan-thunhap")
      socketio.off("server-total-thunhap-taichinhcanhan-thunhap")
    }
  }, [objectSearch, changeStatus])

  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <div>
      <div className="text-center fw-5 fs-18 mb-3 alert alert-primary">
        Tỷ lệ chi phí trên thu nhập
      </div>
      {dataCateChiphi.map((item, index) => {
        // Set tổng thu nhập.
        let sumThunhap =
          totalThunhap[0].card_payment + totalThunhap[0].wallet_payment
        let tysuat = 0
        if (sumThunhap > 0) {
          tysuat = (Number(item.total_money) / sumThunhap) * 100
        }
        return (
          <div key={index}>
            {sumThunhap > 0 && (
              <>
                <Tippy
                  content={"Đã chi " + Number(item.total_money)
                    .toLocaleString()
                    .replace(/[.]/g, ",")}
                >
                  <div
                    className="progress progress-xl progress-animate custom-progress-4 rounded-0"
                    role="progressbar"
                    aria-valuenow="10"
                    aria-valuemin="10"
                    aria-valuemax="100"
                    style={{ borderBottom: "1px solid white" }}
                  >
                    <div
                      className={cx("progress-bar rounded-0")}
                      style={{ width: tysuat + "%", background: item.color }}
                    >
                      {Number.isInteger(tysuat) ? tysuat : tysuat.toFixed(1)}%
                    </div>
                    <div
                      className="progress-bar-label"
                      style={{ color: item.color }}
                    >
                      {tysuat < 10 && (
                        <span className="me-1">
                          {Number.isInteger(tysuat)
                            ? tysuat
                            : tysuat.toFixed(1)}
                          %
                        </span>
                      )}
                      {tysuat >= 80 ? (
                        <span style={{ color: "white" }}>{item.title}</span>
                      ) : (
                        <span>{item.title}</span>
                      )}
                    </div>
                  </div>
                </Tippy>
              </>
            )}
          </div>
        )
      })}
      {/* Trường hợp chưa có doanh thu */}
      {!totalThunhap[0].card_payment && (
        <div className="text-center fs-15">
          Bạn chưa có doanh thu để thống kê.
        </div>
      )}
    </div>
  )
}