
import CheckPCMobile from "../../../handle-reuses/reuses/check.pc.mobile"
import HomePageMobile from "./Mobile/HomePageMobile";
import HomePage1PC from "./PC/HomePage1PC";

function HomePage1() {
    return (
        <CheckPCMobile>
            {isMobile => isMobile ? <HomePageMobile /> : <HomePage1PC />}
      </CheckPCMobile>
    )
}

export default HomePage1



