import React from "react"
import { Pie } from "react-chartjs-2"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js"

ChartJS.register(ArcElement, Tooltip, Legend)

const PieCharts = ({dataPieChart, backgroundPieChart, labelText}) => {
  const data = {
    labels: labelText,
    datasets: [
      {
        label: "Số tiền",
        data: dataPieChart,
        backgroundColor: backgroundPieChart,
        hoverOffset: 6,
      },
    ],
  }

  const options = {
    plugins: {
      legend: {
        display: false, // Ẩn tên nhãn ngoài biểu đồ (legend)
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            //const label = labelText || "" // Nhãn của từng phần
            const dataset = context.dataset
            const currentValue = dataset.data[context.dataIndex]
            const total = dataset.data.reduce((acc, value) => acc + value, 0)
            const percentage = ((currentValue / total) * 100).toFixed(2)
            return `${context.label}: ${percentage}%`
          },
        },
      },
    },
    // animation: {
    //   onComplete: function () {
    //     const chartInstance = this
    //     const ctx = chartInstance.ctx
    //     ctx.font = "14px Arial"
    //     ctx.textAlign = "center"
    //     ctx.textBaseline = "middle"

    //     chartInstance.data.datasets.forEach(function (dataset, i) {
    //       const meta = chartInstance.getDatasetMeta(i)
    //       meta.data.forEach(function (element, index) {
    //         const data = dataset.data[index]
    //         const total = dataset.data.reduce((acc, value) => acc + value, 0)
    //         const percentage = ((data / total) * 100).toFixed(2) + "%"
    //         const position = element.tooltipPosition()

    //         // Chỉnh màu cho tỷ suất phần trăm
    //         ctx.fillStyle = "#fff" // Màu trắng cho tỷ suất
    //         ctx.fillText(percentage, position.x, position.y)
    //       })
    //     })
    //   },
    // },
  }

  return <Pie data={data} options={options} />
}

export default PieCharts
