import { useEffect, useRef, useState } from "react"
import ModalReact from "../../../../Components/Modal/ModalReact";
import FormAddCateThunhap, { FormEditCateThunhap } from "./FormCateThunhap";
import LoadingSpinner from "../../../../Components/Loading/LoadingSpinner";
import moment from "moment";

function ListCateThunhap({ socketio, dataUser, result }) {
    // Set thêm mới danh mục.
    const [modalAddCate, setModalAddCate] = useState(false);
    const [dataUpdate, setDataUpdate] = useState([])
    const handleAddCate = () => {
        setModalAddCate(true)
    }
    const handleCloseAddCate = () => setModalAddCate(false);
    const handleAddCateSuccess = (data) => {
        setModalAddCate(false)
      setDataUpdate([...data])
      result(data)
    }

    return (
      <div>
        <div className="text-center">
          <button
            className="border-0 fs-14 badge bg-dark-transparent"
            onClick={handleAddCate}
          >
            Thêm danh mục
          </button>
        </div>
        {/* Danh sách */}
        <div className="mt-3">
          <ListCateThu
            socketio={socketio}
            dataUser={dataUser}
            dataUpdate={dataUpdate}
            result={result}
          />
        </div>

        {/* Modal Add cate */}
        <ModalReact
          modalTitle="Thêm danh mục"
          showModal={modalAddCate}
          handleClose={handleCloseAddCate}
          theme={
            <FormAddCateThunhap
              socketio={socketio}
              dataUser={dataUser}
              result={handleAddCateSuccess}
            />
          }
        />
      </div>
    )
}
export default ListCateThunhap


// List cate.
function ListCateThu({ socketio, dataUser, dataUpdate, result }) {
    const [dataCate, setDataCate] = useState([])
    const dataCateRef = useRef([]);
    const [loading, setLoading] = useState(true)
    let stt = 1
    // Get data.
    useEffect(() => {
        const paramsGet = {
            iden: process.env.REACT_APP_IDEN_DASH,
            object: {user_id: dataUser[0].id_user}
        }
        socketio.emit("client-get-data-taichinhcanhan-cate-thu", paramsGet)
        socketio.on("server-get-data-taichinhcanhan-cate-thu", (data) => {
            setDataCate([...data])
            dataCateRef.current = [...data];
            setLoading(false)
        })
        return () => {
            socketio.off("client-get-data-taichinhcanhan-cate-thu")
            socketio.off("server-get-data-taichinhcanhan-cate-thu")
        }
    }, [])

    // Update khi thêm mới thành công.
    useEffect(() => {
        if (dataUpdate.length > 0) {
            let dataUpdateNew = dataCateRef.current;
            dataUpdateNew.push(dataUpdate[0])
            let sortDataUpdateNew = dataUpdateNew.sort((a, b) => {
                let min_ = new Date(a.addtime).getTime()
                let max_ = new Date(b.addtime).getTime();
                return max_ - min_
            });
            setDataCate([...sortDataUpdateNew])
            dataCateRef.current = [...sortDataUpdateNew]
        }
    }, [dataUpdate])

    // Quản lý edit.
    const [modalEdit, setModalEdit] = useState(false);
    const dataEdit = useRef([])
    const handleEditCateThu = (object) => {
        setModalEdit(true);
        dataEdit.current = object
    }
    const handleCloseModalEdit = () => {
        setModalEdit(false)
        dataEdit.current = undefined
    }
    const handleEditSuccess = (object) => {
        if (object.status) {
          // Thực hiện cập nhật lại data.
          let dataUpdateEdit_ = dataCateRef.current
          let index = dataUpdateEdit_.findIndex(
            (item) => item.id === object.dataUpdate[0].id,
          )
          dataUpdateEdit_[index] = object.dataUpdate[0]
          setDataCate([...dataUpdateEdit_])
          dataCateRef.current = [...dataUpdateEdit_]
            dataEdit.current = undefined
          setModalEdit(false);
          result(object)
        }
    }


    // Quản lý delete.
    const handleDeleteCateThu = (id) => {
        const checkDelete = window.confirm("Bạn muốn xóa ?");
        if (checkDelete) {
            const paramsDelete = {
                iden: process.env.REACT_APP_IDEN_DASH,
                object: {
                    id: id,
                    user_id: dataUser[0].id_user,
                    delete_thu: 1
                }
            }
            socketio.emit("client-edit-row-delete-taichinhcanhan-cate-thu", paramsDelete)
            socketio.on("server-edit-row-delete-taichinhcanhan-cate-thu", (data) => {
                if (data === "success") {
                    let dataUpdateDelete = dataCateRef.current.filter((item) => item.id !== id);
                    setDataCate([...dataUpdateDelete])
                  dataCateRef.current = [...dataUpdateDelete]
                  result(data)

                }
            })
        }
    }
    useEffect(() => {
        return () => {
            socketio.off("client-edit-row-delete-taichinhcanhan-cate-thu")
            socketio.off("server-edit-row-delete-taichinhcanhan-cate-thu")
        }
    }, [dataCate])

    if (loading) {
        return <LoadingSpinner />
    }
    if (dataCate.length === 0) {
        return <div className="text-center mt-3 fs-15">Bạn chưa có danh mục nào</div>
    }

    return (
      <>
        <ul className="list-group list-group-flush">
          {dataCate.map((item, index) => {
            return (
              <li
                className="list-group-item border-top-0 border-start-0 border-end-0"
                key={index}
              >
                <div>
                  <div className="d-flex align-items-center">
                    <div className="flex-fill">
                      <p
                        className="mb-0 fw-semibold fs-16"
                        style={{ color: item.color }}
                      >
                        {stt++}. {item.title}
                      </p>
                      <p className="fs-15 text-muted mb-0">
                        {moment(item.addtime)
                          .locale("vi")
                          .fromNow()
                          .replace("một", "1")}
                      </p>
                    </div>
                    <div className="text-end fs-16">
                      <button
                        className="border-0 badge bg-success-transparent fs-15 me-2"
                        onClick={() => handleEditCateThu(item)}
                      >
                        Sửa
                      </button>
                      <button
                        className="border-0 badge bg-danger-transparent fs-15"
                        onClick={() => handleDeleteCateThu(item.id)}
                      >
                        Xóa
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            )
          })}
        </ul>
        {/* Modal edit data */}
        {modalEdit && (
          <ModalReact
            modalTitle="Sửa danh mục"
            showModal={modalEdit}
            handleClose={handleCloseModalEdit}
            theme={
              <FormEditCateThunhap
                socketio={socketio}
                dataUser={dataUser}
                dataEdit={dataEdit.current}
                result={handleEditSuccess}
              />
            }
          />
        )}
      </>
    )
}

