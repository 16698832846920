import classNames from "classnames/bind"
import { SlOptionsVertical } from "react-icons/sl"

import styles from "../Job.module.scss";
import SearchHorizontal from "../../../Components/Search/SearchHorizontal";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../../handle-reuses/Contexts/CreateContexts";
import { useNavigate } from "react-router-dom";
import { BsDot } from "react-icons/bs";
import Tippy from "@tippyjs/react/headless";
import ModalReact from "../../../Components/Modal/ModalReact";
import FormAddRole, { FormAddBophan, FormAddChucvu, FormEditDataPersonnel } from "../Component/personnel/FormPersonnel";
const cx = classNames.bind(styles);

function ListPersonnelMobile() {
  const { dataUser, socketio } = useContext(AppContext)
  const navigate = useNavigate()
  useEffect(() => {
    if (dataUser[0].nhan_su !== 1) {
      alert("Bạn không có quyền truy cập.")
      navigate("/")
    }
  }, [])

  // Quản lý thêm mới
  const [modalAdd, setModalAdd] = useState(false)
  const handleAddRoleNew = () => {
    setModalAdd(true)
  }
  const handleCloseModalAdd = () => {
    setModalAdd(false)
  }
  // Quản lý thêm mới quyền thành công
  const handleResultStatus = (status_) => {
    if (status_) {
      setModalAdd(false)
    }
  }

  // Quản lý thêm mới bộ phận.
  const [addBophan, setAddBophan] = useState(false)
  const handleAddBophan = () => {
    setAddBophan(true)
  }
  const handleCloseBophan = () => {
    setAddBophan(false)
  }
  const handleResultStatusSuccessBophan = (status_) => {
    setAddBophan(false)
  }

  // Quản lý thêm mới chức vụ.
  const [addChucvu, setAddChucvu] = useState(false)
  const handleAddChucvu = () => {
    setAddChucvu(true)
  }
  const handleCloseChucvu = () => {
    setAddChucvu(false)
  }
  const handleResultStatusSuccessChucvu = (status_) => {
    setAddChucvu(false)
  }

    return (
        <div>
            <div className={cx("wrapper_ground")}>
                <button
                    className={cx(
                        "wrapper_item",
                        "fs-14 border-0 rounded-2 p-2 fw-5",
                        "button_color_b",
                    )}
                    onClick={handleAddRoleNew}
                >
                    Thêm quyền
                </button>
                <button
                    className={cx(
                        "wrapper_item",
                        "fs-14 border-0 rounded-2 p-2 fw-5",
                        "button_color_a",
                    )}
                    onClick={handleAddBophan}
                >
                    Bộ phận
                </button>
                <button
                    className={cx(
                        "wrapper_item",
                        "fs-14 border-0 rounded-2 p-2 fw-5",
                        "bg-warning",
                    )}
                    onClick={handleAddChucvu}
                >
                    Chức năng
                </button>
            </div>

            {/* content */}
            <div className="mt-3">
                <ListPersonnel socketio={socketio} />
            </div>

            {/* Modal add role */}
            {modalAdd && (
                <ModalReact
                    modalTitle="Thêm mới quyền"
                    showModal={modalAdd}
                    handleClose={handleCloseModalAdd}
                    theme={
                        <FormAddRole socketio={socketio} result={handleResultStatus} />
                    }
                />
            )}
            {/* Modal bộ phận */}
            {addBophan && (
                <ModalReact
                    modalTitle="Thêm mới bộ phận"
                    showModal={addBophan}
                    handleClose={handleCloseBophan}
                    theme={
                        <FormAddBophan
                            socketio={socketio}
                            result={handleResultStatusSuccessBophan}
                        />
                    }
                />
            )}
            {/* Modal add chức vụ */}
            {addChucvu && (
                <ModalReact
                    modalTitle="Thêm mới chức vụ"
                    showModal={addChucvu}
                    handleClose={handleCloseChucvu}
                    theme={<FormAddChucvu socketio={socketio} result={handleResultStatusSuccessChucvu}/>}
                />
            )}
        </div>
    );
}

export default ListPersonnelMobile


// List personnel.
function ListPersonnel({socketio}) {
    // Quản lý hiển thị dữ liệu ban đầu.
    const [dataPersonnel, setDataPersonnel] = useState([])
    const [loading, setLoading] = useState(true)

    function handleGetListPersonnel() {
      const params = {
        iden: process.env.REACT_APP_IDEN_DASH,
      }
      socketio.emit("client-get-data-job-personnel", params)
    }
    useEffect(() => {
      // Get data ban đầu.
      handleGetListPersonnel()
    }, [])
    useEffect(() => {
      socketio.on("server-get-data-job-personnel", (data) => {
          setDataPersonnel([...data]);
        setLoading(false)
      })
      return () => {
        socketio.off("client-get-data-job-personnel")
        socketio.off("server-get-data-job-personnel")
      }
    }, [])

  // Search data
    const handleGetValueSearchText = (keywords) => {
        const paramsSearch = {
          iden: process.env.REACT_APP_IDEN_DASH,
          object: { keywords: keywords.replace(/[.,]/g, "") },
        }
        socketio.emit("client-search-data-personel", paramsSearch)
        setLoading(true)
    }
    useEffect(() => {
        socketio.on("server-search-data-personel", (dataSearch) => {
            setDataPersonnel([...dataSearch])
            setLoading(false)
        })
        return () => {
            socketio.off("client-search-data-personel")
            socketio.off("server-search-data-personel")
        }
    }, []);
    // *Search data

    // Quản lý edit info personnel.
    const [dataEdit, setDataEdit] = useState()
    const [modalEdit, setModalEdit] = useState(false)
    const handleFormEditInfoPersonnel = (idPersonnel, index) => {
        const filterDataEdit = dataPersonnel.find((item) => {
            return item.id === idPersonnel
        });
        filterDataEdit.index = index;
        setDataEdit(filterDataEdit)
        setModalEdit(true)
    }
    const handleCloseModalEdit = () => {
        setModalEdit(false)
        setDataEdit()
    }
    const handleEditDataSuccess = (object) => {
        if (object.status) {
            const objectSuccessEdit = {
              iden: process.env.REACT_APP_IDEN_DASH,
              object: { user_id: object.user_id },
            }
            socketio.emit("client-update-data-job-personnel", objectSuccessEdit)
            socketio.on("server-update-data-job-personnel", (dataUpdate) => {
              dataPersonnel[object.index] = dataUpdate.find((item) => item)
              setModalEdit(false)
            })
        }
    }
    useEffect(() => {
        return () => {
            socketio.off("client-update-data-job-personnel")
            socketio.off("client-update-data-job-personnel")
        }
    }, [dataEdit])
    
    return (
      <>
        {/* search */}
        <div className={cx("col-12 mt-3 mb-3")}>
          <SearchHorizontal
            result={handleGetValueSearchText}
            className="rounded-2"
            style={{ width: "100%" }}
          />
        </div>
        {/* List personnel */}
        {loading && <div className="text-center mt-3 fs-14">Đang tải...</div>}
        {!loading &&
          dataPersonnel.map((item, index) => {
            return (
              <div className="col-xl-12" key={index}>
                <div className="card custom-card">
                  <div className="card-body p-2">
                    <div className="d-flex align-items-center w-100">
                      <div className="me-2">
                        <span className="avatar avatar-rounded">
                          <img
                            src={"https://system.view360.vn/" + item.avatar}
                            alt="img"
                          />
                        </span>
                      </div>
                      <div className="">
                        <div className="fw-semibold">{item.hoten}</div>
                        <p className="mb-0 text-muted op-7">
                          {item.sodienthoai}
                          <BsDot />
                          {item.bo_phan}
                        </p>
                      </div>
                      <div className="ms-auto">
                        <Tippy
                          interactive
                          delay={[0, 0]}
                          trigger="click"
                          placement="bottom-start"
                          render={() => {
                            return (
                              <ul className="bg-tippy-a">
                                <li
                                  className="dropdown-item d-flex cursor-pointer p-0"
                                  onClick={() =>
                                    handleFormEditInfoPersonnel(item.id, index)
                                  }
                                >
                                  Sửa thông tin
                                </li>
                              </ul>
                            )
                          }}
                        >
                          <button className="text-default border-0 bg-transparent">
                            <SlOptionsVertical />
                          </button>
                        </Tippy>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        {/* Modal edit data */}
        {(modalEdit) && (
          <ModalReact
            modalTitle="Sửa thông tin nhân viên"
            showModal={modalEdit}
            handleClose={handleCloseModalEdit}
            theme={
              <FormEditDataPersonnel
                socketio={socketio}
                dataEdit={dataEdit}
                result={handleEditDataSuccess}
              />
            }
          />
        )}
      </>
    )
}