import HeaderDashboardMobile from "../../Components/Header/HeaderDashboardMobile";
import StreetPage from "./StreetPage";

function PageStreetRealEstate() {
    return (
      <>
        <StreetPage />
      </>
    )
}
export default PageStreetRealEstate