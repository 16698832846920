/** ==== Create data */
async function handleMethodCreateData(valueInput, callback, api) {
  let methodPost = {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "identification": process.env.REACT_APP_IDEN_BDSVIEW,
    },
    body: JSON.stringify(valueInput),
  }

  fetch(api, methodPost)
    .then(function (response) {
      return response.json()
    })
    .then(callback)
}
/** ==== *Create data */

/** === Edit data */
async function handleMethodEditData(valueInput, callback, api) {
  let methodPut = {
    method: "PUT",
    headers: {
      "content-type": "application/json",
      identification: process.env.REACT_APP_IDEN_BDSVIEW,
    },
    body: JSON.stringify(valueInput),
  }

  fetch(api, methodPut)
    .then(function (response) {
      return response.json()
    })
    .then(callback)
}
/** === *Edit data */

/** === Delete data === */
async function handleMethodDeleteData(id_, callback, api) {
  let methodDelete = {
    method: "DELETE",
    headers: {
      "content-type": "application/json",
    },
  }

  fetch(api + "/" + id_, methodDelete)
    .then(function (response) {
      return response.json()
    })
    .then(callback)
}
/** === *Delete data === */

/** === UploadFile === */
async function handleMethodUploadFile(formData, callback, api) {
  let methodPost = {
    method: "POST",
    body: formData,
  }
  fetch(api, methodPost)
    .then(function (response) {
      return response.text()
    })
    .then(callback)
    .catch(function (err) {
      console.log(err)
    })
}
/** === *UploadFile === */

export {handleMethodCreateData, handleMethodEditData, handleMethodDeleteData, handleMethodUploadFile}