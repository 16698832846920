import moment from "moment";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { handleFormatPrice } from "../../../handle-reuses/reuses";
import LoadingSpinner from "../../../Components/Loading/LoadingSpinner";
import ListSuggestTuyenduong from "./ListSuggestTuyenduong";
import ListSuggestPhuongxa from "./ListSuggestPhuongxa";
import ListSuggestQuanhuyen from "./ListSuggestQuanhuyen";

function FormAddContract({idSanpham, socketio, dataUser, resultStatus}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm()
  const [dataSale, setDataSale] = useState([])
  const dataSaleRef = useRef([])
  const [loading, setLoading] = useState(true)
  const [formatPrice, setFormatPrice] = useState("")
  // goidichvu.
  const [goidichvu, setGoidichvu] = useState([])
  // Giadichvu.
  const [suggestGiadichvu, setSuggestGiadichvu] = useState("")
  // list user.
  const [listUser, setListUser] = useState([])
  // suggest tuyen duong.
  const [suggestTuyenduong, setSuggestTuyenduong] = useState("")
  // suggest phuongxa.
  const [suggestPhuongxa, setSuggestPhuongxa] = useState("")
  // suggest quanhuyen.
  const [suggestQuanhuyen, setSuggestQuanhuyen] = useState("")

  // Kiểm tra nếu tồn tại idSanham.
  useLayoutEffect(() => {
    if (idSanpham) {
      // Lấy dữ liệu từ data sale sang.
      const params = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: {
          id_sp: idSanpham.idSP,
        },
      }
      socketio.emit("client-filter-data-sale-idsp-sanpham", params)
      socketio.on("server-filter-data-sale-idsp-sanpham", (data) => {
        // format price.
        if (data.length > 0) {
          setFormatPrice(
            data[0].giasanpham.toLocaleString().replace(/[.]/g, ","),
          )
          setSuggestTuyenduong(data[0].tuyenduong)
          setSuggestPhuongxa(data[0].phuongxa)
          setSuggestQuanhuyen(data[0].quanhuyen)
        }
        setDataSale([...data])
        dataSaleRef.current = [...data]
        
        setLoading(false)
      })
      return () => {
        socketio.off("client-filter-data-sale-idsp-sanpham")
        socketio.off("server-filter-data-sale-idsp-sanpham")
      }
    } else {
      // Chỉ lấy dữ liệu mới
    }
  }, [idSanpham])

  // Xử lý format price.
  const handleFormatPriceContract = (e) => {
    let price = e.target.value.replace(/[., ]/g, "")
    let formatPrice_ = Number(price).toLocaleString().replace(/[.]/g, ",")
    setFormatPrice(formatPrice_)
  }

  // Suggest goidichvu.
  useEffect(() => {
    const paramsGoidichvu = {
      iden: process.env.REACT_APP_IDEN_DASH,
    }
    socketio.emit("client-get-data-goidichvu", paramsGoidichvu)
    socketio.on("server-get-data-goidichvu", (data) => {
      setGoidichvu([...data])
    })
    return () => {
      socketio.off("client-get-data-goidichvu")
      socketio.off("server-get-data-goidichvu")
    }
  }, [])
  const handleOnChangeSuggestGoidichvu = (e) => {
    let filterGDV = goidichvu.filter((item) => {
      return item.goidichvu.includes(e.target.value)
    })
    let formatGDV = Number(filterGDV[0].giadichvu)
      .toLocaleString()
      .replace(/[.]/g, ",")
    setSuggestGiadichvu(formatGDV)
  }

  // suggest giadichvu.
  const handleGiadichvu = (e) => {
    let formatPrice = e.target.value.replace(/[., ]/g, "")
    let setPrice = Number(formatPrice).toLocaleString().replace(/[.]/g, ",")
    setSuggestGiadichvu(setPrice)
  }

  // Suggest list user.
  useEffect(() => {
    const paramsUser = {
      iden: process.env.REACT_APP_IDEN_DASH,
    }
    socketio.emit("client-get-data-user-kiemduyet", paramsUser)
    socketio.on("server-get-data-user-kiemduyet", (data) => {
      setListUser([...data])
      setLoading(false)
    })
    return () => {
      socketio.off("client-get-data-user-kiemduyet")
      socketio.off("server-get-data-user-kiemduyet")
    }
  }, [])

  /** Xử lý gợi ý tuyenduong. */
  const [dataTuyenduong, setDataTuyenduong] = useState([])
  const handleGetValueTuyenduong = (e) => {
    setSuggestTuyenduong(e.target.value)
  }
  useEffect(() => {
    if (suggestTuyenduong !== "") {
      // xử lý gợi ý.
      const paramsTuyenduong = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: {
          tuyenduong: suggestTuyenduong,
        },
      }
      socketio.emit("client-get-data-tuyenduong", paramsTuyenduong)
      socketio.on("server-get-data-tuyenduong", (dataSuggest) => {
        setDataTuyenduong([...dataSuggest])
      })
      return () => {
        socketio.off("client-get-data-tuyenduong")
        socketio.off("server-get-data-tuyenduong")
      }
    }
  }, [suggestTuyenduong])
  // Set value tuyenduong.
  const handleSetValueTuyenduong = (key) => {
    setSuggestTuyenduong(key)
  }
  /** *Xử lý gợi ý tuyenduong. */

  /** Quản lý gợi ý phuongxa */
  const handleGetValuePhuongxa = (e) => {
    setSuggestPhuongxa(e.target.value)
  }
  const handleSetValuePhuongxa = (key) => {
    setSuggestPhuongxa(key)
  }

  /** Quản lý gợi ý phuongxa */

  /** Quản lý gợi ý Quanhuyen */
  const handleGetValueQuanhuyen = (e) => {
    setSuggestQuanhuyen(e.target.value)
  }
  const handleSetValueQuanhuyen = (key) => {
    setSuggestQuanhuyen(key)
  }
  /** Quản lý gợi ý Quanhuyen */

  // Xử lý submit
  const checkSubmitSuccess = useRef()
  const handleOnSubmit = (object) => {
    // Set info.
    object.sonha = object.sonha ? object.sonha : 0
    object.duongngo = object.duongngo ? object.duongngo.replace(/[,]/g, ".") : 0
    object.dtmb = object.dtmb.replace(/[,]/g, ".")
    object.mattien = object.mattien ? object.mattien.replace(/[,]/g, ".") : 0
    object.sotang = object.sotang ? object.sotang.replace(/[,]/g, ".") : 0
    object.dtsd = object.dtsd ? object.dtsd.replace(/[,]/g, ".") : 0
    object.sancong = object.sancong ? object.sancong : 0
    object.gara = object.gara ? object.gara : 0
    object.phongkhach = object.phongkhach ? object.phongkhach : 0
    object.phongan = object.phongan ? object.phongan : 0
    object.wc = object.wc ? object.wc : 0
    object.phongngu = object.phongngu ? object.phongngu : 0
    object.phongtho = object.phongtho ? object.phongtho : 0
    object.sanphoi = object.sanphoi ? object.sanphoi : 0

    // Set checkbox.
    object.diennuoc = object.diennuoc ? 1 : 0
    object.dieuhoa = object.dieuhoa ? 1 : 0
    object.binhnonglanh = object.binhnonglanh ? 1 : 0
    object.noithat = object.noithat ? 1 : 0
    object.gantruong = object.gantruong ? 1 : 0
    object.gancho = object.gancho ? 1 : 0
    object.gansieuthi = object.gansieuthi ? 1 : 0
    object.gantrungtam = object.gantrungtam ? 1 : 0
    object.otodocua = object.otodocua ? 1 : 0
    object.giadichvu = suggestGiadichvu.replace(/[., ]/g, "")
    object.giasanpham = object.giasanpham.replace(/[., ]/g, "")
    object.tacgia = dataUser[0].email
    object.hoahong = object.hoahong ? object.hoahong : 0

    // Xử lý thêm mới.
    const paramsCreate = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: object,
    }
    socketio.emit("client-create-data-bds-contract", paramsCreate)
    socketio.on("server-create-data-bds-contract", (data) => {
      if (data === "success") {
        // Xử lý thêm mới hợp đồng (module_job_contract)
        let paramsJobContract = {
          iden: process.env.REACT_APP_IDEN_DASH,
        }
        socketio.emit(
          "client-get-data-new-create-success-bds-contract",
          paramsJobContract,
        )
        socketio.on(
          "server-get-data-new-create-success-bds-contract",
          (dataContract) => {
            alert("Tạo hợp đồng quảng cáo thành công.")
            if (dataContract.length > 0) {
              let paramsCreateContract = {
                iden: process.env.REACT_APP_IDEN_DASH,
                object: {
                  contract_name: "Quảng cáo bất động sản",
                  id_detail: dataContract[0].id_hopdong,
                  price_service: dataContract[0].giadichvu,
                  nhanvien_chot: dataContract[0].nhanvienchot,
                  nhanvien_thitruong: dataContract[0].nhanvienthitruong,
                  tien_hoahong: 0,
                },
              }
              socketio.emit("client-create-data-contract", paramsCreateContract)
              

              // xử lý edit expired, author_expired, status.
              if (idSanpham) {
                const paramsEditExpired = {
                  iden: process.env.REACT_APP_IDEN_DASH,
                  object: {
                    expired: object.date_end,
                    author_expired: dataUser[0].id_user,
                    status: 1,
                    id_sp: idSanpham.idSP,
                  },
                }
                socketio.emit(
                  "client-edit-row-expired-ads-bds",
                  paramsEditExpired,
                )

                checkSubmitSuccess.current = object
                resultStatus(true)
              } else {
                checkSubmitSuccess.current = object
                resultStatus(true)
              }
            }
          },
        )
        // *Xử lý thêm mới hợp đồng (module_job_contract)
      }
    })


    
    
  }

  // Quản lý khi add success.
  useEffect(() => {
    
    return () => {
      socketio.off("client-create-data-bds-contract")
      socketio.off("server-create-data-bds-contract")
      socketio.off("client-edit-row-expired-ads-bds")

      socketio.off("client-get-data-new-create-success-bds-contract")
      socketio.off("server-get-data-new-create-success-bds-contract")
      socketio.off("client-create-data-contract")
    }
  }, [checkSubmitSuccess.current])

  if (loading) {
    return <LoadingSpinner />
  }
  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <div className="col-xl-12 col-md-12">
        <div className="row">
          <div className="form-floating mb-3 col-6">
            <select
              className="form-select"
              {...register("loaisanpham", { required: true })}
              defaultValue={dataSale.length > 0 ? dataSale[0].loaibds : ""}
            >
              <option value="">Chọn loại BDS</option>
              <option value="Bán nhà">Bán nhà</option>
              <option value="Bán đất nền">Bán đất nền</option>
              <option value="Bán đất TDC">Bán đất TDC</option>
              <option value="Bán căn hộ">Bán căn hộ</option>
              <option value="Bán chung cư">Bán chung cư</option>
              <option value="Cho thuê nhà">Cho thuê nhà</option>
              <option value="Cho thuê căn hộ">Cho thuê căn hộ</option>
              <option value="Cho thuê phòng trọ">Cho thuê phòng trọ</option>
              <option value="Cho thuê đất">Cho thuê đất</option>
              <option value="Cho thuê mặt bằng">Cho thuê mặt bằng</option>
              <option value="Sang nhượng">Sang nhượng</option>
            </select>
            <label className="fs-15 ms-2 text-muted">
              Loại BĐS <span className="text-danger">*</span>
            </label>
            {errors.loaisanpham && (
              <span className="text-danger fs-12">
                Bạn cần chọn loại sản phẩm.
              </span>
            )}
          </div>
          <div className="form-floating mb-3 col-6">
            <select
              className="form-select"
              {...register("phaply", { required: true })}
              defaultValue={dataSale.length > 0 ? dataSale[0].phaply : ""}
            >
              <option value="">Chọn pháp lý</option>
              <option value="Sổ hồng">Sổ hồng</option>
              <option value="Sổ đỏ">Sổ đỏ</option>
              <option value="Trích đo">Trích đo</option>
              <option value="Chủ đầu tư">Chủ đầu tư</option>
              <option value="Viết tay">Viết tay</option>
              <option value="Hợp lệ">Hợp lệ</option>
              <option value="Loại khác">Loại khác</option>
            </select>
            <label className="fs-15 ms-2 text-muted">
              Giấy tờ <span className="text-danger">*</span>
            </label>
            {errors.phaply && (
              <span className="text-danger fs-12">Bạn cần chọn pháp lý.</span>
            )}
          </div>
        </div>
      </div>

      <div className="col-xl-12 col-md-12">
        <div className="row">
          <div className="form-floating mb-3 col-6">
            <input
              type="text"
              className="form-control"
              placeholder="Tên input"
              {...register("sonha")}
            />
            <label className="fs-15 ms-2 text-muted">Số nhà</label>
          </div>
          <div className="form-floating mb-3 col-6">
            <input
              type="text"
              className="form-control"
              placeholder="Tên input"
              {...register("tuyenduong")}
              value={suggestTuyenduong}
              onChange={handleGetValueTuyenduong}
            />
            <label className="fs-15 ms-2 text-muted">
              Tuyến đường <span className="text-danger">*</span>
            </label>
            {errors.tuyenduong && (
              <span className="text-danger fs-12">
                Bạn cần chọn tuyến đường.
              </span>
            )}
            {/* Gợi ý */}
            {dataTuyenduong.length > 0 && (
              <ListSuggestTuyenduong
                dataList={dataTuyenduong}
                valueInput={suggestTuyenduong}
                result={handleSetValueTuyenduong}
              />
            )}
          </div>
        </div>
      </div>

      <div className="col-xl-12 col-md-12">
        <div className="row">
          <div className="form-floating mb-3 col-6">
            <input
              type="text"
              className="form-control"
              placeholder="Tên input"
              {...register("phuongxa")}
              value={suggestPhuongxa}
              onChange={handleGetValuePhuongxa}
            />
            <label className="fs-15 ms-2 text-muted">Phường/Xã</label>
            {/* Gợi ý */}
            {dataTuyenduong.length > 0 && (
              <ListSuggestPhuongxa
                dataList={dataTuyenduong}
                valueInput={suggestPhuongxa}
                result={handleSetValuePhuongxa}
              />
            )}
          </div>
          <div className="form-floating mb-3 col-6">
            <input
              type="text"
              className="form-control"
              placeholder="Tên input"
              {...register("quanhuyen", { required: true })}
              value={suggestQuanhuyen}
              onChange={handleGetValueQuanhuyen}
            />
            <label className="fs-15 ms-2 text-muted">
              Quận/Huyện <span className="text-danger">*</span>
            </label>
            {errors.quanhuyen && (
              <span className="text-danger fs-12">
                Bạn cần chọn quận huyện.
              </span>
            )}
            {/* Gợi ý */}
            {dataTuyenduong.length > 0 && (
              <ListSuggestQuanhuyen
                dataList={dataTuyenduong}
                valueInput={suggestQuanhuyen}
                result={handleSetValueQuanhuyen}
              />
            )}
          </div>
        </div>
      </div>

      <div className="col-xl-12 col-md-12">
        <div className="form-floating mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Tên input"
            {...register("thanhpho", { required: true })}
            defaultValue={
              dataSale.length > 0 ? dataSale[0].thanhpho : "Hải phòng"
            }
          />
          <label className="fs-15 text-muted">
            Thành phố <span className="text-danger">*</span>
          </label>
          {errors.thanhpho && (
            <span className="text-danger fs-12">Bạn cần chọn thành phố.</span>
          )}
        </div>
      </div>

      <div className="col-xl-12 col-md-12">
        <div className="row">
          <div className="form-floating mb-3 col-6">
            <input
              type="number"
              step="0.1"
              className="form-control"
              placeholder="Tên input"
              {...register("duongngo")}
              defaultValue={dataSale.length > 0 ? dataSale[0].trucngo : ""}
            />
            <label className="fs-15 ms-2 text-muted">Đường/Ngõ</label>
          </div>
          <div className="form-floating mb-3 col-6">
            <input
              type="number"
              step="0.1"
              className="form-control"
              placeholder="Tên input"
              {...register("dtmb", { required: true })}
              defaultValue={dataSale.length > 0 ? dataSale[0].dtmb_mt : ""}
            />
            <label className="fs-15 ms-2 text-muted">
              Diện tích MB <span className="text-danger">*</span>
            </label>
            {errors.dtmb && (
              <span className="text-danger fs-12">
                Bạn cần chọn diện tích mặt bằng.
              </span>
            )}
          </div>
        </div>
      </div>

      <div className="col-xl-12 col-md-12">
        <div className="row">
          <div className="form-floating mb-3 col-6">
            <input
              type="number"
              step="0.1"
              className="form-control"
              placeholder="Tên input"
              {...register("mattien")}
              defaultValue={dataSale.length > 0 ? dataSale[0].mattien : ""}
            />
            <label className="fs-15 ms-2 text-muted">Mặt tiền</label>
          </div>
          <div className="form-floating mb-3 col-6">
            <input
              type="number"
              step="0.1"
              className="form-control"
              placeholder="Tên input"
              {...register("sotang")}
              defaultValue={dataSale.length > 0 ? dataSale[0].sotang : ""}
            />
            <label className="fs-15 ms-2 text-muted">Số tầng</label>
          </div>
        </div>
      </div>

      <div className="col-xl-12 col-md-12">
        <div className="row">
          <div className="form-floating mb-3 col-6">
            <input
              type="number"
              step="0.1"
              className="form-control"
              placeholder="Tên input"
              {...register("dtsd")}
              defaultValue={dataSale.length > 0 ? dataSale[0].dt_xd : ""}
            />
            <label className="fs-15 ms-2 text-muted">diện tích sử dụng</label>
          </div>
          <div className="form-floating mb-3 col-6">
            <select
              className="form-select"
              {...register("huongnha", { required: true })}
              defaultValue={dataSale.length > 0 ? dataSale[0].huongnha : ""}
            >
              <option value="">Chọn hướng</option>
              <option value="Đông">Đông</option>
              <option value="Tây">Tây</option>
              <option value="Nam">Nam</option>
              <option value="Bắc">Bắc</option>
              <option value="Đông nam">Đông nam</option>
              <option value="Tây bắc">Tây bắc</option>
              <option value="Đông bắc">Đông bắc</option>
              <option value="Tây nam">Tây nam</option>
              <option value="Liên hệ">Liên hệ</option>
            </select>
            <label className="fs-15 ms-2 text-muted">
              Hướng nhà <span className="text-danger">*</span>
            </label>
            {errors.huongnha && (
              <span className="text-danger fs-12">
                Bạn cần chọn quận huyện.
              </span>
            )}
          </div>
        </div>
      </div>

      <div className="col-xl-12 col-md-12">
        <div className="row">
          <div className="form-floating mb-3 col-6">
            <input
              type="number"
              className="form-control"
              placeholder="Tên input"
              {...register("sancong")}
            />
            <label className="fs-15 ms-2 text-muted">Sân cổng</label>
          </div>
          <div className="form-floating mb-3 col-6">
            <input
              type="number"
              className="form-control"
              placeholder="Tên input"
              {...register("gara")}
            />
            <label className="fs-15 ms-2 text-muted">Gara</label>
          </div>
        </div>
      </div>

      <div className="col-xl-12 col-md-12">
        <div className="row">
          <div className="form-floating mb-3 col-6">
            <input
              type="number"
              className="form-control"
              placeholder="Tên input"
              {...register("phongkhach")}
            />
            <label className="fs-15 ms-2 text-muted">Phòng khách</label>
          </div>
          <div className="form-floating mb-3 col-6">
            <input
              type="number"
              className="form-control"
              placeholder="Tên input"
              {...register("phongan")}
            />
            <label className="fs-15 ms-2 text-muted">Phòng ăn</label>
          </div>
        </div>
      </div>

      <div className="col-xl-12 col-md-12">
        <div className="row">
          <div className="form-floating mb-3 col-6">
            <input
              type="number"
              className="form-control"
              placeholder="Tên input"
              {...register("wc")}
            />
            <label className="fs-15 ms-2 text-muted">WC</label>
          </div>
          <div className="form-floating mb-3 col-6">
            <input
              type="number"
              className="form-control"
              placeholder="Tên input"
              {...register("phongngu")}
            />
            <label className="fs-15 ms-2 text-muted">Phòng ngủ</label>
          </div>
        </div>
      </div>

      <div className="col-xl-12 col-md-12">
        <div className="row">
          <div className="form-floating mb-3 col-6">
            <input
              type="number"
              className="form-control"
              placeholder="Tên input"
              {...register("phongtho")}
            />
            <label className="fs-15 ms-2 text-muted">Phòng thờ</label>
          </div>
          <div className="form-floating mb-3 col-6">
            <input
              type="number"
              className="form-control"
              placeholder="Tên input"
              {...register("sanphoi")}
            />
            <label className="fs-15 ms-2 text-muted">Sân phơi</label>
          </div>
        </div>
      </div>

      {/* Tiện nghi */}
      <div className="fs-15 mb-1 mt-3">Tiện nghi</div>
      <div className="col-xl-12 col-md-12">
        <div className="row">
          <div className="mb-3 col-3">
            <label className="fs-15 d-flex align-items-center text-muted">
              <input type="checkbox" {...register("diennuoc")} />
              <span className="ms-1">Điện, nước</span>
            </label>
          </div>
          <div className="mb-3 col-3">
            <label className="fs-15 d-flex align-items-center text-muted">
              <input type="checkbox" {...register("dieuhoa")} />
              <span className="ms-1">Điều hòa</span>
            </label>
          </div>
          <div className="mb-3 col-3">
            <label className="fs-15 d-flex align-items-center text-muted">
              <input type="checkbox" {...register("binhnonglanh")} />
              <span className="ms-1">Nóng lạnh</span>
            </label>
          </div>
          <div className="mb-3 col-3">
            <label className="fs-15 d-flex align-items-center text-muted">
              <input type="checkbox" {...register("noithat")} />
              <span className="ms-1">Nội thất</span>
            </label>
          </div>
        </div>
      </div>

      {/* Vị trí */}
      <div className="fs-15 mb-1">Vị trí</div>
      <div className="col-xl-12 col-md-12 mb-3">
        <div className="row">
          <div className="mb-3 col-4">
            <label className="fs-15 d-flex align-items-center text-muted">
              <input type="checkbox" {...register("gantruong")} />
              <span className="ms-1">Gần trường học</span>
            </label>
          </div>
          <div className="mb-3 col-4">
            <label className="fs-15 d-flex align-items-center text-muted">
              <input type="checkbox" {...register("gancho")} />
              <span className="ms-1">Gần chợ</span>
            </label>
          </div>
          <div className="mb-3 col-4">
            <label className="fs-15 d-flex align-items-center text-muted">
              <input type="checkbox" {...register("gansieuthi")} />
              <span className="ms-1">Gần siêu thị</span>
            </label>
          </div>
        </div>
        <div className="row">
          <div className="mb-3 col-4">
            <label className="fs-15 d-flex align-items-center text-muted">
              <input type="checkbox" {...register("gantrungtam")} />
              <span className="ms-1">Gần trung tâm</span>
            </label>
          </div>
          <div className="mb-3 col-4">
            <label className="fs-15 d-flex align-items-center text-muted">
              <input type="checkbox" {...register("otodocua")} />
              <span className="ms-1">Ôtô đỗ cửa</span>
            </label>
          </div>
        </div>
      </div>

      <div className="col-xl-12 col-md-12">
        <div className="row">
          <div className="form-floating mb-3 col-6">
            <input
              type="text"
              className="form-control"
              placeholder="Tên input"
              {...register("giasanpham", { required: true })}
              value={formatPrice}
              onChange={handleFormatPriceContract}
            />
            <label className="fs-15 ms-2 text-muted">
              Giá sản phẩm <span className="text-danger">*</span>
              <span className="ms-1 text-danger">
                {handleFormatPrice(Number(formatPrice.replace(/[,]/g, "")))}
              </span>
            </label>
            {errors.giasanpham && (
              <span className="text-danger fs-12">Bạn cần chưa nhập giá.</span>
            )}
          </div>
          <div className="form-floating mb-3 col-6">
            <input
              type="number"
              className="form-control"
              placeholder="Tên input"
              {...register("hoahong")}
            />
            <label className="fs-15 ms-2 text-muted">Hoa hồng %</label>
          </div>
        </div>
      </div>

      <div className="col-xl-12 col-md-12">
        <div className="row">
          <div className="form-floating mb-3 col-6">
            <input
              type="text"
              className="form-control"
              placeholder="Tên input"
              {...register("chusohuu", { required: true })}
              defaultValue={dataSale.length > 0 ? dataSale[0].hoten : ""}
            />
            <label className="fs-15 ms-2 text-muted">
              Chủ sở hữu <span className="text-danger">*</span>
            </label>
            {errors.chusohuu && (
              <span className="text-danger fs-12">
                Bạn cần nhập tên chủ sở hữu.
              </span>
            )}
          </div>
          <div className="form-floating mb-3 col-6">
            <input
              type="text"
              className="form-control"
              placeholder="Tên input"
              {...register("sodienthoai", { required: true })}
              defaultValue={dataSale.length > 0 ? dataSale[0].sodienthoai : ""}
            />
            <label className="fs-15 ms-2 text-muted">
              Số điện thoại <span className="text-danger">*</span>
            </label>
            {errors.sodienthoai && (
              <span className="text-danger fs-12">
                Bạn cần nhập số điện thoại.
              </span>
            )}
          </div>
        </div>
      </div>

      <div className="col-xl-12 col-md-12">
        <div className="row">
          <div className="form-floating mb-3 col-6">
            <select
              className="form-select"
              {...register("loaitin")}
              onChange={handleOnChangeSuggestGoidichvu}
            >
              <option value="">Chọn gói dịch vụ</option>
              {goidichvu.length > 0 &&
                goidichvu.map((item, index) => {
                  return (
                    <option value={item.goidichvu} key={index}>
                      {item.goidichvu}
                    </option>
                  )
                })}
            </select>
            <label className="fs-15 ms-2 text-muted">Loại tin</label>
            {/* {errors.loaitin && (
                <span className="text-danger fs-12">
                  Bạn cần chọn gói dịch vụ.
                </span>
              )} */}
          </div>
          <div className="form-floating mb-3 col-6">
            <input
              type="text"
              className="form-control"
              placeholder="Tên input"
              {...register("giadichvu")}
              value={suggestGiadichvu}
              onChange={handleGiadichvu}
            />
            <label className="fs-15 ms-2 text-muted">Giá dịch vụ</label>
            {/* {errors.giadichvu && (
                <span className="text-danger fs-12">
                  Bạn cần chọn giá dịch vụ.
                </span>
              )} */}
          </div>
        </div>
      </div>

      <div className="col-xl-12 col-md-12">
        <div className="row">
          <div className="form-floating mb-3 col-6">
            <input
              type="datetime-local"
              className="form-control"
              placeholder="Tên input"
              {...register("date_start", { required: true })}
              defaultValue={moment().format("YYYY-MM-DD HH:mm")}
            />
            <label className="fs-15 ms-2 text-muted">
              Ngày bắt đầu <span className="text-danger">*</span>
            </label>
            {errors.date_start && (
              <span className="text-danger fs-12">
                Bạn cần chọn thời hạn bắt đầu.
              </span>
            )}
          </div>
          <div className="form-floating mb-3 col-6">
            <input
              type="datetime-local"
              className="form-control"
              placeholder="Tên input"
              {...register("date_end")}
            />
            <label className="fs-15 ms-2 text-muted">Ngày kết thúc</label>
            {/* {errors.date_end && (
                <span className="text-danger fs-12">
                  Bạn cần chọn thời hạn kết thúc.
                </span>
              )} */}
          </div>
        </div>
      </div>

      <div className="col-xl-12 col-md-12">
        <div className="row">
          <div className="form-floating mb-3 col-6">
            <select className="form-select" {...register("nhanvienchot")}>
              <option value="">Chọn nhân viên chốt</option>
              {listUser.length > 0 &&
                listUser.map((item, index) => {
                  return (
                    <option value={item.id_user} key={index}>
                      {item.hoten}
                    </option>
                  )
                })}
            </select>
            <label className="fs-15 ms-2 text-muted">
              Nhân viên chốt <span className="text-danger">*</span>
            </label>
            {errors.nhanvienchot && (
              <span className="text-danger fs-12">
                Bạn cần chọn nhân viên chốt.
              </span>
            )}
          </div>
          <div className="form-floating mb-3 col-6">
            <select className="form-select" {...register("nhanvienthitruong")}>
              <option value="">Chọn thị trường</option>
              {listUser.length > 0 &&
                listUser.map((item, index) => {
                  return (
                    <option value={item.id_user} key={index}>
                      {item.hoten}
                    </option>
                  )
                })}
            </select>
            <label className="fs-15 ms-2 text-muted">
              Nhân viên thị trường <span className="text-danger">*</span>
            </label>
            {errors.nhanvienthitruong && (
              <span className="text-danger fs-12">
                Bạn cần chọn nhân viên thị trường.
              </span>
            )}
          </div>
        </div>
      </div>

      {/* Ghi chú */}
      <div className="form-floating mb-4 mt-3">
        <textarea
          className="form-control"
          placeholder="Nhập ghi chú"
          style={{ height: "100px" }}
          {...register("ghichu")}
        ></textarea>
        <label className="fs-15 ms-2 text-muted">Ghi chú</label>
      </div>
      {/* Button */}
      <div className="px-4 py-3 border-top border-block-start-dashed d-flex justify-content-center">
        <button className="btn btn-primary-light m-1" type="submit">
          {idSanpham ? "Tạo quảng cáo" : "Thêm hợp đồng"}
        </button>
      </div>
    </form>
  )
}
export default FormAddContract