import { useEffect, useState, useRef, useContext } from "react";
import moment from "moment/moment";
import classNames from "classnames/bind";
import { BsDot } from "react-icons/bs"
import { IoEyeOutline } from "react-icons/io5"
import { PiCursorClickLight } from "react-icons/pi"
import { FiEdit } from "react-icons/fi"
import { AiOutlineClockCircle } from "react-icons/ai"
import { LiaExchangeAltSolid } from "react-icons/lia"


import {images} from "../../../../assets/images"
import styles from "../Sanpham.module.scss";
import { SliderImageList } from "../../../../Components/Slider";
import { TextLimit, handleFormatPrice, deleteAccentedText, handleFormatNumberUnit } from "../../../../handle-reuses/reuses";
import ModalReact from "../../../../Components/Modal/ModalReact"
import FormEditRealEstateFast from "./FormEditRealEstateFast";
import { handleMethodEditData } from "../../../../handle-reuses/reuses/method";
import AppContext from "../../../../handle-reuses/Contexts/CreateContexts";
const cx = classNames.bind(styles);

function ListProduct({ dataEstates, socketio, result }) {
  const [dataUser, setDataUser] = useState([])
    const checkReduce = useRef([])
    const [loading, setLoading] = useState(true)
    // Quản lý lọc user.
    useEffect(() => {
        let checkData = dataEstates.map((item) => item.userid)
        let filterReduce = checkData.reduce((khoitao, value) => {
            if (khoitao.indexOf(value) === -1) {
                khoitao.push(value)
            }
            return khoitao
        }, [])
        // Gán giá trị cho mảng
        checkReduce.current = filterReduce
        // Tìm kiếm user trên database
        for (let i = 0; i < filterReduce.length; i++) {
            socketio.emit("client-userid", filterReduce[i])
        }
        // Nhận dữ liệu từ database
        let filterUser = []
        socketio.on("server-userid", (data) => {
            data.forEach((element, index) => {
                let filterUser_ = filterUser.find(
                    (item) => item.id === element.id,
                );
              if (!filterUser_) {
                filterUser.push(element)
              }
            })
            if (checkReduce.current.length === filterUser.length) {
                setDataUser([...filterUser])
                setLoading(false)
            }
        })

        return () => {
            socketio.off("client-userid")
        }
    }, [dataEstates])

    // Thay thế dữ liệu.
    const [modalPro, setModalPro] = useState(false)
    const [idPro, setIdPro] = useState(0)
    const handleEditDataProduct = (id_) => {
        setModalPro(true)
        setIdPro(id_)
    }
    const handleCloseModalPro = () => {
        setModalPro(false)
        setIdPro(0)
    }
    // Gia hạn sản phẩm.
    const [modalExtentsion, setmodalExtentsion] = useState(false)
    const [adMoneyOld, setAdMoneyOld] = useState(0)
    const handleExtentsionPro = (id_, moneyOld) => {
        setmodalExtentsion(true)
        setIdPro(id_)
        setAdMoneyOld(moneyOld)
    }
    const handleCloseExtentsion = () => {
        setmodalExtentsion(false)
        setIdPro(0)
        setAdMoneyOld(0)
    }
    // Gia hạn thành công.
    const handleResultEx = (result) => {
        if (!result) {
            setmodalExtentsion(result)
            setIdPro(0)
            setAdMoneyOld(0)
        }
    }
  // Xác nhận giao dịch.
  const checkTran = useRef()
    const handleCheckTransaction = (id_) => {
        let check_ = window.confirm("Bạn muốn chuyển đổi trạng thái giao dịch?")
        if (check_) {
          socketio.emit("client-transaction-estate", id_)
          socketio.on("server-transaction-estate", (data) => {
            const paramsEditTransaction = {
              iden: process.env.REACT_APP_IDEN_BDSVIEW,
              object: {
                id: data[0].id,
                transaction: data[0].transaction === 1 ? 0 : 1,
              },
            }
            socketio.emit(
              "client-edit-transaction-estate",
              paramsEditTransaction,
            )
            alert("Bạn đã chuyển trạng thái thành công")
            checkTran.current = id_
            result(true) // xác nhận chuyển trạng thái thành công
          });
            
            
        }
    }
  useEffect(() => {
    return () => {
      socketio.off("client-transaction-estate")
      socketio.off("server-transaction-estate")
      socketio.off("client-edit-transaction-estate")
    }
  }, [checkTran.current])

  if (dataEstates.length === 0) {
    return <div className="text-center">Chưa có dữ liệu</div>
  }
    if (loading) {
        return <div className="text-center">Đang tải dữ liệu...</div>
    }

  return (
    <div className={cx("row")}>
      {dataEstates.map((item, index) => {
        let listImages = []
        if (item.images) {
          listImages = item.images.split(",")
        };
        //   Avatar user.
        let avatarUser = dataUser.find((itemU) => itemU.id === item.userid);
        // thời hạn còn lại.
        let currentTime = moment()
        let endtime_ = moment(item.endtime);
        let diffTime = endtime_.diff(currentTime, "days");
        if (diffTime > 0) {
          diffTime = <span className="text-success">{"Còn lại " + diffTime + " ngày"}</span>
        } else {
          diffTime = <span className="text-danger">{"Hết hạn"}</span>
        };
        // địa chỉ.
        let address_ = item.address + ", " + item.districts + ", " + item.city
        //   alias.
        let linkHref
        let cate = deleteAccentedText(item.cate)
          .toLowerCase()
          .replace(/[ ]/g, "-")
        let address = deleteAccentedText(item.address)
          .toLowerCase()
          .replace(/[ ]/g, "-")
        linkHref =
          "https://batdongsan.view360.vn/" +
          cate +
          "-" +
          address +
          "/" +
          item.alias +
          "-pr" +
          item.id
        return (
          <div
            className={cx("col-xl-3 col-12 col-lg-6 mb-5 position-relative")}
            key={index}
          >
            <div className={cx("item-es")}>
              {/* Info tác giả */}
              <div className={cx("d-flex")}>
                <div className={cx("d-flex")}>
                  <span
                    className={cx(
                      "avatar avatar-lg me-2 avatar-rounded",
                      avatarUser && avatarUser.online_offline === 1
                        ? "online"
                        : "",
                    )}
                    style={{ border: "1px solid #e7e7e7" }}
                  >
                    <img
                      src={
                        avatarUser
                          ? avatarUser.avatar
                            ? "https://batdongsan.view360.vn/" +
                              avatarUser.avatar
                            : images.noImage
                          : images.noImage
                      }
                      alt=""
                    />
                  </span>
                  <div>
                    <span className={cx("fs-18 fw-5")}>{item.hoten}</span>
                    
                    <p className="text-muted mb-2" title={item.cate}>
                      <TextLimit text={item.cate} number="9" /> <BsDot />{" "}
                      {moment(item.addtime).locale("vi").fromNow()}
                    </p>
                  </div>
                </div>
              </div>
              {/* content product */}
              <div className="col-12" onClick={() => window.open(linkHref)}>
                {/* <h6>
                <TextLimit text={address_} number="43" />
              </h6> */}
                <span className={cx("fs-15")}>
                  <TextLimit text={item.title} number="68" />
                </span>

                <div className="fs-14 mb-1">
                  <div style={{ color: "#556ee5" }}>
                    <span>{diffTime}</span> <BsDot />
                    Giá: {handleFormatPrice(item.price)}
                    <BsDot />
                    {item.transaction === 1 ? (
                      <span className="text-danger">Đã bán</span>
                    ) : (
                      <span>Chưa bán</span>
                    )}
                  </div>
                </div>
              </div>

              {/* Phone */}
              <div className={cx("pro-phone")}>
                {item.customer_name ? (
                  <span className="me-1">{item.customer_name}</span>
                ) : (
                  ""
                )}
                {item.customer_phone ? item.customer_phone : item.phone}
              </div>
              {/* List images */}
              <SliderImageList
                data={listImages}
                web="https://batdongsan.view360.vn/"
                height="283"
              />
              {/* Lượt xem + click phone */}
              <div className="mt-2">
                <span className="text-muted">
                  <IoEyeOutline className="me-1" />
                  {handleFormatNumberUnit(Number(item.countview))}{" "}
                  lượt xem
                  <BsDot className="ms-1 me-1" />
                  <PiCursorClickLight className="me-1" />
                  {Number(item.countclick)
                    .toLocaleString()
                    .replace(/[.]/g, ",")}{" "}
                  click phone
                </span>
              </div>
              {/* Other */}
              <div className="d-flex mt-1">
                <button
                  className="me-3 border-0 rounded-1 bg-transparent p-0 d-flex align-items-center text-muted"
                  onClick={() => handleEditDataProduct(item.id)}
                >
                  <FiEdit className="me-1" />
                  Thay đổi
                </button>
                <button
                  className="me-3 border-0 rounded-1 bg-transparent p-0 d-flex align-items-center text-muted"
                  onClick={() => handleExtentsionPro(item.id, item.admoney)}
                >
                  <AiOutlineClockCircle className="me-1" />
                  Gia hạn
                </button>
                <button
                  className="me-3 border-0 rounded-1 bg-transparent p-0 d-flex align-items-center text-muted"
                  onClick={() => handleCheckTransaction(item.id)}
                >
                  <LiaExchangeAltSolid className="me-1" />
                  Xác nhận
                </button>
              </div>
            </div>
          </div>
        )
      })}
      {/* Modal edit pro */}
      {modalPro && (
        <ModalReact
          modalTitle="Thay thế dữ liệu"
          showModal={modalPro}
          handleClose={handleCloseModalPro}
          theme={<FormEditRealEstateFast idPro={idPro} />}
        />
      )}
      {/* Modal gia hạn */}
      {modalExtentsion && (
        <ModalReact
          modalTitle="Gia hạn sản phẩm"
          showModal={modalExtentsion}
          handleClose={handleCloseExtentsion}
          theme={<ComponentExtent idPro={idPro} moneyOld={adMoneyOld} resultEx={handleResultEx} />}
        />
      )}
    </div>
  );
}

export default ListProduct


// Gia hạn sản phẩm.
function ComponentExtent({idPro, moneyOld, resultEx}) {
    const [monthNumber, setMonthNumber] = useState("")

    const handleMonthNumber = (e) => {
        setMonthNumber(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const object = {
            id: idPro,
            endtime: monthNumber
        }
        handleExtensionProduct(object, moneyOld)
        // Tạo hiệu ứng đã gia hạn.
        setTimeout(() => {
            resultEx(false)
        }, 100);
    }

    return (
        <div className="">
            <form onSubmit={handleSubmit}>
                <label>Nhập số tháng</label>
                <input
                    type="number"
                    value={monthNumber}
                    placeholder="Số tháng"
                    className="form-control mt-2 mb-2"
                    onChange={handleMonthNumber}
                />
                <div className="px-4 d-flex py-3 border-top border-block-start-dashed d-sm-flex justify-content-center">
                    <button
                        className="btn btn-primary-light m-1"
                        type="submit"
                    >
                        Gia hạn
                    </button>
                </div>
            </form>
        </div>
    );
}


/** === Xử lý gia hạn sản phẩm === */
function handleExtensionProduct(data, admoneyOld) {
  // format endtime.
  const formatEndtime = moment()
    .add(data.endtime, "months")
    .format("YYYY-MM-DD HH:mm:ss")
  const payment = Number(data.endtime) * 50000

  // Set đối tượng đầu vào product.
  data.endtime = formatEndtime
  data.admoney = payment + Number(admoneyOld)
  data.status = 1

  // Xử lý gia hạn sản phẩm.
  handleMethodEditData(
    data,
    () => {
      alert("Gia hạn thành công.")
    },
    process.env.REACT_APP_APIEditEndtimeAdmoneyMarketSimple,
  )
}
/** === *Xử lý gia hạn sản phẩm === */