import { useContext, useEffect, useRef, useState } from "react"
import { MdNavigateNext } from "react-icons/md"
import { Link } from "react-router-dom"
import ModalReact from "../../../../Components/Modal/ModalReact"
import ModalAddContractView from "./ModalAddContractView"
import HandleSearchData from "./HandleSearchData"
import moment from "moment"
import { Dropdown } from "react-bootstrap"
import { HiOutlineDotsVertical } from "react-icons/hi"
import AppContext from "../../../../handle-reuses/Contexts/CreateContexts"
import ModalEditContractView from "./ModalEditContract"
import ModalExtendContractView from "./ModalExtendContract"

function ListContractPC({ active, dataCustomer, dataRevenue, dataRevenueYear, optionUser, result, checkChange }) {
  const {socketio} = useContext(AppContext)
  // Quản lý thêm mới hợp đồng.
  const [modalAddContract, setModalAddContract] = useState(false)
  const handleModalAddContractView360 = () => {
    setModalAddContract(true)
  }
  const handleCloseModalAddContract = () => {
    setModalAddContract(false)
  }
    // result Search
    const handleValueSearchData = (object) => {
        result(object)
    };
  
  // Xử lý duyệt hợp đồng.
  const handleContractApproval = (contractId) => {
    let checkApprovalContract = window.confirm("Bạn muốn duyệt hợp đồng này chứ?");
    if (checkApprovalContract) {
      const paramsApproval = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: {
          id_hopdong: contractId,
          duyethopdong: 1
        }
      }
      socketio.emit("client-contract-approval-view360", paramsApproval)
      alert("Duyệt thành công.")
    }
  }
  // Chuyển trạng thái khi duyệt xong.
  useEffect(() => {
    socketio.on("server-contract-approval-view360", (data) => {
      if (data === "success") {
        checkChange(true)
      }
    })
    return () => {
      socketio.off("client-contract-approval-view360")
      socketio.off("server-contract-approval-view360")
    }
  }, [])
    

  // Xử lý edit hợp đồng.
  const [modalEditContract, setModalEditContract] = useState(false)
  const dataEditContract = useRef([])
  const handleEditContract = (idHopdong) => {
    const filterDataEdit = dataCustomer.filter((item) => item.id_hopdong === Number(idHopdong));
    setModalEditContract(true)
    dataEditContract.current = filterDataEdit
  }
  const handleCloseEditContract = () => {
    setModalEditContract(false)
    dataEditContract.current = []
  }

  // Xử lý gia hạn hợp đồng.
  const [modalExtendContract, setModalExtendContract] = useState(false)
  const dataExtendContract = useRef([])
  const handleExtendContract = (object) => {
    setModalExtendContract(true)
    dataExtendContract.current = [object]
  }
  const handleCloseExtendContract = () => {
    setModalExtendContract(false)
    dataExtendContract.current = []
  }
  return (
    <div
      className="main-content app-content"
      style={{ marginInlineStart: active && "6rem" }}
    >
      <div className="container-fluid mt-3">
        {/* <!-- Page Header --> */}
        <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
          <h1 className="page-title fw-5 fs-18 mb-0">Hợp đồng</h1>
          <div className="ms-md-1 ms-0">
            <nav>
              <ol className="breadcrumb mb-0 align-items-center">
                <li className="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <MdNavigateNext />
                <li className="breadcrumb-item-1 active">View360</li>
              </ol>
            </nav>
          </div>
        </div>
        {/* <!-- Page Header Close --> */}

        {/* <!-- Start::row-1 --> */}
        <div className="row">
          <div className="col-xl-9">
            <div className="card custom-card">
              <div className="card-header justify-content-between">
                <div className="card-title fw-5">Danh sách khách hàng</div>
                <div className="d-flex align-items-center">
                  <button
                    className="btn btn-sm btn-wave waves-light rounded-2"
                    onClick={handleModalAddContractView360}
                    style={{
                      background: "#5d87ff",
                      color: "white",
                      height: "40px",
                    }}
                  >
                    Thêm mới
                  </button>
                  {/* <!-- Start::add task modal --> */}
                  <ModalReact
                    modalTitle="Thêm mới hợp đồng"
                    showModal={modalAddContract}
                    handleClose={handleCloseModalAddContract}
                    theme={<ModalAddContractView checkChange={checkChange} />}
                  />
                  {/* <!-- End::add task modal --> */}

                  {/* Search data */}
                  {optionUser.length > 0 && (
                    <HandleSearchData
                      listUser={optionUser}
                      result={handleValueSearchData}
                    />
                  )}
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table text-nowrap table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">Họ tên</th>
                        <th scope="col">Số điện thoại</th>
                        <th scope="col">Thời gian còn</th>
                        <th scope="col">Giá dịch vụ</th>
                        <th scope="col">Tác giả</th>
                        <th scope="col">Hoa hồng</th>
                        <th scope="col">Tình trạng</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataCustomer.map((item, index) => {
                        const endTime = moment(item.ngayketthuc)
                        const currentTime = moment()
                        let diffTime = endTime.diff(currentTime, "days")
                        if (Number(diffTime) >= 1) {
                          diffTime = (
                            <span className="text-success">
                              {diffTime + " ngày nữa"}
                            </span>
                          )
                        } else if (Number(diffTime >= 0 && diffTime < 1)) {
                          diffTime = (
                            <span className="text-warning">
                              {diffTime + " ngày nữa"}
                            </span>
                          )
                        } else {
                          diffTime = (
                            <span className="text-danger">Hết hạn</span>
                          )
                        }
                        // Xác định hoa hồng.
                        let hoahong = (
                          (Number(item.giadichvu) * Number(item.hoahong)) /
                          100
                        )
                          .toLocaleString()
                          .replace(/[.]/g, ",")

                        return (
                          <tr
                            className="task-list"
                            key={"cus-" + item.id_hopdong}
                          >
                            <td>
                              <span className="fw-semibold">
                                {item.hoten}
                                <p className="mb-0 fw-4 text-muted">
                                  {item.email}
                                </p>
                              </span>
                            </td>
                            <td>
                              <span className="fw-semibold">
                                {item.sodienthoai}
                              </span>
                            </td>
                            <td>{diffTime}</td>

                            <td>
                              {Number(item.giadichvu)
                                .toLocaleString()
                                .replace(/[.]/g, ",")}
                              đ
                            </td>

                            <td>
                              <div className="avatar-list-stacked">
                                <span className="avatar avatar-sm avatar-rounded">
                                  <img src={"/" + item.avatar_user} alt="img" />
                                </span>
                              </div>
                            </td>
                            <td>
                              <span className="text-success fw-5">
                                +{hoahong}đ
                              </span>
                            </td>
                            <td>
                              {item.duyethopdong === 1 ? (
                                <span className="fw-semibold text-success">
                                  Đã duyệt
                                </span>
                              ) : (
                                <span className="fw-semibold text-warning">
                                  Chờ duyệt
                                </span>
                              )}
                            </td>
                            <td>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="outline-dark border-1 p-2 py-1" // class mặc định
                                  bsPrefix="custom-dropdown-toggle"
                                >
                                  <HiOutlineDotsVertical className="fs-21" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <li
                                    className="dropdown-item d-flex fs-14"
                                    onClick={() =>
                                      handleContractApproval(item.id_hopdong)
                                    }
                                  >
                                    Kiểm duyệt
                                  </li>
                                  <li
                                    className="dropdown-item d-flex fs-14"
                                    onClick={() =>
                                      handleEditContract(item.id_hopdong)
                                    }
                                  >
                                    Sửa thông tin
                                  </li>
                                  <li className="dropdown-item d-flex fs-14" onClick={()=>handleExtendContract(item)}>
                                    Gia hạn
                                  </li>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3">
            <div
              className="card custom-card"
              style={{ position: "sticky", top: "68px" }}
            >
              <div className="card-body p-0">
                <div className="p-4 border-bottom border-block-end-dashed d-flex align-items-top">
                  <div className="svg-icon-background bg-primary-transparent me-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      data-name="Layer 1"
                      viewBox="0 0 24 24"
                      className="svg-primary"
                    >
                      <path d="M13,16H7a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2ZM9,10h2a1,1,0,0,0,0-2H9a1,1,0,0,0,0,2Zm12,2H18V3a1,1,0,0,0-.5-.87,1,1,0,0,0-1,0l-3,1.72-3-1.72a1,1,0,0,0-1,0l-3,1.72-3-1.72a1,1,0,0,0-1,0A1,1,0,0,0,2,3V19a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V13A1,1,0,0,0,21,12ZM5,20a1,1,0,0,1-1-1V4.73L6,5.87a1.08,1.08,0,0,0,1,0l3-1.72,3,1.72a1.08,1.08,0,0,0,1,0l2-1.14V19a3,3,0,0,0,.18,1Zm15-1a1,1,0,0,1-2,0V14h2Zm-7-7H7a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2Z" />
                    </svg>
                  </div>
                  <div className="flex-fill">
                    <h6 className="mb-2 fs-15">
                      Hợp đồng tháng
                      <span className="badge bg-primary fw-semibold float-end">
                        {dataRevenue.length > 0
                          ? moment(dataRevenue[0].month_year).format("MM-YYYY")
                          : "0"}
                      </span>
                    </h6>
                    <div className="pb-0 mt-0">
                      <div>
                        <h4 className="fs-18 fw-semibold mb-2">
                          <span className="count-up" data-count="42">
                            {dataRevenue.length > 0
                              ? dataRevenue[0].customer_quantity
                              : 0}
                          </span>
                          <span className="text-muted float-end fs-14 fw-normal">
                            Hợp đồng
                          </span>
                        </h4>
                        {/* <p className="text-muted fs-14 mb-0 lh-1">
                            <span className="text-success me-1 fw-semibold">
                              <i className="ri-arrow-up-s-line me-1 align-middle"></i>
                              10%
                            </span>
                            <span>Hoa hồng</span>
                          </p> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-top p-4 border-bottom border-block-end-dashed">
                  <div className="svg-icon-background bg-warning-transparent me-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      enableBackground="new 0 0 24 24"
                      viewBox="0 0 24 24"
                      className="svg-warning"
                    >
                      <path d="M19,12h-7V5c0-0.6-0.4-1-1-1c-5,0-9,4-9,9s4,9,9,9s9-4,9-9C20,12.4,19.6,12,19,12z M12,19.9c-3.8,0.6-7.4-2.1-7.9-5.9C3.5,10.2,6.2,6.6,10,6.1V13c0,0.6,0.4,1,1,1h6.9C17.5,17.1,15.1,19.5,12,19.9z M15,2c-0.6,0-1,0.4-1,1v6c0,0.6,0.4,1,1,1h6c0.6,0,1-0.4,1-1C22,5.1,18.9,2,15,2z M16,8V4.1C18,4.5,19.5,6,19.9,8H16z" />
                    </svg>
                  </div>
                  <div className="flex-fill">
                    <h6 className="mb-2 fs-15">
                      Doanh thu tháng
                      <span className="badge bg-warning fw-semibold float-end">
                        {dataRevenue.length > 0
                          ? moment(dataRevenue[0].month_year).format("MM-YYYY")
                          : "0"}
                      </span>
                    </h6>
                    <div>
                      <h4 className="fs-15 fw-semibold mb-2">
                        <span className="count-up" data-count="81">
                          {dataRevenue.length > 0
                            ? Number(dataRevenue[0].revenue)
                                .toLocaleString()
                                .replace(/[.]/, ",") + "đ"
                            : "0đ"}
                        </span>
                        <span className="text-muted float-end fs-14 fw-normal">
                          Doanh thu
                        </span>
                      </h4>
                      <p className="text-muted fs-14 mb-0 lh-1">
                        <span className="text-success me-1 fw-semibold">
                          <i className="ri-arrow-up-s-line me-1 align-middle"></i>
                          10%
                        </span>
                        <span>Hoa hồng</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="p-4 border-bottom border-block-end-dashed d-flex align-items-top">
                  <div className="svg-icon-background bg-success-transparent me-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      className="svg-success"
                    >
                      <path d="M11.5,20h-6a1,1,0,0,1-1-1V5a1,1,0,0,1,1-1h5V7a3,3,0,0,0,3,3h3v5a1,1,0,0,0,2,0V9s0,0,0-.06a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19.29.29,0,0,0-.1,0A1.1,1.1,0,0,0,11.56,2H5.5a3,3,0,0,0-3,3V19a3,3,0,0,0,3,3h6a1,1,0,0,0,0-2Zm1-14.59L15.09,8H13.5a1,1,0,0,1-1-1ZM7.5,14h6a1,1,0,0,0,0-2h-6a1,1,0,0,0,0,2Zm4,2h-4a1,1,0,0,0,0,2h4a1,1,0,0,0,0-2Zm-4-6h1a1,1,0,0,0,0-2h-1a1,1,0,0,0,0,2Zm13.71,6.29a1,1,0,0,0-1.42,0l-3.29,3.3-1.29-1.3a1,1,0,0,0-1.42,1.42l2,2a1,1,0,0,0,1.42,0l4-4A1,1,0,0,0,21.21,16.29Z" />
                    </svg>
                  </div>
                  <div className="flex-fill">
                    <h6 className="mb-2 fs-15">
                      Hợp đồng năm
                      <span className="badge bg-success fw-semibold float-end">
                        {dataRevenueYear.length > 0
                          ? dataRevenueYear[0].year_time
                          : 0}
                      </span>
                    </h6>
                    <div>
                      <h4 className="fs-18 fw-semibold mb-2">
                        <span className="count-up" data-count="319">
                          {dataRevenueYear.length > 0
                            ? dataRevenueYear[0].customer_quantity
                            : 0}
                        </span>
                        <span className="text-muted float-end fs-14 fw-normal">
                          Hợp đồng
                        </span>
                      </h4>
                      {/* <p className="text-muted fs-14 mb-0 lh-1">
                          <span className="text-danger me-1 fw-semibold">
                            <i className="ri-arrow-down-s-line me-1 align-middle"></i>
                            1.16%
                          </span>
                          <span>this month</span>
                        </p> */}
                    </div>
                  </div>
                </div>

                <div className="d-flex align-items-top p-4 border-bottom border-block-end-dashed">
                  <div className="svg-icon-background bg-secondary-transparent me-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      enableBackground="new 0 0 24 24"
                      viewBox="0 0 24 24"
                      className="svg-secondary"
                    >
                      <path d="M19,12h-7V5c0-0.6-0.4-1-1-1c-5,0-9,4-9,9s4,9,9,9s9-4,9-9C20,12.4,19.6,12,19,12z M12,19.9c-3.8,0.6-7.4-2.1-7.9-5.9C3.5,10.2,6.2,6.6,10,6.1V13c0,0.6,0.4,1,1,1h6.9C17.5,17.1,15.1,19.5,12,19.9z M15,2c-0.6,0-1,0.4-1,1v6c0,0.6,0.4,1,1,1h6c0.6,0,1-0.4,1-1C22,5.1,18.9,2,15,2z M16,8V4.1C18,4.5,19.5,6,19.9,8H16z" />
                    </svg>
                  </div>
                  <div className="flex-fill">
                    <h6 className="mb-2 fs-15">
                      Doanh thu năm
                      <span className="badge bg-secondary fw-semibold float-end">
                        {dataRevenueYear.length > 0
                          ? dataRevenueYear[0].year_time
                          : 0}
                      </span>
                    </h6>
                    <div>
                      <h4 className="fs-15 fw-semibold mb-2">
                        <span className="count-up" data-count="32">
                          {dataRevenueYear.length > 0
                            ? Number(dataRevenueYear[0].revenue)
                                .toLocaleString()
                                .replace(/[.]/g, ",") + "đ"
                            : "0đ"}
                        </span>
                        <span className="text-muted float-end fs-14 fw-normal">
                          Doanh thu
                        </span>
                      </h4>
                      <p className="text-muted fs-14 mb-0 lh-1">
                        <span className="text-success me-1 fw-semibFold">
                          <i className="ri-arrow-down-s-line me-1 align-middle"></i>
                          10%
                        </span>
                        <span>Hoa hồng</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--End::row-1 --> */}
        {/* Modal edit contract */}
        {dataEditContract.current.length > 0 && (
          <ModalReact
            modalTitle="Sửa hợp đồng"
            showModal={modalEditContract}
            handleClose={handleCloseEditContract}
            theme={
              <ModalEditContractView
                dataEdit={dataEditContract.current}
                checkChange={checkChange}
              />
            }
          />
        )}
        {/* Modal extend contract */}
        {dataExtendContract.current.length > 0 && (
          <ModalReact
            modalTitle="Gia hạn hợp đồng"
            showModal={modalExtendContract}
            handleClose={handleCloseExtendContract}
            theme={<ModalExtendContractView dataExtend={dataExtendContract.current} checkChange={checkChange}/>}
          />
        )}
      </div>
    </div>
  )
}

export default ListContractPC