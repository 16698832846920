import classNames from "classnames/bind"
import styles from "../../Job.module.scss"
import SearchHorizontal from "../../../../Components/Search/SearchHorizontal"
import { useRef, useState } from "react"
import { TbCalendarEvent, TbCalendarSearch } from "react-icons/tb"
import { RiSearch2Line } from "react-icons/ri"
import moment from "moment"
import DateDay from "../../../../Components/Date/DateDay"
import DateMonth from "../../../../Components/Date/DateMonth"
const cx = classNames.bind(styles)

function SearchTaichinhcanhan({ resultSearch }) {
  const [active, setActive] = useState("month-search")
  const objectSearch = useRef({
    monthSearch: moment().format("YYYY-MM"),
    textSearch: "",
    daysSearch: "",
  })
  // Search month
  const [valueMonth, setValueMonth] = useState(moment().format("YYYY-MM"))
  const handleValueMonth = (value) => {
    // setValueMonth(value)
    objectSearch.current = {
      monthSearch: value ? moment(value).format("YYYY-MM") : moment().format("YYYY-MM"),
      daysSearch: objectSearch.current.daysSearch,
      textSearch: objectSearch.current.textSearch,
    }
    resultSearch(objectSearch.current)
  }
  // Search days.
  const [valueDays, setValueDays] = useState(moment().format("YYYY-MM"))
  const handleValueDays = (value) => {
    // setValueDays(value)
    objectSearch.current = {
      monthSearch: objectSearch.current.monthSearch,
      daysSearch: value ? moment(value).format("YYYY-MM-DD") : "",
      textSearch: objectSearch.current.textSearch,
    }
    resultSearch(objectSearch.current)
  }
  // Search text.
  const handleValueSearchText = (value) => {
    objectSearch.current = {
      monthSearch: objectSearch.current.monthSearch,
      daysSearch: objectSearch.current.daysSearch,
      textSearch: value,
    }
    resultSearch(objectSearch.current)
  }

  // Quản lý active search.
  const handleActiveSearch = (cate) => {
    setActive(cate)
  }
  return (
    <div className={cx("wrapper_ground", "col-12")}>
      {/* Tìm theo ngày */}
      {active === "days-search" ? (
        <div className="list_items col-6">
          <DateDay
            result={handleValueDays}
            placeholderText="Tìm theo ngày"
            className="rounded-3"
            style={{ border: "2px solid #ff661d94", height: "39px" }}
          />
          {/* <input
            type="date"
            className={cx(
              "class_input",
              "rounded-3 col-12",
              "search-data",
              active === "days-search" && "active-search",
            )}
            value={valueDays}
            onChange={handleValueDays}
            style={{ border: "2px solid #ff661d94" }}
          /> */}
        </div>
      ) : (
        <button
          className={cx(
            "rounded-3 p-0 btn btn-icon btn-primary-transparent btn-wave text-muted",
            "search-data",
          )}
          onClick={() => handleActiveSearch("days-search")}
          style={{ border: "2px solid #ff661d94" }}
        >
          <TbCalendarEvent className="fs-21 icon-svg" />
        </button>
      )}

      {/* Tìm kiếm theo tháng */}
      {active !== "month-search" && (
        <button
          onClick={() => handleActiveSearch("month-search")}
          className={cx(
            "rounded-3 p-0 btn btn-icon btn-primary-transparent btn-wave text-muted",
            "search-data",
          )}
          style={{ border: "2px solid #ff661d94" }}
        >
          <TbCalendarSearch className="fs-21 icon-svg" />
        </button>
      )}
      {active === "month-search" && (
        <div className="list_items col-6">
          <DateMonth
            result={handleValueMonth}
            placeholderText="Chọn tháng"
            className="rounded-3"
          />
          {/* <input
            type="month"
            className={cx(
              "class_input",
              "rounded-3 col-12",
              "search-data",
              active === "month-search" && "active-search",
            )}
            value={valueMonth}
            onChange={handleValueMonth}
            style={{ border: "2px solid #ff661d94" }}
          /> */}
        </div>
      )}

      {/* Tìm kiếm theo text */}
      {active === "text-search" ? (
        <div className="list_items col-8">
          <SearchHorizontal
            className={cx(
              "rounded-3",
              "search-data",
              active === "text-search" && "active-search",
            )}
            style={{
              backgroundColor: "white",
              border: "2px solid #ff661d94",
              height: "36.4px",
              width: "100%",
            }}
            result={handleValueSearchText}
            placeholder="Tìm theo tên chi phí"
          />
        </div>
      ) : (
        <button
          className={cx(
            "rounded-3 p-0 btn btn-icon btn-primary-transparent btn-wave text-muted",
            "search-data",
          )}
          onClick={() => handleActiveSearch("text-search")}
          style={{ border: "2px solid #ff661d94" }}
        >
          <RiSearch2Line className="fs-21 icon-svg" />
        </button>
      )}
    </div>
  )
}
export default SearchTaichinhcanhan
