import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form"
import LoadingSpinner from "../../../../Components/Loading/LoadingSpinner";
import moment from "moment";

function FormAddThunhap({ socketio, dataUser, result }) {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [dataOption, setDataOption] = useState([]);
    const [loading, setLoading] = useState(true)

    // Set option loại thu nhập
    useEffect(() => {
        const params = {
            iden: process.env.REACT_APP_IDEN_DASH,
            object: { user_id: dataUser[0].id_user }
        }
        socketio.emit("client-get-data-taichinhcanhan-cate-thu", params)
        socketio.on("server-get-data-taichinhcanhan-cate-thu", (data) => {
            setDataOption([...data])
            setLoading(false)
        })
        return () => {
            socketio.off("client-get-data-taichinhcanhan-cate-thu")
            socketio.off("server-get-data-taichinhcanhan-cate-thu")
        }
    }, []);

    // Format money.
    const [formatMoney, setFormatMoney] = useState("")
    const handleFormatMoney = (e) => {
        let format = e.target.value.replace(/[.,]/g, "");
        if (Number(format)) {
            let format_ = Number(format).toLocaleString().replace(/[.]/g, ",");
            setFormatMoney(format_)
        } else {
            alert("Bạn cần nhập dạng số.")
        }
    }

    const checkCreateSuccess = useRef()
    const handleOnSubmit = (object) => {
        object.user_id = dataUser[0].id_user;
        if (object.cate_saved === "Thẻ") {
            object.card_payment = object.money
                .toLocaleString()
                .replace(/[.,]/g, "");
            object.wallet_payment = 0

        } else if (object.cate_saved === "Ví") {
            object.card_payment = 0
            object.wallet_payment = object.money
              .toLocaleString()
              .replace(/[.,]/g, "")
        }
        delete object.cate_saved
        delete object.money
        // Xử lý create data.
        const paramsCreate = {
            iden: process.env.REACT_APP_IDEN_DASH,
            object: object
        }
        socketio.emit("client-create-data-taichinhcanhan-thunhap", paramsCreate)
        socketio.on("server-create-data-taichinhcanhan-thunhap", (data)=>{
            alert("Thêm mới thành công")
            result({
                status: true,
                dataUpdate: data
            })
            checkCreateSuccess.current = object
        })

    }
    useEffect(() => {
        return () => {
            socketio.off("client-create-data-taichinhcanhan-thunhap")
            socketio.off("server-create-data-taichinhcanhan-thunhap")
        }
    }, [checkCreateSuccess.current])

    if (loading) {
        return <LoadingSpinner />
    }
    if (dataOption.length === 0) {
        return <div className="text-center fs-14 mt-3">Bạn cần thêm danh mục thu nhập trước</div>
    }
    return (
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <div className="col-xl-12 col-md-12">
          <div className="row">
            <div className="form-floating mb-3 col-6">
              <select
                className="form-select rounded-3"
                {...register("cate_thu", { required: true })}
              >
                <option value="">Chọn loại thu nhập</option>
                {dataOption.map((item, index) => {
                  return (
                    <option value={item.id} key={index}>
                      {item.title}
                    </option>
                  )
                })}
              </select>
              <label className="fs-15 ms-2 text-muted">
                Loại thu nhập<span className="text-danger ms-1">*</span>
              </label>
              {errors.cate_thu && (
                <span className="text-danger fs-12">
                  *Bạn cần chọn loại thu nhập
                </span>
              )}
            </div>
            <div className="form-floating mb-3 col-6">
              <select
                className="form-select rounded-3"
                {...register("cate_saved", { required: true })}
              >
                <option value="">Chọn phương thức</option>
                <option value="Thẻ">Thẻ</option>
                <option value="Ví">Ví</option>
              </select>
              <label className="fs-15 ms-2 text-muted">
                Phương thức lưu trữ<span className="text-danger ms-1">*</span>
              </label>
              {errors.cate_saved && (
                <span className="text-danger fs-12">
                  *Bạn cần chọn phương thức lưu trữ
                </span>
              )}
            </div>
            <div className="form-floating mb-3 col-12">
              <input
                type="text"
                className="form-control rounded-3"
                placeholder="Tên input"
                {...register("money", { required: true })}
                value={formatMoney}
                onChange={handleFormatMoney}
              />
              <label className="fs-15 ms-2 text-muted">
                Số tiền thu nhập<span className="text-danger ms-1">*</span>
              </label>
              {errors.money && (
                <span className="text-danger fs-12">
                  *Bạn cần nhập số tiền thu nhập
                </span>
              )}
            </div>
          </div>
        </div>
        {/* Ghi chú */}
        <div className="form-floating mb-3">
          <textarea
            className="form-control rounded-3"
            placeholder="Nhập ghi chú"
            style={{ height: "100px" }}
            {...register("note")}
          ></textarea>
          <label className="fs-15 text-muted">Ghi chú</label>
        </div>
        {/* Thoi gian */}
        <div className="form-floating mb-3 col-12">
          <input
            type="datetime-local"
            className="form-control rounded-3"
            placeholder="Tên input"
            {...register("addtime", { required: true })}
            defaultValue={moment().format(
              "YYYY-MM-DD HH:mm",
            )}
          />
          <label className="fs-15 text-muted">
            Thời gian<span className="text-danger ms-1">*</span>
          </label>
          {errors.addtime && (
            <span className="text-danger fs-12">
              *Bạn cần chọn thời gian cho thu nhập này
            </span>
          )}
        </div>
        {/* Button */}
        <div className="px-4 py-3 border-top border-block-start-dashed d-flex justify-content-center">
          <button className="btn btn-primary-light m-1" type="submit">
            Thêm mới
          </button>
        </div>
      </form>
    )
}
export default FormAddThunhap


// Form edit.
export function FormEditThunhap({ socketio, dataUser, result, dataEdit, objectSearch }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()
  const [dataOption, setDataOption] = useState([])
  const [loading, setLoading] = useState(true)

  // Set option loại thu nhập
  useEffect(() => {
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: { user_id: dataUser[0].id_user },
    }
    socketio.emit("client-get-data-taichinhcanhan-cate-thu", params)
    socketio.on("server-get-data-taichinhcanhan-cate-thu", (data) => {
      setDataOption([...data])
      setLoading(false)
    })
    return () => {
      socketio.off("client-get-data-taichinhcanhan-cate-thu")
      socketio.off("server-get-data-taichinhcanhan-cate-thu")
    }
  }, [])

  // Format money.
  const [formatMoney, setFormatMoney] = useState(
    Number(dataEdit.card_payment) > 0
      ? Number(dataEdit.card_payment).toLocaleString().replace(/[.]/g, ",")
      : Number(dataEdit.wallet_payment).toLocaleString().replace(/[.]/g, ","),
  )
  const handleFormatMoney = (e) => {
    let format = e.target.value.replace(/[.,]/g, "")
    if (Number(format)) {
      let format_ = Number(format).toLocaleString().replace(/[.]/g, ",")
      setFormatMoney(format_)
    } else {
      alert("Bạn cần nhập dạng số.")
    }
  }

  const checkCreateSuccess = useRef()
  const handleOnSubmit = (object) => {
    object.id = dataEdit.id
    object.user_id = dataUser[0].id_user
    object.monthSearch = objectSearch.monthSearch

    if (object.cate_saved === "Thẻ") {
      object.card_payment = object.money.toLocaleString().replace(/[.,]/g, "")
      object.wallet_payment = 0
    } else if (object.cate_saved === "Ví") {
      object.card_payment = 0
      object.wallet_payment = object.money.toLocaleString().replace(/[.,]/g, "")
    }
    delete object.cate_saved
    delete object.money
    // Xử lý edit data.
    const paramsEdit = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: object,
    };
    socketio.emit("client-edit-data-taichinhcanhan-thunhap", paramsEdit)
    socketio.on("server-edit-data-taichinhcanhan-thunhap", (data) => {
      alert("Sửa thông tin thành công")
      result({
        status: true,
        dataUpdate: data,
      })
      checkCreateSuccess.current = object
    })
  }
  useEffect(() => {
    return () => {
      socketio.off("client-edit-data-taichinhcanhan-thunhap")
      socketio.off("server-edit-data-taichinhcanhan-thunhap")
    }
  }, [checkCreateSuccess.current])

  if (loading) {
    return <LoadingSpinner />
  }
  if (dataOption.length === 0) {
    return (
      <div className="text-center fs-14 mt-3">
        Bạn cần thêm danh mục thu nhập trước
      </div>
    )
  }
  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <div className="col-xl-12 col-md-12">
        <div className="row">
          <div className="form-floating mb-3 col-6">
            <select
              className="form-select rounded-3"
              {...register("cate_thu", { required: true })}
              defaultValue={dataEdit.cate_thu}
            >
              <option value="">Chọn loại thu nhập</option>
              {dataOption.map((item, index) => {
                return (
                  <option value={item.id} key={index}>
                    {item.title}
                  </option>
                )
              })}
            </select>
            <label className="fs-15 ms-2 text-muted">
              Loại thu nhập<span className="text-danger ms-1">*</span>
            </label>
            {errors.cate_thu && (
              <span className="text-danger fs-12">
                *Bạn cần chọn loại thu nhập
              </span>
            )}
          </div>
          <div className="form-floating mb-3 col-6">
            <select
              className="form-select rounded-3"
              {...register("cate_saved", { required: true })}
              defaultValue={dataEdit.card_payment > 0 ? "Thẻ" : "Ví"}
            >
              <option value="">Chọn phương thức</option>
              <option value="Thẻ">Thẻ</option>
              <option value="Ví">Ví</option>
            </select>
            <label className="fs-15 ms-2 text-muted">
              Phương thức lưu trữ<span className="text-danger ms-1">*</span>
            </label>
            {errors.cate_saved && (
              <span className="text-danger fs-12">
                *Bạn cần chọn phương thức lưu trữ
              </span>
            )}
          </div>
          <div className="form-floating mb-3 col-12">
            <input
              type="text"
              className="form-control rounded-3"
              placeholder="Tên input"
              {...register("money", { required: true })}
              value={formatMoney}
              onChange={handleFormatMoney}
            />
            <label className="fs-15 ms-2 text-muted">
              Số tiền thu nhập<span className="text-danger ms-1">*</span>
            </label>
            {errors.money && (
              <span className="text-danger fs-12">
                *Bạn cần nhập số tiền thu nhập
              </span>
            )}
          </div>
        </div>
      </div>
      {/* Ghi chú */}
      <div className="form-floating mb-3">
        <textarea
          className="form-control rounded-3"
          placeholder="Nhập ghi chú"
          style={{ height: "100px" }}
          {...register("note")}
          defaultValue={dataEdit.note}
        ></textarea>
        <label className="fs-15 text-muted">Ghi chú</label>
      </div>
      {/* Thoi gian */}
      <div className="form-floating mb-3 col-12">
        <input
          type="datetime-local"
          className="form-control rounded-3"
          placeholder="Tên input"
          {...register("addtime", { required: true })}
          defaultValue={moment(dataEdit.addtime).format("YYYY-MM-DD HH:mm")}
        />
        <label className="fs-15 text-muted">
          Thời gian<span className="text-danger ms-1">*</span>
        </label>
        {errors.addtime && (
          <span className="text-danger fs-12">
            *Bạn cần chọn thời gian cho thu nhập này
          </span>
        )}
      </div>
      {/* Button */}
      <div className="px-4 py-3 border-top border-block-start-dashed d-flex justify-content-center">
        <button className="btn btn-primary-light m-1" type="submit">
          Lưu lại
        </button>
      </div>
    </form>
  )
}






/** ========== form luân chuyển tiền từ ví -> thẻ và ngược lại =============== */
export function FormAddChuyentien({ socketio, dataUser, result }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()
  const [dataOption, setDataOption] = useState([])
  const [loading, setLoading] = useState(true)

  // Set option loại thu nhập
  useEffect(() => {
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: { user_id: dataUser[0].id_user },
    }
    socketio.emit("client-get-data-taichinhcanhan-cate-thu", params)
    socketio.on("server-get-data-taichinhcanhan-cate-thu", (data) => {
      setDataOption([...data])
      setLoading(false)
    })
    return () => {
      socketio.off("client-get-data-taichinhcanhan-cate-thu")
      socketio.off("server-get-data-taichinhcanhan-cate-thu")
    }
  }, []);

  // Format money.
  const [formatMoney, setFormatMoney] = useState("")
  const handleFormatMoney = (e) => {
    let format = e.target.value.replace(/[.,]/g, "")
    if (Number(format) >= 0) {
      let format_ = Number(format).toLocaleString().replace(/[.]/g, ",")
      setFormatMoney(format_)
    } else {
      alert("Bạn cần nhập dạng số.")
    }
  };

  // Set status option cate_saved.
  const [optionSaved, setOptionSaved] = useState("");
  const handleOptionSaved = (e) => {
    setOptionSaved(e.target.value + " " + formatMoney)
  }

  const checkCreateSuccess = useRef()
  const handleOnSubmit = (object) => {
    object.user_id = dataUser[0].id_user
    if (object.cate_saved === "Chuyển từ thẻ sang ví") {
      object.card_payment = -(Number(object.money.toLocaleString().replace(/[.,]/g, "")))
      object.wallet_payment = Number(object.money.toLocaleString().replace(/[.,]/g, ""))
      object.note = "Chuyển từ thẻ sang ví " + object.money;
    } else if (object.cate_saved === "Chuyển từ ví sang thẻ") {
      object.card_payment = Number(object.money.toLocaleString().replace(/[.,]/g, ""))
      object.wallet_payment = -(Number(object.money.toLocaleString().replace(/[.,]/g, "")))
      object.note = "Chuyển từ ví sang thẻ " + object.money
    }
    object.chuyentien = 1;
    delete object.money
    delete object.cate_saved

 
    // Xử lý create data.
    const paramsCreate = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: object,
    }
    socketio.emit("client-create-data-taichinhcanhan-thunhap", paramsCreate)
    socketio.on("server-create-data-taichinhcanhan-thunhap", (data) => {
      alert("Chuyển tiền thành công")
      result({
        status: true,
        dataUpdate: data,
      })
      checkCreateSuccess.current = object
    })
  }
  useEffect(() => {
    return () => {
      socketio.off("client-create-data-taichinhcanhan-thunhap")
      socketio.off("server-create-data-taichinhcanhan-thunhap")
    }
  }, [checkCreateSuccess.current])

  if (loading) {
    return <LoadingSpinner />
  }
  if (dataOption.length === 0) {
    return (
      <div className="text-center fs-14 mt-3">
        Bạn cần thêm danh mục thu nhập trước
      </div>
    )
  }
  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <div className="col-xl-12 col-md-12">
        <div className="row">
          <div className="form-floating mb-3 col-12">
            <select
              className="form-select rounded-3"
              {...register("cate_thu", { required: true })}
            >
              <option value="2">Lương</option>
              {dataOption.map((item, index) => {
                return (
                  <option value={item.id} key={index}>
                    {item.title}
                  </option>
                )
              })}
            </select>
            <label className="fs-15 ms-2 text-muted">
              Loại thu nhập<span className="text-danger ms-1">*</span>
            </label>
            {errors.cate_thu && (
              <span className="text-danger fs-12">
                *Bạn cần chọn loại thu nhập
              </span>
            )}
          </div>
          <div className="form-floating mb-3 col-12">
            <input
              type="text"
              className="form-control rounded-3"
              placeholder="Tên input"
              {...register("money", { required: true })}
              value={formatMoney}
              onChange={handleFormatMoney}
            />
            <label className="fs-15 ms-2 text-muted">
              Số tiền cần chuyển<span className="text-danger ms-1">*</span>
            </label>
            {errors.money && (
              <span className="text-danger fs-12">
                *Bạn cần nhập số tiền cần chuyển
              </span>
            )}
          </div>
          <div className="form-floating mb-3 col-12">
            <select
              className="form-select rounded-3"
              {...register("cate_saved", { required: true })}
              onChange={handleOptionSaved}
            >
              <option value="">Chọn phương thức chuyển</option>
              <option value="Chuyển từ thẻ sang ví">Từ thẻ sang ví</option>
              <option value="Chuyển từ ví sang thẻ">Từ ví sang thẻ</option>
            </select>
            <label className="fs-15 ms-2 text-muted">
              Phương thức chuyển tiền<span className="text-danger ms-1">*</span>
            </label>
            {errors.cate_saved && (
              <span className="text-danger fs-12">
                *Bạn cần chọn phương thức chuyển tiền
              </span>
            )}
          </div>
        </div>
      </div>
      {/* Ghi chú */}
      <div className="form-floating mb-3">
        <textarea
          className="form-control rounded-3"
          placeholder="Nhập ghi chú"
          style={{ height: "100px" }}
          {...register("note")}
          defaultValue={optionSaved}
        ></textarea>
        <label className="fs-15 text-muted">Ghi chú</label>
      </div>
      {/* Thoi gian */}
      <div className="form-floating mb-3 col-12">
        <input
          type="datetime-local"
          className="form-control rounded-3"
          placeholder="Tên input"
          {...register("addtime", { required: true })}
          defaultValue={moment().format("YYYY-MM-DD HH:mm")}
        />
        <label className="fs-15 text-muted">
          Thời gian<span className="text-danger ms-1">*</span>
        </label>
        {errors.addtime && (
          <span className="text-danger fs-12">
            *Bạn cần chọn thời gian cho thu nhập này
          </span>
        )}
      </div>
      {/* Button */}
      <div className="px-4 py-3 border-top border-block-start-dashed d-flex justify-content-center">
        <button className="btn btn-primary-light m-1" type="submit">
          Thêm mới
        </button>
      </div>
    </form>
  )
}

export function FormEditChuyentien({ socketio, dataUser, dataEdit, result }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()
  const [dataOption, setDataOption] = useState([])
  const [loading, setLoading] = useState(true)

  // Set option loại thu nhập
  useEffect(() => {
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: { user_id: dataUser[0].id_user },
    }
    socketio.emit("client-get-data-taichinhcanhan-cate-thu", params)
    socketio.on("server-get-data-taichinhcanhan-cate-thu", (data) => {
      setDataOption([...data])
      setLoading(false)
    })
    return () => {
      socketio.off("client-get-data-taichinhcanhan-cate-thu")
      socketio.off("server-get-data-taichinhcanhan-cate-thu")
    }
  }, [])

  // Format money.
  const [formatMoney, setFormatMoney] = useState(
    Number(dataEdit.card_payment).toLocaleString().replace(/[-]/g, "").replace(/[.]/g, ","),
  )
  const handleFormatMoney = (e) => {
    let format = e.target.value.replace(/[.,]/g, "")
    if (Number(format) >= 0) {
      let format_ = Number(format).toLocaleString().replace(/[.]/g, ",")
      setFormatMoney(format_)
    } else {
      alert("Bạn cần nhập dạng số.")
    }
  }

  // Set status option cate_saved.
  const [optionSaved, setOptionSaved] = useState(dataEdit.note)
  const handleOptionSaved = (e) => {
    setOptionSaved(e.target.value + " " + formatMoney)
  }

  const checkCreateSuccess = useRef()
  const handleOnSubmit = (object) => {
    object.user_id = dataUser[0].id_user
    if (object.cate_saved === "Chuyển từ thẻ sang ví") {
      object.card_payment = -Number(
        object.money.toLocaleString().replace(/[.,]/g, ""),
      )
      object.wallet_payment = Number(
        object.money.toLocaleString().replace(/[.,]/g, ""),
      )
      object.note = "Chuyển từ thẻ sang ví " + object.money
    } else if (object.cate_saved === "Chuyển từ ví sang thẻ") {
      object.card_payment = Number(
        Number(object.money).toLocaleString().replace(/[.,]/g, ""),
      )
      object.wallet_payment = -Number(
        object.money.toLocaleString().replace(/[.,]/g, ""),
      )
      object.note = "Chuyển từ ví sang thẻ " + object.money
    }
    object.chuyentien = 1
    object.id = dataEdit.id

    delete object.money
    delete object.cate_saved

    // Xử lý edit data.
    const paramsEdit = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: object,
    }
    socketio.emit("client-edit-data-taichinhcanhan-thunhap", paramsEdit)
    socketio.on("server-edit-data-taichinhcanhan-thunhap", (data) => {
      alert("Sửa thông tin chuyển tiền thành công")
      result({
        status: true,
        dataUpdate: data,
      })
      checkCreateSuccess.current = object
    })
  }
  useEffect(() => {
    return () => {
      socketio.off("client-edit-data-taichinhcanhan-thunhap")
      socketio.off("server-edit-data-taichinhcanhan-thunhap")
    }
  }, [checkCreateSuccess.current])

  if (loading) {
    return <LoadingSpinner />
  }
  if (dataOption.length === 0) {
    return (
      <div className="text-center fs-14 mt-3">
        Bạn cần thêm danh mục thu nhập trước
      </div>
    )
  }
  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <div className="col-xl-12 col-md-12">
        <div className="row">
          <div className="form-floating mb-3 col-12">
            <select
              className="form-select rounded-3"
              {...register("cate_thu", { required: true })}
              defaultValue={dataEdit.cate_thu}
            >
              <option value="2">Lương</option>
              {dataOption.map((item, index) => {
                return (
                  <option value={item.id} key={index}>
                    {item.title}
                  </option>
                )
              })}
            </select>
            <label className="fs-15 ms-2 text-muted">
              Loại thu nhập<span className="text-danger ms-1">*</span>
            </label>
            {errors.cate_thu && (
              <span className="text-danger fs-12">
                *Bạn cần chọn loại thu nhập
              </span>
            )}
          </div>
          <div className="form-floating mb-3 col-12">
            <input
              type="text"
              className="form-control rounded-3"
              placeholder="Tên input"
              {...register("money", { required: true })}
              value={formatMoney}
              onChange={handleFormatMoney}
            />
            <label className="fs-15 ms-2 text-muted">
              Số tiền cần chuyển<span className="text-danger ms-1">*</span>
            </label>
            {errors.money && (
              <span className="text-danger fs-12">
                *Bạn cần nhập số tiền cần chuyển
              </span>
            )}
          </div>
          <div className="form-floating mb-3 col-12">
            <select
              className="form-select rounded-3"
              {...register("cate_saved", { required: true })}
              onChange={handleOptionSaved}
              defaultValue={dataEdit.card_payment < 0 ? "Chuyển từ thẻ sang ví" : "Chuyển từ ví sang thẻ"}
            >
              <option value="">Chọn phương thức chuyển</option>
              <option value="Chuyển từ thẻ sang ví">Từ thẻ sang ví</option>
              <option value="Chuyển từ ví sang thẻ">Từ ví sang thẻ</option>
            </select>
            <label className="fs-15 ms-2 text-muted">
              Phương thức chuyển tiền<span className="text-danger ms-1">*</span>
            </label>
            {errors.cate_saved && (
              <span className="text-danger fs-12">
                *Bạn cần chọn phương thức chuyển tiền
              </span>
            )}
          </div>
        </div>
      </div>
      {/* Ghi chú */}
      <div className="form-floating mb-3">
        <textarea
          className="form-control rounded-3"
          placeholder="Nhập ghi chú"
          style={{ height: "100px" }}
          {...register("note")}
          value={optionSaved}
        ></textarea>
        <label className="fs-15 text-muted">Ghi chú</label>
      </div>
      {/* Thoi gian */}
      <div className="form-floating mb-3 col-12">
        <input
          type="datetime-local"
          className="form-control rounded-3"
          placeholder="Tên input"
          {...register("addtime", { required: true })}
          defaultValue={moment().format("YYYY-MM-DD HH:mm")}
        />
        <label className="fs-15 text-muted">
          Thời gian<span className="text-danger ms-1">*</span>
        </label>
        {errors.addtime && (
          <span className="text-danger fs-12">
            *Bạn cần chọn thời gian cho thu nhập này
          </span>
        )}
      </div>
      {/* Button */}
      <div className="px-4 py-3 border-top border-block-start-dashed d-flex justify-content-center">
        <button className="btn btn-primary-light m-1" type="submit">
          Lưu lại
        </button>
      </div>
    </form>
  )
}