import { useContext, useEffect } from "react";
import CustomersView360 from "./CustormersPage/Page";
import AppContext from "../../handle-reuses/Contexts/CreateContexts";

function CustomersView360Page() {
    const { dataUser } = useContext(AppContext);
    
    if (dataUser[0].goidichvu_user === 1) {
        return <div className="text-center mt-3 fs-15 text-danger">Bạn không có quyền truy cập trang này.</div>
    } else {
        return <CustomersView360 />
    }
    
}
export default CustomersView360Page