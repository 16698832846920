import moment from "moment"
import classNames from "classnames/bind"
import { useContext, useEffect, useLayoutEffect, useRef, useState } from "react"
import { GoBack } from "../../../Components/Goback"
import { useNavigate } from "react-router-dom"
import AppContext from "../../../handle-reuses/Contexts/CreateContexts"
import SearchSalary from "../Component/Bangluong/SearchSalary"
import styles from "../Job.module.scss"
import ModalReact from "../../../Components/Modal/ModalReact"
import FormAddChiphi from "../Component/Ketoan/FormAddChiphi"
import LoadingSpinner from "../../../Components/Loading/LoadingSpinner"
import Tippy from "@tippyjs/react"
import { IoCalendarOutline } from "react-icons/io5"
import { TbArrowBigDownLines, TbArrowBigUpLines } from "react-icons/tb"
import { FaRegClock } from "react-icons/fa"
import ListBangcandoi from "../Component/Ketoan/ListBangcandoi"
import FormEditChiphi from "../Component/Ketoan/FormEditChiphi"
import FormAddTaisan from "../Component/Ketoan/FormAddTaisan"
import FormEditTaisan from "../Component/Ketoan/FormEditTaisan"
import { GoDotFill } from "react-icons/go"
const cx = classNames.bind(styles);

function KetoanPC() {
    const { dataUser, socketio } = useContext(AppContext)
    const navigate = useNavigate()
    useLayoutEffect(() => {
      if (dataUser[0].ke_toan !== 1) {
        navigate("/")
      }
    }, [])
    const [objectSearch, setObjectSearch] = useState({
      monthSearch: moment().format("YYYY-MM"),
      textSearch: "",
    })
    // Quản lý danh mục.
    const [activeCate, setActiveCate] = useState("chi-phi")
    const handleActiveCate = (cate) => {
      setActiveCate(cate)
    }

    // Quản lý search data.
    const handleGetValueSearchText = (object) => {
      setObjectSearch({
        monthSearch: object.monthSearch,
        textSearch: object.textSearch,
        daysSearch: object.daysSearch
      })
    }
  
  // Quản lý thêm mới chi phí.
  const [modalAddChiphi, setModalAddChiphi] = useState(false);
  const handleAddChiphi = () => {
    setModalAddChiphi(true)
  }
  const handleCloseAddModalChiphi = () => {
    setModalAddChiphi(false)
  }
  const [dataUpdate, setDataUpdate] = useState([])
  const handleUpdateDataCreateSuccess = (object) => {
    if (object.status) {
      setDataUpdate([...object.dataUpdate])
    }
  }

  // Quản lý bảng cân đối.
  const [modalBangcandoi, setModalBangcandoi] = useState(false)
  const handleModalBangcandoi = () => {
    setModalBangcandoi(true)
  }
  const handleCloseModalBangcandoi = () => setModalBangcandoi(false);

  // Thêm mới tài sản.
  const [modalTaisan, setModalTaisan] = useState(false);
  const [dataUpdateTaisan, setDataUpdateTaisan] = useState([])
  const handleAddTaisan = () => {
    if (dataUser[0].role_ketoan === 1) {
      setModalTaisan(true)
    } else {
      alert("Bạn không có quyền thêm tài sản.")
    }
  }
  const handleCloseAddTaisan = ()=> setModalTaisan(false)
  const handleAddTaisanSuccess = (object) => {
    if (object.status) {
      setDataUpdateTaisan([...object.dataUpdate])
      console.log(object.dataUpdate)
    }
  }

    return (
      <>
        {/* Top 1 */}
        <div
          className={cx("d-flex justify-content-between align-items-center")}
        >
          <div
            className={cx("d-flex align-items-center gap-3 fs-15 fw-5 col-5")}
          >
            <GoBack />
            <span>
              Bảng kế toán {moment(objectSearch.monthSearch).format("MM/YYYY")}
            </span>
          </div>
          <div className="d-flex align-items-center gap-3">
            <button
              className="d-flex align-items-center fs-15 border-0 bg-transparent"
              onClick={handleModalBangcandoi}
            >
              <GoDotFill className="text-muted fs-21" />
              Bảng cân đối
            </button>
          </div>
        </div>
        {/* top 2 */}
        <div
          className={cx(
            "d-flex justify-content-between align-items-center mt-3",
          )}
        >
          {/* Left */}
          <div className={cx("d-flex alig-items-center gap-3 fs-15")}>
            {/* <button
              className={cx(
                "border-0 bg-transparent",
                activeCate === "doanh-thu" ? "color-orange" : "color-gray",
              )}
              onClick={() => handleActiveCate("doanh-thu")}
            >
              <span className="d-flex align-items-center">Doanh thu</span>
            </button> */}
            <button
              className={cx(
                "border-0 bg-transparent",
                activeCate === "chi-phi" ? "color-orange" : "color-gray",
              )}
              onClick={() => handleActiveCate("chi-phi")}
            >
              <span className="d-flex align-items-center">
                Danh sách chi phí
              </span>
            </button>
            {/* <button
              className={cx(
                "border-0 bg-transparent",
                activeCate === "khoan-vay" ? "color-orange" : "color-gray",
              )}
              onClick={() => handleActiveCate("khoan-vay")}
            >
              <span className="d-flex align-items-center">Khoản vay</span>
            </button> */}
            {/* <button
              className={cx(
                "border-0 bg-transparent",
                activeCate === "bang-can-doi" ? "color-orange" : "color-gray",
              )}
              onClick={() => handleActiveCate("bang-can-doi")}
            >
              <span className="d-flex align-items-center">Bảng cân đối</span>
            </button> */}
            <button
              className={cx(
                "border-0 bg-transparent",
                activeCate === "taisan-von" ? "color-orange" : "color-gray",
              )}
              onClick={() => handleActiveCate("taisan-von")}
            >
              <span className="d-flex align-items-center">Tài sản & vốn</span>
            </button>
            <button
              className={cx(
                "border-0 bg-transparent",
                activeCate === "khoan-vay" ? "color-orange" : "color-gray",
              )}
              onClick={() => handleActiveCate("khoan-vay")}
            >
              <span className="d-flex align-items-center">Khoản vay</span>
            </button>
          </div>
          {/* Right */}
          <div className="wrapper_ground">
            {/* Search month*/}
            <div className="list_items">
              <SearchSalary resultSearch={handleGetValueSearchText} />
            </div>
            {/* Button */}
            <div className={cx("d-flex align-items-center gap-2 list_items")}>
              <button
                className={cx(
                  "border-0 rounded-3 d-flex align-items-center op-9",
                  "btn btn-secondary-gradient btn-wave waves-effect waves-light",
                )}
                onClick={handleAddChiphi}
              >
                Thêm chi phí
              </button>
              {/* <button
                className={cx(
                  "border-0 rounded-3 d-flex align-items-center op-9",
                  "btn btn-primary-gradient btn-wave waves-effect waves-light",
                )}
              >
                Thêm khoản vay
              </button>*/}
              <button
                className={cx(
                  "border-0 rounded-3 d-flex align-items-center op-9",
                  "btn btn-purple-gradient btn-wave waves-effect waves-light",
                )}
                onClick={handleAddTaisan}
              >
                Thêm tài sản
              </button>
            </div>
          </div>
        </div>

        {/* List content */}
        <div
          className={cx("mt-3 p-3 rounded-4 position-relative")}
          style={{
            background: "white",
            height: "calc(100vh - 12rem)",
            overflow: "auto",
          }}
        >
          {/* Chi phí */}
          {activeCate === "chi-phi" && (
            <ListKetoanChiphi
              socketio={socketio}
              dataUser={dataUser}
              objectSearch={objectSearch}
              dataUpdate={dataUpdate}
            />
          )}
          {/* Tài sản */}
          {activeCate === "taisan-von" && dataUser[0].role_ketoan === 1 && (
            <ListKetoanTaisan
              socketio={socketio}
              dataUser={dataUser}
              objectSearch={objectSearch}
              dataUpdate={dataUpdateTaisan}
            />
          )}
          {activeCate === "taisan-von" && dataUser[0].role_ketoan === 0 && (
            <div className="text-center mt-3 fs-15">
              Bạn không có quyền truy cập
            </div>
          )}
        </div>
        {/* Modal add chi phí */}
        {modalAddChiphi && (
          <ModalReact
            modalTitle="Thêm mới chi phí"
            showModal={modalAddChiphi}
            handleClose={handleCloseAddModalChiphi}
            theme={
              <FormAddChiphi
                socketio={socketio}
                dataUser={dataUser}
                result={handleUpdateDataCreateSuccess}
              />
            }
          />
        )}
        {/* Modal add tài sản */}
        {modalTaisan && (
          <ModalReact
            modalTitle="Thêm mới tài sản"
            showModal={modalTaisan}
            handleClose={handleCloseAddTaisan}
            theme={
              <FormAddTaisan
                socketio={socketio}
                dataUser={dataUser}
                result={handleAddTaisanSuccess}
              />
            }
          />
        )}

        {/* Modal bảng cân đối */}
        <ModalReact
          modalTitle="Bảng cân đối"
          showModal={modalBangcandoi}
          handleClose={handleCloseModalBangcandoi}
          theme={<ListBangcandoi socketio={socketio} dataUser={dataUser} />}
        />
      </>
    )
}

export default KetoanPC


// danh sách chi phí.
function ListKetoanChiphi({ socketio, dataUser, objectSearch, dataUpdate }) {
  const [dataExpense, setDataExpense] = useState([])
  const dataExpenseRef = useRef([])
  const totalExpense = useRef(0)
  const totalRevenue = useRef(0)
  const tysuatExpense = useRef(0)
  const tysuatRevenue = useRef(0)
  const totalProfit = useRef(0)
  const [loading, setLoading] = useState(true)
  const [changeEdit, setChangeEdit] = useState()
  const [objectUpdate, setObjectUpdate] = useState({
    totalRevenue: 0,
    totalExpense: 0,
    tysuatRevenue: 0,
    tysuatExpense: 0,
    totalProfit: 0,
  })
  let stt = 1
  // Get data chi phí.
  useEffect(() => {
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        monthSearch: objectSearch.monthSearch,
        textSearch: objectSearch.textSearch,
        daysSearch: objectSearch.daysSearch,
      },
    }
    socketio.emit("client-get-search-data-ketoan-chiphi", params)
    socketio.on("server-get-search-data-ketoan-chiphi", (data) => {
      setDataExpense([...data])
      dataExpenseRef.current = [...data]
      setLoading(true)
    })
    return () => {
      socketio.off("client-get-search-data-ketoan-chiphi")
      socketio.off("server-get-search-data-ketoan-chiphi")
    }
  }, [objectSearch])

  // Quản lý tổng doanh thu, tổng chi phí
  useEffect(() => {
    // Tổng chi phí theo tháng.
    const paramsTotalExpense = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        monthSearch: objectSearch.monthSearch,
      },
    }
    socketio.emit("client-get-total-expense-ketoan-chiphi", paramsTotalExpense)
    socketio.on("server-get-total-expense-ketoan-chiphi", (data) => {
      if (data.length > 0) {
        totalExpense.current = data[0].total_expense
      } else {
        totalExpense.current = 0
      }
    })

    // Tổng doanh thu tháng.
    socketio.emit("client-get-total-revenue-job-contract", paramsTotalExpense)
    socketio.on("server-get-total-revenue-job-contract", (data) => {
      if (data.length > 0) {
        totalRevenue.current = data[0].total_revenue
        tysuatExpense.current =
          (Number(totalExpense.current) / Number(totalRevenue.current)) * 100
        tysuatRevenue.current = 100 - tysuatExpense.current
        totalProfit.current =
          Number(totalRevenue.current) - Number(totalExpense.current)

        setObjectUpdate({
          totalRevenue: totalRevenue.current,
          totalExpense: totalExpense.current,
          tysuatRevenue: Number.isFinite(tysuatRevenue.current) ? tysuatRevenue.current : 0,
            tysuatExpense: Number.isFinite(tysuatExpense.current) ? tysuatExpense.current : 0,
          totalProfit: totalProfit.current,
        })
      } else {
        totalRevenue.current = 0
        tysuatExpense.current = 0
        tysuatRevenue.current = 0
        totalProfit.current = 0
        setObjectUpdate({
          totalRevenue: 0,
          totalExpense: objectUpdate.totalExpense,
          tysuatRevenue: 0,
          tysuatExpense: 0,
          totalProfit: 0,
        })
      }
      setLoading(false)
    })
    return () => {
      socketio.off("client-get-total-expense-ketoan-chiphi")
      socketio.off("server-get-total-expense-ketoan-chiphi")

      socketio.off("client-get-total-revenue-job-contract")
      socketio.off("server-get-total-revenue-job-contract")
    }
  }, [objectSearch, changeEdit])

  // Quản lý update data khi thêm mới
  useEffect(() => {
    if (dataUpdate.length > 0) {
      let dataNew = dataExpenseRef.current
      dataNew.push(dataUpdate[0])
      let sortDataNew = dataNew.sort((a, b) => {
        let min_ = new Date(a.addtime).getTime()
        let max_ = new Date(b.addtime).getTime()
        return max_ - min_
      })
      setDataExpense([...sortDataNew])
      setChangeEdit(dataUpdate[0])
    }
  }, [dataUpdate])

  // Quản lý phê duyệt chí phí,
  const handleAcceptExpense = (id, status_) => {
    let checkAccept

    if (status_ === "accept") {
      checkAccept = window.confirm("Bạn đồng ý khoản chi phí này chứ ?")
    } else if (status_ === "cancel") {
      checkAccept = window.confirm("Bạn không đồng ý với khoản chi này ?")
    }

    if (checkAccept) {
      const paramsEdit = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: {
          status: status_ === "accept" ? 1 : 2,
          id: id,
        },
      }
      socketio.emit("client-edit-row-status-ketoan-chiphi", paramsEdit)
      socketio.on("server-edit-row-status-ketoan-chiphi", (object) => {
        if (object.status === "success") {
          // Thực hiện update data expense.
          let dataUpdateEdit = dataExpenseRef.current
          let index = dataExpenseRef.current.findIndex(
            (item) => item.id === object.data[0].id,
          )
          dataUpdateEdit[index] = object.data[0]
          setDataExpense([...dataUpdateEdit])
          setChangeEdit(object)
        }
      })
    }
  }
  useEffect(() => {
    return () => {
      socketio.off("client-edit-row-status-ketoan-chiphi")
      socketio.off("server-edit-row-status-ketoan-chiphi")
    }
  }, [dataExpense])

  // Quản lý edit data.
  const [modalEdit, setModalEdit] = useState(false)
  const dataEditExpense = useRef()
  const handleEditModelExpense = (object) => {
    setModalEdit(true)
    dataEditExpense.current = object
  }
  const handleCloseEditModelExpense = () => setModalEdit(false)
  const handleEditSuccess = (object) => {
    if (object.status) {
      let dataUpdateEdit = dataExpenseRef.current
      let index = dataUpdateEdit.findIndex(
        (item) => item.id === object.dataUpdate[0].id,
      )
      dataUpdateEdit[index] = object.dataUpdate[0]
      setDataExpense([...dataUpdateEdit])
      dataExpenseRef.current = [...dataUpdateEdit]
      setModalEdit(false)
    }
  }

  if (loading) {
    return <LoadingSpinner />
  }

  if (dataExpense.length === 0) {
    return <div className="text-center fs-15 mt-3">Chưa có dữ liệu.</div>
  }
  return (
    <div className="table-responsive row">
      <div className="d-flex justify-content-center col-xl-1 position-relative p-0">
        <div
          style={{
            background: "#e9edf1",
            height: "100px",
            position: "fixed",
            width: "116px",
          }}
          className="rounded-4 mb-3 d-flex gap-4 flex-direction-column align-items-center p-3"
        >
          <div className="">
            <span className="fs-15 text-muted">Doanh thu</span>
            <div className="fs-18 fw-5">
              {Number(objectUpdate.totalRevenue)
                .toLocaleString()
                .replace(/[.]/g, ",")}
            </div>
            <span className="fs-15 text-muted">Triệu đồng</span>
          </div>
          <div className="">
            <span className="fs-15 text-muted">Chi phí tạm tính</span>
            <div className="fs-18 fw-5 text-danger">
              {Number(objectUpdate.totalExpense)
                .toLocaleString()
                .replace(/[.]/g, ",")}
            </div>
            <span className="fs-15 text-danger">
              {Number.isInteger(objectUpdate.tysuatExpense)
                ? objectUpdate.tysuatExpense
                : objectUpdate.tysuatExpense.toFixed(1)}
              %
            </span>
          </div>
          <div className="">
            <span className="fs-15 text-muted">Lợi nhuận tạm tính</span>
            <div
              className={cx(
                "fs-18 fw-5",
                objectUpdate.totalProfit > 0 && "text-success",
              )}
            >
              {objectUpdate.totalProfit.toLocaleString().replace(/[.]/g, ",")}
            </div>
            <span
              className={cx(
                "fs-15 fw-5 d-flex align-items-center gap-1",
                objectUpdate.totalProfit > 0 && "text-success",
              )}
            >
              {Number.isInteger(objectUpdate.tysuatRevenue)
                ? objectUpdate.tysuatRevenue
                : objectUpdate.tysuatRevenue.toFixed(1)}
              %
              {objectUpdate.totalProfit > 0 ? (
                <TbArrowBigUpLines />
              ) : (
                <TbArrowBigDownLines />
              )}
            </span>
          </div>
        </div>
      </div>
      <div className="col-xl-11">
        <table className="table text-nowrap table-bordered border-primary table-striped">
          <thead>
            <tr>
              <th scope="col">STT</th>
              <th scope="col">Loại chi phí</th>
              {/* <th scope="col">Nội dung</th> */}
              <th scope="col">Chi phí (vnđ)</th>
              <th scope="col">Đã chi(%)</th>
              <th scope="col">Có thể chi(vnđ)</th>
              <th scope="col">C.Phí/Tổng chi</th>
              <th scope="col">Lập phiếu</th>
              <th scope="col">Thời gian</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            {dataExpense.map((item, index) => {
              let tysuat =
                (Number(item.money) / Number(totalExpense.current)) * 100
              // Set limit tỷ suất chi phí được phép chi
              let percentLimit = Number(item.percent_limit)
              // Set tỷ suất đã chi.
              let tysuatDachi =
                (Number(item.money) / Number(objectUpdate.totalRevenue)) * 100
              // Set tỷ suất còn lại có thể chi.
              let tysuatConlai = percentLimit - tysuatDachi
              // Set số tiền còn có thể chi.
              let soTienCotheChi = (Number(objectUpdate.totalRevenue) * (Number(tysuatConlai.toFixed(2)) /100));
              return (
                <tr key={index}>
                  <td style={{ width: "12px" }} className="text-center">
                    <p className="mb-0">{stt++}</p>
                  </td>
                  <td>
                    {/* <p className="mb-0 text-muted fs-14">Loại chi phí</p> */}
                    <p className="mb-0 fw-5">
                      {item.loai_chiphi}{" "}
                      <span className="text-muted fw-4 ms-2">
                        <FaRegClock className="me-1 fs-11" />
                        {moment(item.addtime)
                          .locale("vi")
                          .fromNow()
                          .replace("một", "1")}
                      </span>
                    </p>
                    <p className="mb-0 text-primary">{item.noidung_chiphi}</p>
                  </td>

                  <td>
                    {/* <p className="mb-0 text-muted fs-14">Chi phí</p> */}
                    <p className="mb-0 fw-5">
                      {Number(item.money).toLocaleString().replace(/[.]/g, ",")}
                    </p>
                    <p className="mb-0 fs-12 fw-5 text-primary">
                      Giới hạn chi
                      <span className="fs-15 ms-1">{percentLimit + "%"}</span>
                    </p>
                  </td>

                  <td>
                    <span
                      className={cx(
                        "fw-5",
                        tysuatDachi <= percentLimit
                          ? "text-success"
                          : "text-danger",
                      )}
                    >
                      {Number.isInteger(tysuatDachi)
                        ? Number.isFinite(tysuatDachi)
                          ? tysuatDachi
                          : 0
                        : tysuatDachi.toFixed(2)}
                      %
                      <p className="mb-0 fs-12">
                        {tysuatConlai > 0 ? " Còn lại " : "Đã vượt "}
                        {Number.isInteger(tysuatConlai)
                          ? Number.isFinite(tysuatConlai)
                            ? tysuatConlai
                            : 0
                          : tysuatConlai.toFixed(2)}
                        %
                      </p>
                    </span>
                  </td>

                  <td>
                    {tysuatConlai > 0 ? (
                      <span className="text-success">
                        <p className="mb-0 fs-12">Được chi</p>
                        <p className="mb-0 fw-5">
                          {Number(soTienCotheChi)
                            .toLocaleString()
                            .replace(/[.]/g, ",")}
                        </p>
                      </span>
                    ) : (
                      <span className="text-danger">
                        <p className="mb-0 fs-12">Cần tăng doanh thu</p>
                        <p className="mb-0">0</p>
                      </span>
                    )}
                  </td>

                  <td style={{ width: "12px" }}>
                    {/* <p className="mb-0 text-muted fs-14">Chiếm tỷ lệ</p> */}
                    <div className="mb-0 fw-5 text-danger">
                      <span
                        className={cx(
                          tysuatDachi <= percentLimit
                            ? "text-success"
                            : "text-danger",
                        )}
                      >
                        {Number.isInteger(tysuat) ? tysuat : tysuat.toFixed(2)}
                        <span className="fs-12">%</span>
                      </span>
                    </div>
                    <div className="mb-0 fw-5 text-danger">
                      <div
                        className="progress progress-xs progress-animate"
                        role="progressbar"
                        aria-valuenow="80"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ border: "1px solid #d9d9d9" }}
                      >
                        <div
                          className={cx(
                            "progress-bar progress-bar-striped",
                            tysuatDachi <= percentLimit
                              ? "bg-success"
                              : "bg-danger",
                          )}
                          style={{ width: tysuat + "%" }}
                        ></div>
                      </div>
                    </div>
                  </td>

                  <td style={{ width: "50px" }}>
                    <Tippy content={item.hoten}>
                      <div className="text-center">
                        <span
                          className={cx(
                            "avatar avatar-sm me-2 avatar-rounded",
                            item.onlineoffline === 1 && "online",
                          )}
                        >
                          <img
                            src={"https://system.view360.vn/" + item.avatar}
                            alt="img"
                          />
                        </span>
                      </div>
                    </Tippy>
                  </td>
                  <td>
                    <div className="mb-0 d-flex align-items-center gap-1 text-muted">
                      <IoCalendarOutline />
                      <span>{moment(item.addtime).format("DD/MM/YYYY")}</span>
                    </div>
                    <p className="mb-0 text-muted fs-12">
                      <FaRegClock className="me-2" />
                      Lúc {moment(item.addtime).format("HH:mm:ss")}
                    </p>
                  </td>
                  <td>
                    {item.status === 0 && (
                      <div>
                        {/* Kiểm tra ai được quyền duyệt chi phí này */}
                        {dataUser[0].role_ketoan === 1 ? (
                          <>
                            <button
                              className="badge bg-warning border-0"
                              onClick={() =>
                                handleAcceptExpense(item.id, "accept")
                              }
                            >
                              Chờ duyệt
                            </button>
                            <button
                              className="badge bg-danger border-0 ms-1"
                              onClick={() =>
                                handleAcceptExpense(item.id, "cancel")
                              }
                            >
                              Hủy
                            </button>
                          </>
                        ) : (
                          <button className="badge bg-warning border-0">
                            Chờ duyệt
                          </button>
                        )}

                        {item.nhanvien === dataUser[0].id_user && (
                          <button
                            className="badge bg-info rounded-2 ms-2 border-0"
                            onClick={() => handleEditModelExpense(item)}
                          >
                            Sửa
                          </button>
                        )}
                      </div>
                    )}
                    {item.status === 1 && (
                      <button className="badge bg-success border-0">
                        Đã duyệt
                      </button>
                    )}
                    {item.status === 2 && (
                      <button className="badge bg-danger border-0">
                        Đã hủy
                      </button>
                    )}
                  </td>
                  {/* <td>
                  <p className="mb-0 text-muted fs-14">Tổng lương</p>
                  <p className="text-success mb-0">+ ??? ??? ??? đ</p>
                </td>
                <td>
                  <p className="mb-0 text-muted fs-14">Phiếu lương</p>
                  <button className="border-0 badge bg-primary-gradient">
                    Chi tiết
                  </button>
                </td> */}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      {/* Model edit expense */}
      {modalEdit && (
        <ModalReact
          modalTitle="Sửa dữ liệu"
          showModal={modalEdit}
          handleClose={handleCloseEditModelExpense}
          theme={
            <FormEditChiphi
              dataEdit={dataEditExpense.current}
              result={handleEditSuccess}
            />
          }
        />
      )}
    </div>
  )
}



// List tài sản và vốn.
function ListKetoanTaisan({ socketio, dataUser, objectSearch, dataUpdate }) {
  // Set data taid sản.
  const [dataTaisan, setDataTaisan] = useState([]);
  const dataTaisanRef = useRef([])
  const totalTaisan = useRef(0)
  const [loading, setLoading] = useState(true);
  let stt = 1;

  useEffect(() => {
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        monthSearch: objectSearch.monthSearch,
        textSearch: objectSearch.textSearch
      }
    }
    socketio.emit("client-get-data-ketoan-taisan", params)
    socketio.on("server-get-data-ketoan-taisan", (data) => {
      setDataTaisan([...data]);
      dataTaisanRef.current = [...data]
    });

    // Tổng tài sản.
    socketio.emit("client-total-ketoan-taisan", params)
    socketio.on("server-total-ketoan-taisan", (data) => {
      if (data.length > 0) {
        if (data[0].total_taisan) {
          totalTaisan.current = data[0].total_taisan
        }
      }
      setLoading(false)
    })
    return () => {
      socketio.off("client-get-data-ketoan-taisan")
      socketio.off("server-get-data-ketoan-taisan")

      socketio.off("client-total-ketoan-taisan")
      socketio.off("server-total-ketoan-taisan")
    }
  }, [objectSearch]);

  // Update data khi thêm mới thành công.
  useEffect(() => {
    if (dataUpdate.length > 0) {
      let dataUpdateNew = dataTaisanRef.current;
      dataUpdateNew.push(dataUpdate[0])
      let sortDataNew = dataUpdateNew.sort((a, b) => {
        let min_ = new Date(a.addtime).getTime();
        let max_ = new Date(b.addtime).getTime();
        return max_ - min_
      });
      setDataTaisan([...sortDataNew])
      dataTaisanRef.current = [...sortDataNew]
    }
  }, [dataUpdate])

  // Quản lý phê duyệt tài sản bổ sung.
  const checkEditSuccess = useRef()
  const handleAcceptTaisan = (object, typeText) => {
    let checkAccept 
    if (typeText === "accept") {
      checkAccept = window.confirm("Bạn muốn duyệt tài sản bổ sung này ?");
    } else if (typeText === "cancel") {
      checkAccept = window.confirm("Bạn muốn hủy tài sản bổ sung này ?")
    }
    if (checkAccept) {
      const paramsEdit = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: {id: object.id, status: typeText === "accept" ? 1 : 2}
      }
      socketio.emit("client-edit-row-status-ketoan-taisan", paramsEdit)
      socketio.on("server-edit-row-status-ketoan-taisan", (objectUpdate) => {
        if (objectUpdate.status) {
          alert("Tài sản đã được phê duyệt bổ sung.")
          // Update lại data tài sản.
          let dataUpdateEdit = dataTaisanRef.current
          let index = dataUpdateEdit.findIndex((item) => item.id === objectUpdate.data[0].id)
          dataUpdateEdit[index] = objectUpdate.data[0]
          setDataTaisan([...dataUpdateEdit])
          dataTaisanRef.current = [...dataUpdateEdit]

          checkEditSuccess.current = object
        }
      })
    }
  }
  useEffect(() => {
    return () => {
      socketio.off("client-edit-row-status-ketoan-taisan")
      socketio.off("server-edit-row-status-ketoan-taisan")
    }
    
  }, [checkEditSuccess.current])

  // Quản lý edit data.
  const [modalEdit, setModalEdit] = useState(false)
  const dataEditTaisan = useRef([])
  const handleEditDataTaisan = (object) => {
    setModalEdit(true)
    dataEditTaisan.current = object
  }
  const handleCloseEditDataTaisan = () => {
    setModalEdit(false)
    dataEditTaisan.current = undefined
  }
  const handleResultEditSuccess = (object) => {
    if (object.status) {
      // Update lại data tài sản.
      let dataUpdateEdit = dataTaisanRef.current
      let index = dataUpdateEdit.findIndex(
        (item) => item.id === object.dataUpdate[0].id,
      )
      dataUpdateEdit[index] = object.dataUpdate[0]
      setDataTaisan([...dataUpdateEdit])
      dataTaisanRef.current = [...dataUpdateEdit]
    }
  }

  if (loading) {
    return <LoadingSpinner />
  }
  if (dataTaisan.length === 0) {
    return <div className="text-center mt-3 fs-15">Chưa có dữ liệu.</div>
  }

  return (
    <div style={{ overflow: "auto" }}>
      <div
        className="tab-pane show active text-muted border-0 p-0"
        id="active-orders"
        role="tabpanel"
      >
        <div className="table-responsive">
          <table className="table card-table table-vcenter text-nowrap mb-0">
            <tbody className="active-tab">
              {dataTaisan.map((item, index) => {
                // set cổ phần.
                let cophan = 0
                if (Number(totalTaisan.current) > 0) {
                  cophan =
                    (Number(item.money) / Number(totalTaisan.current)) * 100
                }
                return (
                  <tr key={index}>
                    <td>
                      <div className="align-items-center">
                        <p className="mb-0 fw-semibold">{stt++}</p>
                      </div>
                    </td>
                    <td>
                      <div className="align-items-center">
                        <span className="fs-14 text-muted">Loại tài sản</span>
                        <p className="mb-0 fw-semibold">{item.loai_taisan}</p>
                      </div>
                    </td>
                    <td>
                      <div className="align-items-center">
                        <span className="fs-14 text-muted">Ghi chú</span>
                        <p className="mb-0 fw-semibold text-primary">
                          {item.ghichu}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div className="align-items-center">
                        <span className="fs-14 text-muted">Số tiền</span>
                        <p className="mb-0 fw-semibold">
                          {Number(item.money)
                            .toLocaleString()
                            .replace(/[.]/g, ",")}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div className="align-items-center">
                        <span className="fs-14 text-muted">Cổ phần</span>
                        <p className="mb-0 fw-semibold">
                          {cophan > 0 &&
                            (Number.isInteger(cophan)
                              ? cophan + "%"
                              : cophan.toFixed(2) + "%")}
                          {cophan === 0 && (
                            <span className="text-warning">--</span>
                          )}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="lh-1">
                          <span className="avatar avatar-md avatar-rounded me-2">
                            <img
                              src={"https://system.view360.vn/" + item.avatar}
                              alt=""
                            />
                          </span>
                        </div>
                        <div className="align-items-center">
                          <span className="fs-14 text-muted">Họ tên</span>
                          <p className="mb-0">{item.hoten}</p>
                        </div>
                      </div>
                    </td>
                    {/* <td>
                      <div className="align-items-center">
                        <span className="fs-12 text-muted">Price</span>
                        <p className="mb-0 fw-semibold">$229.99</p>
                      </div>
                    </td> */}
                    <td>
                      <div className="align-items-center">
                        <span className="fs-14 text-muted">Thời gian</span>
                        <p className="mb-0">
                          {moment(item.addtime).format("DD/MM/YYYY HH:mm:ss")}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div className="align-items-center">
                        <span className="fs-14 text-muted">Trạng thái</span>
                        <div>
                          {item.status === 0 && (
                            <span>
                              <button
                                className="border-0 badge bg-warning me-1"
                                onClick={() =>
                                  handleAcceptTaisan(item, "accept")
                                }
                              >
                                Chờ duyệt
                              </button>
                              <button
                                className="border-0 badge bg-danger me-1"
                                onClick={() =>
                                  handleAcceptTaisan(item, "cancel")
                                }
                              >
                                Hủy
                              </button>
                              <button
                                className="border-0 badge bg-info"
                                onClick={() => handleEditDataTaisan(item)}
                              >
                                Sửa
                              </button>
                            </span>
                          )}
                          {item.status === 1 && (
                            <button className="border-0 badge bg-success">
                              Đã duyệt
                            </button>
                          )}
                          {item.status === 2 && (
                            <button className="border-0 badge bg-danger">
                              Đã hủy
                            </button>
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
      {/* Modal edit data */}

      {modalEdit && (
        <ModalReact
          modalTitle="Sửa thông tin"
          showModal={modalEdit}
          handleClose={handleCloseEditDataTaisan}
          theme={
            <FormEditTaisan
              socketio={socketio}
              dataEdit={dataEditTaisan.current}
              result={handleResultEditSuccess}
            />
          }
        />
      )}
    </div>
  )
}