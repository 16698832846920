import moment from "moment"
import classNames from "classnames/bind"
import { PiNotepadLight } from "react-icons/pi"

import styles from "../../Job.module.scss"
import { getDaysArray } from "../../../../handle-reuses/reuses"
import CheckPCMobile from "../../../../handle-reuses/reuses/check.pc.mobile"
import { useEffect, useRef, useState } from "react"
import Tippy from "@tippyjs/react"
import "tippy.js/dist/tippy.css"
import ModalReact from "../../../../Components/Modal/ModalReact"
import ListJobPersonnel from "./ListJobPersonnel"
import LoadingSpinner from "../../../../Components/Loading/LoadingSpinner"
import { RiCheckboxCircleFill, RiMailSendLine } from "react-icons/ri"
import { MdOutlineCancel } from "react-icons/md"
const cx = classNames.bind(styles);

function ListTongquan({ objectSearch, socketio,dataUser }) {
  // Quản lý data job for personel.
  const [dataJob, setDataJob] = useState([])
  const dataJobRef = useRef([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const paramsGet = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        role: dataUser[0].list_congviec,
        month: objectSearch.monthSearch,
        textSearch: objectSearch.textSearch,
        user_id: dataUser[0].id_user
      }
    }
    socketio.emit("client-get-search-data-job", paramsGet)
    socketio.on("server-get-search-data-job", (data) => {
      setDataJob([...data]);
      dataJobRef.current = [...data]
      setLoading(false)
    });
    return () => {
      socketio.off("client-get-search-data-job")
      socketio.off("server-get-search-data-job")
    }
  }, [objectSearch])
  
  // Quản lý update data job khi thêm mới.
  useEffect(() => {
    socketio.on("server-update-create-data-job", (dataUpdateJob) => {
      // Xử lý update lại dữ liệu.
      let user_ = dataUser[0].id_user;
      let sender_ = dataUpdateJob[0].sender
      let receiver_ = dataUpdateJob[0].receiver;
      // let roleListJob = dataUser[0].list_congviec;
      if (user_ === sender_ || user_ === receiver_) {
        setDataJob((old) => [...old, ...dataUpdateJob])
      }
    })
    return () => {
      socketio.off("server-update-create-data-job")
    }
  }, [])
  
  // Quản lý update job khi edit success.
  useEffect(() => {
    socketio.on("server-update-data-edit-job", (dataUpdate) => {
      let dataUpdateJob = [...dataJobRef.current];
      let index = dataUpdateJob.findIndex(
        (item) => item.id === dataUpdate[0].id,
      )
      // Kiểm tra xem là trạng thái xóa hay sửa dữ liệu.
      if (dataUpdate[0].delete_job === 0) {
        dataUpdateJob[index] = dataUpdate[0]
      } else {
        dataUpdateJob.splice(index, 1)
      }
      setDataJob([...dataUpdateJob])
      dataJobRef.current = [...dataUpdateJob]

    });
    return () => {
      socketio.off("server-update-data-edit-job")
    }
  }, [])

  if (loading) {
    return <LoadingSpinner />
  }
  return (
    <ListJobsTypeDaysMobile
      searchMonth={objectSearch.monthSearch}
      dataJob={dataJob}
      dataUser={dataUser}
      socketio={socketio}
    />
  )
}
export default ListTongquan


// List công việc kiểu ngày.
function ListJobsTypeDaysMobile({ searchMonth, dataJob, dataUser, socketio }) {
    const year_ = moment(searchMonth).format("YYYY")
    const month_ = moment(searchMonth).format("MM")
    const arrDays = getDaysArray(year_, month_)
  const sortArrDays = arrDays.sort((a, b) => Number(b.date) - Number(a.date));
  // Set chỉ lấy ngày hiện tại +3 ngày nữa trở xuống.
  const arrShowDays = sortArrDays.filter((item) => {
    let currentDayShow = new Date().getTime()
    let days3 = 3 * 24 * 60 * 60 * 1000; // khoảng thời gian của 3 ngày
    let showDays = new Date(searchMonth + "-" + item.date).getTime()
    return showDays <= currentDayShow + days3
  });

    // Quản lý modal công việc từng user.
  const [modalListJob, setModalListJob] = useState(false)
  const [modalListJobUser, setModalListJobUser] = useState([])
    const userID = useRef(0)
    const handleModalListJob = (userId, day) => {
        setModalListJob(true)
      userID.current = userId;
      let filterListJob = dataJob.filter((item) => {
        let dayStart = moment(item.date_start).format("YYYY-MM-DD")
        let formatDayStart = new Date(dayStart).getTime()

        let dayEnd = moment(item.date_end).format("YYYY-MM-DD")
        let formatDayEnd = new Date(dayEnd).getTime()

        let monthDays = searchMonth + "-" + day
        let formatMonthDays = new Date(monthDays).getTime()

        return (
          (item.receiver === userId && Number(moment(item.date_end).format("DD")) === Number(day)) || (
            formatMonthDays >= formatDayStart &&
            formatMonthDays <= formatDayEnd &&
            item.receiver === userId
          )
        )
        
        
      });
      
      setModalListJobUser([...filterListJob])
    }
    const handleCloseModalListJob = () => {
        setModalListJob(false)
      userID.current = 0
      modalListJobUser.current = []
    }
  return (
    <>
      <div className="d-flex gap-2 flex-direction-column">
        {arrShowDays.map((dayInfo, index) => {
          let currentDay = moment().format("DD")
          let listJob = dataJob.filter((item) => {
            let dayStart = moment(item.date_start).format("YYYY-MM-DD")
            let formatDayStart = new Date(dayStart).getTime()

            let dayEnd = moment(item.date_end).format("YYYY-MM-DD")
            let formatDayEnd = new Date(dayEnd).getTime()

            let monthDays = searchMonth + "-" + dayInfo.date;
            let formatMonthDays = new Date(monthDays).getTime();
            
            return (
              Number(moment(item.date_end).format("DD")) ===
                Number(dayInfo.date) ||
              (formatMonthDays >= formatDayStart &&
                formatMonthDays <= formatDayEnd)
            )
          })
          // Loại bỏ giá trị giống nhau bằng các kết hợp filter và findIndex
          let filterListJob = listJob.filter((item, index, listJobCopy) => {
            return (
              index ===
              listJobCopy.findIndex((t) => t.receiver === item.receiver)
            )
          });

          return (
            <div
              key={index}
              className={cx(
                "day-cell",
                "day-cell-1",
                "rounded-3",
                "m-0",
                Number(currentDay) === Number(dayInfo.date) ? "bg_today" : "",
              )}
            >
              <div
                className="d-flex gap-3 align-items-center p-1"
                style={{ height: "54px" }}
              >
                <div className="d-flex justify-content-between align-items-center flex-direction-column justify-content-center">
                  <div className={cx("day-name")} style={{ width: "56px" }}>
                    <span
                      className={cx(
                        "badge bg-primary-transparent",
                        Number(currentDay) === Number(dayInfo.date)
                          ? "text-warning"
                          : "",
                      )}
                    >
                      {dayInfo.day.replace("Th", "Thứ")}
                    </span>
                  </div>
                  <div
                    className={cx("day-date")}
                    style={{ width: "56px" }}
                  >
                    <span
                      className={cx(
                        Number(currentDay) === Number(dayInfo.date)
                          ? "text-warning"
                          : "text-primary",
                      )}
                    >
                      {dayInfo.date}
                      <span>/{month_}</span>
                    </span>
                  </div>
                </div>
                {/* Nội dung */}
                {listJob.length > 0 && (
                  <div className="wrapper_ground">
                    <div className="list_items">
                      <div className="d-flex gap-2 align-items-center">
                        {filterListJob.map((item, index_) => {
                          // Count job
                          let countJob = listJob.filter(
                            (itemCount) => itemCount.receiver === item.receiver,
                          )
                          // Set thời gian nộp nhiệm vụ.
                          let dateEnd = new Date(item.date_end).getTime()
                          let currentTime = new Date().getTime()
                          let sendFailure = ""
                          if (
                            dateEnd < currentTime &&
                            item.receiver_status === 0
                          ) {
                            sendFailure = (
                              <span className="position-absolute">
                                <MdOutlineCancel
                                  className="fs-18 text-danger rounded-5"
                                  style={{
                                    background: "#ffffffd9",
                                    padding: "2px",
                                  }}
                                />
                              </span>
                            )
                          }
                          return (
                            <div key={index_}>
                              <Tippy content={item.receiver_name}>
                                <div
                                  className="position-relative cursor-pointer d-flex align-items-center"
                                  onClick={() =>
                                    handleModalListJob(
                                      item.receiver,
                                      dayInfo.date,
                                    )
                                  }
                                >
                                  {/* Icon check job */}
                                  <span className="d-flex flex-direction-column align-items-center">
                                    {countJob.map((itemIcon, indexIcon) => {
                                      return (
                                        <span key={indexIcon} className="fs-9">
                                          {itemIcon.sender_status === 1 && (
                                            <RiCheckboxCircleFill className="fs-10 text-success" />
                                          )}
                                          {itemIcon.sender_status === -1 && (
                                            <span className="fs-10">
                                              {sendFailure}
                                            </span>
                                          )}
                                          {itemIcon.receiver_status === 1 &&
                                            itemIcon.sender_status === 0 && (
                                              <RiMailSendLine className="fs-10 text-warning" />
                                            )}
                                          {itemIcon.receiver_status === 1 &&
                                            itemIcon.sender_status === -1 && (
                                              <MdOutlineCancel className="fs-10 text-danger" />
                                            )}
                                        </span>
                                      )
                                    })}
                                  </span>

                                  <span
                                    className={cx(
                                      "avatar avatar-md me-2 avatar-rounded position-relative",
                                      item.onlineoffline === 1 && "online",
                                    )}
                                  >
                                    {/* {item.receiver_status === 1 &&
                                      item.sender_status === 0 && (
                                        <span className="position-absolute">
                                          <RiMailSendLine
                                            className="fs-18 text-warning rounded-5"
                                            style={{
                                              background: "#ffffffd9",
                                              padding: "2px",
                                            }}
                                          />
                                        </span>
                                      )}
                                    {item.receiver_status === 1 &&
                                      item.sender_status === 1 && (
                                        <span className="position-absolute">
                                          <RiCheckboxCircleFill
                                            className="fs-18 text-success rounded-5"
                                            style={{
                                              background: "#ffffffd9",
                                              padding: "2px",
                                            }}
                                          />
                                        </span>
                                      )}
                                    {item.receiver_status === 1 &&
                                      item.sender_status === -1 && (
                                        <span className="position-absolute">
                                          <MdOutlineCancel
                                            className="fs-18 text-danger rounded-5"
                                            style={{
                                              background: "#ffffffd9",
                                              padding: "2px",
                                            }}
                                          />
                                        </span>
                                      )} */}
                                    {sendFailure}
                                    <img
                                      src={
                                        "https://system.view360.vn/" +
                                        item.receiver_avatar
                                      }
                                      alt="img"
                                    />
                                  </span>
                                  <span
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0px",
                                    }}
                                    className="fw-5 fs-14"
                                  >
                                    {countJob.length >= 10
                                      ? "+9"
                                      : countJob.length}
                                  </span>
                                </div>
                              </Tippy>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )
        })}
      </div>
      {/* Modal list job */}
      {modalListJob && (
        <ModalReact
          modalTitle={
            <div className="d-flex align-items-center gap-1">
              <span
                className={cx(
                  "avatar avatar-md me-2 avatar-rounded",
                  modalListJobUser[0].onlineoffline === 1 && "online",
                )}
              >
                <img
                  src={
                    "https://system.view360.vn/" +
                    modalListJobUser[0].receiver_avatar
                  }
                  alt="img"
                />
              </span>
              <span className="d-flex flex-direction-column">
                <span className="fw-5">{modalListJobUser[0].receiver_name}</span>
                <span className="text-muted mt-1">
                  Công việc{" "}
                  {moment(modalListJobUser[0].date_end).format("DD/MM/YYYY")}
                </span>
              </span>
            </div>
          }
          showModal={modalListJob}
          handleClose={handleCloseModalListJob}
          theme={
            <ListJobPersonnel
              userID={userID.current}
              listJob={modalListJobUser}
              dataUser={dataUser}
              socketio={socketio}
            />
          }
        />
      )}
    </>
  )
}



