import CheckPCMobile from "../../handle-reuses/reuses/check.pc.mobile"
import JobHomePageMobile from "./Mobile"
import JobHomePagePC from "./PC"

function JobHomePage() {
    return (
        <CheckPCMobile>
            {(isMobile)=> isMobile ? <JobHomePageMobile /> : <JobHomePagePC />}
        </CheckPCMobile>
    )
}
export default JobHomePage