import io from "socket.io-client"
import "bootstrap/dist/js/bootstrap"
import { Carousel } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { useRef, useEffect, useState } from "react"

import RegisterCover from "../Register"
import { handleLogin } from "../../handle-reuses/reuses/Resuse-login"
import { images, imageLogo } from "../../assets/images"

function LoginPage1() {
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm()
    const socket = useRef()
    const [checkLogin, setCheckLogin] = useState()
    
    // Khởi tạo socket.
    useEffect(() => {
        socket.current = io(process.env.REACT_APP_DOMAIN)
        return () => {
        socket.current.disconnect()
        }
    }, [])

    // Xử lý submit.
    const onSubmit = (data) => {
        // Đổi tên trong object cho đúng định dạng back-end (nếu các trường tên đầu vào bạn không muốn thay đổi)
        const objectNew = {
        sodienthoai: data.phoneNumber,
        matkhau: data.password,
        }
        // Kiểm tra xem dữ liệu có tồn tại hay không trước khi cho đăng nhập.
        socket.current.emit("login", objectNew.sodienthoai)
        socket.current.on("server-login", (data) => {
        // Nếu tồn tại dữ liệu
        if (data.length > 0) {
            if (data[0].kiemduyet === 1) {
            // xác nhận tài khoản có tồn tại.
            setCheckLogin(objectNew)
            } else {
            alert("Tài khoản của bạn chưa được kiểm duyệt.")
            }
        } else {
            alert("Tài khoản chưa tồn tại")
        }
        })
    }
    
    // Xử lý đăng nhập.
    useEffect(() => {
        if (checkLogin) {
            handleLoginSystem(checkLogin)
        }
    }, [checkLogin])

  // Cho phép đăng nhập khi tài khoản đã tồn tại
    function handleLoginSystem(objectNew) {
        const apiUser = process.env.REACT_APP_CHECK_LOGIN
        const methodPost = {
        method: "POST",
        headers: {
            "content-type": "application/json",
            identification: process.env.REACT_APP_IDEN_DASH,
        },
        body: JSON.stringify(objectNew),
        }

        fetch(apiUser, methodPost)
        .then((reponse) => {
            return reponse.json()
        })
        .then((dataUser) => {
            if (dataUser) {
                handleLogin(dataUser)
            } else {
            alert("Mật khẩu không tồn tại.")
            }
        })
        .catch((errors) => {
            // console.log(errors)
        })
    }

    // Mở sang trang view360.
    const handleOpenView360 = () => {
        window.open("https://view360.vn", "_blank")
    }

  return (
    <div className="row authentication mx-0">
      <div className="col-xxl-7 col-xl-7 col-lg-12">
        <div className="row justify-content-center align-items-center h-100">
          <div className="col-xxl-6 col-xl-7 col-lg-7 col-md-7 col-sm-8 col-12">
            <div className="p-5">
              {/* <div className="mb-3">
                <a href="#">
                  <img
                    src={imageLogo.logo}
                    alt=""
                    className="authentication-brand desktop-logo"
                  />
                  <img
                    src={imageLogo.logo}
                    alt=""
                    className="authentication-brand desktop-dark"
                  />
                </a>
              </div> */}
              <p className="h5 fw-semibold mb-2">Đăng nhập</p>
              <p className="mb-5 text-muted op-7 fw-normal">
                Chào mừng bạn đến với View360 !
              </p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row gy-3">
                  <div className="col-xl-12 mt-0">
                    <label htmlFor="phone" className="form-label text-default">
                      Số điện thoại
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      placeholder="Số điện thoại"
                      id="phone"
                      {...register("phoneNumber", { required: true })}
                    />
                    {errors.phoneNumber && (
                      <span className="fs-12 text-danger">
                        *Số điện thoại chưa đăng ký
                      </span>
                    )}
                  </div>
                  <div className="col-xl-12 mb-3">
                    <label
                      htmlFor="password"
                      className="form-label text-default d-block"
                    >
                      Mật khẩu
                    </label>
                    <input
                      type="password"
                      className="form-control form-control-lg"
                      id="password"
                      placeholder="Mật khẩu"
                      {...register("password", { required: true })}
                    />
                    {errors.password && (
                      <span className="text-danger fs-12">
                        *Mật khẩu không đúng.
                      </span>
                    )}
                  </div>
                  <div className="col-xl-12 d-grid mt-2">
                    <button type="submit" className="btn btn-outline-dark">
                      Đăng nhập
                    </button>
                  </div>
                </div>
              </form>
              <div className="text-center">
                <p className="fs-12 text-muted mt-4">
                  Bạn chưa có tài khoản?{" "}
                  <a
                    href="#"
                    className="text-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#registerCover"
                  >
                    Đăng ký
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xxl-5 col-xl-5 col-lg-5 d-xl-block d-none px-0">
        <div className="authentication-cover">
          <div className="aunthentication-cover-content rounded">
            <div className="swiper keyboard-control">
              <div className="swiper-wrapper">
                <Carousel wrap={true}>
                  <Carousel.Item>
                    <div className="swiper-slide">
                      <div className="text-fixed-white text-center p-5 d-flex align-items-center justify-content-center">
                        <div>
                          <div className="mb-5">
                            <img
                              src={images.view360}
                              className="authentication-image"
                              alt=""
                            />
                          </div>
                          <a href="#" onClick={handleOpenView360}>
                            <h6 className="fw-semibold">View360.vn</h6>
                            <p className="fw-normal fs-14 op-7">
                              {" "}
                              Xây dựng không gian thực tế ảo 360 độ chuyên
                              nghiệp, tích hợp tất cả website, app mobile. Giúp
                              tăng trải nghiệm người dùng với việc mô phỏng
                              không gian mà bạn muốn.
                            </p>
                          </a>
                        </div>
                      </div>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="swiper-slide">
                      <div className="text-fixed-white text-center p-5 d-flex align-items-center justify-content-center">
                        <div>
                          <div className="mb-5">
                            <img
                              src="../assets/images/authentication/3.png"
                              className="authentication-image"
                              alt=""
                            />
                          </div>
                          <h6 className="fw-semibold">batdongsan.view360.vn</h6>
                          <p className="fw-normal fs-14 op-7">
                            {" "}
                            Là website cung cấp dịch vụ quảng cáo bất động sản,
                            quản lý bất động sản, việc quản lý căn hộ, chung cư,
                            nhà phố, nhà trọ đơn giản trên 1 nền tảng, giúp việc
                            vận hành dễ dàng tiết kiệm thời gian quản lý.
                          </p>
                        </div>
                      </div>
                    </div>
                  </Carousel.Item>
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal register */}
      <RegisterCover />
    </div>
  )
}

export default LoginPage1
