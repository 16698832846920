import { useLayoutEffect, useState } from "react"
import classNames from "classnames/bind"
import { Link, useLocation } from "react-router-dom"
import { VscDashboard } from "react-icons/vsc"
import {
  IoFolderOpenOutline,
  IoLogoReact,
  IoCalendarOutline,
  IoCardOutline,
} from "react-icons/io5"
import { AiOutlineUsergroupAdd } from "react-icons/ai"
import { HiOutlineUserAdd } from "react-icons/hi"
import { BiSupport } from "react-icons/bi"

import styles from "../../../../Components/Sidebar/Sidebar.module.scss"
import { images } from "../../../../assets/images"

const cx = classNames.bind(styles)

function SidebarView360({ active }) {
  const path = useLocation()
  const [pathActive, setPathActive] = useState("/view360")
  useLayoutEffect(() => {
    setPathActive(path.pathname)
  }, [])

  return (
    <aside
      className={cx("app-sidebar sticky")}
      style={{ zIndex: "999", width: active && "6rem" }}
    >
      {/* <!-- Start::main-sidebar-header --> */}
      <div className="main-sidebar-header" style={{ width: active && "6rem" }}>
        <Link to="/">
          <img src={images.user} alt="logo" width={50} height={50} />
        </Link>
      </div>
      {/* <!-- End::main-sidebar-header --> */}

      {/* <!-- Start::main-sidebar --> */}
      <div className={cx("main-sidebar", "sidebar-scroll")}>
        {/* <!-- Start::nav --> */}
        <nav className="main-menu-container nav nav-pills flex-column sub-open">
          <ul
            className={cx("main-menu", "sidebarMenu")}
            style={{ width: "100%" }}
          >
            {active ? (
              <ListSidebarPC1 active={pathActive} />
            ) : (
              <ListSidebarPC2 active={pathActive} />
            )}
          </ul>
        </nav>
        {/* <!-- End::nav --> */}
      </div>
      {/* <!-- End::main-sidebar --> */}
    </aside>
  )
}

export default SidebarView360

// List sidebar pc 1. (sidebar icon)
function ListSidebarPC1({ active }) {
  return (
    <>
      {/* <!-- Start::slide__category --> */}
      <li className="slide__category">
        <span className="category-name">Main</span>
      </li>
      {/* <!-- End::slide__category --> */}

      {/* <!-- Start::slide --> */}
      <li className="slide has-sub">
        <Link
          to="/view360"
          className={cx(
            "side-menu__item justify-content-center flex-direction-column",
            active === "/view360" ? "active" : "",
          )}
        >
          <VscDashboard className="fs-21" />
          <span className="fs-11">H. Đồng</span>
        </Link>
      </li>
      {/* <!-- End::slide --> */}

      {/* <!-- Start::slide__category --> */}
      {/* <li className="slide__category">
        <span className="category-name">Pages</span>
      </li> */}
      {/* <!-- End::slide__category --> */}

      {/* <!-- Start::slide --> */}
      {/* <li className="slide has-sub">
        <Link
          to="/view360/hop-dong"
          className={cx(
            "side-menu__item justify-content-center flex-direction-column",
            active === "/view360/hop-dong" ? "active" : "",
          )}
        >
          <IoFolderOpenOutline className="fs-21" />
          <span className="fs-11">H. Đồng</span>
        </Link>
      </li> */}
      {/* <!-- End::slide --> */}

      {/* <!-- Start::slide --> */}
      {/* <li className="slide has-sub">
        <Link
          to="/bdsview360/lich-hen"
          className={cx(
            "side-menu__item justify-content-center flex-direction-column",
            active === "/bdsview360/lich-hen" ? "active" : "",
          )}
        >
          <IoCalendarOutline className="fs-21" />
          <span className="fs-11">Lịch hẹn</span>
        </Link>
      </li> */}
      {/* <!-- End::slide --> */}

      {/* <!-- Start::slide --> */}
      {/* <li className="slide has-sub">
        <Link
          to="/bdsview360/khach-hang"
          className={cx(
            "side-menu__item justify-content-center flex-direction-column",
            active === "/bdsview360/khach-hang" ? "active" : "",
          )}
        >
          <AiOutlineUsergroupAdd className="fs-21" />
          <span className="fs-11">K.Hàng</span>
        </Link>
      </li> */}
      {/* <!-- End::slide --> */}

      {/* <!-- Start::slide__category --> */}
      {/* <li className="slide__category">
        <span className="category-name">General</span>
      </li> */}
      {/* <!-- End::slide__category --> */}

      {/* <!-- Start::slide --> */}
      {/* <li className="slide has-sub">
        <Link
          to="/bdsview360/tai-khoan"
          className={cx(
            "side-menu__item justify-content-center flex-direction-column",
            active === "/bdsview360/tai-khoan" ? "active" : "",
          )}
        >
          <HiOutlineUserAdd className="fs-21" />
          <span className="fs-11">T.Khoản</span>
        </Link>
      </li> */}
      {/* <!-- End::slide --> */}

      {/* <!-- Start::slide --> */}
      {/* <li className="slide has-sub">
        <Link
          to="/bdsview360/thanh-toan"
          className={cx(
            "side-menu__item justify-content-center flex-direction-column",
            active === "/bdsview360/thanh-toan" ? "active" : "",
          )}
        >
          <IoCardOutline className="fs-21" />
          <span className="fs-11">T.Toán</span>
        </Link>
      </li> */}
      {/* <!-- End::slide --> */}

      {/* <!-- Start::slide --> */}
      {/* <li className="slide has-sub">
        <Link
          to="/bdsview360/ho-tro"
          className={cx(
            "side-menu__item justify-content-center flex-direction-column",
            active === "/bdsview360/ho-tro" ? "active" : "",
          )}
        >
          <BiSupport className="fs-21" />
          <span className="fs-11">Hỗ trợ</span>
        </Link>
      </li> */}
      {/* <!-- End::slide --> */}

      {/* <!-- Start::slide --> */}
      {/* <li className="slide has-sub">
        <Link
          to="/bdsview360/content-ai"
          className={cx(
            "side-menu__item justify-content-center flex-direction-column",
            active === "/bdsview360/content-ai" ? "active" : "",
          )}
        >
          <IoLogoReact className="fs-21" />
          <span className="fs-11">AI</span>
        </Link>
      </li> */}
      {/* <!-- End::slide --> */}
    </>
  )
}

// List sidebar menu 2.
function ListSidebarPC2({ active }) {
  return (
    <>
      {/* <!-- Start::slide__category --> */}
      <li className="slide__category">
        <span className="category-name">Main</span>
      </li>
      {/* <!-- End::slide__category --> */}

      {/* <!-- Start::slide --> */}
      <li className="slide has-sub">
        <Link
          to="/view360"
          className="side-menu__item align-items-center"
        >
          <VscDashboard className="fs-16 me-2" />
          <span className="side-menu__label">Hợp đồng</span>
        </Link>
      </li>
      {/* <!-- End::slide --> */}

      {/* <!-- Start::slide__category --> */}
      {/* <li className="slide__category">
        <span className="category-name">Pages</span>
      </li> */}
      {/* <!-- End::slide__category --> */}

      {/* <!-- Start::slide --> */}
      {/* <li className="slide has-sub">
        <Link
          to="/view360/hop-dong"
          className="side-menu__item align-items-center"
        >
          <IoFolderOpenOutline className="fs-16 me-2" />
          <span className="side-menu__label">
            Hợp đồng
          </span>
          <i className="fe fe-chevron-right side-menu__angle"></i>
        </Link>
      </li> */}
      {/* <!-- End::slide --> */}

      {/* <!-- Start::slide --> */}
      {/* <li className="slide has-sub">
        <Link
          to="/bdsview360/lich-hen"
          className={cx(
            "side-menu__item align-items-center",
            active === "/bdsview360/lich-hen" ? "active" : "",
          )}
        >
          <IoCalendarOutline className="fs-16 me-2" />
          <span className="side-menu__label">
            Lịch hẹn
            <span className="badge bg-secondary-transparent ms-2">New</span>
          </span>
          <i className="fe fe-chevron-right side-menu__angle"></i>
        </Link>
      </li> */}
      {/* <!-- End::slide --> */}

      {/* <!-- Start::slide --> */}
      {/* <li className="slide has-sub">
        <Link
          to="/bdsview360/khach-hang"
          className={cx(
            "side-menu__item align-items-center",
            active === "/bdsview360/khach-hang" ? "active" : "",
          )}
        >
          <AiOutlineUsergroupAdd className="fs-16 me-2" />
          <span className="side-menu__label">Khách hàng</span>
          <i className="fe fe-chevron-right side-menu__angle"></i>
        </Link>
      </li> */}
      {/* <!-- End::slide --> */}

      {/* <!-- Start::slide__category --> */}
      {/* <li className="slide__category">
        <span className="category-name">General</span>
      </li> */}
      {/* <!-- End::slide__category --> */}

      {/* <!-- Start::slide --> */}
      {/* <li className="slide has-sub">
        <Link
          to="/bdsview360/tai-khoan"
          className={cx(
            "side-menu__item align-items-center",
            active === "/bdsview360/tai-khoan" ? "active" : "",
          )}
        >
          <HiOutlineUserAdd className="fs-16 me-2" />
          <span className="side-menu__label">Tài khoản</span>
          <i className="fe fe-chevron-right side-menu__angle"></i>
        </Link>
      </li> */}
      {/* <!-- End::slide --> */}

      {/* <!-- Start::slide --> */}
      {/* <li className="slide has-sub">
        <Link
          to="/bdsview360/thanh-toan"
          className={cx(
            "side-menu__item align-items-center",
            active === "/bdsview360/thanh-toan" ? "active" : "",
          )}
        >
          <IoCardOutline className="fs-16 me-2" />
          <span className="side-menu__label">Thanh toán</span>
          <i className="fe fe-chevron-right side-menu__angle"></i>
        </Link>
      </li> */}
      {/* <!-- End::slide --> */}

      {/* <!-- Start::slide --> */}
      {/* <li className="slide has-sub">
        <Link
          to="/bdsview360/ho-tro"
          className={cx(
            "side-menu__item align-items-center",
            active === "/bdsview360/ho-tro" ? "active" : "",
          )}
        >
          <BiSupport className="fs-16 me-2" />
          <span className="side-menu__label">Hỗ trợ</span>
          <i className="fe fe-chevron-right side-menu__angle"></i>
        </Link>
      </li> */}
      {/* <!-- End::slide --> */}

      {/* <!-- Start::slide --> */}
      {/* <li className="slide has-sub">
        <Link
          to="/bdsview360/content-ai"
          className={cx(
            "side-menu__item align-items-center",
            active === "/bdsview360/content-ai" ? "active" : "",
          )}
        >
          <IoLogoReact className="fs-16 me-2" />
          <span className="side-menu__label">Conten AI</span>
          <i className="fe fe-chevron-right side-menu__angle"></i>
        </Link>
      </li> */}
      {/* <!-- End::slide --> */}
    </>
  )
}
