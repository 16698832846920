import classNames from "classnames/bind"
import { HiOutlineDotsVertical } from "react-icons/hi"

import styles from "../../Job.module.scss"
import SearchHorizontal from "../../../../Components/Search/SearchHorizontal";
import { useContext, useEffect, useRef, useState } from "react";
import moment from "moment";
import AppContext from "../../../../handle-reuses/Contexts/CreateContexts";
import { TextLimit } from "../../../../handle-reuses/reuses";
import { BsDot } from "react-icons/bs";
import LoadingSpinner from "../../../../Components/Loading/LoadingSpinner";
const cx = classNames.bind(styles);

function ListVangmat() {
    const { socketio, dataUser } = useContext(AppContext);
    const [dataVangmat, setDataVangmat] = useState([])
    const dataAccept = useRef([])
    const monthRef = useRef(moment().format("YYYY-MM"));
    const [objectSearch, setObjectSearch] = useState({
        monthSearch: monthRef.current,
        textSearch: ""
    });
    const [loading, setLoading] = useState(true)

    // Quản lý danh sách đơn vắng mặt.
    const handleGetDataVangmat = (monthSearch, keywords) => {
        const params = {
            iden: process.env.REACT_APP_IDEN_DASH,
            object: {
                role: dataUser[0].list_vangmat,
                month: monthSearch,
                keywords: keywords,
                user_id: dataUser[0].id_user
            }
        };
        socketio.emit("client-get-search-data-month-xinvangmat", params)
        socketio.on("server-get-search-data-month-xinvangmat", (data) => {
            let dataAcceptStart = []
            for (let i = 0; i < data.length; i++) {
                if (data[i].id) {
                   dataAcceptStart.push(data[i])
               }
            }
            setDataVangmat([...dataAcceptStart])
            dataAccept.current = [...dataAcceptStart]
            setLoading(false)
        })
    }
    useEffect(() => {
        // Gọi dữ liệu ban đầu.
        handleGetDataVangmat(monthRef.current)
        return () => {
            socketio.off("client-get-search-data-month-xinvangmat")
            socketio.off("server-get-search-data-month-xinvangmat")
        }
    }, [])

    // Quản lý tìm kiếm
    const handleValueSearchText = (value) => {
        setObjectSearch({
            monthSearch: objectSearch.monthSearch,
            textSearch: value
        })
        handleGetDataVangmat(objectSearch.monthSearch, value)
        monthRef.current = objectSearch.monthSearch
    }
    const handleValueSearchMonth = (e) => {
        setObjectSearch({
            monthSearch: e.target.value,
            textSearch: objectSearch.textSearch
        });
        handleGetDataVangmat(moment(e.target.value).format("YYYY-MM"), objectSearch.textSearch)
        monthRef.current = e.target.value
    }

    // Quản lý duyệt đơn vắng mặt.
    const handleAbsentAccept = (id, index) => {
        const checkAccept = window.confirm("Bạn xác nhận đồng ý chứ ?");
        if (checkAccept) {
            const paramsEdit = {
              iden: process.env.REACT_APP_IDEN_DASH,
              object: { status: 1, id: id },
            }
            socketio.emit("client-edit-row-status-xinvangmat", paramsEdit)
            socketio.on("server-edit-row-status-xinvangmat", (object) => {
              if (object.status === "success") {
                let dataUpdateAccept = dataAccept.current
                dataUpdateAccept[index] = object.dataUpdate[0]
                setDataVangmat([...dataUpdateAccept])
              }
            })
        }
    }
    useEffect(() => {
        return () => {
            socketio.off("client-edit-row-status-xinvangmat")
            socketio.off("server-edit-row-status-xinvangmat")
        }
    }, [dataVangmat])

    // Quản lý hiển thị tất cả nội dung.
    const [activeContent, setActiveContent] = useState()
    const handleActiveMoreContent = (id) => {
        setActiveContent(id)
    }

    if (loading) {
        return <LoadingSpinner />
    }
    return (
      <div>
        {/* Search data */}
        <div className="wrapper_ground">
          <div className="list_items">
            <input
              type="month"
              className={cx("class_input", "rounded-3 col-12")}
              style={{
                border: "2px solid rgb(128 129 246 / 58%)",
                height: "40px",
              }}
              value={objectSearch.monthSearch}
              onChange={handleValueSearchMonth}
            />
          </div>
          <div className="list_items">
            <SearchHorizontal
              result={handleValueSearchText}
              className="rounded-3 col-12"
              style={{
                border: "2px solid rgb(128 129 246 / 58%)",
                width: "100%",
              }}
            />
          </div>
        </div>
        {/* List content */}
        <div className="mt-3 text-center fs-15 alert alert-primary">
          Danh sách yêu cầu vắng mặt tháng{" "}
          <span className="fw-5 fs-16">{moment(monthRef.current).format("MM/YYYY")}</span>
        </div>
        {dataVangmat.length === 0 && (
          <div className="mt-3 fs-15 text-center">
            Không có dữ liệu phù hợp.
          </div>
        )}
        {dataVangmat.length > 0 && (
          <div className="mt-3">
            {dataVangmat.map((item, index) => {
              let infoAbsent = `${item.title} ${item.content}`
              let setToday = moment(item.addtime).format("YYYY-MM-DD")
              let currentDay = moment().format("YYYY-MM-DD")
              if (setToday === currentDay) {
                setToday = (
                  <span className="text-primary me-1 fs-14">Hôm nay</span>
                )
              } else {
                setToday = ""
              }
              return (
                <div className="col-xl-12" key={index}>
                  <div className="card custom-card rounded-4 mb-3">
                    <div className="alert alert-light">
                      <div className="d-flex align-items-center w-100">
                        <div className="me-2">
                          <span className="avatar avatar-rounded">
                            <img
                              src={"https://system.view360.vn/" + item.avatar}
                              alt="img"
                            />
                          </span>
                        </div>
                        <div className="">
                          <div className="fs-15 fw-semibold">
                            <span className="me-1">{item.hoten}</span>
                            <BsDot />
                            {setToday}
                            {item.status === 0 ? (
                              dataUser[0].list_vangmat === 1 ? (
                                <button
                                  className="badge bg-warning ms-2 border-0"
                                  onClick={() =>
                                    handleAbsentAccept(item.id, index)
                                  }
                                >
                                  Chờ xử lý
                                </button>
                              ) : (
                                <span className="badge bg-warning ms-2">
                                  Chờ xử lý
                                </span>
                              )
                            ) : (
                              <span className="badge bg-success ms-2">
                                Đã phê duyệt
                              </span>
                            )}
                          </div>
                          <div className="mb-0 fs-14">
                            {activeContent !== item.id ? (
                              <span className="op-8 fw-5">
                                <TextLimit text={infoAbsent} number={39} />
                              </span>
                            ) : (
                              <div className="alert alert-primary mt-1 mb-1">
                                <div className="fw-5 fs-15">{item.title}</div>
                                <div className="border-top border-block-start-dashed mb-1 mt-1 op-3 bg-primary"></div>
                                <span className="op-9 fs-14">
                                  {item.content}
                                </span>
                                <div className="border-top border-block-start-dashed mb-1 mt-1 op-3 bg-primary"></div>
                                <div>
                                  Gửi bởi:{" "}
                                  <span className="fw-5">{item.hoten}</span>{" "}
                                  <BsDot />{" "}
                                  <span className="op-8">
                                    {moment(item.addtime).format(
                                      "DD/MM/YYYY HH:mm:ss",
                                    )}
                                  </span>
                                </div>
                              </div>
                            )}
                            {activeContent !== item.id &&
                              item.content.length >= 39 && (
                                <button
                                  className="border-0 rounded-3 bg-transparent"
                                  onClick={() =>
                                    handleActiveMoreContent(item.id)
                                  }
                                >
                                  Xem thêm
                                </button>
                              )}
                            {/* <span className="fw-4 me-1 text-muted">
                              {moment(item.addtime).locale("vi").fromNow()}
                            </span> */}
                          </div>
                          {/* <p className="mb-0 fs-14 text-muted">
                            <span className="fw-4 me-1">
                              {moment(item.addtime).locale("vi").fromNow()}
                            </span>
                          </p> */}
                        </div>
                        {/* Điều kiện user có quyền duyệt không */}
                        {/* {dataUser[0].list_vangmat === 1 && (
                          <div className="ms-auto dropdown">
                            <button
                              className="text-default border-0 bg-transparent"
                              data-bs-toggle="dropdown"
                            >
                              <HiOutlineDotsVertical className="fs-21" />
                            </button>
                            <ul className="dropdown-menu">
                              {item.status === 0 && (
                                <li
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleAbsentAccept(item.id, index)
                                  }
                                >
                                  Đồng ý
                                </li>
                              )}
                              {item.status === 1 && (
                                <li className="dropdown-item">Đã xác nhận</li>
                              )}
                            </ul>
                          </div>
                        )} */}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
}
export default ListVangmat