
import classNames from "classnames/bind"
import styles from "../../Job.module.scss";
import Tippy from "@tippyjs/react";
const cx = classNames.bind(styles);

function ListRevenueReport({ socketio, dataUser, object, dataRevenue }) {
    
    return (
      <div>
        <div className="alert alert-primary text-center fs-16">Danh sách doanh thu tháng</div>
            {dataRevenue.map((item, index) => {
              let tysuatDoanhthu =
                (Number(item.total_revenue) / object.totalRevenueMonth) * 100
              return (
                <Tippy
                  key={index}
                  content={
                    "Doanh thu " +
                    Number(item.total_revenue)
                      .toLocaleString()
                      .replace(/[.]/g, ",")
                  }
                >
                  <div
                    className="progress progress-xl progress-animate custom-progress-4 rounded-0"
                    role="progressbar"
                    aria-valuenow="10"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ borderBottom: "1px solid white" }}
                  >
                    <div
                      className={cx(
                        "progress-bar bg-warning rounded-0",
                        item.contract_name === "Công nghệ" && "bg-orange op-9",
                        item.contract_name === "Quảng cáo bất động sản" &&
                          "bg-primary op-9",
                        item.contract_name === "View 360" && "bg-orange",
                      )}
                      style={{
                        width: tysuatDoanhthu + "%",
                      }}
                    >
                      {Number.isInteger(tysuatDoanhthu)
                        ? tysuatDoanhthu
                        : tysuatDoanhthu.toFixed(2)}
                      %
                    </div>
                    <div className="progress-bar-label">
                      {tysuatDoanhthu <= 10 && (
                        <span className="me-1">
                          {Number.isInteger(tysuatDoanhthu)
                            ? tysuatDoanhthu
                            : tysuatDoanhthu.toFixed(2) + "%"}
                        </span>
                      )}
                      {item.contract_name}
                    </div>
                  </div>
                </Tippy>
              )
            })}
        
      </div>
    )
}

export default ListRevenueReport