// lưu ý: nếu fle có đuôi svg thêm .default
const images = {
    noImage: require("../../assets/images/default-avatar.jpeg"),
    noImagePro: require("./no-images.gif"),
    view360: require("./ad-view360.jpg"),
    bannerKM: require("./banner-km.png"),
    notifi: require("./loa.png"),
    bannerHeight: require("./test.png"),
    bannerPrView360: require("./banner-view.png"), 
    user: require("./user.png"),
    bgImage: require("./1785.png"),
    contract: require("./contract.png")
}

const imageLogo = {
    logo: require("./logo.png")
}

const imagePay = {
    qr: require("./qr-pay.jpg")
}

// Danh cho danh mục
const imagesCate = {
    product: require("./add-item.png"),
    calendar: require("./calendar.png"),
    customer: require("./human.png"),
    wallet: require("./wallet.png"),
    love: require("./love.png"),
    customer1: require("./customer.png"),
    datasale: require("./cate-data/document.png"),
    viewgif360: require("./viewgif360.gif"),
    view360: require("./cate-data/view-360.png"),
    realestate: require("./real-estate.png"),
    tech: require("./cate-data/tech.png"),
    street: require("./street.png"),
    khaogia: require('./like.png')
}

// Quản lý ảnh cho danh mục công việc.
const jobCate = {
    personnel: require("./cate-job/personnel.png"),
    timekeeping: require("./cate-job/face-id.png"),
    job: require("./cate-job/job.png"),
    contract: require("./cate-job/contract.png"),
    wallet: require("./cate-job/wallet-1.png"),
    report: require("./cate-job/report.png"),
    partner: require("./cate-job/partner.png"),
    investerment: require("./investment.png"),
    ketoan: require("./ketoan.png")
}

const backgroundMobile = {
    background: require('./pastel-ombre-background-pink-purple.jpg')
}

export {images, imageLogo, imagePay, imagesCate, jobCate, backgroundMobile}