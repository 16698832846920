import { useContext, useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { handleFormatPriceInput } from "../../../../handle-reuses/reuses"
import AppContext from "../../../../handle-reuses/Contexts/CreateContexts"

function ModalExtendContractView({dataExtend, checkChange }) {
  const { socketio, dataUser } = useContext(AppContext)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  // Xử lý format price.
  const [formatPrice, setFormatPrice] = useState("")
  const handleFormatPrice_ = (e) => {
    setFormatPrice(e.target.value)
    let price_ = e.target.value.replace(/[.,]/g, "")
    if (Number(price_) >= 1000) {
      let format_ = handleFormatPriceInput(price_)
      setFormatPrice(format_)
    }
  }
    useEffect(() => {
        if (Number(dataExtend[0].giadichvu) >= 1000) {
          let format_ = handleFormatPriceInput(dataExtend[0].giadichvu)
          setFormatPrice(format_)
        }
    }, [])

  const checkAddData = useRef("")
  const onSubmit = (data) => {
    data.giadichvu = data.giadichvu.replace(/[,]/g, "")
    data.tacgia = dataUser[0].email
    data.kn_user = dataUser[0].id
    data.hoahong = 10
    // Xử lý thêm mới.
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: data,
    }
    socketio.emit("client-create-data-contract-view360", params)

    checkAddData.current = data
  }

  useEffect(() => {
    socketio.on("server-create-data-contract-view360", (status_) => {
      if (status_ === "success") {
        checkChange(true)
        alert("Gia hạn hợp đồng thành công.")
      }
    })
    return () => {
      socketio.off("client-create-data-contract-view360")
      socketio.off("server-create-data-contract-view360")
    }
  }, [checkAddData.current])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row gy-2">
        <div className="col-xl-6">
          <label htmlFor="task-name" className="form-label">
            Tên công ty
          </label>
          <input
            type="text"
            defaultValue={dataExtend[0].congty}
            className="form-control"
            placeholder="Nhập tên công ty"
            {...register("congty")}
          />
        </div>
        <div className="col-xl-6">
          <label htmlFor="task-id" className="form-label">
            Họ tên
          </label>
          <input
            type="text"
            className="form-control"
            defaultValue={dataExtend[0].hoten}
            placeholder="Họ tên khách hàng"
            {...register("hoten", { required: true })}
          />
          {errors.hoten && (
            <span className="text-danger fss-12">
              *Bạn chưa nhập tên khách hàng
            </span>
          )}
        </div>
        <div className="col-xl-6">
          <label className="form-label">Email</label>
          <input
            type="text"
            defaultValue={dataExtend[0].email}
            className="form-control"
            placeholder="Email"
            {...register("email", { required: true })}
          />
          {errors.email && (
            <span className="text-danger fss-12">*Bạn chưa nhập email</span>
          )}
        </div>
        <div className="col-xl-6">
          <label htmlFor="task-id" className="form-label">
            Số điện thoại
          </label>
          <input
            type="text"
            className="form-control"
            defaultValue={dataExtend[0].sodienthoai}
            placeholder="Số điện thoại"
            {...register("sodienthoai", { required: true })}
          />
          {errors.sodienthoai && (
            <span className="text-danger fss-12">
              *Bạn chưa nhập số điện thoại
            </span>
          )}
        </div>
        <div className="col-xl-6">
          <label className="form-label">Gói dịch vụ</label>
          <select
            className="form-control"
            defaultValue={dataExtend[0].goidichvu}
            {...register("goidichvu", { required: true })}
          >
            <option value="Cơ bản">Cơ bản</option>
            <option value="Nâng cao">Nâng cao</option>
            <option value="Chuyên nghiệp">Chuyên nghiệp</option>
          </select>
          {errors.goidichvu && (
            <span className="text-danger fss-12">
              *Bạn chưa chọn gói dịch vụ
            </span>
          )}
        </div>
        <div className="col-xl-6">
          <label className="form-label">Giá dịch vụ</label>
          <input
            type="text"
            value={formatPrice}
            className="form-control"
            placeholder="Nhập giá dịch vụ"
            {...register("giadichvu", { required: true })}
            onChange={handleFormatPrice_}
          />
          {errors.giadichvu && (
            <span className="text-danger fss-12">
              *Bạn chưa nhập tên khách hàng
            </span>
          )}
        </div>
        <div className="col-xl-6">
          <label className="form-label">Ngày bắt đầu</label>
          <input
            type="datetime-local"
            className="form-control"
            placeholder="Ngày bắt đầu"
            {...register("ngaybatdau", { required: true })}
          />
          {errors.ngaybatdau && (
            <span className="text-danger fss-12">
              *Bạn chưa chọn ngày bắt đầu
            </span>
          )}
        </div>
        <div className="col-xl-6">
          <label className="form-label">Ngày kết thúc</label>
          <input
            type="datetime-local"
            className="form-control"
            placeholder="Ngày kết thúc"
            {...register("ngayketthuc", { required: true })}
          />
          {errors.ngayketthuc && (
            <span className="text-danger fss-12">
              *Bạn chưa chọn ngày kết thúc
            </span>
          )}
        </div>
        <div className="col-xl-12">
          <label className="form-label">Nội dung</label>
          <textarea
            className="form-control"
            rows="3"
            {...register("noidung")}
          ></textarea>
        </div>
      </div>
      {/* Button */}
      <div className=" mt-3 px-4 d-flex py-3 border-top border-block-start-dashed d-sm-flex justify-content-center">
        <button className="btn btn-primary-light m-1" type="submit">
          Gia hạn hợp đồng
        </button>
      </div>
    </form>
  )
}

export default ModalExtendContractView
