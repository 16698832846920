import { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import InputColor from "../../../../Components/FromColor/InputColor";

function FormAddCateChiphi({ socketio, dataUser, result }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  // Format money.
  const [formatMoney, setFormatMoney] = useState("")
  const handleFormatMoney = (e) => {
    let format = e.target.value.replace(/[., ]/g, "")
    if (Number(format)) {
      let formatMoney_ = Number(format).toLocaleString().replace(/[.]/g, ",")
      setFormatMoney(formatMoney_)
    } else {
      alert("Bạn cần nhập dạng số.")
    }
  }
  // Set mầu.
  const [color, setColor] = useState("")
  const handleInputColor = (color_) => {
    setColor(color_)
  }

  const changeCreate = useRef()
  const handleOnSubmit = (object) => {
    object.user_id = dataUser[0].id_user
    object.color = color
    object.money_limit = object.money_limit.replace(/[.,]/g, "")
    const paramsCreate = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: object,
    }
    socketio.emit("client-create-data-taichinhcanhan-cate-chi", paramsCreate)
    socketio.on("server-create-data-taichinhcanhan-cate-chi", (data) => {
      result(data)
      changeCreate.current = object
    })
  }
  useEffect(() => {
    return () => {
      socketio.off("client-create-data-taichinhcanhan-cate-chi")
      socketio.off("server-create-data-taichinhcanhan-cate-chi")
    }
  }, [changeCreate.current])
  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <div className="col-xl-12 col-md-12">
        <div className="row">
          <div className="form-floating mb-3 col-10">
            <input
              type="text"
              className="form-control rounded-3"
              placeholder="Tên input"
              {...register("title", { required: true })}
              style={{ color: color, fontSize: "18px" }}
            />
            <label className="fs-15 ms-2 text-muted">Tên danh mục</label>
            {errors.title && (
              <span className="text-danger fs-12">
                *Bạn cần nhập tên danh mục
              </span>
            )}
          </div>
          <div className="mb-3 col-2 d-flex align-items-center flex-direction-column p-0">
            <InputColor result={handleInputColor} />
            <span className="fs-12">Chọn mầu</span>
          </div>
          <div className="form-floating mb-3 col-12">
            <input
              type="text"
              className="form-control rounded-3"
              placeholder="Tên input"
              {...register("money_limit", { required: true })}
              value={formatMoney}
              onChange={handleFormatMoney}
            />
            <label className="fs-15 ms-2 text-muted">Hạn mức chi (vnđ)</label>
            {errors.money_limit && (
              <span className="text-danger fs-12">
                *Bạn cần nhập số tiền tối đa được chi.
              </span>
            )}
          </div>
        </div>
      </div>

      {/* Button */}
      <div className="px-4 py-3 border-top border-block-start-dashed d-flex justify-content-center">
        <button className="btn btn-primary-light m-1" type="submit">
          Thêm mới
        </button>
      </div>
    </form>
  )
}
export default FormAddCateChiphi

// Form edit.
export function FormEditCateChiphi({ socketio, dataUser, dataEdit, result }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  // Format money.
  const [formatMoney, setFormatMoney] = useState(
    Number(dataEdit.money_limit).toLocaleString().replace(/[.]/g, ","),
  )
  const handleFormatMoney = (e) => {
    setFormatMoney(e.target.value)
    let format = e.target.value.replace(/[., ]/g, "")
    if (Number(format)) {
      let formatMoney_ = Number(format).toLocaleString().replace(/[.]/g, ",")
      setFormatMoney(formatMoney_)
    } else {
      alert("Bạn cần nhập dạng số.")
    }
  }

  // Set mầu.
  const [color, setColor] = useState(dataEdit.color)
  const handleInputColor = (color_) => {
    setColor(color_)
  }

  const changeEdit = useRef()
  const handleOnSubmit = (object) => {
    object.id = dataEdit.id
    object.user_id = dataUser[0].id_user
    object.color = color
    object.money_limit = object.money_limit.replace(/[.,]/g, "")
    const paramsEdit = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: object,
    }
    socketio.emit("client-edit-data-taichinhcanhan-cate-chi", paramsEdit)
    socketio.on("server-edit-data-taichinhcanhan-cate-chi", (data) => {
      result({
        status: true,
        dataUpdate: data,
      })
      changeEdit.current = object
    })
  }
  useEffect(() => {
    return () => {
      socketio.off("client-edit-data-taichinhcanhan-cate-chi")
      socketio.off("server-edit-data-taichinhcanhan-cate-chi")
    }
  }, [changeEdit.current])
  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <div className="col-xl-12 col-md-12">
        <div className="row">
          <div className="form-floating mb-3 col-10">
            <input
              type="text"
              className="form-control rounded-3"
              placeholder="Tên input"
              {...register("title", { required: true })}
              defaultValue={dataEdit.title}
              style={{
                color: color ? color : dataEdit.color,
                fontSize: "18px",
              }}
            />
            <label className="fs-15 ms-2 text-muted">Tên danh mục</label>
            {errors.title && (
              <span className="text-danger fs-12">
                *Bạn cần nhập tên danh mục
              </span>
            )}
          </div>
          <div className="mb-3 col-2 d-flex align-items-center flex-direction-column p-0">
            <InputColor
              result={handleInputColor}
              colorDefault={dataEdit.color}
            />
            <span className="fs-12">Chọn mầu</span>
          </div>
          <div className="form-floating mb-3 col-12">
            <input
              type="text"
              className="form-control rounded-3"
              placeholder="Tên input"
              {...register("money_limit", { required: true })}
              value={formatMoney}
              onChange={handleFormatMoney}
            />
            <label className="fs-15 ms-2 text-muted">Hạn mức chi (vnđ)</label>
            {errors.money_limit && (
              <span className="text-danger fs-12">
                *Bạn cần nhập số tiền tối đa được chi.
              </span>
            )}
          </div>
        </div>
      </div>

      {/* Button */}
      <div className="px-4 py-3 border-top border-block-start-dashed d-flex justify-content-center">
        <button className="btn btn-primary-light m-1" type="submit">
          Lưu lại
        </button>
      </div>
    </form>
  )
}
