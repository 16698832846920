import moment from "moment"
import { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"

function FormAddGiavang({ socketio, dataUser, result }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  // Format money.
    const [formatMoneyGiaban, setFormatMoneyGiaban] = useState("")
    const [formatMoneyGiamua, setFormatMoneyGiamua] = useState("")
  const handleFormatMoneyGiaban = (e) => {
    let format = e.target.value.replace(/[., ]/g, "")
    if (Number(format)) {
      let formatMoney_ = Number(format).toLocaleString().replace(/[.]/g, ",")
      setFormatMoneyGiaban(formatMoney_)
    } else {
      alert("Bạn cần nhập dạng số.")
    }
  }
    const handleFormatMoneyGiamua = (e) => {
      let format = e.target.value.replace(/[., ]/g, "")
      if (Number(format)) {
        let formatMoney_ = Number(format).toLocaleString().replace(/[.]/g, ",")
        setFormatMoneyGiamua(formatMoney_)
      } else {
        alert("Bạn cần nhập dạng số.")
      }
    }

    // xu ly submit.
    const changeCreate = useRef()
    const handleOnSubmit = (object) => {
        object.giaban = object.giaban.replace(/[., ]/g, "")
      object.giamua = object.giamua.replace(/[., ]/g, "")
      object.user_id = dataUser[0].id_user
        // Xử lý thêm mới 
        let params = {
            iden: process.env.REACT_APP_IDEN_DASH,
            object: object
        };
        socketio.emit("client-create-follow-giavang", params);
        socketio.on("server-create-follow-giavang", (data) => {
            alert("Thêm mới thành công.")
            result({
                status: true,
                dataUpdate: data
            });
            changeCreate.current = object
        })
    };
    useEffect(() => {
        return () => {
            socketio.off("client-create-follow-giavang")
            socketio.off("server-create-follow-giavang")
        }
    }, [changeCreate.current]);

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <div className="col-xl-12 col-md-12">
        <div className="row">
          <div className="form-floating mb-3 col-6">
            <input
              type="datetime-local"
              className="form-control rounded-3"
              placeholder="Tên input"
              {...register("ngaythang", { required: true })}
              defaultValue={moment().format("YYYY-MM-DD HH:mm")}
            />
            <label className="fs-15 ms-2 text-muted">Thời gian *</label>
            {errors.ngaythang && (
              <span className="text-danger fs-12">*Bạn chọn thời gian</span>
            )}
          </div>
          <div className="form-floating mb-3 col-6">
            <input
              type="text"
              className="form-control rounded-3"
              placeholder="Tên input"
              {...register("loaivang", { required: true })}
              defaultValue="PNJ HN"
            />
            <label className="fs-15 ms-2 text-muted">Loại vàng *</label>
            {errors.loaivang && (
              <span className="text-danger fs-12">*Bạn cần nhập loại vàng</span>
            )}
          </div>
          <div className="form-floating mb-3 col-6">
            <input
              type="text"
              className="form-control rounded-3"
              placeholder="Tên input"
              {...register("giaban", { required: true })}
              value={formatMoneyGiaban}
              onChange={handleFormatMoneyGiaban}
            />
            <label className="fs-15 ms-2 text-muted">Giá bán *</label>
            {errors.giaban && (
              <span className="text-danger fs-12">*Bạn cần nhập giá bán</span>
            )}
          </div>
          <div className="form-floating mb-3 col-6">
            <input
              type="text"
              className="form-control rounded-3"
              placeholder="Tên input"
              {...register("giamua", { required: true })}
              value={formatMoneyGiamua}
              onChange={handleFormatMoneyGiamua}
            />
            <label className="fs-15 ms-2 text-muted">Giá mua *</label>
            {errors.giamua && (
              <span className="text-danger fs-12">*Bạn cần nhập giá mua</span>
            )}
          </div>
        </div>
      </div>

      {/* Button */}
      <div className="px-4 py-3 border-top border-block-start-dashed d-flex justify-content-center">
        <button className="btn btn-primary-light m-1" type="submit">
          Thêm mới
        </button>
      </div>
    </form>
  )
}
export default FormAddGiavang


export function FormEditGiavang({ socketio, dataUser, dataEdit, result }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  // Format money.
  const [formatMoneyGiaban, setFormatMoneyGiaban] = useState(Number(dataEdit.giaban).toLocaleString().replace(/[.]/g, ","))
  const [formatMoneyGiamua, setFormatMoneyGiamua] = useState(Number(dataEdit.giamua).toLocaleString().replace(/[.]/g, ","))
  const handleFormatMoneyGiaban = (e) => {
    let format = e.target.value.replace(/[., ]/g, "")
    if (Number(format)) {
      let formatMoney_ = Number(format).toLocaleString().replace(/[.]/g, ",")
      setFormatMoneyGiaban(formatMoney_)
    } else {
      alert("Bạn cần nhập dạng số.")
    }
  }
  const handleFormatMoneyGiamua = (e) => {
    let format = e.target.value.replace(/[., ]/g, "")
    if (Number(format)) {
      let formatMoney_ = Number(format).toLocaleString().replace(/[.]/g, ",")
      setFormatMoneyGiamua(formatMoney_)
    } else {
      alert("Bạn cần nhập dạng số.")
    }
  }

  // xu ly submit.
  const changeCreate = useRef()
  const handleOnSubmit = (object) => {
    object.giaban = object.giaban.replace(/[., ]/g, "")
      object.giamua = object.giamua.replace(/[., ]/g, "")
      object.id_giavang = dataEdit.id_giavang
    // Xử lý edit
    let params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: object,
    }
    socketio.emit("client-edit-follow-giavang", params)
    socketio.on("server-edit-follow-giavang", (data) => {
      alert("Sửa thông tin thành công.")
      result({
        status: true,
        dataUpdate: data,
      })
      changeCreate.current = object
    })
  }
  useEffect(() => {
    return () => {
      socketio.off("client-edit-follow-giavang")
      socketio.off("server-edit-follow-giavang")
    }
  }, [changeCreate.current])

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <div className="col-xl-12 col-md-12">
        <div className="row">
          <div className="form-floating mb-3 col-6">
            <input
              type="datetime-local"
              className="form-control rounded-3"
              placeholder="Tên input"
              {...register("ngaythang", { required: true })}
              defaultValue={moment(dataEdit.ngaythang).format("YYYY-MM-DD HH:mm")}
            />
            <label className="fs-15 ms-2 text-muted">Thời gian *</label>
            {errors.ngaythang && (
              <span className="text-danger fs-12">*Bạn chọn thời gian</span>
            )}
          </div>
          <div className="form-floating mb-3 col-6">
            <input
              type="text"
              className="form-control rounded-3"
              placeholder="Tên input"
              {...register("loaivang", { required: true })}
              defaultValue={dataEdit.loaivang}
            />
            <label className="fs-15 ms-2 text-muted">Loại vàng *</label>
            {errors.loaivang && (
              <span className="text-danger fs-12">*Bạn cần nhập loại vàng</span>
            )}
          </div>
          <div className="form-floating mb-3 col-6">
            <input
              type="text"
              className="form-control rounded-3"
              placeholder="Tên input"
              {...register("giaban", { required: true })}
              value={formatMoneyGiaban}
              onChange={handleFormatMoneyGiaban}
            />
            <label className="fs-15 ms-2 text-muted">Giá bán *</label>
            {errors.giaban && (
              <span className="text-danger fs-12">*Bạn cần nhập giá bán</span>
            )}
          </div>
          <div className="form-floating mb-3 col-6">
            <input
              type="text"
              className="form-control rounded-3"
              placeholder="Tên input"
              {...register("giamua", { required: true })}
              value={formatMoneyGiamua}
              onChange={handleFormatMoneyGiamua}
            />
            <label className="fs-15 ms-2 text-muted">Giá mua *</label>
            {errors.giamua && (
              <span className="text-danger fs-12">*Bạn cần nhập giá mua</span>
            )}
          </div>
        </div>
      </div>

      {/* Button */}
      <div className="px-4 py-3 border-top border-block-start-dashed d-flex justify-content-center">
        <button className="btn btn-primary-light m-1" type="submit">
          Lưu lại
        </button>
      </div>
    </form>
  )
}