import { useContext, useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import AppContext from "../../../../handle-reuses/Contexts/CreateContexts"
import LoadingSpinner from "../../../../Components/Loading/LoadingSpinner"
import moment from "moment"

function FormAddChiphi({result}) {
    const {socketio, dataUser} = useContext(AppContext)
    const { register, handleSubmit, formState: { errors }, reset } = useForm()
    const [loading, setLoading] = useState(true)
    

    // Quản lý Gợi ý
    const [optionBophan, setOptionBophan] = useState([])
    const [optionLoaichiphi, setOptionLoaichiphi] = useState([])
    useEffect(() => {
        const params = {
            iden: process.env.REACT_APP_IDEN_DASH
        }
        // Option Bộ phận.
        socketio.emit("client-get-data-job-bophan", params)
        socketio.on("server-get-data-job-bophan", (data) => {
            setOptionBophan([...data])
        });

        // option loaichiphi.
        socketio.emit("client-get-data-ketoan-loaichiphi", params)
        socketio.on("server-get-data-ketoan-loaichiphi", (data) => {
            setOptionLoaichiphi([...data])
            setLoading(false)
        })

        return () => {
            socketio.off("client-get-data-job-bophan")
            socketio.off("server-get-data-job-bophan")

            socketio.off("client-get-data-ketoan-loaichiphi")
            socketio.off("server-get-data-ketoan-loaichiphi")
        }
    }, [])

    // Quản lý format money.
    const [formatMoney, setFormatMoney] = useState("")
    const handleFormatMoney = (e) => {
        setFormatMoney(e.target.value)
        let format = e.target.value.replace(/[.,]/g, '')
        let formatNumber = Number(format)
        
        if (!Number.isInteger(formatNumber)) {
          alert("Bạn cần nhập dạng số")
          setFormatMoney("")
        }
        if (formatNumber >= 1000) {
          let formatText = formatNumber.toLocaleString().replace(/[.]/g, ",")
          setFormatMoney(formatText)
        }
    }
     

    // Quản lý button.
    const checkObject = useRef()
    const handleOnSubmit = (object) => {
        let filterNhomchiphi = optionLoaichiphi.find((item) => item.loai_chiphi === object.loai_chiphi);
        object.nhom_chiphi = filterNhomchiphi.nhom_chiphi
        object.money = Number(object.money.replace(/[.,]/g, ""))
        object.nhanvien = dataUser[0].id_user
        if (!object.addtime) {
           delete object.addtime
        }
        const paramsCreate = {
            iden: process.env.REACT_APP_IDEN_DASH,
            object: object
        }
        socketio.emit("client-create-data-ketoan-chiphi", paramsCreate)
        socketio.on("server-create-data-ketoan-chiphi", (data) => {
            if (data.status === "success") {
                alert("Bạn đã gửi yêu cầu thành công. Cần chờ bộ phận kế toán duyệt.")
                result({
                    status: true,
                    dataUpdate: data.dataUpdate
                });
                checkObject.current = object
                reset()
            }
        })
    }
    useEffect(() => {
        return () => {
            socketio.off("client-create-data-ketoan-chiphi")
            socketio.off("server-create-data-ketoan-chiphi")
        }
    }, [checkObject.current])

    if (loading) {
        return <LoadingSpinner />
    }

    return (
        <form onSubmit={handleSubmit(handleOnSubmit)}>
            <div className="col-xl-12 col-md-12">
                <div className="row">
                    <div className="form-floating mb-3 col-12 rounded-3">
                        <select className="form-select rounded-3" {...register("bophan", {required: true})}>
                            <option value="">Chọn bộ phận</option>
                            {optionBophan.length > 0 && (
                                optionBophan.map((item, index) => {
                                    return <option value={item.title} key={index}>{item.title}</option>
                                })
                            )}
                        </select>
                        <label className="fs-15 ms-2 text-muted">Bộ phận <span className="text-danger">*</span></label>
                        {errors.bophan && (
                            <span className="text-danger fs-14">
                                Bạn cần chọn bộ phận xin cấp chi phí.
                            </span>
                        )}
                    </div>
                    <div className="form-floating mb-3 col-12">
                        <select
                            className="form-select rounded-3"
                            {...register("loai_chiphi", {required: true})}
                        >
                            <option value="">Chọn loại chi phí</option>
                            {optionLoaichiphi.length > 0 && (
                                optionLoaichiphi.map((item, index) => {
                                    return (
                                        <option value={item.loai_chiphi} key={index}>
                                            {item.loai_chiphi}
                                        </option>
                                    )
                                })
                            )}
                        </select>
                        <label className="fs-15 ms-2 text-muted">Loại chi phí <span className="text-danger">*</span></label>
                        {errors.loai_chiphi && (
                            <span className="text-danger fs-14">
                                Bạn cần chọn loại chi phí xin cấp.
                            </span>
                        )}
                    </div>
                </div>

                {/* số tiền */}
                <div className="col-xl-12 col-md-12">
                    <div className="row">
                        <div className="form-floating mb-3 col-12">
                            <input
                                type="text"
                                className="form-control rounded-3"
                                placeholder="Tên input"
                                {...register("money", {required: true})}
                                value={formatMoney}
                                onChange={handleFormatMoney}
                            />
                            <label className="fs-15 ms-2 text-muted">Số tiền <span className="text-danger">*</span></label>
                            {errors.money && (
                                <span className="text-danger fs-14">
                                    Bạn cần nhập số tiền xin được cấp.
                                </span>
                            )}
                        </div>
                        <div className="form-floating mb-3 col-12">
                            <input
                                type="datetime-local"
                                className="form-control rounded-3"
                                placeholder="Tên input"
                                {...register("addtime")}
                                defaultValue={moment().format("YYYY-MM-DD HH:mm")}
                            />
                            <label className="fs-15 ms-2 text-muted">Thời gian</label>
                        </div>
                    </div>
                </div>

                {/* Ghi chú */}
                <div className="form-floating mb-4 mt-3">
                    <textarea
                        className="form-control rounded-3"
                        placeholder="Nhập ghi chú"
                        style={{ height: "100px" }}
                        {...register("noidung_chiphi", {required: true})}
                    ></textarea>
                    <label className="fs-15 text-muted">
                        Nội dung xin cấp chi phí <span className="text-danger">*</span>
                    </label>
                    {errors.noidung_chiphi && (
                        <span className="text-danger fs-14">
                            Bạn cần viết nội dung xin cấp vào việc gì.
                        </span>
                    )}
                </div>
                {/* Button */}
                <div className="px-4 py-3 border-top border-block-start-dashed d-flex justify-content-center">
                    <button
                        className="btn btn-secondary-gradient btn-wave waves-effect waves-light"
                        type="submit"
                    >
                        Gửi yêu cầu
                    </button>
                </div>
            </div>
        </form>
    );
}

export default FormAddChiphi