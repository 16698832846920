import Tippy from "@tippyjs/react"
import { useEffect, useRef, useState } from "react"
import { BsDot } from "react-icons/bs"
import ModalReact from "../../../../../Components/Modal/ModalReact"
import PieChart from "../../../../../Components/VongTySuat/PieChart"
import { TbArrowBigDownLinesFilled, TbArrowBigUpLinesFilled } from "react-icons/tb"
import PieCharts from "../../../../../Components/Chart/PieChart"
import LoadingSpinner from "../../../../../Components/Loading/LoadingSpinner"
import { handleTotalCardWallet } from "../reuse_taichinhcanha"

function ListPhantich({
  socketio,
  dataUser,
  objectSearch,
  dataUpdateCreate,
  totalThunhapAll,
  totalPhanboAll,
  changeStatus,
  dataCateThunhap,
  dataCateExpense,
}) {
  const [dataPhanbo, setDataPhanbo] = useState([])
  const [dataColor, setDataColor] = useState([])
  const [dataChart, setDataChart] = useState([])
  const [dataLabel, setDataLabel] = useState([])
  const [dataGiavang, setDataGiavang] = useState()
  const [totalChiphi, setTotalChiphi] = useState(0)
  const [loading, setLoading] = useState(true)
  // Set ty suat phan bo & ty suat con lai.
  const [tysuatPhanbo, setTysuatPhanbo] = useState(0)
  const [tysuatConlai, setTysuatConlai] = useState(0)
  useEffect(() => {
    if (totalThunhapAll > 0) {
      let tysuatPhanbo_ = (totalPhanboAll / (totalThunhapAll - totalChiphi)) * 100
      let tysuatConlai_ =
        (((totalThunhapAll - totalChiphi) - totalPhanboAll) / (totalThunhapAll - totalChiphi)) * 100
      setTysuatPhanbo(tysuatPhanbo_)
      setTysuatConlai(tysuatConlai_)
    }
  }, [dataUpdateCreate, totalThunhapAll, totalPhanboAll, totalChiphi])

  // Danh sach phan bo dong tien.
  useEffect(() => {
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: { user_id: dataUser[0].id_user },
    }
    socketio.emit(
      "client-get-data-total-money-for-cate-dongtien-taichinhcanhan-dongtien",
      params,
    )
    socketio.on(
      "server-get-data-total-money-for-cate-dongtien-taichinhcanhan-dongtien",
      (data) => {
        setDataPhanbo([...data])
        let dataColor_ = data.map((item) => (item.color ? item.color : "blue"))
        let dataChart_ = data.map((item) => item.total_money)
        let dataLabel_ = data.map((item) => item.title)
        setDataColor(dataColor_)
        setDataChart(dataChart_)
        setDataLabel(dataLabel_)
      },
    )

    // Quan ly trhi truong gia vang.
    // Get data gia vang gan nhat.
    socketio.emit("client-get-month-recently-follow-giavang", params)
    socketio.on("server-get-month-recently-follow-giavang", (data) => {
      let objectGiavang = data.find((item) => item)
      setDataGiavang(objectGiavang)
    })

    return () => {
      socketio.off(
        "client-get-data-total-money-for-cate-dongtien-taichinhcanhan-dongtien",
      )
      socketio.off(
        "server-get-data-total-money-for-cate-dongtien-taichinhcanhan-dongtien",
      )

      socketio.off("client-get-month-recently-follow-giavang")
      socketio.off("server-get-month-recently-follow-giavang")
    }
  }, [
    socketio,
    dataUser,
    dataUpdateCreate,
    changeStatus,
    totalThunhapAll,
    totalPhanboAll,
  ])

  // Quanr ly total chi phi.
  useEffect(() => {
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        user_id: dataUser[0].id_user
      }
    }
    socketio.emit("client-total-chiphi-all-taichinhcanhan-chiphi", params)
    socketio.on("server-total-chiphi-all-taichinhcanhan-chiphi", (data) => {
      if (data.length > 0) {
        let total = data[0].card_payment + data[0].wallet_payment
        setTotalChiphi(total)
      }
      setLoading(false)
    });
    return () => {
       socketio.off("client-total-chiphi-all-taichinhcanhan-chiphi")
       socketio.off("server-total-chiphi-all-taichinhcanhan-chiphi")
     }
  },[totalThunhapAll, totalPhanboAll, changeStatus, dataUpdateCreate])

  

  if (loading) {
    return <LoadingSpinner />
  }
  if (dataPhanbo.length === 0) {
    return (
      <div
        className="text-center fs-15 rounded-4"
        style={{
          background: "white",
          height: "calc(100vh - 12rem)",
          overflow: "auto",
        }}
      >
        <div className="mt-3">Chưa có dữ liệu để thống kê.</div>
      </div>
    )
  }
  const object2 = {
    sizeChart: 55,
    colorMain: "#007afe",
    color: "#efefef",
    rate: 100,
  }
  const objectConlai = {
    sizeChart: 55,
    colorMain: "#957ae3",
    color: "#efefef",
    rate: Number.isInteger(tysuatConlai)
      ? tysuatConlai
      : tysuatConlai.toFixed(2),
  }

  const objectPhanbo = {
    sizeChart: 55,
    colorMain: "#12c2c2",
    color: "#efefef",
    rate: Number.isInteger(tysuatPhanbo)
      ? tysuatPhanbo
      : tysuatPhanbo.toFixed(2),
  }
  const backgroundPieChart = dataColor
  const dataPieChart = dataChart
  const labelText = dataLabel
  return (
    <div>
      <div className="wrapper_ground gap-2">
        <div className="wrapper_ground col-12 col-xl-4 list_items">
          <div className="list_items">
            <div className="d-flex align-items-top">
              <div className="me-1">
                <Tippy content="Tổng tài sản">
                  <div className="me-2 lh-1">
                    <PieChart data={object2} />
                  </div>
                </Tippy>
              </div>
              <div className="fs-15">
                <span className="d-block mb-1 text-muted">Tổng tài sản</span>
                <h6 className="fw-semibold mb-0">
                  {(Number(totalThunhapAll) - totalChiphi)
                    .toLocaleString()
                    .replace(/[.]/g, ",")}
                </h6>
              </div>
            </div>
          </div>
          <div className="list_items">
            <div className="d-flex align-items-top">
              <div className="me-1">
                <Tippy content="Đã phân bổ">
                  <div className="me-2 lh-1">
                    <PieChart data={objectPhanbo} />
                  </div>
                </Tippy>
              </div>
              <div className="fs-15">
                <span className="d-block mb-1 text-muted">Đã phân bổ</span>
                <h6 className="fw-semibold mb-0">
                  {Number(totalPhanboAll).toLocaleString().replace(/[.]/g, ",")}
                </h6>
              </div>
            </div>
          </div>
          <div className="list_items">
            <div className="d-flex align-items-top">
              <div className="me-1">
                <Tippy content="Còn lại">
                  <div className="me-2 lh-1">
                    <PieChart data={objectConlai} />
                  </div>
                </Tippy>
              </div>
              <div className="fs-15">
                <span className="d-block mb-1 text-muted">Còn lại</span>
                <h6 className="fw-semibold mb-0">
                  {((Number(totalThunhapAll) - totalChiphi) - Number(totalPhanboAll))
                    .toLocaleString()
                    .replace(/[.]/g, ",")}
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-xl-5 list_items">
          <PieCharts
            dataPieChart={dataPieChart}
            backgroundPieChart={backgroundPieChart}
            labelText={labelText}
          />
        </div>
      </div>

      {/* Danh sách phân bổ */}
      <div className="col-12 col-xl-12">
        {dataPhanbo.map((item, index) => {
          // set ty suat.
          let tysuat =
            (Number(item.total_money) / (Number(totalThunhapAll) - totalChiphi)) * 100
          // Set gia vang.
          let giavang = dataGiavang
          let otherThunhap = 0
          if (item.statistical === "Giá vàng") {
            if (giavang) {
              let giavangTrenChi = Number(giavang.giaban) / 10
              let moneyChiVang = Number(item.total_quantity) * giavangTrenChi
              giavang =
                ((moneyChiVang - Number(item.total_money)) /
                  Number(item.total_money)) *
                100
            } else {
              giavang = 0
            }
          } else {
            giavang = ""
            // Set truờng hợp không phải giá vàng.
            let itemThunhap = [],
              itemChiphi = []
            if (item.statistical) {
              itemThunhap = dataCateThunhap.filter(
                (value) =>
                  value.title.toLowerCase() === item.statistical.toLowerCase(),
              )
              itemChiphi = dataCateExpense.filter(
                (value) =>
                  value.title.toLowerCase() === item.statistical.toLowerCase(),
              )
            }
            let totalChiphi = 0
            if (itemChiphi.length > 0) {
              totalChiphi = handleTotalCardWallet(itemChiphi)
            }
            if (itemThunhap.length > 0) {
              let totalThunhap = handleTotalCardWallet(itemThunhap)
              let totalLoinhuan = totalThunhap - totalChiphi
              otherThunhap =
                ((totalLoinhuan - Number(item.total_money)) /
                  Number(item.total_money)) *
                100
            } else {
              otherThunhap = ""
            }
          }
          return (
            <div key={index}>
              <div className="fw-normal d-flex align-items-center mb-1 mt-3 fs-16">
                <p
                  className="mb-0 flex-fill"
                  style={{ color: item.color ? item.color : "blue" }}
                >
                  {item.title}
                </p>
                {/* mức tăng trưởng của giá vàng */}
                {giavang > 0 && (
                  <p className="me-1 fs-13 text-success mb-0">
                    {Number.isInteger(giavang) ? giavang : giavang.toFixed(2)}
                    %
                    <TbArrowBigUpLinesFilled />
                  </p>
                )}

                {giavang < 0 && (
                  <p className="me-1 fs-13 text-danger mb-0">
                    {Number.isInteger(giavang) ? giavang : giavang.toFixed(2)}
                    %
                    <TbArrowBigDownLinesFilled />
                  </p>
                )}
                {/* Mức tăng trưởng so với thu nhập */}
                {otherThunhap > 0 && (
                  <p className="me-1 fs-13 text-success mb-0">
                    {Number.isInteger(otherThunhap)
                      ? otherThunhap
                      : otherThunhap.toFixed(2)}
                    %
                    <TbArrowBigUpLinesFilled />
                  </p>
                )}
                {otherThunhap < 0 && (
                  <p className="me-1 fs-13 text-danger mb-0">
                    {Number.isInteger(otherThunhap)
                      ? otherThunhap
                      : otherThunhap.toFixed(2)}
                    %
                    <TbArrowBigDownLinesFilled />
                  </p>
                )}
                <span className="">
                  {Number(item.total_money)
                    .toLocaleString()
                    .replace(/[.]/g, ",")}
                </span>
              </div>
              {/* Thanh ty suat */}
              <div className="progress progress-xs progress-animate">
                <div
                  className="progress-bar progress-bar-striped"
                  role="progressbar"
                  style={{
                    width: tysuat + "%",
                    background: item.color ? item.color : "blue",
                  }}
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              {/* Set ty le */}
              <div className="d-flex align-items-center mb-4 mt-1">
                <p
                  className="mb-0 flex-fill"
                  style={{ color: item.color ? item.color : "blue" }}
                >
                  <span className="text-muted fs-14">Chiếm</span>
                  <span className="fw-5 ms-1">
                    {Number.isInteger(tysuat) ? tysuat : tysuat.toFixed(2)}%
                  </span>
                  <span className="text-muted ms-1 fs-14">Tài sản</span>
                </p>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
export default ListPhantich

// Chi tiết.
function DetailItem({ dataDetail }) {
  const object1 = {
    sizeChart: 55,
    colorMain: "#12c2c2",
    color: "#efefef",
    rate: 30,
  }
  const object2 = {
    sizeChart: 55,
    colorMain: "#12c2c2",
    color: "#efefef",
    rate: 44.6,
  }
  return (
    <div>
      <div>
        <div className="wrapper_ground">
          <div className="list_items">
            <div className="d-flex align-items-top">
              <div className="me-2">
                <Tippy content="Tỷ suất phân bổ tiền">
                  <div className="lh-1">
                    <PieChart data={object1} />
                  </div>
                </Tippy>
              </div>
              <div>
                <span className="d-block mb-1 text-muted">Vốn đầu tư</span>
                <h6 className="fw-semibold mb-0">27,100,000</h6>
              </div>
            </div>
          </div>
          <div className="list_items">
            <div className="d-flex align-items-top">
              <div className="me-2">
                <Tippy content="Tỷ suất lợi nhuận">
                  <div className="lh-1">
                    <PieChart data={object2} />
                  </div>
                </Tippy>
              </div>
              <div>
                <span className="d-block mb-1 text-muted">Lợi nhuận</span>
                <h6 className="fw-semibold mb-0">12,000,000</h6>
              </div>
            </div>
          </div>
          <div className="list_items">
            <div className="d-flex align-items-top">
              <div className="me-2">
                <Tippy content="Tỷ suất tăng thêm">
                  <div className="lh-1">
                    <PieChart data={object1} />
                  </div>
                </Tippy>
              </div>
              <div>
                <span className="d-block mb-1 text-muted">Thành tiền</span>
                <h6 className="fw-semibold mb-0">39,100,000</h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ul className="list-group mt-3">
        <li className="list-group-item d-flex justify-content-between align-items-center fs-16 ">
          Số lượng
          <span className="text-muted">5 chỉ</span>
        </li>
        <li className="list-group-item d-flex justify-content-between align-items-center fs-16 ">
          Bắt đầu
          <span className="text-muted">21/09/2022</span>
        </li>
        <li className="list-group-item d-flex justify-content-between align-items-center fs-16">
          Kết thúc
          <span className="text-muted">21/09/2035</span>
        </li>
        <li className="list-group-item d-flex justify-content-between align-items-center fs-16">
          Còn lại
          <span className="text-muted">11 năm</span>
        </li>
        <li className="list-group-item d-flex justify-content-between align-items-center fs-16">
          Tăng trưởng
          <span className="text-success">
            +44.6% <TbArrowBigUpLinesFilled />
          </span>
        </li>
        <li className="list-group-item d-flex justify-content-between align-items-center fs-16">
          Đánh giá
          <span className="text-warning">5 sao</span>
        </li>
        <li className="list-group-item d-flex justify-content-between align-items-center fs-16">
          Ghi chú
          <span className="text-muted">Đầu tư cho quỹ lương hưu</span>
        </li>
      </ul>
    </div>
  )
}
