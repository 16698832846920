import classNames from "classnames/bind"
import styles from "../../Job.module.scss"
import { useEffect, useRef, useState } from "react"
const cx = classNames.bind(styles)

function DetailPhieuluong({ object }) {
    // Set tổng lương.
    const totalSalary = useRef(0)
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        totalSalary.current =
          Number(object.luong_coban) +
          Number(object.tien_chuyencan) +
          Number(object.tien_trachnhiem) +
          Number(object.tien_trocap) +
          Number(object.luong_tangca) +
          Number(object.luong_doanhthu) +
          Number(object.luong_thitruong) +
          Number(object.luong_khac) -
          Number(object.giamtru_dimuon) -
          Number(object.giamtru_vesom) -
          Number(object.giamtru_vangmat) -
        Number(object.giamtru_tiendocongviec)
        
        setLoading(false)
    }, [])

    if (loading) {
        return
    }
    return (
      <>
        <div className="text-center">
          <span className="avatar avatar-xl avatar-rounded">
            <img src={"https://system.view360.vn/" + object.avatar} alt="img" />
          </span>
          <h6>PHIẾU LƯƠNG NHÂN VIÊN</h6>
          <p>Tháng 07 năm 2024</p>
        </div>
        <div className="d-flex justify-content-between">
          <div>
            <span className="d-flex gap-3 justify-content-between">
              <span className="fw-5">Số điện thoại:</span>
              <span>{object.sodienthoai}</span>
            </span>
            <span className="d-flex gap-3 justify-content-between">
              <span className="fw-5">Chức vụ:</span>
              <span>{object.chucvu}</span>
            </span>
          </div>
          <div>
            <span className="d-flex gap-3 justify-content-between">
              <span className="fw-5">Họ tên:</span>
              <span>{object.hoten}</span>
            </span>
            <span className="d-flex gap-3 justify-content-between">
              <span className="fw-5">Phòng ban:</span>
              <span>{object.bophan}</span>
            </span>
          </div>
        </div>
        <div className="mb-3 mt-3 border-top border-block-start-dashed"></div>
        {/* Thông tin tính lương */}
        <div className="mt-3">
          <div>
            <div className="d-flex justify-content-between">
              <h6 className="fs-14" style={{ textTransform: "uppercase" }}>
                1. Các khoản thu nhập chính
              </h6>
              <span className="fw-5">Đơn vị: vnđ</span>
            </div>
            <div className="table-responsive">
              <table className="table text-nowrap table-bordered border-primary">
                <tbody>
                  <tr>
                    <td className={cx("col-6", "border-s")}>Lương cơ bản</td>
                    <td className={cx("text-end col-6 fw-5", "border-s")}>
                      {Number(object.luong_coban)
                        .toLocaleString()
                        .replace(/[.]/g, ",")}
                    </td>
                  </tr>
                  <tr>
                    <td className={cx("col-6", "border-s")}>Chuyên cần</td>
                    <td className={cx("text-end col-6 fw-5", "border-s")}>
                      <span className="me-1">+</span>
                      {Number(object.tien_chuyencan)
                        .toLocaleString()
                        .replace(/[.]/g, ",")}
                    </td>
                  </tr>
                  <tr>
                    <td className={cx("col-6", "border-s")}>Trách nhiệm</td>
                    <td className={cx("text-end col-6 fw-5", "border-s")}>
                      <span className="me-1">+</span>
                      {Number(object.tien_trachnhiem)
                        .toLocaleString()
                        .replace(/[.]/g, ",")}
                    </td>
                  </tr>
                  <tr>
                    <td className={cx("col-6", "border-s")}>Trợ cấp</td>
                    <td className={cx("text-end col-6 fw-5", "border-s")}>
                      <span className="me-1">+</span>
                      {object.tien_trocap.toLocaleString().replace(/[.]/g, ",")}
                    </td>
                  </tr>
                  <tr>
                    <td className={cx("col-6", "border-s")}>Tiền thêm giờ</td>
                    <td className={cx("text-end col-6 fw-5", "border-s")}>
                      <span className="me-1">+</span>
                      {Number(object.luong_tangca)
                        .toLocaleString()
                        .replace(/[.]/g, ",")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="mt-3">
            <div className="d-flex justify-content-between">
              <h6 className="fs-14" style={{ textTransform: "uppercase" }}>
                2. Các khoản thu nhập khác
              </h6>
            </div>
            <div className="table-responsive">
              <table className="table text-nowrap table-bordered border-primary">
                <tbody>
                  <tr>
                    <td className={cx("col-6", "border-s")}>Bán hàng</td>
                    <td className={cx("text-end col-6 fw-5", "border-s")}>
                      <span className="me-1">+</span>
                      {Number(object.luong_doanhthu)
                        .toLocaleString()
                        .replace(/[.]/g, ",")}
                    </td>
                  </tr>

                  <tr>
                    <td className={cx("col-6", "border-s")}>Thị trường</td>
                    <td className={cx("text-end col-6 fw-5", "border-s")}>
                      <span className="me-1">+</span>
                      {Number(object.luong_thitruong)
                        .toLocaleString()
                        .replace(/[.]/g, ",")}
                    </td>
                  </tr>
                  <tr>
                    <td className={cx("col-6", "border-s")}>Lương khác</td>
                    <td className={cx("text-end col-6 fw-5", "border-s")}>
                      <span className="me-1">+</span>
                      {Number(object.luong_khac)
                        .toLocaleString()
                        .replace(/[.]/g, ",")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="mt-3">
            <h6 className="fs-14" style={{ textTransform: "uppercase" }}>
              3. Các khoản khấu trừ
            </h6>
            <div className="table-responsive">
              <table className="table text-nowrap table-bordered border-primary">
                <tbody>
                  <tr>
                    <td className={cx("col-6", "border-s")}>Đi muộn</td>
                    <td className={cx("text-end col-6 fw-5", "border-s")}>
                      <span className="me-1">-</span>
                      {Number(object.giamtru_dimuon)
                        .toLocaleString()
                        .replace(/[.]/g, ",")}
                    </td>
                  </tr>
                  <tr>
                    <td className={cx("col-6", "border-s")}>Về sớm</td>
                    <td className={cx("text-end col-6 fw-5", "border-s")}>
                      <span className="me-1">-</span>
                      {Number(object.giamtru_vesom)
                        .toLocaleString()
                        .replace(/[.]/g, ",")}
                    </td>
                  </tr>
                  <tr>
                    <td className={cx("col-6", "border-s")}>Ngày vắng mặt</td>
                    <td className={cx("text-end col-6 fw-5", "border-s")}>
                      <span className="me-1">-</span>
                      {Number(object.giamtru_vangmat)
                        .toLocaleString()
                        .replace(/[.]/g, ",")}
                    </td>
                  </tr>
                  <tr>
                    <td className={cx("col-6", "border-s")}>
                      KPI - {object.tiendo_kpi}%
                    </td>
                    <td className={cx("text-end col-6 fw-5", "border-s")}>
                      <span className="me-1">-</span>
                      {Number(object.giamtru_tiendocongviec)
                        .toLocaleString()
                        .replace(/[.]/g, ",")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="mb-3 mt-3 border-top border-block-start-dashed"></div>
          <div className="table-responsive">
            <table className="table text-nowrap table-bordered border-primary">
              <tbody>
                <tr>
                  <th className={cx("col-6 text-success", "border-s")}>
                    Tổng nhận
                  </th>
                  <th className={cx("text-end col-6 text-success", "border-s")}>
                    <span className="fs-18">
                      {Number(totalSalary.current)
                        .toLocaleString()
                        .replace(/[.]/g, ",")}
                    </span>
                    <sup>đ</sup>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </>
    )
}

export default DetailPhieuluong