import { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"

function FormAddStreet({socketio, dataUser, result}) {
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm()
    

    // Quản lý submit thêm mới
    const checkSuccess = useRef()
    const handleOnSubmit = (object) => {
      object.tongduongngo = Number(object.duongngo) + Number(object.viahe)
      object.tacgia = dataUser[0].email;
      // Xử lý thêm mới.
      const params = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: object
      }
      socketio.emit("client-create-data-bds-duongngo", params)
      socketio.on("server-create-data-bds-duongngo", (data) => {
        alert("Thêm mới thành công")
        checkSuccess.current = object;
        result({
          status: true,
          dataUpdate: data
        })
      })
    }
  useEffect(() => {
    return () => {
      socketio.off("client-create-data-bds-duongngo")
      socketio.off("server-create-data-bds-duongngo")
    }
  }, [checkSuccess.current])


    return (
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <div className="col-xl-12 col-md-12">
          <div className="row">
            <div className="form-floating mb-3 col-4">
              <input
                type="text"
                className="form-control rounded-3"
                placeholder="Tên input"
                {...register("diachi")}
              />
              <label className="fs-15 ms-2 text-muted">
                Số nhà <span className="text-danger">*</span>
              </label>
            </div>
            <div className="form-floating mb-3 col-8">
              <input
                type="text"
                className="form-control rounded-3"
                placeholder="Tên input"
                {...register("tuyenduong", { required: true })}
              />
              <label className="fs-15 ms-2 text-muted">
                Tuyến đường <span className="text-danger">*</span>
              </label>
              {errors.tuyenduong && (
                <span className="text-danger fs-14">
                  Bạn cần nhập tuyến đường.
                </span>
              )}
            </div>
            <div className="form-floating mb-3 col-12">
              <input
                type="text"
                className="form-control rounded-3"
                placeholder="Tên input"
                {...register("quanhuyen", { required: true })}
              />
              <label className="fs-15 ms-2 text-muted">
                Quận/Huyện <span className="text-danger">*</span>
              </label>
              {errors.quanhuyen && (
                <span className="text-danger fs-14">
                  Bạn cần nhập Quận/Huyện.
                </span>
              )}
            </div>
            <div className="form-floating mb-3 col-6">
              <input
                type="text"
                className="form-control rounded-3"
                placeholder="Tên input"
                {...register("duongngo", { required: true })}
              />
              <label className="fs-15 ms-2 text-muted">
                Đường/Ngõ <span className="text-danger">*</span>
              </label>
              {errors.duongngo && (
                <span className="text-danger fs-14">
                  Bạn cần nhập chiều rộng của đường ngõ.
                </span>
              )}
            </div>
            <div className="form-floating mb-3 col-6">
              <input
                type="text"
                className="form-control rounded-3"
                placeholder="Tên input"
                {...register("viahe")}
              />
              <label className="fs-15 ms-2 text-muted">
                Vỉa hè
              </label>
            </div>
          </div>
        </div>
        {/* Button */}
        <div className="px-4 py-3 border-top border-block-start-dashed d-flex justify-content-center">
          <button className="btn btn-primary-light m-1" type="submit">
            Thêm mới
          </button>
        </div>
      </form>
    )
}
export default FormAddStreet


// form edit data.
export function FormEditStreet({ socketio, dataUser, dataEdit, result }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  // Quản lý submit edit
  const checkSuccess = useRef()
  const handleOnSubmit = (object) => {
    object.tongduongngo = Number(object.duongngo) + Number(object.viahe)
    object.id_dn = dataEdit.id_dn
    // Xử lý edit.
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: object,
    }
    socketio.emit("client-edit-data-bds-duongngo", params)
    socketio.on("server-edit-data-bds-duongngo", (data) => {
      alert("Sửa thông tin thành công")
      checkSuccess.current = object
      result({
        status: true,
        dataUpdate: data,
      })
    })
  }
  useEffect(() => {
    return () => {
      socketio.off("client-edit-data-bds-duongngo")
      socketio.off("server-edit-data-bds-duongngo")
    }
  }, [checkSuccess.current])

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <div className="col-xl-12 col-md-12">
        <div className="row">
          <div className="form-floating mb-3 col-4">
            <input
              type="text"
              className="form-control rounded-3"
              placeholder="Tên input"
              {...register("diachi")}
              defaultValue={dataEdit.diachi}
            />
            <label className="fs-15 ms-2 text-muted">
              Số nhà <span className="text-danger">*</span>
            </label>
          </div>
          <div className="form-floating mb-3 col-8">
            <input
              type="text"
              className="form-control rounded-3"
              placeholder="Tên input"
              {...register("tuyenduong", { required: true })}
              defaultValue={dataEdit.tuyenduong}
            />
            <label className="fs-15 ms-2 text-muted">
              Tuyến đường <span className="text-danger">*</span>
            </label>
            {errors.tuyenduong && (
              <span className="text-danger fs-14">
                Bạn cần nhập tuyến đường.
              </span>
            )}
          </div>
          <div className="form-floating mb-3 col-12">
            <input
              type="text"
              className="form-control rounded-3"
              placeholder="Tên input"
              {...register("quanhuyen", { required: true })}
              defaultValue={dataEdit.quanhuyen}
            />
            <label className="fs-15 ms-2 text-muted">
              Quận/Huyện <span className="text-danger">*</span>
            </label>
            {errors.quanhuyen && (
              <span className="text-danger fs-14">
                Bạn cần nhập Quận/Huyện.
              </span>
            )}
          </div>
          <div className="form-floating mb-3 col-6">
            <input
              type="text"
              className="form-control rounded-3"
              placeholder="Tên input"
              {...register("duongngo", { required: true })}
              defaultValue={dataEdit.duongngo}
            />
            <label className="fs-15 ms-2 text-muted">
              Đường/Ngõ <span className="text-danger">*</span>
            </label>
            {errors.duongngo && (
              <span className="text-danger fs-14">
                Bạn cần nhập chiều rộng của đường ngõ.
              </span>
            )}
          </div>
          <div className="form-floating mb-3 col-6">
            <input
              type="text"
              className="form-control rounded-3"
              placeholder="Tên input"
              {...register("viahe")}
              defaultValue={dataEdit.viahe}
            />
            <label className="fs-15 ms-2 text-muted">Vỉa hè</label>
          </div>
        </div>
      </div>
      {/* Button */}
      <div className="px-4 py-3 border-top border-block-start-dashed d-flex justify-content-center">
        <button className="btn btn-primary-light m-1" type="submit">
          Lưu lại
        </button>
      </div>
    </form>
  )
}



