import classNames from "classnames/bind";
import styles from "../Job.module.scss"
import { backgroundMobile } from "../../../assets/images";
import GoBackPage from "../../../Components/Goback";
import { Notification } from "../../../Components/Notification";
import AvatarUser from "../../../Components/Info/AvatarUser";
import { IoAddCircle, IoAddCircleOutline, IoCardOutline, IoCartOutline, IoColorFilterOutline, IoFileTrayFullOutline, IoPieChartOutline, IoWalletOutline } from "react-icons/io5";
import { BiSolidDoughnutChart } from "react-icons/bi";
import SearchTaichinhcanhan from "../Component/Taichinhcanhan/SearchTaichinhcanhan";
import { GiCardPickup } from "react-icons/gi";
import PieChart from "../../../Components/VongTySuat/PieChart";
import { useContext, useEffect, useRef, useState } from "react";
import AppContext from "../../../handle-reuses/Contexts/CreateContexts";
import moment from "moment";
import ListDoanhthuChiphi from "../Component/Taichinhcanhan/ListDoanhthuChiphi";
import LoadingSpinner from "../../../Components/Loading/LoadingSpinner";
import StatisticalCanhan from "../Component/Taichinhcanhan/StatisticalCanhan";
import ListTargetCanhan from "../Component/Taichinhcanhan/ListTargetCanhan";
import { handleTotalCardWallet } from "../Component/Taichinhcanhan/reuse_taichinhcanha";
import ListPhanchia from "../Component/Taichinhcanhan/Phanchia_dongtien/ListPhanchia";
import ListPhantich from "../Component/Taichinhcanhan/Phanchia_dongtien/ListPhantich";
import ListDulieuPhantich from "../Component/Taichinhcanhan/Phanchia_dongtien/ListDulieuPhantich";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { TfiTarget } from "react-icons/tfi";
import { IoIosAddCircleOutline } from "react-icons/io";
import ModalReact from "../../../Components/Modal/ModalReact";
import ListCateThunhap from "../Component/Taichinhcanhan/ListCateThunhap";
import ListCateChiphi from "../Component/Taichinhcanhan/ListCateChiphi";
import ListCateDongtien from "../Component/Taichinhcanhan/ListCateDongtien";
import FormAddThunhap, { FormAddChuyentien } from "../Component/Taichinhcanhan/FormThunhap";
import FormAddChiphi from "../Component/Taichinhcanhan/FormChiphi";
import FormAddPhanchiaDongtien from "../Component/Taichinhcanhan/Phanchia_dongtien/FormPhanchiaDongtien";
import FormAddTarget from "../Component/Taichinhcanhan/FormTarget";
import ThongkeTaisan from "../Component/Taichinhcanhan/Component_child/ThongkeTaisan";
import CardWalletStatistical from "../Component/Taichinhcanhan/Component_child/CardWalletStatistical";
import HeaderDashboardMobile from "../../../Components/Header/HeaderDashboardMobile";
import { GrTransaction } from "react-icons/gr";
const cx = classNames.bind(styles);

function ListTaichinhcanhanMobile() {
  const { socketio, dataUser } = useContext(AppContext)
  const [totalPhanchia, setTotalPhanchia] = useState(0)
    const [changeEdit, setChangeEdit] = useState()
    // Quản lý danh mục.
    const [activeCate, setActiveCate] = useState("tong-quan")
    const handleActiveCate = (cate) => {
      setActiveCate(cate)
    }

    // Quản lý search data.
    const [objectSearch, setObjectSearch] = useState({
      daySearch: "",
      monthSearch: moment().format("YYYY-MM"),
      textSearch: "",
      user_id: dataUser[0].id_user,
    })
    const handleGetValueSearch = (object) => {
      setObjectSearch({
        daySearch: object.daysSearch,
        monthSearch: object.monthSearch,
        textSearch: object.textSearch,
        user_id: dataUser[0].id_user,
      })
    }

    // Quản lý thêm danh mục thu nhập.
    const [addCateThunhap, setAddCateThunhap] = useState(false)
    const handleAddCateThunhap = () => {
      setAddCateThunhap(true)
    }
    const handleCloseAddCateThunhap = () => {
      setAddCateThunhap(false)
    }
    // Quản lý thêm danh mục chi phí.
    const [addCateChiphi, setAddCateChiphi] = useState(false)
    const handleAddCateChiphi = () => {
      setAddCateChiphi(true)
    }
    const handleCloseAddCateChiphi = () => {
      setAddCateChiphi(false)
    }
    // Quản lý thêm danh mục phân bổ dòng tiền.
    const [addCateDongtien, setAddCateDongtien] = useState(false)
    const handleAddCateDongtien = () => {
      setAddCateDongtien(true)
    }
    const handleCloseAddCateDongtien = () => {
      setAddCateDongtien(false)
    }

    // Quản lý thêm thu nhập.
    const [addThunhap, setAddThunhap] = useState(false)
    const [dataUpdateCreateSuccess, setDataUpdateCreateSuccess] = useState([])
    const handleAddThunhap = () => {
      setAddThunhap(true)
    }
    const handleCloseAddThunhap = () => {
      setAddThunhap(false)
    }
    const handleAddThunhapSuccess = (object) => {
      if (object.status) {
        setDataUpdateCreateSuccess(object.dataUpdate)
        setAddThunhap(false)
        setChangeEdit("add-thunhap")
      }
    }

    // Quản lý thêm chi phí.
    const [addChiphi, setAddChiphi] = useState(false)
    const [dataUpdateCreateSuccessChiphi, setDataUpdateCreateSuccessChiphi] =
      useState([])
    const handleAddChiphi = () => {
      setAddChiphi(true)
    }
    const handleCloseAddChiphi = () => {
      setAddChiphi(false)
    }
    const handleAddChiphiSuccess = (object) => {
      if (object.status) {
        setDataUpdateCreateSuccessChiphi(object.dataUpdate)
        setAddChiphi(false)
        setChangeEdit("add-chiphi")
      }
    }
    // Quản lý thêm mới phân chia dòng tiền.
    const [modalPhanchiaDongtien, setModalPhanchiaDongtien] = useState(false)
    const [
      dataUpdateCreateSuccessDongtien,
      setDataUpdateCreateSuccessDongtien,
    ] = useState([])
    const handleAddPhanchiaDongtien = () => {
      setModalPhanchiaDongtien(true)
    }
    const handleCloseAddPhanchiaDongtien = () => {
      setModalPhanchiaDongtien(false)
    }
    const handleAddPhanchiaDongtienSuccess = (object) => {
      if (object.status) {
        setDataUpdateCreateSuccessDongtien(object.dataUpdate)
        setModalPhanchiaDongtien(false)
        setChangeEdit("phanchia-dongtien")
      }
    }

    // Quan ly cate thu nhap, cate chi phi cho statistical.
    const [cateThunhap, setCateThunhap] = useState([])
    const [cateChiphi, setCateChiphi] = useState([])
    const [loading, setLoading] = useState(true)
    useEffect(() => {
      const params = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: {
          user_id: dataUser[0].id_user,
          monthSearch: objectSearch.monthSearch,
        },
      }
      socketio.emit(
        "client-total-thunhap-cate-month-taichinhcanhan-thunhap",
        params,
      )
      socketio.on(
        "server-total-thunhap-cate-month-taichinhcanhan-thunhap",
        (data) => {
          setCateThunhap([...data])
        },
      )
      // Tong chi phi cate.
      socketio.emit(
        "client-total-expense-for-cate-month-taichinhcanhan-chiphi",
        params,
      )
      socketio.on(
        "server-total-expense-for-cate-month-taichinhcanhan-chiphi",
        (data) => {
          setCateChiphi([...data])
        },
      )

      // Set dong tien
      socketio.emit("client-get-total-money-All-taichinhcanha-dongtien", params)
      socketio.on(
        "server-get-total-money-All-taichinhcanha-dongtien",
        (data) => {
          if (data.length > 0) {
            if (data[0].total_money) {
              let total = data[0].total_money
              setTotalPhanchia(total)
            }
          }
          setLoading(false)
        },
      )

      return () => {
        socketio.off("client-total-thunhap-cate-month-taichinhcanhan-thunhap")
        socketio.off("server-total-thunhap-cate-month-taichinhcanhan-thunhap")

        socketio.off(
          "client-total-expense-for-cate-month-taichinhcanhan-chiphi",
        )
        socketio.off(
          "server-total-expense-for-cate-month-taichinhcanhan-chiphi",
        )

        socketio.off("client-get-total-money-All-taichinhcanha-dongtien")
        socketio.off("server-get-total-money-All-taichinhcanha-dongtien")
      }
    }, [
      objectSearch,
      dataUpdateCreateSuccess,
      dataUpdateCreateSuccessChiphi,
      changeEdit,
    ])

    // Quản lý thêm mới mục tiêu.
    const [modalAddTarget, setModalTarget] = useState(false)
    const [dataUpdateCreateSuccessTarger, setDataUpdateCreateSuccessTarget] =
      useState([])
    const handleAddTarget = () => {
      setModalTarget(true)
    }
    const handleCloseAddTarget = () => setModalTarget(false)
    const handleAddTargetSuccess = (object) => {
      if (object.status) {
        setModalTarget(false)
        setDataUpdateCreateSuccessTarget(object.dataUpdate)
      }
    }
  
  // Quản lý thêm mới chuyển tiền (chuyển từ thẻ -> ví or ví -> thẻ).
  const [modalAddChuyentien, setModalAddChuyentien] = useState(false)
  const handleAddChuyentien = () => {
    setModalAddChuyentien(true)
  }
  const handleCloseAddChuyentien = () => {
    setModalAddChuyentien(false)
  }
  const handleAddChuyentienSuccess = (object) => {
    if (object.status) {
      setModalAddChuyentien(false)
      setChangeEdit(object.dataUpdate)
    }
  }


    // Quản lý edit data của doanh thu và chi phí (cập nhật lại dữ liệu)
    const handleChangeEditRevenueExpense = (object) => {
      setChangeEdit(object)
    }

    // Thay doi hoac them moi danh muc.
    const handleUpdateCate = (object) => {
      setChangeEdit(object)
    }


    /** ====== Quản lý danh mục nhỏ trong danh mục báo cáo tổng quan ==== */
    const [activeCateTongquan, setActiveCateTongquan] = useState("revenue-expense")
    const handleActiveCateTongquan = (cate) => {
      setActiveCateTongquan(cate)
    }
    /** ====== Quản lý danh mục nhỏ trong danh mục báo cáo tổng quan ==== */


    if (loading) {
      return <LoadingSpinner />
    }

  
  return (
    <>
      <div className={cx("background-image")}></div>
      <HeaderDashboardMobile hiden="hiden" />
      <div
        className={cx("p-2 px-3 mt-5")}
        style={
          {
            //   backgroundImage: "url("+backgroundMobile.background+")",
          }
        }
      >
        {/* <div className="d-flex justify-content-between">
          <GoBackPage style={{ color: "white" }} />
          <div className="d-flex align-items-center gap-3">
            <Notification sizeIcon="fs-29" style={{ color: "white" }} />
            <AvatarUser width="30px" height="30px" />
          </div>
        </div> */}

        {/* Số dư tài khoản*/}
        <div
          className="card custom-card mt-3 mb-3 rounded-4"
          // style={{ boxShadow: "none" }}
        >
          <div className="card-header border-bottom-0 pb-0 d-flex justify-content-center wrapper_ground">
            <button
              className={cx(
                "list_items fs-17 border-0 rounded-3 p-2 badge fw-5",
                activeCate === "tong-quan"
                  ? "bg-primary-gradient op-8"
                  : "text-muted op-6",
              )}
              onClick={() => handleActiveCate("tong-quan")}
            >
              Tổng quan
            </button>
            <button
              className={cx(
                "list_items fs-17 border-0 rounded-3 p-2 badge fw-5",
                activeCate === "dong-tien"
                  ? "bg-primary-gradient op-8"
                  : "text-muted op-6",
              )}
              onClick={() => handleActiveCate("dong-tien")}
            >
              Dòng tiền
            </button>
            <button
              className={cx(
                "list_items fs-17 border-0 rounded-3 p-2 badge bg-dark-transparent fw-5",
              )}
              data-bs-toggle="dropdown"
            >
              Thêm mới
            </button>
            <ul className="dropdown-menu">
              <li
                className="dropdown-item fw-5 d-flex align-items-center gap-1"
                onClick={handleAddCateThunhap}
              >
                <IoIosAddCircleOutline />
                Danh mục thu nhập
              </li>
              <li
                className="dropdown-item fw-5 d-flex align-items-center gap-1"
                onClick={handleAddCateChiphi}
              >
                <IoIosAddCircleOutline />
                Danh mục chi phí
              </li>
              <li
                className="dropdown-item fw-5 d-flex align-items-center gap-1"
                onClick={handleAddCateDongtien}
              >
                <IoIosAddCircleOutline />
                Danh mục phân chia
              </li>
              <hr></hr>
              <li
                className="dropdown-item fw-5 d-flex align-items-center gap-1"
                onClick={handleAddThunhap}
              >
                <RiMoneyDollarCircleLine />
                Thêm thu nhập
              </li>
              <li
                className="dropdown-item fw-5 d-flex align-items-center gap-1"
                onClick={handleAddChiphi}
              >
                <IoCartOutline />
                Thêm chi phí
              </li>
              <li
                className="dropdown-item fw-5 d-flex align-items-center gap-1"
                onClick={handleAddPhanchiaDongtien}
              >
                <IoColorFilterOutline />
                Thêm phân chia
              </li>
              <li
                className="dropdown-item fw-5 d-flex align-items-center gap-1"
                onClick={handleAddTarget}
              >
                <TfiTarget />
                Thêm mục tiêu
              </li>
              <li
                className="dropdown-item fw-5 d-flex align-items-center gap-1"
                onClick={handleAddChuyentien}
              >
                <GrTransaction />
                Chuyển tiền
              </li>
            </ul>
          </div>
          {/* Thống kê tài sản */}
          <div className="card-body pt-1 mt-3">
            <ThongkeTaisan
              socketio={socketio}
              dataUser={dataUser}
              totalPhanchia={totalPhanchia}
              changeStatus={changeEdit}
            />
          </div>
          <div className="card-footer">
            <CardWalletStatistical
              socketio={socketio}
              dataUser={dataUser}
              totalPhanchia={totalPhanchia}
              changeStatus={changeEdit}
            />
          </div>
          <div className="card-footer">
            <SearchTaichinhcanhan resultSearch={handleGetValueSearch} />
          </div>
        </div>
        {/* cate tong quan  */}
        {activeCate === "tong-quan" && (
          <div>
            <div className={cx("fs-18 d-flex gap-2", "listCateMobile")}>
              <button
                className={cx(
                  "border-0 badge",
                  activeCateTongquan === "revenue-expense"
                    ? "bg-success"
                    : "op-6 text-muted",
                )}
                style={{ minWidth: "86px" }}
                onClick={() => handleActiveCateTongquan("revenue-expense")}
              >
                Doanh thu & chi phí
              </button>
              <button
                className={cx(
                  "border-0 badge ",
                  activeCateTongquan === "statistical"
                    ? "bg-success"
                    : "op-6 text-muted",
                )}
                style={{ minWidth: "86px" }}
                onClick={() => handleActiveCateTongquan("statistical")}
              >
                Báo cáo
              </button>
              <button
                className={cx(
                  "border-0 badge",
                  activeCateTongquan === "target"
                    ? "bg-success"
                    : "op-6 text-muted",
                )}
                style={{ minWidth: "86px" }}
                onClick={() => handleActiveCateTongquan("target")}
              >
                Mục tiêu
              </button>
            </div>
            {/* List content revenue expense */}
            {activeCateTongquan === "revenue-expense" && (
              <div className="mt-3">
                <ListDoanhthuChiphi
                  socketio={socketio}
                  dataUser={dataUser}
                  objectSearch={objectSearch}
                  dataCateChiphi={cateChiphi}
                  dataUpdateRevenueCreate={dataUpdateCreateSuccess}
                  dataUpdateCreateSuccessChiphi={dataUpdateCreateSuccessChiphi}
                  resultChange={handleChangeEditRevenueExpense}
                  changeStatus={changeEdit}
                />
              </div>
            )}

            {/* stattistical */}
            {activeCateTongquan === "statistical" && (
              <div className="mt-3">
                <StatisticalCanhan
                  socketio={socketio}
                  dataUser={dataUser}
                  objectSearch={objectSearch}
                  dataCateThunhap={cateThunhap}
                  dataCateChiphi={cateChiphi}
                  dataUpdateRevenueCreate={dataUpdateCreateSuccess}
                  dataUpdateCreateSuccessChiphi={dataUpdateCreateSuccessChiphi}
                  changeStatus={changeEdit}
                />
              </div>
            )}
            {/* Target */}
            {activeCateTongquan === "target" && (
              <div className="mt-3">
                <ListTargetCanhan
                  socketio={socketio}
                  dataUser={dataUser}
                  objectSearch={objectSearch}
                  result={handleAddTargetSuccess}
                  dataUpdateCreate={dataUpdateCreateSuccessTarger}
                  dataUpdateRevenueCreate={dataUpdateCreateSuccess}
                  dataUpdateCreateSuccessChiphi={dataUpdateCreateSuccessChiphi}
                  changeEdit={changeEdit}
                />
              </div>
            )}
          </div>
        )}
        {/* cate dong tien */}
        {activeCate === "dong-tien" && (
          <ListPhanChiaDongtienMobile
            socketio={socketio}
            dataUser={dataUser}
            objectSearch={objectSearch}
            dataUpdateCreate={dataUpdateCreateSuccessDongtien}
            dataUpdateRevenueCreate={dataUpdateCreateSuccess}
            dataUpdateCreateSuccessChiphi={dataUpdateCreateSuccessChiphi}
          />
        )}

        {/* Modal add cate thu nhập */}
        {addCateThunhap && (
          <ModalReact
            modalTitle="Thêm mới danh mục thu nhập"
            showModal={addCateThunhap}
            handleClose={handleCloseAddCateThunhap}
            theme={
              <ListCateThunhap
                socketio={socketio}
                dataUser={dataUser}
                result={handleUpdateCate}
              />
            }
          />
        )}
        {/* Modal add cate chi phí */}
        {addCateChiphi && (
          <ModalReact
            modalTitle="Thêm mới danh mục chi phí"
            showModal={addCateChiphi}
            handleClose={handleCloseAddCateChiphi}
            theme={
              <ListCateChiphi
                socketio={socketio}
                dataUser={dataUser}
                result={handleUpdateCate}
              />
            }
          />
        )}
        {/* Modal add cate dòng tiền */}
        {addCateDongtien && (
          <ModalReact
            modalTitle="Thêm mới danh mục dòng tiền"
            showModal={addCateDongtien}
            handleClose={handleCloseAddCateDongtien}
            theme={
              <ListCateDongtien
                socketio={socketio}
                dataUser={dataUser}
                result={handleUpdateCate}
              />
            }
          />
        )}

        {/* Modal add thu nhập */}
        {addThunhap && (
          <ModalReact
            modalTitle="Thêm mới thu nhập"
            showModal={addThunhap}
            handleClose={handleCloseAddThunhap}
            theme={
              <FormAddThunhap
                socketio={socketio}
                dataUser={dataUser}
                result={handleAddThunhapSuccess}
              />
            }
          />
        )}
        {/* Modal add chi phí */}
        {addChiphi && (
          <ModalReact
            modalTitle="Thêm mới chi phí"
            showModal={addChiphi}
            handleClose={handleCloseAddChiphi}
            theme={
              <FormAddChiphi
                socketio={socketio}
                dataUser={dataUser}
                result={handleAddChiphiSuccess}
              />
            }
          />
        )}

        {/* Modal add phanchia_dongtien */}
        {modalPhanchiaDongtien && (
          <ModalReact
            modalTitle="Thêm mới chi phí"
            showModal={modalPhanchiaDongtien}
            handleClose={handleCloseAddPhanchiaDongtien}
            theme={
              <FormAddPhanchiaDongtien
                socketio={socketio}
                dataUser={dataUser}
                result={handleAddPhanchiaDongtienSuccess}
              />
            }
          />
        )}

        {/* modal add target */}
        {modalAddTarget && (
          <ModalReact
            modalTitle="Thêm mới mục tiêu"
            showModal={modalAddTarget}
            handleClose={handleCloseAddTarget}
            theme={
              <FormAddTarget
                socketio={socketio}
                dataUser={dataUser}
                result={handleAddTargetSuccess}
              />
            }
          />
        )}

        {/* Modal add chuyển tiền */}
        {modalAddChuyentien && (
          <ModalReact
            modalTitle="Thêm mới chuyển tiền"
            showModal={modalAddChuyentien}
            handleClose={handleCloseAddChuyentien}
            theme={
              <FormAddChuyentien
                socketio={socketio}
                dataUser={dataUser}
                result={handleAddChuyentienSuccess}
              />
            }
          />
        )}
      </div>
    </>
  )
}

export default ListTaichinhcanhanMobile


// Danh sách phân chia dòng tiền.
function ListPhanChiaDongtienMobile({
  socketio,
  dataUser,
  objectSearch,
  dataUpdateCreate,
  dataUpdateRevenueCreate,
  dataUpdateCreateSuccessChiphi,
}) {
  /** ====== Quản lý danh mục nhỏ trong danh mục báo cáo dòng tiền ==== */
  const [activeCateDongtien, setActiveCateDongtien] = useState("list-phanbo")
  const handleActiveCateDongtien = (cate) => {
    setActiveCateDongtien(cate)
  }
  /** ====== Quản lý danh mục nhỏ trong danh mục báo cáo dòng tiền ==== */

  // Set toong thu nhap all.
  const [thunhapAll, setThunhapAll] = useState(0)
  const [phanboAll, setPhanboAll] = useState(0)
  const [loading, setLoading] = useState(true)
  const [changeStatus, setChangeStatus] = useState()
  const [dataCateThunhap, setDataCateThunhap] = useState([])
  const [dataCateExpese, setDataCateExpense] = useState([])

  const handleChangeStatus = (object) => {
    setChangeStatus(object)
  }

  useEffect(() => {
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: { user_id: dataUser[0].id_user },
    }
    // tong thu nhap all
    socketio.emit("client-total-thunhap-all-taichinhcanhan-thunhap", params)
    socketio.on("server-total-thunhap-all-taichinhcanhan-thunhap", (data) => {
      if (data.length > 0) {
        let totalThunhap = handleTotalCardWallet(data)
        setThunhapAll(totalThunhap)
      }
    })
    // tong da phan bo.
    socketio.emit("client-get-total-money-All-taichinhcanha-dongtien", params)
    socketio.on("server-get-total-money-All-taichinhcanha-dongtien", (data) => {
      if (data.length > 0) {
        setPhanboAll(data[0].total_money)
      }
    })
    // Data tổng thu nhập theo danh mục.
    socketio.emit(
      "client-total-thunhap-for-cate-taichinhcanhan-thunhap",
      params,
    )
    socketio.on(
      "server-total-thunhap-for-cate-taichinhcanhan-thunhap",
      (data) => {
        setDataCateThunhap([...data])
      },
    )

    // data tổng chi phí theo danh mục.
    socketio.emit("client-total-chiphi-for-cate-taichinhcanhan-chiphi", params)
    socketio.on(
      "server-total-chiphi-for-cate-taichinhcanhan-chiphi",
      (data) => {
        if (data.length > 0) {
          setDataCateExpense([...data])
        }
        setLoading(false)
      },
    )
    return () => {
      socketio.off("client-total-thunhap-all-taichinhcanhan-thunhap")
      socketio.off("server-total-thunhap-all-taichinhcanhan-thunhap")

      socketio.off("client-total-thunhap-for-cate-taichinhcanhan-thunhap")
      socketio.off("server-total-thunhap-for-cate-taichinhcanhan-thunhap")

      socketio.off("client-total-chiphi-for-cate-taichinhcanhan-chiphi")
      socketio.off("server-total-chiphi-for-cate-taichinhcanhan-chiphi")
    }
  }, [
    dataUpdateCreate,
    changeStatus,
    dataUpdateRevenueCreate,
    dataUpdateCreateSuccessChiphi,
  ])

  // Quan ly su thay doi khi them moi gia vang thanh cong.
  const handleChangeStatusGiavang = (object) => {
    setChangeStatus(object)
  }

  if (loading) {
    return <LoadingSpinner />
  }
  if (thunhapAll === 0) {
    return <div className="text-center mt-5 fs-16">Bạn chưa có thu nhập</div>
  }

  return (
    <div>
      <div className={cx("fs-18 d-flex gap-2", "listCateMobile")}>
        <button
          className={cx(
            "border-0 badge",
            activeCateDongtien === "list-phanbo"
              ? "bg-success"
              : "op-6 text-muted",
          )}
          style={{ minWidth: "86px" }}
          onClick={() => handleActiveCateDongtien("list-phanbo")}
        >
          Danh sách phân bổ
        </button>
        <button
          className={cx(
            "border-0 badge ",
            activeCateDongtien === "statistical"
              ? "bg-success"
              : "op-6 text-muted",
          )}
          style={{ minWidth: "86px" }}
          onClick={() => handleActiveCateDongtien("statistical")}
        >
          Thống kê
        </button>
        <button
          className={cx(
            "border-0 badge",
            activeCateDongtien === "giavang" ? "bg-success" : "op-6 text-muted",
          )}
          style={{ minWidth: "86px" }}
          onClick={() => handleActiveCateDongtien("giavang")}
        >
          Giá vàng
        </button>
      </div>
      {/* List phan bo */}
      {activeCateDongtien === "list-phanbo" && (
        <div className="py-3">
          <ListPhanchia
            socketio={socketio}
            dataUser={dataUser}
            objectSearch={objectSearch}
            dataUpdateCreate={dataUpdateCreate}
            totalThunhapAll={thunhapAll}
            totalPhanboAll={phanboAll}
            changeStatus={handleChangeStatus}
            dataCateThunhap={dataCateThunhap}
            dataCateExpense={dataCateExpese}
          />
        </div>
      )}
      {/* statistical */}
      {activeCateDongtien === "statistical" && (
        <div className="py-3">
          <ListPhantich
            socketio={socketio}
            dataUser={dataUser}
            objectSearch={objectSearch}
            dataUpdateCreate={dataUpdateCreate}
            totalThunhapAll={thunhapAll}
            totalPhanboAll={phanboAll}
            changeStatus={handleChangeStatus}
            dataCateThunhap={dataCateThunhap}
            dataCateExpense={dataCateExpese}
          />
        </div>
      )}
      {/* gia vang */}
      {activeCateDongtien === "giavang" && (
        <div className="py-3">
          <ListDulieuPhantich
            socketio={socketio}
            dataUser={dataUser}
            objectSearch={objectSearch}
            result={handleChangeStatusGiavang}
          />
        </div>
      )}
    </div>
  )
}