import moment from "moment";
import { useForm } from "react-hook-form"
import { IoFingerPrint } from "react-icons/io5";
import classNames from "classnames/bind";
import styles from "../../Job.module.scss"
import { IoMdCheckmarkCircle } from "react-icons/io";
import { MdCancel } from "react-icons/md";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { TextLimit } from "../../../../handle-reuses/reuses";
const cx = classNames.bind(styles);

function FormChamcong({ socketio, result, dataUser }) {
    const { register, handleSubmit } = useForm();
    const [notifiTimekeeping, setNotifiTimekeeping] = useState("")
  const [textNoteTimekeeping, setTextNoteTimekeeping] = useState("")
  const checkDataTimekeeping = useRef([])
  const gioLamviec = useRef([])
  const arrXinvangmat = useRef([])
  const [checkVangmat, setCheckVangmat] = useState(false)

  // kiểm tra chấm công
  useLayoutEffect(() => {
    // Check hôm nay đã chấm công chưa.
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: { sodienthoai: dataUser[0].sodienthoai }
    }
    socketio.emit("client-check-timekeeping", params)
    socketio.on("server-check-timekeeping", (data) => {
      checkDataTimekeeping.current = [...data]
    });

    // Check giờ làm viêc.
    const paramsGiolamviec = {
      iden: process.env.REACT_APP_IDEN_DASH
    }
    socketio.emit("client-get-data-giolamviec", paramsGiolamviec)
    socketio.on("server-get-data-giolamviec", (data) => {
      gioLamviec.current = [...data]
    });

    // Check xem có đơn xin vắng mặt không.
    const paramsXinvangmat = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: { user_id: dataUser[0].id_user }
    }
    socketio.emit("client-get-data-today-xinvangmat", paramsXinvangmat)
    socketio.on("server-get-data-today-xinvangmat", (data) => {
      arrXinvangmat.current = [...data];
      // Set hide button timekeeping.
      if (data.length > 0) {
        let formatStart = moment(data[0].date_start).format(
          "YYYY-MM-DDTHH:mm:ss",
        )
        let formatEnd = moment(data[0].date_end).format("YYYY-MM-DDTHH:mm:ss")
        let dateStart = new Date(formatStart).getTime()
        let dateEnd = new Date(formatEnd).getTime()
        let currentTime = new Date().getTime()
        if (currentTime >= dateStart && currentTime <= dateEnd) {
          setCheckVangmat(true)
        }
      }
    })
    return () => {
      socketio.off("client-check-timekeeping")
      socketio.off("server-check-timekeeping")
      socketio.off("client-get-data-giolamviec")
      socketio.off("server-get-data-giolamviec")
      socketio.off("client-get-data-today-xinvangmat")
      socketio.off("server-get-data-today-xinvangmat")
    }
  }, []);

  // Quản lý trạng thái chấm công thành công
  useEffect(() => {
    const paramsUpdate = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        user_id: dataUser[0].id_user,
        addtime: moment().format("YYYY-MM-DD"),
      },
    }
    // Thành công khi chấm công mới
    socketio.on("server-create-timekeeping", (data) => {
      if (data === "success") {
        socketio.emit("client-get-list-id-day-timekeeping", paramsUpdate)
        socketio.on("server-get-list-id-day-timekeeping", (dataUpdate) => {
          result({ status: true, dataUpdateTimekeeping: dataUpdate })
        })
      }
    });
    // Thành công chấm công cuối giờ ca sáng.
    socketio.on("server-edit-morning-end-timekeeping", (data) => {
      if (data === "success") {
        socketio.emit("client-get-list-id-day-timekeeping", paramsUpdate)
        socketio.on("server-get-list-id-day-timekeeping", (dataUpdate) => {
          result({ status: true, dataUpdateTimekeeping: dataUpdate })
        })
      }
    });
    // Thành công chấm công đầu giờ chiều
    socketio.on("server-edit-afternoon-start-timekeeping", (data) => {
      if (data === "success") {
        socketio.emit("client-get-list-id-day-timekeeping", paramsUpdate)
        socketio.on("server-get-list-id-day-timekeeping", (dataUpdate) => {
          result({ status: true, dataUpdateTimekeeping: dataUpdate })
        })
      }
    });
    // Thành công chấm cuối giờ chiều.
    socketio.on("server-edit-afternoon-end-timekeeping", (data) => {
      if (data === "success") {
        socketio.emit("client-get-list-id-day-timekeeping", paramsUpdate)
        socketio.on("server-get-list-id-day-timekeeping", (dataUpdate) => {
          result({ status: true, dataUpdateTimekeeping: dataUpdate })
        })
      }
    })
    return () => {
      socketio.off("server-create-timekeeping")
      socketio.off("server-edit-morning-end-timekeeping")
      socketio.off("server-edit-afternoon-start-timekeeping")
      socketio.off("server-edit-afternoon-end-timekeeping")

      socketio.off("client-get-list-id-day-timekeeping")
      socketio.off("server-get-list-id-day-timekeeping")
    }
  }, []);

    // Xử lý submit.
    const onSubmit = (object) => {
        const dateCurrent = moment().format("YYYY-MM-DD")
        // set thời gian quy định làm việc từ 8h - 17h.
        const timekeepingMorningStart = new Date(dateCurrent + gioLamviec.current[0].ca_sang_a).getTime();
        const timekeepingMorningEnd = new Date(dateCurrent + gioLamviec.current[0].ca_sang_b).getTime();
        const timekeepingAfternoonStart = new Date(dateCurrent + gioLamviec.current[0].ca_chieu_a).getTime();
        const timekeepingAfternoonEnd = new Date(dateCurrent + gioLamviec.current[0].ca_chieu_b).getTime();
        // Set time current.
        const currentTime = new Date().getTime()
        // set dung sai 5 phút
      const dungsai = 5 * 60 * 1000
      const dungsai60P =  60 * 60 * 1000; // dung sai 60 phút
        // Set check timekeeping.
      const checkTimekeepingMorningMin = timekeepingMorningStart - dungsai
      const checkTimekeepingMorningMax = timekeepingMorningEnd + dungsai60P
      const checkTimekeepingAfternoonMin = timekeepingAfternoonStart - dungsai
      const checkTimekeepingAfternoonMax = timekeepingAfternoonEnd + dungsai60P

        // Set object
        object.hoten = dataUser[0].hoten;
        object.sodienthoai = dataUser[0].sodienthoai
        object.avatar = dataUser[0].avatar
        object.loai_ca = dataUser[0].loai_ca
        object.cong_chuan = 0
        

        // Điều kiện để tính chấm công
        if (currentTime <= checkTimekeepingMorningMax && currentTime >= checkTimekeepingMorningMin) {
            // Tính toán thời gian chấm công buổi sáng.
          if (currentTime >= checkTimekeepingMorningMin && currentTime <= timekeepingMorningStart) {
            // Xử lý đúng giờ
            handleCreateDataTimekeepingMorning(socketio, object, timekeepingMorningStart, dataUser, gioLamviec)
            setNotifiTimekeeping("success")
            setTextNoteTimekeeping(
              <span className="text-success">
                Chúc mừng bạn đã đi đúng giờ, hãy duy trì nó nhé.
              </span>,
            )
          } else if(currentTime > timekeepingMorningStart && currentTime < timekeepingMorningEnd) {
            if (checkDataTimekeeping.current.length === 0) {
              // Xử lý đi muộn.
              let sophutDimuon =
                (currentTime - timekeepingMorningStart) / (60 * 1000)
              handleCreateDataTimekeepingMorning(
                socketio,
                object,
                timekeepingMorningStart,
                dataUser,
                gioLamviec
              )
              setNotifiTimekeeping("success")
              setTextNoteTimekeeping(
                <span className="text-danger">
                  {"Bạn đã đi muộn " + sophutDimuon.toFixed(2) + " phút"}
                </span>,
              )
            } else {
              // Xử lý đi muộn.
              let sophutVesom = (timekeepingMorningEnd - currentTime) / (60 * 1000)
              handleTimekeepingMorningEnd(socketio, object, checkDataTimekeeping, timekeepingMorningEnd, gioLamviec)
              setNotifiTimekeeping("success")
              setTextNoteTimekeeping(
                <span className="text-danger">
                  {"Bạn đã về sớm " + sophutVesom.toFixed(2) + " phút"}
                </span>,
              )
            }
          } else if (currentTime >= timekeepingMorningEnd && currentTime <= checkTimekeepingMorningMax) {
            if (checkDataTimekeeping.current.length > 0) {
              if (checkDataTimekeeping.current[0].ca_sang_b) {
                alert("Sáng nay bạn đã hoàn tất chấm công rồi.")
              } else {
                // Xử lý chấm công kết thúc buổi sáng
                handleTimekeepingMorningEnd(socketio, object, checkDataTimekeeping, timekeepingMorningEnd, gioLamviec)
                setNotifiTimekeeping("success")
                setTextNoteTimekeeping(
                  <span className="text-success">Sáng nay bạn đã về đúng giờ</span>,
                )
              }
            } else {
              alert("Sáng nay có vẻ như bạn không đi làm.")
            }
          }
            
          /** === *Kết thúc buổi sáng === */
        } else if (currentTime <= checkTimekeepingAfternoonMax && currentTime >= checkTimekeepingAfternoonMin) {

          /** === Tính toán thời gian chấm công buổi chiều ===*/
          if (
            currentTime >= checkTimekeepingAfternoonMin &&
            currentTime <= timekeepingAfternoonStart
          ) {
            // Set đúng giờ
            if (checkDataTimekeeping.current.length > 0) {
              // Edit cho ca chiều
              handleTimekeepingAfternoonStart(socketio, object, checkDataTimekeeping, timekeepingAfternoonStart, gioLamviec)
              setNotifiTimekeeping("success")
              setTextNoteTimekeeping(
                <span className="text-success">
                  Chúc mừng bạn đã đi đúng giờ, hãy duy trì nó nhé.
                </span>,
              )
            } else {
              // Thêm mới cho ca chiều
              handleCreateDataTimekeepingAfternoon(socketio, object, timekeepingAfternoonStart, dataUser, gioLamviec)
              setNotifiTimekeeping("success")
              setTextNoteTimekeeping(
                <span className="text-success">
                  Chúc mừng bạn đã đi đúng giờ, hãy duy trì nó nhé.
                </span>,
              )
            }
          } else if (
            currentTime >= checkTimekeepingAfternoonMin &&
            currentTime <= checkTimekeepingAfternoonMax
          ) {
            // Set đi muộn
            let sophutDimuon =
              (currentTime - timekeepingAfternoonStart) / (60 * 1000)
            let sophutVesom = (timekeepingAfternoonEnd - currentTime) / (60*1000)
            // xử lý chấm công đầu giờ chiều.
            if (checkDataTimekeeping.current.length > 0) {
              if (currentTime > timekeepingAfternoonEnd && !checkDataTimekeeping.current[0].ca_chieu_a) {
                alert(
                  "Bạn không thể chấm công cuối giờ khi đầu giờ bạn chưa chấm.",
                )
              } else {
                // Xử lý edit.
                if (
                  !checkDataTimekeeping.current[0].ca_chieu_a &&
                  !checkDataTimekeeping.current[0].ca_chieu_b
                ) {
                  //TH: Chấm công đầu giờ
                  handleTimekeepingAfternoonStart(
                    socketio,
                    object,
                    checkDataTimekeeping,
                    timekeepingAfternoonStart,
                    gioLamviec,
                  )
                  setNotifiTimekeeping("success")
                  setTextNoteTimekeeping(
                    <span className="text-danger">
                      {"Bạn đã đi muộn " + sophutDimuon.toFixed(2) + " phút"}
                    </span>,
                  )
                } else if (
                  checkDataTimekeeping.current[0].ca_chieu_a &&
                  !checkDataTimekeeping.current[0].ca_chieu_b
                ) {
                  // TH: Chấm công cuối giờ chiều.
                  handleTimekeepingAfternoonEnd(
                    socketio,
                    object,
                    checkDataTimekeeping,
                    timekeepingAfternoonEnd,
                    gioLamviec,
                  )
                  setNotifiTimekeeping("success")
                  if (sophutVesom > 0) {
                    setTextNoteTimekeeping(
                      <span className="text-danger">
                        {"Bạn đã về sớm " + sophutVesom.toFixed(2) + " phút"}
                      </span>,
                    )
                  } else {
                    setTextNoteTimekeeping(
                      <span className="text-success">
                        Chiều nay bạn đã về đúng giờ
                      </span>,
                    )
                  }
                } else {
                  alert("Chiều nay bạn đã hoàn tất chấm công rồi.")
                }
              }
              
            } else {
              // Kiểm tra xem hết giờ chấm công chưa.
              if (currentTime <= checkTimekeepingAfternoonMax - dungsai60P) {
                // Thêm mới ca chiều.
                handleCreateDataTimekeepingAfternoon(socketio, object, timekeepingAfternoonStart, dataUser, gioLamviec)
                setNotifiTimekeeping("success")
                setTextNoteTimekeeping(
                  <span className="text-danger">
                    {"Bạn đã đi muộn " + sophutDimuon.toFixed(2) + " phút"}
                  </span>,
                )
              } else {
                alert(
                  "Bạn không thể chấm công vì thời gian làm việc đã kết thúc.",
                )
              }
            }
          } else {
            alert("Thời gian làm việc đã kết thúc bạn không thể chấm công.")
          }
          /** === *Tính toán thời gian chấm công buổi chiều === */
        } else if (currentTime < checkTimekeepingMorningMin) {
          alert("Bạn không thể chấm công quá sớm, thời gian chấm công bắt đầu lúc 07:55:00")
        } else if (currentTime > checkTimekeepingMorningMax && currentTime < checkTimekeepingAfternoonMin) {
          alert("Bạn không thể chấm công quá sớm, thời gian chấm công bắt đầu lúc 13:55:00")
        } else if (currentTime > checkTimekeepingAfternoonMax) {
          alert("Thời gian làm việc đã kết thúc, bạn không thể chấm công.")
        }
         
    }
  
  // Quản lý ẩn socketio.
  useEffect(() => {
    return () => {
      socketio.off("client-create-timekeeping")
      // Ẩn socket cuối giờ sáng.
      socketio.off("client-edit-morning-end-timekeeping")
      // Ẩn socket chấm công đầu giờ chiều
      socketio.off("client-edit-afternoon-start-timekeeping")
      //ẩn socket chấm công cuối giờ chiều.
      socketio.off("client-edit-afternoon-end-timekeeping")
    }
  }, [NotifiTimekeepingSuccess])

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        {notifiTimekeeping === "success" && textNoteTimekeeping !== "" && (
          <NotifiTimekeepingSuccess textNoti={textNoteTimekeeping} />
        )}
        {!notifiTimekeeping === "failure" && (
          <NotifiTimekeepingFailure textNoti={textNoteTimekeeping} />
        )}
        <div className="mb-3 wrapper_ground">
          <div className="fs-14 bg-primary-transparent p-3 rounded-4 list_items">
            <p className="mb-1">
              Nhân viên: <span className="fw-5">{dataUser[0].hoten}</span>
            </p>
            <p className="mb-1">
              Phone: <span className="fw-5">{dataUser[0].sodienthoai}</span>
            </p>
            <p className="mb-1">
              Thường trú:{" "}
              <span className="fw-5">
                <TextLimit text={dataUser[0].thuongtru} number={35} />
              </span>
            </p>
            <p className="mb-0">
              Ca làm việc:{" "}
              <span className="fw-5">
                {dataUser[0].loai_ca === "full"
                  ? "Cả ngày"
                  : dataUser[0].loai_ca}
              </span>
            </p>
          </div>
          {checkVangmat && (
            <div className="list_items btn bg-primary-gradient">
              Không thể chấm công trong thời gian vắng mặt.
            </div>
          )}
          {notifiTimekeeping === "" && !checkVangmat && (
            <button
              className="list_items btn btn-purple-gradient btn-wave waves-effect waves-light fs-14 d-flex flex-direction-column align-items-center justify-content-center rounded-4"
              type="submit"
            >
              <IoFingerPrint className="me-1 fs-29" />
              Chấm công
            </button>
          )}
        </div>
        <div
          style={{ background: "#fa3333c2", color: "white" }}
          className="p-3 rounded-3"
        >
          Lưu ý: khi kết thúc giờ làm việc ca sáng hoặc ca chiều sẽ có 30 phút
          để bạn chấm công sau mỗi cuối giờ làm việc, nếu vượt quá hệ thống sẽ
          hủy ca làm việc đó.
        </div>
      </form>
    )
}

export default FormChamcong

// Thông báo chấm công thành công.
function NotifiTimekeepingSuccess({textNoti}) {
    return (
      <div className="text-center">
        <div>
          <IoMdCheckmarkCircle
            className={cx("text-success-1")}
            style={{ fontSize: "68px" }}
          />
        </div>
        <span className="fs-15">Bạn đã chấm công thành công.</span>
        <p>{textNoti}</p>
      </div>
    )
}

// Thông báo châm công thất bại
function NotifiTimekeepingFailure({textNoti}) {
  return (
    <div className="text-center">
      <div>
        <MdCancel className={cx("text-danger")} style={{ fontSize: "68px" }} />
      </div>
      <span className="fs-15">Chấm công thất bại. {textNoti}</span>
    </div>
  )
}


// Xử lý thêm mới chấm công buổi sáng.
function handleCreateDataTimekeepingMorning(socketio, object, timekeepingMorningStart, dataUser, gioLamviec) {
  // Set thời gian đi muộn
  let currentTime = new Date().getTime()
  let sophutDimuon = (currentTime - timekeepingMorningStart) / (60 * 1000)
  if (Number(sophutDimuon) < 0) {
    object.di_muon = 0
  } else {
    object.di_muon = sophutDimuon.toFixed(2)
  }
  if (currentTime <= timekeepingMorningStart) {
    object.ca_sang_a = moment().format("YYYY-MM-DD" + gioLamviec.current[0].ca_sang_a);
  } else {
    object.ca_sang_a = moment().format("YYYY-MM-DDTHH:mm:ss")
  }
  object.user_id = dataUser[0].id_user
  const paramsCreate = {
    iden: process.env.REACT_APP_IDEN_DASH,
    object: object,
  };
  socketio.emit("client-create-timekeeping", paramsCreate)
}

// Xử lý chấm công kết thúc buổi sáng.
function handleTimekeepingMorningEnd(socketio, object, checkDataTimekeeping, timekeepingMorningEnd, gioLamviec) {
  // set thời gian về sớm nếu có.
  let currentTime = new Date().getTime()
  let sophutvesom = (timekeepingMorningEnd - currentTime) / (60 * 1000)
  // Set object
  object.cong_chuan = 0.5
  if (Number(sophutvesom < 0)) {
    object.ve_som = 0
  } else {
    object.ve_som = sophutvesom.toFixed(2)
  }
  if (sophutvesom < 0) {
    object.ca_sang_b = moment().format("YYYY-MM-DD" + gioLamviec.current[0].ca_sang_b);
  } else {
    object.ca_sang_b = moment().format("YYYY-MM-DDTHH:mm:ss")
  }
  object.id = checkDataTimekeeping.current[0].id
  const paramsEdit = {
    iden: process.env.REACT_APP_IDEN_DASH,
    object: object,
  }
  socketio.emit("client-edit-morning-end-timekeeping", paramsEdit)
}



// Xử lý thêm mới chấm công buổi chiều.
function handleCreateDataTimekeepingAfternoon(socketio, object, timekeepingAfternoonStart, dataUser, gioLamviec) {
  // Set thời gian đi muộn
  let currentTime = new Date().getTime()
  let sophutDimuon = (currentTime - timekeepingAfternoonStart) / (60 * 1000)
  if (Number(sophutDimuon) < 0) {
    object.di_muon = 0
  } else {
    object.di_muon = sophutDimuon.toFixed(2)
  }
  if (currentTime <= timekeepingAfternoonStart) {
    object.ca_chieu_a = moment().format("YYYY-MM-DD"+ gioLamviec.current[0].ca_chieu_a)
  } else {
    object.ca_chieu_a = moment().format("YYYY-MM-DDTHH:mm:ss")
  }
  object.user_id = dataUser[0].id_user
  const paramsCreate = {
    iden: process.env.REACT_APP_IDEN_DASH,
    object: object,
  }
  socketio.emit("client-create-timekeeping", paramsCreate)
}


// Xử lý chấm công đầu giờ chiều.
function handleTimekeepingAfternoonStart(socketio, object, checkDataTimekeeping, timekeepingAfternoonStart, gioLamviec) {
  // Set thời gian đi muộn
  let currentTime = new Date().getTime();
  let sophutDimuon = (currentTime - timekeepingAfternoonStart) / (60 * 1000)
  // Set object
  if (Number(sophutDimuon) < 0) {
    object.di_muon = Number(checkDataTimekeeping.current[0].di_muon)
  } else {
    object.di_muon = (Number(checkDataTimekeeping.current[0].di_muon) + Number(sophutDimuon)).toFixed(2)
  }
  if (currentTime <= timekeepingAfternoonStart) {
    object.ca_chieu_a = moment().format("YYYY-MM-DD"+gioLamviec.current[0].ca_chieu_a)
  } else {
    object.ca_chieu_a = moment().format("YYYY-MM-DDTHH:mm:ss")
  }
  object.id = checkDataTimekeeping.current[0].id
  const paramsEdit = {
    iden: process.env.REACT_APP_IDEN_DASH,
    object: object
  }
  socketio.emit("client-edit-afternoon-start-timekeeping", paramsEdit)
}

// Xử lý chấm công cuối giờ chiều.
function handleTimekeepingAfternoonEnd(socketio, object, checkDataTimekeeping, timekeepingAfternoonEnd, gioLamviec) {
  // set thời gian về sớm nếu có.
  let currentTime = new Date().getTime();
  let sophutvesom = (timekeepingAfternoonEnd - currentTime) / (60 * 1000);
  // Set object
  object.cong_chuan = Number(checkDataTimekeeping.current[0].cong_chuan) + 0.5
  if (Number(sophutvesom < 0)) {
    object.ve_som = 0
  } else {
    object.ve_som = sophutvesom.toFixed(2)
  }
  if (sophutvesom < 0) {
    object.ca_chieu_b = moment().format("YYYY-MM-DD" + gioLamviec.current[0].ca_chieu_b)
  } else {
    object.ca_chieu_b = moment().format("YYYY-MM-DDTHH:mm:ss")
  }
  object.id = checkDataTimekeeping.current[0].id
  const paramsEdit = {
    iden: process.env.REACT_APP_IDEN_DASH,
    object: object,
  }
  socketio.emit("client-edit-afternoon-end-timekeeping", paramsEdit)
}