import SearchHorizontal from "../../../../Components/Search/SearchHorizontal"

function ComponentTopUserMobile({ result }) {
  const handleResultSearchUser = (valueText) => {
    result(valueText)
  }
  return (
    <div className="mt-4 mb-4">
      <h6>Danh sách user</h6>
      <div className="d-flex">
        <div className="col-12">
          <SearchHorizontal
            className="rounded-3 mt-2 col-12"
            style={{ border: "1px solid #485056" }}
            result={handleResultSearchUser}
          />
        </div>
      </div>
    </div>
  )
}

export default ComponentTopUserMobile
