import { memo, useContext, useEffect, useRef, useState } from "react";
import HeaderDataSaleMobile from "./HeaderDataSaleMobile"
import ListDataSaleMobile from "./ListDataSaleMobile";
import SearchDataSaleMobile from "./SearchDataSaleMobile"
import ListCateDataSaleMobile from "./ListCateDataSaleMobile";
import { useLocation } from "react-router-dom";
import AppContext from "../../../handle-reuses/Contexts/CreateContexts";
import LoadingSpinner from "../../../Components/Loading/LoadingSpinner";


function DataSalePageMobile() {
  const { dataUser, socketio } = useContext(AppContext)
  const path = useLocation()
  const [dataSale, setDataSale] = useState([])
  const limit = 51
  const page = useRef(1)
  const [loading, setLoading] = useState(true)
  const currentPath = path.pathname
  const myDiv = useRef()
  const isLoading = useRef(false)
  const checkLoadMore = useRef(false)
  const dataCategory = useRef([])

  // Quản lý search data
  const [dataSearchFast, setDataSearchFast] = useState([])
  const [searchFast, setSearchFast] = useState("")
  const checkSearchFast = useRef("")
  const [dataFilterDetail, setDataFilterDetail] = useState([])
  const checkFilterDetail = useRef()
  // search fast.
  const handleGetValueSearch = (keywords) => {
    // Xóa tìm kiếm bộ lọc trước đó.
    setDataFilterDetail([])
    // Xử lý tìm kiếm fast
    setSearchFast(keywords)
    checkSearchFast.current = keywords
    if (keywords !== "") {
      const paramsSearch = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: {
          goidichvu: dataUser[0].goidichvu_user,
          author: dataUser[0].id_user,
          keywords: keywords,
        },
      }
      if (currentPath === "/data-sale") {
        socketio.emit("client-search-keywords-data-sale-sanpham", paramsSearch)
      } else if (currentPath === "/khach-kinh-doanh") {
        socketio.emit(
          "client-search-keywords-khachkinhdoanh-sanpham",
          paramsSearch,
        )
      } else if (currentPath === "/khach-net") {
        socketio.emit("client-search-keywords-khachnet-sanpham", paramsSearch)
      } else if (currentPath === "/khach-hen") {
        socketio.emit("client-search-keywords-khachhen-sanpham", paramsSearch)
      } else if (currentPath === "/khach-het-han") {
        socketio.emit(
          "client-search-keywords-khach-het-han-sanpham",
          paramsSearch,
        )
      }
    }
  }
  // Quản lý Nhận data search fast
  useEffect(() => {
    if (currentPath === "/data-sale") {
      if (searchFast !== "") {
        socketio.on(
          "server-search-keywords-data-sale-sanpham",
          (dataSearchFast) => {
            setDataSearchFast([...dataSearchFast])
          },
        )
      } else {
        setDataSearchFast([])
      }
    } else if (currentPath === "/khach-kinh-doanh") {
      if (searchFast !== "") {
        socketio.on("server-search-keywords-khachkinhdoanh-sanpham", (data) => {
          setDataSearchFast([...data])
        })
      } else {
        setDataSearchFast([])
      }
    } else if (currentPath === "/khach-net") {
      if (searchFast !== "") {
        socketio.on("server-search-keywords-khachnet-sanpham", (data) => {
          setDataSearchFast([...data])
        })
      } else {
        setDataSearchFast([])
      }
    } else if (currentPath === "/khach-hen") {
      if (searchFast !== "") {
        socketio.on("server-search-keywords-khachhen-sanpham", (data) => {
          setDataSearchFast([...data])
        })
      } else {
        setDataSearchFast([])
      }
    } else if (currentPath === "/khach-het-han") {
      if (searchFast !== "") {
        socketio.on("server-search-keywords-khach-het-han-sanpham", (data) => {
          setDataSearchFast([...data])
        })
      } else {
        setDataSearchFast([])
      }
    }
    return () => {
      socketio.off("client-search-keywords-data-sale-sanpham")
      socketio.off("server-search-keywords-data-sale-sanpham")

      socketio.off("client-search-keywords-khachkinhdoanh-sanpham")
      socketio.off("server-search-keywords-khachkinhdoanh-sanpham")

      socketio.off("client-search-keywords-khachnet-sanpham")
      socketio.off("server-search-keywords-khachnet-sanpham")

      socketio.off("client-search-keywords-khachhen-sanpham")
      socketio.off("server-search-keywords-khachhen-sanpham")

      socketio.off("client-search-keywords-khach-het-han-sanpham")
      socketio.off("server-search-keywords-khach-het-han-sanpham")
    }
  }, [searchFast])
  // Quản lý làm mới lại các trường tìm kiếm
  useEffect(() => {
    setSearchFast("")
  }, [currentPath])

  // filter detail (bộ lọc).
  const handleFilterDetail = (objectFilter) => {
    // Xóa tìm kiếm trước đó(search fast)
    setDataSearchFast([])
    // Xử lý tìm kiếm bộ lọc
    checkFilterDetail.current = objectFilter
    if (objectFilter.note === "note") {
      const paramsNote = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: {
          goidichvu: dataUser[0].goidichvu_user,
          author: dataUser[0].id_user,
          note: objectFilter.text,
        },
      }
      // Chỉ tìm trong ghi chú
      if (currentPath === "/data-sale") {
        socketio.emit("client-filter-note-datasale-sanpham", paramsNote)
      }
    } else if (objectFilter.note === "data-sale") {
      // Chỉ tìm trong data sale
      const paramsDataSale = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: {
          goidichvu: dataUser[0].goidichvu_user,
          author: dataUser[0].id_user,
          keywords: objectFilter.text,
        },
      }
      socketio.emit("client-filter-data-sale-detail-sanpham", paramsDataSale)
    }
  }
  // Nhận data filter detail (bộ lọc)
  useEffect(() => {
    if (currentPath === "/data-sale" && checkFilterDetail.current) {
      if (checkFilterDetail.current.note === "note") {
        // Tìm theo ghi chú
        socketio.on("server-filter-note-datasale-sanpham", (dataFilter) => {
          let dataFilterNote = []
          if (dataFilter.length > 0) {
            for (let i = 0; i < dataFilter.length; i++) {
              const paramsDataSanpham = {
                iden: process.env.REACT_APP_IDEN_DASH,
                object: {
                  id_sp: dataFilter[i].kn_sanpham,
                },
              }
              socketio.emit(
                "client-filter-data-sale-idsp-sanpham",
                paramsDataSanpham,
              )
            }
          } else {
            alert("Không có kết quả phù hợp")
          }

          // Nhận dữ liệu.
          socketio.on("server-filter-data-sale-idsp-sanpham", (data) => {
            if (data.length > 0) {
              let filterNote = dataFilterNote.filter((item) => {
                return item.id_sp === data[0].id_sp
              })
              if (filterNote.length === 0) {
                dataFilterNote.push(data[0])
              }
            } 
            // Lọc theo các giá trị khác của sản phẩm.
            let filterDataNote = []
            if (
              checkFilterDetail.current.dateStart &&
              checkFilterDetail.current.dateEnd
            ) {
              filterDataNote = dataFilterNote.filter((item) => {
                let dateStart = new Date(
                  checkFilterDetail.current.dateStart,
                ).getTime()
                let dateEnd = new Date(
                  checkFilterDetail.current.dateEnd,
                ).getTime()
                let addtime = new Date(item.ngaycapnhat).getTime()
                return addtime >= dateStart && addtime <= dateEnd
              })
              setDataFilterDetail([...filterDataNote])
            } else if (
              checkFilterDetail.current.dateStart &&
              !checkFilterDetail.current.dateEnd
            ) {
              filterDataNote = dataFilterNote.filter((item) => {
                let dateStart = new Date(
                  checkFilterDetail.current.dateStart,
                ).getTime()
                let addtime = new Date(item.ngaycapnhat).getTime()
                return addtime >= dateStart
              })
              // Kiểm tra có tồn tại kết quả không
              setDataFilterDetail([...filterDataNote])
            } else if (
              !checkFilterDetail.current.dateStart &&
              checkFilterDetail.current.dateEnd
            ) {
              filterDataNote = dataFilterNote.filter((item) => {
                let dateEnd = new Date(
                  checkFilterDetail.current.dateEnd,
                ).getTime()
                let addtime = new Date(item.ngaycapnhat).getTime()
                return addtime <= dateEnd
              })
              // Kiểm tra có tồn tại kết quả không
              setDataFilterDetail([...filterDataNote])
            } else if (
              !checkFilterDetail.current.dateStart &&
              !checkFilterDetail.current.dateEnd
            ) {
              setDataFilterDetail([...dataFilterNote])
            }
          })
        })
      } else if (checkFilterDetail.current.note === "data-sale") {
        // Tìm theo data sale
        socketio.on("server-filter-data-sale-detail-sanpham", (data) => {
          // Lọc theo ngày bắt đầu , ngày kết thúc.
          let filterDataNote = []
          if (checkFilterDetail.current.dateStart && checkFilterDetail.current.dateEnd) {
            filterDataNote = data.filter((item) => {
              let dateStart = new Date(
                checkFilterDetail.current.dateStart,
              ).getTime()
              let dateEnd = new Date(
                checkFilterDetail.current.dateEnd,
              ).getTime()
              let addtime = new Date(item.ngaycapnhat).getTime()
              return addtime >= dateStart && addtime <= dateEnd
            });
            if (filterDataNote.length > 0) {
              setDataFilterDetail([...filterDataNote])
            } else {
              alert("Không có sản phẩm phù hợp.")
            }

          }else if (checkFilterDetail.current.dateStart && !checkFilterDetail.current.dateEnd) {
            filterDataNote = data.filter((item) => {
              let dateStart = new Date(
                checkFilterDetail.current.dateStart,
              ).getTime()
              let addtime = new Date(item.ngaycapnhat).getTime()
              return addtime >= dateStart
            });
            if (filterDataNote.length > 0) {
              setDataFilterDetail([...filterDataNote])
            } else {
              alert("Không có sản phẩm phù hợp.")
            }
          }else if (!checkFilterDetail.current.dateStart && checkFilterDetail.current.dateEnd) {
            filterDataNote = data.filter((item) => {
              
              let dateEnd = new Date(
                checkFilterDetail.current.dateEnd,
              ).getTime()
              let addtime = new Date(item.ngaycapnhat).getTime()
              return addtime <= dateEnd
            });
            if (filterDataNote.length > 0) {
              setDataFilterDetail([...filterDataNote])
            } else {
              alert("Không có sản phẩm phù hợp.")
            }
          }else if (!checkFilterDetail.current.dateStart && !checkFilterDetail.current.dateEnd) {
            if (data.length > 0) {
              setDataFilterDetail([...data])
            } else {
              alert("Không có sản phẩm phù hợp.")
            }
          }
        })
      }
    } else {
      setDataFilterDetail([]) // trả bộ lọc về rỗng
    }
    return () => {
      socketio.off("client-filter-note-datasale-sanpham")
      socketio.off("server-filter-note-datasale-sanpham")
      socketio.off("client-filter-data-sale-idsp-sanpham")
      socketio.off("server-filter-data-sale-idsp-sanpham")
      socketio.off("client-filter-data-sale-detail-sanpham")
      socketio.off("server-filter-data-sale-detail-sanpham")
    }
  }, [currentPath, checkFilterDetail.current])

  // Quản lý get data sale (tất cả).
  const handleGetDataSale = (limit_, page_) => {
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        author: dataUser[0].id_user,
        goidichvu: dataUser[0].goidichvu_user,
        quantity: limit_,
        offset: (page_ - 1) * limit_,
      },
    }
    socketio.emit("client-get-data-sale-sanpham", params)
  }
  // get data theo danh mục
  const handleGetDatAuthorCate = (cate) => {
    const paramsCate = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        author: dataUser[0].id_user,
        goidichvu: dataUser[0].goidichvu_user,
      },
    };
    setDataSale([])
    setLoading(true)
    if (cate === "kinh-doanh") {
      socketio.emit("client-get-data-cate-product-kinhdoanh", paramsCate)
    } else if (cate === "khach-net") {
      socketio.emit("client-get-data-cate-product-khachnet", paramsCate)
    } else if (cate === "khach-hen") {
      socketio.emit("client-get-data-cate-product-booking", paramsCate)
    } else if (cate === "khach-het-han") {
      socketio.emit("client-data-expired-ads-data-sale-sanpham", paramsCate)
    }
  }
  // Nhận dữ liệu ban đầu.
  useEffect(() => {
    if (dataUser.length > 0) {
      // Điều kiện hiển thị theo doanh mục
      if (currentPath === "/data-sale") {
        page.current = 1
        setDataSale([])
        handleGetDataSale(limit, page.current)
      } else if (currentPath === "/khach-kinh-doanh") {
        handleGetDatAuthorCate("kinh-doanh")
      } else if (currentPath === "/khach-net") {
        handleGetDatAuthorCate("khach-net")
      } else if (currentPath === "/khach-hen") {
        handleGetDatAuthorCate("khach-hen")
      } else if (currentPath === "/khach-het-han") {
        handleGetDatAuthorCate("khach-het-han")
      }
    }
    return () => {
      socketio.off("client-get-data-sale-sanpham")
      socketio.off("client-get-data-cate-product-kinhdoanh")
      socketio.off("client-get-data-cate-product-khachnet")
      socketio.off("client-get-data-cate-product-booking")
      socketio.off("client-data-expired-ads-data-sale-sanpham")
    }
  }, [currentPath])

  // Quản lý nhận data.
  const dataKhachhen = useRef([])
  useEffect(() => {
    // Tất cả data
    if (currentPath === "/data-sale") {
      socketio.on("server-get-data-sale-sanpham", (dataCustomerSale) => {
        setDataSale((old) => [...old, ...dataCustomerSale])
        setLoading(false)
        checkLoadMore.current = false
        // Xác nhận đã lấy hêt data
        if (limit > dataCustomerSale.length) {
          isLoading.current = true
        }
      })
    }
    // Data theo khách kinh doanh.
    if (currentPath === "/khach-kinh-doanh") {
      socketio.on("server-get-data-cate-product-kinhdoanh", (data) => {
        dataCategory.current = [] // làm mới lại data
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            const paramsCateKinhdoanh = {
              iden: process.env.REACT_APP_IDEN_DASH,
              object: {
                id_sp: data[i].kn_sanpham,
              },
            }
            socketio.emit(
              "client-search-data-sale-author-id-sanpham",
              paramsCateKinhdoanh,
            )
          }
          
        } else {
          setDataSale([])
          setLoading(false)
        }
      })
    }
    // Data khách net.
    if (currentPath === "/khach-net") {
      socketio.on("server-get-data-cate-product-khachnet", (data) => {
        dataCategory.current = []
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            const paramsCateKhachnet = {
              iden: process.env.REACT_APP_IDEN_DASH,
              object: {
                id_sp: data[i].kn_sanpham,
              },
            }
            socketio.emit(
              "client-search-data-sale-author-id-sanpham",
              paramsCateKhachnet,
            )
          }
        } else {
          setDataSale([])
          setLoading(false)
        }
      })
    }
    // Data khách hẹn.
    if (currentPath === "/khach-hen") {
      socketio.on("server-get-data-cate-product-booking", (data) => {
        dataCategory.current = []
        dataKhachhen.current = [...data] // dùng để xác định thời gian cho khách hẹn
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            const paramsCateKhachhen = {
              iden: process.env.REACT_APP_IDEN_DASH,
              object: {
                id_sp: data[i].kn_sanpham,
              },
            }
            socketio.emit(
              "client-search-data-sale-author-id-sanpham",
              paramsCateKhachhen,
            )
          }
        } else {
          setDataSale([])
          setLoading(false)
        }
      })
    }
    // Data khách hết hạn.
    if (currentPath === "/khach-het-han") {
      socketio.on("server-data-expired-ads-data-sale-sanpham", (data) => {
        setDataSale([...data])
        setLoading(false)
      })
    }
    return () => {
      socketio.off("server-get-data-sale-sanpham")
      socketio.off("server-get-data-cate-product-kinhdoanh")
      socketio.off("server-get-data-cate-product-khachnet")
      socketio.off("client-search-data-sale-author-id-sanpham")
      socketio.off("server-data-expired-ads-data-sale-sanpham")
    }
  }, [currentPath])

  // Quản lý nhận data category.
  useEffect(() => {
    // Nhận data category
    socketio.on("server-search-data-sale-author-id-sanpham", (dataCateKD) => {
      if (dataCateKD.length > 0) {
        let filterCateKD = dataCategory.current.filter((item) => {
          return item.id_sp === dataCateKD[0].id_sp
        })
        if (filterCateKD.length === 0) {
          dataCategory.current.push(dataCateKD[0])
        }
        setDataSale([...dataCategory.current])
        setLoading(false)
      };
      
    })

    return () => {
      socketio.off("server-search-data-sale-author-id-sanpham")
    }
  }, [])

  // Quản lý sự kiện scroll.
  useEffect(() => {
    const containerDiv = myDiv.current
    const handleAutoScroll = () => {
      const totalHeight_ = containerDiv.scrollHeight
      const scrollWin = window.innerHeight
      const scrollY_ = window.scrollY
      // Điều kiện loadmore data
      if (
        scrollY_ + scrollWin > totalHeight_ &&
        !isLoading.current &&
        !checkLoadMore.current &&
        currentPath === "/data-sale" &&
        checkSearchFast.current === "" &&
        !checkFilterDetail.current
      ) {
        checkLoadMore.current = true
        setTimeout(() => {
          page.current = page.current + 1
          handleGetDataSale(limit, page.current)
        }, 500)
      }
    }

    window.addEventListener("scroll", handleAutoScroll)
    return () => {
      window.removeEventListener("scroll", handleAutoScroll)
    }
  }, [currentPath])

  // Xác nhận đã có sự thay đổi dữ liệu.
  const handleStatusData = (status_) => {
    if (status_) {
      // console.log(status_)
    }
  }



  return (
    <div className="main-content" ref={myDiv}>
      <div className="container-fluid">
        <HeaderDataSaleMobile />
      </div>
      {/* container */}
      <div className="mt-3">
        {/* List cate */}
        <ListCateDataSaleMobile dataKhachhen={dataKhachhen.current} />
        {/* Search */}
        <SearchDataSaleMobile
          resultSearchFast={handleGetValueSearch}
          resultSearchFilter={handleFilterDetail}
        />
        {/* List customer */}
        {loading && <div className="text-center mt-3">Đang tải dữ liệu...</div>}
        {!loading && (
          <ListDataSaleMobile
            dataCusSale={dataSale}
            dataKhachhen={dataKhachhen.current}
            isLoading={isLoading.current}
            dataSearchFast={dataSearchFast}
            checkSearchFast={checkSearchFast.current}
            dataFilterDetail={dataFilterDetail}
            socketio={socketio}
            resultStatus={handleStatusData}
          />
        )}
      </div>
    </div>
  )
}
export default memo(DataSalePageMobile)