import { useContext, useEffect, useRef, useState } from "react"
import classNames from "classnames/bind";
import { useForm } from "react-hook-form" 
import { handleFormatPrice } from "../../../handle-reuses/reuses";
import AppContext from "../../../handle-reuses/Contexts/CreateContexts";
import styles from "../DataSale.module.scss"
const cx = classNames.bind(styles);

function FormEditDataSale({dataSaleEdit, indexEdit, resultStatus}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()
  const { socketio, dataUser } = useContext(AppContext)
  // Xử lý format price.
  const [formatPrice, setFormatPrice] = useState("")
  const showPrice = useRef()
  const [formatPriceM, setFormatPriceM] = useState("")


  const handleFormatPriceForm = (e) => {
    let price_ = e.target.value.replace(/[.,]/g, "")
    let formatPrice_ = Number(price_).toLocaleString().replace(/[.]/g, ",")
    setFormatPrice(formatPrice_)
    // Hiển thị dạng chữ.
    showPrice.current = handleFormatPrice(Number(price_))
    setFormatPriceM("")
  }

  // Hiển thị trường nhập giá trên m2.
  const [showInputM, setShowInputM] = useState(false)
  const handleShowGiaMetvuong = (key) => {
    if (key === "giam2") {
      setShowInputM(true)
    } else {
      setShowInputM(false)
    }
  }
  // Xử lý format giá trên m2
  const handleFormatPriceFormM = (e) => {
    let format_ = e.target.value.replace(/[.,]/g, "")
    let format__ = Number(format_).toLocaleString().replace(/[.]/g, ",")
    setFormatPriceM(format__)
    setFormatPrice("")
  }

  /** Xử lý gợi ý tuyenduong. */
  const [suggestTuyenduong, setSuggestTuyenduong] = useState(
    dataSaleEdit[0].tuyenduong,
  )
  const [dataTuyenduong, setDataTuyenduong] = useState([])
  const handleGetValueTuyenduong = (e) => {
    setSuggestTuyenduong(e.target.value)
    // xử lý gợi ý.
    const paramsTuyenduong = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        tuyenduong: e.target.value,
      },
    }
    socketio.emit("client-get-data-tuyenduong", paramsTuyenduong)
    
  }
  useEffect(() => {
    socketio.on("server-get-data-tuyenduong", (dataSuggest) => {
      setDataTuyenduong([...dataSuggest])
    })
    return () => {
      socketio.off("client-get-data-tuyenduong")
      socketio.off("server-get-data-tuyenduong")
    }
  }, [suggestTuyenduong])
  // Set value tuyenduong.
  const handleSetValueTuyenduong = (key) => {
    setSuggestTuyenduong(key)
  }
  /** *Xử lý gợi ý tuyenduong. */

  /** Quản lý gợi ý phuongxa */
  const [suggestPhuongxa, setSuggestPhuongxa] = useState(
    dataSaleEdit[0].phuongxa,
  )
  const [checkPhuongxa, setCheckPhuongxa] = useState(false)
  const handleGetValuePhuongxa = (e) => {
    setSuggestPhuongxa(e.target.value)
    setCheckPhuongxa(true)
  }
  const handleSetValuePhuongxa = (key) => {
    setSuggestPhuongxa(key)
    if (key) {
      setCheckPhuongxa(false)
    }
  }
  /** Quản lý gợi ý phuongxa */

  /** Quản lý gợi ý Quanhuyen */
  const [suggestQuanhuyen, setSuggestQuanhuyen] = useState(
    dataSaleEdit[0].quanhuyen,
  )
  const [checkQuanhuyen, setCheckQuanhuyen] = useState(false)
  const handleGetValueQuanhuyen = (e) => {
    setSuggestQuanhuyen(e.target.value)
    setCheckQuanhuyen(true)
  }
  const handleSetValueQuanhuyen = (key) => {
    setSuggestQuanhuyen(key)
    if (key) {
      setCheckQuanhuyen(false)
    }
  }
  /** Quản lý gợi ý Quanhuyen */

  const checkAddSuccess = useRef()
  const handleOnSubmit = (data) => {
    data.id_sp = dataSaleEdit[0].id_sp
    if (formatPriceM) {
      let xacdinh =
        Number(data.dtmb_mt) * Number(data.giam2.replace(/[,.]/g, ""))
      data.giasanpham = Number(xacdinh).toLocaleString().replace(/[.]/g, ",")
    }
    delete data.giam2
    data.tuyenduong = suggestTuyenduong
    data.phuongxa = suggestPhuongxa
    data.quanhuyen = suggestQuanhuyen
    data.trucngo = data.trucngo.replace(/[,]/g, ".")
    data.dtmb_mt = data.dtmb_mt.replace(/[,]/g, ".")
    data.mattien = data.mattien.replace(/[,]/g, ".")
    data.sotang = data.sotang.replace(/[,]/g, ".")
    data.dt_xd = data.dt_xd.replace(/[,]/g, ".")

    // Xử lý thêm mới data.
    const paramsObject = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: data,
    }
    socketio.emit("client-edit-data-sale-sanpham", paramsObject)
    socketio.on("server-edit-data-sale-sanpham", (dataStatus) => {
      if (dataStatus === "success") {
        reset()
        resultStatus({ status: true, id_sp: dataSaleEdit[0].id_sp, index: indexEdit })
        alert("Sửa thông tin thành công.")

        // Thêm dữ liệu vào thay đổi lịch sử.
        let objectHistory = {}
        // Thay đổi diachi
        if (dataSaleEdit[0].diachi !== data.diachi) {
          objectHistory.tieude = "Thay đổi địa chỉ"
          objectHistory.note_old = dataSaleEdit[0].diachi
          objectHistory.note_new = data.diachi
          objectHistory.kn_sanpham = dataSaleEdit[0].id_sp
          objectHistory.tacgia = dataUser[0].id_user
          let paramsAddHis = {
            iden: process.env.REACT_APP_IDEN_DASH,
            object: objectHistory,
          }
          socketio.emit("client-create-data-history-sanpham", paramsAddHis)
        }
        // Thay đổi dt-xd
        if (dataSaleEdit[0].dt_xd !== data.dt_xd) {
          objectHistory.tieude = "Thay đổi diện tích xây dựng"
          objectHistory.note_old = dataSaleEdit[0].dt_xd
          objectHistory.note_new = data.dt_xd
          objectHistory.kn_sanpham = dataSaleEdit[0].id_sp
          objectHistory.tacgia = dataUser[0].id_user
          let paramsAddHis = {
            iden: process.env.REACT_APP_IDEN_DASH,
            object: objectHistory,
          }
          socketio.emit("client-create-data-history-sanpham", paramsAddHis)
        }
        // Thay đổi dtmb_mt.
        if (dataSaleEdit[0].dtmb_mt !== data.dtmb_mt) {
          objectHistory.tieude = "Thay đổi diện tích mặt bằng"
          objectHistory.note_old = dataSaleEdit[0].dtmb_mt
          objectHistory.note_new = data.dtmb_mt
          objectHistory.kn_sanpham = dataSaleEdit[0].id_sp
          objectHistory.tacgia = dataUser[0].id_user
          let paramsAddHis = {
            iden: process.env.REACT_APP_IDEN_DASH,
            object: objectHistory,
          }
          socketio.emit("client-create-data-history-sanpham", paramsAddHis)
        }
        // Thay đổi giasanpham.
        if (dataSaleEdit[0].giasanpham !== data.giasanpham) {
          objectHistory.tieude = "Thay đổi giá sản phẩm"
          objectHistory.note_old = dataSaleEdit[0].giasanpham
          objectHistory.note_new = data.giasanpham
          objectHistory.kn_sanpham = dataSaleEdit[0].id_sp
          objectHistory.tacgia = dataUser[0].id_user
          let paramsAddHis = {
            iden: process.env.REACT_APP_IDEN_DASH,
            object: objectHistory,
          }
          socketio.emit("client-create-data-history-sanpham", paramsAddHis)
        }
        // Thay đổi hoten.
        if (dataSaleEdit[0].hoten !== data.hoten) {
          objectHistory.tieude = "Thay đổi họ tên khách hàng"
          objectHistory.note_old = dataSaleEdit[0].hoten
          objectHistory.note_new = data.hoten
          objectHistory.kn_sanpham = dataSaleEdit[0].id_sp
          objectHistory.tacgia = dataUser[0].id_user
          let paramsAddHis = {
            iden: process.env.REACT_APP_IDEN_DASH,
            object: objectHistory,
          }
          socketio.emit("client-create-data-history-sanpham", paramsAddHis)
        }
        // Thay đổi huongnha.
        if (dataSaleEdit[0].huongnha !== data.huongnha) {
          objectHistory.tieude = "Thay đổi hướng nhà"
          objectHistory.note_old = dataSaleEdit[0].hoten
          objectHistory.note_new = data.hoten
          objectHistory.kn_sanpham = dataSaleEdit[0].id_sp
          objectHistory.tacgia = dataUser[0].id_user
          let paramsAddHis = {
            iden: process.env.REACT_APP_IDEN_DASH,
            object: objectHistory,
          }
          socketio.emit("client-create-data-history-sanpham", paramsAddHis)
        }
        // Thay đổi loaibds.
        if (dataSaleEdit[0].loaibds !== data.loaibds) {
          objectHistory.tieude = "Thay đổi loại BDS"
          objectHistory.note_old = dataSaleEdit[0].loaibds
          objectHistory.note_new = data.loaibds
          objectHistory.kn_sanpham = dataSaleEdit[0].id_sp
          objectHistory.tacgia = dataUser[0].id_user
          let paramsAddHis = {
            iden: process.env.REACT_APP_IDEN_DASH,
            object: objectHistory,
          }
          socketio.emit("client-create-data-history-sanpham", paramsAddHis)
        }
        // Thay đổi mattien.
        if (dataSaleEdit[0].mattien !== data.mattien) {
          objectHistory.tieude = "Thay đổi mặt tiền"
          objectHistory.note_old = dataSaleEdit[0].mattien
          objectHistory.note_new = data.mattien
          objectHistory.kn_sanpham = dataSaleEdit[0].id_sp
          objectHistory.tacgia = dataUser[0].id_user
          let paramsAddHis = {
            iden: process.env.REACT_APP_IDEN_DASH,
            object: objectHistory,
          }
          socketio.emit("client-create-data-history-sanpham", paramsAddHis)
        }
        // Thay đổi phaply
        if (dataSaleEdit[0].phaply !== data.phaply) {
          objectHistory.tieude = "Thay đổi pháp lý"
          objectHistory.note_old = dataSaleEdit[0].phaply
          objectHistory.note_new = data.phaply
          objectHistory.kn_sanpham = dataSaleEdit[0].id_sp
          objectHistory.tacgia = dataUser[0].id_user
          let paramsAddHis = {
            iden: process.env.REACT_APP_IDEN_DASH,
            object: objectHistory,
          }
          socketio.emit("client-create-data-history-sanpham", paramsAddHis)
        }
        // Thay đổi phuongxa
        if (dataSaleEdit[0].phuongxa !== data.phuongxa) {
          objectHistory.tieude = "Thay đổi phường xã"
          objectHistory.note_old = dataSaleEdit[0].phuongxa
          objectHistory.note_new = data.phuongxa
          objectHistory.kn_sanpham = dataSaleEdit[0].id_sp
          objectHistory.tacgia = dataUser[0].id_user
          let paramsAddHis = {
            iden: process.env.REACT_APP_IDEN_DASH,
            object: objectHistory,
          }
          socketio.emit("client-create-data-history-sanpham", paramsAddHis)
        }
        // Thay đổi quanhuyen
        if (dataSaleEdit[0].quanhuyen !== data.quanhuyen) {
          objectHistory.tieude = "Thay đổi quận huyện"
          objectHistory.note_old = dataSaleEdit[0].quanhuyen
          objectHistory.note_new = data.quanhuyen
          objectHistory.kn_sanpham = dataSaleEdit[0].id_sp
          objectHistory.tacgia = dataUser[0].id_user
          let paramsAddHis = {
            iden: process.env.REACT_APP_IDEN_DASH,
            object: objectHistory,
          }
          socketio.emit("client-create-data-history-sanpham", paramsAddHis)
        }
        // Thay đổi sodienthoai
        if (dataSaleEdit[0].sodienthoai !== data.sodienthoai) {
          objectHistory.tieude = "Thay đổi số điện thoại"
          objectHistory.note_old = dataSaleEdit[0].sodienthoai
          objectHistory.note_new = data.sodienthoai
          objectHistory.kn_sanpham = dataSaleEdit[0].id_sp
          objectHistory.tacgia = dataUser[0].id_user
          let paramsAddHis = {
            iden: process.env.REACT_APP_IDEN_DASH,
            object: objectHistory,
          }
          socketio.emit("client-create-data-history-sanpham", paramsAddHis)
        }
        // Thay đổi sotang
        if (dataSaleEdit[0].sotang !== data.sotang) {
          objectHistory.tieude = "Thay đổi số tầng"
          objectHistory.note_old = dataSaleEdit[0].sotang
          objectHistory.note_new = data.sotang
          objectHistory.kn_sanpham = dataSaleEdit[0].id_sp
          objectHistory.tacgia = dataUser[0].id_user
          let paramsAddHis = {
            iden: process.env.REACT_APP_IDEN_DASH,
            object: objectHistory,
          }
          socketio.emit("client-create-data-history-sanpham", paramsAddHis)
        }
        // Thay đổi thanhpho
        if (dataSaleEdit[0].thanhpho !== data.thanhpho) {
          objectHistory.tieude = "Thay đổi thành phố"
          objectHistory.note_old = dataSaleEdit[0].thanhpho
          objectHistory.note_new = data.thanhpho
          objectHistory.kn_sanpham = dataSaleEdit[0].id_sp
          objectHistory.tacgia = dataUser[0].id_user
          let paramsAddHis = {
            iden: process.env.REACT_APP_IDEN_DASH,
            object: objectHistory,
          }
          socketio.emit("client-create-data-history-sanpham", paramsAddHis)
        }
        // Thay đổi trucngo
        if (dataSaleEdit[0].trucngo !== data.trucngo) {
          objectHistory.tieude = "Thay đổi trục ngõ"
          objectHistory.note_old = dataSaleEdit[0].trucngo
          objectHistory.note_new = data.trucngo
          objectHistory.kn_sanpham = dataSaleEdit[0].id_sp
          objectHistory.tacgia = dataUser[0].id_user
          let paramsAddHis = {
            iden: process.env.REACT_APP_IDEN_DASH,
            object: objectHistory,
          }
          socketio.emit("client-create-data-history-sanpham", paramsAddHis)
        }
        // Thay đổi tuyenduong
        if (dataSaleEdit[0].tuyenduong !== data.tuyenduong) {
          objectHistory.tieude = "Thay đổi tuyến đường"
          objectHistory.note_old = dataSaleEdit[0].tuyenduong
          objectHistory.note_new = data.tuyenduong
          objectHistory.kn_sanpham = dataSaleEdit[0].id_sp
          objectHistory.tacgia = dataUser[0].id_user
          let paramsAddHis = {
            iden: process.env.REACT_APP_IDEN_DASH,
            object: objectHistory,
          }
          socketio.emit("client-create-data-history-sanpham", paramsAddHis)
        }
        checkAddSuccess.current = data
      }
    })
  }

  // Quản lý tắt socketio
  useEffect(() => {
    return () => {
      socketio.off("client-edit-data-sale-sanpham")
      socketio.off("server-edit-data-sale-sanpham")
      socketio.off("client-create-data-history-sanpham")
    }
  }, [checkAddSuccess.current])

  if (dataSaleEdit.length === 0) {
    return
  }
  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      {/* Địa chỉ */}
      <div>
        <h6 className="text-muted">Địa chỉ</h6>
      </div>
      <div className="col-xl-12 col-md-12">
        <div className="form-floating mb-3">
          <input
            type="text"
            className="form-control"
            defaultValue={dataSaleEdit[0].diachi && dataSaleEdit[0].diachi}
            placeholder="Tên input"
            {...register("diachi", { required: true })}
          />
          <label className="fs-15 text-muted">
            Địa chỉ <span className="text-danger">*</span>
          </label>
          {errors.diachi && (
            <span className="fs-12 text-danger">Bạn chưa nhập địa chỉ</span>
          )}
        </div>
      </div>
      <div className="col-xl-12 col-md-12">
        <div className="row">
          <div className="form-floating mb-3 col-6">
            <input
              type="text"
              className="form-control"
              value={suggestTuyenduong}
              placeholder="Tên input"
              {...register("tuyenduong", { required: true })}
              onChange={handleGetValueTuyenduong}
            />
            <label className="fs-15 ms-2 text-muted">
              Tuyến đường <span className="text-danger">*</span>
            </label>
            {errors.tuyenduong && (
              <span className="fs-12 text-danger">
                Bạn chưa nhập tuyến đường
              </span>
            )}
            {/* Gợi ý */}
            {dataTuyenduong.length > 0 && (
              <ListSuggestTuyenduong
                dataList={dataTuyenduong}
                valueInput={suggestTuyenduong}
                result={handleSetValueTuyenduong}
              />
            )}
          </div>
          <div className="form-floating mb-3 col-6">
            <input
              type="text"
              className="form-control"
              value={suggestPhuongxa}
              placeholder="Tên input"
              {...register("phuongxa")}
              onChange={handleGetValuePhuongxa}
            />
            <label className="fs-15 ms-2 text-muted">Phường/Xã</label>
            {/* Gợi ý */}
            {dataTuyenduong.length > 0 && checkPhuongxa && (
              <ListSuggestPhuongxa
                dataList={dataTuyenduong}
                valueInput={suggestPhuongxa}
                result={handleSetValuePhuongxa}
              />
            )}
          </div>
        </div>
      </div>
      <div className="col-xl-12 col-md-12">
        <div className="row">
          <div className="form-floating mb-3 col-6">
            <input
              type="text"
              className="form-control"
              value={suggestQuanhuyen}
              placeholder="Tên input"
              {...register("quanhuyen", { required: true })}
              onChange={handleGetValueQuanhuyen}
            />
            <label className="fs-15 ms-2 text-muted">
              Quận huyện <span className="text-danger">*</span>
            </label>
            {errors.quanhuyen && (
              <span className="fs-12 text-danger">
                Bạn chưa nhập quận huyện
              </span>
            )}
            {/* Gợi ý */}
            {dataTuyenduong.length > 0 && checkQuanhuyen && (
              <ListSuggestQuanhuyen
                dataList={dataTuyenduong}
                valueInput={suggestQuanhuyen}
                result={handleSetValueQuanhuyen}
              />
            )}
          </div>
          <div className="form-floating mb-3 col-6">
            <input
              type="text"
              className="form-control"
              defaultValue={
                dataSaleEdit[0].thanhpho && dataSaleEdit[0].thanhpho
              }
              placeholder="Tên input"
              {...register("thanhpho", { required: true })}
            />
            <label className="fs-15 ms-2 text-muted">
              Thành phố <span className="text-danger">*</span>
            </label>
            {errors.thanhpho && (
              <span className="fs-12 text-danger">Bạn chưa nhập thành phố</span>
            )}
          </div>
        </div>
      </div>
      {/* Thông tin */}
      <div className="border-top border-block-start-dashed mt-3 mb-3"></div>
      <div>
        <h6 className="text-muted">Thông tin</h6>
      </div>
      <div className="col-xl-12 col-md-12">
        <div className="row">
          <div className="form-floating mb-3 col-6">
            <input
              type="number"
              step="0.1"
              className="form-control"
              defaultValue={dataSaleEdit[0].trucngo && dataSaleEdit[0].trucngo}
              placeholder="Tên input"
              {...register("trucngo")}
            />
            <label className="fs-15 ms-2 text-muted">Trục ngõ</label>
          </div>
          <div className="form-floating mb-3 col-6">
            <input
              type="text"
              className="form-control"
              defaultValue={dataSaleEdit[0].dtmb_mt && dataSaleEdit[0].dtmb_mt}
              placeholder="Tên input"
              {...register("dtmb_mt")}
            />
            <label className="fs-15 ms-2 text-muted">Diện tích</label>
          </div>
        </div>
      </div>
      <div className="col-xl-12 col-md-12">
        <div className="row">
          <div className="form-floating mb-3 col-6">
            <input
              type="text"
              className="form-control"
              placeholder="Tên input"
              defaultValue={dataSaleEdit[0].mattien && dataSaleEdit[0].mattien}
              {...register("mattien")}
            />
            <label className="fs-15 ms-2 text-muted">Mặt tiền</label>
          </div>
          <div className="form-floating mb-3 col-6">
            <input
              type="text"
              className="form-control"
              placeholder="Tên input"
              defaultValue={
                dataSaleEdit[0].sotang &&
                dataSaleEdit[0].sotang.replace(/[,]/g, ".")
              }
              {...register("sotang")}
            />
            <label className="fs-15 ms-2 text-muted">Số tầng</label>
          </div>
        </div>
      </div>
      <div className="col-xl-12 col-md-12">
        <div className="row">
          <div className="form-floating mb-3 col-6">
            <input
              type="text"
              className="form-control"
              placeholder="Tên input"
              defaultValue={
                dataSaleEdit[0].dt_xd &&
                dataSaleEdit[0].dt_xd.replace(/[,]/g, ".")
              }
              {...register("dt_xd")}
            />
            <label className="fs-15 ms-2 text-muted">Diện tích XD</label>
          </div>
          <div className="form-floating mb-3 col-6">
            <select
              className="form-select"
              {...register("huongnha")}
              defaultValue={
                dataSaleEdit[0].huongnha && dataSaleEdit[0].huongnha
              }
            >
              <option value="">Chọn hướng</option>
              <option value="Đông">Đông</option>
              <option value="Nam">Nam</option>
              <option value="Bắc">Bắc</option>
              <option value="Tây">Tây</option>
              <option value="Đông nam">Đông nam</option>
              <option value="Tây nam">Tây nam</option>
              <option value="Đông bắc">Đông bắc</option>
              <option value="Tây bắc">Tây bắc</option>
            </select>
            <label className="fs-15 ms-2 text-muted">Hướng nhà</label>
          </div>
        </div>
      </div>
      <div className="col-xl-12 col-md-12">
        <div className="row">
          <div className="form-floating mb-3 col-6">
            <select
              className="form-select"
              {...register("phaply")}
              defaultValue={dataSaleEdit[0].phaply && dataSaleEdit[0].phaply}
            >
              <option value="">Chọn pháp lý</option>
              <option value="Sổ hồng">Sổ hồng</option>
              {/* <option value="Sổ đỏ">Sổ đỏ</option> */}
              <option value="Trích đo">Trích đo</option>
              <option value="Chủ đầu tư">Chủ đầu tư</option>
              <option value="Viết tay">Viết tay</option>
              <option value="Hợp lệ">Hợp lệ</option>
              <option value="Loại khác">Loại khác</option>
            </select>
            <label className="fs-15 ms-2 text-muted">Giấy tờ</label>
          </div>
          <div className="form-floating mb-3 col-6">
            <select
              className="form-select"
              {...register("loaibds")}
              defaultValue={dataSaleEdit[0].loaibds && dataSaleEdit[0].loaibds}
            >
              <option value="">Chọn loại BDS</option>
              <option value="Bán nhà">Bán nhà</option>
              <option value="Bán đất nền">Bán đất nền</option>
              <option value="Bán đất TDC">Bán đất TDC</option>
              <option value="Bán căn hộ">Bán căn hộ</option>
              <option value="Bán chung cư">Bán chung cư</option>
              <option value="Cho thuê nhà">Cho thuê nhà</option>
              <option value="Cho thuê căn hộ">Cho thuê căn hộ</option>
              <option value="Cho thuê phòng trọ">Cho thuê phòng trọ</option>
              <option value="Cho thuê đất">Cho thuê đất</option>
              <option value="Cho thuê mặt bằng">Cho thuê mặt bằng</option>
              <option value="Sang nhượng">Sang nhượng</option>
            </select>
            <label className="fs-15 ms-2 text-muted">Loại BĐS</label>
          </div>
        </div>
      </div>
      <div className="col-xl-12 col-md-12">
        <div className="mb-2 d-flex justify-content-end align-items-center">
          <button
            type="button"
            className={cx(
              "border-0 rounded-1 me-2 fs-14",
              showInputM ? "active" : "",
            )}
            onClick={() => handleShowGiaMetvuong("giam2")}
          >
            Giá/m<sup>2</sup>
          </button>
          <button
            type="button"
            className={cx(
              "border-0 rounded-1 fs-14",
              !showInputM ? "active" : "",
            )}
            onClick={() => handleShowGiaMetvuong("giatong")}
          >
            Giá tổng
          </button>
        </div>
        <div
          className="form-floating mb-3 col-12"
          style={{ display: showInputM ? "block" : "none" }}
        >
          <input
            type="text"
            className="form-control"
            value={formatPriceM}
            placeholder="Tên input"
            {...register("giam2")}
            onChange={handleFormatPriceFormM}
          />
          <label className="fs-15 text-muted">
            Giá/m<sup>2</sup>
          </label>
        </div>
        <div
          className="form-floating mb-3 col-12"
          style={{ display: showInputM ? "none" : "block" }}
        >
          <input
            type="text"
            className="form-control"
            value={formatPrice ? formatPrice : dataSaleEdit[0].giasanpham}
            placeholder="Tên input"
            {...register("giasanpham")}
            onChange={handleFormatPriceForm}
          />
          <label className="fs-15 text-muted">
            Giá sản phẩm{" "}
            <span className="text-danger">{showPrice.current}</span>
          </label>
        </div>
      </div>
      {/* Liên hệ */}
      <div className="border-top border-block-start-dashed mt-3 mb-3"></div>
      <div>
        <h6 className="text-muted">Liên hệ</h6>
      </div>
      <div className="col-xl-12 col-md-12">
        <div className="row">
          <div className="form-floating mb-3 col-6">
            <input
              type="text"
              className="form-control"
              placeholder="Tên input"
              defaultValue={dataSaleEdit[0].hoten && dataSaleEdit[0].hoten}
              {...register("hoten", { required: true })}
            />
            <label className="fs-15 ms-2 text-muted">
              Họ tên <span className="text-danger">*</span>
            </label>
            {errors.hoten && (
              <span className="fs-12 text-danger">
                Bạn chưa nhập tên liên hệ
              </span>
            )}
          </div>
          <div className="form-floating mb-3 col-6">
            <input
              type="text"
              className="form-control"
              placeholder="Tên input"
              defaultValue={
                dataSaleEdit && dataSaleEdit[0].sodienthoai
              }
              {...register("sodienthoai", { required: true })}
            />
            <label className="fs-15 ms-2 text-muted">
              Số điện thoại <span className="text-danger">*</span>
            </label>
            {errors.sodienthoai && (
              <span className="fs-12 text-danger">
                Bạn chưa nhập số điện thoại
              </span>
            )}
          </div>
        </div>
      </div>
      {/* Button */}
      <div className="px-4 py-3 border-top border-block-start-dashed d-sm-flex justify-content-center">
        <button className="btn btn-primary-light m-1" type="submit">
          Lưu lại
        </button>
      </div>
    </form>
  )
}

export default FormEditDataSale



// Component suggest tuyenduong.
const ListSuggestTuyenduong = ({ dataList, valueInput, result }) => {
  const [showHide, setShowHide] = useState(true)
  const checkValue = useRef("")
  const [dataSuggest, setDataSuggest] = useState([])
  // Quản lý xử lý danh sách trùng nhau.
  useEffect(() => {
    const dataFilter = dataList.map((item) => {
      return item.tuyenduong
    });
    const listSuggest = dataFilter.reduce((khoitao, value) => {
      if (khoitao.indexOf(value) === -1) {
        khoitao.push(value)
      }
      return khoitao
    }, []);
    setDataSuggest(listSuggest)
  }, [dataList])
  

  const handleSetValue = (key) => {
    result(key)
    setShowHide(false)
    checkValue.current = key
  }
  useEffect(() => {
    if (checkValue.current !== valueInput) {
      setShowHide(true)
    } else {
      setShowHide(false)
    }
  }, [dataList, checkValue.current])

  // Quản lý ẩn gợi ý khi click ra ngoài.
  useEffect(() => {
    window.addEventListener("click", function () {
      setShowHide(false)
    });

  }, [])

  return (
    <ul
      className="list_suggest"
      style={{ display: showHide ? "block" : "none" }}
    >
      {dataSuggest.map((item, index) => {
        return (
          <li
            key={index}
            className="dropdown-item d-flex fs-14"
            onClick={() => handleSetValue(item)}
          >
            {item}
          </li>
        )
      })}
    </ul>
  )
}

 // Component suggest phuongxa
const ListSuggestPhuongxa = ({ dataList, valueInput, result }) => {
  const [showHide, setShowHide] = useState(true)
  const checkValue = useRef("")
  const [dataSuggest, setDataSuggest] = useState([])
  // Quản lý xử lý danh sách trùng nhau.
  useEffect(() => {
    const dataFilter = dataList.map((item) => {
      return item.tenphuongxa
    })
    const listSuggest = dataFilter.reduce((khoitao, value) => {
      if (khoitao.indexOf(value) === -1) {
        khoitao.push(value)
      }
      return khoitao
    }, [])
    setDataSuggest(listSuggest)
  }, [valueInput])

  const handleSetValue = (key) => {
    result(key)
    setShowHide(false)
    checkValue.current = key
  }
  useEffect(() => {
    if (checkValue.current !== valueInput) {
      setShowHide(true)
    } else {
      setShowHide(false)
    }
  }, [checkValue.current, valueInput])

  // Quản lý ẩn gợi ý khi click ra ngoài.
  useEffect(() => {
    window.addEventListener("click", function () {
      setShowHide(false)
    })

  }, [])

  return (
    <ul
      className="list_suggest"
      style={{ display: showHide ? "block" : "none" }}
    >
      {dataSuggest.map((item, index) => {
        return (
          <li
            key={index}
            className="dropdown-item d-flex fs-14"
            onClick={() => handleSetValue(item)}
          >
            {item}
          </li>
        )
      })}
    </ul>
  )
}

 // Component suggest quanhuyen
const ListSuggestQuanhuyen = ({ dataList, valueInput, result }) => {
  const [showHide, setShowHide] = useState(false)
  const checkValue = useRef("")
  const [dataSuggest, setDataSuggest] = useState([])
  // Quản lý xử lý danh sách trùng nhau.
  useEffect(() => {
    const dataFilter = dataList.map((item) => {
      return item.quanhuyen
    })
    const listSuggest = dataFilter.reduce((khoitao, value) => {
      if (khoitao.indexOf(value) === -1) {
        khoitao.push(value)
      }
      return khoitao
    }, [])
    setDataSuggest(listSuggest)
  }, [valueInput])

  const handleSetValue = (key) => {
    result(key)
    setShowHide(false)
    checkValue.current = key
  }
  useEffect(() => {
    if (valueInput) {
      setShowHide(true)
    } else {
      setShowHide(false)
    }
  }, [valueInput])

  // Quản lý ẩn gợi ý khi click ra ngoài.
  useEffect(() => {
    window.addEventListener("click", function () {
      setShowHide(false)
    })

  }, [])

  return (
    <ul
      className="list_suggest"
      style={{ display: showHide ? "block" : "none" }}
    >
      {dataSuggest.map((item, index) => {
        return (
          <li
            key={index}
            className="dropdown-item d-flex fs-14"
            onClick={() => handleSetValue(item)}
          >
            {item}
          </li>
        )
      })}
    </ul>
  )
}