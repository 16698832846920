import { useEffect, useRef, useState } from "react"

// Component suggest tuyenduong.
function ListSuggestTuyenduong({ dataList, valueInput, result }) {
  const [showHide, setShowHide] = useState(true)
  const checkValue = useRef("")
  const [dataSuggest, setDataSuggest] = useState([])
  // Quản lý xử lý danh sách trùng nhau.
  useEffect(() => {
    const dataFilter = dataList.map((item) => {
      return item.tuyenduong
    })
    const listSuggest = dataFilter.reduce((khoitao, value) => {
      if (khoitao.indexOf(value) === -1) {
        khoitao.push(value)
      }
      return khoitao
    }, [])
    setDataSuggest(listSuggest)
  }, [dataList])

  const handleSetValue = (key) => {
    result(key)
    setShowHide(false)
    checkValue.current = key
  }
  useEffect(() => {
    if (checkValue.current !== valueInput) {
      setShowHide(true)
    } else {
      setShowHide(false)
    }
  }, [dataList, checkValue.current])

  // Quản lý ẩn gợi ý khi click ra ngoài.
  useEffect(() => {
    window.addEventListener("click", function () {
      setShowHide(false)
    })
  }, [])

  return (
    <ul
      className="list_suggest"
      style={{ display: showHide ? "block" : "none" }}
    >
      {dataSuggest.map((item, index) => {
        return (
          <li
            key={index}
            className="dropdown-item d-flex fs-14"
            onClick={() => handleSetValue(item)}
          >
            {item}
          </li>
        )
      })}
    </ul>
  )
}

export default ListSuggestTuyenduong