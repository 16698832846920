// Xác đinh user đăng nhập 1 lần nào chưa (ký tự !! trả về true nếu sodienthoai tồn tại)
const checkLocal = localStorage.getItem("object")
let checkUserLogin
if (checkLocal) {
  checkUserLogin = JSON.parse(localStorage.getItem("object"))
}
const phone = checkUserLogin ? checkUserLogin.p : ""
const status_ = checkUserLogin ? checkUserLogin.status : ""
let isAuthen = false;
if (!!phone && !!status_) {
  isAuthen = true
}

export {isAuthen}
