import { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import moment from "moment";
import classNames from "classnames/bind"
import { GoDotFill } from "react-icons/go";
import { IoCalendarOutline, IoDocumentTextOutline, IoRocketOutline } from "react-icons/io5"
import { IoMdAdd } from "react-icons/io";

import styles from "../Job.module.scss";
import { GoBack } from "../../../Components/Goback"
import SearchHorizontal from "../../../Components/Search/SearchHorizontal";
import { GiProgression } from "react-icons/gi";
import ListTongquan from "../Component/Job/ListTongquan";
import ModalReact from "../../../Components/Modal/ModalReact";
import FormAddJob from "../Component/Job/FormAddJob";
import AppContext from "../../../handle-reuses/Contexts/CreateContexts";
import { useNavigate } from "react-router-dom";
import CheckPCMobile from "../../../handle-reuses/reuses/check.pc.mobile";
import LoadingSpinner from "../../../Components/Loading/LoadingSpinner";
import ListEvaluate from "../Component/Job/ListEvaluate";
const cx = classNames.bind(styles);

function JobPagePC() {
  const { socketio, dataUser } = useContext(AppContext)
  const navigate = useNavigate()
  useLayoutEffect(() => {
    if (dataUser[0].cong_viec === 0) {
      navigate("/")
    }
  }, []);

  const [objectSearch, setObjectSearch] = useState({
    monthSearch: moment().format("YYYY-MM"),
    textSearch: ""
  })

  // Quản lý search month.
  const handleMonthSearch = (e) => {
    setObjectSearch({
      monthSearch: e.target.value,
      textSearch: objectSearch.textSearch
    })
  }

    // Quản lý search text.
    const handleGetValueSearchText = (keywords) => {
      setObjectSearch({
        monthSearch: objectSearch.monthSearch,
        textSearch: keywords
        })
    }

    // Quản lý danh mục.
    const [activeCate, setActiveCate] = useState("list-congviec")
    const handleActiveCate = (cate) => {
        setActiveCate(cate)
    }
  // Quản lý thêm mới công việc.
  const [modalAddJob, setModalAddJob] = useState(false);
  const handleModalAddJob = () => {
    setModalAddJob(true)
  }
  const handleCloseModalAddJob = () => {
    setModalAddJob(false)
  }
    return (
      <>
        {/* Top 1 */}
        <div
          className={cx("d-flex justify-content-between align-items-center")}
        >
          <div className={cx("d-flex align-items-center gap-3 fs-15 fw-5")}>
            <GoBack />
            <span>
              Quản lý tổng quan công việc tháng{" "}
              {moment(objectSearch.monthSearch).format("MM/YYYY")}
            </span>
          </div>
          <div className="d-flex align-items-center gap-3">
            <span className="d-flex align-items-center fs-14">
              <GoDotFill className="text-success-1 me-1 fs-18" />
              Tốt
            </span>
            <span className="d-flex align-items-center fs-14">
              <GoDotFill className="text-warning-1 me-1 fs-18" />
              Trung bình
            </span>
            <span className="d-flex align-items-center fs-14">
              <GoDotFill className="text-danger me-1 fs-18" />
              Báo động
            </span>
          </div>
        </div>
        {/* top 2 */}
        <div
          className={cx(
            "d-flex justify-content-between align-items-center mt-3",
          )}
        >
          {/* Left */}
          <div className={cx("d-flex alig-items-center gap-3 fs-15")}>
            <button
              className={cx(
                "border-0 bg-transparent",
                activeCate === "list-congviec" ? "color-orange" : "color-gray",
              )}
              onClick={() => handleActiveCate("list-congviec")}
            >
              <span className="d-flex align-items-center">
                <IoCalendarOutline className="me-1" />
                List công việc
              </span>
            </button>
            <button
              className={cx(
                "border-0 bg-transparent",
                activeCate === "tien-do" ? "color-orange" : "color-gray",
              )}
              onClick={() => handleActiveCate("tien-do")}
            >
              <span className="d-flex align-items-center">
                <GiProgression className="me-1" />
                Tiến độ
              </span>
            </button>
            <button
              className={cx(
                "border-0 bg-transparent",
                activeCate === "danh-gia" ? "color-orange" : "color-gray",
              )}
              onClick={() => handleActiveCate("danh-gia")}
            >
              <span className="d-flex align-items-center">
                <IoRocketOutline className="me-1" />
                Đánh giá năng lực
              </span>
            </button>
            <button
              className={cx(
                "border-0 bg-transparent",
                activeCate === "tai-lieu" ? "color-orange" : "color-gray",
              )}
              onClick={() => handleActiveCate("tai-lieu")}
            >
              <span className="d-flex align-items-center">
                <IoDocumentTextOutline className="me-1" />
                Tài liệu
              </span>
            </button>
          </div>
          {/* Right */}
          <div className={cx("d-flex align-items-center gap-3")}>
            {/* Search month*/}
            <input
              type="month"
              className={cx("class_input", "rounded-3")}
              value={objectSearch.monthSearch}
              onChange={handleMonthSearch}
            />
            {/* Search text */}
            <SearchHorizontal
              result={handleGetValueSearchText}
              className={cx("rounded-3 border-1")}
              style={{ background: "white" }}
              placeholder="Họ tên, số điện thoại"
            />
            {/* Thêm mới công việc */}
            <button
              className={cx(
                "btn btn-purple-gradient btn-wave",
                "border-0 rounded-3 d-flex align-items-center",
              )}
              onClick={handleModalAddJob}
            >
              <IoMdAdd className="fs-15 me-1" />
              Thêm việc
            </button>
          </div>
        </div>
        {/* List content */}
        <div className={cx("mt-3")}>
          {/* Tiến độ */}
          {activeCate === "list-congviec" && objectSearch && (
            <ListTongquan
              objectSearch={objectSearch}
              socketio={socketio}
              dataUser={dataUser}
            />
          )}
          {activeCate === "tien-do" && (
            <ListContentProgress
              objectSearch={objectSearch}
              socketio={socketio}
              dataUser={dataUser}
            />
          )}
          {activeCate === "danh-gia" && (
            <ListEvaluate
              objectSearch={objectSearch}
              socketio={socketio}
              dataUser={dataUser}
            />
          )}
          {activeCate === "tai-lieu" && <ListDocument />}
        </div>
        {/* Modal add job */}
        {modalAddJob && (
          <ModalReact
            modalTitle="Thêm mới công việc"
            showModal={modalAddJob}
            handleClose={handleCloseModalAddJob}
            theme={<FormAddJob socketio={socketio} dataUser={dataUser} />}
          />
        )}
      </>
    )
}
export default JobPagePC


// List content tiến độ.
function ListContentProgress({ objectSearch, socketio, dataUser }) {
  // Quản lý list progress.
  const [dataProgress, setDataProgress] = useState([])
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        role: dataUser[0].list_tiendo,
        list_congviec: dataUser[0].list_congviec,
        month: objectSearch.monthSearch,
        textSearch: objectSearch.textSearch,
        user_id: dataUser[0].id_user
      }
    }; 
    socketio.emit("client-get-search-data-role-progress-job", params)
    socketio.on("server-get-search-data-role-progress-job", (data) => {
      setDataProgress([...data]);
      setLoading(false)
    })
    return () => {
      socketio.off("client-get-search-data-role-progress-job")
      socketio.off("server-get-search-data-role-progress-job")
    }
  }, [objectSearch])
  
  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <ListProgressPC
      dataProgress={dataProgress}
      monthSearch={objectSearch.monthSearch}
    />
  )
}

// List tiến độ PC.
function ListProgressPC({dataProgress, monthSearch }) {
  
  return (
    <div className="table-responsive">
      <table className="table text-nowrap table-bordered">
        <thead>
          <tr>
            <th scope="col">Avatar</th>
            <th scope="col" className="text-center">
              Họ tên
            </th>
            <th>Tháng</th>
            <th scope="col" className="text-center">
              Tiến độ
            </th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody className="table-group-divider">
          {dataProgress.map((item, index) => {
            // Set tiến độ.
            let progress = (
              (Number(item.success_job) / Number(item.total_job)) *
              100
            ).toFixed(2)
            return (
              <tr key={index}>
                <th
                  scope="row"
                  className={cx("text-center")}
                  style={{ width: "50px" }}
                >
                  <span
                    className={cx(
                      "avatar avatar-sm avatar-rounded",
                      item.onlineoffline === 1 && "online",
                    )}
                  >
                    <img
                      src={"https://system.view360.vn/" + item.avatar}
                      alt="img"
                    />
                  </span>
                </th>
                <td style={{ width: "100px" }}>{item.hoten}</td>
                <td style={{ width: "50px" }} className="text-center">
                  {moment(monthSearch).format("MM-YYYY")}
                </td>
                <td style={{ width: "50px" }} className={cx("fw-5")}>
                  <span>
                    <GoDotFill
                      className={cx(
                        "me-1 fs-18",
                        progress >= 90 && "text-success-1",
                        progress < 90 && progress > 70 && "text-warning-1",
                        progress <= 70 && "text-danger",
                      )}
                    />
                    {progress}%
                  </span>
                </td>
                <td>
                  <div
                    className="progress progress-lg custom-progress-3 progress-animate"
                    role="progressbar"
                    aria-valuenow="50"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    <div
                      className={cx(
                        "progress-bar m-0",
                        progress >= 90 && "bg-success-1",
                        progress < 90 && progress > 70 && "bg-warning-1",
                        progress <= 70 && "bg-danger",
                      )}
                      style={{ width: progress + "%" }}
                    >
                      <div
                        className="progress-bar-value border-0 d-flex align-items-center justify-content-end"
                        style={{ background: "none" }}
                      >
                        <span
                          className={cx(
                            "avatar avatar-sm avatar-rounded",
                            item.onlineoffline === 1 && "online",
                          )}
                        >
                          <img
                            src={"https://system.view360.vn/" + item.avatar}
                            alt="img"
                            style={{ border: "2px solid white" }}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}



// Danh sách tài liệu.
function ListDocument() {
  return (
    <div className="table-responsive">
      <table className="table text-nowrap table-bordered">
        <thead>
          <tr>
            <th scope="col">Danh mục</th>
            <th scope="col">Giải thích</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row" style={{ width: "100px" }}>
              <div className="d-flex align-items-center">Trách nhiệm</div>
            </th>
            <td>
              Những công việc liên quan đến{" "}
              <span className="text-primary fw-5">Trách Nhiệm</span> được giao
              hoặc tự giao việc.
            </td>
          </tr>
          <tr>
            <th scope="row">
              <div className="d-flex align-items-center">Sáng tạo</div>
            </th>
            <td>
              Những công việc liên quan đến sự{" "}
              <span className="text-primary fw-5">Sáng Tạo</span> mà bạn được
              giao hoặc tự giao việc.
            </td>
          </tr>
          <tr>
            <th scope="row">
              <div className="d-flex align-items-center">Am hiểu sản phẩm</div>
            </th>
            <td>
              Những công việc liên quan đến{" "}
              <span className="text-primary fw-5">Am Hiểu Sản Phẩm</span> mà bạn
              được giao hoặc tự giao việc.
            </td>
          </tr>
          <tr>
            <th scope="row">
              <div className="d-flex align-items-center">Giải quyết vấn đề</div>
            </th>
            <td>
              Những công việc liên quan đến{" "}
              <span className="text-primary fw-5">Giải Quyết Vấn Đề</span> mà
              bạn được giao hoặc tự giao việc.
            </td>
          </tr>
          <tr>
            <th scope="row">
              <div className="d-flex align-items-center">Quản lý khách hàng</div>
            </th>
            <td>
              Những công việc liên quan đến{" "}
              <span className="text-primary fw-5">Quản lý khách hàng</span> mà
              bạn được giao hoặc tự giao việc.
            </td>
          </tr>
          <tr>
            <th scope="row">
              <div className="d-flex align-items-center">Khả năng phân tích</div>
            </th>
            <td>
              Những công việc liên quan đến{" "}
              <span className="text-primary fw-5">Khả năng phân tích</span> mà
              bạn được giao hoặc tự giao việc.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}