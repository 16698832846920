import { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import classNames from "classnames/bind"
import styles from "../../Job.module.scss"
import LoadingSpinner from "../../../../Components/Loading/LoadingSpinner";
import moment from "moment";
const cx = classNames.bind(styles);

function FormAddJob({socketio, dataUser}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()
    const [loading, setLoading] = useState(true)
    
    //Quản lý gợi ý loại công việc.
    const [optionLoaicongviec, setOptionLoaicongviec] = useState([])
    useEffect(() => {
        const params = {
            iden: process.env.REACT_APP_IDEN_DASH,
        }
        socketio.emit("client-get-data-loaicongviec", params)
        socketio.on("server-get-data-loaicongviec", (data) => {
            setOptionLoaicongviec([...data])
            setLoading(false)
            
        })
        return () => {
            socketio.off("client-get-data-loaicongviec")
            socketio.off("server-get-data-loaicongviec")
        }
    },[])

  // Quản lý danh sách user.
  const [listUser, setListUser] = useState([])
  const [valuePersonnel, setValuePersonnel] = useState("")
  const [showHide, setShowHide] = useState(false)
  const checkValue = useRef()
  const idUser = useRef()
  const avatarUser = useRef()

  // Quản lý sự thay đổi lựa chọn nhân sự
  const handleGetValuePersonnel = (e) => {
    setValuePersonnel(e.target.value);
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        hoten: e.target.value,
        giao_congviec: dataUser[0].list_congviec === 1 ? dataUser[0].giao_congviec : 0,
        user_id: dataUser[0].id_user,
        chuc_vu: dataUser[0].chuc_vu,
        bo_phan: dataUser[0].bo_phan,
        district: dataUser[0].district,
        city: dataUser[0].city
      },
    };
    socketio.emit("client-search-data-user-hoten", params)
  }
  // Nhận dữ liệu
  useEffect(() => {
    socketio.on("server-search-data-user-hoten", (data) => {
      setListUser([...data]);
    })

    return () => {
      socketio.off("client-search-data-user-hoten")
      socketio.off("server-search-data-user-hoten")
    }
  }, [])
  // Sự kiện chọn user.
  const handleClickOptionUser = (userId, userName, avatar) => {
    // set value vào input.
    setValuePersonnel(userName)
    checkValue.current = userName
    idUser.current = userId
    avatarUser.current = avatar
  }
  useEffect(() => {
    if (checkValue.current !== valuePersonnel) {
      setShowHide(true)
    } else {
      setShowHide(false)
    }
    return () => {
      socketio.off("client-check-data-job-personnel")
      socketio.off("server-check-data-job-personnel")
    }
  }, [listUser, checkValue.current])

  // Quản lý ẩn gợi ý khi click ra ngoài.
  useEffect(() => {
    window.addEventListener("click", function () {
      setShowHide(false)
    })
  }, [])
    
    // QUản lý submit.
    const checkChange = useRef()
    const onSubmit = (object) => {
        object.receiver = idUser.current
        object.sender = dataUser[0].id_user
        object.sender_name = dataUser[0].hoten
        object.sender_avatar = dataUser[0].avatar
        const paramsCreate = {
            iden: process.env.REACT_APP_IDEN_DASH,
            object: object,
            objectUpdate: {
              role: dataUser[0].list_congviec,
              month: moment().format("YYYY-MM"),
              user_id: dataUser[0].id_user
            }
        }
        socketio.emit("client-create-data-job", paramsCreate)
        socketio.on("server-create-data-job", (data) => {
            if (data === "success") {
                alert("Công việc đã được thêm mới.")
                checkChange.current = object
                reset()
            }
        })
    }
    useEffect(() => {
        return () => {
            socketio.off("client-create-data-job")
            socketio.off("server-create-data-job")
        }
    }, [checkChange.current])
    
    if (loading) {
        return <LoadingSpinner />
    }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="form-floating mb-3 col-12">
          <select
            className="form-select"
            {...register("loaicongviec", { required: true })}
          >
            <option value="">Chọn loại công việc</option>
            {optionLoaicongviec.map((item, index) => {
              return (
                <option value={item.title} key={index}>
                  {item.title.replace(
                    "Cá nhân",
                    "Cá nhân (mục không đánh giá)",
                  )}
                </option>
              )
            })}
          </select>
          <label className="fs-15 ms-2 text-muted">Chọn loại công việc</label>
          {errors.loaicongviec && (
            <span className="text-danger fs-14">
              *Bạn cần chọn loại công việc.
            </span>
          )}
        </div>
      </div>
      <div className="row">
        <div className="form-floating mb-3 col-12">
          <input
            type="text"
            className="form-control"
            placeholder="Tên input"
            {...register("title", { required: true })}
          />
          <label className="fs-15 ms-2 text-muted">Tiêu đề công việc</label>
          {errors.title && (
            <span className="text-danger fs-14">
              *Bạn cần nhập tiêu đề công việc.
            </span>
          )}
        </div>
        <div className="form-floating mb-3 col-12">
          <textarea
            className="form-control"
            placeholder="Nhập ghi chú"
            style={{ height: "100px" }}
            {...register("content", { required: true })}
          ></textarea>
          <label className="fs-15 ms-2 text-muted">Nội dung công việc</label>
          {errors.content && (
            <span className="text-danger fs-14">
              *Bạn cần nhập nội dung công việc.
            </span>
          )}
        </div>
      </div>
      <div className="row">
        <div className="form-floating mb-3 col-12 position-relative">
          <input
            type="text"
            className="form-control"
            placeholder="Chọn nhân viên"
            {...register("receiver", { required: true })}
            value={valuePersonnel}
            onChange={handleGetValuePersonnel}
          />
          {avatarUser.current && valuePersonnel && (
            <span
              className={cx(
                "avatar avatar-sm me-2 avatar-rounded",
                "avatarInput",
              )}
            >
              <img
                src={"https://system.view360.vn/" + avatarUser.current}
                alt="img"
              />
            </span>
          )}
          <label className="fs-15 ms-2 text-muted">Chọn nhân viên</label>
          {errors.receiver && (
            <span className="text-danger fs-12">*Bạn cần chọn nhân viên.</span>
          )}
          {/* gợi ý user */}
          {listUser.length > 0 && (
            <ul
              className="list_suggest"
              style={{ display: showHide ? "block" : "none", width: "95%" }}
            >
              {listUser.map((item, index) => {
                return (
                  <li
                    key={index}
                    onClick={() =>
                      handleClickOptionUser(
                        item.id_user,
                        item.hoten,
                        item.avatar,
                      )
                    }
                    className="dropdown-item d-flex align-items-center gap-1"
                  >
                    <span className="avatar avatar-xs me-2 avatar-rounded">
                      <img
                        src={"https://system.view360.vn/" + item.avatar}
                        alt="img"
                      />
                    </span>
                    <span>{item.hoten}</span>
                  </li>
                )
              })}
            </ul>
          )}
        </div>
      </div>
      <div className="row">
        <div className="form-floating mb-3 col-6">
          <input
            type="datetime-local"
            placeholder="Bắt đầu"
            className="form-control"
            {...register("date_start", { required: true })}
          />
          <label className="fs-15 ms-2 text-muted">Thời gian bắt đầu</label>
          {errors.date_start && (
            <span className="text-danger fs-12">
              *Bạn cần chọn thời gian bắt đầu.
            </span>
          )}
        </div>
        <div className="form-floating mb-3 col-6">
          <input
            type="datetime-local"
            placeholder="Kết thúc"
            className="form-control"
            {...register("date_end", { required: true })}
          />
          <label className="fs-15 ms-2 text-muted">Thời gian kết thúc</label>
          {errors.date_end && (
            <span className="text-danger fs-12">
              *Bạn cần chọn thời gian kết thúc.
            </span>
          )}
        </div>
      </div>
      {/* Button */}
      <div className="px-4 py-3 d-flex border-top border-block-start-dashed justify-content-center">
        <button
          className="btn btn-secondary-gradient btn-wave waves-effect waves-light"
          type="submit"
        >
          Thêm mới
        </button>
      </div>
    </form>
  )
}
export default FormAddJob