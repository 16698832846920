import { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import LoadingSpinner from "../../../../../Components/Loading/LoadingSpinner"
import moment from "moment"
import { handleTotalCardWallet } from "../reuse_taichinhcanha"



// Form add phanchia_dongtien.
function FormAddPhanchiaDongtien({ socketio, dataUser, result }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const [dataOption, setDataOption] = useState([])
  const [loading, setLoading] = useState(true)
  // lựa chọn danh mục.
  useEffect(() => {
    const paramsOption = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: { user_id: dataUser[0].id_user },
    }
    socketio.emit("client-get-data-taichinhcanhan-cate-dongtien", paramsOption)
    socketio.on("server-get-data-taichinhcanhan-cate-dongtien", (data) => {
      setDataOption([...data])
      setLoading(false)
    })
    return () => {
      socketio.off("client-get-data-taichinhcanhan-cate-dongtien")
      socketio.off("server-get-data-taichinhcanhan-cate-dongtien")
    }
  }, [])

  // Lựa chọn theo thị trường để phân tích(lấy data từ cate thu nhập).
  const [optionCateThunhap, setOptionCateThunhap] = useState([])
  const checkTotalThunhap = useRef(0)
  const checkTotalChiphi = useRef(0)
  useEffect(() => {
    const paramsThuNhap = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: { user_id: dataUser[0].id_user },
    }
    socketio.emit(
      "client-total-thunhap-for-cate-taichinhcanhan-thunhap",
      paramsThuNhap,
    )
    socketio.on(
      "server-total-thunhap-for-cate-taichinhcanhan-thunhap",
      (data) => {
        if (data.length > 0) {
          setOptionCateThunhap([...data])
        }
      },
    )
    return () => {
      socketio.off("client-total-thunhap-for-cate-taichinhcanhan-thunhap")
      socketio.off("server-total-thunhap-for-cate-taichinhcanhan-thunhap")
    }
  }, []);

  // Quan ly quyen duoc phan chia dong tien.
  useEffect(() => {
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        user_id: dataUser[0].id_user
      }
    }
    // Tong thu nhap
    socketio.emit("client-total-thunhap-all-taichinhcanhan-thunhap", params)
    socketio.on("server-total-thunhap-all-taichinhcanhan-thunhap", (data) => {
      checkTotalThunhap.current = handleTotalCardWallet(data)
      
    })
    // tong chi phi.
    socketio.emit("client-total-chiphi-all-taichinhcanhan-chiphi", params)
    socketio.on("server-total-chiphi-all-taichinhcanhan-chiphi", (data) => {
      checkTotalChiphi.current = handleTotalCardWallet(data)
      setLoading(false)
    })

    return () => {
      socketio.off("client-total-thunhap-all-taichinhcanhan-thunhap")
      socketio.off("server-total-thunhap-all-taichinhcanhan-thunhap")

      socketio.off("client-total-chiphi-all-taichinhcanhan-chiphi")
      socketio.off("server-total-chiphi-all-taichinhcanhan-chiphi")
    }
  }, []);


  // Format money.
  const [formatMoney, setFormatMoney] = useState("")
  const [alertLimitMoney, setAlertLimitMoney] = useState(false)
  const handleFormatMoney = (e) => {
    let format = e.target.value.replace(/[.,]/g, "")

    // Kiểm tra xem phân bổ dòng tiền có vượt quá hạn mức không.
    let sotienConlai = checkTotalThunhap.current - checkTotalChiphi.current
    if (parseInt(format) > sotienConlai) {
      setAlertLimitMoney(true)
    } else {
      setAlertLimitMoney(false)
    }

    if (parseInt(format)) {
      let format_ = parseInt(format).toLocaleString().replace(/[.]/g, ",")
      setFormatMoney(format_)
    } else {
      alert("Bạn cần nhập dạng số.")
      setAlertLimitMoney(true)
    }
  }

  // Quản lý submit.
  const checkCreateSuccess = useRef()
  const handleOnSubmit = (object) => {
    object.user_id = dataUser[0].id_user
    object.money = object.money.replace(/[.,]/g, "")
    object.quantity = object.quantity.replace(/[, ]/g, ".")
    // Xu ly them moi.
    const paramsCreate = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: object,
    }
    socketio.emit("client-create-data-taichinhcanhan-dongtien", paramsCreate)
    socketio.on("server-create-data-taichinhcanhan-dongtien", (data) => {
      alert("Thêm mới thành công.")
      result({
        status: true,
        dataUpdate: data,
      })
      checkCreateSuccess.current = object
    })
  }
  useEffect(() => {
    return () => {
      socketio.off("client-create-data-taichinhcanhan-dongtien")
      socketio.off("server-create-data-taichinhcanhan-dongtien")
    }
  }, [checkCreateSuccess.current])

  // Điều kiện hiển thị form
  if (loading) {
    return <LoadingSpinner />
  }
  if (dataOption.length === 0) {
    return (
      <div className="text-center mt-3 fs-15">
        Bạn cần có danh mục phân bổ trước.
      </div>
    )
  }
  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <div className="col-xl-12 col-md-12">
        <div className="row">
          <div className="form-floating mb-3 col-12">
            <select
              className="form-select rounded-3"
              {...register("cate_dongtien", { required: true })}
            >
              <option value="">Chọn loại dòng tiền</option>
              {dataOption.map((item, index) => {
                return (
                  <option value={item.id} key={index}>
                    {item.title}
                  </option>
                )
              })}
            </select>
            <label className="fs-15 ms-2 text-muted">
              Loại dòng tiền<span className="text-danger ms-1">*</span>
            </label>
            {errors.cate_dongtien && (
              <span className="text-danger fs-12">
                *Bạn cần chọn loại dòng tiền điều phối
              </span>
            )}
          </div>
          <div className="form-floating mb-3 col-6">
            <input
              type="text"
              className="form-control rounded-3"
              placeholder="Tên input"
              {...register("quantity", { required: true })}
            />
            <label className="fs-15 ms-2 text-muted">
              Số lượng phân bổ<span className="text-danger ms-1">*</span>
            </label>
            {errors.quantity && (
              <span className="text-danger fs-12">
                *Bạn cần nhập số lượng phân bổ
              </span>
            )}
          </div>
          <div className="form-floating mb-3 col-6">
            <select
              className="form-select rounded-3"
              {...register("donvi", { required: true })}
            >
              <option value="">Chọn đơn vị</option>
              <option value="Cái">Cái</option>
              <option value="Chỉ">Chỉ</option>
              <option value="Lô">Lô</option>
              <option value="Căn">Căn</option>
              <option value="Thùng">Thùng</option>
              <option value="Hộp">Hộp</option>
              <option value="Chiếc">Chiếc</option>
              <option value="Đôi">Đôi</option>
              <option value="Lần">Lần</option>
              <option value="Khác">Khác</option>
            </select>
            <label className="fs-15 ms-2 text-muted">
              Đơn vị cho số lượng<span className="text-danger ms-1">*</span>
            </label>
            {errors.donvi && (
              <span className="text-danger fs-12">
                *Bạn cần chọn đơn vị cho số lượng.
              </span>
            )}
          </div>
          <div className="form-floating mb-3 col-12">
            <input
              type="text"
              className="form-control rounded-3"
              placeholder="Tên input"
              {...register("money", { required: true })}
              value={formatMoney}
              onChange={handleFormatMoney}
            />
            <label className="fs-15 ms-2 text-muted">
              Số tiền chi phí<span className="text-danger ms-1">*</span>
            </label>
            {errors.money && (
              <span className="text-danger fs-12">
                *Bạn cần nhập số tiền chi phí
              </span>
            )}
            {alertLimitMoney && (
              <div className="text-danger fs-13 mt-1">
                Bạn không thể phân chia dòng tiền vì đã vượt quá số dư. Số dư
                hiện tại của bạn đang là{" "}
                <span className="fs-16">
                  {(checkTotalThunhap.current - checkTotalChiphi.current)
                    .toLocaleString()
                    .replace(/[.]/g, ",")}
                </span>
              </div>
            )}
          </div>
          <div className="form-floating mb-3 col-12">
            <input
              type="datetime-local"
              className="form-control rounded-3"
              placeholder="Tên input"
              {...register("date_start", { required: true })}
            />
            <label className="fs-15 ms-2 text-muted">
              Bắt đầu<span className="text-danger ms-1">*</span>
            </label>
            {errors.date_start && (
              <span className="text-danger fs-12">
                *Bạn cần chọn thời gian bắt đầu
              </span>
            )}
          </div>
          <div className="form-floating mb-3 col-12">
            <input
              type="datetime-local"
              className="form-control rounded-3"
              placeholder="Tên input"
              {...register("date_end", { required: true })}
            />
            <label className="fs-15 ms-2 text-muted">
              Kết thúc<span className="text-danger ms-1">*</span>
            </label>
            {errors.date_end && (
              <span className="text-danger fs-12">
                *Bạn cần chọn thời gian kết thúc
              </span>
            )}
          </div>
        </div>
        <div className="form-floating mb-3 col-12">
          <select
            className="form-select rounded-3"
            {...register("statistical")}
          >
            <option value="">Chọn thị trường để phân tích</option>
            <option value="Giá vàng">Thị trường giá vàng</option>
            {optionCateThunhap.map((item, index) => {
              return (
                <option value={item.title} key={index}>
                  So sánh thu nhập từ {item.title}
                </option>
              )
            })}
          </select>
          <label className="fs-15 text-muted">Chọn thị trường</label>
        </div>
      </div>
      {/* Ghi chú */}
      <div className="form-floating mb-4">
        <textarea
          className="form-control rounded-3"
          placeholder="Nhập ghi chú"
          style={{ height: "100px" }}
          {...register("note")}
        ></textarea>
        <label className="fs-15 text-muted">Ghi chú</label>
      </div>
      {/* Button */}
      <div className="px-4 py-3 border-top border-block-start-dashed d-flex justify-content-center">
        {!alertLimitMoney && (
          <button className="btn btn-primary-light m-1" type="submit">
            Thêm mới
          </button>
        )}
      </div>
    </form>
  )
}

export default FormAddPhanchiaDongtien


// Form edit phan chia dong tien
export function FormEditPhanchiaDongtien({ dataEdit, socketio, dataUser, result }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const [dataOption, setDataOption] = useState([])
  const [loading, setLoading] = useState(true)
  // lựa chọn danh mục.
  useEffect(() => {
    const paramsOption = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: { user_id: dataUser[0].id_user },
    }
    socketio.emit("client-get-data-taichinhcanhan-cate-dongtien", paramsOption)
    socketio.on("server-get-data-taichinhcanhan-cate-dongtien", (data) => {
      setDataOption([...data])
    })
    return () => {
      socketio.off("client-get-data-taichinhcanhan-cate-dongtien")
      socketio.off("server-get-data-taichinhcanhan-cate-dongtien")
    }
  }, [])

  // Lựa chọn theo thị trường để phân tích(lấy data từ cate thu nhập).
  const [optionCateThunhap, setOptionCateThunhap] = useState([])
  const checkTotalThunhap = useRef(0)
  const checkTotalChiphi = useRef(0)
  useEffect(() => {
    const paramsThuNhap = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: { user_id: dataUser[0].id_user },
    }
    socketio.emit(
      "client-total-thunhap-for-cate-taichinhcanhan-thunhap",
      paramsThuNhap,
    )
    socketio.on(
      "server-total-thunhap-for-cate-taichinhcanhan-thunhap",
      (data) => {
        setOptionCateThunhap([...data]);
      },
    )
    return () => {
      socketio.off("client-total-thunhap-for-cate-taichinhcanhan-thunhap")
      socketio.off("server-total-thunhap-for-cate-taichinhcanhan-thunhap")
    }
  }, [])

  // Quan ly quyen duoc phan chia dong tien.
  useEffect(() => {
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        user_id: dataUser[0].id_user,
      },
    }
    // Tong thu nhap
    socketio.emit("client-total-thunhap-all-taichinhcanhan-thunhap", params)
    socketio.on("server-total-thunhap-all-taichinhcanhan-thunhap", (data) => {
      checkTotalThunhap.current = handleTotalCardWallet(data)
    })
    // tong chi phi.
    socketio.emit("client-total-chiphi-all-taichinhcanhan-chiphi", params)
    socketio.on("server-total-chiphi-all-taichinhcanhan-chiphi", (data) => {
      checkTotalChiphi.current = handleTotalCardWallet(data)
      setLoading(false)
    })

    return () => {
      socketio.off("client-total-thunhap-all-taichinhcanhan-thunhap")
      socketio.off("server-total-thunhap-all-taichinhcanhan-thunhap")

      socketio.off("client-total-chiphi-all-taichinhcanhan-chiphi")
      socketio.off("server-total-chiphi-all-taichinhcanhan-chiphi")
    }
  }, [optionCateThunhap])

  // Format money.
  const [formatMoney, setFormatMoney] = useState(
    Number(dataEdit.money).toLocaleString().replace(/[.]/g, ","),
  )
  const [alertLimitMoney, setAlertLimitMoney] = useState(false)
  const handleFormatMoney = (e) => {
    let format = e.target.value.replace(/[.,]/g, "")
    if (Number(format)) {
      let format_ = Number(format).toLocaleString().replace(/[.]/g, ",")
      setFormatMoney(format_)
    } else {
      alert("Bạn cần nhập dạng số.")
    }
    // Kiểm tra xem phân bổ dòng tiền có vượt quá hạn mức không.
    let sotienConlai = checkTotalThunhap.current - checkTotalChiphi.current
    if (Number(format) > sotienConlai) {
      setAlertLimitMoney(true)
    } else {
      setAlertLimitMoney(false)
    }
  }

  // Quản lý submit.
  const checkEditSuccess = useRef()
  const handleOnSubmit = (object) => {
    object.user_id = dataUser[0].id_user
    object.money = object.money.replace(/[.,]/g, "")
    object.id = dataEdit.id
    object.quantity = object.quantity.replace(/[, ]/g, ".")
    // Xu ly them moi.
    const paramsEdit = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: object,
    }
    socketio.emit("client-edit-data-taichinhcanhan-dongtien", paramsEdit)
    socketio.on("server-edit-data-taichinhcanhan-dongtien", (data) => {
      alert("Cập nhật dữ liệu thành công.")
      result({
        status: true,
        dataUpdate: data,
      })
      checkEditSuccess.current = object
    })
  }
  useEffect(() => {
    return () => {
      socketio.off("client-edit-data-taichinhcanhan-dongtien")
      socketio.off("server-edit-data-taichinhcanhan-dongtien")
    }
  }, [checkEditSuccess.current])

  // Điều kiện hiển thị form
  if (loading) {
    return <LoadingSpinner />
  }
  if (dataOption.length === 0) {
    return (
      <div className="text-center mt-3 fs-15">
        Bạn cần có danh mục phân bổ trước.
      </div>
    )
  }
  if (optionCateThunhap.length === 0) {
    return (
      <div className="text-center mt-3 fs-15">
        Hãy tạo danh mục thu nhập để có thể sửa.
      </div>
    )
  }
    return (
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <div className="col-xl-12 col-md-12">
          <div className="row">
            <div className="form-floating mb-3 col-12">
              <select
                className="form-select rounded-3"
                {...register("cate_dongtien", { required: true })}
                defaultValue={dataEdit.cate_dongtien}
              >
                <option value="">Chọn loại dòng tiền</option>
                {dataOption.map((item, index) => {
                  return (
                    <option value={item.id} key={index}>
                      {item.title}
                    </option>
                  )
                })}
              </select>
              <label className="fs-15 ms-2 text-muted">
                Loại dòng tiền<span className="text-danger ms-1">*</span>
              </label>
              {errors.cate_dongtien && (
                <span className="text-danger fs-12">
                  *Bạn cần chọn loại dòng tiền điều phối
                </span>
              )}
            </div>
            <div className="form-floating mb-3 col-6">
              <input
                type="text"
                className="form-control rounded-3"
                placeholder="Tên input"
                {...register("quantity", { required: true })}
                defaultValue={dataEdit.quantity}
              />
              <label className="fs-15 ms-2 text-muted">
                Số lượng phân bổ<span className="text-danger ms-1">*</span>
              </label>
              {errors.quantity && (
                <span className="text-danger fs-12">
                  *Bạn cần nhập số lượng phân bổ
                </span>
              )}
            </div>
            <div className="form-floating mb-3 col-6">
              <select
                className="form-select rounded-3"
                {...register("donvi", { required: true })}
                defaultValue={dataEdit.donvi}
              >
                <option value="">Chọn đơn vị</option>
                <option value="Cái">Cái</option>
                <option value="Căn">Căn</option>
                <option value="Chỉ">Chỉ</option>
                <option value="Chiếc">Chiếc</option>
                <option value="Đôi">Đôi</option>
                <option value="Lô">Lô</option>
                <option value="Lần">Lần</option>
                <option value="Thùng">Thùng</option>
                <option value="Hộp">Hộp</option>
                <option value="Khác">Khác</option>
              </select>
              <label className="fs-15 ms-2 text-muted">
                Đơn vị cho số lượng<span className="text-danger ms-1">*</span>
              </label>
              {errors.donvi && (
                <span className="text-danger fs-12">
                  *Bạn cần chọn đơn vị cho số lượng.
                </span>
              )}
            </div>
            <div className="form-floating mb-3 col-12">
              <input
                type="text"
                className="form-control rounded-3"
                placeholder="Tên input"
                {...register("money", { required: true })}
                value={formatMoney}
                onChange={handleFormatMoney}
              />
              <label className="fs-15 ms-2 text-muted">
                Số tiền chi phí<span className="text-danger ms-1">*</span>
              </label>
              {errors.money && (
                <span className="text-danger fs-12">
                  *Bạn cần nhập số tiền chi phí
                </span>
              )}
              {alertLimitMoney && (
                <div className="text-danger fs-13 mt-1">
                  Bạn không thể phân chia dòng tiền vì đã vượt quá số dư. Số dư
                  hiện tại của bạn đang là{" "}
                  <span className="fs-16">
                    {(checkTotalThunhap.current - checkTotalChiphi.current)
                      .toLocaleString()
                      .replace(/[.]/g, ",")}
                  </span>
                </div>
              )}
            </div>
            <div className="form-floating mb-3 col-6">
              <input
                type="datetime-local"
                className="form-control rounded-3"
                placeholder="Tên input"
                {...register("date_start", { required: true })}
                defaultValue={moment(dataEdit.date_start).format(
                  "YYYY-MM-DD HH:mm",
                )}
              />
              <label className="fs-15 ms-2 text-muted">
                Bắt đầu<span className="text-danger ms-1">*</span>
              </label>
              {errors.date_start && (
                <span className="text-danger fs-12">
                  *Bạn cần chọn thời gian bắt đầu
                </span>
              )}
            </div>
            <div className="form-floating mb-3 col-6">
              <input
                type="datetime-local"
                className="form-control rounded-3"
                placeholder="Tên input"
                {...register("date_end", { required: true })}
                defaultValue={moment(dataEdit.date_end).format(
                  "YYYY-MM-DD HH:mm",
                )}
              />
              <label className="fs-15 ms-2 text-muted">
                Kết thúc<span className="text-danger ms-1">*</span>
              </label>
              {errors.date_end && (
                <span className="text-danger fs-12">
                  *Bạn cần chọn thời gian kết thúc
                </span>
              )}
            </div>
          </div>
          <div className="form-floating mb-3 col-12">
            <select
              className="form-select rounded-3"
              {...register("statistical")}
              defaultValue={dataEdit.statistical}
            >
              <option value="">Chọn thị trường để phân tích</option>
              <option value="Giá vàng">Thị trường giá vàng</option>
              {optionCateThunhap.map((item, index) => {
                return (
                  <option value={item.title} key={index}>
                    So sánh thu nhập từ {item.title}
                  </option>
                )
              })}
            </select>
            <label className="fs-15 text-muted">Chọn thị trường</label>
          </div>
        </div>
        {/* Ghi chú */}
        <div className="form-floating mb-4">
          <textarea
            className="form-control rounded-3"
            placeholder="Nhập ghi chú"
            style={{ height: "100px" }}
            {...register("note")}
            defaultValue={dataEdit.note}
          ></textarea>
          <label className="fs-15 text-muted">Ghi chú</label>
        </div>
        {/* Button */}
        <div className="px-4 py-3 border-top border-block-start-dashed d-flex justify-content-center">
          {!alertLimitMoney && (
            <button className="btn btn-primary-light m-1" type="submit">
              Lưu lại
            </button>
          )}
        </div>
      </form>
    )
}