import classNames from "classnames/bind"
import { useContext, useEffect, useState } from "react";
import { AiOutlineAppstoreAdd } from "react-icons/ai"

import styles from "../Job.module.scss"
import { Notification } from "../../../Components/Notification";
import AvatarUser from "../../../Components/Info/AvatarUser";
import { Link, useLocation } from "react-router-dom";
import { GrFormPreviousLink } from "react-icons/gr";
import Tippy from "@tippyjs/react/headless";
import { IoChevronDownOutline } from "react-icons/io5";
import AppContext from "../../../handle-reuses/Contexts/CreateContexts";
import NotificationSystem, { NotifiTimekeeing } from "../../../Components/Notification/NotificationSystem";

const cx = classNames.bind(styles);

function HeaderJob() {
  const {dataUser} = useContext(AppContext)
    const path = useLocation()
    const currentPath = path.pathname
  const [stickyCss, setStickeyCss] = useState(false)

  // Quản lý active category.
  const [active, setActive] = useState(currentPath)
  const handleActiveCate = (cate) => {
      setActive(cate)
  }
    
  // Quản lý fixed header
  useEffect(() => {
    const handleAddStickey = () => {
      const scrollY_ = window.scrollY
      if (scrollY_ > 0) {
        setStickeyCss(true)
      }
    }
    window.addEventListener("scroll", handleAddStickey)
    return () => {
      window.removeEventListener("scroll", handleAddStickey)
    }
  }, [])

  return (
    <div style={{ height: "58px" }}>
      {/* Notification */}
      {(dataUser[0].id_user === 1 || dataUser[0].id_user === 2) && (
        <NotificationSystem textAlert="Bạn cần duyệt bảng lương trước 23:59:59 hôm nay !" />
      )}
      {/* notifi Chấm công khi chưa chấm công hôm nay */}
      <NotifiTimekeeing />

      <aside className={cx("wrapper_header", stickyCss ? "stickyCss" : "")}>
        <div className={cx("d-flex align-items-center gap-3")}>
          <Link
            to="/"
            className="d-flex align-items-center fs-15 me-5 ms-3 fw-5"
          >
            <GrFormPreviousLink className="fs-21" />
            Home
          </Link>
          {/* Left */}
          <div>
            <AiOutlineAppstoreAdd className="fs-29 cursor-pointer" />
          </div>

          <div
            className={cx(
              "d-flex align-items-center gap-4 fs-15",
              "list_cate_job",
            )}
          >
            {dataUser[0].nhan_su === 1 && (
              <div
                className={cx(
                  "cursor-pointer fw-5",
                  active === "/nhan-su" ? "active" : "",
                )}
                onClick={() => handleActiveCate("/nhan-su")}
              >
                <Link to="/nhan-su" className={cx("cate_main")}>
                  Nhân sự
                </Link>
              </div>
            )}
            <div
              className={cx(
                "cursor-pointer fw-5",
                active === "/cham-cong" ||
                  active === "/cham-cong/vang-mat" ||
                  active === "/cham-cong/xin-nghi"
                  ? "active"
                  : "",
              )}
              onClick={() => handleActiveCate("/cham-cong")}
            >
              <Link to="/cham-cong" className={cx("cate_main")}>
                Chấm công
              </Link>
              {/* <Tippy
                interactive
                delay={[0, 0]}
                // trigger="click"
                render={() => {
                  return (
                    <ul className={cx("list_cate")}>
                      <Link
                        to="/cham-cong"
                        className="dropdown-item d-flex fs-15 cursor-pointer"
                      >
                        Bảng chấm công
                      </Link>
                      <Link
                        to="/cham-cong/vang-mat"
                        className="dropdown-item d-flex fs-15 cursor-pointer"
                      >
                        Đơn vắng mặt
                      </Link>
                      <Link
                        to="/cham-cong/xin-nghi"
                        className="dropdown-item d-flex fs-15 cursor-pointer"
                      >
                        Đơn xin nghỉ
                      </Link>
                    </ul>
                  )
                }}
              >
                <Link to="/cham-cong" className={cx("cate_main")}>
                  Chấm công <IoChevronDownOutline />
                </Link>
              </Tippy> */}
            </div>
            <div
              className={cx(
                "cursor-pointer fw-5",
                active === "/cong-viec" ||
                  active === "/cong-viec/du-an-kinh-doanh"
                  ? "active"
                  : "",
              )}
              onClick={() => handleActiveCate("/cong-viec")}
            >
              <Tippy
                interactive
                delay={[0, 0]}
                // trigger="click"
                render={() => {
                  return (
                    <ul className={cx("list_cate")}>
                      <Link
                        to="/cong-viec"
                        className="dropdown-item d-flex fs-15 cursor-pointer"
                      >
                        Công việc & Đánh giá
                      </Link>

                      {dataUser[0].id_user === 1 && (
                        <>
                          <Link
                            to="/cong-viec/du-an-kinh-doanh"
                            className="dropdown-item d-flex fs-15 cursor-pointer"
                          >
                            Dự án kinh doanh (tương lai)
                          </Link>
                          <Link
                            to="/cong-viec/quan-ly-thue"
                            className="dropdown-item d-flex fs-15 cursor-pointer"
                          >
                            Quản lý thuế (tương lai)
                          </Link>
                        </>
                      )}
                    </ul>
                  )
                }}
              >
                <Link to="/cong-viec" className={cx("cate_main")}>
                  Công việc <IoChevronDownOutline />
                </Link>
              </Tippy>
            </div>

            {/* <div
              className={cx(
                "cursor-pointer fw-5",
                active === "/ca-lam-viec" ? "active" : "",
              )}
              onClick={() => handleActiveCate("/ca-lam-viec")}
            >
              
            </div> */}
            <div
              className={cx(
                "cursor-pointer fw-5",
                active === "/hop-dong" ? "active" : "",
              )}
              onClick={() => handleActiveCate("/hop-dong")}
            >
              <Tippy
                interactive
                delay={[0, 0]}
                // trigger="click"
                render={() => {
                  return (
                    <ul className={cx("list_cate")}>
                      <Link
                        to="/hop-dong"
                        className="dropdown-item d-flex fs-15 cursor-pointer"
                      >
                        Tất cả hợp đồng
                      </Link>
                      {/* <Link
                        to="/hop-dong/quang-cao-bat-dong-san"
                        className="dropdown-item d-flex fs-15 cursor-pointer"
                      >
                        Quảng cáo bất động sản
                      </Link>
                      <Link
                        to="/hop-dong/view-360"
                        className="dropdown-item d-flex fs-15 cursor-pointer"
                      >
                        View 360
                      </Link>
                      <Link
                        to="/hop-dong"
                        className="dropdown-item d-flex fs-15 cursor-pointer"
                      >
                        Công nghệ (dự án)
                      </Link>
                      <Link
                        to="/hop-dong"
                        className="dropdown-item d-flex fs-15 cursor-pointer"
                      >
                        Môi giới bất động sản (dự án)
                      </Link> */}
                    </ul>
                  )
                }}
              >
                <Link to="/hop-dong" className={cx("cate_main")}>
                  Hợp đồng <IoChevronDownOutline />
                </Link>
              </Tippy>
            </div>
            <div
              className={cx(
                "cursor-pointer fw-5",
                active === "/bang-luong" ? "active" : "",
              )}
              onClick={() => handleActiveCate("/bang-luong")}
            >
              <Link to="/bang-luong" className={cx("cate_main")}>
                Bảng lương
              </Link>
            </div>

            <div
              className={cx(
                "cursor-pointer fw-5",
                active === "/ke-toan" ? "active" : "",
              )}
              onClick={() => handleActiveCate("/ke-toan")}
            >
              <Link to="/ke-toan" className={cx("cate_main")}>
                Kế toán
              </Link>
            </div>

            {dataUser[0].bao_cao === 1 && (
              <div
                className={cx(
                  "cursor-pointer fw-5",
                  active === "/bao-cao" ? "active" : "",
                )}
                onClick={() => handleActiveCate("/bao-cao")}
              >
                <Link to="/bao-cao" className={cx("cate_main")}>
                  Báo cáo
                </Link>
              </div>
            )}

            <div
              className={cx(
                "cursor-pointer fw-5",
                active === "/tai-chinh-ca-nhan" ? "active" : "",
              )}
              onClick={() => handleActiveCate("/tai-chinh-ca-nhan")}
            >
              <Link to="/tai-chinh-ca-nhan" className={cx("cate_main")}>
                Tài chính cá nhân
              </Link>
            </div>

            {/* <div
              className={cx(
                "cursor-pointer fw-5",
                active === "/setting" ? "active" : "",
              )}
              onClick={() => handleActiveCate("/setting")}
            >
              <Tippy
                interactive
                delay={[0, 0]}
                // trigger="click"
                render={() => {
                  return (
                    <ul className={cx("list_cate")}>
                      <Link
                        to="/cham-cong"
                        className="dropdown-item d-flex fs-15 cursor-pointer"
                      >
                        Công thức chấm công
                      </Link>
                      <Link
                        to="/cham-cong"
                        className="dropdown-item d-flex fs-15 cursor-pointer"
                      >
                        Công thức tính lương
                      </Link>
                      <Link
                        to="/cham-cong"
                        className="dropdown-item d-flex fs-15 cursor-pointer"
                      >
                        Công thức Đánh giá
                      </Link>
                      <li className="dropdown-item d-flex fs-15 cursor-pointer">
                        Thêm giờ làm việc (modal)
                      </li>
                      <li className="dropdown-item d-flex fs-15 cursor-pointer">
                        Thêm loại công việc (modal)
                      </li>
                      <li className="dropdown-item d-flex fs-15 cursor-pointer">
                        Thêm tài liệu danh mục (modal)
                      </li>
                      <li className="dropdown-item d-flex fs-15 cursor-pointer">
                        Thêm tài liệu hệ thống (modal)
                      </li>
                      <li className="dropdown-item d-flex fs-15 cursor-pointer">
                        Thêm tài liệu AI (modal)
                      </li>
                    </ul>
                  )
                }}
              >
                <Link to="/setting" className={cx("cate_main")}>
                  Cài đặt
                </Link>
              </Tippy>
            </div> */}
          </div>
        </div>
        {/* right */}
        <div className={cx("d-flex align-items-center")}>
          {/* notifi */}
          <Notification style={{fontSize: "26px"}}/>
          {/* info user */}
          <div className="ms-3 me-3">
            <AvatarUser />
          </div>
        </div>
      </aside>
    </div>
  )
}
export default HeaderJob