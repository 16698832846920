import { useEffect, useState } from "react"
import { SlOptions } from "react-icons/sl"
import classNames from "classnames/bind"
import { BsDot } from "react-icons/bs"
import { Dropdown } from "react-bootstrap"
import { PiSealCheckFill } from "react-icons/pi"


import { images } from "../../../../assets/images"
import styles from "../Taikhoan.module.scss"
import ModalReact from "../../../../Components/Modal/ModalReact"
import ModalEditInfoUser from "./Modal-edit-user/ModalEditInfoUser"
import { handleFormatPrice } from "../../../../handle-reuses/reuses"

const cx = classNames.bind(styles);

function ListUser({ dataUser, socketio }) {
  
  // Hiển thị số dư tài khoản.
  const [dataUserPay, setdataUserPay] = useState([]);
  useEffect(() => {
    // tìm user pay tương ứng.
    for (let i = 0; i < dataUser.length; i++) {
      socketio.emit("client-check-user-pay", dataUser[i].id)
    }
    // Nhận list user pay tương ứng.
    let listUserPay = []
    socketio.on("server-check-user-pay", (data) => {
      let filterUserPay = []
      if (data.length > 0) {
        filterUserPay = listUserPay.find((item) => item.id === data[0].id)
      };
      if (!filterUserPay) {
        listUserPay.push(data[0])
      };
      setdataUserPay([...listUserPay])

    })
    
    return () => {
      socketio.off("client-check-user-pay")
      socketio.off("server-check-user-pay")
    }
  }, [])


  // Sửa thông tin user.
  const [modalEditUser, setModalEditUser] = useState(false)
  const [idUser, setIdUser] = useState(0)
  const [dataEdit, setDataEdit] = useState([]);
  const handleEditInfoUser = (id_) => {
    setModalEditUser(true)
    setIdUser(id_)
    const filterData = dataUser.filter((item) => {
      return item.id === id_
    });
    setDataEdit(filterData)
  }
  const handleCloseEditModal = () => {
    setModalEditUser(false)
    setIdUser(0)
    setDataEdit([])
  }
  // Chặn tài khoản.
  const handleCancelKiemduyet = (id_) => {
    let check_ = window.confirm("Bạn muốn chuyển đổi trạng thái chứ?")
    if (check_) {
      socketio.emit("client-kiemduyet-user", id_)
      alert("Bạn đã chuyển đổi trạng thái thành công.")
    }
  }

  if (dataUser.length === 0) {
    return <div className="text-center mt-5">Chưa có dữ liệu.</div>
  }
  if (dataUserPay.length === 0) {
    return <div className="text-center mt-5">Đang tải dữ liệu...</div>
  }

  return (
    <>
      <div className="table-responsive" style={{ minHeight: "500px" }}>
        <table className="table text-nowrap table-bordered">
          <thead>
            <tr>
              <th scope="col" className="text-center">
                Họ tên
              </th>
              <th scope="col" className="text-center">
                Trạng thái
              </th>
              <th scope="col" className="text-center">
                Email
              </th>
              <th scope="col" className="text-center">
                Phone
              </th>
              <th scope="col" className="text-center">
                Lựa chọn
              </th>
            </tr>
          </thead>
          <tbody>
            {dataUser.map((item) => {
              let itemUserPay = dataUserPay.find(
                (itemPay) => itemPay.userid === item.id,
              );
              let dachi = 0;
              if (itemUserPay) {
                dachi =
                  Number(itemUserPay.total_deposit) -
                  Number(itemUserPay.total_balance)
              }
                
              return (
                <tr key={item.id}>
                  <th scope="row">
                    <div className="d-flex align-items-center">
                      <span
                        className={cx(
                          "avatar avatar-md me-2 avatar-rounded",
                          item.online_offline === 1 ? "online" : "offline",
                        )}
                      >
                        <img
                          src={
                            item.avatar
                              ? "https://batdongsan.view360.vn/" + item.avatar
                              : images.user
                          }
                          alt="img"
                        />
                      </span>
                      <div className="fs-16">
                        <span>
                          {item.hoten}{" "}
                          {item.reputation === 1 && (
                            <PiSealCheckFill
                              style={{ color: "#2793e6" }}
                            />
                          )}
                        </span>
                        <p className="mb-0 text-muted fs-13 fw-4">
                          Số dư:{" "}
                          {itemUserPay
                            ? handleFormatPrice(
                                Number(itemUserPay.total_balance),
                              )
                            : 0}
                          <BsDot /> Đã chi: {handleFormatPrice(dachi)}
                        </p>
                      </div>
                    </div>
                  </th>
                  <td className="text-center">
                    {item.kiemduyet === 1 ? (
                      <span className="badge bg-success-transparent fs-14">
                        Hoạt động
                      </span>
                    ) : (
                      <span className="badge bg-danger-transparent fs-14">
                        Ngưng hoạt động
                      </span>
                    )}
                  </td>
                  <td className="text-center">
                    <span className="fs-15">{item.email}</span>
                  </td>
                  <td className="text-center">
                    <span className="fs-15">{item.sodienthoai}</span>
                  </td>
                  <td>
                    <div className="d-flex justify-content-center">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="outline-dark border-1 p-2 py-1" // class mặc định
                          id="dropdown-basic"
                          bsPrefix="custom-dropdown-toggle" // đặt tên cho class fix loại bỏ class dropdown-toggle
                        >
                          <SlOptions />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <li
                            className="dropdown-item d-flex fs-14"
                            onClick={() => handleEditInfoUser(item.id)}
                          >
                            Sửa thông tin
                          </li>
                          <li
                            className="dropdown-item d-flex fs-14"
                            onClick={() => handleCancelKiemduyet(item.id)}
                          >
                            Chặn tài khoản
                          </li>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      {/* Modal edit info user */}
      {dataEdit.length > 0 && (
        <ModalReact
          modalTitle="Bảng sửa thông tin"
          showModal={modalEditUser}
          handleClose={handleCloseEditModal}
          theme={<ModalEditInfoUser dataUser={dataEdit} />}
        />
      )}
    </>
  )
}

export default ListUser
