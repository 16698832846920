import { useEffect, useRef, useState } from "react";
import { IoIosAddCircle, IoMdAdd } from "react-icons/io";
import LoadingSpinner from "../../../../../Components/Loading/LoadingSpinner";
import moment from "moment";
import ModalReact from "../../../../../Components/Modal/ModalReact";
import FormAddGiavang, { FormEditGiavang } from "../FormGiavang";

function ListDulieuPhantich({ socketio, dataUser, objectSearch, result }) {
  const [dataGiavang, setDataGiavang] = useState([]);
  const dataGiavangRef = useRef([]);
  const [loading, setLoading] = useState(true);

  // Get data.
  const handleGetDataGiavang = (object) => {
    let params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        monthSearch:
          object.monthSearch === moment().format("YYYY-MM")
            ? ""
            : object.monthSearch,
        user_id: dataUser[0].id_user,
      },
    };
    socketio.emit("client-get-search-data-follow-giavang", params)
    socketio.on("server-get-search-data-follow-giavang", (data) => {
      setDataGiavang([...data]);
      dataGiavangRef.current = [...data]
      setLoading(false)
    })
    
  }
  // Get data gia vang
  useEffect(() => {
    handleGetDataGiavang(objectSearch)
    return () => {
      socketio.off("client-get-search-data-follow-giavang")
      socketio.off("server-get-search-data-follow-giavang")
    }
  }, [objectSearch]);

  // Quan ly them gia vang.
  const [modalAddGiavang, setModalAddGiavang] = useState(false);
  const handleModalAddGiavang = () => {
    setModalAddGiavang(true)
  }
  const handleCloseModalAddGiavang = () => setModalAddGiavang(false);
  const handleAddGiavangSuccess = (object) => {
    if (object.status) {
      let dataUpdateCreate = dataGiavangRef.current;
      dataUpdateCreate.push(object.dataUpdate[0]);
      let sortDataUpdate = dataUpdateCreate.sort((a, b) => {
        let min_ = new Date(a.ngaythem).getTime();
        let max_ = new Date(b.ngaythem).getTime();
        return max_ - min_
      });
      setDataGiavang([...sortDataUpdate])
      dataGiavangRef.current = [...sortDataUpdate];
      result(object.dataUpdate[0])
      setModalAddGiavang(false)
    }
  }

  // Quản lý giá vàng.
  const [modalEditGiavang, setModalEditGiavang] = useState(false);
  const dataEdit = useRef();
  const handleModalEditGiavang = (object) => {
    setModalEditGiavang(true)
    dataEdit.current = object
  }
  const handleCloseEditGiavang = () => {
    setModalEditGiavang(false);
    dataEdit.current = undefined
  }
  const handleEditSuccessGiavang = (object) => {
    if (object.status) {
      // Update laij data.
      let dataUpdateEdit = dataGiavangRef.current;
      let index = dataUpdateEdit.findIndex((item) => item.id_giavang === object.dataUpdate[0].id_giavang);
      dataUpdateEdit[index] = object.dataUpdate[0];
      setDataGiavang([...dataUpdateEdit])
      dataGiavangRef.current = [...dataUpdateEdit]
      result(object.dataUpdate[0])
      setModalEditGiavang(false)
    }
  }


  // Dieu kien hien thi.
  if (loading) {
    return <LoadingSpinner />
  }
  // if (dataGiavang.length === 0) {
  //   return <div className="text-center mt-3">Chưa có dữ liệu.</div>
  // }


    return (
      <div>
        {/* Top */}
        <div className="d-flex justify-content-between align-items-center">
          <span>Giá vàng</span>
          <div>
            <button
              className="border-0 rounded-5 bg-transparent button-hover"
              data-bs-toggle="dropdown"
            >
              <IoIosAddCircle className="fs-29 op-8" />
            </button>
            <ul className="dropdown-menu">
              <li
                className="dropdown-item cursor-pointer"
                onClick={handleModalAddGiavang}
              >
                Thêm giá vàng
              </li>
              {/* <li className="dropdown-item">Thêm loại khác</li> */}
            </ul>
          </div>
        </div>
        {/* content */}
        {dataGiavang.length === 0 && (
          <div className="text-center mt-3">Chưa có dữ liệu.</div>
        )}
        {dataGiavang.length > 0 && (
          <div>
            {dataGiavang.map((item, index) => {
              return (
                <ul
                  className="wrapper_ground align-items-center mb-3 py-1"
                  key={index}
                  style={{ borderBottom: "1px solid var(--default-border)" }}
                >
                  <li className="list_items" style={{ maxWidth: "95px" }}>
                    {/* <p className="mb-0 text-muted">Loại vàng</p> */}
                    <p className="mb-0 fw-5">{item.loaivang}</p>
                    <button
                      className="border-0 badge bg-info-transparent me-1"
                      onClick={() => handleModalEditGiavang(item)}
                    >
                      Sửa
                    </button>
                  </li>
                  <li className="list_items">
                    <p className="mb-0 text-muted">Giá mua</p>
                    <p className="mb-0">
                      {Number(item.giamua)
                        .toLocaleString()
                        .replace(/[.]/g, ",")}
                    </p>
                  </li>
                  <li className="list_items">
                    <p className="mb-0 text-muted">Giá bán</p>
                    <p className="mb-0">
                      {Number(item.giaban)
                        .toLocaleString()
                        .replace(/[.]/g, ",")}
                    </p>
                  </li>
                  <li className="list_items">
                    <p className="mb-0 text-muted">Thời gian</p>
                    <p className="mb-0">
                      {moment(item.ngaythem).format("DD/MM/YYYY")}
                    </p>
                  </li>
                </ul>
              )
            })}
          </div>
        )}
        {/* Modal add gia vang */}
        {modalAddGiavang && (
          <ModalReact
            modalTitle="Thêm mới giá vàng"
            showModal={modalAddGiavang}
            handleClose={handleCloseModalAddGiavang}
            theme={
              <FormAddGiavang
                socketio={socketio}
                dataUser={dataUser}
                result={handleAddGiavangSuccess}
              />
            }
          />
        )}
        {/* Modal edit data */}
        {modalEditGiavang && (
          <ModalReact
            modalTitle="Sửa thông tin"
            showModal={modalEditGiavang}
            handleClose={handleCloseEditGiavang}
            theme={
              <FormEditGiavang
                socketio={socketio}
                dataUser={dataUser}
                dataEdit={dataEdit.current}
                result={handleEditSuccessGiavang}
              />
            }
          />
        )}
      </div>
    )
}

export default ListDulieuPhantich