import { IoMdAddCircle } from "react-icons/io"
import { IoChevronBack } from "react-icons/io5"
import { useNavigate } from "react-router-dom"
import SearchHorizontal from "../../Components/Search/SearchHorizontal"
import { BsDot } from "react-icons/bs"
import { useContext, useEffect, useRef, useState } from "react"
import ModalReact from "../../Components/Modal/ModalReact"
import FormAddStreet, { FormEditStreet } from "./Component/FormStreet"
import AppContext from "../../handle-reuses/Contexts/CreateContexts"
import LoadingSpinner from "../../Components/Loading/LoadingSpinner"
import moment from "moment/moment"
import HeaderStreet from "./Component/HeaderStreet"

function StreetPage() {
  const { socketio, dataUser } = useContext(AppContext)
  // Quan ly button back
  const navigate = useNavigate()
  const goBack = () => {
    navigate(-1)
  }
  // set object search.
  const [objectSearch, setObjectSearch] = useState({
    keywords: "",
  })
  // Quan ly search.
  const handleGetValueSearch = (keywords) => {
    setObjectSearch({
      keywords: keywords,
    })
  }

  // Get data duong ngo.
  const [dataStreet, setDataStreet] = useState([])
  const dataStreetRef = useRef([])
  const [loading, setLoading] = useState(true)

  const handleGetDataDuongngo = (object) => {
    const parmas = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        keywords: object.keywords,
      },
    }
    socketio.emit("client-search-duongngo", parmas)
    socketio.on("server-search-duongngo", (data) => {
      setDataStreet([...data])
      dataStreetRef.current = [...data]
      setLoading(false)
    })
  }
  useEffect(() => {
    handleGetDataDuongngo(objectSearch)
    return () => {
      socketio.off("client-search-duongngo")
      socketio.off("server-search-duongngo")
    }
  }, [objectSearch])

  // Quản lý thêm dữ liệu thành công
  const handleAddStreetSuccess = (object) => {
    if (object.status) {
      let dataUpdateStreet = dataStreetRef.current;
      dataUpdateStreet.push(object.dataUpdate[0])
      let sortDataUpdate = dataUpdateStreet.sort((a, b) => {
        let min_ = new Date(a.ngaythem).getTime()
        let max_ = new Date(b.ngaythem).getTime()
        return max_ - min_
      });
      setDataStreet([...sortDataUpdate])
      dataStreetRef.current = [...sortDataUpdate]
    }
  };

  // Quản lý sự thay đổi của edit.
  const handleChangeEditSuccess = (object) => {
    if (object.status === "edit data") {
      const dataUpdateEdit = dataStreetRef.current;
      let index = dataUpdateEdit.findIndex((item) => item.id_dn === object.dataUpdate[0].id_dn);
      dataUpdateEdit[index] = object.dataUpdate[0];
      setDataStreet([...dataUpdateEdit])
      dataStreetRef.current = [...dataUpdateEdit];

    } else if(object.status === "delete data") {
      const dataUpdateDelete = dataStreetRef.current.filter((item)=> item.id_dn !== object.id);
      setDataStreet([...dataUpdateDelete])
      dataStreetRef.current = [...dataUpdateDelete]
    }

  }

  if (loading) {
    return <LoadingSpinner />
  }
  
  return (
    <>
      <div>
        <HeaderStreet
          result={handleAddStreetSuccess}
          socketio={socketio}
          dataUser={dataUser}
        />
      </div>
      <div className="py-3 px-3 mt-5">
        {/* Search */}
        <div className="mt-3">
          <SearchHorizontal
            placeholder="Nhập tên đường..."
            className="rounded-3"
            result={handleGetValueSearch}
          />
        </div>
        {/* List content */}
        <div className="mt-3">
          {objectSearch.keywords ? (
            <div className="text-center text-primary mb-2">
              Có{" "}
              <span className="fs-21 fw-5">
                {dataStreet.length.toLocaleString().replace(/[.]/g, ",")}
              </span>{" "}
              kết quả tìm kiếm
            </div>
          ) : (
            ""
          )}
          <ListContentStreet
            dataStreet={dataStreet}
            socketio={socketio}
            dataUser={dataUser}
            result={handleChangeEditSuccess}
          />
        </div>
      </div>
    </>
  )
}
export default StreetPage

// Danh sach tuyen duong.
function ListContentStreet({ dataStreet, socketio, dataUser, result }) {

  // Quản lý edit data.
  const [modalEditData, setModalEditData] = useState(false);
  const dataEdit = useRef([])
  const handleEditData = (object) => {
    setModalEditData(true)
    dataEdit.current = object
  }
  const handleCloseEditData = () => {
    setModalEditData(false)
    dataEdit.current = undefined
  }
  const handleEditSuccess = (object) => {
    result({
      status: "edit data",
      dataUpdate: object.dataUpdate
    })
    setModalEditData(false)
  }
  // Quản lý delete data.
  const checkDeleteSuccess = useRef()
  const handleDeleteData = (id) => {
    const checkDelete = window.confirm("Bạn muốn xóa chứ ?")
    if (checkDelete) {
      const params = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: {
          delete_duongngo: 1,
          id_dn: id
        }
      };
      socketio.emit("client-edit-row-delete-bds-duongngo", params)
      socketio.on("server-edit-row-delete-bds-duongngo", (data) => {
        alert("Xóa thành công.")
        result({
          status: "delete data",
          id: id
        })
        checkDeleteSuccess.current = id
      })
    }
  }
  useEffect(() => {
    return () => {
      socketio.off("client-edit-row-delete-bds-duongngo")
      socketio.off("server-edit-row-delete-bds-duongngo")
    }
  }, [checkDeleteSuccess.current])

  if (dataStreet.length === 0) {
    return <div className="fs-16 text-center mt-3">Chưa có thông tin nào phù hợp.</div>
  }
  return (
    <div>
      {dataStreet.map((item, index) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center mb-4"
            key={index}
          >
            <div>
              <h6 className="mb-0">
                {item.diachi + " " + item.tuyenduong + ", " + item.quanhuyen}
              </h6>
              <div className="mb-0 ">
                <div className="text-primary">
                  Đường/Ngõ: {item.duongngo}m
                  <span className="text-muted"></span>
                </div>
                <p className="mb-0 fs-16 text-muted">
                  {moment(item.ngaythem)
                    .locale("vi")
                    .fromNow()
                    .replace("một", "1")}
                  <BsDot />
                  Tác giả: {item.hoten}
                </p>
              </div>
            </div>
            <div>
              {dataUser[0].sodienthoai === "0934222740" && (
                <>
                  <button
                    className="badge bg-success-transparent border-0 me-1"
                    onClick={() => handleEditData(item)}
                  >
                    Sửa
                  </button>
                  <br />
                  <button
                    className="badge bg-danger-transparent border-0"
                    onClick={() => handleDeleteData(item.id_dn)}
                  >
                    Xóa
                  </button>
                </>
              )}
            </div>
          </div>
        )
      })}

      {/* Modal edit data */}
      {modalEditData && (
        <ModalReact
          modalTitle="Sửa thông tin đường ngõ"
          showModal={modalEditData}
          handleClose={handleCloseEditData}
          theme={
            <FormEditStreet
              socketio={socketio}
              dataUser={dataUser}
              dataEdit={dataEdit.current}
              result={handleEditSuccess}
            />
          }
        />
      )}
    </div>
  )
}