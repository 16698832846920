import Tippy from "@tippyjs/react"
import { BsDot } from "react-icons/bs"
import { PiAirplaneTakeoff } from "react-icons/pi"
import PieChart from "../../../../Components/VongTySuat/PieChart"
import { useEffect, useRef, useState } from "react"
import ModalReact from "../../../../Components/Modal/ModalReact"
import classNames from "classnames/bind"
import styles from "../../Job.module.scss"
import moment from "moment"
import LoadingSpinner from "../../../../Components/Loading/LoadingSpinner"
import { IoCloseCircle, IoRibbon } from "react-icons/io5"
import { FaGifts } from "react-icons/fa"
import { FormEditTarget } from "./FormTarget"
import { IoMdCheckmarkCircle } from "react-icons/io"
const cx = classNames.bind(styles)

function ListTargetCanhan({
  socketio,
  dataUser,
  objectSearch,
  dataUpdateCreate,
  dataUpdateRevenueCreate,
  dataUpdateCreateSuccessChiphi,
  changeEdit
}) {
  // Quản lý cate.
  const [cateActive, setCateActive] = useState("year-search")
  const handleCateActive = (cate) => {
    setCateActive(cate)
  }

  // Get danh sách mục tiêu.
  const [dataTarget, setDataTarget] = useState([])
  const dataTargetRef = useRef([])
  const [loading, setLoading] = useState(true)
  // Xu ly get data
  const handleGetDataTarget = (object) => {
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        user_id: dataUser[0].id_user,
        monthSearch: cateActive === "month-search" ? object.monthSearch : "",
        yearSearch:
          cateActive === "year-search"
            ? moment(object.monthSearch).format("YYYY")
            : "",
      },
    };
    socketio.emit("client-get-data-taichinhcanhan-target", params)
    socketio.on("server-get-data-taichinhcanhan-target", (data) => {
      setDataTarget([...data])
      dataTargetRef.current = [...data]
      setLoading(false)
    })
  }
  useEffect(() => {
    handleGetDataTarget(objectSearch)
    return () => {
      socketio.off("client-get-data-taichinhcanhan-target")
      socketio.off("server-get-data-taichinhcanhan-target")
    }
  }, [objectSearch, cateActive])

  // Update lại data khi thêm mới target success.
  useEffect(() => {
    if (dataUpdateCreate.length > 0) {
      let dataUpdateNew = dataTargetRef.current
      dataUpdateNew.push(dataUpdateCreate[0])
      let sortDataUpdateNew = dataUpdateNew.sort((a, b) => {
        let min_ = new Date(a.addtime).getTime()
        let max_ = new Date(b.addtime).getTime()
        return max_ - min_
      })
      setDataTarget([...sortDataUpdateNew])
      dataTargetRef.current = [...sortDataUpdateNew]
    }
  }, [dataUpdateCreate])

  // Thu nhập theo khoảng target
  const [dataThunhap, setDataThunhap] = useState([])
  const [dataChiphi, setDataChiphi] = useState([])
  useEffect(() => {
    if (dataTargetRef.current.length > 0) {
      for (let i = 0; i < dataTargetRef.current.length; i++) {
        let paramsThunhap = {
          iden: process.env.REACT_APP_IDEN_DASH,
          object: {
            user_id: dataUser[0].id_user,
            dateStart: moment(dataTargetRef.current[i].date_start).format(
              "YYYY-MM-DD",
            ),
            dateEnd: moment(dataTargetRef.current[i].date_end).format(
              "YYYY-MM-DD",
            ),
            id: dataTargetRef.current[i].id,
          },
        }
        // Thu nhap
        socketio.emit(
          "client-total-thunhap-time-taichinhcanhan-thunhap",
          paramsThunhap,
        )
        // Chi phi.
        socketio.emit(
          "client-total-chiphi-time-taichinhcanhan-chiphi",
          paramsThunhap,
        )
      }
    }
  }, [
    dataTargetRef.current,
    dataUpdateRevenueCreate,
    dataUpdateCreateSuccessChiphi,
    changeEdit
  ])
  useEffect(() => {
    let arrThu = [],
      arrChi = []
    socketio.on("server-total-thunhap-time-taichinhcanhan-thunhap", (data) => {
      if (data.length === 1) {
        arrThu.push(data[0])
        if (dataTargetRef.current.length === arrThu.length) {
          setDataThunhap([...arrThu]);
        }
      }
    })
    socketio.on("server-total-chiphi-time-taichinhcanhan-chiphi", (data) => {
      if (data.length === 1) {
        arrChi.push(data[0])
        if (dataTargetRef.current.length === arrChi.length) {
          setDataChiphi([...arrChi])
        }
      }
    })
    return () => {
      socketio.off("client-total-thunhap-time-taichinhcanhan-thunhap")
      socketio.off("server-total-thunhap-time-taichinhcanhan-thunhap")

      socketio.off("client-total-chiphi-time-taichinhcanhan-chiphi")
      socketio.off("server-total-chiphi-time-taichinhcanhan-chiphi")
    }
  }, [dataUpdateRevenueCreate, dataUpdateCreateSuccessChiphi, dataTargetRef.current, changeEdit])
  // *Thu nhập theo khoảng target


  // Update khi edit, delete thanh cong.
  const handleEditTargetSuccess = (object) => {
    if (object.status === "edit success") {
      let dataUpdateEdit = dataTargetRef.current;
      let index = dataUpdateEdit.findIndex((item) => item.id === object.dataUpdate[0].id);
      dataUpdateEdit[index] = object.dataUpdate[0]
      setDataTarget([...dataUpdateEdit])
      dataTargetRef.current = [...dataUpdateEdit]
    } else if (object.status === "delete success") {
      let dataUpdateDelete = dataTargetRef.current
      let filter = dataUpdateDelete.filter(
        (item) => item.id !== object.id,
      )
      setDataTarget([...filter])
      dataTargetRef.current = [...filter]
    }
  }

  if (loading) {
    return <LoadingSpinner />
  }
  if (dataTarget.length === 0) {
    return (
      <div
        className="col-12 p-3 fs-16 text-center"
      >
        Bạn chưa thiết lập mục tiêu.
      </div>
    )
  }
  return (
    <div>
      <div
        className="text-than fw-5 d-flex align-items-center justify-content-between"
        style={{ height: "30px" }}
      >
        <div>
          <PiAirplaneTakeoff className="fs-21 me-2" />
          Mục tiêu tài chính
        </div>
        <div className="wrapper_ground">
          <button
            className={cx(
              "list_items badge bg-success",
              cateActive === "month-search" ? "border-2" : "op-6 border-0",
            )}
            onClick={() => handleCateActive("month-search")}
            style={{
              border: cateActive === "month-search" ? "2px solid #8170e3" : "",
            }}
          >
            Tháng
          </button>
          <button
            className={cx(
              "list_items badge bg-success",
              cateActive === "year-search" ? "border-2" : "op-6 border-0",
            )}
            style={{
              border: cateActive === "year-search" ? "1px solid #8170e3" : "",
            }}
            onClick={() => handleCateActive("year-search")}
          >
            Năm
          </button>
        </div>
      </div>
      <div className="border-top border-block-start-dashed mt-2 mb-2"></div>

      {/* chart target */}
      {/* <div>
        <ChartTarget
          socketio={socketio}
          dataUser={dataUser}
          objectSearch={objectSearch}
        />
      </div> */}
      {/* List target */}
      <div style={{ height: "calc(100vh - 17rem)", overflow: "auto" }} className="px-1">
        <ListTarget
          socketio={socketio}
          dataUser={dataUser}
          objectSearch={objectSearch}
          dataTarget={dataTarget}
          dataThunhap={dataThunhap}
          dataChiphi={dataChiphi}
          result={handleEditTargetSuccess}
        />
      </div>
    </div>
  )
}
export default ListTargetCanhan


// Biểu đồ mục tiêu.
// function ChartTarget({ socketio, dataUser }) {
//   return (
//     <div>
//       <div
//         className="progress progress-xl progress-animate custom-progress-4 rounded-0"
//         role="progressbar"
//         aria-valuenow="10"
//         aria-valuemin="0"
//         aria-valuemax="100"
//         style={{ borderBottom: "1px solid white" }}
//       >
//         <div className="progress-bar bg-warning rounded-0" style={{ width: "12%" }}>
//           12%
//         </div>
//         <div className="progress-bar-label">Hoạt động</div>
//       </div>
//       <div
//         className="progress progress-xl progress-animate custom-progress-4 rounded-0"
//         role="progressbar"
//         aria-valuenow="10"
//         aria-valuemin="0"
//         aria-valuemax="100"
//         style={{ borderBottom: "1px solid white" }}
//       >
//         <div
//           className="progress-bar bg-success rounded-0"
//           style={{ width: "52%" }}
//         >
//           52%
//         </div>
//         <div className="progress-bar-label">Thành công</div>
//       </div>
//       <div
//         className="progress progress-xl progress-animate custom-progress-4 rounded-0"
//         role="progressbar"
//         aria-valuenow="10"
//         aria-valuemin="0"
//         aria-valuemax="100"
//         style={{ borderBottom: "1px solid white" }}
//       >
//         <div className="progress-bar bg-danger rounded-0" style={{ width: "22%" }}>
//           22%
//         </div>
//         <div className="progress-bar-label">Thất bại</div>
//       </div>
//     </div>
//   )
// }

// danh sách mục tiêu.
function ListTarget({ socketio, dataUser, objectSearch, dataTarget, dataThunhap, dataChiphi, result }) {
    
    // Quản lý detail target.
    const [detailTarget, setDetailTarget] = useState(false);
    const dataDetailTarget = useRef()
    const handleDetailTarget = (object) => {
        setDetailTarget(true);
        dataDetailTarget.current = object
    }
    const handleCloseDetailTarget = () => {
        setDetailTarget(false);
        dataDetailTarget.current = undefined
    }
  
  // Quan ly edit data.
  const [modalEdit, setModalEdit] = useState(false);
  const dataEditTarget = useRef()
  const handleEditTarget = (object) => {
    setModalEdit(true)
    dataEditTarget.current = object
  }
  const handleCloseEditTarget = () => {
    setModalEdit(false);
    dataEditTarget.current = undefined
  }
  const handleEditTargetSuccess = (object) => {
    if (object.status) {
      // Update lai data target.
      result(object)
      setModalEdit(false)
    }
  }

  // Quan ly delete data.
  const changeDeleteTarget = useRef()
  const handleDeleteDataTarget = (id) => {
    let checkDelete = window.confirm("Bạn muốn xóa chứ ?");
    if (checkDelete) {
      const paramsDelete = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: {id: id, delete_target: 1}
      }
      socketio.emit("client-edit-row-delete-taichinhcanhan-target", paramsDelete)
      socketio.on("server-edit-row-delete-taichinhcanhan-target", (data) => {
        if (data === "success") {
          result({
            status: "delete success",
            id: id
          });
          changeDeleteTarget.current = id
        }
      })
    }
  }
  useEffect(() => {
    return () => {
      socketio.off("client-edit-row-delete-taichinhcanhan-target")
      socketio.off("server-edit-row-delete-taichinhcanhan-target")
    }
  }, [changeDeleteTarget.current])

  if (dataTarget.length === 0) {
    return <div className="text-center mt-3 fs-15">Bạn chưa có mục tiêu.</div>
  }
    return (
      <>
        <ul className="list-group list-group-flush">
          {dataTarget.map((item, index) => {
            // Set thời hạn còn lại
            let dateStart = moment(item.date_start)
            let dateEnd = moment(item.date_end)
            let currentTime = moment()
            let soNgayDaqua = Number(currentTime.diff(dateStart, "days"));
            if (soNgayDaqua < 0) {
              soNgayDaqua = Number(dateStart.diff(dateStart, "days"))
            }

            let limitTime = dateEnd.diff(currentTime, "days")
            let formatLimitTime = ""
            if (Number(limitTime) >= 365) {
              formatLimitTime = Number(limitTime) / 365 + " năm"
            } else if (Number(limitTime) >= 30) {
              formatLimitTime = dateEnd.diff(currentTime, "months") + " tháng"
            } else {
              if (Number(limitTime) >= 0) {
                formatLimitTime = limitTime + " ngày"
              } else {
                formatLimitTime = <span className="text-danger">Hết hạn</span>
              }
            }

            // Set số tiền trung bình mỗi ngày cần phải kiếm.
            let moneyDays = Number(item.money_target) / Number(dateEnd.diff(dateStart, "days"));

            // Set lợi nhuận trong khoảng thời gian của mục tiêu.
            let thunhap = dataThunhap.find(
              (value) => value.target_id === item.id,
            );
            let chiphi = dataChiphi.find((value) => value.target_id === item.id);
            let loinhuan = 0;
            if (thunhap && chiphi) {
              loinhuan = thunhap.total_money_thunhap - chiphi.total_money_chiphi
            }
            let loinhuanDays = loinhuan / soNgayDaqua;
            let tysuatSuccess = (loinhuan / Number(item.money_target)) * 100
            if (tysuatSuccess > 0) {
              tysuatSuccess = Number.isInteger(tysuatSuccess) ? tysuatSuccess : tysuatSuccess.toFixed(1)
            } else {
              tysuatSuccess = 0
            }
            // Set ty suat hoan thanh
            
            let color_;
            if (loinhuanDays > moneyDays) {
              color_ = "#26bf94"
            } else if (loinhuanDays === moneyDays) {
              color_ = "#f5b849"
            } else {
              color_ = "#e6533c"
            }
            const object = {
              sizeChart: 55,
              colorMain: color_,
              color: "#efefef",
              rate: tysuatSuccess,
            }
            return (
              <li
                className="list-group-item border-top-0 border-start-0 border-end-0 px-0 cursor-pointer"
                key={index}
              >
                <div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div
                      className="d-flex align-items-center"
                      onClick={() =>
                        handleDetailTarget({
                          item,
                          thunhap,
                          chiphi,
                          dataUser,
                          tysuatSuccess,
                          color_,
                        })
                      }
                    >
                      <Tippy content="Tỷ lệ thành công">
                        <div className="me-2 lh-1">
                          <PieChart data={object} />
                        </div>
                      </Tippy>
                      <div className="flex-fill">
                        <p className="mb-0 fw-semibold text-than">
                          {item.target_cate}
                          {tysuatSuccess >= 100 && (
                            <IoMdCheckmarkCircle className="ms-1 text-success" />
                          )}
                          {tysuatSuccess < 100 && loinhuanDays < moneyDays && (
                            <IoCloseCircle className="ms-1 text-danger" />
                          )}
                        </p>
                        <p className="fs-14 text-muted mb-0">
                          {moment(item.addtime)
                            .locale("vi")
                            .fromNow()
                            .replace("một", "1")}{" "}
                          <BsDot />
                          {Number(limitTime) > 0 ? "còn lại" : ""}{" "}
                          {formatLimitTime}
                        </p>
                        <p className="mb-0 fs-14 text-muted">
                          {moment(item.date_start).format("DD/MM/YYYY")}
                          <BsDot />
                          {moment(item.date_end).format("DD/MM/YYYY")}
                        </p>
                      </div>
                    </div>

                    <div className="text-end">
                      <p className="mb-0 fs-16 fw-5 text-than">
                        {Number(item.money_target)
                          .toLocaleString()
                          .replace(/[.]/g, ",")}
                      </p>
                      <Tippy content="Thu nhập đã trừ chi phí">
                        <p className="fs-14 mb-0 text-muted">
                          {loinhuan.toLocaleString().replace(/[.]/g, ",")}
                        </p>
                      </Tippy>
                      <button
                        className="badge bg-success-transparent border-0 fs-14 me-1 fw-4"
                        onClick={() => handleEditTarget(item)}
                      >
                        Sửa
                      </button>
                      <button
                        className="badge bg-danger-transparent border-0 fs-14 fw-4"
                        onClick={() => handleDeleteDataTarget(item.id)}
                      >
                        Xóa
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            )
          })}
          
        </ul>
        {/* Modal detail target */}
        {detailTarget && (
          <ModalReact
            modalTitle="Chi tiết mục tiêu"
            showModal={detailTarget}
            handleClose={handleCloseDetailTarget}
            theme={<DetailTarget dataDetail={dataDetailTarget.current} />}
          />
        )}
        {/* Edit data target */}
        {modalEdit && (
          <ModalReact
            modalTitle="Sửa thông tin mục tiêu"
            showModal={modalEdit}
            handleClose={handleCloseEditTarget}
            theme={<FormEditTarget socketio={socketio} dataUser={dataUser} dataEdit={dataEditTarget.current} result={handleEditTargetSuccess}/>}
          />
        )}
      </>
    )
}


// Detail target.
function DetailTarget({ dataDetail }) {
  
  let loinhuan =
    dataDetail.thunhap.total_money_thunhap -
    dataDetail.chiphi.total_money_chiphi
    return (
      <div>
        <div className="text-center">
          <span className="avatar avatar-xxl me-2 avatar-rounded">
            <img src={"/" + dataDetail.dataUser[0].avatar} alt="img" />
          </span>
          <p className="fs-18 text-warning mb-0 fw-5">
            {dataDetail.item.target_cate}
            <IoRibbon className="ms-1 me-1" />
            {Number(dataDetail.item.money_target)
              .toLocaleString()
              .replace(/[.]/g, ",")}
          </p>
          <p className="mb-0 fs-16 text-muted">
            <span className="me-1">Thời gian</span>
            {moment(dataDetail.item.date_start).format("DD/MM/YYYY")}
            <BsDot />
            {moment(dataDetail.item.date_end).format("DD/MM/YYYY")}
          </p>
        </div>

        <div className="wrapper_ground mt-3">
          <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-3 list_items">
            <div>
              <div className="category-link primary text-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="category-svg"
                  enableBackground="new 0 0 24 24"
                  viewBox="0 0 24 24"
                >
                  <g>
                    <rect fill="none" height="24" width="24"></rect>
                  </g>
                  <g>
                    <g opacity=".3">
                      <path d="M6.71,18.71c-0.28,0.28-2.17,0.76-2.17,0.76s0.47-1.88,0.76-2.17C5.47,17.11,5.72,17,6,17c0.55,0,1,0.45,1,1 C7,18.28,6.89,18.53,6.71,18.71z M7.41,10.83L5.5,10.01l1.97-1.97l1.44,0.29C8.34,9.16,7.83,10.03,7.41,10.83z M13.99,18.5 l-0.82-1.91c0.8-0.42,1.67-0.93,2.49-1.5l0.29,1.44L13.99,18.5z M19.99,4.01c0,0-3.55-0.69-8.23,3.99 c-1.32,1.32-2.4,3.38-2.73,4.04l2.93,2.93c0.65-0.32,2.71-1.4,4.04-2.73C20.68,7.56,19.99,4.01,19.99,4.01z M15,11 c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2s2,0.9,2,2C17,10.1,16.1,11,15,11z"></path>
                    </g>
                    <g>
                      <path d="M6,15c-0.83,0-1.58,0.34-2.12,0.88C2.7,17.06,2,22,2,22s4.94-0.7,6.12-1.88C8.66,19.58,9,18.83,9,18C9,16.34,7.66,15,6,15 z M6.71,18.71c-0.28,0.28-2.17,0.76-2.17,0.76s0.47-1.88,0.76-2.17C5.47,17.11,5.72,17,6,17c0.55,0,1,0.45,1,1 C7,18.28,6.89,18.53,6.71,18.71z M17.42,13.65L17.42,13.65c6.36-6.36,4.24-11.31,4.24-11.31s-4.95-2.12-11.31,4.24l-2.49-0.5 C7.21,5.95,6.53,6.16,6.05,6.63L2,10.69l5,2.14L11.17,17l2.14,5l4.05-4.05c0.47-0.47,0.68-1.15,0.55-1.81L17.42,13.65z M7.41,10.83L5.5,10.01l1.97-1.97l1.44,0.29C8.34,9.16,7.83,10.03,7.41,10.83z M13.99,18.5l-0.82-1.91 c0.8-0.42,1.67-0.93,2.49-1.5l0.29,1.44L13.99,18.5z M16,12.24c-1.32,1.32-3.38,2.4-4.04,2.73l-2.93-2.93 c0.32-0.65,1.4-2.71,2.73-4.04c4.68-4.68,8.23-3.99,8.23-3.99S20.68,7.56,16,12.24z M15,11c1.1,0,2-0.9,2-2s-0.9-2-2-2s-2,0.9-2,2 S13.9,11,15,11z"></path>
                    </g>
                  </g>
                </svg>
                <p className="fs-14 mb-1 text-default fw-semibold">Thu nhập</p>
                <span className="fs-15 text-muted">
                  {dataDetail.thunhap.total_money_thunhap
                    .toLocaleString()
                    .replace(/[.]/g, ",")}
                </span>
              </div>
            </div>
          </div>
          <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-3 list_items">
            <div>
              <div className="category-link secondary text-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="category-svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M0 0h24v24H0V0z" fill="none"></path>
                  <path
                    d="M5 5h4v6H5zm10 8h4v6h-4zM5 17h4v2H5zM15 5h4v2h-4z"
                    opacity=".3"
                  ></path>
                  <path d="M3 13h8V3H3v10zm2-8h4v6H5V5zm8 16h8V11h-8v10zm2-8h4v6h-4v-6zM13 3v6h8V3h-8zm6 4h-4V5h4v2zM3 21h8v-6H3v6zm2-4h4v2H5v-2z"></path>
                </svg>
                <p className="fs-14 mb-1 text-default fw-semibold">Chi phí</p>
                <span className="fs-15 text-muted">
                  {dataDetail.chiphi.total_money_chiphi
                    .toLocaleString()
                    .replace(/[.]/g, ",")}
                </span>
              </div>
            </div>
          </div>
          <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-3 list_items">
            <div>
              <div className="category-link warning text-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="category-svg"
                  enableBackground="new 0 0 24 24"
                  viewBox="0 0 24 24"
                >
                  <path
                    opacity="0.2"
                    d="M9 22H5a3.003 3.003 0 0 1-3-3V5a3.003 3.003 0 0 1 3-3h4a3.003 3.003 0 0 1 3 3v14a3.003 3.003 0 0 1-3 3z"
                  ></path>
                  <path
                    opacity="0.4"
                    d="m20.293 6.535-2.828-2.828a3.004 3.004 0 0 0-4.243 0l-1.229 1.228c0 .022.007.043.007.065v14c0 .027-.007.052-.008.08l8.301-8.302a3.004 3.004 0 0 0 0-4.243z"
                  ></path>
                  <circle cx="7" cy="17" r="1" opacity="1"></circle>
                  <path
                    opacity="1"
                    d="m19.065 12.007-7.073 7.072c0-.027.008-.052.008-.079a3.003 3.003 0 0 1-3 3h10a3.003 3.003 0 0 0 3-3v-4a3 3 0 0 0-2.935-2.993z"
                  ></path>
                </svg>
                <p className="fs-14 mb-1 text-default fw-semibold">Lợi nhuận</p>
                <span className="fs-15 text-muted">
                  {loinhuan.toLocaleString().replace(/[.]/g, ",")}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 mt-3 text-center">
          <div type="button" className="btn btn-primary-ghost btn-wave col-12">
            <div className="mb-1">
              Tỷ suất thành công{" "}
              <span className="fs-18 fw-5">{dataDetail.tysuatSuccess}%</span>
              <span className="ms-1 text-info">
                {dataDetail.tysuatSuccess > 100 && "Vượt kỳ vọng"}
              </span>
            </div>

            <div
              className="progress progress-xl progress-animate custom-progress-4 rounded-0 mb-1"
              role="progressbar"
              aria-valuenow="10"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ borderBottom: "1px solid white" }}
            >
              <div
                className={cx(
                  "progress-bar rounded-0",
                  // dataDetail.tysuatSuccess >= 100 && "bg-success",
                  // dataDetail.tysuatSuccess >= 80 && "bg-success",
                  // dataDetail.tysuatSuccess >= 100 && "bg-success",
                )}
                style={{
                  width: dataDetail.tysuatSuccess + "%",
                  background: dataDetail.color_,
                }}
              >
                {dataDetail.tysuatSuccess}%
              </div>
              <div className="progress-bar-label">Thành công</div>
            </div>

            <div className="text-primary text-center fs-16 mt-3 d-flex align-items-center justify-content-center">
              {dataDetail.tysuatSuccess >= 100 && (
                <span>
                  <span className="me-1 text-success">
                    Bạn <span className="fw-5 fs-21">được nhận</span> phần
                    thưởng
                  </span>
                  <span>
                    <p className="mb-0">
                      <FaGifts className="me-2 fs-29" />
                    </p>
                    <p>{dataDetail.item.note}</p>
                  </span>
                </span>
              )}
              {dataDetail.tysuatSuccess < 100 && (
                <span>
                  <span className="me-1 text-danger">
                    Bạn <span className="fw-5 fs-21">không được nhận</span> phần
                    thưởng
                  </span>
                  <span>
                    <p className="mb-0">
                      <FaGifts className="me-2 fs-29" />
                    </p>
                    <p>{dataDetail.item.note}</p>
                  </span>
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    )
}