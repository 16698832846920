import Tippy from "@tippyjs/react"
import { useEffect, useRef, useState } from "react"
import { BsDot } from "react-icons/bs"
import { RiMoneyDollarCircleLine } from "react-icons/ri"
import { FaStar } from "react-icons/fa"

import ModalReact from "../../../../../Components/Modal/ModalReact"
import PieChart from "../../../../../Components/VongTySuat/PieChart"
import { TbArrowBigDownLinesFilled, TbArrowBigUpLinesFilled } from "react-icons/tb"
import moment from "moment"
import LoadingSpinner from "../../../../../Components/Loading/LoadingSpinner"
import { FormEditPhanchiaDongtien } from "./FormPhanchiaDongtien"
import { handleTotalCardWallet } from "../reuse_taichinhcanha"

function ListPhanchia({
  socketio,
  dataUser,
  objectSearch,
  dataUpdateCreate,
  totalThunhapAll,
  changeStatus,
  dataCateThunhap,
  dataCateExpense,
}) {
  const [dataDongtien, setDataDongtien] = useState([])
  const dataDongtienRef = useRef([])
  const [totalChiphi, setTotalChiphi] = useState(0)
  const [loading, setLoading] = useState(true)

  // Xử lý get data.
  const handleGetDataDongtien = (objectSearch_) => {
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        user_id: dataUser[0].id_user,
        daySearch: objectSearch_.daySearch,
        monthSearch: objectSearch_.monthSearch,
        textSearch: objectSearch_.textSearch,
      },
    }
    socketio.emit("client-get-data-taichinhcanhan-dongtien", params)
    socketio.on("server-get-data-taichinhcanhan-dongtien", (data) => {
      if (data.length > 0) {
        setDataDongtien([...data])
        dataDongtienRef.current = [...data]
      }
    })
  }
  useEffect(() => {
    handleGetDataDongtien(objectSearch)
    return () => {
      socketio.off("client-get-data-taichinhcanhan-dongtien")
      socketio.off("server-get-data-taichinhcanhan-dongtien")
    }
  }, [objectSearch, totalThunhapAll, changeStatus])

  // Quản lý giá vàng trên thị trường.
  const [dataGiavang, setDataGiavang] = useState()
  useEffect(() => {
    // Get data gia vang gan nhat.
    let params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: { user_id: dataUser[0].id_user },
    }
    socketio.emit("client-get-month-recently-follow-giavang", params)
    socketio.on("server-get-month-recently-follow-giavang", (data) => {
      let objectGiavang = data.find((item) => item)
      setDataGiavang(objectGiavang)
    })

    return () => {
      socketio.off("client-get-month-recently-follow-giavang")
      socketio.off("server-get-month-recently-follow-giavang")
    }
  }, [totalThunhapAll, changeStatus])

  // Quanr ly total chi phi.
  useEffect(() => {
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        user_id: dataUser[0].id_user,
      },
    }
    socketio.emit("client-total-chiphi-all-taichinhcanhan-chiphi", params)
    socketio.on("server-total-chiphi-all-taichinhcanhan-chiphi", (data) => {
      if (data.length > 0) {
        let total = data[0].card_payment + data[0].wallet_payment
        setTotalChiphi(total)
      }
      setLoading(false)
    })
    return () => {
      socketio.off("client-total-chiphi-all-taichinhcanhan-chiphi")
      socketio.off("server-total-chiphi-all-taichinhcanhan-chiphi")
    }
  }, [totalThunhapAll, changeStatus, dataUpdateCreate])

  // Cập nhật lại data khi thêm mới thành công
  useEffect(() => {
    if (dataUpdateCreate.length > 0) {
      let dataUpdateNew = dataDongtienRef.current
      dataUpdateNew.push(dataUpdateCreate[0])
      let sortDataUpdateNew = dataUpdateNew.sort((a, b) => {
        let min_ = new Date(a.addtime).getTime()
        let max_ = new Date(b.addtime).getTime()
        return max_ - min_
      })
      setDataDongtien([...sortDataUpdateNew])
      dataDongtienRef.current = [...sortDataUpdateNew]
    }
    return () => {}
  }, [dataUpdateCreate])

  // Quản lý detail item.
  const [detailItem, setDetailItem] = useState(false)
  const dataDetailItem = useRef()
  const handleDetailItem = (object) => {
    setDetailItem(true)
    dataDetailItem.current = object
  }
  const handleCloseDetailItem = () => {
    setDetailItem(false)
    dataDetailItem.current = undefined
  }

  // Quan ly edit data.
  const [modalEdit, setModalEdit] = useState(false)
  const dataEdit = useRef()
  const handleEditData = (object) => {
    setModalEdit(true)
    dataEdit.current = object
  }
  const handleCloseModalEdit = () => {
    setModalEdit(false)
    dataEdit.current = undefined
  }
  const handleEditDataSuccess = (object) => {
    if (object.status) {
      let dataEditUpdate = dataDongtienRef.current
      let index = dataEditUpdate.findIndex(
        (item) => item.id === object.dataUpdate[0].id,
      )
      dataEditUpdate[index] = object.dataUpdate[0]
      setDataDongtien([...dataEditUpdate])
      dataDongtienRef.current = [...dataEditUpdate]
      setModalEdit(false)
      changeStatus(object.dataUpdate)
    }
  }

  // Quan ly delete data.
  const changeDeleteData = useRef()
  const handleDeleteData = (id) => {
    let checkDelete = window.confirm("Bạn muốn xóa ?")
    if (checkDelete) {
      const paramsDelete = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: { delete_dongtien: 1, id: id },
      }
      socketio.emit(
        "client-edit-row-delete-taichinhcanha-dongtien",
        paramsDelete,
      )
      socketio.on("server-edit-row-delete-taichinhcanha-dongtien", (data) => {
        let dataDeleteUpdate = dataDongtienRef.current.filter(
          (item) => item.id !== id,
        )
        setDataDongtien([...dataDeleteUpdate])
        dataDongtienRef.current = [...dataDeleteUpdate]
        changeStatus(data)
        changeDeleteData.current = id
        alert("Bạn đã xóa thành công.")
      })
    }
  }
  useEffect(() => {
    return () => {
      socketio.off("client-edit-row-delete-taichinhcanha-dongtien")
      socketio.off("server-edit-row-delete-taichinhcanha-dongtien")
    }
  }, [changeDeleteData.current])

  // Dieu kien hien thi.
  if (loading) {
    return <LoadingSpinner />
  }
  if (dataDongtien.length === 0) {
    return <div>Chưa có dữ liệu.</div>
  }

  return (
    <div>
      <ul className="list-group list-group-flush">
        {dataDongtien.map((item, index) => {
          // Set ty suat phan chia/tong thu nhap all.
          let tySuatPhanchia =
            (Number(item.money) / (Number(totalThunhapAll) - totalChiphi)) * 100
          const object = {
            sizeChart: 55,
            colorMain: item.color,
            color: "#efefef",
            rate: Number.isInteger(tySuatPhanchia)
              ? tySuatPhanchia
              : tySuatPhanchia.toFixed(2),
          }

          // Set gia vang.
          let giavang = dataGiavang
          let otherThunhap = 0
          if (item.statistical === "Giá vàng") {
            if (giavang) {
              let giavangTrenChi = Number(giavang.giaban) / 10
              let moneyChiVang = Number(item.quantity) * giavangTrenChi
              giavang =
                ((moneyChiVang - Number(item.money)) / Number(item.money)) * 100
            } else {
              giavang = 0
            }
          } else {
            giavang = ""
            // Set truờng hợp không phải giá vàng.
            let itemThunhapCate = [],
              itemChiphiCate = []
            if (item.statistical) {
              itemThunhapCate = dataCateThunhap.filter(
                (value) =>
                  value.title.toLowerCase() === item.statistical.toLowerCase(),
              )
              itemChiphiCate = dataCateExpense.filter(
                (value) =>
                  value.title.toLowerCase() === item.statistical.toLowerCase(),
              )
            }
            let totalChiphiCate = 0
            if (itemChiphiCate.length > 0) {
              totalChiphiCate = handleTotalCardWallet(itemChiphiCate);
            }
            if (itemThunhapCate.length > 0) {
              let totalThunhapCate = handleTotalCardWallet(itemThunhapCate)
              let totalLoinhuan = totalThunhapCate - totalChiphiCate
              otherThunhap =
                ((totalLoinhuan - Number(item.money)) / Number(item.money)) *
                100
            } else {
              otherThunhap = ""
            }
          }

          return (
            <li
              className="list-group-item border-top-0 border-start-0 border-end-0 px-0 cursor-pointer"
              key={index}
            >
              <div>
                <div className="d-flex align-items-center justify-content-between">
                  <div
                    className="d-flex align-items-center"
                    onClick={() =>
                      handleDetailItem({
                        item: item,
                        tySuatPhanchia: tySuatPhanchia,
                        giavang: giavang,
                        otherThunhap: otherThunhap,
                      })
                    }
                  >
                    <Tippy content="Tỷ lệ phân bổ trên tài sản">
                      <div className="me-2 lh-1">
                        <PieChart data={object} />
                      </div>
                    </Tippy>
                    <div className="flex-fill">
                      <p
                        className="mb-0 fw-semibold text-than"
                        style={{ color: item.color }}
                      >
                        {item.title}
                      </p>
                      <div className="fs-14 text-muted">
                        <p className="mb-0">
                          <span className="text-muted fw-4 fs-14">
                            <span>
                              {moment(item.addtime).format("DD/MM/YY")}
                            </span>
                            <BsDot />
                            {moment(item.addtime)
                              .locale("vi")
                              .fromNow()
                              .replace("một", "1")}
                          </span>
                        </p>
                        <p className="mb-0">{item.note}</p>
                      </div>
                    </div>
                  </div>

                  <div className="text-end">
                    <p className="mb-0 fs-16 fw-5 text-than">
                      {Number(item.money).toLocaleString().replace(/[.]/g, ",")}
                    </p>
                    {giavang > 0 && (
                      <p className="ms-1 fs-13 text-success mb-0">
                        {Number.isInteger(giavang)
                          ? giavang
                          : giavang.toFixed(2)}
                        %
                        <TbArrowBigUpLinesFilled />
                      </p>
                    )}

                    {giavang < 0 && (
                      <p className="ms-1 fs-13 text-danger mb-0">
                        {Number.isInteger(giavang)
                          ? giavang
                          : giavang.toFixed(1)}
                        %
                        <TbArrowBigDownLinesFilled />
                      </p>
                    )}
                    {/* So sanh voi thu nhap */}
                    {otherThunhap > 0 && (
                      <p className="ms-1 fs-13 text-success mb-0">
                        {Number.isInteger(otherThunhap)
                          ? otherThunhap
                          : otherThunhap.toFixed(2)}
                        %
                        <TbArrowBigUpLinesFilled />
                      </p>
                    )}
                    {otherThunhap < 0 && (
                      <p className="ms-1 fs-13 text-danger mb-0">
                        {Number.isInteger(otherThunhap)
                          ? otherThunhap
                          : otherThunhap.toFixed(1)}
                        %
                        <TbArrowBigDownLinesFilled />
                      </p>
                    )}

                    <button
                      className="badge bg-success-transparent border-0 fs-14 me-1 fw-4"
                      onClick={() => handleEditData(item)}
                    >
                      Sửa
                    </button>
                    <button
                      className="badge bg-danger-transparent border-0 fs-14 fw-4"
                      onClick={() => handleDeleteData(item.id)}
                    >
                      Xóa
                    </button>
                  </div>
                </div>
              </div>
            </li>
          )
        })}
      </ul>
      {/* Modal detail target */}
      {detailItem && (
        <ModalReact
          modalTitle="Chi tiết dòng tiền"
          showModal={detailItem}
          handleClose={handleCloseDetailItem}
          theme={<DetailItem objectDetail={dataDetailItem.current} />}
        />
      )}

      {/* Modal edit data */}
      {modalEdit && (
        <ModalReact
          modalTitle="Sửa thông tin"
          showModal={modalEdit}
          handleClose={handleCloseModalEdit}
          theme={
            <FormEditPhanchiaDongtien
              dataEdit={dataEdit.current}
              socketio={socketio}
              dataUser={dataUser}
              result={handleEditDataSuccess}
            />
          }
        />
      )}
    </div>
  )
}
export default ListPhanchia


// Chi tiết.
function DetailItem({ objectDetail }) {
    
  const [loinhuanDautu, setLoinhuandautu] = useState(0);
  const totalMoney = useRef(0)
  const [loading, setLoading] = useState(true);
  // Quan ly thong so.
  useEffect(() => {
    // Set loi nhuan dau tu.
    let loinhuanDautu_ = 0;
    if (objectDetail.item.statistical === "Giá vàng") {
      // Neu so sanh voi gia vang
      loinhuanDautu_ =
        (Number(objectDetail.item.money) * objectDetail.giavang) / 100
    } else {
      // So sanh voi thu nhap.
      loinhuanDautu_ = (Number(objectDetail.item.money) * objectDetail.otherThunhap) / 100;
    }
    setLoinhuandautu(Number.isInteger(loinhuanDautu_) ? loinhuanDautu_ : loinhuanDautu_.toFixed())

    // Set tổng vốn + lợi nhuận.
    let totalMoney_ = Number(objectDetail.item.money) + Number(loinhuanDautu_)
    totalMoney.current = Number.isInteger(totalMoney_) ? totalMoney_ : totalMoney_.toFixed()
      
    setLoading(false)

  }, [objectDetail]);

  if (loading) {
    return <LoadingSpinner />
  }

  // Set vòng tròn tỷ suất.
  const objectPhanboTien = {
    sizeChart: 55,
    colorMain: objectDetail.item.color,
    color: "#efefef",
    rate: Number.isInteger(objectDetail.tySuatPhanchia)
      ? objectDetail.tySuatPhanchia
      : objectDetail.tySuatPhanchia.toFixed(1),
  }
  const objectLoinhuan = {
    sizeChart: 55,
    colorMain: objectDetail.item.color,
    color: "#efefef",
    rate: objectDetail.item.statistical === "Giá vàng" ? Number.isInteger(objectDetail.giavang) ? objectDetail.giavang : objectDetail.giavang.toFixed(1) : 0,
  }

  // Set số năm còn lại.
  let dateStart = moment(objectDetail.item.date_start)
  let dateEnd = moment(objectDetail.item.date_end)
  let dateCurrent = moment();
  let diffYear = dateEnd.diff(dateCurrent, "years")
  if (Number(diffYear) < 1) {
    diffYear = dateEnd.diff(dateCurrent, "days") + " ngày"
  } else {
    diffYear = diffYear + " năm"
  };
  // Set mức tăng trưởng trung bình hàng năm.
  let soNam = dateCurrent.diff(dateStart, "years")
  let tangTruongNam = 0
  if (Number(soNam) >= 1) {
    if (objectDetail.item.statistical === "Giá vàng") {
      tangTruongNam = objectDetail.giavang / Number(soNam)
    } else {
      if (objectDetail.otherThunhap) {
        tangTruongNam = objectDetail.otherThunhap / Number(soNam)
      } else {
        tangTruongNam = ""
      }
    }
  } else {
    tangTruongNam = "Chưa đủ thời gian."
  }
    return (
      <div>
        <div>
          <div className="wrapper_ground">
            <div className="list_items">
              <div className="d-flex align-items-top">
                <div className="me-2">
                  <Tippy content="Tỷ lệ phân bổ trên thu nhập">
                    <div className="lh-1">
                      <PieChart data={objectPhanboTien} />
                    </div>
                  </Tippy>
                </div>
                <div>
                  <span className="d-block mb-1 text-muted">Vốn đầu tư</span>
                  <h6 className="fw-semibold mb-0">
                    {Number(objectDetail.item.money)
                      .toLocaleString()
                      .replace(/[.]/g, ",")}
                  </h6>
                </div>
              </div>
            </div>
            <div className="list_items">
              <div className="d-flex align-items-top">
                <div className="me-2">
                  <Tippy content="Tỷ suất lợi nhuận">
                    <div className="lh-1">
                      <PieChart data={objectLoinhuan} />
                    </div>
                  </Tippy>
                </div>
                <div>
                  <span className="d-block mb-1 text-muted">Lợi nhuận</span>
                  <h6 className="fw-semibold mb-0">
                    {Number(loinhuanDautu)
                      .toLocaleString()
                      .replace(/[.]/g, ",")}
                  </h6>
                </div>
              </div>
            </div>
            <div className="list_items">
              <div className="d-flex align-items-top">
                <div className="me-2">
                  <Tippy content="Tỷ suất tăng thêm">
                    <div className="lh-1">
                      {/* <PieChart data={objectLoinhuan} /> */}
                      <RiMoneyDollarCircleLine
                        style={{
                          fontSize: "48px",
                          color: objectDetail.item.color,
                        }}
                      />
                    </div>
                  </Tippy>
                </div>
                <div>
                  <span className="d-block mb-1 text-muted">Thành tiền</span>
                  <h6 className="fw-semibold mb-0">
                    {Number(totalMoney.current)
                      .toLocaleString()
                      .replace(/[.]/g, ",")}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ul className="list-group mt-3">
          <li className="list-group-item d-flex justify-content-between align-items-center fs-16 ">
            Số lượng
            <span className="text-muted">
              {objectDetail.item.quantity} {objectDetail.item.donvi}
            </span>
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-center fs-16 ">
            Bắt đầu
            <span className="text-muted">
              {moment(objectDetail.item.date_start).format("DD/MM/YYYY HH:mm")}
            </span>
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-center fs-16">
            Kết thúc
            <span className="text-muted">
              {moment(objectDetail.item.date_end).format("DD/MM/YYYY HH:mm")}
            </span>
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-center fs-16">
            Còn lại
            <span className="text-muted">{diffYear}</span>
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-center fs-16">
            Đã tăng trưởng sau {soNam} năm
            {/* So sanh voi gia vang */}
            {objectDetail.item.statistical === "Giá vàng" &&
              objectDetail.giavang > 0 && (
                <span className="text-success">
                  +
                  {Number.isInteger(objectDetail.giavang)
                    ? objectDetail.giavang
                    : objectDetail.giavang.toFixed(2)}
                  % <TbArrowBigUpLinesFilled />
                </span>
              )}
            {objectDetail.item.statistical === "Giá vàng" &&
              objectDetail.giavang < 0 && (
                <span className="text-danger">
                  {Number.isInteger(objectDetail.giavang)
                    ? objectDetail.giavang
                    : objectDetail.giavang.toFixed(2)}
                  % <TbArrowBigUpLinesFilled />
                </span>
              )}
            {/* So sanh voi thu nhap */}
            {(objectDetail.otherThunhap &&
              objectDetail.item.statistical !== "Giá vàng" &&
              objectDetail.otherThunhap < 0) ? (
                <span className="text-danger">
                  {Number.isInteger(objectDetail.otherThunhap)
                    ? objectDetail.otherThunhap
                    : objectDetail.otherThunhap.toFixed(2)}
                  % <TbArrowBigDownLinesFilled />
                </span>
              ): ""}
            {(objectDetail.otherThunhap &&
              objectDetail.item.statistical !== "Giá vàng" &&
              objectDetail.otherThunhap > 0) ? (
                <span className="text-success">
                  {Number.isInteger(objectDetail.otherThunhap)
                    ? objectDetail.otherThunhap
                    : objectDetail.otherThunhap.toFixed(2)}
                  % <TbArrowBigUpLinesFilled />
                </span>
              ): ""}
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-center fs-16">
            Tăng trưởng trung bình năm
            {Number(tangTruongNam) > 0 && (
              <span className="text-success">
                {Number.isInteger(tangTruongNam)
                  ? tangTruongNam
                  : tangTruongNam.toFixed(2)}
                %
                <TbArrowBigUpLinesFilled />
              </span>
            )}
            {Number(tangTruongNam) < 0 && (
              <span className="text-danger">
                {Number.isInteger(tangTruongNam)
                  ? tangTruongNam
                  : tangTruongNam.toFixed(2)}
                %
                <TbArrowBigDownLinesFilled />
              </span>
            )}
            {!Number(tangTruongNam) && <span className="text-muted">--</span>}
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-center fs-16">
            Đánh giá
            {Number(tangTruongNam) >= 25 && (
              <span className="text-warning">
                <FaStar className="me-1" />
                <FaStar className="me-1" />
                <FaStar className="me-1" />
                <FaStar className="me-1" />
                <FaStar className="me-1" />
              </span>
            )}
            {Number(tangTruongNam) >= 15 && Number(tangTruongNam) < 25 && (
              <span className="text-warning">
                <FaStar className="me-1" />
                <FaStar className="me-1" />
                <FaStar className="me-1" />
                <FaStar className="me-1" />
              </span>
            )}
            {Number(tangTruongNam) >= 10 && Number(tangTruongNam) < 15 && (
              <span className="text-warning">
                <FaStar className="me-1" />
                <FaStar className="me-1" />
                <FaStar className="me-1" />
              </span>
            )}
            {Number(tangTruongNam) >= 0 && Number(tangTruongNam) < 10 && (
              <span className="text-warning">
                <FaStar className="me-1" />
                <FaStar className="me-1" />
              </span>
            )}
            {!Number(tangTruongNam) && <span className="text-muted">--</span>}
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-center fs-16">
            Ghi chú
            <span className="text-muted">{objectDetail.item.note}</span>
          </li>
        </ul>
      </div>
    )
}

