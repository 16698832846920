import classNames from "classnames/bind"
import styles from "../../Job.module.scss";
import Tippy from "@tippyjs/react";
import { BsDot } from "react-icons/bs";
const cx = classNames.bind(styles);

function ListExpenseReport({ socketio, dataUser, object, dataExpense }) {

    return (
      <div>
        <div className="alert alert-primary text-center fs-16">
          Biểu đồ chi phí tháng
        </div>
        {dataExpense.map((item, index) => {
          let tysuatChiphi =
            (Number(item.total_expense) / object.totalExpenseMonth) * 100
          return (
            <Tippy
              key={index}
              content={
                "Chi phí " +
                Number(item.total_expense).toLocaleString().replace(/[.]/g, ",")
              }
            >
              <div
                className="progress progress-xl progress-animate custom-progress-4 rounded-0"
                role="progressbar"
                aria-valuenow="10"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ borderBottom: "1px solid white" }}
              >
                <div
                  className={cx("progress-bar bg-danger op-8 rounded-0")}
                  style={{ width: tysuatChiphi + "%" }}
                >
                  {Number.isInteger(tysuatChiphi)
                    ? tysuatChiphi
                    : tysuatChiphi.toFixed(2)}
                  %
                </div>
                <div className="progress-bar-label">
                  {tysuatChiphi <= 10 && (
                    <span className="me-1">
                      {Number.isInteger(tysuatChiphi)
                        ? tysuatChiphi
                        : tysuatChiphi.toFixed(2) + "%"}
                    </span>
                  )}
                  {item.loai_chiphi}
                  <BsDot className="ms-1 me-1"/>
                  {Number(item.total_expense)
                    .toLocaleString()
                    .replace(/[.]/g, ",")}
                </div>
              </div>
            </Tippy>
          )
        })}
      </div>
    )
}

export default ListExpenseReport