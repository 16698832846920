import React, { useEffect, useState } from "react"
import classNames from "classnames/bind"
import styles from "./Date.module.scss"

import DatePicker from "react-datepicker"
import { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import vi from "date-fns/locale/vi"
import moment from "moment"
registerLocale("vi", vi)
const cx = classNames.bind(styles)

function DateMonth({className, result, placeholderText, defaultMonth}) {
  const [selectedDate, setSelectedDate] = useState(
    defaultMonth ? defaultMonth : moment().format("YYYY-MM"),
  )
  const handleGetValueSearch = (date) => {
    setSelectedDate(date)
   result(date)
  }

  return (
    <DatePicker
      locale="vi"
      selected={selectedDate}
      onChange={handleGetValueSearch}
      dateFormat="MM/yyyy"
      showMonthYearPicker // chỉ cho chọn tháng
      isClearable // nút clear
      // showFullMonthYearPicker // hiển thị full name month
      showFourColumnMonthYearPicker
      placeholderText={placeholderText ? placeholderText : "Chọn tháng"}
      className={cx("dateInput", "fs-15 col-12", className)}
    />
  )
}

export default DateMonth
