import { useEffect, useRef, useState } from "react"
import DateMonth from "../../../../Components/Date/DateMonth"
import SearchHorizontal from "../../../../Components/Search/SearchHorizontal"
import moment from "moment"

function HandleSearchData({ listUser, result }) {
  const [object, setObject] = useState({
    search_name: "",
    search_text: "",
    search_date: "",
  })
  const checkSearch = useRef(false)
  // Value user.
  const handleValueUser = (e) => {
    if (e.target.value !== "") {
      checkSearch.current = true
    }
    setObject({
      search_name: e.target.value,
      search_text: object.search_text,
      search_date: object.search_date,
    })
  }
  // Value text search
  const handleValueSearchData = (keywords) => {
    if (keywords !== "") {
      checkSearch.current = true
    }
    setObject({
      search_name: object.search_name,
      search_text: keywords,
      search_date: object.search_date,
    })
  }
  // value Date search.
  const handleValueSearchDate = (date) => {
    if (moment(date).format("YYYY-MM").toLocaleLowerCase() === "invalid date") {
      setObject({
        search_name: object.search_name,
        search_text: object.search_text,
        search_date: "",
      })
    } else {
      checkSearch.current = true
      setObject({
        search_name: object.search_name,
        search_text: object.search_text,
        search_date: moment(date).format("YYYY-MM"),
      })
    }
  }
  // Đối tượng cần tìm kiếm
  useEffect(() => {
    if (checkSearch.current) {
      result(object)
    }
    // if (object.search_name !== "" || object.search_text !== "" || object.search_date !== "") {

    // }
  }, [object])
  return (
    <>
      {/* list option user */}
      <div className="ms-3">
        <select
          className="border-1 rounded-2 px-3 fs-15"
          style={{ height: "40px", border: "2px solid #e8e8e8" }}
          onChange={handleValueUser}
        >
          <option value="">Chọn nhân viên</option>
          {listUser.map((option, index) => {
            return (
              <option value={option.id} key={index}>
                {option.hoten}
              </option>
            )
          })}
        </select>
      </div>
      {/* Search data */}
      <div className="ms-3">
        <SearchHorizontal
          result={handleValueSearchData}
          className="rounded-2 border-1"
        />
      </div>
      <div className="ms-3">
        <DateMonth className="rounded-2" result={handleValueSearchDate} />
      </div>
      {/* *Search data */}
    </>
  )
}

export default HandleSearchData