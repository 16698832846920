import { useContext, useEffect } from "react"
import CheckPCMobile from "../../handle-reuses/reuses/check.pc.mobile"
import DataSalePageMobile from "./Mobile"
import DataSalePagePC from "./PC"
import AppContext from "../../handle-reuses/Contexts/CreateContexts"
import { useNavigate } from "react-router-dom"

function DataSalePage() {
    const { dataUser } = useContext(AppContext);
    const navigate = useNavigate()
    useEffect(() => {
        if (dataUser[0].data_sale !== 1) {
            alert("Bạn không có quyền truy cập mục này.")
            navigate("/")
        }
    }, [])
    return (
        <CheckPCMobile>
            {(isMobile)=> isMobile ? <DataSalePageMobile /> : <DataSalePagePC />}
        </CheckPCMobile>
    )
}

export default DataSalePage