import { useContext } from "react"
import AppContext from "../../../handle-reuses/Contexts/CreateContexts"
import PageKhachHang2 from "./PageKhachHang-2"

function KhachhangPage() {
  const { dataUser } = useContext(AppContext)
  if (dataUser[0].goidichvu_user !== 1) {
    return (
      <div className="text-danger mt-3 text-center fs-15">
        Bạn không có quyền truy cập vào trang này.
      </div>
    )
  }
  return <PageKhachHang2 />
}

export default KhachhangPage