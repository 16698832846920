import moment from "moment"
import { useEffect, useRef, useState } from "react"
import classNames from "classnames/bind"
import styles from "../../Job.module.scss"
import LoadingSpinner from "../../../../Components/Loading/LoadingSpinner"
import SearchHorizontal from "../../../../Components/Search/SearchHorizontal"
import { BsDot } from "react-icons/bs"
import { TextLimit } from "../../../../handle-reuses/reuses"
const cx = classNames.bind(styles);

function ListDonNghiviec({socketio, dataUser}) {
    const [dataNghiviec, setDataNghiviec] = useState([])
    const dataAccept = useRef([])
    const monthRef = useRef(moment().format("YYYY-MM"))
    const [objectSearch, setObjectSearch] = useState({
      monthSearch: monthRef.current,
      textSearch: "",
    })
    const [loading, setLoading] = useState(true)

    // Quản lý danh sách đơn nghỉ việc.
    const handleGetDataNghiviec = (monthSearch, keywords) => {
      const params = {
        iden: process.env.REACT_APP_IDEN_DASH,
        object: {
          role: dataUser[0].list_xinnghi,
          month: monthSearch,
          keywords: keywords,
          user_id: dataUser[0].id_user,
        },
      }
      socketio.emit("client-get-search-data-donnghiviec", params)
      socketio.on("server-get-search-data-donnghiviec", (data) => {
        let dataAcceptStart = []
        for (let i = 0; i < data.length; i++) {
          if (data[i].id) {
            dataAcceptStart.push(data[i])
          }
        }
        setDataNghiviec([...dataAcceptStart])
        dataAccept.current = [...dataAcceptStart]
        setLoading(false)
      })
    }
    useEffect(() => {
      // Gọi dữ liệu ban đầu.
      handleGetDataNghiviec(monthRef.current)
      return () => {
        socketio.off("client-get-search-data-donnghiviec")
        socketio.off("server-get-search-data-donnghiviec")
      }
    }, [])

    // Quản lý tìm kiếm
    const handleValueSearchText = (value) => {
      setObjectSearch({
        monthSearch: objectSearch.monthSearch,
        textSearch: value,
      })
      handleGetDataNghiviec(objectSearch.monthSearch, value)
      monthRef.current = objectSearch.monthSearch
    }
    const handleValueSearchMonth = (e) => {
      setObjectSearch({
        monthSearch: e.target.value,
        textSearch: objectSearch.textSearch,
      })
      handleGetDataNghiviec(
        moment(e.target.value).format("YYYY-MM"),
        objectSearch.textSearch,
      )
      monthRef.current = e.target.value
    }

    // Quản lý duyệt đơn nghỉ việc.
    const handleAbsentAccept = (id, index) => {
      const checkAccept = window.confirm("Bạn xác nhận đồng ý chứ ?")
      if (checkAccept) {
        const paramsEdit = {
          iden: process.env.REACT_APP_IDEN_DASH,
          object: { status: 1, id: id },
        }
        socketio.emit("client-edit-row-status-donnghiviec", paramsEdit)
        socketio.on("server-edit-row-status-donnghiviec", (object) => {
          if (object.status === "success") {
            let dataUpdateAccept = dataAccept.current
              dataUpdateAccept[index] = object.data[0];
            setDataNghiviec([...dataUpdateAccept])
          }
        })
      }
    }
    useEffect(() => {
      return () => {
        socketio.off("client-edit-row-status-donnghiviec")
        socketio.off("server-edit-row-status-donnghiviec")
      }
    }, [dataNghiviec])

    // Quản lý hiển thị tất cả nội dung chi tiết.
    const [activeContent, setActiveContent] = useState()
    const handleActiveMoreContent = (id) => {
      setActiveContent(id)
    }

    if (loading) {
      return <LoadingSpinner />
    }
    return (
      <div>
        {/* Search data */}
        <div className="wrapper_ground">
          <div className="list_items">
            <input
              type="month"
              className={cx("class_input", "rounded-3 col-12")}
              style={{
                border: "2px solid rgb(128 129 246 / 58%)",
                height: "40px",
              }}
              value={objectSearch.monthSearch}
              onChange={handleValueSearchMonth}
            />
          </div>
          <div className="list_items">
            <SearchHorizontal
              result={handleValueSearchText}
              className="rounded-3 col-12"
              style={{
                border: "2px solid rgb(128 129 246 / 58%)",
                width: "100%",
              }}
            />
          </div>
        </div>
        {/* List content */}
        <div className="mt-3 text-center fs-15 alert alert-primary">
          Danh sách yêu cầu nghỉ việc tháng{" "}
          <span className="fw-5 fs-16">{moment(monthRef.current).format("MM/YYYY")}</span>
        </div>
        {dataNghiviec.length === 0 && (
          <div className="mt-3 fs-15 text-center">
            Không có dữ liệu phù hợp.
          </div>
        )}
        {dataNghiviec.length > 0 && (
          <div className="mt-3">
            {dataNghiviec.map((item, index) => {
              let infoAbsent = item.title + " " + item.content
              let setToday = moment(item.addtime).format("YYYY-MM-DD")
              let currentDay = moment().format("YYYY-MM-DD")
              if (setToday === currentDay) {
                setToday = (
                  <span className="text-primary me-1 fs-14">Hôm nay</span>
                )
              } else {
                setToday = ""
              }
              return (
                <div className="col-xl-12" key={index}>
                  <div className="card custom-card rounded-4 mb-3">
                    <div className="alert alert-light">
                      <div className="d-flex align-items-center w-100">
                        <div className="me-2">
                          <span className="avatar avatar-rounded">
                            <img
                              src={"https://system.view360.vn/" + item.avatar}
                              alt="img"
                            />
                          </span>
                        </div>
                        <div className="">
                          <div className="fs-15 fw-semibold">
                            <span className="me-1">{item.hoten}</span>
                            <BsDot />
                            {setToday}
                            {item.status === 0 ? (
                              dataUser[0].list_vangmat === 1 ? (
                                <button
                                  className="badge bg-warning ms-2 border-0"
                                  onClick={() =>
                                    handleAbsentAccept(item.id, index)
                                  }
                                >
                                  Chờ xử lý
                                </button>
                              ) : (
                                <span className="badge bg-warning ms-2">
                                  Chờ xử lý
                                </span>
                              )
                            ) : (
                              <span className="badge bg-success ms-2">
                                Đã phê duyệt
                              </span>
                            )}
                          </div>
                          <div className="mb-0 fs-14">
                            {activeContent !== item.id ? (
                              <span className="op-8 fw-5">
                                <TextLimit text={infoAbsent} number={33} />
                              </span>
                            ) : (
                              <div className="alert alert-primary mt-1 mb-1">
                                <div className="fw-5 fs-15">{item.title}</div>
                                <div className="border-top border-block-start-dashed mb-1 mt-1 op-3 bg-primary"></div>
                                <span className="op-9 fs-14">
                                  {item.content}
                                </span>
                                <div className="border-top border-block-start-dashed mb-1 mt-1 op-3 bg-primary"></div>
                                <div>
                                  Gửi bởi:{" "}
                                  <span className="fw-5">{item.hoten}</span>{" "}
                                  <BsDot />{" "}
                                  <span className="op-8">
                                    {moment(item.addtime).format(
                                      "DD/MM/YYYY HH:mm:ss",
                                    )}
                                  </span>
                                </div>
                              </div>
                            )}
                            {activeContent !== item.id &&
                              item.content.length >= 33 && (
                                <button
                                  className="border-0 bg-transparent"
                                  onClick={() =>
                                    handleActiveMoreContent(item.id)
                                  }
                                >
                                  Xem thêm
                                </button>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
}
export default ListDonNghiviec