import classNames from "classnames/bind"
import moment from "moment";
import { GoDotFill } from "react-icons/go";

import styles from "../Job.module.scss"
import { GoBack } from "../../../Components/Goback";
import { IoMdAdd } from "react-icons/io";
import SearchHorizontal from "../../../Components/Search/SearchHorizontal";
import { useState } from "react";
import { IoCalendarOutline } from "react-icons/io5";
import { BsDot } from "react-icons/bs";
import { GrLineChart } from "react-icons/gr";
import ModalReact from "../../../Components/Modal/ModalReact";
const cx = classNames.bind(styles);

function ListProjectPC() {
  // Quản lý danh mục.
  const [activeCate, setActiveCate] = useState("tat-ca")
  const handleActiveCate = (cate) => {
    setActiveCate(cate)
  }

  // Quản lý search data.
  const handleGetValueSearchText = () => {}
  return (
    <>
      {/* Top 1 */}
      <div className={cx("d-flex justify-content-between align-items-center")}>
        <div className={cx("d-flex align-items-center gap-3 fs-15 fw-5")}>
          <GoBack />
          <span>Quản lý dự án kinh doanh</span>
        </div>
        <div className="d-flex align-items-center gap-3">
          <span className="d-flex align-items-center fs-14">
            <GoDotFill className="text-success-1 me-1 fs-18" />
            Thành công
          </span>
          <span className="d-flex align-items-center fs-14">
            <GoDotFill className="text-warning-1 me-1 fs-18" />
            Chờ duyệt
          </span>
          <span className="d-flex align-items-center fs-14">
            <GoDotFill className="text-danger me-1 fs-18" />
            Đã hủy bỏ
          </span>
        </div>
      </div>
      {/* top 2 */}
      <div
        className={cx("d-flex justify-content-between align-items-center mt-3")}
      >
        {/* Left */}
        <div className={cx("d-flex alig-items-center gap-3 fs-15")}>
          <button
            className={cx(
              "border-0 bg-transparent",
              activeCate === "tat-ca" ? "color-orange" : "color-gray",
            )}
            onClick={() => handleActiveCate("tat-ca")}
          >
            <span className="d-flex align-items-center">Tất cả</span>
          </button>
          <button
            className={cx(
              "border-0 bg-transparent",
              activeCate === "dang-xay-dung" ? "color-orange" : "color-gray",
            )}
            onClick={() => handleActiveCate("dang-xay-dung")}
          >
            <span className="d-flex align-items-center">Đang xây dựng</span>
          </button>
          <button
            className={cx(
              "border-0 bg-transparent",
              activeCate === "dang-hoat-dong" ? "color-orange" : "color-gray",
            )}
            onClick={() => handleActiveCate("dang-hoat-dong")}
          >
            <span className="d-flex align-items-center">Đang hoạt động</span>
          </button>
          <button
            className={cx(
              "border-0 bg-transparent",
              activeCate === "du-an-cho" ? "color-orange" : "color-gray",
            )}
            onClick={() => handleActiveCate("du-an-cho")}
          >
            <span className="d-flex align-items-center">Dự án chờ</span>
          </button>
          <button
            className={cx(
              "border-0 bg-transparent",
              activeCate === "du-an-huy-bo" ? "color-orange" : "color-gray",
            )}
            onClick={() => handleActiveCate("du-an-huy-bo")}
          >
            <span className="d-flex align-items-center">Dự án hủy bỏ</span>
          </button>
        </div>
        {/* Right */}
        <div className={cx("d-flex align-items-center gap-3")}>
          {/* Search text */}
          <SearchHorizontal
            result={handleGetValueSearchText}
            className={cx("rounded-1 border-1")}
            style={{ background: "white" }}
          />
          {/* Thêm mới công việc */}
          <button
            className={cx(
              "button_color_b",
              "border-0 rounded-1 d-flex align-items-center",
            )}
          >
            <IoMdAdd className="fs-15 me-1" />
            Thêm dự án
          </button>

        </div>
      </div>
      {/* List content */}
      <div className={cx("mt-3")}>
        {/* Tiến độ */}
        {activeCate === "tat-ca" && <ListProject />}
        {activeCate === "dang-xay-dung" && <ListProject />}
        {activeCate === "dang-hoat-dong" && <ListProject />}
        {activeCate === "du-an-cho" && <ListProject />}
        {activeCate === "du-an-huy-bo" && <ListProject />}
      </div>
    </>
  )
}
export default ListProjectPC


// List project.
function ListProject() {
    // Quản lý chi tiết dự án.
    const [detailProject, setDetailProject] = useState(false);
    const handleModalDetailProject = () => {
        setDetailProject(true)
    }
    const handleCloseModalDetailProject = () => {
        setDetailProject(false)
    }
    return (
      <>
        <div className="table-responsive">
          <table className="table text-nowrap">
            <tbody>
              <tr>
                <td scope="row">
                  <div className="d-flex align-items-center gap-2">
                    <span className="avatar avatar-md online avatar-rounded">
                      <img
                        src="https://system.view360.vn/images/user/21-hoanghieu.jpeg"
                        alt="img"
                      />
                    </span>
                    {/* <div>
                  <span className="fw-5">Hoàng văn hiếu</span>
                  <p className="mb-0 text-muted fw-4">Thị trường, công nghệ</p>
                </div> */}
                  </div>
                </td>
                <td>
                  <p className="mb-0 fw-5">Tên dự án</p>
                  <span className="text-muted">MUA NHÀ HẢI PHÒNG</span>
                </td>
                <td>
                  <p className="mb-0 fw-5">Danh mục</p>
                  <span className="text-muted">Bất động sản</span>
                </td>
                <td>
                  <p className="mb-0 fw-5">Lĩnh vực</p>
                  <span className="text-muted">Truyền thông</span>
                </td>
                <td>
                  <p className="mb-0 fw-5">Khu vực</p>
                  <span className="text-muted">Hải Phòng</span>
                </td>
                <td>
                  <p className="mb-0 fw-5 text-orange">Website</p>
                  <a href="https://muanhahaiphong.com" className="text-info">
                    muanhahaiphong.com
                  </a>
                </td>

                <td>
                  <span className="d-flex align-items-center gap-3">
                    <button
                      className={cx("border-0", "button_icon")}
                      onClick={handleModalDetailProject}
                    >
                      <GrLineChart className="fs-21" />
                    </button>
                    <span>
                      <span className="fw-5">Chi tiết</span>
                      <p className="mb-0 text-muted">
                        Các giai đoạn triển khai
                      </p>
                    </span>
                  </span>
                </td>
                <td>
                  <span className={cx("text-muted text-success fw-5 fs-18")}>
                    100%
                  </span>
                  <div
                    class="progress progress-xs progress-animate"
                    role="progressbar"
                    aria-valuenow="10"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    <div
                      class="progress-bar progress-bar-striped bg-success"
                      style={{ width: "100%" }}
                    ></div>
                  </div>
                </td>
                <td>
                  <button
                    className={cx(
                      "bg-success-1",
                      "border-0 rounded-1 p-1 px-2 fw-5 fs-14",
                    )}
                    style={{ width: "100%", color: "white" }}
                  >
                    Đang hoạt động
                  </button>
                </td>

                <td>
                  <span className="d-flex align-items-center gap-3">
                    <button className={cx("border-0", "button_icon")}>
                      <IoCalendarOutline className="fs-21" />
                    </button>
                    <span>
                      <span className="fw-5">Ngày tạo</span>
                      <p className="mb-0 text-muted">05/07/2024 08:00</p>
                    </span>
                  </span>
                </td>
              </tr>
              <tr>
                <td scope="row">
                  <div className="d-flex align-items-center gap-2">
                    <span className="avatar avatar-md online avatar-rounded">
                      <img
                        src="https://system.view360.vn/images/user/2078-5A2353E0-59F9-407F-9559-E90634AFE3D6.jpeg"
                        alt="img"
                      />
                    </span>
                    {/* <div>
                  <span className="fw-5">Hoàng văn hiếu</span>
                  <p className="mb-0 text-muted fw-4">Thị trường, công nghệ</p>
                </div> */}
                  </div>
                </td>
                <td>
                  <p className="mb-0 fw-5">Tên dự án</p>
                  <span className="text-muted">VIEW 360</span>
                </td>
                <td>
                  <p className="mb-0 fw-5">Danh mục</p>
                  <span className="text-muted">Công nghệ</span>
                </td>
                <td>
                  <p className="mb-0 fw-5">Lĩnh vực</p>
                  <span className="text-muted">Giải pháp</span>
                </td>
                <td>
                  <p className="mb-0 fw-5">Khu vực</p>
                  <span className="text-muted">Toàn quốc</span>
                </td>
                <td>
                  <p className="mb-0 fw-5 text-orange">Website</p>
                  <a href="https://view360.vn" className="text-info">
                    view360.vn
                  </a>
                </td>

                <td>
                  <span className="d-flex align-items-center gap-3">
                    <button className={cx("border-0", "button_icon")}>
                      <GrLineChart className="fs-21" />
                    </button>
                    <span>
                      <span className="fw-5">Chi tiết</span>
                      <p className="mb-0 text-muted">
                        Các giai đoạn triển khai
                      </p>
                    </span>
                  </span>
                </td>
                <td>
                  <span className={cx("text-muted text-primary fw-5 fs-18")}>
                    90%
                  </span>
                  <div
                    class="progress progress-xs progress-animate"
                    role="progressbar"
                    aria-valuenow="10"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    <div
                      class="progress-bar progress-bar-striped bg-primary op-8"
                      style={{ width: "90%" }}
                    ></div>
                  </div>
                </td>
                <td>
                  <button
                    className={cx(
                      "bg-primary op-8",
                      "border-0 rounded-1 p-1 px-2 fw-5 fs-14",
                    )}
                    style={{ width: "100%", color: "white" }}
                  >
                    Đang xây dựng
                  </button>
                </td>

                <td>
                  <span className="d-flex align-items-center gap-3">
                    <button className={cx("border-0", "button_icon")}>
                      <IoCalendarOutline className="fs-21" />
                    </button>
                    <span>
                      <span className="fw-5">Ngày tạo</span>
                      <p className="mb-0 text-muted">05/07/2024 08:00</p>
                    </span>
                  </span>
                </td>
              </tr>
              <tr>
                <td scope="row">
                  <div className="d-flex align-items-center gap-2">
                    <span className="avatar avatar-md online avatar-rounded">
                      <img
                        src="https://system.view360.vn/images/user/2078-5A2353E0-59F9-407F-9559-E90634AFE3D6.jpeg"
                        alt="img"
                      />
                    </span>
                    {/* <div>
                  <span className="fw-5">Hoàng văn hiếu</span>
                  <p className="mb-0 text-muted fw-4">Thị trường, công nghệ</p>
                </div> */}
                  </div>
                </td>
                <td>
                  <p className="mb-0 fw-5">Tên dự án</p>
                  <span className="text-muted">TECH VIEW 360</span>
                </td>
                <td>
                  <p className="mb-0 fw-5">Danh mục</p>
                  <span className="text-muted">Công nghệ</span>
                </td>
                <td>
                  <p className="mb-0 fw-5">Lĩnh vực</p>
                  <span className="text-muted">Chuyển đổi số</span>
                </td>
                <td>
                  <p className="mb-0 fw-5">Khu vực</p>
                  <span className="text-muted">Toàn quốc</span>
                </td>
                <td>
                  <p className="mb-0 fw-5 text-orange">Website</p>
                  <a href="https://tech.view360.vn" className="text-info">
                    tech.view360.vn
                  </a>
                </td>

                <td>
                  <span className="d-flex align-items-center gap-3">
                    <button className={cx("border-0", "button_icon")}>
                      <GrLineChart className="fs-21" />
                    </button>
                    <span>
                      <span className="fw-5">Chi tiết</span>
                      <p className="mb-0 text-muted">
                        Các giai đoạn triển khai
                      </p>
                    </span>
                  </span>
                </td>
                <td>
                  <span className={cx("text-muted fw-5 fs-18")}>0%</span>
                  {/* <p className="mb-0 text-muted fs-14">Tiến độ</p> */}
                  <div
                    class="progress progress-xs"
                    role="progressbar"
                    aria-valuenow="10"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    <div
                      class="progress-bar progress-bar-striped progress-bar-animated"
                      style={{ width: "0%" }}
                    ></div>
                  </div>
                </td>
                <td>
                  <button
                    className={cx(
                      "bg-warning-1",
                      "border-0 rounded-1 p-1 px-2 fw-5 fs-14",
                    )}
                    style={{ width: "100%", color: "white" }}
                  >
                    Chờ phê duyệt
                  </button>
                </td>

                <td>
                  <span className="d-flex align-items-center gap-3">
                    <button className={cx("border-0", "button_icon")}>
                      <IoCalendarOutline className="fs-21" />
                    </button>
                    <span>
                      <span className="fw-5">Ngày tạo</span>
                      <p className="mb-0 text-muted">05/07/2024 08:00</p>
                    </span>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* Modal detail project */}
        {detailProject && (
          <ModalReact
            modalTitle="Các giai đoạn của dự án"
            size="modal-lg"
            showModal={detailProject}
            handleClose={handleCloseModalDetailProject}
            theme={<DetailProject />}
          />
        )}
      </>
    )
}

// Detail project.
function DetailProject() {
    return (
      <div>
        <div>
          <h6>
            <span className="me-3">Giai đoạn 1</span>
            <span>05/07 - 31/07/2024</span>
          </h6>
          <div className="table-responsive">
            <table className="table text-nowrap table-striped table-hover">
              <tbody>
                <tr>
                  <th scope="row">
                    <span className="avatar avatar-md online avatar-rounded">
                      <img
                        src="https://system.view360.vn/images/user/2078-5A2353E0-59F9-407F-9559-E90634AFE3D6.jpeg"
                        alt="img"
                      />
                    </span>
                  </th>
                  <td>
                    <p className="mb-0 text-muted fs-14">Từ ngày</p>
                    <p className="text-success mb-0">01/08</p>
                  </td>
                  <td>
                    <p className="mb-0 text-muted fs-14">Đến ngày</p>
                    <p className="text-danger mb-0">03/08</p>
                  </td>
                  <td>
                    <p className="mb-0 text-muted fs-14">Tiến độ</p>
                    <p className="mb-0">30%</p>
                  </td>
                  <td>
                    <p className="mb-0 text-muted fs-14">Nội dung</p>
                    <p className="mb-0">
                      Hoàn thành giao diện châm công nhân viên
                    </p>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <span className="avatar avatar-md online avatar-rounded">
                      <img
                        src="https://system.view360.vn/images/user/21-hoanghieu.jpeg"
                        alt="img"
                      />
                    </span>
                  </th>
                  <td>
                    <p className="mb-0 text-muted fs-14">Từ ngày</p>
                    <p className="text-success mb-0">01/08</p>
                  </td>
                  <td>
                    <p className="mb-0 text-muted fs-14">Đến ngày</p>
                    <p className="text-danger mb-0">03/08</p>
                  </td>
                  <td>
                    <p className="mb-0 text-muted fs-14">Tiến độ</p>
                    <p className="mb-0">30%</p>
                  </td>
                  <td>
                    <p className="mb-0 text-muted fs-14">Nội dung</p>
                    <p className="mb-0">Hoàn thành giao diện công việc</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="mt-4">
          <h6>
            <span className="me-3">Giai đoạn 2</span>{" "}
            <span>01/08 - 30/08/2024</span>
          </h6>
          <div className="table-responsive">
            <table className="table text-nowrap table-striped table-hover">
              <tbody>
                <tr>
                  <th scope="row">
                    <span className="avatar avatar-md online avatar-rounded">
                      <img
                        src="https://system.view360.vn/images/user/2078-5A2353E0-59F9-407F-9559-E90634AFE3D6.jpeg"
                        alt="img"
                      />
                    </span>
                  </th>
                  <td>
                    <p className="mb-0 text-muted fs-14">Từ ngày</p>
                    <p className="text-success mb-0">01/08</p>
                  </td>
                  <td>
                    <p className="mb-0 text-muted fs-14">Đến ngày</p>
                    <p className="text-danger mb-0">03/08</p>
                  </td>
                  <td>
                    <p className="mb-0 text-muted fs-14">Tiến độ</p>
                    <p className="mb-0">30%</p>
                  </td>
                  <td>
                    <p className="mb-0 text-muted fs-14">Nội dung</p>
                    <p className="mb-0">
                      Hoàn thành giao diện châm công nhân viên
                    </p>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <span className="avatar avatar-md online avatar-rounded">
                      <img
                        src="https://system.view360.vn/images/user/21-hoanghieu.jpeg"
                        alt="img"
                      />
                    </span>
                  </th>
                  <td>
                    <p className="mb-0 text-muted fs-14">Từ ngày</p>
                    <p className="text-success mb-0">01/08</p>
                  </td>
                  <td>
                    <p className="mb-0 text-muted fs-14">Đến ngày</p>
                    <p className="text-danger mb-0">03/08</p>
                  </td>
                  <td>
                    <p className="mb-0 text-muted fs-14">Tiến độ</p>
                    <p className="mb-0">30%</p>
                  </td>
                  <td>
                    <p className="mb-0 text-muted fs-14">Nội dung</p>
                    <p className="mb-0">Hoàn thành giao diện công việc</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
}