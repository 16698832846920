import moment from "moment"
import { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import classNames from "classnames/bind"
import styles from "../../Job.module.scss"
const cx = classNames.bind(styles)

// Modal add.
export default function FormAddRole({ socketio, result }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  // Quản lý danh sách user.
  const [listUser, setListUser] = useState([])
  const [valuePersonnel, setValuePersonnel] = useState("")
  const [showHide, setShowHide] = useState(false)
  const checkValue = useRef()
  const idUser = useRef()
  const avatarUser = useRef()
  const handleGetValuePersonnel = (e) => {
    setValuePersonnel(e.target.value)
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: { hoten: e.target.value },
    }
    socketio.emit("client-search-data-user-hoten", params)
  }
  // Nhận dữ liệu
  useEffect(() => {
    socketio.on("server-search-data-user-hoten", (data) => {
      setListUser([...data])
    })

    return () => {
      socketio.off("client-search-data-user-hoten")
      socketio.off("server-search-data-user-hoten")
    }
  }, [])
  // Sự kiện chọn user.
  const handleClickOptionUser = (userId, userName, avatar) => {
    // Kiểm tra xem đã tồn tại dữ liệu hay chưa.
    const paramsCheck = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: { user_id: userId },
    }
    socketio.emit("client-check-data-job-personnel", paramsCheck)
    socketio.on("server-check-data-job-personnel", (dataCheck) => {
      if (dataCheck.length > 0) {
        alert("Nhân sự này đã tồn tại, bạn không thể thêm mới.")
        setValuePersonnel("")
      } else {
        // set value vào input.
        setValuePersonnel(userName)
        checkValue.current = userName
        idUser.current = userId
        avatarUser.current = avatar
      }
    })
  }
  useEffect(() => {
    if (checkValue.current !== valuePersonnel) {
      setShowHide(true)
    } else {
      setShowHide(false)
    }
    return () => {
      socketio.off("client-check-data-job-personnel")
      socketio.off("server-check-data-job-personnel")
    }
  }, [listUser, checkValue.current])

  // Quản lý ẩn gợi ý khi click ra ngoài.
  useEffect(() => {
    window.addEventListener("click", function () {
      setShowHide(false)
    })
  }, [])

  // Quản lý option bộ phận.
  const [bophanOption, setBophanOption] = useState([])
  useEffect(() => {
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
    }
    socketio.emit("client-get-data-job-bophan", params)
    socketio.on("server-get-data-job-bophan", (data) => {
      setBophanOption([...data])
    })
    return () => {
      socketio.off("client-get-data-job-bophan")
      socketio.off("server-get-data-job-bophan")
    }
  }, [])

  // Quản lý chức vụ.
  const [optionChucvu, setOptionChucvu] = useState([])
  useEffect(() => {
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
    }
    socketio.emit("client-get-data-job-chucvu", params)
    socketio.on("server-get-data-job-chucvu", (data) => {
      setOptionChucvu([...data])
    })
    return () => {
      socketio.off("client-get-data-job-chucvu")
      socketio.off("server-get-data-job-chucvu")
    }
  }, [])

  // Xử lý submit
  const checkObject = useRef()
  const onSubmit = (object) => {
    object.user_id = idUser.current
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: object,
    }
    socketio.emit("client-create-job-personnel", params)
    socketio.on("server-create-job-personnel", (data) => {
      if (data === "success") {
        checkObject.current = object
        // Ẩn modal khi thêm mới thành công.
        result(true)
        alert("Thêm quyền thành công.")
      }
    })
  }
  // Ẩn socketio.
  useEffect(() => {
    return () => {
      socketio.off("client-create-job-personnel")
      socketio.off("server-create-job-personnel")
    }
  }, [checkObject.current])
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="col-xl-12 col-md-12">
        <div className="row">
          <div className="form-floating mb-3 col-12 position-relative">
            <input
              type="text"
              className="form-control"
              placeholder="Chọn nhân viên"
              {...register("user_id", { required: true })}
              value={valuePersonnel}
              onChange={handleGetValuePersonnel}
            />
            {avatarUser.current && valuePersonnel && (
              <span
                className={cx(
                  "avatar avatar-sm me-2 avatar-rounded",
                  "avatarInput",
                )}
              >
                <img
                  src={"https://system.view360.vn/" + avatarUser.current}
                  alt="img"
                />
              </span>
            )}
            <label className="fs-15 ms-2 text-muted">Chọn nhân viên</label>
            {errors.user_id && (
              <span className="text-danger fs-12">
                *Bạn cần chọn nhân viên.
              </span>
            )}
            {/* gợi ý user */}
            {listUser.length > 0 && (
              <ul
                className="list_suggest"
                style={{ display: showHide ? "block" : "none", width: "95%" }}
              >
                {listUser.map((item, index) => {
                  return (
                    <li
                      key={index}
                      onClick={() =>
                        handleClickOptionUser(
                          item.id_user,
                          item.hoten,
                          item.avatar,
                        )
                      }
                      className="dropdown-item d-flex align-items-center gap-1"
                    >
                      <span className="avatar avatar-xs me-2 avatar-rounded">
                        <img
                          src={"https://system.view360.vn/" + item.avatar}
                          alt="img"
                        />
                      </span>
                      <span>{item.hoten}</span>
                    </li>
                  )
                })}
              </ul>
            )}
          </div>
        </div>

        <div className="row">
          <div className="form-floating mb-3 col-6">
            <select className="form-select" {...register("bo_phan")}>
              <option value="">Chọn bộ phận</option>
              {bophanOption.length > 0 &&
                bophanOption.map((item, index) => {
                  return (
                    <option value={item.title} key={index}>
                      {item.title}
                    </option>
                  )
                })}
            </select>
            <label className="fs-15 ms-2 text-muted">Bộ phận</label>
          </div>
          <div className="form-floating mb-3 col-6">
            <select className="form-select" {...register("chuc_vu")}>
              <option value="">Chọn chức vụ</option>
              {optionChucvu.length > 0 &&
                optionChucvu.map((item, index) => {
                  return (
                    <option value={item.title} key={index}>
                      {item.title}
                    </option>
                  )
                })}
            </select>
            <label className="fs-15 ms-2 text-muted">Chức vụ</label>
          </div>
        </div>

        <div className="row">
          <div className="form-floating mb-3 col-6">
            <input
              type="text"
              className="form-control"
              placeholder="Ví dụ: Kiến an"
              {...register("district", { required: true })}
            />
            <label className="fs-15 ms-2 text-muted">Quận/Huyện</label>
            {errors.district && (
              <span className="text-danger fs-14">
                *Bạn cần chọn quận huyện làm việc.
              </span>
            )}
          </div>
          <div className="form-floating mb-3 col-6">
            <input
              type="text"
              className="form-control"
              placeholder="Ví dụ: Hải phòng"
              {...register("city", { required: true })}
              defaultValue="Hải phòng"
            />
            <label className="fs-15 ms-2 text-muted">Thành phố</label>
            {errors.city && (
              <span className="text-danger fs-14">
                *Bạn cần chọn thành phố làm việc.
              </span>
            )}
          </div>
        </div>

        <div className="row">
          <div className="form-floating mb-3 col-6">
            <input
              type="datetime-local"
              placeholder="Từ ngày"
              className="form-control"
              {...register("tu_ngay", { required: true })}
            />
            <label className="fs-15 ms-2 text-muted">Ngày bắt đầu</label>
            {errors.tu_ngay && (
              <span className="text-danger fs-12">
                *Bạn cần chọn ngày bắt đầu.
              </span>
            )}
          </div>
          <div className="form-floating mb-3 col-6">
            <input
              type="datetime-local"
              placeholder="Đến ngày"
              className="form-control"
              {...register("den_ngay", { required: true })}
            />
            <label className="fs-15 ms-2 text-muted">Ngày kết thúc</label>
            {errors.den_ngay && (
              <span className="text-danger fs-12">
                *Bạn cần chọn ngày kết thúc.
              </span>
            )}
          </div>
        </div>

        <div className="row">
          <div className="form-floating mb-3 col-6">
            <select className="form-select" {...register("ca_lamviec")}>
              <option value="0">Chọn ca làm việc</option>
              <option value="1">Nửa ca</option>
              <option value="2">Cả ngày</option>
            </select>
            <label className="fs-15 ms-2 text-muted">Chọn ca làm việc</label>
          </div>
          <div className="form-floating mb-3 col-6">
            <select className="form-select" {...register("loai_ca")}>
              <option value="">Chọn loại ca</option>
              <option value="full">Cả ngày</option>
              <option value="sang">Ca sáng</option>
              <option value="chieu">Ca chiều</option>
            </select>
            <label className="fs-15 ms-2 text-muted">Chọn loại ca</label>
          </div>
        </div>
      </div>
      {/* Button */}
      <div className="px-4 py-3 border-top border-block-start-dashed d-flex justify-content-center">
        <button className="btn btn-primary-light m-1" type="submit">
          Thêm mới
        </button>
      </div>
    </form>
  )
}

// Form dit data.
export function FormEditDataPersonnel({ socketio, result, dataEdit }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  // Quản lý danh sách user.
  const [listUser, setListUser] = useState([])
  const [valuePersonnel, setValuePersonnel] = useState("")
  const [showHide, setShowHide] = useState(false)
  const checkValue = useRef()
  const idUser = useRef()
  const avatarUser = useRef()
  // set giá trị edit.
  useEffect(() => {
    setValuePersonnel(dataEdit.hoten)
    avatarUser.current = dataEdit.avatar
  }, [])

  // Quản lý sự thay đổi lựa chọn nhân sự
  const handleGetValuePersonnel = (e) => {
    setValuePersonnel(e.target.value)
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: { hoten: e.target.value },
    }
    socketio.emit("client-search-data-user-hoten", params)
  }
  // Nhận dữ liệu
  useEffect(() => {
    socketio.on("server-search-data-user-hoten", (data) => {
      setListUser([...data])
    })

    return () => {
      socketio.off("client-search-data-user-hoten")
      socketio.off("server-search-data-user-hoten")
    }
  }, [])
  // Sự kiện chọn user.
  const handleClickOptionUser = (userId, userName, avatar) => {
    // Kiểm tra xem đã tồn tại dữ liệu hay chưa.
    const paramsCheck = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: { user_id: userId },
    }
    socketio.emit("client-check-data-job-personnel", paramsCheck)
    socketio.on("server-check-data-job-personnel", (dataCheck) => {
      if (dataCheck.length > 0) {
        alert("Nhân sự này đã tồn tại, bạn không thể thêm mới.")
        setValuePersonnel("")
      } else {
        // set value vào input.
        setValuePersonnel(userName)
        checkValue.current = userName
        idUser.current = userId
        avatarUser.current = avatar
      }
    })
  }
  useEffect(() => {
    if (checkValue.current !== valuePersonnel) {
      setShowHide(true)
    } else {
      setShowHide(false)
    }
    return () => {
      socketio.off("client-check-data-job-personnel")
      socketio.off("server-check-data-job-personnel")
    }
  }, [listUser, checkValue.current])

  // Quản lý ẩn gợi ý khi click ra ngoài.
  useEffect(() => {
    window.addEventListener("click", function () {
      setShowHide(false)
    })
  }, [])

  // Quản lý option bộ phận.
  const [bophanOption, setBophanOption] = useState([])
  useEffect(() => {
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
    }
    socketio.emit("client-get-data-job-bophan", params)
    socketio.on("server-get-data-job-bophan", (data) => {
      setBophanOption([...data])
    })
    return () => {
      socketio.off("client-get-data-job-bophan")
      socketio.off("server-get-data-job-bophan")
    }
  }, [])

  // Quản lý chức vụ.
  const [optionChucvu, setOptionChucvu] = useState([])
  useEffect(() => {
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
    }
    socketio.emit("client-get-data-job-chucvu", params)
    socketio.on("server-get-data-job-chucvu", (data) => {
      setOptionChucvu([...data])
    })
    return () => {
      socketio.off("client-get-data-job-chucvu")
      socketio.off("server-get-data-job-chucvu")
    }
  }, [])

  // Xử lý submit
  const checkObject = useRef()
  const onSubmit = (object) => {
    object.user_id = idUser.current
    object.id = dataEdit.id
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: object,
    }
    socketio.emit("client-edit-data-job-personnel", params)
    socketio.on("server-edit-data-job-personnel", (data) => {
      if (data === "success") {
        checkObject.current = object
        // Ẩn modal khi thêm mới thành công.
        result({
          status: true,
          index: dataEdit.index,
          user_id: dataEdit.user_id,
        })
        alert("Sửa thông tin thành công.")
      }
    })
  }
  // Ẩn socketio.
  useEffect(() => {
    return () => {
      socketio.off("client-edit-data-job-personnel")
      socketio.off("server-edit-data-job-personnel")
    }
  }, [checkObject.current])

  if (bophanOption.length === 0) {
    return <div className="text-center mt-3 fs-15">Đang tải bộ phận.</div>
  }
  if (optionChucvu.length === 0) {
    return <div className="text-center mt-3 fs-15">Đang tải Chức vụ.</div>
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="col-xl-12 col-md-12">
        <div className="row">
          <div className="form-floating mb-3 col-12 position-relative">
            <input
              type="text"
              className="form-control"
              placeholder="Chọn nhân viên"
              {...register("user_id", { required: true })}
              value={valuePersonnel}
              onChange={handleGetValuePersonnel}
            />
            {avatarUser.current && valuePersonnel && (
              <span
                className={cx(
                  "avatar avatar-sm me-2 avatar-rounded",
                  "avatarInput",
                )}
              >
                <img
                  src={"https://system.view360.vn/" + avatarUser.current}
                  alt="img"
                />
              </span>
            )}
            <label className="fs-15 ms-2 text-muted">Chọn nhân viên</label>
            {errors.user_id && (
              <span className="text-danger fs-12">
                *Bạn cần chọn nhân viên.
              </span>
            )}
            {/* gợi ý user */}
            {listUser.length > 0 && (
              <ul
                className="list_suggest"
                style={{ display: showHide ? "block" : "none", width: "95%" }}
              >
                {listUser.map((item, index) => {
                  return (
                    <li
                      key={index}
                      onClick={() =>
                        handleClickOptionUser(
                          item.id_user,
                          item.hoten,
                          item.avatar,
                        )
                      }
                      className="dropdown-item d-flex align-items-center gap-1"
                    >
                      <span className="avatar avatar-xs me-2 avatar-rounded">
                        <img
                          src={"https://system.view360.vn/" + item.avatar}
                          alt="img"
                        />
                      </span>
                      <span>{item.hoten}</span>
                    </li>
                  )
                })}
              </ul>
            )}
          </div>
        </div>

        <div className="row">
          <div className="form-floating mb-3 col-6">
            <select
              className="form-select"
              {...register("bo_phan")}
              defaultValue={dataEdit.bo_phan}
            >
              <option value="">Chọn bộ phận</option>
              {bophanOption.length > 0 &&
                bophanOption.map((item, index) => {
                  return (
                    <option value={item.title} key={index}>
                      {item.title}
                    </option>
                  )
                })}
            </select>
            <label className="fs-15 ms-2 text-muted">Bộ phận</label>
          </div>
          <div className="form-floating mb-3 col-6">
            <select
              className="form-select"
              {...register("chuc_vu")}
              defaultValue={dataEdit.chuc_vu}
            >
              <option value="">Chọn chức vụ</option>
              {optionChucvu.length > 0 &&
                optionChucvu.map((item, index) => {
                  return (
                    <option value={item.title} key={index}>
                      {item.title}
                    </option>
                  )
                })}
            </select>
            <label className="fs-15 ms-2 text-muted">Chức vụ</label>
          </div>
        </div>

        <div className="row">
          <div className="form-floating mb-3 col-6">
            <input
              type="text"
              className="form-control"
              placeholder="Ví dụ: Kiến an"
              {...register("district", { required: true })}
              defaultValue={dataEdit.district}
            />
            <label className="fs-15 ms-2 text-muted">Quận/Huyện</label>
            {errors.district && (
              <span className="text-danger fs-14">
                *Bạn cần chọn quận huyện làm việc.
              </span>
            )}
          </div>
          <div className="form-floating mb-3 col-6">
            <input
              type="text"
              className="form-control"
              placeholder="Ví dụ: Hải phòng"
              {...register("city", { required: true })}
              defaultValue={dataEdit.city}
            />
            <label className="fs-15 ms-2 text-muted">Thành phố</label>
            {errors.city && (
              <span className="text-danger fs-14">
                *Bạn cần chọn thành phố làm việc.
              </span>
            )}
          </div>
        </div>

        <div className="row">
          <div className="form-floating mb-3 col-6">
            <input
              type="datetime-local"
              placeholder="Từ ngày"
              className="form-control"
              {...register("tu_ngay", { required: true })}
              defaultValue={moment(dataEdit.tu_ngay).format("YYYY-MM-DD HH:mm")}
            />
            <label className="fs-15 ms-2 text-muted">Ngày bắt đầu</label>
            {errors.tu_ngay && (
              <span className="text-danger fs-12">
                *Bạn cần chọn ngày bắt đầu.
              </span>
            )}
          </div>
          <div className="form-floating mb-3 col-6">
            <input
              type="datetime-local"
              placeholder="Đến ngày"
              className="form-control"
              {...register("den_ngay", { required: true })}
              defaultValue={moment(dataEdit.den_ngay).format(
                "YYYY-MM-DD HH:mm",
              )}
            />
            <label className="fs-15 ms-2 text-muted">Ngày kết thúc</label>
            {errors.den_ngay && (
              <span className="text-danger fs-12">
                *Bạn cần chọn ngày kết thúc.
              </span>
            )}
          </div>
        </div>

        <div className="row">
          <div className="form-floating mb-3 col-6">
            <select
              className="form-select"
              {...register("ca_lamviec")}
              defaultValue={dataEdit.ca_lamviec}
            >
              <option value="0">Chọn ca làm việc</option>
              <option value="1">Nửa ca</option>
              <option value="2">Cả ngày</option>
            </select>
            <label className="fs-15 ms-2 text-muted">Chọn ca làm việc</label>
          </div>
          <div className="form-floating mb-3 col-6">
            <select
              className="form-select"
              {...register("loai_ca")}
              defaultValue={dataEdit.loai_ca}
            >
              <option value="">Chọn loại ca</option>
              <option value="full">Cả ngày</option>
              <option value="sang">Ca sáng</option>
              <option value="chieu">Ca chiều</option>
            </select>
            <label className="fs-15 ms-2 text-muted">Chọn loại ca</label>
          </div>
        </div>
      </div>
      {/* Button */}
      <div className="px-4 py-3 d-flex border-top border-block-start-dashed justify-content-center">
        <button className="btn btn-primary-light m-1" type="submit">
          Lưu lại
        </button>
      </div>
    </form>
  )
}

// Form add bộ phận.
export function FormAddBophan({ socketio, result }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onSubmit = (object) => {
    const paramsCreate = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: object,
    }
    socketio.emit("client-create-data-job-bophan", paramsCreate)
    socketio.on("server-create-data-job-bophan", (data) => {
      if (data === "success") {
        alert("Thêm mới thành công.")
        result(true)
      }
    })
  }
  useEffect(() => {
    socketio.off("client-create-data-job-bophan")
    socketio.off("server-create-data-job-bophan")
  }, [])
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-floating mb-3 col-12">
        <input
          type="text"
          placeholder="Từ ngày"
          className="form-control"
          {...register("title", { required: true })}
        />
        <label className="fs-15 text-muted">Bộ phận công ty</label>
        {errors.title && (
          <span className="text-danger fs-12">
            *Bạn cần đặt tên cho bộ phận công ty.
          </span>
        )}
      </div>
      {/* Button */}
      <div className="px-4 py-3 border-top border-block-start-dashed d-flex justify-content-center">
        <button className="btn btn-primary-light m-1" type="submit">
          Lưu lại
        </button>
      </div>
    </form>
  )
}


// Form add chức vụ.
export function FormAddChucvu({ socketio, result }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onSubmit = (object) => {
    const paramsCreate = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: object,
    }
    socketio.emit("client-create-data-job-chucvu", paramsCreate)
    socketio.on("server-create-data-job-chucvu", (data) => {
      if (data === "success") {
        alert("Thêm mới thành công.")
        result(true)
      }
    })
  }
  useEffect(() => {
    socketio.off("client-create-data-job-chucvu")
    socketio.off("server-create-data-job-chucvu")
  }, [])
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-floating mb-3 col-12">
        <input
          type="text"
          placeholder="Từ ngày"
          className="form-control"
          {...register("title", { required: true })}
        />
        <label className="fs-15 text-muted">Chức vụ công ty</label>
        {errors.title && (
          <span className="text-danger fs-12">
            *Bạn cần đặt tên cho chức vụ công ty.
          </span>
        )}
      </div>
      {/* Button */}
      <div className="px-4 py-3 border-top border-block-start-dashed d-flex justify-content-center">
        <button className="btn btn-primary-light m-1" type="submit">
          Lưu lại
        </button>
      </div>
    </form>
  )
}
