import { useState } from "react"
import { IoMdFingerPrint } from "react-icons/io"
import { IoCalendarOutline, IoRocketOutline } from "react-icons/io5"
import classNames from "classnames/bind"
import styles from "../../Home.module.scss";
import FormChamcong from "../../../Job/Component/Chamcong/FormChamcong";
import moment from "moment";
import { BsDot } from "react-icons/bs";
import ModalReact from "../../../../Components/Modal/ModalReact";
import FormAddJob from "../../../Job/Component/Job/FormAddJob";
const cx = classNames.bind(styles);

function TimeKeepingAndOther({ socketio, dataUser }) {

  // Quản lý modal chấm công.
  const [modalChamcong, setModalChamcong] = useState(false)
  const handleAddChamcong = () => {
    setModalChamcong(true)
  }
  const handleCloseModalChamcong = () => {
    setModalChamcong(false)
  }
  const handleChamcongSuccess = (object) => {
    if (object.status) {
    //   setModalChamcong(false)
    }
  }
  // Quản lý thêm mới công việc.
  const [modalAddJob, setModalAddJob] = useState(false)
  const handleModalAddJob = () => {
    setModalAddJob(true)
  }
  const handleCloseModalAddJob = () => {
    setModalAddJob(false)
  }
    const handleAddJobSuccess = (object) => {
      setModalAddJob(false)
  }
    return (
        <>
            <div className="wrapper_ground">
                <button
                    className={cx(
                        "list_items border-0 d-flex align-items-center justify-content-center gap-1 p-2 rounded-3 fs-14 fw-5",
                    )}
                    onClick={handleAddChamcong}
                >
                    <IoMdFingerPrint className="fs-16" />
                    Chấm công
                </button>
                <button
                    className={cx(
                        "list_items border-0 d-flex align-items-center justify-content-center gap-1 p-2 rounded-3 fs-14 fw-5",
                    )}
                    onClick={handleModalAddJob}
                >
                    <IoCalendarOutline className="fs-16" />
                    Thêm việc
                </button>
            </div>
            {/* Modal cham cong */}
            <ModalReact
                modalTitle={
                    <>
                        <div className="d-flex gap-3 align-items-center">
                            <div>
                                <span className="avatar avatar-rounded">
                                    <img src={"/" + dataUser[0].avatar} alt="avatar" />
                                </span>
                            </div>
                            <div>
                                <span>{dataUser[0].hoten}</span>
                                <p className="mb-0 fs-14 text-muted mt-1">
                                    Xác nhận chấm công <BsDot /> Ngày{" "}
                                    {moment().format("DD/MM/YYYY")}
                                </p>
                            </div>
                        </div>
                    </>
                }
                showModal={modalChamcong}
                handleClose={handleCloseModalChamcong}
                theme={
                    <FormChamcong
                        socketio={socketio}
                        result={handleChamcongSuccess}
                        dataUser={dataUser}
                    />
                }
            />
            {/* Modal add job */}
            {modalAddJob && (
                <ModalReact
                    modalTitle="Thêm mới công việc"
                    showModal={modalAddJob}
                    handleClose={handleCloseModalAddJob}
                    theme={<FormAddJob socketio={socketio} dataUser={dataUser} />}
                />
            )}
        </>
    );
}
export default TimeKeepingAndOther
