import { useEffect, useRef, useState } from "react"
import { TwitterPicker } from "react-color"
import classNames from "classnames/bind";
import styles from "./InputColor.module.scss"
const cx = classNames.bind(styles)

function InputColor({colorDefault, result }) {
    const [color, setColor] = useState(colorDefault ? colorDefault : "")
    const [showPicker, setShowPicker] = useState(false)
    const pickerRef = useRef(null)

    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setShowPicker(false)
      }
    }

    const handleChangeComplete = (color) => {
        setColor(color.hex)
      result(color.hex) // Gọi callback khi màu được chọn
      setShowPicker(false) // Ẩn trình chọn màu sau khi chọn màu
    }

    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside)
      return () => document.removeEventListener("mousedown", handleClickOutside)
    }, [])

    return (
      <div style={{ position: "relative" }}>
        <button
          type="button"
          onClick={() => setShowPicker(!showPicker)}
          style={{
            backgroundColor: color,
            width: "50px",
            height: "50px",
            border: "1px solid #ccc",
            cursor: "pointer",
            borderRadius: "100px",
          }}
        >
          {/* <span className="fs-11">{color}</span> */}
        </button>
        {showPicker && (
          <div
            ref={pickerRef}
            style={{ position: "absolute", zIndex: 2, right: "0" }}
          >
            <TwitterPicker
              className={cx("twitter-picker-1")}
              color={color}
              onChangeComplete={handleChangeComplete}
              width="100%"
            />
          </div>
        )}
      </div>
    )
}

export default InputColor