import classNames from "classnames/bind"
import styles from "../Job.module.scss"
import { GrFormPreviousLink } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import SearchVangmat from "../Component/Chamcong/SearchVangmat";
const cx = classNames.bind(styles);

function ListXinnghiPC() {
    const goBack = useNavigate()
    const handleGoBack = () => {
        goBack(-1)
    }
    return (
      <>
        {/* Top */}
        <div className={cx("d-flex justify-content-between")}>
          {/* name cate */}
          <div className={cx("d-flex align-items-center gap-3 fs-15 fw-5")}>
            <button
              className={cx(
                "border-0 bg-transparent fs-15 d-flex align-items-center op-8 p-0",
              )}
              onClick={handleGoBack}
            >
              <GrFormPreviousLink className="fs-21" />
              Quay lại
            </button>
            <span>Quản lý đơn xin nghỉ</span>
          </div>
          {/* Search */}
          <SearchVangmat />
        </div>
        {/* Content */}
        <div className={cx("mt-3")}>
            <ListContentXinnghi />
        </div>
      </>
    )
}
export default ListXinnghiPC


// List content tam nghi.
function ListContentXinnghi() {
    return (
      <div className="table-responsive">
        <table className="table text-nowrap table-bordered border-primary">
          <thead>
            <tr>
              <th scope="col">Người tạo</th>
              <th scope="col">Mã NV</th>
              <th scope="col">Họ tên</th>
              <th scope="col">Trạng thái</th>
              <th scope="col">Loại đơn</th>
              <th scope="col">Phòng ban</th>
              <th scope="col">Vị trí</th>
              <th scope="col">Bắt đầu nghỉ</th>
              <th scope="col">Ngày tạo</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th
                scope="row"
                style={{ width: "50px" }}
                className={cx("text-center")}
              >
                <span className="avatar avatar-sm me-2 online avatar-rounded">
                  <img src="../assets/images/faces/13.jpg" alt="img" />
                </span>
              </th>
              <td style={{ width: "50px" }}>Ma 0001</td>
              <td>Hoàng văn hiếu</td>
              <td>
                <button
                  className="px-3 py-1 rounded-2 bg-warning op-8 border-0 fs-12"
                  style={{ color: "white" }}
                >
                  <span>Chờ duyệt</span>
                </button>
              </td>
              <td>Đơn nghỉ việc</td>
              <td>Thị trường, công nghệ</td>
              <td>CEO</td>
              <td>21/12/2023</td>
              <td>09/07/2024 08:00:00</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
}