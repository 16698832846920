import { CgArrowLongRightR } from "react-icons/cg"
import SearchSalary from "../Bangluong/SearchSalary"
import { useEffect, useRef, useState } from "react"
import moment from "moment"
import LoadingSpinner from "../../../../Components/Loading/LoadingSpinner";
import { AiFillDislike, AiFillLike } from "react-icons/ai";
import classNames from "classnames/bind";
import styles from "../../Job.module.scss"
import { HiEmojiHappy } from "react-icons/hi";
import { FaFaceSadTear, FaFaceSmile } from "react-icons/fa6";
import { BsFillSignStopFill } from "react-icons/bs";
import { MdCheckCircle } from "react-icons/md";
const cx = classNames.bind(styles)

function ListBangcandoi({ socketio, dataUser }) {
    const [objectSearch, setObjectSearch] = useState({
        monthSearch: moment().format("YYYY-MM"),
        textSearch: ""
    });
    const [dataExpense, setDataExpense] = useState([])
    const [totalRevenue, setTotalRevenue] = useState(0)
    const [loading, setLoading] = useState(true)
    
    // Quản lý search.
    const handleGetValueSearchText = (object) => {
        setObjectSearch({
            monthSearch: object.monthSearch,
            textSearch: object.textSearch
        })
    }

    // Get data.
    useEffect(() => {
        const params = {
            iden: process.env.REACT_APP_IDEN_DASH,
            object: objectSearch
        }
        socketio.emit("client-get-data-total-expense-group-by-ketoan-chiphi", params)
        socketio.on("server-get-data-total-expense-group-by-ketoan-chiphi", (data) => {
            setDataExpense([...data])
        });

        // Tổng doanh thu tháng.
        socketio.emit("client-get-total-revenue-job-contract", params)
        socketio.on("server-get-total-revenue-job-contract", (data) => {
            if (data.length > 0) {
                setTotalRevenue(data[0].total_revenue)
            }
            setLoading(false)
        })

        return () => {
            socketio.off("client-get-data-total-expense-group-by-ketoan-chiphi")
            socketio.off("server-get-data-total-expense-group-by-ketoan-chiphi")

            socketio.off("client-get-total-revenue-job-contract")
            socketio.off("server-get-total-revenue-job-contract")
        }
    }, [objectSearch])

    if (loading) {
        return <LoadingSpinner />
    }
    if (dataExpense.length === 0) {
        return <div className="text-center fs-15 mt-3">Chưa có dữ liệu.</div>
    }
    return (
        <div>
            {/* Search */}
            <SearchSalary resultSearch={handleGetValueSearchText} />
            {/* Danh sách */}
            <div className="text-center mt-3">
                {dataExpense.map((item, index) => {
                    let tysuatCP =
                        (Number(item.total_expense) / Number(totalRevenue)) *
                        100
                    //   Set tỷ suất limit.
                    let percentLimit = Number(item.percent_limit)
                    //   Set còn lại.
                    let tysuatConlai = percentLimit - tysuatCP
                    return (
                        <div className="timeline-hor mb-3" key={index}>
                            <div
                                className="timeline-event-hor"
                                style={{ width: "160px" }}
                            >
                                <div
                                    className="event-date-hor fw-4 fs-15"
                                //   style={{
                                //     position: "relative",
                                //     top: "15px",
                                //     background: " white",
                                //     zIndex: "9",
                                //     padding: "3px",
                                //   }}
                                >
                                    {item.loai_chiphi.length <= 12 ? (
                                        item.loai_chiphi
                                    ) : (
                                        <div className="fs-12">{item.loai_chiphi}</div>
                                    )}
                                </div>
                                <div className="event-description-hor mt-4">
                                    {tysuatConlai > 0 && (
                                        <>
                                            <span className="me-1 text-success">
                                                Được chi
                                            </span>
                                            <span className="text-success">
                                                {Number.isInteger(tysuatConlai)
                                                    ? tysuatConlai
                                                    : tysuatConlai.toFixed(2)}{" "}
                                                %
                                            </span>
                                        </>
                                    )}
                                    {tysuatConlai < 0 && (
                                        <>
                                            <span className="me-1 text-danger">
                                                Vượt quá
                                            </span>
                                            <span className="text-danger">
                                                {Number.isInteger(tysuatConlai)
                                                    ? tysuatConlai
                                                    : tysuatConlai.toFixed(2)}{" "}
                                                %
                                            </span>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="timeline-event-hor">
                                <div className="event-date-hor text-primary fs-16">
                                    <span className="fs-14 fw-4">Hạn mức</span>{" "}
                                    {percentLimit}%
                                </div>
                                <div className="event-description-hor text-danger mt-4">
                                    <span className="fs-14 fw-4 me-1">Đã chi</span>
                                    <span className="fs-15">
                                        {Number.isInteger(tysuatCP)
                                            ? tysuatCP
                                            : tysuatCP.toFixed(2)}
                                        %
                                    </span>
                                </div>
                            </div>
                            <div className="timeline-event-hor">
                                <div className="event-date-hor">
                                    {tysuatCP < percentLimit && (
                                        <MdCheckCircle className="fs-21 text-success" />
                                    )}
                                    {tysuatCP === percentLimit && (
                                        <BsFillSignStopFill className="fs-21 text-warning" />
                                    )}
                                    {tysuatCP > percentLimit && (
                                        <BsFillSignStopFill className="fs-21 text-danger" />
                                    )}
                                </div>
                                <div className="event-description-hor mt-4">
                                    <span className="text-danger fs-15 fw-4">
                                        {Number(item.total_expense)
                                            .toLocaleString()
                                            .replace(/[.]/g, ",")}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>

        </div>
    );
}

export default ListBangcandoi