import classNames from "classnames/bind"
import styles from "../../Job.module.scss"
import SearchHorizontal from "../../../../Components/Search/SearchHorizontal"
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { getDaysArray, TextLimit } from "../../../../handle-reuses/reuses";
import AppContext from "../../../../handle-reuses/Contexts/CreateContexts";
const cx = classNames.bind(styles);

function ListTangca() {
  const { dataUser, socketio } = useContext(AppContext)
  const [dataTangca, setDataTangca] = useState([])
  const dataTangcaRef = useRef([])
    const setMonth = useRef(moment().format("YYYY-MM"))
    const [objectSearch, setObjectSearch] = useState({
        month: setMonth.current,
        keywords: "",
    })

  const handleGetListDataTangca = (month, textSearch) => {
      const params = {
          iden: process.env.REACT_APP_IDEN_DASH,
          object: {
              role: dataUser[0].list_tangca,
              month: month,
              keywords: textSearch,
              user_id: dataUser[0].id_user
          },
      };
    socketio.emit("client-get-search-role-for-data-tangca", params)
    socketio.on("server-get-search-role-for-data-tangca", (data) => {
      setDataTangca([...data])
      dataTangcaRef.current = [...data]
    })
  }
  // Quản lý danh sách tăng ca theo quyền.
  useEffect(() => {
    handleGetListDataTangca(setMonth.current, "")
    return () => {
      socketio.off("client-get-search-role-for-data-tangca")
      socketio.off("server-get-search-role-for-data-tangca")
    }
  }, [])

  // Quản lý thời gian.
  const [arrDays, setArrDays] = useState([])
  useEffect(() => {
      if (dataTangca.length > 0) {
        let year_ = moment().format("YYYY")
        let month_ = moment().format("MM")
        const arr = getDaysArray(year_, month_)
        let filterArr = arr.filter((item) => {
          return Number(item.date) <= Number(moment().format("DD"))
        })
        
        let filterDays = []
        for (let i = 0; i < dataTangca.length; i++) {
            let filter = filterArr.filter((item) => {
                return (
                    Number(item.date) ===
                    Number(moment(dataTangca[i].addtime).format("DD"))
                )
            });
            if (filterDays.indexOf(filter[0])) {
                filterDays.push(filter[0])
            }
        }
          let sortData = filterDays.sort((a, b) => {
            return Number(b.date) - Number(a.date)
          })
        setArrDays([...sortData])
    }
  }, [dataTangca])
    // Quản lý kiểm duyệt tăng ca.
  const handlePendingTangca = (id) => {
        const check = window.confirm("Bạn muốn duyệt phiếu tăng ca này chứ ?")
        if (check) {
            let paramsEdit = {
                iden: process.env.REACT_APP_IDEN_DASH,
                object: {id: id, status: 1}
            }
            socketio.emit("client-edit-row-status-tangca", paramsEdit)
            socketio.on("server-edit-row-status-tangca", (object) => {
                if (object.status === "success") {
                  let dataUpdate = dataTangcaRef.current;
                  let index = dataUpdate.findIndex((item) => item.id === object.data[0].id);
                  dataUpdate[index] = object.data[0];
                    setDataTangca([...dataUpdate])
                }
            })
        }
    }
    useEffect(() => {
        return () => {
            socketio.off("client-edit-row-status-tangca")
            socketio.off("server-edit-row-status-tangca")
        }
    }, [dataTangca])

    // Quản lý xem thêm nội dung.
    const [activeContent, setActiveContent] = useState(0)
    const handleMoreContent = (id) => {
        setActiveContent(id)
    }

  // Quản lý search text.
    const handleValueSearchText = (value) => {
        setObjectSearch({
            month: objectSearch.month,
            keywords: value
        })
        handleGetListDataTangca(objectSearch.month, value)
        setMonth.current = objectSearch.month
    }
    // QUản lý search month.
    const handleGetValueMonthSearch = (e) => {
        setObjectSearch({
            month: moment(e.target.value).format("YYYY-MM"),
            keywords: objectSearch.keywords
        })
        handleGetListDataTangca(moment(e.target.value).format("YYYY-MM"), objectSearch.keywords)
        setMonth.current = moment(e.target.value).format("YYYY-MM")
    }
    
  return (
    <div className="card-body">
      {/* Search data */}
      <div className="wrapper_ground">
        <div className="list_items">
          <input
            type="month"
            className={cx("class_input", "rounded-3 col-12")}
            style={{
              border: "2px solid rgb(128 129 246 / 58%)",
              height: "40px",
            }}
            value={objectSearch.month}
            onChange={handleGetValueMonthSearch}
          />
        </div>
        <div className="list_items">
          <SearchHorizontal
            result={handleValueSearchText}
            className="rounded-3 col-12"
            style={{
              border: "2px solid rgb(128 129 246 / 58%)",
              width: "100%",
            }}
          />
        </div>
      </div>

      {/* Title */}
      <div className="mt-3 text-center fs-15 alert alert-primary">
        Danh sách tăng ca tháng
        <span className="fw-5 ms-1 fs-16">{moment(setMonth.current).format("MM/YYYY")}</span>
      </div>

      {/* List content */}
      <ul className="list-unstyled timeline-widget-1 mb-0 my-3">
        {arrDays.map((item, index) => {
          let filterDataTangca = dataTangca.filter((value) => {
            return (
              Number(moment(value.addtime).format("DD")) === Number(item.date)
            )
          })
          return (
            <li className="timeline-widget-list-1" key={index}>
              <div className="d-flex align-items-top">
                <div className="me-4 text-center timeline-width">
                  <span className="d-block fs-20 fw-semibold text-primary">{item.date}</span>
                  <span className="d-block fs-14 text-muted">
                    {item.day.replace("Th", "Thứ")}
                  </span>
                </div>
                <div className="align-items-top justify-content-between ms-0 col-9">
                  {filterDataTangca.map((item_, index_) => {
                    let title = item_.title + " " + item_.content

                    return (
                      <div className="wrapper_ground mb-3" key={index_}>
                        <div className="list-items col-2">
                          <span
                            className={cx(
                              "avatar avatar-md avatar-rounded",
                              item_.onlineoffline === 1 && "online",
                            )}
                          >
                            <img
                              src={"https://system.view360.vn/" + item_.avatar}
                              alt="img"
                            />
                          </span>
                        </div>
                        <div className="list_items col-9">
                          <p className="mb-0 text-truncate timeline-widget-content text-wrap fs-15">
                            {item_.hoten}
                          </p>
                          <div>
                            {activeContent === item_.id ? (
                              <div className="alert alert-primary mt-1 mb-1">
                                <div className="fw-5 fs-15">{item_.title}</div>
                                <p className="mb-0 fs-14">{item_.content}</p>
                              </div>
                            ) : (
                              <span className="text-muted fs-14">
                                <TextLimit text={title} number={15} />
                              </span>
                            )}
                            {title.length >= 15 &&
                              activeContent !== item_.id && (
                                <button
                                  className="border-0 bg-transparent fs-14"
                                  onClick={() => handleMoreContent(item_.id)}
                                >
                                  xem thêm
                                </button>
                              )}
                          </div>

                          <p className="mb-0 fs-14 lh-1 text-muted">
                            {/* {moment(item_.addtime).locale("vi").fromNow().replace("một", "1")} */}
                            <span className="badge bg-primary-transparent">
                              Tăng ca{" "}
                              <span className="fs-15">
                                {item_.sophut_tangca}
                              </span>{" "}
                              phút
                            </span>
                            {dataUser[0].list_tangca === 1 &&
                              (item_.status === 1 ? (
                                <span className="badge bg-success ms-1">
                                  Đã duyệt
                                </span>
                              ) : (
                                <button
                                  className="badge bg-warning ms-1 border-0"
                                  onClick={() =>
                                    handlePendingTangca(item_.id, index_)
                                  }
                                >
                                  Chờ xét duyệt
                                </button>
                              ))}
                            {dataUser[0].list_tangca === 0 &&
                              (item_.status === 1 ? (
                                <span className="badge bg-success ms-1">
                                  Đã duyệt
                                </span>
                              ) : (
                                <span className="badge bg-success ms-1">
                                  Chờ xét duyệt
                                </span>
                              ))}
                          </p>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default ListTangca