import React from "react"
import classNames from "classnames/bind"
import styles from "./tysuat.scss"
const cx = classNames.bind(styles)


// const data = {
//   sizeChart: 50,
//   sizeText: 9,
//   rate: 60,
//   colorMain: "#ff9502",
//   color: "#efefef",
//   background: "#efefef",
//   border: 3,
//   icon: ""
// }

const PieChart = ({ data }) => {
  return (
    <div
      className={cx("path-card")}
      style={{ width: data.sizeChart ? data.sizeChart : "25px" }}
    >
      <svg viewBox="0 0 41 41" style={{ display: "block" }}>
        {/* <!-- vòng tỷ suất --> */}
        <path
          d="M19,6 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
          fill="none"
          stroke={data.color ? data.color : "red"}
          strokeWidth={data.border ? data.border : 3}
          fillOpacity="0"
        ></path>
        <path
          d="M19,6 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
          stroke={data.colorMain ? data.colorMain : "#efefef"}
          strokeWidth={data.border ? data.border : 3}
          fillOpacity="0"
          id="ts-bc"
          style={{
            strokeDasharray: data.rate + ", 100",
          }}
        ></path>
        {/* <!-- vòng tròn trong --> */}
        <circle
          cx="19"
          cy="22"
          r="13"
          stroke="black"
          strokeWidth="0"
          fill={data.background ? data.background : "#efefef"}
        />
        {/* <!-- text --> */}
        {data.icon ? (
          <g transform="translate(12, 14)">{data.icon}</g>
        ) : (
          <text
            x="20"
            y="25"
            fill={data.colorMain ? data.colorMain : "red"}
            className={cx("ts-card", "fw-5")}
            style={{ fontSize: data.sizeText ? data.sizeText : "0.45rem" }}
          >
            {data.rate}%
            {/* <tspan x="20" dy="-.2rem">
              {data.rate}
            </tspan> */}
            {/* <tspan x="20" dy="1.2em" style={{fontSize: "0.5rem"}}>
              %
            </tspan> */}
          </text>
        )}
      </svg>
    </div>
  )
}

export default PieChart
