import { useEffect, useState } from "react";
import { GiCardPickup } from "react-icons/gi";
import { IoCardOutline, IoWalletOutline } from "react-icons/io5";
import LoadingSpinner from "../../../../../Components/Loading/LoadingSpinner";

function ThongkeTaisan({ socketio, dataUser, totalPhanchia, changeStatus }) {
  const [totalThunhap, setTotalThunhap] = useState(0)
  const [totalChiphi, setTotalChiphi] = useState(0)

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const params = {
      iden: process.env.REACT_APP_IDEN_DASH,
      object: {
        user_id: dataUser[0].id_user,
      },
    }
    // Set thu nhap
    socketio.emit("client-total-thunhap-all-taichinhcanhan-thunhap", params)
    socketio.on("server-total-thunhap-all-taichinhcanhan-thunhap", (data) => {
      if (data.length > 0) {
        let total = data[0].card_payment + data[0].wallet_payment
        setTotalThunhap(total)
      }
    })
    // set chi phi.
    socketio.emit("client-total-chiphi-all-taichinhcanhan-chiphi", params)
    socketio.on("server-total-chiphi-all-taichinhcanhan-chiphi", (data) => {
      if (data.length > 0) {
        let total = data[0].card_payment + data[0].wallet_payment
        setTotalChiphi(total)
      }
      setLoading(false)
    })

    return () => {
      socketio.off("client-total-thunhap-all-taichinhcanhan-thunhap")
      socketio.off("server-total-thunhap-all-taichinhcanhan-thunhap")

      socketio.off("client-total-chiphi-all-taichinhcanhan-chiphi")
      socketio.off("server-total-chiphi-all-taichinhcanhan-chiphi")
    }
  }, [changeStatus])
  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <div className="wrapper_ground justify-content-center">
      <div className="d-flex align-items-center flex-direction-column list_items fs-17">
        <span className="fw-5">
          {(totalThunhap - totalChiphi).toLocaleString().replace(/[.]/g, ",")}
        </span>
        <span className="text-muted d-flex align-items-center gap-1">
          <IoCardOutline className="fs-18" />
          Tài sản
        </span>
      </div>
      <div className="d-flex align-items-center flex-direction-column list_items fs-17">
        <span className="fw-5">
          {totalPhanchia.toLocaleString().replace(/[.]/g, ",")}
        </span>
        <span className="text-muted d-flex align-items-center gap-1">
          <GiCardPickup className="fs-18" />
          Phân bổ
        </span>
      </div>
      <div className="d-flex align-items-center flex-direction-column list_items fs-17">
        <span className="fw-5">
          {(totalThunhap - totalChiphi - totalPhanchia)
            .toLocaleString()
            .replace(/[.]/g, ",")}
        </span>
        <span className="text-muted d-flex align-items-center gap-1">
          <IoWalletOutline className="fs-18" />
          Khả dụng
        </span>
      </div>
    </div>
  )
}
export default ThongkeTaisan