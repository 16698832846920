import { useState, useEffect } from "react"
import ModalReact from "../../../../Components/Modal/ModalReact"
import FilterEstate from "../../../../Components/Filter/FilterEstate"
import SearchHorizontal from "../../../../Components/Search/SearchHorizontal"
import FormRealEstateFast from "../Component/FromRealEstateFast"
function ComponentTopPC({ resultSearchData , socketio}) {
  // Button thêm mới
  const [addModal, setAddModal] = useState(false)
  const handleAddModal = () => {
    setAddModal(true)
  }
  const handleCloseAddModal = () => {
    setAddModal(false)
  }
  // result search.
  const handleResultSearch = (result) => {
    socketio.emit("client-search-for-admin-simple", result)
  }
  useEffect(() => {
    socketio.on("server-search-for-admin-simple", (data) => {
      resultSearchData(data)
    })
    return () => {
      socketio.off("server-search-for-admin-simple")
    }
  }, [])

  return (
    <>
      <div className="mt-4 mb-4 d-flex justify-content-between align-items-center">
        <h6>Danh sách sản phẩm</h6>
        <div className="d-flex">
          <div className="me-3">
            <SearchHorizontal
              className="rounded-3"
              style={{ border: "1px solid rgb(var(--dark-rgb))" }}
              result={handleResultSearch}
            />
          </div>
          <button className="btn btn-outline-dark" onClick={handleAddModal}>
            Thêm mới
          </button>
        </div>
      </div>
      {/* Modal add product */}
      <ModalReact
        modalTitle="Thêm mới sản phẩm"
        showModal={addModal}
        handleClose={handleCloseAddModal}
        theme={<FormRealEstateFast />}
      />
    </>
  )
}

export default ComponentTopPC